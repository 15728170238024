import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { KTSVG } from '../../../../_metronic/helpers';

import BulkSettingPopup from '../planning/components/BulkSettingPopup';
import { getTaskTicketList, updateMultipleTaskOffset } from '../../../../_metronic/requests/PlanningRequest';
import moment from "moment";
import { confirm, alert } from '@mobiscroll/react';
import { adjustDatesBasedOnConfig } from '../../../helpers/offset';
import { Loading } from '../../../components/loading/Loading';
const BulkEditPage = (
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tasksID, setTasksID] = useState([]);
  const [isdisabled, setDisabled] = useState<any>(true);
  const [istodoShown, settodoIsShown] = useState(false);
  let [todoclassName, settodoclassName] = useState("");
  const[dayRange, setDayRange]=useState<any>("");
  const [isloading, setLoading] = useState<boolean>(false);
  const [relatedServicecall, setrelatedServicecall] = useState<any>(0);

  const [payload, setPayload] = useState<any>({
    resources: [],
    projects: [],
    departments: [],
    companies: [],
    workgroups: [],
    start_date: '',
    end_date: '',
    roles: [],
    project_leads: [],
    queues: [],
    sla: [],
    contract_category: [],
    skills: [],
    lineOfBusiness: [],
    page: 1,
    items_per_page: 5,
    statuses: [],
    servicecall_statuses: [],
    types: ['task'],
    priority: [],
    task_categories: [],
    ticket_categories: [],
    ticket_types: [],
    search: '',
    tasks: []
  });

  const [selectedOption, setSelectedOption] = useState({
    "startDate": {
        "type": "offset", //by-specific-date
        "offset": "1",
        "offsetType": "day",
        "date":"",
        "isApply":false
    },
    "endDate": {
        "type": "offset",
        "offset": "1",
        "offsetType": "day",
        "date":"",
        "isApply":false
    }
  });
  const [finalOffsetValue, setFinalOffsetValue] = useState({
    "startDate": {
        "type": "offset", //by-specific-date
        "offset": "1",
        "offsetType": "day",
        "date":"",
        "isApply":false
    },
    "endDate": {
        "type": "offset",
        "offset": "1",
        "offsetType": "day",
        "date":"",
        "isApply":false
    }
  });
  const [offsetPayload, setOffsetPayload] = useState([])

  const saveChangesAutotask = () => {
    confirm({
      title: "Confirm Multiple Edits",
      message: `You are about to make multiple changes. Those changes will impact: ${tasksID?.length} tasks, ${relatedServicecall} Servicecall. Be aware that the item might be planned outside the office hours. Please check the planned items to make sure that they are planned in the correct time window.`,
      okText: "Yes",
      cancelText: "No",
      callback: async function (result) {
        if (result) {
          setDisabled(true)
          setLoading(true)
          let result:any = await updateMultipleTaskOffset(offsetPayload);
          setLoading(false)
          let alertMessages = "Tasks updated successfully."
          if (result?.data && result?.data?.message) {
            alertMessages = "Tasks update failed."
          } 
          alert({
            message: <h2>{alertMessages}</h2>,
              cssClass: "mbsc-alert-title",
              callback: function () {
                  navigate("/apps/projects/list");
              },
          });
          setDisabled(false)
        }
      },
    });
  }
  
    // Effect to update tasksID and payload on location state change
    useEffect(() => {
      if (location.state && Array.isArray(location.state.tasksID) && location.state.tasksID.length > 0) {
        setTasksID(location.state.tasksID);

        setPayload(prevPayload => ({
          ...prevPayload,
          tasks: location.state.tasksID,
          start_date: location.state.start_date,
          end_date: location.state.end_date,
        }));
      } else {
        setTasksID([]);
        setPayload(prevPayload => ({
          ...prevPayload,
          tasks: [],
          start_date:"",
          end_date: "",
        }));
      }
    }, [location.state]);

    const { isFetching: istoplanfetch, data: taskTicketList } = useQuery(
      ['task-ticket-list', payload], 
      () => getTaskTicketList(payload),
      {
        cacheTime: 0,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        onSuccess(data) {
          const totalServiceCallsCount = data.reduce((total:any, item:any) => total + item.servicecalls_count, 0);
          setrelatedServicecall(totalServiceCallsCount);
        },
      }
    );

    function formatOutput(tasks, config) {

      return tasks.map(task => {
        let offsetArray:any = adjustDatesBasedOnConfig(task?.start, task?.end, config);
        return {
          id: task.id,
          offset_value: offsetArray?.offset_value,
          start: task.start,
          startDate: offsetArray?.adjustedStartDate,
          end: task.end,
          endDate: offsetArray?.adjustedEndDate,
          offset_type: "day"
        };
      });
    }

    const saveChanges =  () => {
      let newValue = JSON.parse(JSON.stringify(finalOffsetValue));
      if(dayRange === "Start"){
        newValue['startDate'] = selectedOption['startDate']
        newValue['startDate']["isApply"] = true
        newValue['endDate']["isApply"] = false
      }else{
        newValue['endDate'] = selectedOption['endDate']
        newValue['endDate']["isApply"] = true
        newValue['startDate']["isApply"] = false
      }
      setFinalOffsetValue(newValue)
      if(newValue['startDate']["isApply"] || newValue['endDate']["isApply"]){
        const output = formatOutput(taskTicketList, newValue);
        setOffsetPayload(output)
        setDisabled(false)
      }
      showstartendcomponent(dayRange)
    }
  
    const  showstartendcomponent = (value: string) => {
      todoclassName === "dateShow"
      ? settodoclassName("")
      : settodoclassName("dateShow");
      settodoIsShown((current) => !current);
      setDayRange(value);
      setSelectedOption(finalOffsetValue)
    };

    const getDuration = (task:any) => {
      let duration = moment.duration(moment(task.end).diff(moment(task.start)));
      const months = duration.years() * 12 + duration.months();
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();

      let formattedDuration = '';
      if (months > 0) formattedDuration += `${months} Month${months > 1 ? 's' : ''} `;
      if (days > 0) formattedDuration += `${days} Day${days > 1 ? 's' : ''} `;
      if (hours > 0) formattedDuration += `${hours} Hour${hours > 1 ? 's' : ''} `;
      if (minutes > 0) formattedDuration += `${minutes} Minute${minutes > 1 ? 's' : ''}`;

      if (formattedDuration === '') formattedDuration = '0 Minutes';

      return formattedDuration;
    }

  return (
    <div id="kt_project_list_edit" className={`p-0 notifyHeight h-100 ${todoclassName} bulkEdit_main`}>
      <Loading
          isLoading={isloading}
          isHideSpinner={true}
          className="splash-screen"
        />
      <div className='card h-100'>
            {/* Back button & End Project text & Save changes button */}
            <div className="card-header d-flex align-items-center justify-content-between min-h-65px">
                <div className="card-title">
                    <div className='d-flex align-items-center'>
                        <button
                        className="w-32px h-32px rounded-8px bg-gray-100 border-0 p-0"
                        style={{ border: "1px solid #E4E4E4", borderStyle: "solid" }}
                        onClick={() => {
                          confirm({
                            title: "Unsaved Changes",
                            message: "You have made changes, but they haven't been saved. Leaving now will discard these changes. Are you sure you want to leave?",
                            okText: "Yes",
                            cancelText: "No",
                            callback: function (result) {
                              if (result) {
                                navigate("/apps/projects/list");
                              }
                            },
                          });
                        }}
                        >
                            <KTSVG
                            path="/media/icons/duotune/new-icons/back-arrow.svg"
                            className="text-darkblue"
                            svgClassName="w-16px h-auto"
                            />
                        </button>
                        <h3 className="text-dark fw-bold ms-5 fs-20px">Edit Tasks</h3>
                    </div>
                </div>
                <div>
                    <button
                        type="submit"
                        className={`btn btn-primary h-32px py-0 px-5 rounded-8px fs-16px fw-bold flex-1 ${isdisabled ?  "isofflinebutton" : ""}`}
                        onClick={saveChangesAutotask}
                        disabled={isdisabled}
                    >
                    Save Changes
                    </button>
                </div>
            </div>

        {/* 1 Project selected */}
        <div className='card-body pt-0 bg-gray-100 d-flex flex-column' style={{ maxHeight: 'calc(100% - 65px)' }}>
                {/* 1 Project selected & Make a copy button start */}
                <div className="d-flex py-7 align-items-center justify-content-between">
                    <div className='d-inline-flex align-items-center'>
                        <span className="me-4 fs-20px fw-bold">{tasksID?.length} Tasks Selected</span>
                        <span>
                            <KTSVG
                                path="/media/icons/duotune/new-icons/pencil-icon.svg"
                                className="text-primary"
                                svgClassName="w-16px h-auto"
                            />
                        </span>
                    </div>
             
                </div>
                {/* 1 Project selected & Make a copy button end */}

                <div className='bg-white rounded-8px p-8 overflow-auto flex-fill' style={{ boxShadow: '0 0 2px 0 rgba(0,0,0,0.1)', maxHeight: 'calc(100% - 80px)' }}>

                    <h2 className='mb-7'><span className='fs-16'>Select what you want to change ? </span></h2>

                    <div className='d-flex align-items-center mb-7'>
                        <button                        
                            onClick={() => showstartendcomponent("Start")}
                            className={`btn ${finalOffsetValue['startDate']["isApply"] ? "btn-primary" : "btn-outline btn-outline-primary"} fw-normal border-primary rounded-pill px-12px py-0 h-32px fs-16 d-inline-flex align-items-center me-4`}
                            id="plan-bucket">Start Date</button>
                        <button
                         onClick={() => showstartendcomponent("End")}
                          className={`btn ${finalOffsetValue['endDate']["isApply"] ? "btn-primary" : "btn-outline btn-outline-primary"} fw-normal border-primary rounded-pill px-12px py-0 h-32px fs-16 d-inline-flex align-items-center me-1`}
                          id="plan-bucket">End Date</button>                        
                    </div>

                    <table cellPadding={0} cellSpacing={0} width={'100%'} className="table border-bottom border-gray-new-three mb-0 fs-16">
                        <thead className="bg-gray-100 fw-bold position-sticky top-0" style={{ zIndex: '4' }}>
                            <tr>                            
                                <th className="py-0 pe-0 ps-5 py-8px">Tasks</th> 
                                <th className="py-0 pe-0 ps-5 py-8px">Start date</th>
                                <th className="py-0 pe-0 ps-5 py-8px">End date</th>
                                <th className="py-0 ps-5 py-8px">Duration</th>
                                {(finalOffsetValue['startDate']["isApply"] || finalOffsetValue['endDate']["isApply"]) && 
                                    <><th className="py-0 pe-0 ps-5 py-8px">New start date</th>
                                    <th className="py-0 ps-5 py-8px">New end date</th></>
                                }
                            </tr>
                        </thead>

                        <tbody className='fs-14'>
                     {istoplanfetch && (
                        <tr>
                            <td colSpan={5} className="text-center">Loading...</td>
                        </tr>
                    )}
                    {!istoplanfetch && taskTicketList &&  Array.from(taskTicketList)?.map(
                        (task: any) => {
                            
                            return (
                            <tr key ={task?._id}>
                            <td className="ps-5 pe-0 py-9px border-bottom border-gray-new-three align-middle">{task?.title}</td>
                            <td className="ps-5 pe-0 py-9px border-bottom border-gray-new-three align-middle">{moment(task?.start).tz('UTC').format("DD MMM YYYY")}</td>
                            <td className="ps-5 pe-0 py-9px border-bottom border-gray-new-three align-middle">{moment(task?.end).tz('UTC').format("DD MMM YYYY")}</td>
                            <td className="ps-5 pe-0 py-9px border-bottom border-gray-new-three align-middle">{getDuration(task)}</td>
                            {offsetPayload && (finalOffsetValue['startDate']["isApply"] || finalOffsetValue['endDate']["isApply"]) && 
                              <><td style={{backgroundColor:"#FEFCF6"}} className="ps-5 pe-0 py-9px border-bottom border-gray-new-three align-middle">{moment(offsetPayload?.filter(item => item.id === task.id)?.[0]?.startDate).tz('UTC').format("DD MMM YYYY")}</td>
                              <td style={{backgroundColor:"#FEFCF6"}} className="ps-5 pe-0 py-9px border-bottom border-gray-new-three align-middle">{moment(offsetPayload?.filter(item => item.id === task.id)?.[0]?.endDate).tz('UTC').format("DD MMM YYYY")}</td></>
                            }
                           
                            </tr>
                        )
                    })
                    }
                        </tbody>
                    </table>                    
                </div>

                {/* Project Table end */}
              </div>
              <BulkSettingPopup 
                showstartendcomponent={showstartendcomponent}
                istodoShown={istodoShown}
                dayRange={dayRange}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                saveChanges={saveChanges}
              />
      </div>        
    </div>
  )
}
export default BulkEditPage; 