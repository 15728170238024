
import { BaseComponent } from '../../base';
import { addDays, createDate, formatDate, getDateOnly, getDateStr, makeDate, REF_DATE, removeTimezone } from '../../util/datetime';
import { getDimension, getDocument, hasAnimation, jsPrefix, listen, unlisten } from '../../util/dom';
import { CLICK } from '../../util/events';
import { DOWN_ARROW, END, HOME, LEFT_ARROW, PAGE_DOWN, PAGE_UP, RIGHT_ARROW, UP_ARROW } from '../../util/keys';
import { addPixel, floor, isEmpty, isNumeric, UNDEFINED } from '../../util/misc';
import { isBrowser } from '../../util/platform';
import { getEventMap } from '../../util/recurrence';
import { resizeObserver } from '../../util/resize-observer';
import {
  ICalendarViewProps,
  ICalendarViewState,
  ICellClickEvent,
  ICellHoverEvent,
  ILabelClickEvent,
  IPageLoadedEvent,
  ViewType,
} from './calendar-view.types';
import {
  getFirstPageDay,
  getLabels,
  getMonthIndex,
  getPageIndex,
  getPageNr,
  getYearIndex,
  getYearsIndex,
  MONTH_VIEW,
  MULTI_YEAR_VIEW,
  PAGE_VIEW,
  PAGE_WIDTH,
  YEAR_VIEW,
} from './calendar-view.util';

// tslint:disable no-non-null-assertion
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

/** @hidden */

export class CalendarViewBase extends BaseComponent<ICalendarViewProps, ICalendarViewState> {
  public state: ICalendarViewState = {
    height: 'sm',
    // maxLabels: 0,
    pageSize: 0,
    pickerSize: 0,
    // view: MONTH_VIEW,
    width: 'sm',
  };

  // tslint:disable variable-name
  // These are public because of the angular template only
  // ---
  public _active!: number;
  public _activeMonth!: number;
  public _axis?: 'X' | 'Y';
  public _body!: HTMLElement;
  public _cssClass?: string;
  public _colors?: { [key: string]: any };
  public _dayNames!: string[];
  public _dim: any = {};
  public _firstPage!: HTMLElement;
  public _hasPicker?: boolean;
  public _isGrid?: boolean;
  public _invalid?: { [key: string]: any };
  public _pageIndex!: number;
  public _prevIcon?: string;
  public _labels?: { [key: string]: any };
  public _labelsLayout?: { [key: string]: any };
  public _lastDay?: Date;
  public _marked?: { [key: string]: any };
  public _maxDate!: Date | number;
  public _maxIndex!: number;
  public _maxMonthIndex!: number;
  public _maxYear!: Date | number;
  public _maxYearIndex!: number;
  public _maxYears!: number;
  public _maxYearsIndex!: number;
  public _minDate!: Date | number;
  public _minIndex!: number;
  public _minMonthIndex!: number;
  public _minYear!: Date | number;
  public _minYearIndex!: number;
  public _minYears!: number;
  public _minYearsIndex!: number;
  public _months: any[] = [1, 2, 3]; // TODO: this is crap
  public _monthsMulti!: number[][];
  public _monthIndex!: number;
  public _mousewheel?: boolean;
  public _nextIcon?: string;
  public _pageNr!: number;
  public _pickerBtn?: HTMLElement;
  public _pickerCont!: HTMLElement;
  public _prevAnim?: boolean;
  public _rtlNr!: number;
  public _showDaysTop?: boolean;
  public _showOuter?: boolean;
  public _title: any[] = [];
  public _valid?: { [key: string]: any };
  public _viewTitle?: string;
  public _weeks?: number;
  public _variableRow?: number;
  public _view?: ViewType;
  public _yearFirst?: boolean;
  public _yearIndex!: number;
  public _yearsIndex!: number;

  public PAGE_VIEW: ViewType = PAGE_VIEW;
  public MONTH_VIEW: ViewType = MONTH_VIEW;
  public YEAR_VIEW: ViewType = YEAR_VIEW;
  public MULTI_YEAR_VIEW: ViewType = MULTI_YEAR_VIEW;
  // ---

  // tslint:disable variable-name
  public _headerElement?: HTMLDivElement;
  public _headerHTML?: string;
  public _shouldEnhanceHeader?: boolean;

  private _doc?: Document;
  private _hoverTimer: any;
  private _isHover?: boolean;
  private _isIndexChange?: boolean;
  private _isLabelClick?: boolean;
  private _isSwipeChange?: boolean;
  private _isVertical?: boolean;
  private _maxLabels?: number;
  private _observer: any;
  private _pageChange?: boolean;
  private _prevClick?: boolean;
  private _prevView?: ViewType;
  private _viewEnd!: Date;
  private _viewStart!: Date;
  private _shouldCheckSize?: boolean;
  private _shouldFocus?: boolean;
  private _shouldPageLoad?: boolean;
  private _disableHover?: boolean;
  // tslint:enable variable-name

  // ---

  /**
   * Navigates to next page
   */
  public nextPage = () => {
    this._prevDocClick();
    switch (this._view) {
      case MONTH_VIEW:
        this._activeMonthChange(1);
        break;
      case MULTI_YEAR_VIEW:
        this._activeYearsChange(1);
        break;
      case YEAR_VIEW:
        this._activeYearChange(1);
        break;
      default:
        this._activeChange(1);
    }
  };

  /**
   * Navigates to previous page
   */
  public prevPage = () => {
    this._prevDocClick();
    switch (this._view) {
      case MONTH_VIEW:
        this._activeMonthChange(-1);
        break;
      case MULTI_YEAR_VIEW:
        this._activeYearsChange(-1);
        break;
      case YEAR_VIEW:
        this._activeYearChange(-1);
        break;
      default:
        this._activeChange(-1);
    }
  };

  // These are public because of the Angular template only
  // ---
  // tslint:disable variable-name
  public _changeView = (newView?: ViewType) => {
    const s = this.s;
    const view = this._view;
    const hasPicker = this._hasPicker;
    const selectView = s.selectView;
    const navView = s.navView;
    const isYearView = s.showCalendar && s.calendarType === 'year';

    if (!newView) {
      switch (view) {
        case PAGE_VIEW:
          newView = navView || (isYearView ? MULTI_YEAR_VIEW : YEAR_VIEW);
          break;
        case MONTH_VIEW:
          newView = YEAR_VIEW;
          break;
        case MULTI_YEAR_VIEW:
          newView = isYearView || navView === MULTI_YEAR_VIEW ? PAGE_VIEW : YEAR_VIEW;
          break;
        default:
          newView =
            (hasPicker && navView === YEAR_VIEW) || selectView === YEAR_VIEW || this._prevView !== MULTI_YEAR_VIEW
              ? MULTI_YEAR_VIEW
              : navView === MONTH_VIEW
              ? MONTH_VIEW
              : PAGE_VIEW;
      }
      if (selectView === MULTI_YEAR_VIEW || navView === MULTI_YEAR_VIEW) {
        newView = MULTI_YEAR_VIEW;
      }
    }
    // Reset date to active date when opening navigation
    if (view === PAGE_VIEW) {
      this._activeMonth = this._active;
    }
    const skipAnimation = hasPicker && newView === selectView;
    this._prevView = view;
    this.setState({
      view: newView,
      viewClosing: skipAnimation ? UNDEFINED : view,
      viewOpening: skipAnimation ? UNDEFINED : newView,
    });
  };

  public _getPageDay(pageIndex: number): number {
    return +getFirstPageDay(pageIndex, this.s);
  }

  public _getPageStyle(index: number, offset: number, pageIndex: number, pageNr?: number): any {
    return {
      [(jsPrefix ? jsPrefix + 'T' : 't') + 'ransform']: 'translate' + this._axis + '(' + (index - offset) * 100 * this._rtlNr + '%)',
      position: index === pageIndex ? 'relative' : '',
      width: 100 / (pageNr || 1) + '%',
    };
  }

  public _getPageMonth(pageIndex: number): number {
    const s = this.s;
    const refDate = s.refDate ? makeDate(s.refDate) : REF_DATE;
    const year = s.getYear!(refDate);
    const month = s.getMonth!(refDate);
    return +s.getDate!(year, month + pageIndex, 1);
  }

  public _getPageYear(pageIndex: number): number {
    const s = this.s;
    const refDate = s.refDate ? makeDate(s.refDate) : REF_DATE;
    const year = s.getYear!(refDate);
    return year + pageIndex;
  }

  public _getPageYears(pageIndex: number): number {
    const s = this.s;
    const refDate = s.refDate ? makeDate(s.refDate) : REF_DATE;
    const year = s.getYear!(refDate);
    return year + pageIndex * 12;
  }

  public _getPickerClass(view: ViewType): string {
    let animName: string;
    const pickerName = view === this.s.selectView ? ' mbsc-calendar-picker-main' : '';
    const baseName = 'mbsc-calendar-picker';
    const hasPicker = this._hasPicker;
    const { viewClosing, viewOpening } = this.state;
    switch (view) {
      case PAGE_VIEW:
        animName = hasPicker ? '' : (viewOpening === PAGE_VIEW ? 'in-down' : '') + (viewClosing === PAGE_VIEW ? 'out-down' : '');
        break;
      case MONTH_VIEW:
        animName =
          hasPicker && viewClosing === PAGE_VIEW
            ? ''
            : (viewOpening === MONTH_VIEW ? 'in-down' : '') + (viewClosing === MONTH_VIEW ? 'out-down' : '');
        break;
      case MULTI_YEAR_VIEW:
        animName =
          hasPicker && viewClosing === PAGE_VIEW
            ? ''
            : (viewOpening === MULTI_YEAR_VIEW ? 'in-up' : '') + (viewClosing === MULTI_YEAR_VIEW ? 'out-up' : '');
        break;
      default:
        animName =
          hasPicker && (viewOpening === PAGE_VIEW || viewClosing === PAGE_VIEW)
            ? ''
            : (viewOpening === YEAR_VIEW ? (viewClosing === MULTI_YEAR_VIEW ? 'in-down' : 'in-up') : '') +
              (viewClosing === YEAR_VIEW ? (viewOpening === MULTI_YEAR_VIEW ? 'out-down' : 'out-up') : '');
    }
    return baseName + pickerName + (hasAnimation && animName ? ' ' + baseName + '-' + animName : '');
  }

  public _isNextDisabled(isModalPicker?: boolean): boolean {
    if (!this._hasPicker || isModalPicker) {
      const view = this._view;
      if (view === MULTI_YEAR_VIEW) {
        return this._yearsIndex + 1 > this._maxYearsIndex;
      }
      if (view === YEAR_VIEW) {
        return this._yearIndex + 1 > this._maxYearIndex;
      }
      if (view === MONTH_VIEW) {
        return this._monthIndex + 1 > this._maxMonthIndex;
      }
    }
    return this._pageIndex + 1 > this._maxIndex;
  }

  public _isPrevDisabled(isModalPicker?: boolean): boolean {
    if (!this._hasPicker || isModalPicker) {
      const view = this._view;
      if (view === MULTI_YEAR_VIEW) {
        return this._yearsIndex - 1 < this._minYearsIndex;
      }
      if (view === YEAR_VIEW) {
        return this._yearIndex - 1 < this._minYearIndex;
      }
      if (view === MONTH_VIEW) {
        return this._monthIndex! - 1 < this._minMonthIndex;
      }
    }
    return this._pageIndex - 1 < this._minIndex;
  }

  public _onDayHoverIn = (ev: ICellHoverEvent) => {
    if (!this._disableHover) {
      this._hook<ICellHoverEvent>('onDayHoverIn', ev);
      this._hoverTimer = setTimeout(() => {
        const key = getDateStr(ev.date);
        if (this._labels) {
          ev.labels = this._labels[key];
        }
        if (this._marked) {
          ev.marked = this._marked[key];
        }
        this._isHover = true;
        this._hook<ICellHoverEvent>('onCellHoverIn', ev);
      }, 150);
    }
  };

  public _onDayHoverOut = (ev: ICellHoverEvent) => {
    if (!this._disableHover) {
      this._hook<ICellHoverEvent>('onDayHoverOut', ev);
      clearTimeout(this._hoverTimer);
      if (this._isHover) {
        const key = getDateStr(ev.date);
        if (this._labels) {
          ev.labels = this._labels[key];
        }
        if (this._marked) {
          ev.marked = this._marked[key];
        }
        this._isHover = false;
        this._hook<ICellHoverEvent>('onCellHoverOut', ev);
      }
    }
  };

  public _onLabelClick = (args: ILabelClickEvent) => {
    this._isLabelClick = true;
    this._hook<ILabelClickEvent>('onLabelClick', args);
  };

  public _onDayClick = (args: ICellClickEvent) => {
    this._shouldFocus = !this._isLabelClick;
    this._prevAnim = false;
    this._isLabelClick = false;
    this._hook<ICellClickEvent>('onDayClick', args);
  };

  public _onTodayClick = (args: any) => {
    this._prevAnim = false;
    this._hook('onActiveChange', {
      date: +removeTimezone(createDate(this.s)),
      today: true, // Will always force selected date update
    });
    this._hook('onTodayClick', {});
  };

  public _onNavDayClick = (args: any) => {
    if (args.disabled) {
      return;
    }
    const d = args.date;
    const s = this.s;
    const newIndex = getPageIndex(d, s)!;
    this._prevDocClick();
    this._changeView(PAGE_VIEW);
    this._shouldFocus = true;
    this._prevAnim = !this._hasPicker;
    this._hook('onActiveChange', {
      date: +d,
      // Used for scrolling to the first day of the selected month in case of quick navigation
      nav: true,
      pageChange: newIndex !== this._pageIndex,
      // Will force setting the selected date for the Eventcalendar
      today: true,
    });
  };

  public _onMonthClick = (args: any) => {
    if (args.disabled) {
      return;
    }
    const s = this.s;
    const d = new Date(args.date);
    if (s.selectView === YEAR_VIEW) {
      this._hook('onDayClick', args);
    } else {
      this._prevDocClick();
      this._shouldFocus = true;
      this._prevAnim = !this._hasPicker;
      this._activeMonth = +d;
      if (s.navView === YEAR_VIEW || s.navView === UNDEFINED) {
        const newIndex = getPageIndex(d, s)!;
        this._changeView(PAGE_VIEW);
        this._hook('onActiveChange', {
          date: +d,
          // Used for scrolling to the first day of the selected month in case of quick navigation
          nav: true,
          pageChange: newIndex !== this._pageIndex,
          // Will force setting the selected date for the Eventcalendar
          today: true,
        });
      } else {
        this._changeView(MONTH_VIEW);
      }
    }
  };

  public _onYearClick = (args: any) => {
    if (args.disabled) {
      return;
    }
    const d = args.date;
    const s = this.s;
    const view = s.selectView;
    if (view === MULTI_YEAR_VIEW) {
      this._hook('onDayClick', args);
    } else {
      this._shouldFocus = true;
      this._prevAnim = view === YEAR_VIEW;
      this._activeMonth = +d;
      this._prevDocClick();
      if (s.navView === MULTI_YEAR_VIEW || s.calendarType === 'year') {
        const newIndex = getPageIndex(d, s)!;
        this._changeView(PAGE_VIEW);
        this._hook('onActiveChange', {
          date: +d,
          pageChange: newIndex !== this._pageIndex,
          // Will force setting the selected date for the Eventcalendar
          today: true,
        });
      } else {
        this._changeView(YEAR_VIEW);
      }
    }
  };

  public _onPageChange = (args: any) => {
    this._isSwipeChange = true;
    this._activeChange(args.diff);
  };

  public _onMonthPageChange = (args: any) => {
    this._activeMonthChange(args.diff);
  };

  public _onYearPageChange = (args: any) => {
    this._activeYearChange(args.diff);
  };

  public _onYearsPageChange = (args: any) => {
    this._activeYearsChange(args.diff);
  };

  public _onAnimationEnd = (args: any) => {
    this._disableHover = false;
    if (this._isIndexChange) {
      this._pageLoaded();
      this._isIndexChange = false;
    }
  };

  public _onStart = () => {
    clearTimeout(this._hoverTimer);
  };

  public _onGestureStart = (args: any) => {
    this._disableHover = true;
    this._hook('onGestureStart', args);
  };

  public _onGestureEnd = (args: any) => {
    this._prevDocClick();
  };

  public _onPickerClose = () => {
    this.setState({ view: PAGE_VIEW });
  };

  public _onPickerOpen = () => {
    const pageHeight = this._pickerCont.clientHeight;
    const pageWidth = this._pickerCont.clientWidth;
    this.setState({ pickerSize: this._isVertical ? pageHeight : pageWidth });
  };

  public _onPickerBtnClick = (ev: any) => {
    if (this._view === PAGE_VIEW) {
      this._pickerBtn = ev.currentTarget;
    }
    this._prevDocClick();
    this._changeView();
  };

  public _onDocClick = () => {
    const view = this.s.selectView;
    if (!this._prevClick && !this._hasPicker && this._view !== view) {
      this._changeView(view);
    }
  };

  public _onViewAnimationEnd = () => {
    if (this.state.viewClosing) {
      this.setState({ viewClosing: UNDEFINED });
    }
    if (this.state.viewOpening) {
      this.setState({ viewOpening: UNDEFINED });
    }
  };

  public _onResize = () => {
    if (!this._body || !isBrowser) {
      return;
    }

    const s = this.s;
    const state = this.state;
    const showCalendar = s.showCalendar!;
    // In Chrome, if _body has a size in subpixels, the inner element will still have rounded pixel values,
    // so we calculate with the size of the inner element.
    const body = showCalendar /* TRIALCOND */ ? this._body.querySelector('.mbsc-calendar-body-inner')! : this._body;
    // We need to use getBoundingClientRect to get the subpixel values if that's the case,
    // otherwise after navigating multiple times the transform will be off
    // const rect = body.getBoundingClientRect();
    // const pageHeight = rect.height; // this._body.clientHeight;
    // const pageWidth = rect.width; // this._body.clientWidth;
    const totalWidth = this._el.offsetWidth;
    const totalHeight = this._el.offsetHeight;
    const pageHeight = body.clientHeight;
    const pageWidth = body.clientWidth;
    const pageSize = this._isVertical ? pageHeight : pageWidth;
    const pickerSize = this._hasPicker ? state.pickerSize : pageSize;
    const ready = showCalendar !== UNDEFINED;
    let width: 'sm' | 'md' = 'sm';
    let height: 'sm' | 'md' = 'sm';
    let maxLabels: number | undefined = UNDEFINED;
    let hasScrollY = false;
    let cellTextHeight = 0;
    let labelHeight = 0;

    if (s.responsiveStyle && !this._isGrid) {
      if (pageHeight > 300) {
        height = 'md';
      }

      if (pageWidth > 767) {
        width = 'md';
      }
    }

    if (width !== state.width || height !== state.height) {
      // Switch between mobile and desktop styling.
      // After the new classes are applied, labels and page sizes needs re-calculation
      this._shouldCheckSize = true;
      this.setState({ width, height });
    } else {
      if (this._labels && showCalendar /* TRIALCOND */) {
        // Check how many labels can we display on a day
        // TODO: this must be refactored for React Native
        const placeholder = body.querySelector('.mbsc-calendar-text') as HTMLElement;
        const cell = body.querySelector('.mbsc-calendar-day-inner') as HTMLElement;
        const labelsCont = cell.querySelector('.mbsc-calendar-labels') as HTMLElement;
        const txtMargin = placeholder ? getDimension(placeholder, 'marginBottom') : 2;
        const txtHeight = placeholder ? placeholder.offsetHeight : 18;

        cellTextHeight = labelsCont.offsetTop;
        hasScrollY = body.scrollHeight > body.clientHeight;
        labelHeight = txtHeight + txtMargin;
        maxLabels = Math.max(1, floor((cell.clientHeight - cellTextHeight) / labelHeight));
      }

      this._hook('onResize', {
        height: totalHeight,
        target: this._el,
        width: totalWidth,
      });
      s.navService!.pageSize = pageSize;
      // Force update if page loaded needs to be triggered
      const update = this._shouldPageLoad ? (state.update || 0) + 1 : state.update;
      this.setState({ cellTextHeight, hasScrollY, labelHeight, maxLabels, pageSize, pickerSize, ready, update });
    }
  };

  public _onKeyDown = (ev: any) => {
    const s = this.s;
    const view = this._view;
    const active = view === PAGE_VIEW ? this._active : this._activeMonth;
    const activeDate = new Date(active);
    const year = s.getYear!(activeDate);
    const month = s.getMonth!(activeDate);
    const day = s.getDay!(activeDate);
    const getDate = s.getDate!;
    const weeks = s.weeks!;
    const isMonthView = s.calendarType === 'month';
    let newDate: Date | undefined;

    if (view === MULTI_YEAR_VIEW) {
      let newYear: number | undefined;

      switch (ev.keyCode) {
        case LEFT_ARROW:
          newYear = year - 1 * this._rtlNr;
          break;
        case RIGHT_ARROW:
          newYear = year + 1 * this._rtlNr;
          break;
        case UP_ARROW:
          newYear = year - 3;
          break;
        case DOWN_ARROW:
          newYear = year + 3;
          break;
        case HOME:
          newYear = this._getPageYears(this._yearsIndex);
          break;
        case END:
          newYear = this._getPageYears(this._yearsIndex) + 11;
          break;
        case PAGE_UP:
          newYear = year - 12;
          break;
        case PAGE_DOWN:
          newYear = year + 12;
          break;
      }

      if (newYear && this._minYears <= newYear && this._maxYears >= newYear) {
        ev.preventDefault();
        this._shouldFocus = true;
        this._prevAnim = false;
        this._activeMonth = +getDate(newYear, 0, 1);
        this.forceUpdate();
      }
    } else if (view === YEAR_VIEW) {
      switch (ev.keyCode) {
        case LEFT_ARROW:
          newDate = getDate(year, month - 1 * this._rtlNr, 1);
          break;
        case RIGHT_ARROW:
          newDate = getDate(year, month + 1 * this._rtlNr, 1);
          break;
        case UP_ARROW:
          newDate = getDate(year, month - 3, 1);
          break;
        case DOWN_ARROW:
          newDate = getDate(year, month + 3, 1);
          break;
        case HOME:
          newDate = getDate(year, 0, 1);
          break;
        case END:
          newDate = getDate(year, 11, 1);
          break;
        case PAGE_UP:
          newDate = getDate(year - 1, month, 1);
          break;
        case PAGE_DOWN:
          newDate = getDate(year + 1, month, 1);
          break;
      }

      if (newDate && this._minYear <= newDate && this._maxYear >= newDate) {
        ev.preventDefault();
        this._shouldFocus = true;
        this._prevAnim = false;
        this._activeMonth = +newDate;
        this.forceUpdate();
      }
    } else {
      switch (ev.keyCode) {
        case LEFT_ARROW:
          newDate = getDate(year, month, day - 1 * this._rtlNr);
          break;
        case RIGHT_ARROW:
          newDate = getDate(year, month, day + 1 * this._rtlNr);
          break;
        case UP_ARROW:
          newDate = getDate(year, month, day - 7);
          break;
        case DOWN_ARROW:
          newDate = getDate(year, month, day + 7);
          break;
        case HOME:
          newDate = getDate(year, month, 1);
          break;
        case END:
          newDate = getDate(year, month + 1, 0);
          break;
        case PAGE_UP:
          newDate = ev.altKey
            ? getDate(year - 1, month, day)
            : isMonthView
            ? getDate(year, month - 1, day)
            : getDate(year, month, day - weeks * 7);
          break;
        case PAGE_DOWN:
          newDate = ev.altKey
            ? getDate(year + 1, month, day)
            : isMonthView
            ? getDate(year, month + 1, day)
            : getDate(year, month, day + weeks * 7);
          break;
      }

      if (newDate && this._minDate <= newDate && this._maxDate >= newDate) {
        ev.preventDefault();
        const newIndex = getPageIndex(newDate, s)!;
        this._shouldFocus = true;
        this._prevAnim = false;

        if (view === MONTH_VIEW) {
          this._activeMonth = +newDate;
          this.forceUpdate();
        } else {
          this._pageChange = s.noOuterChange! && newIndex !== this._pageIndex;
          this._hook('onActiveChange', {
            date: +newDate,
            pageChange: this._pageChange,
          });
        }
      }
    }
  };

  public _setHeader = (el: any) => {
    this._headerElement = el;
  };

  public _setBody = (el: any) => {
    this._body = el;
  };

  public _setPickerCont = (el: any) => {
    this._pickerCont = el;
  };

  // tslint:enable variable-name
  // ---

  protected _render(s: ICalendarViewProps, state: ICalendarViewState) {
    const getDate = s.getDate!;
    const getYear = s.getYear!;
    const getMonth = s.getMonth!;
    const showCalendar = s.showCalendar!;
    const calendarType = s.calendarType;
    const eventRange = s.eventRange;
    const eventRangeSize = s.eventRangeSize || 1;
    const firstWeekDay = s.firstDay!;
    const isWeekView = calendarType === 'week';
    const isMonthView = calendarType === 'month';
    const isYearView = calendarType === 'year';
    const size = isYearView ? 12 : +(s.size || 1);
    const isGrid = size > 1 && !isWeekView;
    const weeks = showCalendar ? (isWeekView ? s.weeks! : 6) : 0;
    const active = s.activeDate || this._active || +new Date();
    const activeChanged = active !== this._active;
    const d = new Date(active);
    const prevProps = this._prevS;
    const dateFormat = s.dateFormat!;
    const monthNames = s.monthNames!;
    const yearSuffix = s.yearSuffix!;
    const variableRow = isNumeric(s.labelList) ? +s.labelList! + 1 : s.labelList === 'all' ? -1 : 0;
    const labelListingChanged = s.labelList !== prevProps.labelList;

    const navService = s.navService!;
    const pageIndex = navService.pageIndex;
    const firstDay = navService.firstDay;
    const lastDay = navService.lastDay;
    const start = navService.viewStart;
    const end = navService.viewEnd;

    this._minDate = navService.minDate;
    this._maxDate = navService.maxDate;

    if (!isEmpty(s.min)) {
      const min = getDateOnly(this._minDate as Date);
      this._minDate = getDateOnly(min);
      this._minYear = getDate(getYear(min), getMonth(min), 1);
      this._minYears = getYear(min);
      this._minIndex = getPageIndex(min, s)!;
      this._minYearIndex = getYearIndex(min, s);
      this._minYearsIndex = getYearsIndex(min, s);
      this._minMonthIndex = getMonthIndex(min, s);
    } else {
      this._minIndex = -Infinity;
      this._minYears = -Infinity;
      this._minYearsIndex = -Infinity;
      this._minYear = -Infinity;
      this._minYearIndex = -Infinity;
      this._minMonthIndex = -Infinity;
    }

    if (!isEmpty(s.max)) {
      const max = this._maxDate as Date;
      this._maxYear = getDate(getYear(max), getMonth(max) + 1, 1);
      this._maxYears = getYear(max);
      this._maxIndex = getPageIndex(max, s)!;
      this._maxYearIndex = getYearIndex(max, s);
      this._maxYearsIndex = getYearsIndex(max, s);
      this._maxMonthIndex = getMonthIndex(max, s);
    } else {
      this._maxIndex = Infinity;
      this._maxYears = Infinity;
      this._maxYearsIndex = Infinity;
      this._maxYear = Infinity;
      this._maxYearIndex = Infinity;
      this._maxMonthIndex = Infinity;
    }

    // We only recalculate the page index if the new active date is outside of the current view limits,
    // or page change is forced (swipe, or prev/next arrows), or the view is changed
    const viewChanged =
      calendarType !== prevProps.calendarType ||
      eventRange !== prevProps.eventRange ||
      firstWeekDay !== prevProps.firstDay ||
      s.eventRangeSize !== prevProps.eventRangeSize ||
      s.refDate !== prevProps.refDate ||
      s.showCalendar !== prevProps.showCalendar ||
      s.weeks !== prevProps.weeks;

    if (viewChanged && this._pageIndex !== UNDEFINED) {
      this._prevAnim = true;
    }

    if (activeChanged) {
      this._activeMonth = active;
    }

    this._view = state.view || s.selectView!;
    this._yearsIndex = getYearsIndex(new Date(this._activeMonth), s);
    this._yearIndex = getYearIndex(new Date(this._activeMonth), s);
    this._monthIndex = getMonthIndex(new Date(this._activeMonth), s);

    const pageNr = isGrid ? 1 : getPageNr(s.pages, state.pageSize);
    const isVertical = s.calendarScroll === 'vertical' && s.pages !== 'auto' && (s.pages === UNDEFINED || s.pages === 1);
    const showOuter = s.showOuterDays !== UNDEFINED ? s.showOuterDays : !isVertical && pageNr < 2 && (isWeekView || !size || size < 2);
    const monthIndex = dateFormat.search(/m/i);
    const yearIndex = dateFormat.search(/y/i);

    if (this._view === MONTH_VIEW) {
      const date = new Date(this._getPageMonth(this._monthIndex));
      const month = monthNames[getMonth(date)];
      const year = getYear(date) + yearSuffix;
      this._viewTitle = yearIndex < monthIndex ? year + ' ' + month : month + ' ' + year;
    } else if (this._view === YEAR_VIEW) {
      this._viewTitle = this._getPageYear(this._yearIndex) + '';
    } else if (this._view === MULTI_YEAR_VIEW) {
      const startYear = this._getPageYears(this._yearsIndex);
      this._viewTitle = startYear + ' - ' + (startYear + 11);
    }

    // Grid view
    if (isGrid) {
      this._monthsMulti = [];

      if (pageIndex !== UNDEFINED) {
        // Multiplying with 0.96 and 1.1 needed, because margins and paddings are used on the month grid
        let columns: number = floor((state.pageSize * 0.96) / (PAGE_WIDTH * 1.1)) || 1;

        while (size % columns) {
          columns--;
        }

        for (let i = 0; i < size / columns; ++i) {
          const rowItems = [];
          for (let j = 0; j < columns; ++j) {
            rowItems.push(+getDate(getYear(firstDay), getMonth(firstDay) + i * columns + j, 1));
          }
          this._monthsMulti.push(rowItems);
        }
      }
    }

    if (
      calendarType !== prevProps.calendarType ||
      s.theme !== prevProps.theme ||
      s.calendarScroll !== prevProps.calendarScroll ||
      s.hasContent !== prevProps.hasContent ||
      s.showCalendar !== prevProps.showCalendar ||
      s.showSchedule !== prevProps.showSchedule ||
      s.showWeekNumbers !== prevProps.showWeekNumbers ||
      s.weeks !== prevProps.weeks ||
      labelListingChanged
    ) {
      this._shouldCheckSize = true;
    }

    if (prevProps.width !== s.width || prevProps.height !== s.height) {
      this._dim = {
        height: addPixel(s.height),
        width: addPixel(s.width),
      };
    }

    this._cssClass =
      'mbsc-calendar mbsc-font mbsc-flex-col' +
      this._theme +
      this._rtl +
      (state.ready ? '' : ' mbsc-hidden') +
      (isGrid ? ' mbsc-calendar-grid-view' : ' mbsc-calendar-height-' + state.height + ' mbsc-calendar-width-' + state.width) +
      ' ' +
      s.cssClass;

    this._dayNames = state.width === 'sm' || isGrid ? s.dayNamesMin! : s.dayNamesShort!;
    this._isSwipeChange = false;
    this._yearFirst = yearIndex < monthIndex;
    this._pageNr = pageNr;
    this._variableRow = variableRow;

    // Only calculate labels/marks/colors when needed
    const forcePageLoad = s.pageLoad !== prevProps.pageLoad;
    const pageChanged = +start !== +this._viewStart || +end !== +this._viewEnd;

    if (this._pageIndex !== UNDEFINED && pageChanged) {
      this._isIndexChange = !this._isSwipeChange && !viewChanged;
    }

    if (pageIndex !== UNDEFINED) {
      this._pageIndex = pageIndex;
    }

    if (
      pageIndex !== UNDEFINED &&
      (s.marked !== prevProps.marked ||
        s.colors !== prevProps.colors ||
        s.labels !== prevProps.labels ||
        s.invalid !== prevProps.invalid ||
        s.valid !== prevProps.valid ||
        state.maxLabels !== this._maxLabels ||
        pageChanged ||
        labelListingChanged ||
        forcePageLoad)
    ) {
      this._maxLabels = state.maxLabels;
      this._viewStart = start;
      this._viewEnd = end;

      const labelsMap = s.labelsMap || getEventMap(s.labels!, start, end, s);
      const labels =
        labelsMap &&
        getLabels(
          s,
          labelsMap,
          start,
          end,
          this._variableRow || this._maxLabels || 1,
          7,
          false,
          firstWeekDay,
          true,
          s.eventOrder,
          !showOuter,
          s.showLabelCount,
          s.moreEventsText,
          s.moreEventsPluralText,
        );

      // If labels were not displayed previously, need to calculate how many labels can be placed
      if (labels && !this._labels) {
        this._shouldCheckSize = true;
      }

      if ((labels && state.maxLabels) || !labels) {
        this._shouldPageLoad = !this._isIndexChange || this._prevAnim || !showCalendar || forcePageLoad;
      }

      this._labelsLayout = labels;
      this._labels = labelsMap;
      this._marked = labelsMap ? UNDEFINED : s.marksMap || getEventMap(s.marked!, start, end, s);
      this._colors = getEventMap(s.colors!, start, end, s);
      this._valid = getEventMap(s.valid!, start, end, s, true);
      this._invalid = getEventMap(s.invalid!, start, end, s, true);
    }

    // Generate the header title
    if (
      pageChanged ||
      activeChanged ||
      eventRange !== prevProps.eventRange ||
      eventRangeSize !== prevProps.eventRangeSize ||
      s.monthNames !== prevProps.monthNames
    ) {
      this._title = [];

      const lDay = addDays(lastDay, -1);

      let titleDate = pageIndex === UNDEFINED ? d : firstDay;

      // Check if a selected day is in the current view,
      // the title will be generated based on the selected day
      if (isWeekView) {
        titleDate = d;
        for (const key of Object.keys(s.selectedDates!)) {
          if (+key >= +firstDay && +key < +lastDay) {
            titleDate = new Date(+key);
            break;
          }
        }
      }

      if (this._pageNr > 1) {
        for (let i = 0; i < pageNr; i++) {
          const dt = getDate(getYear(firstDay), getMonth(firstDay) + i, 1);
          const yt = getYear(dt) + yearSuffix;
          const mt = monthNames[getMonth(dt)];
          this._title.push({ yearTitle: yt, monthTitle: mt });
        }
      } else {
        const titleObj: any = { yearTitle: getYear(titleDate) + yearSuffix, monthTitle: monthNames[getMonth(titleDate)] };
        const titleType = s.showSchedule && eventRangeSize === 1 ? eventRange : showCalendar ? calendarType : eventRange;
        const agendaOnly = eventRange && !showCalendar && (!s.showSchedule || eventRangeSize > 1);

        switch (titleType) {
          case 'year': {
            titleObj.title = getYear(firstDay) + yearSuffix;
            if (eventRangeSize > 1) {
              titleObj.title += ' - ' + (getYear(lDay) + yearSuffix);
            }
            break;
          }
          case 'month': {
            if (eventRangeSize > 1 && !showCalendar) {
              const monthStart = monthNames[getMonth(firstDay)];
              const yearStart = getYear(firstDay) + yearSuffix;
              const titleStart = this._yearFirst ? yearStart + ' ' + monthStart : monthStart + ' ' + yearStart;
              const monthEnd = monthNames[getMonth(lDay)];
              const yearEnd = getYear(lDay) + yearSuffix;
              const titleEnd = this._yearFirst ? yearEnd + ' ' + monthEnd : monthEnd + ' ' + yearEnd;
              titleObj.title = titleStart + ' - ' + titleEnd;
            } else if (isGrid) {
              titleObj.title = getYear(firstDay) + yearSuffix;
            }
            break;
          }
          case 'day':
          case 'week': {
            if (agendaOnly) {
              const dayIndex = dateFormat.search(/d/i);
              const shortDateFormat = dayIndex < monthIndex ? 'D MMM, YYYY' : 'MMM D, YYYY';
              titleObj.title = formatDate(shortDateFormat, firstDay, s);
              if (titleType === 'week' || eventRangeSize > 1) {
                titleObj.title += ' - ' + formatDate(shortDateFormat, lDay, s);
              }
            }
            break;
          }
          // case 'day': {
          //   if (agendaOnly) {
          //     titleObj.title = formatDate(dateFormat, firstDay, s);
          //     if (eventRangeSize > 1) {
          //       titleObj.title += ' - ' + formatDate(dateFormat, lDay, s);
          //     }
          //   }
          // }
        }
        this._title.push(titleObj);
      }
    }

    this._active = active;
    this._hasPicker = s.hasPicker || isGrid || !isMonthView || !showCalendar || (state.width === 'md' && s.hasPicker !== false);
    this._axis = isVertical ? 'Y' : 'X';
    this._rtlNr = !isVertical && s.rtl ? -1 : 1;
    this._weeks = weeks;
    this._nextIcon = isVertical ? s.nextIconV! : s.rtl ? s.prevIconH! : s.nextIconH!;
    this._prevIcon = isVertical ? s.prevIconV! : s.rtl ? s.nextIconH! : s.prevIconH!;
    this._mousewheel = s.mousewheel === UNDEFINED ? isVertical : s.mousewheel;
    this._isGrid = isGrid;
    this._isVertical = isVertical;
    this._showOuter = showOuter;
    this._showDaysTop = isVertical || (!!variableRow && size === 1);
  }

  protected _mounted() {
    this._observer = resizeObserver(this._el, this._onResize, this._zone);
    this._doc = getDocument(this._el);
    listen(this._doc, CLICK, this._onDocClick);
  }

  protected _updated() {
    if (this._shouldCheckSize) {
      setTimeout(() => {
        this._onResize();
      });
      this._shouldCheckSize = false;
    } else if (this._shouldPageLoad) {
      // Trigger initial onPageLoaded if needed
      this._pageLoaded();
      this._shouldPageLoad = false;
    }

    if (this._shouldFocus) {
      // Angular needs setTimeout to wait for the next tick
      setTimeout(() => {
        this._focusActive();
        this._shouldFocus = false;
      });
    }

    if (this.s.instanceService) {
      this.s.instanceService.onComponentChange.next({});
    }

    this._pageChange = false;

    // TODO: why is this needed???
    if (this._variableRow && this.s.showCalendar) {
      const body = this._body.querySelector('.mbsc-calendar-body-inner')!;
      const hasScrollY = body.scrollHeight > body.clientHeight;

      if (hasScrollY !== this.state.hasScrollY) {
        this._shouldCheckSize = true;
        this.setState({ hasScrollY });
      }
    }
  }

  protected _destroy() {
    if (this._observer) {
      this._observer.detach();
    }
    unlisten(this._doc, CLICK, this._onDocClick);
    clearTimeout(this._hoverTimer);
  }

  // ---

  private _getActiveCell(): HTMLDivElement | null {
    // TODO: get rid of direct DOM function
    const view = this._view;
    const cont = view === PAGE_VIEW ? this._body : this._pickerCont;
    const cell = view === MULTI_YEAR_VIEW ? 'year' : view === YEAR_VIEW ? 'month' : 'cell';
    return cont && (cont.querySelector('.mbsc-calendar-' + cell + '[tabindex="0"]') as HTMLDivElement);
  }

  private _focusActive() {
    const cell = this._getActiveCell();
    if (cell) {
      cell.focus();
    }
  }

  private _pageLoaded() {
    const navService = this.s.navService!;
    this._hook<IPageLoadedEvent>('onPageLoaded', {
      activeElm: this._getActiveCell()!,
      firstDay: navService.firstPageDay!,
      lastDay: navService.lastPageDay!,
      month: this.s.calendarType === 'month' ? navService.firstDay : UNDEFINED,
      viewEnd: navService.viewEnd,
      viewStart: navService.viewStart,
    });
  }

  private _activeChange(diff: number) {
    const nextIndex = this._pageIndex + diff;
    if (this._minIndex <= nextIndex && this._maxIndex >= nextIndex /* TRIALCOND */) {
      this._prevAnim = false;
      this._pageChange = true;
      this._hook('onActiveChange', {
        date: this._getPageDay(nextIndex), // the date will be in number format
        dir: diff,
        pageChange: true,
      });
    }
  }

  private _activeMonthChange(diff: number) {
    const nextIndex = this._monthIndex + diff;
    if (this._minMonthIndex <= nextIndex && this._maxMonthIndex >= nextIndex) {
      this._prevAnim = false;
      this._activeMonth = this._getPageMonth(nextIndex);
      this.forceUpdate();
    }
  }

  private _activeYearsChange(diff: number) {
    const nextIndex = this._yearsIndex + diff;
    if (this._minYearsIndex <= nextIndex && this._maxYearsIndex >= nextIndex) {
      const newYear = this._getPageYears(nextIndex);
      this._prevAnim = false;
      this._activeMonth = +this.s.getDate!(newYear, 0, 1);
      this.forceUpdate();
    }
  }

  private _activeYearChange(diff: number) {
    const nextIndex = this._yearIndex + diff;
    if (this._minYearIndex <= nextIndex && this._maxYearIndex >= nextIndex) {
      const newYear = this._getPageYear(nextIndex);
      this._prevAnim = false;
      this._activeMonth = +this.s.getDate!(newYear, 0, 1);
      this.forceUpdate();
    }
  }

  private _prevDocClick() {
    this._prevClick = true;
    setTimeout(() => {
      this._prevClick = false;
    });
  }
}
