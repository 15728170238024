import { FC, lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { SuperadminMasterLayout } from "../../_metronic/layout/SuperadminMasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import DashboardPage from "../modules/superadmin/dashboard-management/DashboardPage";
import GeneralSettingPage from "../modules/superadmin/general-settings/GeneralSettingPage";
import InvoiceSetting from "../modules/superadmin/invoice-settings/InvoiceSetting";
import IntrojsPage from "../modules/superadmin/hint-tour/IntrojsPage";

const UsersPage = lazy(
  () => import("../modules/superadmin/user-management/UsersPage")
);
const RolesPage = lazy(
  () => import("../modules/superadmin/role-management/RolesPage")
);
const ProfilePage = lazy(
  () => import("../modules/superadmin/profile/ProfilePage")
);
const EmailPage = lazy(() => import("../modules/superadmin/email/EmailPage"));
const OrganisationsPage = lazy(
  () => import("../modules/superadmin/organisations/OrganisationPage")
);
const InvoicePage = lazy(
  () => import("../modules/superadmin/invoices/InvoicePage")
);

const SuperadminRoutes = () => {
  return (
    <Routes>
      <Route element={<SuperadminMasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path="superadmin/auth/*"
          element={<Navigate to="/superadmin/dashboard" />}
        />
        <Route
          path="/"
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />
        <Route
          path="dashboard"
          element={
            <SuspensedView>
              <DashboardPage />
            </SuspensedView>
          }
        />

        <Route
          path="invoices-management/*"
          element={
            <SuspensedView>
              <InvoicePage />
            </SuspensedView>
          }
        />

        <Route
          path="organisation-management/organisations/:user_id/users"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        <Route
          path="user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path="role-management/*"
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />
        <Route
          path="mail-template/*"
          element={
            <SuspensedView>
              <EmailPage />
            </SuspensedView>
          }
        />
        <Route
          path="general-settings/*"
          element={
            <SuspensedView>
              <GeneralSettingPage />
            </SuspensedView>
          }
        />
        <Route
          path="invoice-settings/*"
          element={
            <SuspensedView>
              <InvoiceSetting />
            </SuspensedView>
          }
        />
        <Route
          path="organisation-management/*"
          element={
            <SuspensedView>
              <OrganisationsPage />
            </SuspensedView>
          }
        />
        <Route
          path="/apps/profile"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="introjs/*"
          element={
            <SuspensedView>
              <IntrojsPage />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { SuperadminRoutes };
