import { IBaseProps } from '../base';
// tslint:disable-next-line: ordered-imports
import { options as globals, MbscCalendarSystem } from '../commons';
import { MbscDateType, MbscTimezonedDate, MbscTimezonePlugin } from './datetime.types.public';
import { isArray, isNumber, isObject, isString, isUndefined, pad, round, UNDEFINED } from './misc';
import { isBrowser } from './platform';
import { MbscRecurrenceRule } from './recurrence.types.public';

// tslint:disable no-non-null-assertion

// TODO: Add types and descriptions

export const REF_DATE = new Date(1970, 0, 1);
export const DAY_OF_MONTH = /^\d{1,2}(\/\d{1,2})?$/;
export const DAY_OF_WEEK = /^w\d$/i;
export const ONE_MIN = 60000;
export const ONE_HOUR = 60 * ONE_MIN;
export const ONE_DAY = 24 * ONE_HOUR;

export interface IValidateProps {
  /** If true the specified invalid will cover the whole day. */
  allDay?: boolean;
  /** Specifies the start of the invalid range. */
  start?: MbscDateType;
  /** Specifies the end of the invalid range. */
  end?: MbscDateType;
  /** Recurrence rule for recurring invalid ranges. */
  recurring?: MbscRecurrenceRule | string;
  /**
   * Specifies recurring exceptions.
   * Useful when specific dates need to be skipped from the rule.
   */
  recurringException?: MbscDateType[] | MbscDateType;
  /**
   * Specifies a recurrence exception rule.
   * Useful when recurring dates need to be skipped from the rule.
   */
  recurringExceptionRule?: MbscRecurrenceRule | string;
}

/** @hidden */
export interface IDatetimeProps extends IBaseProps {
  // #region Hidden options
  /** @hidden */
  allDayText?: string; // TODO: get rid of this
  /** @hidden */
  defaultValue?: any;
  /** @hidden */
  endDay?: number;
  /** @hidden */
  shortYearCutoff?: string | number;
  /** @hidden */
  showEventTooltip?: boolean; // TODO: get rid of this
  /** @hidden */
  startDay?: number;
  /** @hidden */
  getDate?: (y: number, m: number, d: number, h?: number, i?: number, s?: number, u?: number) => Date;
  /** @hidden */
  getDay?: (d: Date) => number;
  /** @hidden */
  getMaxDayOfMonth?: (y: number, m: number) => number;
  /** @hidden */
  getMonth?: (d: Date) => number;
  /** @hidden */
  getWeekNumber?: (d: Date) => number;
  /** @hidden */
  getYear?: (d: Date) => number;
  // #endregion Hidden options

  // #region Options

  /**
   * The timezone in which the data is interpreted. If the data contains timezone information
   * (when the ISO string has a timezone offset, e.g. `"2021-03-28T01:00:00Z"` or `"2021-03-28T03:00:00+03:00"`)
   * then the data's timezone is used instead.
   *
   * :::info
   * When using timezones, the [exclusiveEndDates](#opt-exclusiveEndDates) option is also turned on by default.
   * :::
   * :::info
   * When using anything other than the default (`'local'`), a [timezone plugin](#opt-timezonePlugin)
   * must be also passed to the component.
   * :::
   *
   * Possible values:
   * - `'local'` - The system's local timezone.
   * - `'utc'` - UTC (Universal Coordinated Time) timezone.
   * - Timezone name - The timezone name from the
   * [IANA time zone database](https://gist.github.com/aviflax/a4093965be1cd008f172), e.g. `"America/New_York"`.
   *
   * If not specified, it defaults to the [displayTimezone](#opt-displayTimezone).
   * @defaultValue undefined
   */
  dataTimezone?: 'local' | 'utc' | string;
  /**
   * The timezone in which the data is displayed.
   *
   * :::info
   * When using timezones, the [exclusiveEndDates](#opt-exclusiveEndDates) option is also turned on by default.
   * :::
   * :::info
   * When using anything other than the default (`'local'`), a [timezone plugin](#opt-timezonePlugin)
   * must be also passed to the component.
   * :::
   *
   * Possible values:
   * - `'local'` - The system's local timezone.
   * - `'utc'` - UTC (Universal Coordinated Time) timezone.
   * - Timezone name - The timezone name from the
   * [IANA time zone database](https://gist.github.com/aviflax/a4093965be1cd008f172), e.g. `"America/New_York"`.
   *
   * @defaultValue 'local'
   */
  displayTimezone?: 'local' | 'utc' | string;
  /**
   * If `true`, the picker will work in exclusive end dates mode,
   * meaning that the last moment of the range (selected value, colors, invalids, marked days etc.)
   * is not part of the range.
   *
   * For example, when selecting a date range without the time part,
   * selecting `'2021-07-20'` for the range end, the underlying value will return `'2021-07-21'` instead,
   * because the range ends on the 21st, not including the 21st.
   *
   * :::info
   * When using timezones, the `exclusiveEndDates` option will default to `true`.
   * :::
   *
   * @defaultValue false
   */
  exclusiveEndDates?: boolean;
  /**
   * An array containing the invalid values. Can contain dates,
   * or objects with the following properties:
   * - `allDay`: *boolean* - Specifies whether the invalid range is all day or not.
   * - `start`: *Date | string | object* - Start of the invalid range.
   * - `end`: *Date, string | object* - End of the invalid range.
   * - `recurring`: *string | object* - Recurrence rule for recurring invalid ranges.
   * - `recurringException`: *string | object | Array<string | object>* - Exception dates of the recurring rule.
   * Useful when specific dates need to be skipped from the rule.
   * - `recurringExceptionRule`: *string | object* - Exception rule of the recurring rule.
   * Useful when recurring dates need to be skipped from the rule.
   * - `resource`: *string | number | Array<string | number>* - Specifies the [resource](#opt-resources) ids for the invalid range.
   * The invalid range will be displayed only in the specified resource.
   * If there is no resource defined, the invalid range will be displayed in every resource.
   * - `slot`: *string | number* - Specifies the [slot](#opt-slots) id for the invalid range.
   * The invalid range will be displayed only in the specified slot.
   * If there is no slot defined, the invalid range will be displayed in every slot.
   * - `title`: *string* - Text which will be displayed for the invalid range. Only applicable for the timeline and scheduler views.
   *
   * :::info
   * The dates can be specified as JavaScript Date objects, ISO 8601 strings, or moment objects.
   * :::
   *
   * ```js
   * invalid: [
   *   // Passing exact dates and times
   *   new Date(2021, 1, 7), // Date object
   *   '2021-10-15T12:00', // ISO 8601 string
   *   moment('2020-12-25'), // moment object
   *
   *   // Passing invalid ranges
   *   {
   *     // ISO 8601 strings
   *     start: '2021-10-15T12:00',
   *     end: '2021-10-18T13:00',
   *     title: 'Company 10th anniversary',
   *   },
   *   {
   *     // Date objects
   *     allDay: true,
   *     start: new Date(2021, 2, 7),
   *     end: new Date(2021, 2, 9),
   *     title: 'Conference for the whole team',
   *   },
   *   {
   *     // Time range with recurrence
   *     start: '13:00',
   *     end: '12:00',
   *     recurring: { repeat: 'weekly', weekDays: 'MO,TU,WE,TH,FR' },
   *     title: 'Lunch break',
   *   },
   *   {
   *     // Disable weekends
   *     recurring: {
   *       repeat: 'weekly',
   *       weekDays: 'SA,SU'
   *     }
   *   }
   * ];
   * ```
   *
   * @defaultValue undefined
   * @group Options_calendarview
   * @group Options_scheduler
   * @group Options_timeline
   * @group Properties
   */
  invalid?: MbscDateType[] | IValidateProps[];
  /**
   * Maximum value that can be selected.
   * @defaultValue undefined
   */
  max?: MbscDateType;
  /**
   * Minimum value that can be selected.
   * @defaultValue undefined
   */
  min?: MbscDateType;
  /**
   * Reference to the Moment.js library. Needed when using Moment objects as [return values](#opt-returnFormat).
   *
   * @defaultValue undefined
   */
  moment?: any;
  /**
   * Specifies the format in which the selected dates are returned.
   * - `'jsdate'` - JavaScript date object.
   * - `'iso8601'` - ISO 8601 date string.
   * - `'locale'` - Formatted date string based on the locale option,
   * or the [dateFormat](#localization-dateFormat) and [timeFormat](#localization-timeFormat) options, if they are specified.
   * - `'moment'` - Moment object.
   * Ensure that [moment.js](https://momentjs.com/) is loaded and passed through the [moment](#opt-moment) option.
   *
   * :::info
   * When using a [timezone plugin](#opt-timezonePlugin),
   * the returned values are always in 'iso8601' format and this option is not taken into account.
   * :::
   *
   * @defaultValue 'jsdate'
   */
  returnFormat?: 'jsdate' | 'iso8601' | 'locale' | 'moment';
  /**
   * Separator between date and time in the formatted date string.
   * @defaultValue ' '
   */
  separator?: string;
  /**
   * Specifies the timezone plugin, which can handle the timezone conversions.
   *
   * By default the component uses the local timezone of the browser to interpret dates.
   * If you want to interpret dates a different timezone,
   * you will need an external library to handle the timezone conversions.
   * There are two supported libraries: [moment-timezone](https://momentjs.com/timezone/)
   * and [luxon](https://moment.github.io/luxon/#/).
   *
   * You can specify either the [dataTimezone](#opt-dataTimezone) or the [displayTimezone](#opt-displayTimezone) or both.
   *
   * Depending on which external library you use you can pass either the `momentTimezone` or `luxonTimezone`
   * objects. These objects can be imported from the mobiscroll bundle.
   *
   * @defaultValue undefined
   */
  timezonePlugin?: MbscTimezonePlugin;
  /**
   * An array containing the valid values. Use it when it's more convenient to specify valid values instead of the invalid ones.
   * If specified, everything else is considered to be invalid, and the [invalid](#opt-invalid) option will be ignored.
   *
   * Can contain dates, or objects with the following properties:
   * - `allDay`: *boolean* - Specifies whether the valid range is all day or not.
   * - `start`: *Date | string | object* - Start of the valid range.
   * - `end`: *Date, string | object* - End of the valid range.
   * - `recurring`: *string | object* - Recurrence rule for recurring valid ranges.
   * - `recurringException`: *string | object | Array<string | object>* - Exception dates of the recurring rule.
   * Useful when specific dates need to be skipped from the rule.
   * - `recurringExceptionRule`: *string | object* - Exception rule of the recurring rule.
   * Useful when recurring dates need to be skipped from the rule.
   *
   * :::info
   * The dates can be specified as JavaScript Date objects, ISO 8601 strings, or moment objects.
   * :::
   *
   * ```js
   * valid: [
   *   // Passing exact dates and times
   *   new Date(2021, 1, 7), // Date object
   *   '2021-10-15T12:00', // ISO 8601 string
   *   moment('2020-12-25'), // moment object
   *
   *   // Passing invalid ranges
   *   {
   *     // ISO 8601 strings
   *     start: '2021-10-15T12:00',
   *     end: '2021-10-18T13:00',
   *     title: 'Company 10th anniversary',
   *   },
   *   {
   *     // Date objects
   *     allDay: true,
   *     start: new Date(2021, 2, 7),
   *     end: new Date(2021, 2, 9),
   *     title: 'Conference for the whole team',
   *   },
   *   {
   *     // Time range with recurrence
   *     start: '13:00',
   *     end: '12:00',
   *     recurring: { repeat: 'weekly', weekDays: 'MO,TU,WE,TH,FR' },
   *     title: 'Lunch break',
   *   },
   *   {
   *     // Disable weekends
   *     recurring: {
   *       repeat: 'weekly',
   *       weekDays: 'SA,SU'
   *     }
   *   }
   * ];
   * ```
   *
   * @defaultValue undefined
   * @group Options_calendarview
   * @group Options_scheduler
   * @group Options_timeline
   * @group Properties
   */
  valid?: MbscDateType[] | IValidateProps[];

  // #endregion Options

  // #region Localization

  /**
   * Text for AM.
   * @defaultValue 'am'
   * @group Localizations
   */
  amText?: string;
  /**
   * Specifies the calendar system to be used. Supported calendars:
   * - Gregorian - Gregorian calendar. This is the default calendar system.
   * - Jalali - Persian calendar. The Farsi language needs to be included to the package.
   * - Hijri - Hijri calendar. The Arabic language needs to be included to the package
   * @defaultValue undefined
   * @group Localizations
   */
  calendarSystem?: MbscCalendarSystem;
  /**
   * The format for parsed and displayed dates:
   * - `M` - month of year (no leading zero)
   * - `MM` - month of year (two digit)
   * - `MMM` - month name short
   * - `MMMM` - month name long
   * - `D` - day of month (no leading zero)
   * - `DD` - day of month (two digit)
   * - `DDD` - day of week (short)
   * - `DDDD` - day of week (long)
   * - `YY` - year (two digit)
   * - `YYYY` - year (four digit)
   * - `'...'` - literal text
   * - `''` - single quote
   * - anything else - literal text
   *
   * @defaultValue 'MM/DD/YYYY'
   * @group Localizations
   */
  dateFormat?: string;
  /**
   * Human readable date format, used by screen readers to read out full dates.
   * Characters have the same meaning as in the [dateFormat](#localization-dateFormat) option.
   * @defaultValue 'DDDD, MMMM D, YYYY'
   * @group Localizations
   */
  dateFormatFull?: string;
  /**
   * Long date format, used by the agenda view and timeline day headers.
   * Characters have the same meaning as in the [dateFormat](#localization-dateFormat) option.
   * @defaultValue 'D DDD MMM YYYY'
   * @group Localizations
   * @group Localizations_agenda
   * @group Localizations_scheduler
   * @group Localizations_timeline
   */
  dateFormatLong?: string;
  /**
   * The list of long day names, starting from Sunday.
   * @defaultValue ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
   * @group Localizations
   */
  dayNames?: string[];
  /**
   * The list of minimal day names, starting from Sunday.
   * @defaultValue ['S', 'M', 'T', 'W', 'T', 'F', 'S']
   * @group Localizations
   */
  dayNamesMin?: string[];
  /**
   * The list of abbreviated day names, starting from Sunday.
   * @defaultValue ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
   * @group Localizations
   */
  dayNamesShort?: string[];
  /**
   * Additional string to display after the day on the wheel.
   * @defaultValue undefined
   * @group Localizations
   */
  daySuffix?: string;
  /**
   * Set the first day of the week: Sunday is 0, Monday is 1, etc.
   * @defaultValue 0
   * @group Localizations
   */
  firstDay?: number;
  /** @hidden */
  fromText?: string;
  /**
   * The list of full month names.
   * @defaultValue
   * ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
   * @group Localizations
   */
  monthNames?: string[];
  /**
   * The list of abbreviated month names.
   * @defaultValue ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
   * @group Localizations
   */
  monthNamesShort?: string[];
  /**
   * Additional string to display after the month on the wheel.
   * @defaultValue undefined
   * @group Localizations
   */
  monthSuffix?: string;
  /**
   * Text for PM.
   * @defaultValue 'pm'
   * @group Localizations
   */
  pmText?: string;
  /**
   * Text for quarter numbers in the timeline header. The {count} inside the string will be replaced with the number of the current quarter.
   * @defaultValue 'Q {count}'
   * @group Localizations
   * @group Localizations_timeline
   */
  quarterText?: string;
  /**
   * The format for parsed and displayed times:
   * - `h` - 12 hour format (no leading zero)
   * - `hh` - 12 hour format (leading zero)
   * - `H` - 24 hour format (no leading zero)
   * - `HH` - 24 hour format (leading zero)
   * - `m` - minutes (no leading zero)
   * - `mm` - minutes (leading zero)
   * - `s` - seconds (no leading zero)
   * - `ss` - seconds (leading zero)
   * - `a` - lowercase am/pm
   * - `A` - uppercase AM/PM
   * - `'...'` - literal text
   * - `''` - single quote
   * - anything else - literal text
   *
   * @defaultValue 'hh:mm A'
   * @group Localizations
   */
  timeFormat?: string;
  /**
   * Text for the "Today" button.
   * @defaultValue 'Today'
   * @group Localizations
   */
  todayText?: string;
  /** @hidden */
  toText?: string;
  /**
   * Additional string to display after the year on the wheel.
   * @defaultValue undefined
   * @group Localizations
   */
  yearSuffix?: string;
  /**
   * Text for week numbers in the timeline header. The {count} inside the string will be replaced with the number of the current week.
   * @defaultValue 'Week {count}'
   * @group Localizations
   * @group Localizations_timeline
   */
  weekText?: string;

  // #endregion Localization
}

/**
 * Returns if a date object is a pseudo-date object
 * Pseudo-date objects are our implementation of a Date interface
 */
export function isMBSCDate(d: any): d is MbscTimezonedDate {
  return !!d._mbsc;
}

/**
 * Returns an ISO8601 date string in data timezone, if it's a date with timezone, otherwise the original date.
 * @param d The date to check.
 * @param s Options object containing timezone options.
 * @param tz Explicit timezone, if specified
 */
export function convertTimezone(d: MbscDateType, s: IDatetimeProps, tz?: string): MbscDateType {
  const timezone = tz || s.dataTimezone || s.displayTimezone;
  const timezonePlugin = s.timezonePlugin;
  if (timezone && timezonePlugin && isMBSCDate(d)) {
    const clone = d.clone();
    clone.setTimezone(timezone);
    return clone.toISOString();
  }
  return d;
}

/** @hidden */
export const dateTimeLocale: IDatetimeProps = {
  amText: 'am',
  dateFormat: 'MM/DD/YYYY',
  dateFormatFull: 'DDDD, MMMM D, YYYY',
  dateFormatLong: 'D DDD MMM YYYY',
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  daySuffix: '',
  firstDay: 0,
  fromText: 'Start',
  getDate: adjustedDate,
  monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  monthSuffix: '',
  pmText: 'pm',
  quarterText: 'Q{count}',
  separator: ' ',
  shortYearCutoff: '+10',
  timeFormat: 'h:mm A',
  toText: 'End',
  todayText: 'Today',
  weekText: 'Week {count}',
  yearSuffix: '',
  getMonth(d: Date) {
    return d.getMonth();
  },
  getDay(d: Date) {
    return d.getDate();
  },
  getYear(d: Date) {
    return d.getFullYear();
  },
  getMaxDayOfMonth(y: number, m: number) {
    return 32 - new Date(y, m, 32, 12).getDate();
  },
  getWeekNumber(dt: Date) {
    // Copy date so don't modify original
    const d: any = new Date(+dt);
    d.setHours(0, 0, 0);
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    // Get first day of year
    const yearStart: any = new Date(d.getFullYear(), 0, 1);
    // Calculate full weeks to nearest Thursday
    return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  },
};

// tslint:disable-next-line max-line-length
export const ISO_8601_FULL =
  /^(\d{4}|[+-]\d{6})(?:-?(\d{2})(?:-?(\d{2}))?)?(?:[T\s](\d{2}):?(\d{2})(?::?(\d{2})(?:\.(\d{3}))?)?((Z)|([+-])(\d{2})(?::?(\d{2}))?)?)?$/;
export const ISO_8601_TIME = /^((\d{2}):(\d{2})(?::(\d{2})(?:\.(\d{3}))?)?(?:(Z)|([+-])(\d{2})(?::?(\d{2}))?)?)?$/;

/** @hidden */
function setISOParts(parsed: any, offset: any, parts: any) {
  let part: any;
  let v: any;
  const p: any = { y: 1, m: 2, d: 3, h: 4, i: 5, s: 6, u: 7, tz: 8 };

  if (parts) {
    for (part of Object.keys(p)) {
      v = parsed[p[part] - offset];
      if (v) {
        parts[part] = part === 'tz' ? v : 1;
      }
    }
  }
}
/** @hidden */
function getISOString(d: Date, parts: any) {
  let ret = '';
  let time = '';

  if (d) {
    if (parts.h) {
      time += pad(d.getHours()) + ':' + pad(d.getMinutes());

      if (parts.s) {
        time += ':' + pad(d.getSeconds());
      }

      if (parts.u) {
        time += '.' + pad(d.getMilliseconds(), 3);
      }

      if (parts.tz) {
        time += parts.tz; // Just put what we got
      }
    }

    if (parts.y) {
      ret += d.getFullYear();

      if (parts.m) {
        ret += '-' + pad(d.getMonth() + 1);

        if (parts.d) {
          ret += '-' + pad(d.getDate());
        }

        if (parts.h) {
          ret += 'T' + time;
        }
      }
    } else if (parts.h) {
      ret = time;
    }
  }
  return ret;
}

/**
 * Returns the milliseconds of a date since midnight.
 * @hidden
 * @param d The date.
 */
export function getDayMilliseconds(d: Date): number {
  // We need to use a date where we don't have DST change
  const dd = new Date(1970, 0, 1, d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds());
  return +dd - +REF_DATE;
}

/**
 * Checks if two date ranges are overlapping each other
 * @hidden
 * @param start1 start date of the first range
 * @param end1 end date of the first range
 * @param start2 start date of the second range
 * @param end2 end date of the second range
 * @param adjust if true, 0 length range will be modified to 1ms
 * @returns true if there is overlap false otherwise
 */
export function checkDateRangeOverlap(start1: Date, end1: Date, start2: Date, end2: Date, adjust?: boolean): boolean {
  const aStart = +start1;
  const bStart = +start2;
  const aEnd = adjust && aStart === +end1 ? +end1 + 1 : +end1;
  const bEnd = adjust && bStart === +end2 ? +end2 + 1 : +end2;
  return aStart < bEnd && aEnd > bStart;
}

/**
 * Returns the starting point of a day in display timezone
 * @param s
 * @param d
 * @returns
 */
export function getDayStart(s: IDatetimeProps, d: Date) {
  const newDate = createDate(s, d);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

/**
 * Returns the last point of a day in display timezone
 * @param s
 * @param d
 * @returns
 */
export function getDayEnd(s: IDatetimeProps, d: Date) {
  const newDate = createDate(s, d);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
}

/** @hidden */
export function getEndDate(s: IDatetimeProps, allDay: boolean | undefined, start: Date, end: Date, isList?: boolean): Date {
  const exclusive = allDay || isList ? s.exclusiveEndDates : true;
  const tzOpt = allDay ? UNDEFINED : s;
  return exclusive && start && end && start < end ? createDate(tzOpt, +end - 1) : end;
}

/** @hidden */
export function getDateStr(d: Date): string {
  return d.getFullYear() + '-' + pad(d.getMonth() + 1) + '-' + pad(d.getDate());
}

/** @hidden */
export function getDateOnly(d: Date, nativeDate?: boolean): Date {
  if (isMBSCDate(d) && !nativeDate) {
    return d.createDate(d.getFullYear(), d.getMonth(), d.getDate());
  } else {
    return adjustedDate(d.getFullYear(), d.getMonth(), d.getDate());
  }
}

/** @hidden */
export function getUTCDateOnly(d: Date): number {
  return Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
}

/**
 * Returns the difference in days for two dates
 * @hidden
 * @param d1 First date
 * @param d2 Second date
 * @returns Difference in days
 */
export function getDayDiff(d1: Date, d2: Date): number {
  return round((getUTCDateOnly(d2) - getUTCDateOnly(d1)) / ONE_DAY);
}

/**
 * Returns the number of days between two dates if there are missing days between them
 * @hidden
 */
export function getGridDayDiff(from: Date, to: Date, startDay: number, endDay: number): number {
  let dayIndex = -1;
  for (const d = getDateOnly(from); d <= getDateOnly(to); d.setDate(d.getDate() + 1)) {
    if (isInWeek(d.getDay(), startDay, endDay)) {
      dayIndex++;
    }
  }
  return dayIndex;
}

/**
 * Returns the date of the first day of the week for a given date
 * @hidden
 */
export function getFirstDayOfWeek(d: Date, s: any, w?: number): Date {
  const y = d.getFullYear();
  const m = d.getMonth();
  const weekDay = d.getDay();
  const firstWeekDay = w === UNDEFINED ? s.firstDay : w;
  const offset = firstWeekDay - weekDay > 0 ? 7 : 0;
  return new Date(y, m, firstWeekDay - offset - weekDay + d.getDate());
}

/**
 * Checks if two dates are on the same date
 * @hidden
 * @param d1 First date
 * @param d2 Second date
 * @returns True or false
 */
export function isSameDay(d1: Date, d2: Date): boolean {
  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

/**
 * Checks if two dates ranges are on the same range
 * @hidden
 * @param d1 First date of first range
 * @param d2 Second date of first range
 * @param d3 First date of second range
 * @param d4 Second date of second range
 * @returns True or false
 */
export function isSameDateRange(d1: Date, d2: Date, d3: Date, d4: Date) {
  return isSameDay(d1, d3) && isSameDay(d2, d4);
}

/**
 * Check if 2 dates are in the same month (depends on the calendar system).
 * @param d1 First date.
 * @param d2 Second date.
 * @param s Settings containing the calendar system functions.
 */
export function isSameMonth(d1: Date, d2: Date, s: IDatetimeProps) {
  return s.getYear!(d1) === s.getYear!(d2) && s.getMonth!(d1) === s.getMonth!(d2);
}

/** @hidden */
export function adjustedDate(y: number, m: number, d: number, h?: number, i?: number, s?: number, u?: number) {
  const date = new Date(y, m, d, h || 0, i || 0, s || 0, u || 0);

  if (date.getHours() === 23 && (h || 0) === 0) {
    date.setHours(date.getHours() + 2);
  }

  return date;
}

export function isDate(d: any): d is Date {
  return d.getTime;
}

export function isTime(d: any): boolean {
  return isString(d) && ISO_8601_TIME.test(d);
}

/**
 * When a timezone plugin is specified, return a date with the same parts as the passed date (year, month, day, hour)
 * only with a timezone specified to display timezone
 * Otherwise it returns the same thing in the local timezone
 * @param s Settings object
 * @param d Date object
 * @returns
 */
export function addTimezone(s: any, d: Date) {
  return createDate(s, d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds());
}

/**
 * Returns a local date with the same year/month day/hours/minutes... as the original date in the parameter
 * It does not convert to any timezone, it just takes the date/hour/minute and creates a new local date from that
 * @param d Date with or without timezone data or null/undefined
 * @returns A new local Date object or undefined/null when nothing is pass as param
 */
export function removeTimezone(d: Date) {
  if (!d) {
    return d;
  } else {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds());
  }
}
/**
 * Creates a new date object, depending on the parameters.
 * Will return a native Date object or a Mobiscroll Date object depending on what timezone plugin is specified in
 * the Settings object.
 * Can be passed another datetime to initialize from, or can be passed individual date and time parameters
 * @param s Mobiscroll Settings object
 * @param yearOrStamp The year or the other date string/timestamp/object
 * @param month The month
 * @param date The date of month
 * @param hour The Hour
 * @param min The Minute
 * @param sec The Second
 * @param ms The Millisecond
 * @returns
 */
export function createDate(s: any, value?: number | string | Date | MbscTimezonedDate): Date;
export function createDate(s: any, year: number, month: number, date: number, hour?: number, min?: number, sec?: number, ms?: number): Date;
export function createDate(
  s: any,
  yearOrStamp?: number | string | Date | MbscTimezonedDate,
  month?: number,
  date?: number,
  h?: number,
  min?: number,
  sec?: number,
  ms?: number,
): Date | null {
  if (yearOrStamp === null) {
    return null;
  }
  if (yearOrStamp && (isNumber(yearOrStamp) || isString(yearOrStamp)) && isUndefined(month)) {
    return makeDate(yearOrStamp, s);
  }
  if (s && s.timezonePlugin) {
    return (s.timezonePlugin as MbscTimezonePlugin).createDate(s, yearOrStamp, month, date, h, min, sec, ms);
  }
  if (isObject(yearOrStamp)) {
    return new Date(yearOrStamp);
  }
  if (isUndefined(yearOrStamp)) {
    return new Date();
  }
  return new Date(yearOrStamp as number, month || 0, date || 1, h || 0, min || 0, sec || 0, ms || 0);
}

/** @hidden */
// this should return a Date type or null, but it's fucking hard to make this work, so I give up
// re: nice comment, but tslint gave an error about the line length, so I moved it above the function (@dioslaska).
export function makeDate(d: any, s?: any, format?: any, parts?: any, skipTimezone?: boolean): any {
  let parse: any;

  if (isString(d)) {
    d = d.trim();
  }

  if (!d) {
    return null;
  }

  const plugin: MbscTimezonePlugin = s && s.timezonePlugin;

  if (plugin && !skipTimezone) {
    const parsedDate = isMBSCDate(d) ? d : plugin.parse(d, s);
    parsedDate.setTimezone(s.displayTimezone);
    return parsedDate;
  }

  // If already date object
  if (isDate(d)) {
    return d;
  }

  // Moment object
  if (d._isAMomentObject) {
    return d.toDate();
  }

  // timestamp
  if (isNumber(d)) {
    return new Date(d);
  }

  parse = ISO_8601_TIME.exec(d);

  const defVal = s && s.defaultValue;
  const def: Date = makeDate((isArray(defVal) ? defVal[0] : defVal) || new Date());
  const defYear = def.getFullYear();
  const defMonth = def.getMonth();
  const defDay = def.getDate();

  // If ISO 8601 time string
  if (parse) {
    setISOParts(parse, 2, parts);
    return new Date(
      defYear,
      defMonth,
      defDay,
      parse[2] ? +parse[2] : 0,
      parse[3] ? +parse[3] : 0,
      parse[4] ? +parse[4] : 0,
      parse[5] ? +parse[5] : 0,
    );
  }

  parse = ISO_8601_FULL.exec(d);

  // If ISO 8601 date string
  if (parse) {
    setISOParts(parse, 0, parts);
    return new Date(
      parse[1] ? +parse[1] : defYear,
      parse[2] ? parse[2] - 1 : defMonth,
      parse[3] ? +parse[3] : defDay,
      parse[4] ? +parse[4] : 0,
      parse[5] ? +parse[5] : 0,
      parse[6] ? +parse[6] : 0,
      parse[7] ? +parse[7] : 0,
    );
  }

  // Parse date based on format
  return parseDate(format, d, s);
}

/** @hidden */
export function returnDate(d: Date, s: IDatetimeProps, displayFormat: string, isoParts: any, hasTimePart: boolean) {
  const moment = (isBrowser && (window as any).moment) || s.moment;
  const timezone = s.timezonePlugin && (s.dataTimezone || s.displayTimezone);
  const format = timezone ? 'iso8601' : s.returnFormat;

  if (timezone && hasTimePart) {
    return convertTimezone(d, s);
  }

  if (d) {
    if (format === 'moment' && moment) {
      return moment(d);
    }

    if (format === 'locale') {
      return formatDate(displayFormat, d, s);
    }

    if (format === 'iso8601') {
      return getISOString(d, isoParts);
    }
  }

  return d;
}

/**
 * Format a date into a string value with a specified format.
 * @param {string} format - Output format.
 * @param {Date} date - Date to format.
 * @param {IDatetimeProps} options - Locale options.
 * @returns {string} The formatted date string.
 */
export function formatDatePublic(format: string, date: Date, options?: IDatetimeProps): string {
  const s = { ...dateTimeLocale, ...globals.locale, ...options };
  return formatDate(format, date, s);
}

/**
 * Format a date into a string value with a specified format.
 * This is for inner usage, and it's faster than the one above, because it skips the option merge.
 * @param {string} format - Output format.
 * @param {Date} date - Date to format.
 * @param {IDatetimeProps} options - Locale options.
 * @returns {string} The formatted date string.
 */
export function formatDate(format: string, date: Date, s: IDatetimeProps): string {
  let i: number;
  let year: number;
  let output = '';
  let literal = false;
  let c: number;

  // Counts how many times a symbol is repeated (0 if not repeated, 1 if its doubled, etc...)
  const peekAhead = (symbol: string): number => {
    let nr = 0;
    let j = i;
    while (j + 1 < format.length && format.charAt(j + 1) === symbol) {
      nr++;
      j++;
    }
    return nr;
  };
  // Check whether a format character is doubled
  const lookAhead = (symbol: string): number => {
    const nr = peekAhead(symbol);
    i += nr;
    return nr;
  };
  // Format a number, with leading zero if necessary
  const formatNumber = (symbol: string, val: number, len: number): string => {
    let ret = '' + val;
    if (lookAhead(symbol)) {
      while (ret.length < len) {
        ret = '0' + ret;
      }
    }
    return ret;
  };
  // Format a name, short or long as requested
  const formatName = (symbol: string, val: number, short: string[], long: string[]) => {
    return lookAhead(symbol) === 3 ? long[val] : short[val];
  };

  for (i = 0; i < format.length; i++) {
    if (literal) {
      if (format.charAt(i) === "'" && !lookAhead("'")) {
        literal = false;
      } else {
        output += format.charAt(i);
      }
    } else {
      switch (format.charAt(i)) {
        case 'D':
          c = peekAhead('D');
          if (c > 1) {
            output += formatName('D', date.getDay(), s.dayNamesShort!, s.dayNames!);
          } else {
            output += formatNumber('D', s.getDay!(date), 2);
          }
          break;
        case 'M':
          c = peekAhead('M');
          if (c > 1) {
            output += formatName('M', s.getMonth!(date), s.monthNamesShort!, s.monthNames!);
          } else {
            output += formatNumber('M', s.getMonth!(date) + 1, 2);
          }
          break;
        case 'Y':
          year = s.getYear!(date);
          output += lookAhead('Y') === 3 ? year : (year % 100 < 10 ? '0' : '') + (year % 100);
          break;
        case 'h': {
          const h = date.getHours();
          output += formatNumber('h', h > 12 ? h - 12 : h === 0 ? 12 : h, 2);
          break;
        }
        case 'H':
          output += formatNumber('H', date.getHours(), 2);
          break;
        case 'm':
          output += formatNumber('m', date.getMinutes(), 2);
          break;
        case 's':
          output += formatNumber('s', date.getSeconds(), 2);
          break;
        case 'a':
          output += date.getHours() > 11 ? s.pmText : s.amText;
          break;
        case 'A':
          output += date.getHours() > 11 ? s.pmText!.toUpperCase() : s.amText!.toUpperCase();
          break;
        case "'":
          if (lookAhead("'")) {
            output += "'";
          } else {
            literal = true;
          }
          break;
        default:
          output += format.charAt(i);
      }
    }
  }
  return output;
}

/**
 * Extract a date from a string value with a specified format.
 * @param {string} format Input format.
 * @param {string} value String to parse.
 * @param {IDatetimeProps} options Locale options
 * @return {Date} Returns the extracted date or defaults to now if no format or no value is given
 */
export function parseDate(format: string, value: string, options: IDatetimeProps): Date {
  const s = { ...dateTimeLocale, ...options };
  const def: Date = makeDate(s.defaultValue || new Date());

  if (!value) {
    return def;
  }

  if (!format) {
    format = s.dateFormat! + s.separator! + s.timeFormat!;
  }

  const shortYearCutoff = s.shortYearCutoff!;
  let year = s.getYear!(def);
  let month = s.getMonth!(def) + 1;
  // let doy = -1,
  let day = s.getDay!(def);
  let hours = def.getHours();
  let minutes = def.getMinutes();
  let seconds = 0; // def.getSeconds()
  let ampm = -1;
  let literal = false;
  let iValue = 0;
  let iFormat: number;

  /**
   * Counts how many times a symbol is repeated (0 if not repeated, 1 if its doubled, etc...)
   * without moving the index forward
   */
  const peekAhead = (symbol: string): number => {
    let nr = 0;
    let j = iFormat;
    while (j + 1 < format.length && format.charAt(j + 1) === symbol) {
      nr++;
      j++;
    }
    return nr;
  };

  /**
   * Check whether a format character is doubled
   * Check how many times a format character is repeated. Also move the index forward.
   */
  const lookAhead = (match: string) => {
    const matches = peekAhead(match);
    iFormat += matches;
    return matches;
  };

  /**
   * Extract a number from the string value
   * @param {string} match The current symbol in the format string
   * @returns {number} The extracted number
   */
  const getNumber = (match: string): number => {
    const count = lookAhead(match);
    const size = count >= 2 ? 4 : 2;
    const digits = new RegExp('^\\d{1,' + size + '}');
    const num = value.substr(iValue).match(digits);

    if (!num) {
      return 0;
    }

    iValue += num[0].length;
    return parseInt(num[0], 10);
  };

  /**
   * Extracts a name from the string value and converts to an index
   * @param {string} match The symbol we are looking at in the format string
   * @param {Array<string>} shortNames Short names array
   * @param {Array<string>} longNames Long names array
   * @returns {number} Returns the index + 1 of the name in the names array if found, 0 otherwise
   */
  const getName = (match: string, shortNames: string[], longNames: string[]): number => {
    const count = lookAhead(match);
    const names = count === 3 ? longNames : shortNames;

    for (let i = 0; i < names.length; i++) {
      if (value.substr(iValue, names[i].length).toLowerCase() === names[i].toLowerCase()) {
        iValue += names[i].length;
        return i + 1;
      }
    }
    return 0;
  };
  const checkLiteral = () => {
    iValue++;
  };

  for (iFormat = 0; iFormat < format.length; iFormat++) {
    if (literal) {
      if (format.charAt(iFormat) === "'" && !lookAhead("'")) {
        literal = false;
      } else {
        checkLiteral();
      }
    } else {
      switch (format.charAt(iFormat)) {
        case 'Y':
          year = getNumber('Y');
          break;
        case 'M': {
          const p = peekAhead('M');
          if (p < 2) {
            month = getNumber('M');
          } else {
            month = getName('M', s.monthNamesShort!, s.monthNames!);
          }
          break;
        }
        case 'D': {
          const p = peekAhead('D');
          if (p < 2) {
            day = getNumber('D');
          } else {
            getName('D', s.dayNamesShort!, s.dayNames!);
          }
          break;
        }
        case 'H':
          hours = getNumber('H');
          break;
        case 'h':
          hours = getNumber('h');
          break;
        case 'm':
          minutes = getNumber('m');
          break;
        case 's':
          seconds = getNumber('s');
          break;
        case 'a':
          ampm = getName('a', [s.amText!, s.pmText!], [s.amText!, s.pmText!]) - 1;
          break;
        case 'A':
          ampm = getName('A', [s.amText!, s.pmText!], [s.amText!, s.pmText!]) - 1;
          break;
        case "'":
          if (lookAhead("'")) {
            checkLiteral();
          } else {
            literal = true;
          }
          break;
        default:
          checkLiteral();
      }
    }
  }

  if (year < 100) {
    let cutoffYear: number;
    // Cut off year setting supports string and number. When string, it is considered relative to the current year,
    // otherwise it is the year number in the current century
    if (!isString(shortYearCutoff)) {
      cutoffYear = +shortYearCutoff;
    } else {
      cutoffYear = (new Date().getFullYear() % 100) + parseInt(shortYearCutoff, 10);
    }
    let addedCentury: number;
    if (year <= cutoffYear) {
      addedCentury = 0;
    } else {
      addedCentury = -100;
    }
    year += new Date().getFullYear() - (new Date().getFullYear() % 100) + addedCentury;
  }

  hours = ampm === -1 ? hours : ampm && hours < 12 ? hours + 12 : !ampm && hours === 12 ? 0 : hours;

  const date = s.getDate!(year, month - 1, day, hours, minutes, seconds);

  if (s.getYear!(date) !== year || s.getMonth!(date) + 1 !== month || s.getDay!(date) !== day) {
    return def; // Invalid date
  }

  return date;
}

/** Value Equality function for Date and Array of Date types
 * Checks if two dates or two array of dates are the same.
 * NOTE: empty Arrays are treated the same way as null values because,
 * when parsing a null value, the returned value representation is an empty object (datepicker),
 * which when turned back, won't be null, but rather an empty array
 */
export function dateValueEquals(v1: any, v2: any, s: IDatetimeProps): boolean {
  // null | MbscDateType | MbscDateType[]
  if (v1 === v2) {
    // shortcut for reference equality
    return true;
  }

  // Empty Arrays are treated the same way as null values
  if ((isArray(v1) && !v1.length && v2 === null) || (isArray(v2) && !v2.length && v1 === null)) {
    return true;
  }

  if (v1 === null || v2 === null || v1 === UNDEFINED || v2 === UNDEFINED) {
    return false;
  }
  // compare strings
  if (isString(v1) && isString(v2)) {
    // shortcut for strings
    return v1 === v2;
  }

  const dateFormat = s && s.dateFormat;

  // if one of them is an array compare each items
  if (isArray(v1) || isArray(v2)) {
    if (v1.length !== v2.length) {
      // if one of them is not an array, or the lengths are not the same
      return false;
    }
    for (let i = 0; i < v1.length; i++) {
      let eq = true;
      const a = v1[i];
      const b = v2[i];
      if (isString(a) && isString(b)) {
        eq = a === b;
      } else {
        eq = +makeDate(a, s, dateFormat) === +makeDate(b, s, dateFormat);
      }
      if (!eq) {
        return false;
      }
    }
    return true;
  }

  return +makeDate(v1, s, dateFormat) === +makeDate(v2, s, dateFormat);
}

/**
 * Clones a date object (native or custom mbsc date).
 * @param date The date to clone.
 */
export function cloneDate(date: Date): Date {
  return isMBSCDate(date) ? date.clone() : new Date(date);
}

/**
 * Adds the specified number of days to a date. Returns a new date object.
 * @param date The date.
 * @param days Days to add.
 */
export function addDays(date: Date, days: number): Date {
  const copy = cloneDate(date);
  copy.setDate(copy.getDate() + days);
  return copy;
}

/**
 * Adds the specified number of days to a date. Returns a new date object.
 * @param date The date.
 * @param months Days to add.
 * @param s
 */
export function addMonths(date: Date, months: number, s: IDatetimeProps): Date {
  const year = s.getYear!(date);
  const month = s.getMonth!(date) + months;
  const maxDays = s.getMaxDayOfMonth!(year, month);
  return addTimezone(
    s,
    s.getDate!(
      year,
      month,
      Math.min(s.getDay!(date), maxDays),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
  );
}

/**
 * Check if a day is inside the visible week days.
 * @param day Weekday to check.
 * @param startDay Start day of the week.
 * @param endDay End day of the week.
 */
export function isInWeek(day: number, startDay: number, endDay: number) {
  return startDay > endDay ? day <= endDay || day >= startDay : day >= startDay && day <= endDay;
}

/**
 * Rounds a date to the specified minute step.
 * @param date The date to round.
 * @param step Step specified as minutes.
 */
export function roundTime(date: Date, step: number): Date {
  const ms = ONE_MIN * step;
  const copy = cloneDate(date).setHours(0, 0, 0, 0);
  const rounded = copy + Math.round((+date - +copy) / ms) * ms;
  return isMBSCDate(date) ? date.createDate(rounded) : new Date(rounded);
}

/** Constrains a date to min and max */
export function constrainDate(date: Date, min?: Date, max?: Date) {
  return min && date < min ? new Date(min) : max && date > max ? new Date(max) : date;
}

// Symbol dummy for IE11
if (isBrowser && typeof Symbol === 'undefined') {
  (window as any).Symbol = {
    toPrimitive: 'toPrimitive',
  };
}

export const datetime = {
  formatDate: formatDatePublic,
  parseDate,
};

// For backward compatibility, remove in Mobiscroll 6
export type DateType = MbscDateType;
export type IDate = MbscTimezonedDate;
export type ITimezonePlugin = MbscTimezonePlugin;
