// @ts-nocheck
import { Column } from "react-table";
import { ApiLogCustomHeader } from "./CustomHeader";
import { ApiLog } from "../../../core/_model";
import { ApiLogInfoCell } from "./InfoCell";
import { ApiLogInfoApiCell } from "./InfoApiCell";
import { ApiLogSuccessCell } from "./SuccessCell";
import { ApiLogDateCell } from "./DateCell";

const ApiLogColumns: ReadonlyArray<Column<ApiLog>> = [
  {
    Header: (props) => (
      <ApiLogCustomHeader
        tableProps={props}
        title="Status"
        className="min-w-125px"
      />
    ),
     id: 'resoponse_status',
    Cell: ({...props}) => <ApiLogSuccessCell user={props.data[props.row.index]}/>,
  },
  {
    Header: (props) => (
      <ApiLogCustomHeader
        tableProps={props}
        title="API"
        className="min-w-125px"
      />
    ),
     id: 'type',
    Cell: ({...props}) => <ApiLogInfoApiCell user={props.data[props.row.index]}/>,
  },
  {
    Header: (props) => (
      <ApiLogCustomHeader
        tableProps={props}
        title="PATH"
        className="min-w-125px"
      />
    ),
     id: 'autotask_entiry',
    Cell: ({...props}) => <ApiLogInfoCell user={props.data[props.row.index]}/>,
  },
  {
    Header: (props) => (
      <ApiLogCustomHeader
        tableProps={props}
        title="Created At"
        className="min-w-125px"
      />
    ),
     id: 'created_at',
    Cell: ({...props}) => <ApiLogDateCell user={props.data[props.row.index]}/>,
  },
];

export { ApiLogColumns };
