import { ID, Response } from "../../../../_metronic/helpers";

export type MyOrganisation = {
  _id?: ID
  id?: ID
  first_name?: string
  last_name?:string
  email?: string
  invoice_email?:string
  zipcode?:string
  street?: string
  city?:string
  client_id?:string
  secret?:string
  user_name?:string
  // integration_code?:string
  is_active?: boolean
  status?: string
  subdomain?: string 
  vat_number ?: string ; 
  country ?:string ; 
  address ? :any  ; 
  default_billing_address?: boolean ;   
  company_name ?: string ;
  billing_address ?: string , 
  billing_zipcode  ?: string ,
  billing_name  ?: string ,
  Country_type ?: any , 
};
export type MyOrganisationQueryResponse = Response<Array<MyOrganisation>>;

export const initialMyOrganisation: MyOrganisation = {
  is_active: false,
  first_name: '',
  last_name: '',
  email: '',
  invoice_email:'',
  zipcode:'',
  street:'',
  city:'',
  client_id: '',
  secret: '',
  // integration_code: '',
  status: '',
  subdomain: '',
  vat_number :''  , 
  country : '' , 
  address: '' , 
  default_billing_address : true  , 
  company_name : '' , 
   billing_address : '' , 
   billing_zipcode : ' ' , 
   billing_name : '' , 
  Country_type : '' , 

 

};