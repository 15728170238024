import axios, { AxiosResponse } from "axios";
import {
  Log, LogQueryResponse,
} from "../../app/modules/apps/logs/login/core/_model";

const API_URL_NODE = process.env.REACT_APP_API_URL;
const USER_URL_NODE = `${API_URL_NODE}logs`;

export function getLogs()  {
    return axios.get<Log>(USER_URL_NODE, {});
};

const getLogById = (id: string | null): Promise<LogQueryResponse> => {
    return axios
    .get(`${USER_URL_NODE}`)
    .then((d: AxiosResponse<LogQueryResponse>) => d.data);
  };

export {
    getLogById
};

