type AnyFunction = (...args: any[]) => void;

// eslint-disable-next-line import/no-anonymous-default-export
export default (func: AnyFunction, delay: Number) => {
  let timeoutId: any;

  return function (...args: any[]) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};
