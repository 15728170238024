import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Loading } from "../../../../components/loading/Loading";
import React, { useEffect, useState } from "react";
import { isNotEmpty } from "../../../../../_metronic/helpers";
import { initialSmtpSetting, SmtpSetting } from "../core/_models";
import {
  getGeneralSetting,
  updateSmtpSetting,
  createSmtpSetting,
} from "../core/_requests";
import { useQuery } from "react-query";
import { alert } from "@mobiscroll/react";
import * as Yup from "yup";
const Security = () => {
  const { refetch, data: securitySetting } = useQuery(
    `security-setting`,
    async () => {
      return await getGeneralSetting("");
    },
    {
      cacheTime: 0,
    }
  );

  let settingForEdit = securitySetting
    ? {
        id: securitySetting._id,
        password_expire_value: securitySetting.password_expire_value,
        session_expire_value: securitySetting.session_expire_value,
        session_expire_unit: securitySetting.session_expire_unit,
        password_link_expire_value: securitySetting.password_link_expire_value,
        password_link_expire_unit: securitySetting.password_link_expire_unit,
        password_expire_unit: securitySetting.password_expire_unit,
        is_user_password_to_configure:
          securitySetting?.is_user_password_to_configure || false,
        is_remember_session_to_configure:
          securitySetting?.is_remember_session_to_configure || false,
        is_password_link_expire_value:
          securitySetting?.is_password_link_expire_value || false,
      }
    : initialSmtpSetting;

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
  };
  const [remember_password, setRememberpassword] = useState<any>(null);
  const [remember_session, setRemembersession] = useState<any>(null);
  const [expireLink, setExpireLink] = useState<any>(null);

  const updateRememberpassword = (
    fieldsToUpdate: Partial<SmtpSetting>
  ): void => {
    setRememberpassword(fieldsToUpdate?.is_user_password_to_configure);
  };

  const updateRememberSession = (
    fieldsToUpdate: Partial<SmtpSetting>
  ): void => {
    setRemembersession(fieldsToUpdate?.is_remember_session_to_configure);
  };

  //is_password_link_expire_value
  const updateExpireLink = (fieldsToUpdate: Partial<SmtpSetting>): void => {
    setExpireLink(fieldsToUpdate?.is_password_link_expire_value);
  };

  useEffect(() => {
    if (remember_password === null || remember_password === undefined) {
      setRememberpassword(securitySetting?.is_user_password_to_configure);
    }
    if (remember_session === null || remember_session === undefined) {
      setRemembersession(securitySetting?.is_remember_session_to_configure);
    }
    if (expireLink === null || expireLink === undefined) {
      setExpireLink(securitySetting?.is_password_link_expire_value);
    }
  }, [securitySetting, settingForEdit, remember_password, expireLink]);
  const securitySettingSchema = Yup.object().shape({
    password_expire_value: Yup.number().required().min(1).label("Expire Every").integer("Expire Every should not contain decimal point value"),
    session_expire_value: Yup.number()
      .required()
      .min(1)
      .label("Remember time out").integer("Remember time out should not contain decimal point value"),
    password_link_expire_value: Yup.number()
      .required()
      .min(1)
      .label("Expire link timeout").integer("Expire link timeout should not contain decimal point value"),
  });
  const formik = useFormik<SmtpSetting>({
    enableReinitialize: true,
    initialValues: settingForEdit,
    validationSchema: securitySettingSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);
      try {
        values.is_user_password_to_configure = remember_password;
        values.is_remember_session_to_configure = remember_session;
        values.is_password_link_expire_value = expireLink;
        if (isNotEmpty(values._id)) {
          await updateSmtpSetting(values);
        } else {
          await createSmtpSetting(values);
        }
      } catch (ex) {
        setLoading(false);
      } finally {
        navigate("/superadmin/general-settings/security");
        setSubmitting(true);
        setLoading(false);
        cancel(true);
        alert({
          message: "Changes saved successfully!",
          callback: function (result: any) {
            window.location.reload();
          },
        });
      }
    },
  });

  return (
    <>
      <div className="col">
        <div className="card card-custom card-stretch h-100">
          <form
            onSubmit={formik.handleSubmit}
            noValidate
            className="form d-flex flex-column"
          >
            <div className="card-header">
              <div className="card-title align-items-start flex-column">
                <h1 className="fw-bolder m-0 fs-4 fs-lg-2">
                  Security Settings
                </h1>
              </div>
              <div className="card-toolbar">
                <button
                  type="submit"
                  className="btn btn-primary h-40px fs-6 py-0 px-5 rounded-pill fs-ls-14 me-3"
                  disabled={loading}
                >
                  {!loading && "Save changes"}
                  <Loading
                    isLoading={loading}
                    text={"Please wait..."}
                  ></Loading>
                </button>
              </div>
            </div>

            <div className="form">
              <div className="card-body">
                <div className="row ">
                  <div className="col-lg-12 col-xl-12 mb-2">
                    <div className="form-check align-items-center mb-2">
                      <label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="is_user_password_to_configure"
                          name="is_user_password_to_configure"
                          defaultChecked={
                            remember_password === null
                              ? securitySetting?.is_user_password_to_configure
                              : remember_password
                          }
                          onClick={() => {
                            updateRememberpassword({
                              is_user_password_to_configure:
                                remember_password === null
                                  ? !securitySetting?.is_user_password_to_configure
                                  : !remember_password,
                            });
                          }}
                        ></input>

                        <h5 className="font-weight-bold mb-2 fs-ls-16 ">
                          Configure user password to expire periodically :
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-8">
                  <label className="col-form-label col-form-label fw-semibold fs-6 mw-250px fs-ls-15 ms-4">
                    Expire Every :
                  </label>
                  <div className="col-lg-3 col-xl-3">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        className="form-control form-control-solid fs-6 h-45px p-0 px-4 h-ls-48 fs-ls-14"
                        // name="password_expire_value"
                        type="number"
                        {...formik.getFieldProps("password_expire_value")}
                      />
                    </div>
                    {formik.touched.password_expire_value &&
                      formik.errors.password_expire_value && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block fs-8">
                            {formik.errors.password_expire_value}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="col-lg-3 col-xl-3">
                    <select
                      // name="password_expire_unit"
                      className="form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14"
                      {...formik.getFieldProps("password_expire_unit")}
                    >
                      <option value="months">Months</option>
                      <option value="years">Years</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-xl-12 ">
                    <div className="form-check align-items-center mb-2">
                      <label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="is_remember_session_to_configure"
                          name="is_remember_session_to_configure"
                          defaultChecked={
                            remember_session === null
                              ? securitySetting?.is_remember_session_to_configure
                              : remember_session
                          }
                          onClick={() => {
                            updateRememberSession({
                              is_remember_session_to_configure:
                                remember_session === null
                                  ? !securitySetting?.is_remember_session_to_configure
                                  : !remember_session,
                            });
                          }}
                        ></input>
                        <h5 className="font-weight-bold mb-2 fs-ls-16 ">
                          Remember session :
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-8">
                  <label className="col-form-label col-form-label fw-semibold fs-6 mw-250px fs-ls-15 ms-4">
                    Remember time out:
                  </label>
                  <div className="col-lg-3 col-xl-3">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        className={`form-control form-control-solid fs-6 h-45px p-0 px-4 h-ls-48 fs-ls-14`}
                        // name="session_expire_value"
                        type="number"
                        {...formik.getFieldProps("session_expire_value")}
                      />
                    </div>
                    {formik.touched.session_expire_value &&
                      formik.errors.session_expire_value && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block fs-8">
                            {formik.errors.session_expire_value}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="col-lg-3 col-xl-3">
                    <select
                      // name="session_expire_unit"
                      className="form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14"
                      {...formik.getFieldProps("session_expire_unit")}
                    >
                      <option value="hours">Hours</option>
                      <option value="minutes">Minutes</option>
                      <option value="days">Days</option>
                      <option value="months">Months</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-xl-12">
                    <div className="form-check align-items-center mb-2">
                      <label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="is_password_link_expire_value"
                          name="is_password_link_expire_value"
                          defaultChecked={
                            expireLink === null
                              ? securitySetting?.is_password_link_expire_value
                              : expireLink
                          }
                          onClick={() => {
                            updateExpireLink({
                              is_password_link_expire_value:
                                expireLink === null
                                  ? !securitySetting?.is_password_link_expire_value
                                  : !expireLink,
                            });
                          }}
                        ></input>
                        <h5 className="font-weight-bold mb-2 fs-ls-16 ">
                          Validity of new link password/password forgotten:
                        </h5>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-form-label col-form-label fw-semibold fs-6 mw-250px fs-ls-15 ms-4">
                    Expire link timeout:
                  </label>
                  <div className="col-lg-3 col-xl-3">
                    <div className="input-group input-group-lg input-group-solid">
                      <input
                        className={`form-control form-control-solid fs-6 h-45px p-0 px-4 h-ls-48 fs-ls-14`}
                        // name="password_link_expire_value"
                        type="number"
                        {...formik.getFieldProps("password_link_expire_value")}
                      />
                    </div>
                    {formik.touched.password_link_expire_value &&
                      formik.errors.password_link_expire_value && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block fs-8">
                            {formik.errors.password_link_expire_value}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="col-lg-3 col-xl-3">
                    <select
                      // name="password_link_expire_unit"
                      className="form-select form-select-solid h-45px p-0 px-4 fs-6 h-ls-48 fs-ls-14"
                      {...formik.getFieldProps("password_link_expire_unit")}
                    >
                      <option value="hours">Hours</option>
                      <option value="minutes">Minutes</option>
                      <option value="days">Days</option>
                      <option value="months">Months</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* end::Form */}
          </form>
        </div>
      </div>
    </>
  );
};

export { Security };
