import React from "react";


export function MenuInner() {
  return (
    <>

    </>
  );
}


