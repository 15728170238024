import {
  ListViewProvider
} from "../../../../../components/core/ListViewProvider";
import { QueryRequestProvider } from "../../../../../components/core/QueryRequestProvider";
import {
  QueryResponseProvider,
  useQueryResponsePagination,
} from "../../apis/core/QueryResponseProvider";//
import { SuperTable } from "../../../../../components/table/SuperTable";
import { KTCard } from "../../../../../../_metronic/helpers";
import {
  useQueryResponse,
  useQueryResponseData,
} from "../../apis/core/QueryResponseProvider";
import { ListSearchComponent } from "../../../../../components/header/ListSearchComponent";
import { useEffect, useMemo, useState } from "react";
import { ApiLogColumns } from "./table/columns/_columns";
import { ListFilter } from "../../../../../components/header/ListFilter";
import { RefreshApi } from "./RefreshApi";
import { useAppSelector } from "../../../../../hook";
import { useQuery } from "react-query";
import { getTooltipData } from "../../../../superadmin/hint-tour/core/_request";
import { useAuth } from "../../../../auth/core/AuthCognito";
import { Steps } from "intro.js-react";
import { getCurrentUserDetails, updateTooltipVisibilityByPage } from "../../../common/_requests";
import React from "react";
const ApiList = () => {

  const { isLoading, refetch } = useQueryResponse();
  let apilogs = useQueryResponseData();
  const data = useMemo(() => apilogs, [apilogs]);
  const pagination = useQueryResponsePagination();
  const columns = useMemo(() => ApiLogColumns, []);
  const [r_time, setr_time] = useState<any>(null);
  const [interval, settimeinterval] = useState<any>(null);
  const time = useAppSelector((state) => state.counter)
  if (time.refreshtime != r_time && time.refreshtime != '') {
    setr_time(time.refreshtime)
    clearInterval(interval)
    const intervall = setInterval(() => {
      refetch()
    }, time.refreshtime * 1000);
    settimeinterval(intervall)
  }

  if (time.refreshtime == '') {
    clearInterval(interval)
  }
  useEffect(() => {

  }, [interval, r_time]);

  const { data: tooltipData } = useQuery(
    "tooltip-Data",
    () => {
      return getTooltipData();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );
  const { data: loggedUser } = useQuery(
    'logged-user',
    async () => {
      return await getCurrentUserDetails(_id)
    },
    {
      cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false
    }
  )
  const steps = tooltipData?.filter(
    (v: any) => v.type === "tour" && v.page === "Logs-APIs"
  );

  const { currentUser } = useAuth()
  const  _id  = currentUser?.data?.user?._id
  const [enabled, setEnabled] = useState<boolean>(true);
  const [initialStep] = useState<number>(0);
  
  const tooltipVisibility = {
    showTooltipVisibility: {
      LogsAPIs: false,
    },
  };
  const onExit = async () => {
    setEnabled(false);
    await updateTooltipVisibilityByPage(_id, tooltipVisibility);
  };
  return (
    <>
    {loggedUser && loggedUser?.showTooltipVisibility?.LogsAPIs !== false && data?.length > 0 && (
          <Steps
            enabled={enabled}
            initialStep={initialStep}
            steps={steps?.length > 0 ? steps : []}
            onExit={onExit}
            options={{
              showStepNumbers: true,
              showButtons: true,
              hidePrev: true,
              scrollToElement: true,
              showBullets: true,
              showProgress: true,
              exitOnOverlayClick: false,
              disableInteraction: true,
            }}
          />
        )}
      <KTCard className="h-100 logs-api">
        
        <RefreshApi />
        <div className="card-header border-0 pt-6 min-h-auto pb-4 apiHeader mb-4">

          <ListFilter />
          <ListSearchComponent />
          {/* <ListToolbar link="" /> */}
          {/* <ListFilter /> */}
          {/* begin::Card toolbar */}
          <div className="card-toolbar d-none">

          </div>
          {/* end::Card toolbar */}
        </div>
        {/* end::Card toolbar */}
        <SuperTable
          isLoading={isLoading}
          columns={columns}
          data={data}
          pagination={pagination}
        />
      </KTCard>
    </>
  );
};

const ApiListWrapper = () => {
  const { isLoading } = useQueryResponse();
  const data = useQueryResponseData();
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <ListViewProvider isLoading={isLoading} data={data}>
            <ApiList />
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  );
};

export { ApiListWrapper };
