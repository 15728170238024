import moment from "moment"
import { MONTHYEARCOLOUR } from "../modules/apps/constant/Constant";

export const calculatePlanningData =  async (data: any,generalSetting:any,resource_settings:any,view_type:any) => {
    let finalData: any[] = [];
    let yearData = {};
    let monthData = {};
    let weekData = {};
    let dayData = {};
    const defaultMonthYearHoursHighlight =
    generalSetting.default_monthyearhours_highlight;
    let otherServicecallStatus = generalSetting?.default_monthyearhours || [];
    otherServicecallStatus = otherServicecallStatus?.filter(
        (i:any) => i != defaultMonthYearHoursHighlight
    );
    
    data?.forEach( (e: any) => {
        let setting = resource_settings?.find((x: any) => x.id === e.resource);
        e.free = 0;
        e.over = 0;
        e.weeknumber = moment.utc(e.start).isoWeek();
        e.year = moment.utc(e.start).year();
        e.planned = 0;
        e.highlightedHours = 0;
        e.selectedStatusHours = 0;
        e.editable = false;
        e.end = e.start;
        e.monthnumber = parseInt(moment.utc(e.start).month()) + 1;
        e.monthkey = `${e.year}-${e.monthnumber}`;
        e.weekkey = `${e.weeknumber}-${e.year}`;
        e.weekunique = `${e.weeknumber}-${e.resource}-${e.year}`;
        let day = moment(e.start, "YYYY-MM-DD").format("dddd").substring(0, 3).toLowerCase();
        let capacity = setting && e.weeknumber % 2 === 0 ?
            setting[`${day}_even`] :
            setting[`${day}_odd`];
        e.capacity = capacity;
        e.free = capacity;
        e.selectedStatusHours = 0
        e.highlightedHours = 0
        e.planned = 0
        let servicecallStatus: any = e.servicecall_status || {};
        if (view_type === "month" || view_type === "year") {
          e.selectedStatusHours = otherServicecallStatus.reduce(
          (accumulator: any, currentValue: any) => { 
              return +(servicecallStatus?.[currentValue] || 0) + +accumulator
          },
          0
          );
          e.highlightedHours = servicecallStatus?.[defaultMonthYearHoursHighlight] || 0
          e.planned = e.highlightedHours + e.selectedStatusHours;
        }else{
          for (const key in servicecallStatus) {
            if (Object.hasOwnProperty.call(servicecallStatus, key)) {
                e.planned += servicecallStatus[key];
            }
        }
        }
        const resourceOnly = setting?.resource_only || false
        if ((resourceOnly == false && setting.has_license == false)) {
          e.selectedStatusHours = 0;
          e.highlightedHours = 0;
          e.planned = 0;
        }
        
        e.free = e.capacity - e.planned;
        if (e.planned > e.capacity) {
            e.color = MONTHYEARCOLOUR.over; //red
            e.bgcolor = MONTHYEARCOLOUR.over_bg; //red
          } else if (e.planned === 0) {
            e.color = MONTHYEARCOLOUR.noplanned;
            e.bgcolor = MONTHYEARCOLOUR.noplanned_bg;
          } else {
            e.color = MONTHYEARCOLOUR.left; //blue
            e.bgcolor = MONTHYEARCOLOUR.left_bg; //blue
          }
        
        
        
        if (view_type === "year") {
            let weekUniqueKey = e.weekunique;
            if (!yearData[weekUniqueKey]) {
              e.over = e.capacity < e.planned ? (e.capacity - e.planned) : 0;
              yearData[weekUniqueKey] = { ...e };
            } else {
              yearData[weekUniqueKey].capacity += e.capacity;
              yearData[weekUniqueKey].planned += e.planned;
              yearData[weekUniqueKey].highlightedHours += e.highlightedHours;
              yearData[weekUniqueKey].selectedStatusHours +=
                e.selectedStatusHours;
              yearData[weekUniqueKey].over =
                yearData[weekUniqueKey].capacity < yearData[weekUniqueKey].planned
                  ? yearData[weekUniqueKey].capacity -
                    yearData[weekUniqueKey].planned
                  : 0;
              yearData[weekUniqueKey].free =
                yearData[weekUniqueKey].capacity -
                yearData[weekUniqueKey].planned;
              yearData[weekUniqueKey].start = moment().year(e.year).week(e.weeknumber).day("Thursday").format("YYYY-MM-DD");
              yearData[weekUniqueKey].end = yearData[weekUniqueKey].start;
  
              if (
                yearData[weekUniqueKey].planned > yearData[weekUniqueKey].capacity
              ) {
                yearData[weekUniqueKey].color = MONTHYEARCOLOUR.over; //red
                yearData[weekUniqueKey].bgcolor = MONTHYEARCOLOUR.over_bg; //red
              } else if (yearData[weekUniqueKey].planned === 0) {
                yearData[weekUniqueKey].color = MONTHYEARCOLOUR.noplanned;
                yearData[weekUniqueKey].bgcolor = MONTHYEARCOLOUR.noplanned_bg;
              } else {
                yearData[weekUniqueKey].color = MONTHYEARCOLOUR.left; //blue
                yearData[weekUniqueKey].bgcolor = MONTHYEARCOLOUR.left_bg; //blue
              }
            }
          }

          if (view_type !== "year") {
            finalData.push(e)
            if (!dayData[e.start]) {
                e.over = e.capacity < e.planned ? (e.capacity - e.planned) : 0;
                dayData[e.start] = { ...e };
              } else {
                dayData[e.start].capacity += e.capacity;
                dayData[e.start].planned += e.planned;
                dayData[e.start].highlightedHours += e.highlightedHours;
                dayData[e.start].selectedStatusHours += e.selectedStatusHours;
                dayData[e.start].over =
                  dayData[e.start].capacity < dayData[e.start].planned
                    ? dayData[e.start].capacity - dayData[e.start].planned
                    : 0;
                dayData[e.start].free =
                  dayData[e.start].capacity - dayData[e.start].planned;
              }
          }

          if (!weekData[e.weekkey]) {
            e.over = e.capacity < e.planned ? (e.capacity - e.planned) : 0;
            weekData[e.weekkey] = { ...e };
          } else {
            weekData[e.weekkey].capacity += e.capacity;
            weekData[e.weekkey].planned += e.planned;
            weekData[e.weekkey].highlightedHours += e.highlightedHours;
            weekData[e.weekkey].selectedStatusHours +=
              e.selectedStatusHours;
            weekData[e.weekkey].over =
              weekData[e.weekkey].capacity < weekData[e.weekkey].planned
                ? weekData[e.weekkey].capacity -
                  weekData[e.weekkey].planned
                : 0;
            weekData[e.weekkey].free =
              weekData[e.weekkey].capacity - weekData[e.weekkey].planned;
            
            const startdate = moment()
              .isoWeek(e.weeknumber)
              .isoWeekday(1)
              .isoWeekYear(e.year)
              .format("DD MMM");
            const enddate = moment()
              .isoWeek(e.weeknumber)
              .isoWeekday(5)
              .isoWeekYear(e.year)
              .format("DD MMM");

            const monday = moment(weekData[e.weekkey].start)
              .clone()
              .weekday(0)
              .format("DD-MM");
            const friday = moment(weekData[e.weekkey].start)
              .clone()
              .weekday(6)
              .format("DD-MM");
            weekData[e.weekkey].weekdatelabel =
              view_type === "year"
                ? `${monday} - ${friday}`
                : `${startdate} - ${enddate}`;
            if(view_type == 'year'){
              weekData[e.weekkey].start = moment()
                .week(e.weeknumber)
                .isoWeekday(4)
                .isoWeekYear(e.year)
                .format("YYYY-MM-DD");
                weekData[e.weekkey].end = weekData[e.weekkey].start;
            }
          }

          //month data
          if (!monthData[e.monthkey]) {
            e.over = e.capacity < e.planned ? (e.capacity - e.planned) : 0;
            monthData[e.monthkey] = { ...e };
          } else {
            monthData[e.monthkey].capacity += e.capacity;
            monthData[e.monthkey].planned += e.planned;
            monthData[e.monthkey].highlightedHours += e.highlightedHours;
            monthData[e.monthkey].selectedStatusHours +=
              e.selectedStatusHours;
            monthData[e.monthkey].over =
              monthData[e.monthkey].capacity <
              monthData[e.monthkey].planned
                ? monthData[e.monthkey].capacity -
                  monthData[e.monthkey].planned
                : 0;
            monthData[e.monthkey].free =
              monthData[e.monthkey].capacity -
              monthData[e.monthkey].planned;
            
            monthData[e.monthkey].monthdatelabel = moment(
              e.monthnumber,
              "M"
            ).format("MMM");
            const startdate = moment()
              .isoWeek(e.weeknumber)
              .isoWeekday(1)
              .isoWeekYear(e.year)
              .format("DD MMM");
            const enddate = moment()
              .isoWeek(e.weeknumber)
              .isoWeekday(5)
              .isoWeekYear(e.year)
              .format("DD MMM");
            const monday = moment(monthData[e.monthkey].start)
              .clone()
              .weekday(0)
              .format("DD-MM");
            const friday = moment(monthData[e.monthkey].start)
              .clone()
              .weekday(6)
              .format("DD-MM");
            monthData[e.monthkey].weekdatelabel =
              view_type === "year"
                ? `${monday} - ${friday}`
                : `${startdate} - ${enddate}`;
          }
    });
    let responsedata = {
        data: finalData,
        yearData: Object.values(yearData),
        monthData: Object.values(monthData),
        dayData: Object.values(dayData),
        weekData: Object.values(weekData),
    }
    return responsedata;
};
