import axios, { AxiosResponse } from "axios";

const API_URL_NODE = process.env.REACT_APP_API_URL;
const USER_URL_NODE = `${API_URL_NODE}`;

const getProjects = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}projects`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getDepartments = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}department`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getResources = (): Promise<any> => {
  return axios
    .post(`${USER_URL_NODE}resources`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getCompany = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}company`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getLocations = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}locations`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getSkills = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}skills`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getRoles = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}roles/list`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getQueues = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}masterdata/queues`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getPriorities = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}masterdata/priorities`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getSlas = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}masterdata/sla_list`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getTicketType = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}masterdata/ticket_types`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getTicketCategory = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}masterdata/ticket_categories`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getTaskCategory = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}masterdata/task_categories`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getTaskType = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}masterdata/task_types`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getContractCategory = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}masterdata/contract_categories`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getIssues = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}masterdata/issue_types`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getSubIssues = (): Promise<any> => {
  return axios
  .get(`${USER_URL_NODE}masterdata/subissue_types`)
  .then((d: AxiosResponse<any>) => d.data);
};

const getlineOfBusiness = (): Promise<any> => {
  return axios
    .get(`${USER_URL_NODE}masterdata/line_of_business`)
    .then((d: AxiosResponse<any>) => d.data);
};


export {
    getProjects,
    getDepartments,
    getResources,
    getCompany,
    getLocations,
    getSkills,
    getRoles,
    getQueues,
    getPriorities,
    getSlas,
    getTicketType,
    getTicketCategory,
    getTaskCategory,
    getContractCategory,
    getIssues,
    getSubIssues,
    getTaskType,
    getlineOfBusiness
};
