// @ts-nocheck
import clsx from "clsx";
import { FC, useState } from "react";
import { Row } from "react-table";
import { User } from "../../core/_models";
import { ViewModal } from "../../../modules/apps/logs/apis/api-list/ApiLogView";

type Props = {
    row: Row<User>;
};

const CustomRowClickable: FC<Props> = ({ row }) => {

    const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)

    return (
        <><tr {...row.getRowProps()} className="text-gray-900 fs-16"
            onClick={() => setShowCreateAppModal(true)}
        >
            {row.cells.map((cell) => {
                return (
                    <td
                        {...cell.getCellProps()}
                        className={clsx({
                            "text-end min-w-100px": cell.column.id === "actions",
                        })}
                    >
                        {cell.render("Cell")}
                    </td>
                );
            })}
        </tr><ViewModal userdata={row} show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} /></>
    )
}

export { CustomRowClickable };
