import axios, { AxiosResponse } from "axios";

const API_URL_NODE = process.env.REACT_APP_API_URL;
const RESOURCE_URL_NODE = `${API_URL_NODE}`;

export const savedViewSettings = async (
  data: any
): Promise<any | undefined> => {
  const response = await axios
    .post(
      `${RESOURCE_URL_NODE}viewsetting
            `,
      data
    )
    .then((response: AxiosResponse<any>) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};

export const getViewSettings = async (data: any): Promise<any | undefined> => {
  const response = await axios
    .get(
      `${RESOURCE_URL_NODE}viewsetting`,data)
    .then((response: AxiosResponse<any>) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });
  return response;
};
