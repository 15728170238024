import { FC } from "react";

type Props = {
  descriptionnote: string;
  taskdata: any;
};

const ActivityTaskHeader: FC<Props> = ({
  descriptionnote,
  taskdata
}) => {

  return (
    <div className="timeline-item">
      <div className="timeline-content my-2">
       
        <div className="pe-2">
          {/* <div className="fs-5 fw-bold mb-2">{taskdata}</div> */}
          {/* <span className="text-gray-500 fw-semibold">{descriptionnote}</span> */}
          <div className="input-group input-group-solid">
          <input type="text" className="form-control bg-white border-0 py-0 px-2 h-30px text-dark fs-7" placeholder={descriptionnote} />
        </div>
        </div>
      </div>
      <div className="separator border-gray-101"></div>
    </div>
  );
};

export { ActivityTaskHeader };
