import * as Yup from "yup";
import {
    uniqueEmail,
    uniqueSubdomain,
} from "../../../../_metronic/requests/RegistrationRequest";

export const registrationDetailSchema = Yup.object().shape({
    first_name: Yup.string()
        .required("First name is required")
        .trim()
        .max(15, "Maximum 15 characters are required in first name"),

    last_name: Yup.string()
        .required("Last name is required")
        .trim()
        .max(15, "Maximum 15 characters are required in last name"),

    subdomain: Yup.string()
        .required("Subdomain is required")
        .trim()
        .matches(/^[a-z0-9]+$/, "Subdomain must contain only small letters and numbers")
        .matches(
            new RegExp(`^(?!${process.env.REACT_APP_AUTHENTICATION_SUBDOMAIN}\\b)[a-z0-9]+$`),
            `Subdomain cannot be '${process.env.REACT_APP_AUTHENTICATION_SUBDOMAIN}'.`
        )
        .test("Unique Subdomain", "Subdomain already in use", function (value: any) {
            return new Promise((resolve, reject) => {
                let subdomain = { subdomain: value };
                uniqueSubdomain(subdomain)
                    .then((response: any) => {
                        if (response == "Subdomain already been taken") {
                            resolve(false)
                        } else {
                            resolve(true)
                        }
                    })
                    .catch((error: any) => {
                        console.error("Error checking unique subdomain:", error);
                        resolve(false)
                    });
            });
        }),
    email: Yup.string()
        .email("Please enter a valid email address")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Email address is required")
        .matches(/^(\S+$)/g, "Email cannot contain blankspaces")
        .test("Unique Email", "Email already in use", function (value: any) {
            return new Promise((resolve, reject) => {
                let invoiceSettingData = { email: value };
                uniqueEmail(invoiceSettingData)
                    .then((res: any) => {
                        if (res == "Username is available") {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    })
                    .catch((error: any) => {
                        if (error === "Username already been taken") {
                            resolve(false);
                        }
                    });
            });
        }),

    invoice_email: Yup.string()
        .email("Please enter a valid email address")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Invoice email address is required")
        .matches(/^(\S+$)/g, "Invoice email cannot contain blankspaces"),

    zipcode: Yup.string().required("Zipcode is required").trim(),

    street: Yup.string().required("Street is required").trim(),

    city: Yup.string()
        .required("City is required")
        .trim()
        .max(10, "Maximum 10 characters are required in city"),

    is_active: Yup.boolean().nullable(),
    terms_condition: Yup.boolean()
        .oneOf([true], 'You must agree to the terms and conditions')
        .required("You must accept the terms and conditions"),

    company_name: Yup.string()
        .required("Company name is required")
        .trim()
        .max(30, "Maximum 30 characters are required in company name"),

});