
import { BaseComponent } from '../../base';
import { getClosestValidDate, isInvalid } from '../../util/date-validation';
import {
  addTimezone,
  dateTimeLocale,
  dateValueEquals,
  formatDate,
  getDateStr,
  getDayEnd,
  getDayStart,
  IDatetimeProps,
  isSameDay,
  makeDate,
} from '../../util/datetime';
import { MbscDateType } from '../../util/datetime.types.public';
import { floor, isEmpty, pad, round, step, UNDEFINED } from '../../util/misc';
import { getEventMap } from '../../util/recurrence';
import { getValid, ScrollerBase } from '../scroller/scroller';
import { IScrollerProps, MbscScrollerWheel } from '../scroller/scroller.types';

// tslint:disable no-non-null-assertion
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

const WHEEL_WIDTHS: { [key: string]: number } = {
  ios: 50,
  material: 46,
  windows: 50,
};

const TIME_PARTS = ['a', 'h', 'i', 's', 'tt'];

// tslint:disable-next-line interface-name
export interface MbscDatetimeOptions<T = DatetimeBase> extends IDatetimeProps, IScrollerProps<T> {
  // #region Hidden options
  /** @hidden */
  dateDisplay?: string;
  /** @hidden */
  dateWheelFormat?: string;
  /** @hidden */
  defaultValue?: MbscDateType;
  /** @hidden */
  mode?: 'date' | 'datetime' | 'time';
  // #endregion Hidden options

  // #region Options

  /**
   * Step for the hours scroll wheel. Also, sets the hours step for the timegrid.
   * @defaultValue 1
   */
  stepHour?: number;
  /**
   * Step for the minutes scroll wheel. Also, sets the minutes step for the timegrid.
   * @defaultValue 1
   */
  stepMinute?: number;
  /**
   * Step for the seconds scroll wheel. Also, sets the seconds step for the timegrid.
   * @defaultValue 1
   */
  stepSecond?: number;

  // #endregion Options

  // #region Localization

  /**
   * Display order and formatting for month/day/year wheels. Characters have the same meaning as in the
   * [dateFormat](#localization-dateFormat) option. The options also controls if a specific wheel should appear or not,
   * e.g. use `'MMMMYYYY'` to display month and year wheels only, `'MMDDD DDYYYY'` to display both day of week and date on the day wheel.
   *
   * If not specified, the order of the wheels will be taken from the [dateFormat](#localization-dateFormat) option, and the
   * formatting will be defined by the [theme](#opt-theme).
   *
   * To display the whole date on one wheel, the format of the date should be specified between `|` characters:
   *
   * ```js
   * dateWheels: '|DDD MMM D|' // Will produce 'Sun Sep 9'
   * ```
   *
   * @defaultValue undefined
   * @group Localizations
   */
  dateWheels?: string;
  /**
   * Display order and formatting of the time wheels. Characters have the same meaning as in the
   * [timeFormat](#localization-timeFormat) option.
   *
   * If not specified, the order of the wheels will be taken from the [timeFormat](#localization-timeFormat) option,
   * and the formatting will be defined by the theme.
   *
   * To display the whole time on one wheel, the format of the time should be specified between `|` characters:
   *
   * ```js
   * timeWheels: '|h:mm A|' // Will produce '9:12 AM'
   * ```
   *
   * @defaultValue undefined
   * @group Localizations
   */
  timeWheels?: string;

  // #endregion Localization
}

/** @hidden */
// tslint:disable-next-line interface-name
export interface MbscDatetimeState {
  value?: MbscDateType;
}

function validateTimes(
  s: IDatetimeProps,
  hasAmPm: boolean,
  i: number,
  valid: number[],
  wheelOrder: any,
  getDatePart: any,
  maxs: { [key: string]: number },
  steps: { [key: string]: number },
  key: string,
  disabled: Array<Map<any, boolean>>,
  order: number,
  validDate: Date,
  startDate: Date,
  endDate: Date,
  isValid: boolean,
  exclusiveEndDates?: boolean,
): void {
  // Notes:
  // 1. in case of invalid rules that are limited to a single day (start and end is on same day)
  // we take the start and end of the rule

  // 2. in case of invalid rules that span across multiple days, we need to take the start of the day
  // or end of the day depending on the current date we are validating ("validated")

  // if we are validating the "end of the rule" (the last date of the rule) - invalids will start at
  // the beginning of the day (bc invalids are coming from prev. day), and span until the end of the rule
  // if we are validating the "start of the rule" (the first date of the rule) - invalids will start at
  // the rule start and will span until the end of the day (bc. the rule spans to the next day...)
  const sameDayInvalid = isSameDay(startDate, endDate);
  const start = sameDayInvalid || !isSameDay(validDate, endDate) ? startDate : getDayStart(s, startDate);
  const end = sameDayInvalid || !isSameDay(validDate, startDate) ? endDate : getDayEnd(s, endDate);
  const startAmPm = getDatePart.a(start);
  const endAmPm = getDatePart.a(end);

  let startProp = true;
  let endProp = true;
  let all = false;
  let add = 0;
  let remove = 0;

  // Look behind to check if the invalid propagates down to the current wheel
  for (let j = 0; j < i; j++) {
    const k = TIME_PARTS[j];
    let validVal = valid[wheelOrder[k]];
    if (validVal !== UNDEFINED) {
      let startVal = startProp ? getDatePart[k](start) : 0;
      let endVal = endProp ? getDatePart[k](end) : maxs[k];

      if (hasAmPm && j === 1) {
        // Adjust hours
        startVal += startAmPm ? 12 : 0;
        endVal += endAmPm ? 12 : 0;
        validVal += valid[wheelOrder.a] ? 12 : 0;
      }

      if ((startProp || endProp) && startVal < validVal && validVal < endVal) {
        all = true;
      }

      if (validVal !== startVal) {
        startProp = false;
      }

      if (validVal !== endVal) {
        endProp = false;
      }
    }
  }

  if (!isValid) {
    // Look ahead to see if there are any possible values on lower wheels,
    // if yes, don't disable the start and/or end value of the range.
    for (let j = i + 1; j < 4; j++) {
      const k = TIME_PARTS[j];
      if (wheelOrder[k] !== UNDEFINED) {
        if (getDatePart[k](start) > 0 && startProp) {
          add = steps[key];
        }
        if (getDatePart[k](end) < maxs[k] && endProp) {
          remove = steps[key];
        }
      }
    }

    if (endProp && exclusiveEndDates && !remove) {
      remove = end.getMilliseconds() !== 999 ? steps[key] : 0;
    }
  }

  // Set disabled values
  if (startProp || endProp || all) {
    const startVal = startProp && !all ? getDatePart[key](start) + add : 0;
    const endVal = endProp && !all ? getDatePart[key](end) - remove : maxs[key];
    for (let j = startVal; j <= endVal; j += steps[key]) {
      disabled[order].set(j, !isValid);
    }
  }
}

function getDateIndex(d: string, hasDay: boolean): number {
  const dt = new Date(d);
  return hasDay
    ? // Number of days since 1970-01-01
      floor(+dt / 8.64e7)
    : // Number of month since 1970-01-01
      dt.getMonth() + 12 * (dt.getFullYear() - 1970);
}

function getFullDate(d: Date): string {
  return d.getFullYear() + '-' + pad(d.getMonth() + 1) + '-' + pad(d.getDate());
}

function getMilliseconds(d: Date): number {
  return d.getMilliseconds();
}

function getAmPm(d: Date): number {
  return d.getHours() > 11 ? 1 : 0;
}

/**
 * @hidden
 */

export class DatetimeBase extends BaseComponent<MbscDatetimeOptions, MbscDatetimeState> {
  /** @hidden */
  public static defaults: MbscDatetimeOptions = {
    ...dateTimeLocale,
    dateDisplay: 'MMMMDDYYYY',
    dateWheelFormat: '|DDD MMM D|',
    stepHour: 1,
    stepMinute: 1,
    stepSecond: 1,
  };

  // tslint:disable variable-name

  public static _name = 'Datetime';

  /** @hidden */
  public _wheels?: MbscScrollerWheel[][];
  /** @hidden */
  public _minWheelWidth?: number | number[];
  /** @hidden */
  public _value!: MbscDateType;

  protected _preset = 'date';
  protected _scroller?: ScrollerBase;

  private _dateDisplay!: string;
  private _dateTemplate!: string;
  private _dateWheels!: string;
  private _format!: string;
  private _getDatePart: any;
  private _hasAmPm!: boolean;
  private _hasDay!: boolean;
  private _max?: Date | null;
  private _min?: Date | null;
  private _innerValues: any = {};
  private _invalids?: { [key: string]: any[] };
  private _timeDisplay!: string;
  private _timeStep!: number;
  private _timeWheels!: string;
  private _valids?: { [key: string]: any[] };
  private _wheelOrder: any;

  public getVal(): MbscDateType {
    return this._value;
  }

  public setVal(value: MbscDateType) {
    this._value = value;
    this.setState({ value });
  }

  public position() {
    if (this._scroller) {
      this._scroller.position();
    }
  }

  public isVisible() {
    return this._scroller && this._scroller.isVisible();
  }

  public _onChange = (args: any) => {
    if (this.s.value === UNDEFINED) {
      this.setState({ value: args.value });
    }
    this._hook('onChange', args);
  };

  public _parseDate = (value: string): any[] => {
    const s = this.s;

    if (!value) {
      this._innerValues = {};
    }

    return this._getArray(makeDate(value || s.defaultSelection || new Date(), s, this._format), !!value);
  };

  public _formatDate = (values: any[]): string => {
    const d = this._getDate(values);
    return d ? formatDate(this._format, d, this.s) : '';
  };

  public _getDate = (values: any[]): Date | null => {
    const s = this.s;
    const getArrayPart = this._getArrayPart;
    const wheelOrder = this._wheelOrder;
    const today = new Date(new Date().setHours(0, 0, 0, 0));

    let d: Date | undefined;
    let t: Date | undefined;

    if (values === null || values === UNDEFINED) {
      return null;
    }

    if (wheelOrder.dd !== UNDEFINED) {
      const parts = values[wheelOrder.dd].split('-');
      d = new Date(parts[0], parts[1] - 1, parts[2]);
    }

    if (wheelOrder.tt !== UNDEFINED) {
      t = d || today;
      t = new Date(t.getTime() + (values[wheelOrder.tt] % 86400) * 1000);
    }

    const year = getArrayPart(values, 'y', d, today);
    const month = getArrayPart(values, 'm', d, today);
    const day = Math.min(getArrayPart(values, 'd', d, today), s.getMaxDayOfMonth!(year, month));
    const hour = getArrayPart(values, 'h', t, today);

    return s.getDate!(
      year,
      month,
      day,
      this._hasAmPm && getArrayPart(values, 'a', t, today) ? hour + 12 : hour,
      getArrayPart(values, 'i', t, today),
      getArrayPart(values, 's', t, today),
      getArrayPart(values, 'u', t, today),
    );
  };

  public _validate = ({ direction, index, values, wheels }: any) => {
    const disabled: Array<Map<any, boolean>> = [];
    const s = this.s;
    const stepHour = s.stepHour!;
    const stepMinute = s.stepMinute!;
    const stepSecond = s.stepSecond!;
    const preset = s.mode || this._preset;
    const wheelOrder = this._wheelOrder;
    const getDatePart = this._getDatePart;
    const maxDate = this._max;
    const minDate = this._min;
    const current = addTimezone(s, this._getDate(values)!);
    const currYear = s.getYear!(current);
    const currMonth = s.getMonth!(current);
    const from = s.getDate!(currYear, currMonth - 1, 1);
    const until = s.getDate!(currYear, currMonth + 2, 1);
    // Map the valids and invalids for prev and next months
    if (index === wheelOrder.y || index === wheelOrder.m || index === wheelOrder.d || index === wheelOrder.dd || index === UNDEFINED) {
      this._valids = getEventMap(s.valid!, from, until, s, true);
      this._invalids = getEventMap(s.invalid!, from, until, s, true);
    }
    const valids = this._valids;
    const invalids = this._invalids;
    // Normalize min and max dates for comparing later (set default values where there are no values from wheels)
    // const mind = this._min ? +this._getDate(this._getArray(this._min))! : -Infinity;
    // const maxd = this._max ? +this._getDate(this._getArray(this._max))! : Infinity;
    const mind = minDate ? +minDate : -Infinity;
    const maxd = maxDate ? +maxDate : Infinity;
    // Get the closest valid dates
    const validated = getClosestValidDate(current, s, mind, maxd, invalids, valids, direction);
    const valid = this._getArray(validated);
    const dayWheel = this._wheels && this._wheels[0][wheelOrder.d];
    const y = getDatePart.y(validated);
    const m = getDatePart.m(validated);
    const maxDays = s.getMaxDayOfMonth!(y, m);
    // tslint:disable object-literal-sort-keys
    const mins: { [key: string]: number } = {
      y: minDate ? minDate.getFullYear() : -Infinity,
      m: 0,
      d: 1,
      h: 0,
      i: 0,
      s: 0,
      a: 0,
      tt: 0,
    };
    const maxs: { [key: string]: number } = {
      y: maxDate ? maxDate.getFullYear() : Infinity,
      m: 11,
      d: 31,
      h: step(this._hasAmPm ? 11 : 23, stepHour),
      i: step(59, stepMinute),
      s: step(59, stepSecond),
      a: 1,
      tt: 86400,
    };
    const steps: { [key: string]: number } = {
      y: 1,
      m: 1,
      d: 1,
      h: stepHour,
      i: stepMinute,
      s: stepSecond,
      a: 1,
      tt: this._timeStep,
    };
    // tslint:enable object-literal-sort-keys

    let init = false;
    let minprop = true;
    let maxprop = true;

    ['dd', 'y', 'm', 'd', 'tt', 'a', 'h', 'i', 's'].forEach((key: any) => {
      let min = mins[key];
      let max = maxs[key];
      let val = getDatePart[key](validated);

      const order = wheelOrder[key];

      if (minprop && minDate) {
        min = getDatePart[key](minDate);
      }
      if (maxprop && maxDate) {
        max = getDatePart[key](maxDate);
      }

      if (val < min) {
        val = min;
      }

      if (val > max) {
        val = max;
      }

      // Skip full date, full time, and am/pm wheel (if not present)
      if (key !== 'dd' && key !== 'tt' && !(key === 'a' && order === UNDEFINED)) {
        if (minprop) {
          minprop = val === min;
        }
        if (maxprop) {
          maxprop = val === max;
        }
      }

      if (order !== UNDEFINED) {
        disabled[order] = new Map<any, boolean>();

        if (key !== 'y' && key !== 'dd') {
          for (let i = mins[key]; i <= maxs[key]; i += steps[key]) {
            if (i < min || i > max) {
              disabled[order].set(i, true);
            }
          }
        }

        // Validate dates
        if (key === 'd' && invalids) {
          for (const d in invalids) {
            if (!valids || !valids[d]) {
              const dd = makeDate(d, s); // d is a string here
              const yy = s.getYear!(dd);
              const mm = s.getMonth!(dd);
              // If invalid is in the currently displayed month, let's add it
              if (yy === y && mm === m && isInvalid(s, dd, invalids, valids)) {
                disabled[order].set(s.getDay!(dd), true);
              }
            }
          }
        }
      }
    });

    // Validate times
    if (/time/i.test(preset)) {
      // TODO: merge overlapping invalids
      const invalidsForDay = invalids && invalids[getDateStr(validated)];
      const validsForDay = valids && valids[getDateStr(validated)];
      TIME_PARTS.forEach((key, i) => {
        const order = wheelOrder[key];
        if (order !== UNDEFINED) {
          const entries = s.valid ? validsForDay : invalidsForDay;
          if (entries) {
            if (s.valid) {
              // Set everything to invalid
              for (let j = 0; j <= maxs[key]; j++) {
                disabled[order].set(j, true);
              }
            }
            for (const entry of entries) {
              const start = entry.start;
              const end = entry.end;
              if (start && end) {
                validateTimes(
                  s,
                  this._hasAmPm,
                  i,
                  valid,
                  wheelOrder,
                  getDatePart,
                  maxs,
                  steps,
                  key,
                  disabled,
                  order,
                  validated,
                  start,
                  end,
                  !!s.valid,
                  s.exclusiveEndDates,
                );
              }
            }
          }
          // Get valid wheel value
          valid[order] = getValid(wheels[order], getDatePart[key](validated), disabled[order], direction);
        }
      });
    }

    // Regenerate day wheel if number of days in month changes
    // or if day names needs to be regenerated
    const dateDisplay = this._dateDisplay;
    if (dayWheel && (dayWheel.data!.length !== maxDays || /DDD/.test(dateDisplay))) {
      const data = [];
      const dayDisplay = dateDisplay
        .replace(/[my|]/gi, '')
        .replace(/DDDD/, '{dddd}')
        .replace(/DDD/, '{ddd}')
        .replace(/DD/, '{dd}')
        .replace(/D/, '{d}');
      for (let j = 1; j <= maxDays; j++) {
        const weekDay = s.getDate!(y, m, j).getDay();
        const dayStr = dayDisplay
          .replace(/{dddd}/, s.dayNames![weekDay])
          .replace(/{ddd}/, s.dayNamesShort![weekDay])
          .replace(/{dd}/, pad(j) + s.daySuffix!)
          .replace(/{d}/, j + s.daySuffix!);
        data.push({
          display: dayStr,
          value: j,
        });
      }
      dayWheel.data = data;
      // Will trigger wheel re-render
      // this._wheels[0][wheelOrder.d] = { ...dayWheel };
      // Will trigger wheel re-init in scroller validation
      init = true;
    }

    return { disabled, init, valid };
  };

  // public _shouldValidate = (s: MbscDatetimeOptions, prevS: MbscDatetimeOptions) => {
  // We're using any types here, since min/max are datetime options, and wheels are scroller options
  // This is a temporary solution, the wheels should be checked by the scroller
  public _shouldValidate = (s: any, prevS: any) => {
    return (
      !!((s.min && +s.min !== +prevS.min!) || (s.max && +s.max !== +prevS.max!)) ||
      s.wheels !== prevS.wheels ||
      s.dataTimezone !== prevS.dataTimezone ||
      s.displayTimezone !== prevS.displayTimezone
    );
  };

  public _valueEquals(v1: any, v2: any) {
    return dateValueEquals(v1, v2, this.s);
  }

  public _setScroller = (scroller: any) => {
    this._scroller = scroller;
  };

  // tslint:enable variable-name

  protected _render(s: MbscDatetimeOptions, state: MbscDatetimeState) {
    let genWheels = false;
    const prevProps = this._prevS;
    const dateFormat = s.dateFormat!;
    const timeFormat = s.timeFormat!;
    const preset = s.mode || this._preset;
    const format = preset === 'datetime' ? dateFormat + s.separator! + timeFormat : preset === 'time' ? timeFormat : dateFormat;

    this._value = s.value === UNDEFINED ? state.value : s.value;
    this._minWheelWidth = s.minWheelWidth || (preset === 'datetime' ? WHEEL_WIDTHS[s.baseTheme || s.theme] : UNDEFINED);
    this._dateWheels = s.dateWheels || (preset === 'datetime' ? s.dateWheelFormat! : dateFormat);
    this._dateDisplay = s.dateWheels || s.dateDisplay!;
    this._timeWheels = s.timeWheels || timeFormat;
    this._timeDisplay = this._timeWheels;
    this._format = format;
    this._hasAmPm = /h/.test(this._timeDisplay);
    // tslint:disable: object-literal-sort-keys
    this._getDatePart = {
      y: s.getYear!,
      m: s.getMonth!,
      d: s.getDay!,
      h: this._getHours,
      i: this._getMinutes,
      s: this._getSeconds,
      u: getMilliseconds,
      a: getAmPm,
      dd: getFullDate,
      tt: this._getFullTime,
    };
    // tslint:enable: object-literal-sort-keys

    if (+makeDate(prevProps.min) !== +makeDate(s.min)) {
      genWheels = true;
      this._min = isEmpty(s.min) ? UNDEFINED : makeDate(s.min, s, format);
    }

    if (+makeDate(prevProps.max) !== +makeDate(s.max)) {
      genWheels = true;
      this._max = isEmpty(s.max) ? UNDEFINED : makeDate(s.max, s, format);
    }

    if (
      s.theme !== prevProps.theme ||
      s.mode !== prevProps.mode ||
      s.locale !== prevProps.locale ||
      s.dateWheels !== prevProps.dateWheels ||
      s.timeWheels !== prevProps.timeWheels ||
      genWheels
    ) {
      this._wheels = this._getWheels();
    }
  }

  // tslint:disable variable-name

  protected _getYearValue = (i: number): any => {
    return {
      display: (/yy/i.test(this._dateDisplay) ? i : (i + '').substr(2, 2)) + this.s.yearSuffix!,
      value: i,
    };
  };

  protected _getYearIndex = (i: any): any => {
    return +i;
  };

  protected _getDateIndex = (i: any): any => {
    return getDateIndex(i, this._hasDay);
  };

  protected _getDateItem = (i: number) => {
    const s = this.s;
    const hasDay = this._hasDay;
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    let d = hasDay ? new Date(i * 8.64e7) : new Date(1970, i, 1);

    if (hasDay) {
      d = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
    }

    return {
      disabled: hasDay && isInvalid(s, d, this._invalids, this._valids),
      display: today.getTime() === d.getTime() ? s.todayText : formatDate(this._dateTemplate, d, s),
      value: getFullDate(d),
    };
  };

  // tslint:enable variable-name

  private _getWheels(): MbscScrollerWheel[][] {
    this._wheelOrder = {};

    const s = this.s;
    const preset = s.mode || this._preset;
    const hasAmPm = this._hasAmPm;
    const dateDisplay = this._dateDisplay;
    const timeDisplay = this._timeDisplay;
    const wheelOrder = this._wheelOrder;
    const wheels: MbscScrollerWheel[][] = [];
    const dateGroup: MbscScrollerWheel[] = [];

    let values: any[];
    let timeGroup: MbscScrollerWheel[] = [];
    let nr = 0;

    if (/date/i.test(preset)) {
      const dateParts = this._dateWheels.split(/\|/.test(this._dateWheels) ? '|' : '');

      for (const template of dateParts) {
        let types = 0;
        if (template.length) {
          // If contains different characters
          if (/y/i.test(template)) {
            types++;
          }

          if (/m/i.test(template)) {
            types++;
          }

          if (/d/i.test(template)) {
            types++;
          }

          if (types > 1 && wheelOrder.dd === UNDEFINED) {
            wheelOrder.dd = nr;
            nr++;
            dateGroup.push(this._getDateWheel(template));
            timeGroup = dateGroup; // TODO ???
            // oneDateWheel = true;
          } else if (/y/i.test(template) && wheelOrder.y === UNDEFINED) {
            wheelOrder.y = nr;
            nr++;

            // Year wheel
            dateGroup.push({
              cssClass: 'mbsc-datetime-year-wheel',
              getIndex: this._getYearIndex,
              getItem: this._getYearValue,
              max: this._max ? s.getYear!(this._max) : UNDEFINED,
              min: this._min ? s.getYear!(this._min) : UNDEFINED,
              spaceAround: true,
            });
          } else if (/m/i.test(template) && wheelOrder.m === UNDEFINED) {
            // Month wheel
            wheelOrder.m = nr;
            values = [];
            nr++;

            const monthDisplay = dateDisplay
              .replace(/[dy|]/gi, '')
              .replace(/MMMM/, '{mmmm}')
              .replace(/MMM/, '{mmm}')
              .replace(/MM/, '{mm}')
              .replace(/M/, '{m}');

            for (let j = 0; j < 12; j++) {
              const monthStr = monthDisplay
                .replace(/{mmmm}/, s.monthNames![j])
                .replace(/{mmm}/, s.monthNamesShort![j])
                .replace(/{mm}/, pad(j + 1) + (s.monthSuffix || ''))
                .replace(/{m}/, j + 1 + (s.monthSuffix || ''));

              values.push({
                display: monthStr,
                value: j,
              });
            }

            dateGroup.push({
              cssClass: 'mbsc-datetime-month-wheel',
              data: values,
              spaceAround: true,
            });
          } else if (/d/i.test(template) && wheelOrder.d === UNDEFINED) {
            // Day wheel
            wheelOrder.d = nr;
            values = [];
            nr++;

            for (let j = 1; j < 32; j++) {
              values.push({
                display: (/dd/i.test(dateDisplay) ? pad(j) : j) + s.daySuffix!,
                value: j,
              });
            }

            dateGroup.push({
              cssClass: 'mbsc-datetime-day-wheel',
              data: values,
              spaceAround: true,
            });
          }
        }
      }

      wheels.push(dateGroup);
    }

    if (/time/i.test(preset)) {
      const timeParts = this._timeWheels.split(/\|/.test(this._timeWheels) ? '|' : '');

      for (const template of timeParts) {
        let types = 0;
        if (template.length) {
          // If contains different characters
          if (/h/i.test(template)) {
            types++;
          }

          if (/m/i.test(template)) {
            types++;
          }

          if (/s/i.test(template)) {
            types++;
          }

          if (/a/i.test(template)) {
            types++;
          }
        }

        if (types > 1 && wheelOrder.tt === UNDEFINED) {
          wheelOrder.tt = nr;
          nr++;
          timeGroup.push(this._getTimeWheel(template));
        } else if (/h/i.test(template) && wheelOrder.h === UNDEFINED) {
          // Hours wheel
          values = [];
          wheelOrder.h = nr;
          nr++;

          for (let j = 0; j < (hasAmPm ? 12 : 24); j += s.stepHour!) {
            values.push({
              display: hasAmPm && j === 0 ? 12 : /hh/i.test(timeDisplay) ? pad(j) : j,
              value: j,
            });
          }

          timeGroup.push({
            cssClass: 'mbsc-datetime-hour-wheel',
            data: values,
            spaceAround: true,
          });
        } else if (/m/i.test(template) && wheelOrder.i === UNDEFINED) {
          // Minutes wheel
          values = [];
          wheelOrder.i = nr;
          nr++;

          for (let j = 0; j < 60; j += s.stepMinute!) {
            values.push({
              display: /mm/i.test(timeDisplay) ? pad(j) : j,
              value: j,
            });
          }

          timeGroup.push({
            cssClass: 'mbsc-datetime-minute-wheel',
            data: values,
            spaceAround: true,
          });
        } else if (/s/i.test(template) && wheelOrder.s === UNDEFINED) {
          // Seconds wheel
          values = [];
          wheelOrder.s = nr;
          nr++;

          for (let j = 0; j < 60; j += s.stepSecond!) {
            values.push({
              display: /ss/i.test(timeDisplay) ? pad(j) : j,
              value: j,
            });
          }

          timeGroup.push({
            cssClass: 'mbsc-datetime-second-wheel',
            data: values,
            spaceAround: true,
          });
        } else if (/a/i.test(template) && wheelOrder.a === UNDEFINED) {
          wheelOrder.a = nr;
          nr++;

          timeGroup.push({
            cssClass: 'mbsc-dt-whl-a',
            data: /A/.test(template)
              ? [
                  {
                    display: s.amText!.toUpperCase(),
                    value: 0,
                  },
                  {
                    display: s.pmText!.toUpperCase(),
                    value: 1,
                  },
                ]
              : [
                  {
                    display: s.amText!,
                    value: 0,
                  },
                  {
                    display: s.pmText!,
                    value: 1,
                  },
                ],
            spaceAround: true,
          });
        }
      }

      if (timeGroup !== dateGroup) {
        wheels.push(timeGroup);
      }
    }
    return wheels;
  }

  private _getDateWheel(template: string): MbscScrollerWheel {
    const hasDay = /d/i.test(template);
    this._hasDay = hasDay;
    this._dateTemplate = template;
    return {
      cssClass: 'mbsc-datetime-date-wheel',
      getIndex: this._getDateIndex,
      getItem: this._getDateItem,
      label: '',
      max: this._max ? getDateIndex(getFullDate(this._max), hasDay) : UNDEFINED,
      min: this._min ? getDateIndex(getFullDate(this._min), hasDay) : UNDEFINED,
      spaceAround: true,
    };
  }

  private _getTimeWheel(template: string): MbscScrollerWheel {
    const s = this.s;
    const values = [];

    let st = 1;

    if (/s/i.test(template)) {
      st = s.stepSecond!;
    } else if (/m/i.test(template)) {
      st = s.stepMinute! * 60;
    } else if (/h/i.test(template)) {
      st = s.stepHour! * 3600;
    }
    this._timeStep = st;

    for (let i = 0; i < 86400; i += st) {
      const time = new Date(new Date().setHours(0, 0, 0, 0) + i * 1000);
      values.push({
        display: formatDate(template, time, s),
        value: i,
      });
    }

    return {
      // cssClass: 'mbsc-datetime-time-wheel',
      data: values,
      label: '',
      spaceAround: true,
    };
  }

  private _getArray(d: Date, fillInner?: boolean): number[] {
    const parts = ['y', 'm', 'd', 'a', 'h', 'i', 's', 'u', 'dd', 'tt'];
    const ret: number[] = [];
    const wheelOrder = this._wheelOrder;

    if (d === null || d === UNDEFINED) {
      return ret;
    }

    for (const part of parts) {
      const v = this._getDatePart[part](d);
      if (wheelOrder[part] !== UNDEFINED) {
        ret[wheelOrder[part]] = v;
      }
      if (fillInner) {
        this._innerValues[part] = v;
      }
    }

    return ret;
  }

  // tslint:disable-next-line: variable-name
  private _getArrayPart = (values: any[], part: string, d?: Date, def?: Date) => {
    let ret: any;

    if (this._wheelOrder[part] !== UNDEFINED) {
      ret = +values[this._wheelOrder[part]];
      if (!isNaN(ret)) {
        return ret;
      }
    }

    if (d) {
      return this._getDatePart[part](d);
    }

    if (this._innerValues[part] !== UNDEFINED) {
      return this._innerValues[part];
    }

    return this._getDatePart[part](def);
  };

  // tslint:disable-next-line: variable-name
  private _getHours = (d: Date): number => {
    let hour = d.getHours();
    hour = this._hasAmPm && hour >= 12 ? hour - 12 : hour;
    // TODO: check if min/max needed here
    // return step(hour, this.s.stepHour, minHour, maxHour);
    return step(hour, this.s.stepHour!);
  };

  // tslint:disable-next-line: variable-name
  private _getMinutes = (d: Date): number => {
    // TODO: check if min/max needed here
    // return step(d.getMinutes(), this.s.stepMinute, minMinute, maxMinute);
    return step(d.getMinutes(), this.s.stepMinute!);
  };

  // tslint:disable-next-line: variable-name
  private _getSeconds = (d: Date): number => {
    // TODO: check if min/max needed here
    // return step(d.getSeconds(), this.s.stepSecond, minSecond, maxSecond);
    return step(d.getSeconds(), this.s.stepSecond!);
  };

  // tslint:disable-next-line: variable-name
  private _getFullTime = (d: Date): number => {
    // TODO: check if min/max needed here
    // return step(Math.round((d.getTime() - new Date(d).setHours(0, 0, 0, 0)) / 1000), this._timeStep || 1, 0, 86400);
    return step(round((d.getTime() - new Date(d).setHours(0, 0, 0, 0)) / 1000), this._timeStep || 1);
  };
}
