import React, { createContext, useState } from "react";
import { useAuth } from "../modules/auth/core/AuthCognito";
import useWebSocket from "react-use-websocket";
export const webSocketContext = createContext({});
let WSURL = process.env.REACT_APP_WSURL || "ws://localhost:6068";
export const WebsocketProvider = ({ children }: any) => {
  const { currentUser } = useAuth();
  const [socketMessage,setSocketMessage] = useState<any>(null)
  const userId = currentUser?.data?.user?._id || ""
  const organizationId = currentUser?.data?.user?.organizationId || ""  
  useWebSocket(`${WSURL}?dataId=${organizationId}-${userId}`, {
    onOpen() {
    },
    onMessage(event) {
      setSocketMessage(event)
    },
    shouldReconnect: () => true,
  });
  const ret = {
    socketMessage
  };

  return (
    <webSocketContext.Provider value={ret}>
      {children}
    </webSocketContext.Provider>
  );
};
