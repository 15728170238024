import { FC } from "react";
import { MenuInner } from "./MenuInner";

const Header: FC = () => {
 
  return (
    <div
      id="kt_header_menu"
      className="header-menu align-items-stretch d-flex"
      // data-kt-drawer="true"
      // data-kt-drawer-name="header-menu"
      // data-kt-drawer-activate="{default: true, lg: false}"
      // data-kt-drawer-overlay="true"
      // data-kt-drawer-width="{default:'200px', '300px': '100%'}"
      // data-kt-drawer-direction="end"
      // data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
      // data-kt-swapper="true"
      // data-kt-swapper-mode="prepend"
      // data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
      <div
        className="menu align-items-center"
        id="#kt_header_menu"
        data-kt-menu="true"
      >
      <MenuInner />
      </div>
    </div>
  );
};

export { Header };
