import { isDate } from './datetime';
import { MbscTimezonedDate, MbscTimezonePlugin } from './datetime.types.public';
import { isArray, isNumber, isObject, isString, isUndefined, UNDEFINED } from './misc';

// tslint:disable: no-use-before-declare

let localTimezone: string;

function normTimezone(timezone?: string): string {
  if (!localTimezone) {
    localTimezone = luxonTimezone.luxon.DateTime.local().zoneName;
  }
  return !timezone || timezone === 'local' ? localTimezone : timezone;
}

/**
 * Checks which version of luxon library is used, version 1 or 2+
 * @param DT
 * @returns 1 for version 1.x and 2 for versions above 2.0, depending on the DT.fromObject function
 */
function getVersion(DT: any): number {
  const fn = DT.fromObject.toString().trim();
  return /^(function )?\w*\(\w+\)/.test(fn) ? 1 : 2;
}

export class LDate implements MbscTimezonedDate {
  // tslint:disable-next-line
  public _mbsc = true;

  private dt: any;
  private zone: string;

  constructor(value?: string | number | Date | number[], timezone = 'utc') {
    timezone = normTimezone(timezone);
    const DT = luxonTimezone.luxon.DateTime;
    const zoneOpt = { zone: timezone };

    this.zone = timezone;

    if (isUndefined(value)) {
      this.dt = DT.utc().setZone(timezone);
    } else if (isDate(value) || isNumber(value)) {
      this.dt = DT.fromMillis(+value, zoneOpt);
    } else if (isString(value)) {
      this.dt = DT.fromISO(value, zoneOpt);
    } else if (isArray(value)) {
      const keys = ['year', 'month', 'day', 'hour', 'minute', 'second', 'millisecond'];
      const valueObj: { [key: string]: number } = {};
      for (let i = 0; i < value.length && i < 7; i++) {
        valueObj[keys[i]] = value[i] + (i === 1 ? 1 : 0);
      }
      // In version 2+ of luxon, the options (the zone) should go into a second parameter.
      // To work with both version 1 and 2 we need to determine the version of luxon if not provided explicitly.
      luxonTimezone.version = luxonTimezone.version || getVersion(DT);
      if (luxonTimezone.version === 1) {
        // v1.x
        this.dt = DT.fromObject({ ...valueObj, ...zoneOpt });
      } else {
        // v2+
        this.dt = DT.fromObject(valueObj, zoneOpt);
      }
    }
  }

  public clone() {
    return new LDate(this, this.zone);
  }

  public createDate(value: number | Date): MbscTimezonedDate;
  public createDate(
    year?: number | string | MbscTimezonedDate | Date,
    month?: number,
    date?: number,
    h?: number,
    min?: number,
    sec?: number,
    ms?: number,
  ): MbscTimezonedDate {
    return luxonTimezone.createDate({ displayTimezone: this.zone }, year, month, date, h, min, sec, ms);
  }

  /**
   * Converts a Date object to a string.
   */
  public [Symbol.toPrimitive](hint: 'number'): number;
  public [Symbol.toPrimitive](hint: 'default' | 'string'): string;
  public [Symbol.toPrimitive](hint: 'default' | 'number' | 'string'): string | number {
    return this.dt.toJSDate()[Symbol.toPrimitive](hint);
  }

  public toDateString() {
    return this.dt.toFormat('ccc MMM dd yyyy');
  }
  public toISOString() {
    return this.dt.toISO();
  }
  public toJSON() {
    return this.dt.toISO();
  }
  public valueOf() {
    return this.dt.valueOf();
  }

  // Getters

  public getDate() {
    return this.dt.day;
  }
  public getDay() {
    return this.dt.weekday % 7;
  }
  public getFullYear() {
    return this.dt.year;
  }
  public getHours() {
    return this.dt.hour;
  }
  public getMilliseconds() {
    return this.dt.millisecond;
  }
  public getMinutes() {
    return this.dt.minute;
  }
  public getMonth() {
    return this.dt.month - 1;
  }
  public getSeconds() {
    return this.dt.second;
  }
  public getTime() {
    return this.valueOf();
  }
  public getTimezoneOffset() {
    return -this.dt.offset;
  }
  public getUTCDate() {
    return this.dt.toUTC().day;
  }
  public getUTCDay() {
    return this.dt.toUTC().weekday % 7;
  }
  public getUTCFullYear() {
    return this.dt.toUTC().year;
  }
  public getUTCHours() {
    return this.dt.toUTC().hour;
  }
  public getUTCMilliseconds() {
    return this.dt.toUTC().millisecond;
  }
  public getUTCMinutes() {
    return this.dt.toUTC().minute;
  }
  public getUTCMonth() {
    return this.dt.toUTC().month - 1;
  }
  public getUTCSeconds() {
    return this.dt.toUTC().second;
  }

  // Setters

  public setMilliseconds(millisecond: number) {
    return this.setter({ millisecond });
  }
  public setSeconds(second: number, millisecond?: number) {
    return this.setter({ second, millisecond });
  }
  public setMinutes(minute: number, second?: number, millisecond?: number) {
    return this.setter({ minute, second, millisecond });
  }
  public setHours(hour: number, minute?: number, second?: number, millisecond?: number) {
    return this.setter({ hour, minute, second, millisecond });
  }
  public setDate(day: number) {
    return this.setter({ day });
  }
  public setMonth(month: number, day?: number) {
    month++;
    return this.setter({ month, day });
  }
  public setFullYear(year: number, month?: number, day?: number) {
    return this.setter({ year, month, day });
  }
  public setTime(time: number) {
    this.dt = luxonTimezone.luxon.DateTime.fromMillis(time);
    return this.dt.valueOf();
  }
  public setTimezone(timezone: string) {
    timezone = normTimezone(timezone);
    this.zone = timezone;
    this.dt = this.dt.setZone(timezone);
  }

  // The correct implementations of the UTC methods are omitted for not using them currently
  // but because of the Date interface they must be present
  public setUTCMilliseconds(ms: number) {
    return 0;
  }
  public setUTCSeconds(sec: number, ms?: number) {
    return 0;
  }
  public setUTCMinutes(min: number, sec?: number, ms?: number) {
    return 0;
  }
  public setUTCHours(hours: number, min?: number, sec?: number, ms?: number) {
    return 0;
  }
  public setUTCDate(date: number) {
    return 0;
  }
  public setUTCMonth(month: number, date?: number) {
    return 0;
  }
  public setUTCFullYear(year: number, month?: number, date?: number) {
    return 0;
  }
  public toUTCString() {
    return '';
  }
  public toTimeString() {
    return '';
  }
  public toLocaleDateString() {
    return '';
  }
  public toLocaleTimeString() {
    return '';
  }

  private setter(obj: any) {
    this.dt = this.dt.set(obj);
    return this.dt.valueOf();
  }
}

export interface ILuxonTimezonePlugin extends MbscTimezonePlugin {
  luxon: any;
  version?: number;
}

/** @hidden */
export const luxonTimezone: ILuxonTimezonePlugin = {
  luxon: UNDEFINED,
  version: UNDEFINED,
  parse(date, s) {
    return new LDate(date, s.dataTimezone || s.displayTimezone);
  },
  /**
   * Supports two call signatures:
   * createDate(settings, dateObj|timestamp)
   * createDate(settings, year, month, date, hour, min, sec)
   * @returns IDate object
   */
  createDate(
    s: any,
    year?: number | string | Date,
    month?: number,
    day?: number,
    hour?: number,
    minute?: number,
    second?: number,
    millisecond?: number,
  ) {
    const displayTimezone = s.displayTimezone;
    if (isObject(year) || isString(year) || isUndefined(month)) {
      return new LDate(year, displayTimezone);
    }
    return new LDate([year || 1970, month || 0, day || 1, hour || 0, minute || 0, second || 0, millisecond || 0], displayTimezone);
  },
};
