import { DatepickerBase, modules } from './datepicker';
import { MbscDatepickerOptions } from './datepicker.types.public';
import { Calendar } from '../../../react/components/calendar';
import { Datetime } from '../../../react/components/datetime';
import { Icon } from '../../../react/components/icon';
import { Segmented, SegmentedGroup } from '../../../react/components/segmented';
import { Timegrid } from '../../../react/components/timegrid';
import { Fragment } from '../../../react/renderer';
import { pickerTemplate } from '../../shared/picker/picker.common';
import './datepicker.scss';
import React from 'react';

export { CalendarNext, CalendarPrev, CalendarToday, CalendarNav } from '../../../react/shared/calendar-header';

export function template(s: MbscDatepickerOptions, inst: DatepickerBase, content: any, slots?: any): any {
  const hasTabs = inst._renderTabs;
  const controls = inst._controls!;
  const activeSelect = inst._activeSelect;
  const rtl = inst._rtl;
  const theme = inst._theme;
  return (
    <Fragment>
      <div
        className={
          'mbsc-datepicker mbsc-flex-col mbsc-datepicker-' +
          s.display +
          theme +
          (s.display === 'inline' ? ' ' + inst._className + inst._hb : '') +
          inst._controlsClass
        }
      >
        {inst._headerText && s.display === 'inline' && <div className={'mbsc-picker-header' + theme + inst._hb}>{inst._headerText}</div>}
        {hasTabs && (
          <SegmentedGroup
            rtl={s.rtl}
            theme={s.theme}
            themeVariant={s.themeVariant}
            value={inst._activeTab}
            onChange={inst._changeActiveTab}
          >
            {controls.map((control, i) => {
              return (
                <Segmented key={i} rtl={s.rtl} theme={s.theme} themeVariant={s.themeVariant} value={control.name}>
                  {control.title}
                </Segmented>
              );
            })}
          </SegmentedGroup>
        )}
        {inst._renderControls && (
          <div className={'mbsc-range-control-wrapper' + theme}>
            <SegmentedGroup
              theme={s.theme}
              themeVariant={s.themeVariant}
              rtl={s.rtl}
              value={activeSelect}
              onChange={inst._changeActiveSelect}
            >
              <Segmented
                rtl={s.rtl}
                theme={s.theme}
                themeVariant={s.themeVariant}
                value="start"
                className={'mbsc-range-start' + (inst._tempStartText ? ' mbsc-range-value-nonempty' : '')}
              >
                <div className={'mbsc-range-control-label' + theme + rtl + (activeSelect === 'start' ? ' active' : '')}>
                  {s.rangeStartLabel}
                </div>
                <div
                  className={
                    'mbsc-range-control-value' +
                    theme +
                    rtl +
                    (activeSelect === 'start' ? ' active' : '') +
                    (!inst._tempStartText ? ' mbsc-range-control-text-empty' : '')
                  }
                >
                  {inst._tempStartText || s.rangeStartHelp}
                </div>
                {activeSelect === 'start' && inst._tempStartText && (
                  <Icon className={'mbsc-range-label-clear' + rtl} onClick={inst._clearStart} svg={s.clearIcon} theme={s.theme} />
                )}
              </Segmented>
              <Segmented
                rtl={s.rtl}
                theme={s.theme}
                themeVariant={s.themeVariant}
                value="end"
                className={'mbsc-range-end' + (inst._tempEndText ? ' mbsc-range-value-nonempty' : '')}
              >
                <div className={'mbsc-range-control-label' + theme + rtl + (activeSelect === 'end' ? ' active' : '')}>
                  {s.rangeEndLabel}
                </div>
                <div
                  className={
                    'mbsc-range-control-value' +
                    theme +
                    rtl +
                    (activeSelect === 'end' ? ' active' : '') +
                    (!inst._tempEndText ? ' mbsc-range-control-text-empty' : '')
                  }
                >
                  {inst._tempEndText || s.rangeEndHelp}
                </div>
                {activeSelect === 'end' && inst._tempEndText && (
                  <Icon className={'mbsc-range-label-clear' + rtl} onClick={inst._clearEnd} svg={s.clearIcon} theme={s.theme} />
                )}
              </Segmented>
            </SegmentedGroup>
          </div>
        )}
        <div className={'mbsc-datepicker-tab-wrapper mbsc-flex mbsc-flex-1-1' + theme} ref={inst._setWrapper}>
          {controls.map((control, i) => {
            const options = control.options;

            if (slots) {
              options.renderCalendarHeader = slots.header;
              options.renderDay = slots.day;
              options.renderDayContent = slots.dayContent;
            }

            return (
              <div
                key={i}
                className={
                  'mbsc-flex mbsc-datepicker-tab mbsc-datepicker-tab-' +
                  control.name +
                  theme +
                  ((hasTabs && control.name === inst._activeTab) || !hasTabs ? ' mbsc-datepicker-tab-active' : '') +
                  (hasTabs && control.name === 'time' ? ' mbsc-datepicker-time-modal' : '') +
                  (hasTabs || controls.length === 1 ? ' mbsc-datepicker-tab-expand mbsc-flex-1-1' : '')
                }
              >
                <control.Component {...options} />
              </div>
            );
          })}
        </div>
      </div>
      {content}
    </Fragment>
  );
}

export class Datepicker extends DatepickerBase {
  constructor(props: MbscDatepickerOptions) {
    modules[Datetime._name] = Datetime;
    modules[Calendar._name] = Calendar;
    modules[Timegrid._name] = Timegrid;
    super(props);
  }

  protected _template(s: MbscDatepickerOptions): any {
    return pickerTemplate(this, s, template(s, this, s.children));
  }
}
