
import { BaseComponent, IBaseProps } from '../../../base';
import { MbscCalendarDayData } from '../../../shared/calendar-view/calendar-day';
import { MbscCalendarEvent } from '../../../shared/calendar-view/calendar-view.types';
import { gestureListener } from '../../../util/gesture';

// tslint:disable no-non-null-assertion
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

// tslint:disable-next-line interface-name
export interface MbscWeekDayOptions extends IBaseProps {
  background?: string;
  day: number;
  dayNames: string[];
  events: MbscCalendarEvent[];
  isToday: boolean;
  label: string;
  largeNames: boolean;
  resource?: string | number;
  rtl: boolean;
  selectable: boolean;
  selected: boolean;
  theme: string;
  timestamp: number;
  // Events
  onClick: any;
  dayTemplate?(args: MbscCalendarDayData): any;
  dayContentTemplate?(args: MbscCalendarDayData): any;
  renderDay?(args: MbscCalendarDayData): any;
  renderDayContent?(args: MbscCalendarDayData): any;
}

// tslint:disable-next-line interface-name
export interface MbscWeekDayState {
  hasHover: boolean;
}

/** @hidden */

export class WeekDayBase extends BaseComponent<MbscWeekDayOptions, MbscWeekDayState> {
  // tslint:disable variable-name
  public _cssClass?: string;
  public _data!: MbscCalendarDayData;
  public _day!: number;

  private _unlisten?: () => void;
  // tslint:enable variable-name

  // tslint:disable-next-line variable-name
  public _onClick = () => {
    const s = this.s;

    if (s.selectable) {
      s.onClick(s.timestamp);
    }
  };

  protected _render(s: MbscWeekDayOptions, state: MbscWeekDayState) {
    const date = new Date(s.timestamp);

    this._cssClass =
      'mbsc-schedule-header-item ' +
      this._className +
      this._theme +
      this._rtl +
      this._hb +
      (s.largeNames ? ' mbsc-schedule-header-item-large' : '') +
      (s.selected ? ' mbsc-selected' : '') +
      (state.hasHover ? ' mbsc-hover' : '');

    this._data = {
      date,
      events: s.events || [],
      resource: s.resource,
      selected: s.selected,
    };

    this._day = date.getDay();
  }

  protected _mounted() {
    this._unlisten = gestureListener(this._el, {
      onHoverIn: () => {
        if (this.s.selectable) {
          this.setState({ hasHover: true });
        }
      },
      onHoverOut: () => {
        if (this.s.selectable) {
          this.setState({ hasHover: false });
        }
      },
    });
  }

  protected _destroy() {
    if (this._unlisten) {
      this._unlisten();
    }
  }
}
