import {
  Button,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Datepicker,
  SegmentedGroup,
  SegmentedItem
} from "@mobiscroll/react";
import moment from "moment";
import React, { FC, useCallback, useMemo, useState } from "react";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import { useAuth } from "../../../auth";
import LayoutButtonPopup from "../../../../components/modals/layoutButton/LayoutButtonPopup";
let disabled_year_id = process.env.REACT_APP_ORGANIZATIONID || "";


const CustomWithNavButtons: FC<any> = ({
  buttonText,
  startDate,
  endDate,
  setCurrentDate,
  setRefDate,
  getNrDays,
  setView,
  setCalView,
  extendedMorningStart,
  extendedEveningEnd,
  setRangeVal,
  currentDate,
  sortTooltip,
  sortActive,
  sortIcon,
  sortactivetextcolor,
  sortData,
  setPlanningcapacityview,
  view,
  handleclick,
  activetextcolor,
  toplanActive,
  showtodo_onclick,
  toplanactivetextcolor,
  pagination,
  offlineCount,
  rangeVal,
  activebtn,
  viewSettingsData,
  layoutAndFiltersData,
  selectActiveLayout,
  refetchLayouts,
  filterIds,
  toggleDropdown,
  openDropdown
}) => {
  const [onChangeDate, setOnChangeDate] = useState(false)
  const { currentUser } = useAuth();
  const  renderDayWeekView = (view) => {
    setPlanningcapacityview(view)
  }
  const  renderMonthYearView = (view) => {
    setPlanningcapacityview(view)
  }
  const handleDayView = () => {
    renderDayWeekView("day")
  }
  const handleWeekView = () => {
    renderDayWeekView("week")
  }
  const handleMonthView = () => {
    renderMonthYearView("month")
  }
  const handleYearView = () => {
    renderMonthYearView("year")
  }
  const buttonProps = useMemo(() => {
    const content = (
      <span className="mbsc-calendar-title d-inline-flex align-items-center">
        {buttonText}
        <svg
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="ms-2"
        >
          <path
            d="M10.9498 1.75L6.87481 5.825C6.39355 6.30625 5.60605 6.30625 5.12481 5.825L1.0498 1.75"
            stroke="currentcolor"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    );
    return {
      children: content,
      className: "mbsc-calendar-button",
      variant: "flat",
    };
  }, [buttonText]);

  const onDatePickerClose = useCallback((event: any, inst: any) => {
    if (onChangeDate === true && startDate.current && endDate.current) {
      // navigate the calendar
      setCurrentDate(startDate.current);
      // set calendar view
      setRefDate(startDate.current);
      if (
        getNrDays(startDate.current, endDate.current) > 1 &&
        getNrDays(startDate.current, endDate.current) <= 7
      ) {
        setView("week");
        if (moment(startDate.current).format('ddd') === 'Mon' && moment(endDate.current).format('ddd') === "Fri") {
          setCalView({
            timeline: {
              type: "week",
              timeCellStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
              timeLabelStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
              startTime: extendedMorningStart,
              endTime: extendedEveningEnd,
              startDay:(viewSettingsData?.start || viewSettingsData?.start === 0) ? +viewSettingsData?.start : 1,
              endDay: (viewSettingsData?.end || viewSettingsData?.end === 0)  ? +viewSettingsData?.end : 5,
              weekNumbers: false,
              maxEventStack: 'all'
            },
          });
        } else {
          setCalView({
            timeline: {
              type: "day",
              size: getNrDays(startDate.current, endDate.current),
              timeCellStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60, 
              timeLabelStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
              startTime: extendedMorningStart,
              endTime: extendedEveningEnd,
              weekNumbers: false,
              maxEventStack: 'all'
            },
          });
        }
      } else if (
        getNrDays(startDate.current, endDate.current) > 7 &&
        getNrDays(startDate.current, endDate.current) < 32
      ) {
        setView("month");
        setCalView({
          timeline: {
            type: "day",
            size: getNrDays(startDate.current, endDate.current),
            startTime: extendedMorningStart,
            endTime: extendedEveningEnd,
            startDay:1,
            endDay:5,
            eventList: true,
          },
        });
      } else if (getNrDays(startDate.current, endDate.current) >= 32) {
        setView("year");
        setCalView({
          timeline: {
            type: "day",
            size: getNrDays(startDate.current, endDate.current),
            startDay:1,
            endDay:5,
            weekNumbers: true,
            eventList: true,
          },
        });
      } else {
        setView("day");
        setCalView({
          timeline: {
            type: "day",
            size: getNrDays(startDate.current, endDate.current),
            timeCellStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
            timeLabelStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
            startTime: extendedMorningStart,
            endTime: extendedEveningEnd,
            maxEventStack: 'all'
          },
        });
      }
      setOnChangeDate(false)
    }
    setRangeVal([startDate.current, endDate.current]);
  }, [getNrDays, currentDate, onChangeDate]);
  
  const onChange = useCallback(
    (args: any) => {
      setOnChangeDate(true)
      const date = args.value;
      setRangeVal(date);
      if (date[0] && date[1]) {
        startDate.current = date[0];
        endDate.current = date[1];
      }
    },
    [currentDate]
  );
  
  const renderPlannBucketTooltip = (
    <Popover id="renderAddUserTooltip">
      <div className="p-5 bg-white card">
        <div>
        All your task, ticket, and service calls from Autotask. Drag an item from the Plan bucket and drop it on the Planning Board to create a service call and its outlook appointment.
          <a className="text-decoration-underline ms-2"
            href="https://knowledgebase.proxuma.io/article/what-is-to-plan-function-and-what-can-you-see-and-do"
            target="_blank"
          >
            Learn more
          </a>
        </div>
      </div>
    </Popover>
  )
  return (
    <>
      <div className="d-flex align-items-center justify-content-between w-100 flex-wrap pickerMain">
        <div className="picLeft d-flex align-items-center">
          <div className="me-4" id="planning-datepiker">
            <Datepicker
              select="range"
              display="anchored"
              showOverlay={false}
              touchUi={true}
              buttons={[]}
              inputComponent={Button}
              inputProps={buttonProps}
              onClose={onDatePickerClose}
              maxRange={(disabled_year_id.includes(currentUser?.data?.user?.organizationId)) ? 31 : undefined}
              onChange={onChange}
              value={rangeVal}
            />
          </div>
          <div className="d-flex align-items-center md-custom-range-view-controls">
            <div id="date-range-prevbutton"><CalendarPrev /></div>
            <div id="date-range-today"><CalendarToday /></div>
            <div id="date-range-nextbutton"><CalendarNext /></div>
          </div>
        </div>

        <div className="picMid d-flex align-items-center">
          <div className="filterBtns">
            <OverlayTrigger
              key="copy-to-clipboard"
              placement="bottom"
              overlay={
                <Tooltip
                  id="tooltip-copy-to-clipboard"
                  className="position-absolute hideonHover"
                >
                  <span className="pe-2">{sortTooltip}</span>
                </Tooltip>
              }
            >
              <button
                style={{ backgroundColor: sortActive }}
                className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 py-0 h-32px fs-16 d-inline-flex align-items-center me-3"
                type="button"
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-start"
                data-kt-menu-flip="bottom"
              >
                <KTSVG
                  path={sortIcon}
                  className={`me-2 ${sortactivetextcolor}`}
                  svgClassName="w-16px h-auto"
                />
                <span className={`${sortactivetextcolor}`}>Sort by</span>
              </button>
            </OverlayTrigger>
            <div
              className="menu menu-sub menu-sub-dropdown bg-white px-3 py-5 w-125px fs-13"
              data-kt-menu="true"
            >
              <span className="mb-3" style={{ color: "grey" }}>
                Resource name
              </span>
              <a
                className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary"
                data-name="people_asc"
                onClick={sortData}
                href="javascript:void(0)"
              >
                ASC
              </a>
              <a
                onClick={sortData}
                data-name="people_desc"
                className="d-flex align-items-center text-black fw-semibold text-hover-primary"
                href="javascript:void(0)"
              >
                DESC
              </a>
            </div>
          </div>

          <div className="bg-white border border-primary rounded-pill me-1 switchBtns">
            <SegmentedGroup
              value={view}
              className="m-0 bg-transparent p-0 fs-16 rounded-pill"
            >
              <SegmentedItem
                value="day"
                className=""
                onChange={handleDayView}
              >
                Day
              </SegmentedItem>
              <SegmentedItem
                value="week"
                className=""
                onChange={handleWeekView}
              >
                Week
              </SegmentedItem>
              <SegmentedItem
                value="month"
                className=""
                onChange={handleMonthView}
              >
                Month
              </SegmentedItem>
              {!disabled_year_id.includes(currentUser?.data?.user?.organizationId) &&
                <SegmentedItem
                  value="year"
                  className=""
                  onChange={handleYearView}
                >
                  Year
                </SegmentedItem>
              }
            </SegmentedGroup>
          </div>
        </div>

        <div className="picRight d-flex">
          <button
            className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 py-0 h-32px fs-16 d-inline-flex align-items-center me-3"
            onClick={handleclick}
            style={{ backgroundColor: activebtn }}
            id="capacity-graph-button"
          >
            <KTSVG
              path="/media/icons/duotune/new-icons/capacity.svg"
              className={`me-2 ${activetextcolor}`}
              svgClassName="w-18px h-auto"
            />
            <span className={activetextcolor}>Graphs</span>
          </button>
          <button
            style={{ backgroundColor: toplanActive }}
            onClick={showtodo_onclick}
            className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 pe-1 py-0 h-32px fs-16 d-inline-flex align-items-center me-1"
            id="plan-bucket"
          >
            <KTSVG
              path="/media/icons/duotune/new-icons/to-plan.svg"
              className={`me-2 ${toplanactivetextcolor}`}
              svgClassName="w-16px h-auto"
            />
            <b className={`fw-normal ${toplanactivetextcolor}`}>Plan bucket</b>
            &nbsp;
            <small className="badge rounded-pill badge-primary bg-yellow text-dark fs-100 top-0 ms-2 d-inline-block min-w-24px min-h-24px">
              {pagination?.total && pagination?.total + offlineCount > 0
                ? pagination?.total + offlineCount
                : "0"}
            </small>
          </button>
          <div className="h-32px d-flex align-items-start">
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              overlay={renderPlannBucketTooltip}
            >
              <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn me-3">i</button>
            </OverlayTrigger>
          </div>

          <LayoutButtonPopup
            layoutAndFiltersData={layoutAndFiltersData}
            selectActiveLayout={selectActiveLayout}
            refetchLayouts={refetchLayouts}
            layoutType="planning"
            payload={{
              planningPayload: filterIds
            }}
            toggleDropdown={toggleDropdown}
            openDropdown ={openDropdown}
          />
        </div>
      </div>
    </>
  );
};

export default CustomWithNavButtons;