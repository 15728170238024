import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { getNotification, getNotificationcount } from "../../../../_metronic/requests/NotificationRequest";
import  NotificationView  from "./NotificationView";
import { useAuth } from "../../auth/core/AuthCognito";
import { getGeneralSetting } from "../../../../_metronic/requests/GeneralSettingRequest";
import { Steps } from "intro.js-react";
import { getTooltipData } from "../../superadmin/hint-tour/core/_request";
import { getCurrentUserDetails, updateTooltipVisibilityByPage } from "../common/_requests";

const NotificationPage = () => {
  const {
    isFetching: isfetchnotification,
    data: notificationdata } = useQuery(
    `notification-list`,
    async () => {
        return await getNotification('');
    },
    {
        cacheTime: 0,
        onError: (err) => {
            console.error(err);
        },
        onSuccess: (data: any) => {
          localStorage.setItem('sucesscount','0');
          localStorage.setItem('failcount','0');
        }
    }
);

const { currentUser } = useAuth();

const Arrnotification = notificationdata && notificationdata?.data?.filter((imp: any) => {
  let keep = true;
  if (!["sync process", "unified_sync"].includes(imp.type)) {
    keep = keep && imp.organizationId === currentUser?.data?.user?.organizationId && imp.userId === currentUser?.data?.user?._id;
  } 
  if (imp?.type === "sync process" || imp?.type === "unified_sync") {
    keep = keep && imp.organizationId === currentUser?.data?.user?.organizationId;
  }
  return keep;
});

  let notifications: any = notificationdata ? Arrnotification : [];

  let groups = notifications.reduce((groups: any, notification: any) => {
    let date = notification.created_at!.split('T')[0];
    if (!groups[date as keyof Notification]) {
      groups[date as keyof Notification] = [];
    }
    groups[date as keyof Notification].push(notification);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date,
      notificationdata: groups[date as keyof Notification]
    };
  });

  const {
    isFetching: isfetchgeneralsetting,
    data: generalSetting
  } = useQuery(
    `planning-resource-setting`,
    async () => {
      return await getGeneralSetting('');
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const timezone: any = localStorage.getItem('timezone') ? localStorage.getItem('timezone') : generalSetting?.timezone;
  const currDate = moment(new Date).tz(timezone).format('DD-MM-Y');
  const yesterday = moment().tz(timezone).subtract(1, 'days').format('DD-MM-Y');

  const changeDate = (date: any) => {
    switch (date) {
      case currDate:
        return 'Today'
      case yesterday:
        return 'Yesterday'
      default:
        return date;
    }
  }
  const {
    data: notification } = useQuery(
    `notification-data`,
    async () => {
        return await getNotificationcount('');
    },
    {
        cacheTime: 0,
        onError: (err) => {
            console.error(err);
        },
    }
);
  useEffect(()=> {
    if(notification){
    localStorage.setItem('sucesscount','0');
    localStorage.setItem('failcount','0');
    }
  },[notification]);

  const { data: tooltipData } = useQuery(
    "tooltip-Data",
    () => {
      return getTooltipData();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );
  const { data: loggedUser } = useQuery(
    'logged-user',
    async () => {
      return await getCurrentUserDetails(_id)
    },
    {
      cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false
    }
  )
  const steps = tooltipData?.filter(
    (v: any) => v.type === "tour" && v.page === "Notifications"
  );

  const  _id  = currentUser?.data?.user?._id
  const [enabled, setEnabled] = useState<boolean>(true);
  const [initialStep] = useState<number>(0);
  
  const tooltipVisibility = {
    showTooltipVisibility: {
      Notifications: false,
    },
  };
  const onExit = async () => {
    setEnabled(false);
    await updateTooltipVisibilityByPage(_id, tooltipVisibility);
  };
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="list"
          element={
            <>
              {loggedUser && loggedUser?.showTooltipVisibility?.Notifications !== false && groupArrays.length > 0 && (
                <Steps
                  enabled={enabled}
                  initialStep={initialStep}
                  steps={steps?.length > 0 ? steps : []}
                  onExit={onExit}
                  options={{
                    showStepNumbers: true,
                    showButtons: true,
                    hidePrev: true,
                    scrollToElement: true,
                    showBullets: true,
                    showProgress: true,
                    exitOnOverlayClick: false,
                  }}
                />
              )}
              <NotificationView
                loading={isfetchnotification}
                data={groupArrays}
                changeDateFormat={changeDate}
                setting={generalSetting}
                isfetchgeneralsetting={isfetchgeneralsetting}
              />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="/apps/notifications/list" />} />
    </Routes>
  );
};

export default NotificationPage;