import { useEffect, useContext } from 'react';
import showConfirmation from './showConfirmation';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

function useBlocker(blocker, when) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = navigator.block(async (tx) => {
      const allowNavigation = await blocker();
      if (allowNavigation) {
        unblock(); // Allow navigation
        tx.retry(); // Proceed with navigation
      }
    });

    return () => {
      unblock();  // Clean up the navigation blocker
    };
  }, [navigator, blocker, when]);
}

// Hook to handle page navigation prompts, including browser back button
export function usePrompt(message, when, onConfirmReload) {
  const blocker = () => showConfirmation(message, onConfirmReload);
  useBlocker(blocker, when);
}

export function useBeforeUnload(when: boolean, message: string, onConfirmReload: () => void) {
  useEffect(() => {
    if (!when) return;

    // Handler for beforeunload event
    const handleBeforeUnload = (event) => {
      if (when) {
        event.preventDefault();
        event.returnValue = message; // Display the native browser confirmation dialog
      }
    };

    // Handler for unload event (only fires if the user confirms the reload/close)
    const handleUnload =  (event) => {
      if (when) {
        event.preventDefault();
        onConfirmReload(); // Call the custom function when the user confirms
      }
    };

    // Add the event listeners
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    // Cleanup event listeners when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [when, message, onConfirmReload]);
}
