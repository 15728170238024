import {ID, Response} from '../../../../../_metronic/helpers'
export type Email = {
  _id?: ID
  is_active?: boolean
  name?: string
  email?: string
  subject?: string
  content?: string
  is_fixed?: boolean
  type?: string
  unique_identifier?: string
  bcc?: string
  mailtags?: string
}

export type EmailQueryResponse = Response<Array<Email>>

export const initialEmail: Email = {
  name: '',
  email: '',
  subject: '',
  is_active: true,
  content: '',
  is_fixed: false,
  type: '',
  unique_identifier: '',
  bcc: '',
  mailtags: '',
}

export type GeneralSetting = {
  [x: string]: unknown;
  id?: ID;
  daily_availability?: string;
  planning_screen_view?: string;
  task_screen_view?: string,
  service_call_duration?: string,
  task_duration?: string,
  ticket_duration?: string,
  task_color?: string,
  ticket_color?: string,
  service_call_color?: string,
  planning_first_day_of_week?: string,
  task_first_day_of_week?: string,
  allow_overlapping?: boolean,
  show_task_type?: boolean,
  time_format?: any
  default_todofilter?: any,
  show_resource_skills?: boolean;
  default_monthyearhours?: any
};

export type GeneralSettingQueryResponse = Response<Array<GeneralSetting>>;

export const initialGeneralSetting: GeneralSetting = {
  daily_availability: "",
  planning_screen_view: "",
  task_screen_view: "",
  service_call_duration: "",
  task_duration: "",
  ticket_duration: "",
  task_color: "",
  ticket_color: "",
  service_call_color: "",
  planning_first_day_of_week: "",
  task_first_day_of_week: "",
};

export type MediaSetting = {
  [x: string]: unknown;
  id?: ID;
  admin_background?: string;
  company_logo?: string;
};

export type MediaSettingQueryResponse = Response<Array<MediaSetting>>;

export const initialMediaSetting: MediaSetting = {
  admin_background: "",
  company_logo: "",
};

export type EmailCredSetting = {
  [x: string]: unknown;
  id?: ID;
  sender_mail_address?: string,
  smtp_server?: string,
  smtp_port?: string,
  smtp_user?: string,
  smtp_password?: string,
  smtp_type?: string,
};

export type EmailCredSettingQueryResponse = Response<Array<EmailCredSetting>>;

export const initialEmailCredSetting: EmailCredSetting = {
  sender_mail_address: "",
  smtp_server: "",
  smtp_port: "",
  smtp_user: "",
  smtp_password: "",
  smtp_type: "",
};


export type SmtpSetting = {
  [x: string]: unknown;
  id?: ID;
  password_expire_value?: string,
  session_expire_value?: string,
  session_expire_unit?: string,
  password_link_expire_value?: string,
  password_link_expire_unit?: string,
  password_expire_unit?: string,
  is_user_password_to_configure?: boolean,
  is_remember_session_to_configure?: boolean
};

export type SmtpSettingQueryResponse = Response<Array<SmtpSetting>>;

export const initialSmtpSetting: SmtpSetting = {
  password_expire_value: "",
  session_expire_value: "",
  session_expire_unit: "",
  password_link_expire_value: "",
  password_link_expire_unit: "",
  password_expire_unit: "",
  is_user_password_to_configure:false,
  is_remember_session_to_configure:false,
  is_password_link_expire_value:false
};


export type TestMail = {
  [x: string]: unknown;
  id?: ID;
  email?: string;
};

export type TestMailQueryResponse = Response<Array<TestMail>>;

export const initialTestMail: TestMail = {
email: ''
};

export type InvoiceSetting = {
  [x: string]: unknown;
  id?: ID;
  approval_email?:string,
};

export type InvoiceSettingQueryResponse = Response<Array<InvoiceSetting>>;

export const initialInvoiceSetting: InvoiceSetting = {
  approval_email: ''
};