import { alert } from "@mobiscroll/react";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { KTSVG } from "../../../../_metronic/helpers/components/KTSVG";
import {
  getViewSettings,
  savedViewSettings,
} from "../../../../_metronic/requests/ViewSettingsRequest";
import { useAppDispatch } from "../../../hook";
import { setViewSetting } from "../../../viewSettingsSlice";
import { Loading } from "../../loading/Loading";
import ColumnSelectionComponent from "./ColumnSelection";
import ColumnSizeComponent from "./ColumnSize";
import DaysComponent from "./DaysComponent";
import EventPerRowComponent from "./EventPerRow";
import RowSelectionComponent from "./RowSelection";
import { ViewsettingFunction } from "./_model";
import ViewComponent from "./viewComponent";

const ViewSettings = ({ showviewcomponent, screen_view, isviewShown, setIsSaveView = () => { } }: any) => {
  const currentURL = window.location.href;
  const regex = /\/apps\/([^/]+)\//;
  const match = currentURL.match(regex);
  const dispatch = useAppDispatch();  
  const currentPath = match[1] === "planning" ? true : false;
  const {
  } = useQuery(
    `get-view-setting-data`,
    async () => {
      return await getViewSettings({
        layoutpage:typeof currentPath === 'boolean' && currentPath === true ? "planning" : "project"
      });
    },
    {
      enabled: localStorage.getItem('viewSettingIsShow') == "1" ? false : true,
      cacheTime: 0,
      onSuccess: (data) => {
        localStorage.setItem('viewSettingIsShow', "1")
        let storedViewSettingsData = localStorage.getItem('viewSettingsData');
        let setviewSetting = localStorage.getItem('setviewSetting');
        const parsedData = JSON.parse(storedViewSettingsData);
        let dispatchGetViewSettingData = parsedData ? {
          PlannigViewData: parsedData?.PlannigViewData,
          ProjectViewData: parsedData?.ProjectViewData,
          PlanBucketData: parsedData?.PlanBucketData
        } : {
          PlannigViewData : data?.data?.PlannigViewData,
          ProjectViewData : data?.data?.ProjectViewData,
          PlanBucketData : data?.data?.PlanBucketData
        }
        if(setviewSetting && setviewSetting === "planning"){
          dispatchGetViewSettingData.PlannigViewData = data?.data?.PlannigViewData
        }
        if(setviewSetting && setviewSetting === "project"){
          dispatchGetViewSettingData.ProjectViewData = data?.data?.ProjectViewData
        }
        if(setviewSetting && setviewSetting === "planbucket"){
          dispatchGetViewSettingData.PlannigViewData.planbucket_from_filter = data?.data?.PlannigViewData?.planbucket_from_filter
          dispatchGetViewSettingData.PlanBucketData = data?.data?.PlanBucketData
        }
        dispatch(setViewSetting(dispatchGetViewSettingData));
        localStorage.setItem('viewSettingsData', JSON.stringify(dispatchGetViewSettingData));
        localStorage.setItem('setviewSetting',"")
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );
  const [isLoading, setIsLoading] = useState(false);
  const viewSettingsData = useSelector((state: any) => state?.viewSettings);

  const latestview = currentPath ? viewSettingsData?.PlannigViewData?.currentview  : viewSettingsData?.ProjectViewData?.currentview
  const [view, setView] = useState(latestview ??  "week" );
  const [isData, setIsData] = useState(false)

  //new changes by me 
  const viewSettingInitial = ViewsettingFunction();
  const [initialValues, setInitialValues] = useState(viewSettingInitial);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

useEffect(() => {
    if (viewSettingsData) {
        setInitialValues(viewSettingsData);
    }
}, [viewSettingsData, currentPath]);

useEffect(() => {
  if (screen_view) {
    if(currentPath === true && (screen_view === "week" || screen_view === "day")){
      setView(screen_view ?? "week");
    }else if(currentPath !== true){
      setView(screen_view ?? "week");
    }
  }
}, [screen_view,isviewShown]);

useEffect(() => {
  if(isviewShown === false){
    formik.resetForm();
  }
}, [isviewShown])

const handleDataSave = async (values: any, updatedValues: any) => {
  const data = {
    values: updatedValues ,
    view: view,
    path: currentPath,
    type: typeof currentPath === 'boolean' && currentPath === true ? "planning" : "project"
  };
  let storedViewSettingsData = localStorage.getItem('viewSettingsData');
  const parsedData = JSON.parse(storedViewSettingsData);
  let dispatchViewSettingData:any = {
    PlannigViewData: parsedData?.PlannigViewData,
    ProjectViewData: parsedData?.ProjectViewData,
    PlanBucketData: parsedData?.PlanBucketData,
  };
  if((currentPath === true && (viewSettingsData?.PlannigViewData?.planning_from_filter === false || viewSettingsData?.PlannigViewData.hasOwnProperty('planning_from_filter')=== false)) || (currentPath === false && (viewSettingsData?.ProjectViewData?.project_from_filter === false || viewSettingsData?.ProjectViewData.hasOwnProperty('project_from_filter')=== false))){
    const res = await savedViewSettings(data);
    if(res?.data?.success){
      setIsData(false)
      setIsSaveView(true)
      if(currentPath){
        dispatchViewSettingData.PlannigViewData = res?.data?.data?.PlannigViewData
      }else{
        dispatchViewSettingData.ProjectViewData = res?.data?.data?.ProjectViewData
      }
      localStorage.setItem('viewSettingsData', JSON.stringify(dispatchViewSettingData));
      dispatch(setViewSetting(dispatchViewSettingData));
    }
  } else{
    setIsData(false)
    let updatedData = currentPath === true ? values?.PlannigViewData : values?.ProjectViewData
    let commonViewData:any = {}
    if(currentPath){
      commonViewData = {
        currentview: view,
        day: updatedData?.day,
        week: updatedData?.week
      };
      if(commonViewData?.week?.start == commonViewData?.week?.end){
        commonViewData.week.end = commonViewData?.week?.end + 1
      }
      dispatchViewSettingData.PlannigViewData = commonViewData;
    } else {
      commonViewData = {
        currentview: view,
        day: updatedData?.day,
        week: updatedData?.week,
        month: updatedData?.month,
        year: updatedData?.year,
      };

      ['week', 'month', 'year'].forEach(view => {
        if (commonViewData[view]?.start === commonViewData[view]?.end) {
          commonViewData[view].end = commonViewData[view].end + 1;
        }
      });
      dispatchViewSettingData.ProjectViewData = commonViewData;
    }
    localStorage.setItem('viewSettingsData', JSON.stringify(dispatchViewSettingData));
    dispatch(setViewSetting(dispatchViewSettingData));
  }
}

  const formik = useFormik<any>({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values, { setSubmitting }) => {    
      setSubmitting(true);
      setIsData(true)
      try {
        const updatedValues = {
          ...values,
          PlannigViewData: {
            ...values.PlannigViewData,
            view_settings_days: false,
          },
          ProjectViewData: {
            ...values.ProjectViewData,
            view_settings_days:false,
          },
        };
        await handleDataSave(values, updatedValues);
      } catch (error: any) {
        setIsData(false)
        console.log(error);
      } finally {
        setIsData(false)
        alert({
          message: "Changes saved successfully!",
        });
        // showviewcomponent()
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!formik.isSubmitting) {
      setHasUnsavedChanges(formik.dirty);
    }
  }, [formik.values, formik.isSubmitting, formik.dirty]);
  
  const handleIconClick = () => {
    if (hasUnsavedChanges) {
      formik.resetForm();
    }
    showviewcomponent();
  };
  
  useEffect(() => {
    const storedViewSettingsData = localStorage.getItem('viewSettingsData');
    if (storedViewSettingsData) {
      const parsedData = JSON.parse(storedViewSettingsData);
      dispatch(setViewSetting(parsedData));
    }
  }, [dispatch]);
  
  const setPlanningcapacityview = (day: string) => {
    setView(day);
  };
  const handleFormReset  = async () => {
    try {
      formik.resetForm({ values: viewSettingInitial });
      await handleDataSave(viewSettingInitial, viewSettingInitial);
    } finally {
      setIsLoading(false);
      alert({
        message: "Reset successfully!",
      });
    }
  };

  return (
    <div
      className="bg-body viewSidebar  position-relative"
      style={{ width: "30%", float: "right" }}
    >
      <div
        className="d-flex flex-column bg-white h-100 shadow"
        style={{ borderColor: "#DADCE0" }}
      >
        {/* Toolbar */}

        <div className="border-bottom paddLR24 d-flex align-items-center justify-content-between w-100 grayBorder min-h-65px">
          <h3 className="text-dark fw-bold fs-20px m-0 d-inline-flex align-items-center">
            View Settings{" "}
          </h3>
          <div
            className="cursor-pointer"
            id="kt_todo_plan_close"
            onClick={handleIconClick}
          >
            <KTSVG
              path="/media/icons/duotune/new-icons/cross-circle-dark.svg"
              className="w-24px h-24px d-inline-flex"
              svgClassName="w-100 h-100"
              stylecode={{ color: "#181833" }}
            />
          </div>
        </div>
        {/* <CalendarNav className="md-work-week-nav" /> */}

        <form
          onSubmit={formik.handleSubmit}
          noValidate
          className="form card-body  border-top p-0 overflow-auto mh-100 d-flex flex-column"
        >
          <div className="overflow-auto py-5 px-10">
            <div className="border border-primary rounded-pill switchBtns mx-auto max-w-360px mb-10">
              <ViewComponent
                handleViewChange={setPlanningcapacityview}
                currentPath={currentPath}
                view={view}
              />
            </div>

            {!currentPath && (
              <div className="mb-10">
                <div className="d-flex justify-content-between align-items-center mb-5">
                  <label className="col-form-label fw-normal fs-16 text-dark py-0">
                    Show servicecall
                  </label>
                  <div className="form-check form-check-dark form-switch fv-row">
                    <input
                      className="form-check-input w-45px h-30px"
                      type="checkbox"
                      checked={
                        formik.values?.ProjectViewData?.[view]?.showServicecall
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          `ProjectViewData.${view}.showServicecall`,
                          e.target.checked
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center mb-5 d-none">
                  <label className="col-form-label fw-normal fs-16 text-dark py-0">
                    Show relations
                  </label>
                  <div className="form-check form-check-dark form-switch fv-row">
                    <input
                      className="form-check-input w-45px h-30px"
                      type="checkbox"
                      checked={
                        formik.values?.ProjectViewData?.[view]?.showRelations
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          `ProjectViewData.${view}.showRelations`,
                          e.target.checked
                        );
                      }}
                    ></input>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <label className="col-form-label fw-normal fs-16 text-dark py-0">
                    Show a column Summary Event
                  </label>
                  <div className="form-check form-check-dark form-switch fv-row">
                    <input
                      className="form-check-input w-45px h-30px"
                      type="checkbox"
                      checked={
                        formik.values?.ProjectViewData?.[view]
                          ?.showColumnSummaryEvent
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          `ProjectViewData.${view}.showColumnSummaryEvent`,
                          e.target.checked
                        );
                      }}
                    ></input>
                  </div>
                </div>
              </div>
            )}

            <EventPerRowComponent
              formik={formik}
              view={view}
              currentPath={currentPath}
            />

            <RowSelectionComponent
              formik={formik}
              view={view}
              currentPath={currentPath}
            />

            {!currentPath && (
              <ColumnSelectionComponent
                formik={formik}
                view={view}
                currentPath={currentPath}
              />
            )}

            {view!=="month" && view!=="year"  && (
              <ColumnSizeComponent
                formik={formik}
                view={view}
                currentPath={currentPath}
              />
            )}

            {(view !== "day" &&  view !== "year")  && (
              <DaysComponent
                formik={formik}
                view={view}
                currentPath={currentPath}
              />
            )}

          </div>
          <div className="d-flex align-items-center justify-content-center py-10 px-10 mt-auto" style={{ columnGap: '16px' }}>
            <button
              type="reset"
              onClick={handleFormReset}
              className="btn bg-gray-100 border border-primary text-primary h-55px py-0 px-5 rounded-8px fs-20px fw-bold flex-1"
              data-kt-role-modal-action="close"
            >
                 {!isLoading && "Reset settings"}
                <Loading isLoading={isLoading} text={"Please wait..."}></Loading>
            </button>

            <button
              type="submit"
              className="btn btn-primary h-55px py-0 px-5 rounded-8px fs-20px fw-bold flex-1"
              disabled={isData}
            >
              Save changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewSettings;
