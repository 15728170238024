import { ListBase, MbscListOptions } from './list';

import '../../base.scss';
import './list.scss';
import React from 'react';

export function template(s: MbscListOptions, inst: ListBase, content: any): any {
  return (
    <div ref={inst._setEl} className={inst._cssClass}>
      {content}
    </div>
  );
}

/**
 * The List component
 *
 * Usage:
 *
 * ```
 * <List theme="ios">
 *   <ListItem>Items inside</ListItem>
 * </List>
 * ```
 */
export class List extends ListBase {
  protected _template(s: MbscListOptions): any {
    return template(s, this, s.children);
  }
}
