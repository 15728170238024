/** @jsxRuntime classic */
/** @jsx createElement */
import { PickerBase } from './picker';
import { IPickerProps } from './picker.types';
import { Input } from '../../../react/components/input';
import { Popup } from '../../../react/components/popup';
import { createElement, Fragment } from '../../../react/renderer';
import { UNDEFINED } from '../../util/misc';

export function pickerTemplate(inst: PickerBase, s: IPickerProps, content: any) {
  let comp = s.inputComponent;
  let props = {
    defaultValue: (inst._value && inst._valueText) || '',
    placeholder: s.placeholder,
    ref: inst._setInput,
    ...s.inputProps,
  };
  if (!s.inputComponent) {
    comp = Input;
    props = {
      'aria-expanded': !!inst._isOpen,
      'aria-haspopup': 'dialog',
      'aria-label': s.ariaLabel,
      disabled: s.disabled,
      dropdown: s.dropdown,
      endIcon: s.endIcon,
      endIconSrc: s.endIconSrc,
      endIconSvg: s.endIconSvg,
      error: s.error,
      errorMessage: s.errorMessage,
      inputStyle: s.inputStyle,
      label: s.label,
      labelStyle: s.labelStyle,
      name: s.name,
      pickerMap: s.valueMap,
      pickerValue: inst._value,
      placeholder: s.placeholder,
      role: 'combobox',
      rtl: s.rtl,
      startIcon: s.startIcon,
      startIconSrc: s.startIconSrc,
      startIconSvg: s.startIconSvg,
      tags: s.tagInput === UNDEFINED ? s.selectMultiple : s.tagInput,
      theme: s.theme,
      themeVariant: s.themeVariant,
      ...props,
    };
  }
  const input = createElement(comp, props);
  return (
    <Fragment>
      {inst._showInput && input}
      <Popup
        activeElm={s.activeElm}
        anchor={inst._anchor}
        anchorAlign={inst._anchorAlign}
        animation={s.animation}
        buttons={inst._buttons}
        cancelText={s.cancelText}
        closeOnEsc={s.closeOnEsc}
        closeOnOverlayClick={s.closeOnOverlayClick}
        closeOnScroll={s.closeOnScroll}
        closeText={s.closeText}
        contentPadding={false}
        context={s.context}
        cssClass={inst._cssClass}
        disableLeftRight={true}
        display={s.display}
        focusElm={inst._focusElm}
        focusOnClose={s.focusOnClose}
        focusOnOpen={!inst._allowTyping}
        focusTrap={s.focusTrap}
        fullScreen={s.fullScreen}
        headerText={inst._headerText}
        height={s.height}
        isOpen={inst._isOpen}
        maxHeight={s.maxHeight}
        maxWidth={inst._maxWidth}
        onClose={inst._onPopupClose}
        onClosed={inst._onPopupClosed}
        onKeyDown={inst._onPopupKey}
        onOpen={inst._onPopupOpen}
        onResize={inst._onResize}
        setText={s.setText}
        showArrow={s.showArrow}
        showOverlay={inst._allowTyping ? false : s.showOverlay}
        ref={inst._setPopup}
        rtl={s.rtl}
        scrollLock={inst._scrollLock}
        theme={s.theme}
        themeVariant={s.themeVariant}
        touchUi={inst._touchUi}
        windowWidth={inst.state.width}
        width={s.width}
      >
        {content}
      </Popup>
    </Fragment>
  );
}
