
import { BaseComponent } from '../../base';
import { ICalendarLabelDragArgs, MbscCalendarEvent } from '../../shared/calendar-view/calendar-view.types';
import { getDocument } from '../../util/dom';
import { gestureListener } from '../../util/gesture';
import { isString, UNDEFINED } from '../../util/misc';
import { Observable } from '../../util/observable';
import { MbscDraggableOptions } from './draggable.types.public';

// tslint:disable no-non-null-assertion
// tslint:disable no-inferrable-types
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

export const dragObservable = new Observable<any>();

// tslint:disable-next-line: interface-name
export interface MbscDraggableState {
  hasHover?: boolean;
  hasFocus?: boolean;
}

export function subscribeExternalDrag(handler: (value: any) => void): number {
  return dragObservable.subscribe(handler);
}

export function unsubscribeExternalDrag(key: number) {
  dragObservable.unsubscribe(key);
}

export function moveClone(ev: any, clone: HTMLElement) {
  clone.style.left = ev.endX + 'px';
  clone.style.top = ev.endY + 'px';
}

/** @hidden */

export class DraggableBase extends BaseComponent<MbscDraggableOptions, MbscDraggableState> {
  // tslint:disable variable-name
  protected static _name = 'Draggable';

  private _dragData?: MbscCalendarEvent;
  private _unlisten?: () => void;
  // tslint:enable variable-name

  protected _render(s: MbscDraggableOptions) {
    if (s.dragData !== this._prevS.dragData) {
      this._dragData = isString(s.dragData) ? JSON.parse(s.dragData.toString()) : s.dragData;
    }
  }

  protected _updated() {
    const el = this.s.element || this._el;
    const doc = getDocument(el);

    if (this._unlisten === UNDEFINED && el && doc) {
      el.classList.add('mbsc-draggable');

      let clone: HTMLElement;
      let isDrag: boolean;
      let touchTimer: any;

      const body = doc.body;

      this._unlisten = gestureListener(el, {
        onEnd: (ev) => {
          if (isDrag) {
            const args: ICalendarLabelDragArgs = { ...ev };
            // Will prevent mousedown event on doc
            args.domEvent.preventDefault();
            args.action = 'externalDrop';
            args.dragData = this._dragData;
            args.create = true;
            args.external = true;
            args.eventName = 'onDragEnd';
            dragObservable.next(args);

            isDrag = false;

            body.removeChild(clone);
          }
          clearTimeout(touchTimer);
        },
        onMove: (ev) => {
          const args: ICalendarLabelDragArgs = { ...ev };

          args.dragData = this._dragData;
          args.clone = clone;
          args.create = true;
          args.external = true;

          if (isDrag || !args.isTouch) {
            // Prevents page scroll on touch and text selection with mouse
            args.domEvent.preventDefault();
          }

          if (isDrag) {
            moveClone(ev, clone);
            args.eventName = 'onDragMove';
            dragObservable.next(args);
          } else if (Math.abs(args.deltaX) > 7 || Math.abs(args.deltaY) > 7) {
            clearTimeout(touchTimer);
            if (!args.isTouch) {
              moveClone(ev, clone);
              body.appendChild(clone);
              args.eventName = 'onDragStart';
              dragObservable.next(args);
              isDrag = true;
            }
          }
        },
        onStart: (ev) => {
          const args: ICalendarLabelDragArgs = { ...ev };

          if (!isDrag) {
            clone = el.cloneNode(true) as HTMLElement;
            clone.classList.add('mbsc-drag-clone');

            args.dragData = this._dragData;
            args.create = true;
            args.external = true;

            if (args.isTouch) {
              touchTimer = setTimeout(() => {
                moveClone(ev, clone);
                body.appendChild(clone);
                args.clone = clone;
                args.eventName = 'onDragModeOn';
                dragObservable.next(args);
                args.eventName = 'onDragStart';
                dragObservable.next(args);
                isDrag = true;
              }, 350);
            }
          }
        },
      });
    }
  }

  protected _destroy() {
    if (this._unlisten) {
      this._unlisten();
      this._unlisten = UNDEFINED;
    }
  }
}
