import axios, { AxiosResponse } from "axios";
import { Response } from "../../../../_metronic/helpers";

const URL = process.env.REACT_APP_API_URL;

const updateTooltipVisibilityByPage = async (id: any, data: any) => {
  return axios
    .post(`${URL}users/show-tooltip/${id}`, data)
    .then((response: AxiosResponse<any>) => response.data)
    .then((response: Response<any>) => response.data);
};

const getCurrentUserDetails = async (id: any) => {
  return axios
  .get(`${URL}users/${id}`)
  .then((response: AxiosResponse<any>) => response.data)
  .then((response: Response<any>) => response.data)
  .catch((error) => console.log(error))
}
export { updateTooltipVisibilityByPage, getCurrentUserDetails };