import { ISO_8601_TIME } from './datetime';
import { MbscTimezonedDate, MbscTimezonePlugin } from './datetime.types.public';
import { isArray, isNumber, isObject, isString, isUndefined, UNDEFINED } from './misc';

// tslint:disable: no-use-before-declare

function normTimezone(timezone?: string): string {
  return !timezone || timezone === 'local' ? momentTimezone.moment.tz.guess() : timezone;
}

export class MDate implements MbscTimezonedDate {
  // tslint:disable-next-line
  public _mbsc = true;

  private m: any;
  private timezone: string;

  constructor(value?: string | number | Date | number[], timezone?: string) {
    this.timezone = normTimezone(timezone);
    this.init(value);
  }

  public clone() {
    return new MDate(this, this.timezone);
  }

  public createDate(value: number | Date): MbscTimezonedDate;
  public createDate(
    year?: number | string | MbscTimezonedDate | Date,
    month?: number,
    date?: number,
    h?: number,
    min?: number,
    sec?: number,
    ms?: number,
  ): MbscTimezonedDate {
    return momentTimezone.createDate({ displayTimezone: this.timezone }, year, month, date, h, min, sec, ms);
  }

  /**
   * Converts a Date object to a string.
   */
  public [Symbol.toPrimitive](hint: 'number'): number;
  public [Symbol.toPrimitive](hint: 'default' | 'string'): string;
  public [Symbol.toPrimitive](hint: 'default' | 'number' | 'string'): string | number {
    return this.m.toDate()[Symbol.toPrimitive](hint);
  }

  public toDateString() {
    return this.m.format('ddd MMM DD YYYY');
  }
  public toISOString() {
    return this.m.toISOString(true);
  }
  public toJSON() {
    return this.m.toISOString();
  }
  public valueOf() {
    return this.m.valueOf();
  }

  // Getters

  public getDate() {
    return this.m.date();
  }
  public getDay() {
    return this.m.day();
  }
  public getFullYear() {
    return this.m.year();
  }
  public getHours() {
    return this.m.hours();
  }
  public getMilliseconds() {
    return this.m.milliseconds();
  }
  public getMinutes() {
    return this.m.minutes();
  }
  public getMonth() {
    return this.m.month();
  }
  public getSeconds() {
    return this.m.seconds();
  }
  public getTime() {
    return this.m.valueOf();
  }
  public getTimezoneOffset() {
    return -this.m.utcOffset();
  }
  public getUTCDate() {
    return this.utc().date();
  }
  public getUTCDay() {
    return this.utc().day();
  }
  public getUTCFullYear() {
    return this.utc().year();
  }
  public getUTCHours() {
    return this.utc().hours();
  }
  public getUTCMilliseconds() {
    return this.utc().milliseconds();
  }
  public getUTCMinutes() {
    return this.utc().minutes();
  }
  public getUTCMonth() {
    return this.utc().month();
  }
  public getUTCSeconds() {
    return this.utc().seconds();
  }

  // Setters

  public setMilliseconds(ms: number) {
    return +this.m.set({ millisecond: ms });
  }
  public setSeconds(sec: number, ms?: number) {
    return +this.m.set({ seconds: sec, milliseconds: ms });
  }
  public setMinutes(min: number, sec?: number, ms?: number) {
    return +this.m.set({ minutes: min, seconds: sec, milliseconds: ms });
  }
  public setHours(hours: number, min?: number, sec?: number, ms?: number) {
    return +this.m.set({ hours, minutes: min, seconds: sec, milliseconds: ms });
  }
  public setDate(date: number) {
    return +this.m.set({ date });
  }
  public setMonth(month: number, date?: number) {
    return +this.m.set({ month, date });
  }
  public setFullYear(year: number, month?: number, date?: number) {
    return +this.m.set({ year, month, date });
  }
  public setTime(time: number) {
    this.init(time);
    return this.m.valueOf();
  }
  public setTimezone(timezone: string) {
    this.timezone = normTimezone(timezone);
    this.m.tz(this.timezone);
  }

  // The original implementations of the UTC methods are commented out below for not using them currently
  // but because of the Date interface they must be present
  public setUTCMilliseconds(ms: number) {
    return 0;
  }
  public setUTCSeconds(sec: number, ms?: number) {
    return 0;
  }
  public setUTCMinutes(min: number, sec?: number, ms?: number) {
    return 0;
  }
  public setUTCHours(hours: number, min?: number, sec?: number, ms?: number) {
    return 0;
  }
  public setUTCDate(date: number) {
    return 0;
  }
  public setUTCMonth(month: number, date?: number) {
    return 0;
  }
  public setUTCFullYear(year: number, month?: number, date?: number) {
    return 0;
  }
  public toUTCString() {
    return '';
  }
  public toTimeString() {
    return '';
  }
  public toLocaleDateString() {
    return '';
  }
  public toLocaleTimeString() {
    return '';
  }
  // public setUTCMilliseconds(ms: number) { return this.setter({ millisecond: ms }, true).milliseconds(); }
  // public setUTCSeconds(sec: number, ms?: number) { return this.setter({ seconds: sec, milliseconds: ms }, true).seconds(); }
  // public setUTCMinutes(min: number, sec?: number, ms?: number) {
  //   return this.setter({ minutes: min, seconds: sec, milliseconds: ms }, true).minutes();
  // }
  // public setUTCHours(hours: number, min?: number, sec?: number, ms?: number) {
  //   return this.setter({ hours, minutes: min, seconds: sec, milliseconds: ms }, true).hours();
  // }
  // public setUTCDate(date: number) { return this.setter({ date }, true).date(); }
  // public setUTCMonth(month: number, date?: number) { return this.setter({ month, date }, true).month(); }
  // public setUTCFullYear(year: number, month?: number, date?: number) { return this.setter({ year, month, date }, true).year(); }
  // public toUTCString() { throw new Error('not implemented'); return ''; }
  // public toTimeString() { throw new Error('not implemented'); return ''; }
  // public toLocaleDateString() { throw new Error('not implemented'); return ''; }
  // public toLocaleTimeString() { throw new Error('not implemented'); return ''; }

  private init(input?: string | number | Date | number[]) {
    const tz = momentTimezone.moment.tz;
    const normInput = isUndefined(input) || isString(input) || isNumber(input) || isArray(input) ? input : +input;
    const isTime = isString(input) && ISO_8601_TIME.test(input);
    this.m = isTime ? tz(normInput, 'HH:mm:ss', this.timezone) : tz(normInput, this.timezone);
  }

  private utc() {
    return this.m.clone().utc();
  }
}

export interface IMomentTimezonePlugin extends MbscTimezonePlugin {
  moment: any;
}

/** @hidden */
export const momentTimezone: IMomentTimezonePlugin = {
  // ...timezonePluginBase,
  moment: UNDEFINED,
  parse(date, s) {
    return new MDate(date, s.dataTimezone || s.displayTimezone);
  },
  /**
   * Supports two call signatures:
   * createDate(settings, dateObj|timestamp)
   * createDate(settings, year, month, date, hour, min, sec)
   * @returns IDate object
   */
  createDate(s: any, year?: number | string | Date, month?: number, date?: number, h?: number, min?: number, sec?: number, ms?: number) {
    const displayTimezone = s.displayTimezone;
    if (isObject(year) || isString(year) || isUndefined(month)) {
      return new MDate(year, displayTimezone);
    }
    return new MDate([year || 1970, month || 0, date || 1, h || 0, min || 0, sec || 0, ms || 0], displayTimezone);
  },
};
