
import React from 'react';
import { isVue, ON_CONTEXT_MENU } from '../../../react/renderer';
import { UNDEFINED } from '../../util/misc';
import { CalendarLabelBase, ICalendarLabelProps } from './calendar-label';

export function template(s: ICalendarLabelProps, inst: CalendarLabelBase): any {
  const editable = s.event && s.event.editable !== false;
  const rightClick = { [ON_CONTEXT_MENU]: inst._onRightClick };

  return (
    <div
      aria-hidden={s.showText ? UNDEFINED : 'true'}
      className={inst._cssClass}
      data-id={s.showText && s.event ? s.event.id : null}
      onClick={inst._onClick}
      ref={inst._setEl}
      role={s.showText ? 'button' : UNDEFINED}
      style={{ color: inst._color }}
      tabIndex={inst._tabIndex}
      title={inst._title}
      {...rightClick}
    >
      {inst._hasResizeStart && editable && (
        <div
          className={
            'mbsc-calendar-label-resize mbsc-calendar-label-resize-start' +
            inst._rtl +
            (s.isUpdate ? ' mbsc-calendar-label-resize-start-touch' : '')
          }
        />
      )}
      {inst._hasResizeEnd && editable && (
        <div
          className={
            'mbsc-calendar-label-resize mbsc-calendar-label-resize-end' +
            inst._rtl +
            (s.isUpdate ? ' mbsc-calendar-label-resize-end-touch' : '')
          }
        />
      )}
      {s.showText && !s.more && !s.render && <div className={'mbsc-calendar-label-background' + inst._theme} />}
      {s.showText && !s.more && s.render ? (
        inst._html ? (
          // eslint-disable-next-line react/no-danger-with-children
          <div dangerouslySetInnerHTML={isVue ? UNDEFINED : inst._html}>{isVue ? inst._html : UNDEFINED}</div>
        ) : (
          inst._content
        )
      ) : (
        <div className={'mbsc-calendar-label-inner' + inst._theme} style={{ color: inst._textColor }}>
          <div
            aria-hidden="true"
            className={'mbsc-calendar-label-text' + inst._theme}
            dangerouslySetInnerHTML={isVue ? UNDEFINED : inst._html}
            style={{ color: s.event && s.event.textColor }}
          >
            {isVue ? inst._content || inst._html : inst._content}
          </div>
          {s.label && <div className="mbsc-hidden-content">{s.label}</div>}
        </div>
      )}
    </div>
  );
}

export class CalendarLabel extends CalendarLabelBase {
  protected _template(s: ICalendarLabelProps): any {
    return template(s, this);
  }
}
