
import { BaseComponent } from '../../base';
import { subscribeExternalDrag, unsubscribeExternalDrag } from '../draggable/draggable';
import { MbscDropcontainerOptions } from './dropcontainer.types.public';

// tslint:disable no-non-null-assertion
// tslint:disable no-inferrable-types
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

/** @hidden */

export class DropcontainerBase extends BaseComponent<MbscDropcontainerOptions, any> {
  // tslint:disable variable-name
  protected static _name = 'Dropcontainer';

  private _unsubscribe?: number;
  private _elTop!: number;
  private _elBottom!: number;
  private _elLeft!: number;
  private _elRight!: number;
  private _isItemIn!: boolean;
  private _isOwner!: boolean;
  // tslint:enable variable-name

  // tslint:disable-next-line: variable-name
  public _onExternalDrag = (args: any) => {
    const element = this.s.element || this._el;
    const isIn = () => {
      return args.endY < this._elBottom && args.endY > this._elTop && args.endX > this._elLeft && args.endX < this._elRight;
    };
    let isInArea: boolean;
    switch (args.eventName) {
      case 'onDragStart':
        if (element) {
          const rect = element.getBoundingClientRect();
          this._elTop = rect.top;
          this._elBottom = rect.bottom;
          this._elLeft = rect.left;
          this._elRight = rect.right;
          this._isItemIn = this._isOwner = isIn();
        }
        break;
      case 'onDragMove':
        isInArea = isIn();
        if (isInArea && !this._isItemIn) {
          this._hook('onItemDragEnter', {
            clone: args.clone,
            data: args.dragData,
            domEvent: args.domEvent,
          });
        } else if (!isInArea && this._isItemIn) {
          this._hook('onItemDragLeave', {
            clone: args.clone,
            data: args.dragData,
            domEvent: args.domEvent,
          });
        }
        this._isItemIn = isInArea;
        break;
      case 'onDragEnd':
        if (this._isItemIn && !this._isOwner) {
          if (args.from) {
            // If the external item comes from a calendar, notify the instance from the drop
            args.from._eventDropped = true;
          }
          this._hook('onItemDrop', {
            clone: args.clone,
            data: args.dragData,
            domEvent: args.domEvent,
          });
        }
        this._isItemIn = false;
        break;
    }
  };

  protected _mounted() {
    this._unsubscribe = subscribeExternalDrag(this._onExternalDrag);
  }

  protected _destroy() {
    if (this._unsubscribe) {
      unsubscribeExternalDrag(this._unsubscribe);
    }
  }
}
