import { AUTOTASK_ENTITY } from "../constants/global";
import { anyWordIncludedInURL } from "../modules/auth/core/_requests";

export const handleInteraction = (e) => {
  if (
    e.type === "contextmenu" ||
    (e.type === "keydown" && e.key === "F12") ||
    (e.ctrlKey &&
      e.shiftKey &&
      (e.key === "I" || e.key === "C" || e.key === "J")) ||
    (e.ctrlKey && e.key === "u")
  ) {
    e.preventDefault();
  }
};

export function areEqual(array1: any[], array2: any[]): boolean {
  if (array1 === array2) {
    return true; // If both arrays are the same object, they are equal
  }

  if (array1.length !== array2.length) {
    return false; // If the lengths are different, they are not equal
  }

  // Sort the arrays to ensure the same order of elements
  const sortedArray1 = [...array1].sort();
  const sortedArray2 = [...array2].sort();

  // Compare elements of both arrays
  for (let i = 0; i < sortedArray1.length; i++) {
    if (sortedArray1[i] !== sortedArray2[i]) {
      return false; // If any element is different, arrays are not equal
    }
  }

  return true; // If all elements are equal, arrays are equal
}



export const itemTitle  = (general_settings:any,eventDetail:any) => {
  let preparedStr = "";
  if (general_settings?.show_task_type === true) {
    preparedStr =
      eventDetail?.type === "servicecall"
        ? `${AUTOTASK_ENTITY.ServiceCall.title} : `
        : `${eventDetail?.type} : `;
  }

  preparedStr = preparedStr?.charAt(0).toUpperCase() + preparedStr?.slice(1)
  return eventDetail?.type === "servicecall"
    ? `${preparedStr} ${eventDetail?.servcallTitle}`
    : `${preparedStr} ${eventDetail?.title}`;
}

export const redirectURL = async () => {
  let domain = window.location.origin;
  let redirect = await anyWordIncludedInURL(AUTOTASK_ENTITY.URLConstant)
  if (!redirect) {
    let redirect = window.location.protocol + '//' + process.env.REACT_APP_AUTHENTICATION_SUBDOMAIN + '.' + process.env.REACT_APP_REDIRECT_URL + "/?continueurl=" + encodeURIComponent(domain);
    window.location.href = redirect
  }
}