import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";
import { QueryRequestProvider } from "../../../components/core/QueryRequestProvider";
import { ApiListWrapper } from "./apis/api-list/ApiList";
import { QueryResponseProvider } from "./login/core/QueryResponseProvider";
import  LogView  from "./login/LogView";

const logsBreadcrumbs: Array<PageLink> = [
  {
    title: "Logs",
    path: "/apps/logs/login",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const LogsPage = () => {
  
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="login"
          element={
            <>
            <QueryRequestProvider>
              <QueryResponseProvider>
                <PageTitle breadcrumbs={logsBreadcrumbs}>Log list</PageTitle>
                <LogView />
              </QueryResponseProvider>
              </QueryRequestProvider>
            </>
          }
        />
        <Route element={<Outlet />}>
          <Route
            path="apis"
            element={ 
              <>
              <div className='card h-100 py-6 px-7 bg-transparent notifyHeight'>
              <ApiListWrapper />
              </div>
              </>
            }
          />
        </Route>
      </Route>
      <Route index element={<Navigate to="/apps/logs/login" />} />
    </Routes>
  );
};

export default LogsPage;