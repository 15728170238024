import { KTSVG } from "../../../../../_metronic/helpers";

const CustomButtonComponent = (props) => {
  const { node, onCopy, onCreate, OnDelete } = props;
  let rowData = node ? node.data : null;
  let id = rowData ? rowData.id : null;
  let isDraft = rowData ? rowData.isDraft : false;
  let isEdit = rowData ? rowData.isEdit : null;
  let autotask_id = rowData ? rowData.autotask_id : null;
  let type = rowData ? rowData.type : null;
  let timeEntryCount = rowData ? rowData.timeEntryCount : 0;
  let filePath = rowData ? rowData.filePath : [];
  let taskCount = rowData ? rowData.taskCount : [];
  const currentFilePathName = filePath.length > 0 ? filePath[filePath.length - 1] : 'N/A';

  const handleCopyClick = (e) => {
    e.preventDefault();
    onCopy(rowData, id, type, currentFilePathName);
  };

  const handleCreateClick = (e) => {
    let type = "subphase";
    if (onCreate) onCreate(rowData, rowData?.phaseID, type);
  };

  const handleDeleteClick = (e) => {
    e.preventDefault();
    if (OnDelete) OnDelete(type, autotask_id,timeEntryCount ,taskCount, currentFilePathName);
  };


  return (
    <>
      <div className="d-flex align-items-center justify-content-start">
        {(type === 'phase') && (
          <button className="border-0 p-0 bg-transparent me-4" title="Create" onClick={(e) => handleCreateClick(e)}>
            <KTSVG
              path="/media/icons/duotune/new-icons/plus.svg"
              className="text-primary"
              svgClassName="w-16px h-16px"
            />
          </button>)}
        <button className="border-0 p-0 bg-transparent me-4" title="copy" onClick={(e) => handleCopyClick(e)}>
          <KTSVG
            path="/media/icons/duotune/new-icons/copy-new.svg"
            className="text-primary"
            svgClassName="w-16px h-16px"
          />
        </button>
        { ( isDraft  && (isEdit === true )) && (<button className="border-0 p-0 bg-transparent " title="delete" onClick={(e) => handleDeleteClick(e)}>
          <KTSVG
            path="/media/icons/duotune/new-icons/trash-icon-new.svg"
            className="text-primary"
            svgClassName="w-16px h-16px"
          />
        </button>)}
      </div>
      </>
  );
};

export default CustomButtonComponent;




