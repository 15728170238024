
import { BaseComponent } from '../../base';
import { MbscPrintConfig } from '../../print';
import { MbscCalendarNavService } from '../../shared/calendar-nav/calendar-nav';
import { CalendarViewBase } from '../../shared/calendar-view/calendar-view';
import {
  ICalendarLabelDragArgs,
  IPageChangeEvent,
  IPageLoadedEvent,
  IPageLoadingEvent,
  MbscCalendarEvent,
  MbscCalendarEventData,
  MbscResource,
  ViewType,
} from '../../shared/calendar-view/calendar-view.types';
import {
  calendarViewDefaults,
  computeEventDragInTime,
  MONTH_VIEW,
  MULTI_YEAR_VIEW,
  sortEvents,
  YEAR_VIEW,
} from '../../shared/calendar-view/calendar-view.util';
import { InstanceServiceBase } from '../../shared/instance-service';
import { getClosestValidDate } from '../../util/date-validation';
import {
  addDays,
  addTimezone,
  convertTimezone,
  createDate,
  formatDate,
  getDateOnly,
  getDateStr,
  getDayDiff,
  getDayMilliseconds,
  getEndDate,
  getFirstDayOfWeek,
  isSameDay,
  makeDate,
  removeTimezone,
} from '../../util/datetime';
import { MbscDateType } from '../../util/datetime.types.public';
import { closest, forEach, getDocument, listen, smoothScroll, unlisten } from '../../util/dom';
import { KEY_DOWN } from '../../util/events';
import { TAB } from '../../util/keys';
import { constrain, floor, isArray, isEmpty, ngSetTimeout, noop, throttle, toArray, UNDEFINED } from '../../util/misc';
import { isBrowser } from '../../util/platform';
import { getEventMap, getExceptionList } from '../../util/recurrence';
import { dragObservable, moveClone, subscribeExternalDrag, unsubscribeExternalDrag } from '../draggable/draggable';
import {
  IEventDragStopArgs,
  MbscEventcalendarOptions,
  MbscEventcalendarState,
  MbscEventCreatedEvent,
  MbscEventCreateEvent,
  MbscEventCreateFailedEvent,
  MbscEventDeletedEvent,
  MbscEventDeleteEvent,
  MbscEventDragEvent,
  MbscEventList,
  MbscEventUpdatedEvent,
  MbscEventUpdateEvent,
  MbscEventUpdateFailedEvent,
  MbscSchedulerTimezone,
} from './eventcalendar.types';
import { checkInvalidCollision, checkOverlap, getDataInRange, getEventData, getEventId, prepareEvents } from './eventcalendar.util';

// tslint:disable no-non-null-assertion
// tslint:disable no-inferrable-types
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

/** @hidden */

export class EventcalendarBase extends BaseComponent<MbscEventcalendarOptions, MbscEventcalendarState> {
  /** @hidden */
  public static defaults: MbscEventcalendarOptions = {
    ...calendarViewDefaults,
    actionableEvents: true,
    allDayText: 'All-day',
    data: [],
    newEventText: 'New event',
    noEventsText: 'No events',
    showControls: true,
    showEventTooltip: true,
    view: { calendar: { type: 'month' } },
  };

  // tslint:disable variable-name
  protected static _name = 'Eventcalendar';

  /** @hidden */
  public eventList?: MbscEventList[];

  public print: (config?: MbscPrintConfig) => void = noop;

  // These are public because of the angular template only
  // ---
  /** @hidden */
  public _active!: number;
  /** @hidden */
  public _anchor!: HTMLDivElement;
  /** @hidden */
  public _calendarScroll?: 'horizontal' | 'vertical';
  /** @hidden */
  public _calendarSize?: number;
  /** @hidden */
  public _calendarLabelList?: 'all' | number | boolean;
  /** @hidden */
  public _calendarType?: 'year' | 'month' | 'week';
  /** @hidden */
  public _calendarView!: CalendarViewBase;
  /** @hidden */
  public _checkSize = 0;
  /** @hidden */
  public _colorEventList!: boolean;
  /** @hidden */
  public _colorsMap?: { [key: number]: MbscCalendarEvent[] };
  /** @hidden */
  public _cssClass?: string;
  /** @hidden */
  public _currentTimeIndicator?: boolean;
  /** @hidden */
  public _dragTimeStep?: number;
  /** @hidden */
  public _eventDropped!: boolean;
  /** @hidden */
  public _eventListHTML: any;
  /** @hidden */
  public _eventListType?: 'year' | 'month' | 'week' | 'day';
  /** @hidden */
  public _eventListSize!: number;
  /** @hidden */
  public _eventMap!: { [key: string]: MbscCalendarEvent[] };
  /** @hidden */
  public _firstDay!: Date;
  /** @hidden */
  public _firstWeekDay!: number;
  /** @hidden */
  public _instanceService?: InstanceServiceBase;
  /** @hidden */
  public _invalidsMap?: { [key: number]: MbscCalendarEvent[] };
  /** @hidden */
  public _labelsMap?: { [key: string]: MbscCalendarEvent[] };
  /** @hidden */
  public _lastDay!: Date;
  /** @hidden */
  public _list!: HTMLElement | null;
  /** @hidden */
  public _listDays?: { [key: string]: HTMLElement | null } | null;
  /** @hidden */
  public _marksMap?: { [key: string]: MbscCalendarEvent[] };
  /** @hidden */
  public _maxDate!: number;
  /** @hidden */
  public _minDate!: number;
  /** @hidden */
  public _navigateToEvent?: MbscCalendarEvent;
  /** @hidden */
  public _navService: MbscCalendarNavService = new MbscCalendarNavService();
  /** @hidden The select view of the navigation picker */
  public _navView?: ViewType;
  /** @hidden */
  public _pageLoad = 0;
  /** @hidden */
  public _popoverClass!: string;
  /** @hidden */
  public _popoverList?: HTMLElement;
  /** @hidden Flag for the print method to turn off virtual scrolling */
  public _print?: boolean;
  /** @hidden */
  public _rangeEndDay?: number;
  /** @hidden */
  public _rangeStartDay?: number;
  /** @hidden */
  public _rangeType?: 'year' | 'month' | 'week' | 'day';
  /** @hidden */
  public _refDate?: Date;
  /** @hidden */
  public _resourcesMap?: { [key: string]: MbscResource };
  /** @hidden */
  public _scheduleMaxEventStack?: 'all' | 'auto' | number;
  /** @hidden */
  public _scheduleMinEventWidth?: number;
  /** @hidden */
  public _scheduleType!: 'month' | 'week' | 'day';
  /** @hidden */
  public _scheduleEndDay!: number;
  /** @hidden */
  public _scheduleStartDay!: number;
  /** @hidden */
  public _scheduleEndTime?: string;
  /** @hidden */
  public _scheduleSize!: number;
  /** @hidden */
  public _scheduleStartTime?: string;
  /** @hidden */
  public _scheduleTimeCellStep!: number;
  /** @hidden */
  public _scheduleTimeLabelStep!: number;
  /** @hidden */
  public _scheduleTimezones?: Array<MbscSchedulerTimezone | string>;
  /** @hidden */
  public _timelineMaxEventStack?: 'all' | number;
  /** @hidden */
  public _timelineSize!: number;
  /** @hidden */
  public _timelineType!: 'year' | 'month' | 'week' | 'day';
  /** @hidden */
  public _timelineEndDay!: number;
  /** @hidden */
  public _timelineStartDay!: number;
  /** @hidden */
  public _timelineEndTime?: string;
  /** @hidden */
  public _timelineStartTime?: string;
  /** @hidden */
  public _timelineResolution?: 'year' | 'quarter' | 'month' | 'week' | 'day' | 'hour';
  /** @hidden */
  public _timelineResolutionVertical?: 'day';
  /** @hidden */
  public _timelineRowHeight?: 'variable' | 'equal';
  /** @hidden */
  public _timelineTimeCellStep!: number;
  /** @hidden */
  public _timelineTimeLabelStep!: number;
  /** @hidden */
  public _timelineListing?: boolean;
  /** @hidden */
  public _timelineVirtualScroll?: boolean;
  /** @hidden */
  public _selected!: number;
  /** @hidden */
  public _selectedDateHeader?: string;
  /** @hidden */
  public _selectedDates: { [key: number]: boolean } = {};
  /** @hidden */
  public _selectedEventsMap!: { [key: string]: MbscCalendarEvent };
  /** @hidden */
  public _selectedDateTime!: number;
  /** @hidden */
  public _shouldLoadDays?: boolean;
  /** @hidden */
  public _shouldScrollSchedule = 0;
  /** @hidden */
  public _showCalendar?: boolean;
  /** @hidden */
  public _showDate?: boolean;
  /** @hidden */
  public _showEventCount?: boolean;
  /** @hidden */
  public _showEventLabels?: boolean;
  /** @hidden */
  public _showEventList?: boolean;
  /** @hidden */
  public _showEventPopover?: boolean;
  /** @hidden */
  public _showOuterDays?: boolean;
  /** @hidden */
  public _showSchedule?: boolean;
  /** @hidden */
  public _showScheduleAllDay?: boolean;
  /** @hidden */
  public _showScheduleDays?: boolean;
  /** @hidden */
  public _showTimeline?: boolean;
  /** @hidden */
  public _showTimelineWeekNumbers?: boolean;
  /** @hidden */
  public _showWeekNumbers?: boolean;
  /** @hidden */
  public _rowTops!: number[];
  /** @hidden */
  public _update = 0;
  /** @hidden */
  public _validsMap?: { [key: number]: MbscCalendarEvent[] };

  /** @hidden */
  public _onScroll = throttle(() => {
    if (!this._isListScrolling && !this._viewChanged) {
      for (const timestamp in this._listDays) {
        if (this._listDays[timestamp]) {
          const day = this._listDays[timestamp]!;
          const bottom = day.offsetTop + day.offsetHeight - this._list!.scrollTop;
          if (bottom > 0) {
            if (+timestamp !== this._selected) {
              this._shouldSkipScroll = true;
              this._selectedChange(+timestamp);
            }
            break;
          }
        }
      }
    }
  });

  // ---
  // Used by drag & drop
  private _tempDay?: number;
  private _tempWeek?: number;
  private _tempEvent?: MbscCalendarEvent;
  private _calCellWidth!: number;
  private _areaTop!: number;
  private _areaLeft!: number;
  private _areaBottom!: number;
  private _areaRight!: number;
  private _onCalendar?: boolean;
  private _triggerCreated?: MbscEventCreatedEvent | null;
  private _triggerUpdated?: MbscEventUpdatedEvent | null;
  private _triggerDeleted?: MbscEventDeletedEvent | null;
  // ---

  private _body!: HTMLElement;
  private _clone?: HTMLElement;
  private _defaultDate?: number;
  private _events!: MbscCalendarEvent[];
  private _hoverTimer: any;
  private _isHover?: boolean;
  private _isEventClick?: boolean;
  private _isListScrolling = 0;
  private _isPageChange?: boolean;
  private _moreLabelClicked?: boolean;
  private _refresh?: boolean;
  private _shouldAnimateScroll?: boolean;
  private _shouldScroll?: boolean;
  private _shouldSkipScroll?: boolean;
  private _skipScheduleScroll?: boolean;
  private _unsubscribe?: number;
  private _viewChanged?: boolean;
  private _viewDate?: number;

  /**
   * @hidden
   * Adds one or more events to the calendar
   *
   * @param events - Object or Array containing the events.
   * @returns - An array containing the list of IDs generated for the events.
   */
  public addEvent(events: MbscCalendarEvent | MbscCalendarEvent[]): string[] {
    // TODO: check if id exists already?
    const eventsToAdd: any = isArray(events) ? events : [events];
    const ids = [];
    const data = prepareEvents(eventsToAdd);
    for (const event of data) {
      ids.push('' + event.id);
      this._events.push(event);
    }
    this.refresh();
    return ids;
  }

  /**
   * Returns the [events](#opt-data) between two dates. If `start` and `end` are not specified,
   * it defaults to the start and end days of the current view.
   * If `end` is not specified, it defaults to start date + 1 day.
   *
   * @param start - Start date of the specified interval.
   * @param end  - End date of the specified interval.
   * @returns - An array containing the event objects.
   */
  public getEvents(start?: MbscDateType, end?: MbscDateType): MbscCalendarEvent[] {
    return getDataInRange(this._events, this.s, this._firstDay, this._lastDay, start, end);
  }

  /**
   * Returns the [invalids](#opt-invalid) between two dates. If `start` and `end` are not specified,
   * it defaults to the start and end days of the current view.
   * If `end` is not specified, it defaults to start date + 1 day.
   *
   * @param start - Start date of the specified interval.
   * @param end  - End date of the specified interval.
   * @returns - An array containing the invalid objects.
   */
  public getInvalids(start?: MbscDateType, end?: MbscDateType): MbscCalendarEvent[] {
    return getDataInRange(this.s.invalid!, this.s, this._firstDay, this._lastDay, start, end);
  }

  /**
   * @hidden
   * Returns the selected events.
   *
   * @returns - An array containing the selected events.
   *
   * The selected events can be set with the [setSelectedEvents](#method-setSelectedEvents) method.
   * Multiple event selection can be turned on with the [selectMultipleEvents](#opt-selectMultipleEvents) option.
   */
  public getSelectedEvents(): MbscCalendarEvent[] {
    return toArray(this._selectedEventsMap);
  }

  /**
   * @hidden
   * Set the events for the calendar. The previous events will be overwritten.
   * Returns the list of IDs generated for the events.
   *
   * @param events - An array containing the events.
   * @returns - An array containing the event IDs.
   */
  public setEvents(events: MbscCalendarEvent[]): string[] {
    const ids = [];
    const data = prepareEvents(events);
    for (const event of data) {
      ids.push('' + event.id);
    }
    this._events = data;
    this.refresh();
    return ids;
  }

  /**
   * Returns the view date which is representing the currently displayed date on the component.
   */
  // public getViewDate(): Date {
  //   return makeDate(this._viewDate);
  // }

  /**
   * @hidden
   * Sets the selected events.
   *
   * @param selectedEvents - An array containing the selected events.
   *
   * The selected events are returned by the [getSelectedEvents](#method-getSelectedEvents) method.
   * Multiple event selection can be turned on with the [selectMultipleEvents](#opt-selectMultipleEvents) option.
   */
  public setSelectedEvents(selectedEvents: MbscCalendarEvent[]) {
    this._selectedEventsMap = (selectedEvents || []).reduce((map: any, ev) => {
      if (ev.occurrenceId) {
        map[ev.occurrenceId] = ev;
      } else {
        map[ev.id!] = ev;
      }
      return map;
    }, {});
    this.forceUpdate();
  }

  /**
   * @hidden
   * Removes one or more events from the event list based on IDs. For events without IDs, the IDs are generated internally.
   * The generated ids are returned by the [addEvent](#method-addEvent) or [getEvents](#method-getEvents) methods.
   *
   * @param events - An array containing IDs or the event objects to be deleted.
   */
  public removeEvent(events: string | number | MbscCalendarEvent | string[] | number[] | MbscCalendarEvent[]) {
    const eventsToRemove: any = isArray(events) ? events : [events];
    const data = this._events;
    const len = data.length;
    for (const eventToRemove of eventsToRemove) {
      let found = false;
      let i = 0;
      while (!found && i < len) {
        const event = data[i];
        if (event.id === eventToRemove || event.id === eventToRemove.id) {
          found = true;
          data.splice(i, 1);
        }
        i++;
      }
    }
    this.refresh();
  }

  /**
   * Navigates to the specified event on the calendar.
   * @param event - The event object. The `id`, `start` and `resource`
   * (in case if resources are used in timeline or schedule views) properties must be present in the object.
   */
  public navigateToEvent(event: MbscCalendarEvent) {
    this._navigateToEvent = event;
    this._shouldScrollSchedule++;
    this.navigate(event.start!, true);
  }

  /**
   * Navigates to the specified date on the calendar.
   * For views, where time is also displayed, the view will be scrolled to the specified time.
   * If the time part is not explicitly specified, it defaults to the start of the day.
   *
   * To change the initial date of the calendar, use the [selectedDate](#opt-selectedDate) option instead.
   *
   * @param date - Date to navigate to.
   */
  public navigate(date: MbscDateType, animate?: boolean) {
    const d = +makeDate(date);
    const isNavigate = this._navigateToEvent !== UNDEFINED;
    const changed = d !== this._selectedDateTime;

    if (changed || isNavigate) {
      this._shouldAnimateScroll = !!animate;
    }

    if (this.s.selectedDate === UNDEFINED) {
      if ((this._showSchedule || this._showTimeline) && !changed) {
        // If we navigate to the already selected date, we should still force a scroll on the view
        this._shouldScrollSchedule++;
        this.forceUpdate();
      } else {
        this.setState({ selectedDate: d });
      }
    } else if (changed || isNavigate) {
      // In controlled mode just trigger a selected date change event
      this._selectedChange(d);
    }
  }

  /**
   * @hidden
   * Updates one or more events in the event calendar.
   * @param events - The event or events to update.
   */
  public updateEvent(events: MbscCalendarEvent | MbscCalendarEvent[]) {
    const eventsToUpdate: any = isArray(events) ? events : [events];
    const data = this._events;
    const len = data.length;
    for (const eventToUpdate of eventsToUpdate) {
      let found = false;
      let i = 0;
      while (!found && i < len) {
        const event = data[i];
        if (event.id === eventToUpdate.id) {
          found = true;
          data.splice(i, 1, { ...eventToUpdate });
        }
        i++;
      }
    }
    this.refresh();
  }

  /**
   * @hidden
   * Refreshes the view.
   */
  public refresh() {
    this._refresh = true;
    this.forceUpdate();
  }

  /** @hidden */
  public _onWeekDayClick = (d: number) => {
    if (d !== this._selected) {
      this._skipScheduleScroll = true;
      this._selectedChange(d);
    }
  };

  /** @hidden */
  public _onDayClick = (args: any) => {
    const date = args.date;
    const d = +date;
    const key = getDateStr(date);
    const events: MbscCalendarEvent[] = sortEvents(this._eventMap[key], this.s.eventOrder);
    const showEventPopover = this._showEventPopover;
    const computed =
      showEventPopover === UNDEFINED ? !this._showEventLabels && !this._showEventList && !this._showSchedule : showEventPopover;
    const showMore = showEventPopover !== false && this._moreLabelClicked;
    const showPopover =
      (computed || showMore) && // Popover is enabled
      events &&
      events.length > 0; // Has events

    args.events = events;

    if (!this._isEventClick) {
      this._resetSelection();
    }

    this._hook('onCellClick', args);
    this._moreLabelClicked = false;

    if (!args.disabled && d !== this._selected) {
      this._navService.preventPageChange = !this._showEventList;
      this._skipScheduleScroll = true;
      this._selectedChange(d);
    }

    if (showPopover) {
      this._showPopover(
        d,
        d,
        events.map((event) => this._getEventData(event, date)),
        args.target,
      );
    }
    this._isEventClick = false;
  };

  /** @hidden */
  public _onActiveChange = (args: any) => {
    if (args.scroll) {
      this._viewDate = +args.date;
      return;
    }

    const d = this._getValidDay(args.date, args.dir);
    // We set the active date in the state as well, to trigger re-render
    // Note: we cannot use the state only, because the active date will be updated when the selected date changes,
    // but we don't want an extra setState call on selected date change
    const newState: MbscEventcalendarState = {
      activeDate: d,
    };
    this._active = d;
    this._viewDate = d;
    this._update++; // Force update in case of Angular, if active date is the same as previous active date
    this._skipScheduleScroll = args.pageChange && !args.nav;
    // If page is changed or today button is clicked, also update the selected date
    if (args.pageChange || args.today) {
      newState.selectedDate = d;
      this._selectedChange(d, true);
      this._navService.forcePageChange = true;
    }
    this.setState(newState);
  };

  /** @hidden */
  public _onGestureStart = (args: any) => {
    this._hidePopover();
  };

  /** @hidden */
  public _onDayDoubleClick = (args: any) => {
    this._dayClick('onCellDoubleClick', args);
  };

  /** @hidden */
  public _onDayRightClick = (args: any) => {
    this._dayClick('onCellRightClick', args);
  };

  /** @hidden */
  public _onCellHoverIn = (args: any) => {
    args.events = this._eventMap[getDateStr(args.date)];
    this._hook('onCellHoverIn', args);
  };

  /** @hidden */
  public _onCellHoverOut = (args: any) => {
    args.events = this._eventMap[getDateStr(args.date)];
    this._hook('onCellHoverOut', args);
  };

  /** @hidden */
  public _onEventHoverIn = (args: any) => {
    this._hoverTimer = setTimeout(() => {
      this._isHover = true;
      this._eventClick('onEventHoverIn', args);
    }, 150);
  };

  /** @hidden */
  public _onEventHoverOut = (args: any) => {
    clearTimeout(this._hoverTimer);
    if (this._isHover) {
      this._isHover = false;
      this._eventClick('onEventHoverOut', args);
    }
  };

  /** @hidden */
  public _onEventClick = (args: any) => {
    const s = this.s;
    this._handleMultipleSelect(args);
    const close = this._eventClick('onEventClick', args);
    if (close !== false && !(s.selectMultipleEvents || s.eventDelete || ((s.dragToCreate || s.clickToCreate) && s.eventDelete !== false))) {
      this._hidePopover();
    }
  };

  /** @hidden */
  public _onEventDoubleClick = (args: any) => {
    this._eventClick('onEventDoubleClick', args);
  };

  /** @hidden */
  public _onEventRightClick = (args: any) => {
    this._eventClick('onEventRightClick', args);
  };

  /** @hidden */
  public _onEventDragEnd = (args: any) => {
    this._hook('onEventDragEnd', args);
  };

  /** @hidden */
  public _onEventDragStart = (args: any) => {
    this._hook('onEventDragStart', args);
  };

  /** @hidden */
  public _onEventDragEnter = (args: any) => {
    this._hook('onEventDragEnter', args);
  };

  /** @hidden */
  public _onEventDragLeave = (args: any) => {
    this._hook('onEventDragLeave', args);
  };

  /** @hidden */
  public _onLabelHoverIn = (args: any) => {
    this._hoverTimer = setTimeout(() => {
      this._isHover = true;
      this._labelClick('onEventHoverIn', args);
    }, 150);
  };

  /** @hidden */
  public _onLabelHoverOut = (args: any) => {
    clearTimeout(this._hoverTimer);
    if (this._isHover) {
      this._isHover = false;
      this._labelClick('onEventHoverOut', args);
    }
  };

  /** @hidden */
  public _onLabelClick = (args: any) => {
    this._handleMultipleSelect(args);
    this._hook('onLabelClick', args);
    this._labelClick('onEventClick', args);
    this._isEventClick = true;
    if (!args.label) {
      this._moreLabelClicked = true;
    }
  };

  /** @hidden */
  public _onLabelDoubleClick = (args: any) => {
    this._labelClick('onEventDoubleClick', args);
  };

  /** @hidden */
  public _onLabelRightClick = (args: any) => {
    this._labelClick('onEventRightClick', args);
  };

  /** @hidden */
  public _onCellClick = (args: any) => {
    this._resetSelection();
    this._cellClick('onCellClick', args);
  };

  /** @hidden */
  public _onCellDoubleClick = (args: any) => {
    this._cellClick('onCellDoubleClick', args);
  };

  /** @hidden */
  public _onCellRightClick = (args: any) => {
    this._cellClick('onCellRightClick', args);
  };

  /** @hidden */
  public _proxy = (args: any) => {
    // Needed to set the Eventcalendar instance on any emitted event
    this._hook(args.type, args);
  };

  /** @hidden */
  public _onPageChange = (args: IPageChangeEvent) => {
    // Next cycle
    setTimeout(() => {
      this._hidePopover();
    });
    this._isPageChange = true;
    this._hook<IPageChangeEvent>('onPageChange', args);
  };

  /** @hidden */
  public _onPageLoading = (args: IPageLoadingEvent) => {
    const s = this.s;
    const eventMap = getEventMap(this._events, args.viewStart, args.viewEnd, s);

    this._colorsMap = getEventMap(s.colors!, args.viewStart, args.viewEnd, s);
    this._invalidsMap = getEventMap(s.invalid!, args.viewStart, args.viewEnd, s, true);
    this._validsMap = getEventMap(s.valid!, args.viewStart, args.viewEnd, s, true);
    this._eventMap = eventMap!;
    this._firstDay = getFirstDayOfWeek(args.firstDay, s, this._firstWeekDay);
    this._lastDay = args.lastDay;

    this._labelsMap = this._marksMap = UNDEFINED;
    if (!s.labels && (this._showEventLabels || this._showEventCount)) {
      this._labelsMap = eventMap;
    } else if (!s.marked) {
      this._marksMap = eventMap;
    }

    if (args.viewChanged) {
      this._hook<IPageLoadingEvent>('onPageLoading', args);
    }
  };

  /** @hidden */
  public _onPageLoaded = (args: IPageLoadedEvent) => {
    this._shouldAnimateScroll = this._isPageChange;
    this._isPageChange = false;
    const viewType = this._eventListType;

    // Generate event list
    if (this._showEventList && !(this._showCalendar && viewType === 'day')) {
      const s = this.s;
      const month = args.month;
      const isMonthList = this._showEventList && month && viewType === 'month';
      const firstDay = isMonthList ? month : args.firstDay;
      const lastDay = isMonthList ? s.getDate!(s.getYear!(month), s.getMonth!(month) + this._eventListSize, 1) : args.lastDay;
      this._setEventList(firstDay, lastDay);
    }

    this._hook<IPageLoadedEvent>('onPageLoaded', args);
  };

  /** @hidden */
  public _onMoreClick = (args: {
    context: HTMLElement;
    date: Date;
    inst: any;
    key: string;
    list: MbscCalendarEventData[];
    target: HTMLDivElement;
  }) => {
    this._showPopover(
      args.key,
      +args.date,
      args.list.map((event) => this._getEventData(event.original!, new Date(args.date), event.currentResource, true)),
      args.target,
      args.context,
      args.inst,
    );
  };

  /** @hidden */
  public _onPopoverClose = (args: any) => {
    const state = this.state;
    if (state.popoverHost && args.source === 'dragStart') {
      this.setState({ popoverDrag: true, popoverHidden: true });
    } else if (!state.popoverHost || args.source !== 'scroll' || !state.popoverDrag) {
      this._hidePopover();
    }
  };

  /** @hidden */
  public _onResize = (ev: any) => {
    let isListScrollable: boolean | undefined;

    if (this._showEventList && isBrowser) {
      // Calculate the available height for the event list
      const cal = ev.target;
      const height = cal.offsetHeight;
      const calTop = cal.getBoundingClientRect().top;
      const listTop = this._list!.getBoundingClientRect().top;
      isListScrollable = height - listTop + calTop > 170;
    }

    this.setState({ height: ev.height, isListScrollable, width: ev.width });
  };

  /** @hidden */
  public _onSelectedEventsChange = (events: any) => {
    this._emit('selectedEventsChange', events); // needed for the two-way binding to work (copied from _selectedChange)
    this._hook('onSelectedEventsChange', { events });
  };

  //#region Drag & Drop

  /** @hidden */
  public _getDragDates = (start: Date, end: Date, event: MbscCalendarEvent): { [key: string]: any } => {
    const draggedDates: { [key: string]: any } = {};
    const firstWeekDay = this._firstWeekDay;
    const endDate = getEndDate(this.s, event.allDay, start, end, true);
    const until = getDateOnly(addDays(endDate, 1));

    for (const d = getDateOnly(start); d < until; d.setDate(d.getDate() + 1)) {
      const weekDay = d.getDay();
      const offset = firstWeekDay - weekDay > 0 ? 7 : 0;
      if (isSameDay(start, d) || weekDay === firstWeekDay) {
        draggedDates[getDateStr(d)] = {
          event,
          width: Math.min(getDayDiff(d, endDate) + 1, 7 + firstWeekDay - weekDay - offset) * 100,
        };
      } else {
        draggedDates[getDateStr(d)] = {};
      }
    }

    return draggedDates;
  };

  /** @hidden */
  public _onLabelUpdateModeOn = (args: ICalendarLabelDragArgs) => {
    const event = args.create ? this._tempEvent! : args.event!;
    const start = makeDate(event.start);
    const end = makeDate(event.end || start);
    this.setState({
      isTouchDrag: true,
      labelDragData: {
        draggedEvent: event,
        originDates: args.external ? UNDEFINED : this._getDragDates(start, end, event),
      },
    });
  };

  /** @hidden */
  public _onLabelUpdateModeOff = (args: ICalendarLabelDragArgs) => {
    this._hook<MbscEventDragEvent>('onEventDragEnd', {
      domEvent: args.domEvent,
      event: args.create ? this._tempEvent! : args.event!,
      source: 'calendar',
    });
    this.setState({
      isTouchDrag: false,
      labelDragData: UNDEFINED,
    });
  };

  /** @hidden */
  public _onLabelUpdateStart = (args: ICalendarLabelDragArgs) => {
    const s = this.s;
    const el = this._el;

    if (s.externalDrag && args.drag && !args.create) {
      const eventEl =
        el.querySelector(`.mbsc-calendar-label[data-id='${args.event!.id}']`) ||
        closest(args.domEvent.target as HTMLElement, '.mbsc-list-item');

      if (eventEl) {
        const clone = eventEl!.cloneNode(true) as HTMLElement;
        const cloneClass = clone.classList;
        clone.style.display = 'none';
        cloneClass.add('mbsc-drag-clone', 'mbsc-schedule-drag-clone', 'mbsc-font');
        cloneClass.remove('mbsc-calendar-label-hover', 'mbsc-hover', 'mbsc-focus', 'mbsc-active');
        this._clone = clone;
        this._body = getDocument(el)!.body;
        this._body.appendChild(clone);
        this._eventDropped = false;

        dragObservable.next({
          ...args,
          create: true,
          dragData: args.event,
          eventName: 'onDragStart',
          external: true,
          from: this,
        });
      }
    }

    const weekNumWidth = this._showWeekNumbers ? el.querySelector('.mbsc-calendar-week-nr')!.getBoundingClientRect().width : 0;
    const slide = el.querySelectorAll('.mbsc-calendar-slide-active')[0];
    const slideRect = slide.getBoundingClientRect();
    const weeksCont = el.querySelector('.mbsc-calendar-week-days');
    const rows = slide.querySelectorAll('.mbsc-calendar-row');
    const isClick = /click/.test(args.domEvent.type);

    this._areaTop = 0;
    if (weeksCont) {
      const weeksRect = weeksCont.getBoundingClientRect();
      this._areaTop = weeksRect.top + weeksRect.height;
    }
    this._areaLeft = slideRect.left + (s.rtl ? 0 : weekNumWidth);
    this._areaBottom = slideRect.top + slideRect.height;
    this._areaRight = this._areaLeft + slideRect.width - (s.rtl ? weekNumWidth : 0);
    this._calCellWidth = (this._areaRight - this._areaLeft) / 7;

    let newWeek = 0;
    this._rowTops = [];
    rows.forEach((r: Element, i: number) => {
      const rowTop = r.getBoundingClientRect().top - this._areaTop;
      this._rowTops.push(rowTop);
      if (args.endY - this._areaTop > rowTop) {
        newWeek = i;
      }
    });

    if (args.create) {
      const newDay = floor((s.rtl ? this._areaRight - args.endX : args.endX - this._areaLeft) / this._calCellWidth);
      const newStartDay = addDays(this._firstDay, newWeek * 7 + newDay);
      const newStart = new Date(newStartDay.getFullYear(), newStartDay.getMonth(), newStartDay.getDate());
      const nextDay = addDays(newStart, 1);
      const newEnd = s.exclusiveEndDates ? nextDay : new Date(+nextDay - 1);
      const eventData = s.extendDefaultEvent ? s.extendDefaultEvent({ start: newStart }) : UNDEFINED;

      this._tempEvent = {
        allDay: true,
        end: newEnd,
        id: getEventId(),
        start: newStart,
        title: s.newEventText,
        ...args.dragData,
        ...eventData,
      };
    }

    if (!isClick) {
      this._hook<MbscEventDragEvent>('onEventDragStart', {
        action: args.create ? 'create' : args.resize ? 'resize' : 'move',
        domEvent: args.domEvent,
        event: args.create ? this._tempEvent! : args.event!,
        source: 'calendar',
      });
    }
  };

  /** @hidden */
  public _onLabelUpdateMove = (args: ICalendarLabelDragArgs) => {
    const s = this.s;
    const event = args.create ? this._tempEvent! : args.event!;
    const draggedEvent = { ...event };
    const labelDragData = this.state.labelDragData;
    const tzOpt = event.allDay ? UNDEFINED : s;

    if (s.externalDrag && args.drag && !args.create && this._clone) {
      dragObservable.next({
        ...args,
        clone: this._clone,
        create: true,
        dragData: args.event,
        eventName: 'onDragMove',
        external: true,
        from: this,
      });

      if (!this._onCalendar) {
        moveClone(args, this._clone);
        if (!labelDragData || !labelDragData.draggedEvent) {
          // In case of instant drag the dragged event is not set
          this.setState({ labelDragData: { draggedEvent } });
        }
        return;
      }
    }

    if (args.endY > this._areaTop && args.endY < this._areaBottom && args.endX > this._areaLeft && args.endX < this._areaRight) {
      const newDay = floor((s.rtl ? this._areaRight - args.endX : args.endX - this._areaLeft) / this._calCellWidth);
      const oldDay = floor((s.rtl ? this._areaRight - args.startX : args.startX - this._areaLeft) / this._calCellWidth);
      let newWeek = 0;
      let oldWeek = 0;

      this._rowTops.forEach((rowTop, i) => {
        if (args.startY - this._areaTop > rowTop) {
          oldWeek = i;
        }
        if (args.endY - this._areaTop > rowTop) {
          newWeek = i;
        }
      });

      const dayDelta = (newWeek - oldWeek) * 7 + (newDay - oldDay);

      if (newDay !== this._tempDay || newWeek !== this._tempWeek) {
        const start: Date = makeDate(event.start, tzOpt);
        const end: Date = makeDate(event.end, tzOpt) || start;
        const isEventDraggableInTime = computeEventDragInTime(event.dragInTime, UNDEFINED, s.dragInTime);

        let newStart = start;
        let newEnd = end;

        if (args.external) {
          const ms = getDayMilliseconds(start);
          const duration = +end - +start;
          if (isEventDraggableInTime) {
            newStart = createDate(s, +addDays(this._firstDay, newWeek * 7 + newDay) + ms);
            newEnd = createDate(s, +newStart + duration);
          }
        } else if (args.drag) {
          // Drag
          if (!isEventDraggableInTime) {
            return;
          }
          newStart = addDays(start, dayDelta);
          newEnd = addDays(end, dayDelta);
        } else {
          // Resize, create
          const rtl = s.rtl ? -1 : 1;
          const endResize = args.create ? (newWeek === oldWeek ? args.deltaX * rtl > 0 : dayDelta > 0) : args.direction === 'end';
          const days = getDayDiff(start, end);

          if (endResize) {
            newEnd = addDays(end, Math.max(-days, dayDelta));
          } else {
            newStart = addDays(start, Math.min(days, dayDelta));
          }

          // Don't allow end date before start date when resizing
          if (newEnd < newStart) {
            if (endResize) {
              newEnd = createDate(tzOpt, newStart);
            } else {
              newStart = createDate(tzOpt, newEnd);
            }
          }
        }

        draggedEvent.start = newStart;
        draggedEvent.end = newEnd;

        this.setState({
          labelDragData: {
            draggedDates: this._getDragDates(newStart, newEnd, draggedEvent),
            draggedEvent,
            originDates: labelDragData && labelDragData.originDates,
          },
          popoverHidden: true,
        });

        this._tempDay = newDay;
        this._tempWeek = newWeek;
      }
    }
  };

  /** @hidden */
  public _onLabelUpdateEnd = (args: ICalendarLabelDragArgs) => {
    const s = this.s;
    const state = this.state;
    const isCreating = args.create;
    const dragData = state.labelDragData || {};
    const event = isCreating ? this._tempEvent! : args.event!;
    const draggedEvent = dragData.draggedEvent || event;
    const origStart = makeDate(event.start);
    const origEnd = makeDate(event.end);
    const newStart = makeDate(draggedEvent.start);
    const newEnd = makeDate(draggedEvent.end);
    const changed = isCreating || +origStart !== +newStart || +origEnd !== +newEnd;
    const draggedEventData = {
      allDay: event.allDay,
      endDate: newEnd,
      original: event,
      startDate: newStart,
    };
    let eventLeft = false;

    if (s.externalDrag && args.drag && !args.create && this._clone) {
      dragObservable.next({
        ...args,
        action: 'externalDrop',
        create: true,
        dragData: args.event,
        eventName: 'onDragEnd',
        external: true,
        from: this,
      });

      this._body.removeChild(this._clone);
      this._clone = UNDEFINED;

      if (!this._onCalendar) {
        eventLeft = true;
        if (this._eventDropped) {
          this._onEventDelete(args);
        }
      }
    }

    const action = args.action || (dragData.draggedEvent ? 'drag' : 'click');
    const allowUpdate =
      !eventLeft &&
      (changed
        ? this._onEventDragStop({
            action,
            collision: checkInvalidCollision(
              s,
              this._invalidsMap,
              this._validsMap,
              newStart,
              newEnd,
              this._minDate,
              this._maxDate,
              s.invalidateEvent,
              s.exclusiveEndDates,
            ),
            create: isCreating,
            domEvent: args.domEvent,
            event: draggedEventData as MbscCalendarEventData,
            external: args.external,
            from: args.from,
            overlap: checkOverlap(event, newStart, newEnd, this._eventMap, s),
            source: 'calendar',
          })
        : true);

    const keepDragMode = state.isTouchDrag && !eventLeft && (!isCreating || allowUpdate);

    if (!keepDragMode && action !== 'click') {
      this._hook<MbscEventDragEvent>('onEventDragEnd', {
        domEvent: args.domEvent,
        event,
        source: 'calendar',
      });
    }

    this.setState({
      isTouchDrag: keepDragMode,
      labelDragData: keepDragMode
        ? {
            draggedEvent: allowUpdate ? draggedEvent : { ...event },
            originDates: allowUpdate ? this._getDragDates(newStart, newEnd, draggedEventData.original) : dragData.originDates,
          }
        : {}, // Empty object needed to trigger re-render in case of click
    });

    if (args.drag) {
      this._hidePopover();
    }

    this._tempWeek = -1;
    this._tempDay = -1;
  };

  /** @hidden */
  public _onEventDragStop = (args: IEventDragStopArgs): boolean => {
    const s = this.s;
    const action = args.action;
    const resource = args.resource;
    const slot = args.slot;
    const invalidCollision = args.collision;
    const overlapCollision = args.overlap;
    const isCreating = args.create;
    const source = args.source;
    const draggedEvent: MbscCalendarEventData = args.event;
    const event = draggedEvent.original!;
    // In case of recurring event original refers to the occurrence,
    const orig: MbscCalendarEvent = event.recurring ? event.original! : event;
    const originalEvent = s.immutableData ? { ...orig } : orig;
    const oldEvent = { ...originalEvent };
    const eventCopy = { ...originalEvent };
    const eventTz = event.timezone;
    const origStart = convertTimezone(event.start!, s, eventTz);
    const start = convertTimezone(draggedEvent.startDate, s, eventTz);
    const end = convertTimezone(draggedEvent.endDate, s, eventTz);
    const allDay = draggedEvent.allDay;
    const isRecurring = eventCopy.recurring;

    if (isRecurring) {
      // add original start date to exceptions
      eventCopy.recurringException = [...getExceptionList(eventCopy.recurringException), origStart];
    } else {
      // Update the copy of the original event
      eventCopy.allDay = allDay;
      eventCopy.start = start;
      eventCopy.end = end;
      if (resource !== UNDEFINED) {
        eventCopy.resource = resource;
      }
      if (slot !== UNDEFINED) {
        eventCopy.slot = slot;
      }
    }

    let allowUpdate = false;

    const newEvent = isRecurring ? { ...originalEvent } : originalEvent;

    if (isCreating || isRecurring) {
      if (isRecurring) {
        // remove recurring property
        delete newEvent.recurring;
      }

      if (isRecurring || newEvent.id === UNDEFINED) {
        newEvent.id = getEventId();
      }

      if (resource !== UNDEFINED) {
        newEvent.resource = resource;
      }

      if (slot !== UNDEFINED) {
        newEvent.slot = slot;
      }

      newEvent.start = start;
      newEvent.end = end;
      newEvent.allDay = allDay;

      allowUpdate =
        this._hook<MbscEventCreateEvent>('onEventCreate', {
          action,
          domEvent: args.domEvent,
          event: newEvent,
          source,
          ...(isRecurring && { originEvent: event }),
        }) !== false;

      if (invalidCollision !== false || overlapCollision !== false) {
        allowUpdate = false;
        this._hook<MbscEventCreateFailedEvent>('onEventCreateFailed', {
          action,
          event: newEvent,
          invalid: invalidCollision as MbscCalendarEvent,
          overlap: overlapCollision as MbscCalendarEvent,
          source,
          ...(isRecurring && { originEvent: event }),
        });
      }
    }

    if ((!isCreating || isRecurring) && !args.external) {
      allowUpdate =
        this._hook<MbscEventUpdateEvent>('onEventUpdate', {
          domEvent: args.domEvent,
          event: eventCopy,
          oldEvent,
          oldResource: args.oldResource,
          oldSlot: args.oldSlot,
          resource: args.newResource,
          slot: args.newSlot,
          source,
          ...(isRecurring && { newEvent, oldEventOccurrence: event }),
        }) !== false;

      if (invalidCollision !== false || overlapCollision !== false) {
        allowUpdate = false;
        this._hook<MbscEventUpdateFailedEvent>('onEventUpdateFailed', {
          event: eventCopy,
          invalid: invalidCollision as MbscCalendarEvent,
          oldEvent,
          overlap: overlapCollision as MbscCalendarEvent,
          source,
          ...(isRecurring && { newEvent, oldEventOccurrence: event }),
        });
      }
    }

    if (allowUpdate) {
      if (args.from) {
        // If the external item comes from another calendar, notify the instance from the drop
        args.from._eventDropped = true;
      }

      if (isCreating || isRecurring) {
        this._events.push(newEvent);
        this._triggerCreated = {
          action,
          event: newEvent,
          source,
        };
      }

      if (!isCreating || isRecurring) {
        // Handle recurring event
        if (isRecurring) {
          draggedEvent.id = newEvent.id;
          draggedEvent.original = newEvent;
          originalEvent.recurringException = eventCopy.recurringException;
        } else {
          // Update the original event
          originalEvent.start = start;
          originalEvent.end = end;
          originalEvent.allDay = allDay;
          if (resource !== UNDEFINED) {
            originalEvent.resource = resource;
          }
          if (slot !== UNDEFINED) {
            originalEvent.slot = slot;
          }
        }

        this._triggerUpdated = {
          event: originalEvent,
          oldEvent,
          source,
        };
      }
      this._refresh = true;

      if (source !== 'calendar') {
        this.forceUpdate();
      }
    } else {
      this._hidePopover();
    }
    return allowUpdate;
  };

  /** @hidden */
  public _onExternalDrag = (args: ICalendarLabelDragArgs) => {
    const s = this.s;
    const clone: HTMLElement = args.clone!;
    const isSelf = args.from === this;
    const externalDrop = !isSelf && s.externalDrop;
    const instantDrag = isSelf && s.externalDrag && !s.dragToMove;
    const dragData = this.state.labelDragData;

    if (this._showCalendar && (externalDrop || s.externalDrag)) {
      const isInArea =
        !instantDrag &&
        args.endY > this._areaTop &&
        args.endY < this._areaBottom &&
        args.endX > this._areaLeft &&
        args.endX < this._areaRight;
      switch (args.eventName) {
        case 'onDragModeOff':
          if (externalDrop) {
            this._onLabelUpdateModeOff(args);
          }
          break;
        case 'onDragModeOn':
          if (externalDrop) {
            this._onLabelUpdateModeOn(args);
          }
          break;
        case 'onDragStart':
          if (externalDrop) {
            this._onLabelUpdateStart(args);
          } else if (isSelf) {
            this._onCalendar = true;
          }
          break;
        case 'onDragMove':
          if (!isSelf && !externalDrop) {
            return;
          }

          if (isInArea) {
            if (!this._onCalendar) {
              this._hook<MbscEventDragEvent>('onEventDragEnter', {
                domEvent: args.domEvent,
                event: args.dragData!,
                source: 'calendar',
              });
            }
            if (isSelf || externalDrop) {
              clone.style.display = 'none';
            }
            if (externalDrop) {
              this._onLabelUpdateMove(args);
            }
            this._onCalendar = true;
          } else if (this._onCalendar) {
            this._hook<MbscEventDragEvent>('onEventDragLeave', {
              domEvent: args.domEvent,
              event: args.dragData!,
              source: 'calendar',
            });
            clone.style.display = 'table';

            if (!isSelf || (dragData && dragData.draggedEvent)) {
              this.setState({
                labelDragData: {
                  draggedDates: {},
                  draggedEvent: isSelf ? dragData && dragData.draggedEvent : UNDEFINED,
                  originDates: isSelf ? dragData && dragData.originDates : UNDEFINED,
                },
              });
            }

            this._tempWeek = -1;
            this._tempDay = -1;
            this._onCalendar = false;
          }
          break;
        case 'onDragEnd':
          // this is needed, otherwise it creates event on drag click
          if (externalDrop) {
            if (!isInArea) {
              this.setState({
                labelDragData: UNDEFINED,
              });
              this._hook<MbscEventDragEvent>('onEventDragEnd', {
                domEvent: args.domEvent,
                event: args.dragData!,
                source: 'calendar',
              });
            } else {
              this._onLabelUpdateEnd(args);
            }
          }
          break;
      }
    }
  };

  //#endregion Drag & drop

  /** @hidden */
  public _onEventDelete = (args: any) => {
    const s = this.s;

    if ((s.eventDelete === UNDEFINED && !s.dragToCreate && !s.clickToCreate) || s.eventDelete === false) {
      return;
    }

    let changed = false;
    let hasRecurring = false;
    let hasNonRecurring = false;
    let originalEvent: MbscCalendarEvent;
    let oldEvent: MbscCalendarEvent | undefined;
    let eventCopy: MbscCalendarEvent;
    let event: MbscCalendarEvent = args.event;
    let occurrence: MbscCalendarEvent = event;

    const isMultiple = s.selectMultipleEvents;
    const selectedEventsMap = isMultiple ? this._selectedEventsMap : { [event.id!]: event };
    const selectedEvents = toArray(selectedEventsMap);
    const oldEvents: MbscCalendarEvent[] = [];
    const recurringEvents: MbscCalendarEvent[] = [];
    const updatedEvents: MbscCalendarEvent[] = [];
    const updatedEventsMap: { [key: string]: MbscCalendarEvent } = {};
    const events: MbscCalendarEvent[] = [];

    for (const selectedEvent of selectedEvents) {
      if (selectedEvent.recurring) {
        occurrence = selectedEvent;
        originalEvent = selectedEvent.original;
        hasRecurring = true;
        const id = originalEvent.id!;
        if (updatedEventsMap[id]) {
          eventCopy = updatedEventsMap[id];
        } else {
          oldEvent = { ...originalEvent };
          eventCopy = { ...originalEvent };
          recurringEvents.push(originalEvent);
          oldEvents.push(oldEvent);
          updatedEvents.push(eventCopy);
          updatedEventsMap[id] = eventCopy;
        }
        // add original start date to exceptions
        const origStart = convertTimezone(selectedEvent.start!, s);
        eventCopy.recurringException = [...getExceptionList(eventCopy.recurringException), origStart];
      } else {
        hasNonRecurring = true;
        event = selectedEvent;
        events.push(selectedEvent);
      }
    }

    if (hasRecurring) {
      const allowUpdate =
        this._hook<MbscEventUpdateEvent>('onEventUpdate', {
          domEvent: args.domEvent,
          event: eventCopy!,
          events: isMultiple ? updatedEvents : UNDEFINED,
          isDelete: true,
          oldEvent: isMultiple ? UNDEFINED : oldEvent,
          oldEventOccurrence: occurrence,
          oldEvents: isMultiple ? oldEvents : UNDEFINED,
          oldResource: args.resource,
          oldSlot: args.slot,
          resource: args.resource,
          slot: args.slot,
          source: args.source,
        }) !== false;

      if (allowUpdate) {
        changed = true;
        for (const recurringEvent of recurringEvents) {
          const updatedEvent = updatedEventsMap[recurringEvent.id!];
          recurringEvent.recurringException = updatedEvent.recurringException;
        }
        this._triggerUpdated = {
          event: originalEvent!,
          events: isMultiple ? recurringEvents : UNDEFINED,
          oldEvent: isMultiple ? UNDEFINED : oldEvent,
          oldEvents: isMultiple ? oldEvents : UNDEFINED,
          source: args.source,
        };
      }
    }

    if (hasNonRecurring) {
      const allowDelete =
        this._hook<MbscEventDeleteEvent>('onEventDelete', {
          domEvent: args.domEvent,
          event: event!,
          events: isMultiple ? events : UNDEFINED,
          source: args.source,
        }) !== false;

      if (allowDelete) {
        changed = true;
        this._events = this._events.filter((e) => !selectedEventsMap[e.id!]);
        this._selectedEventsMap = {};
        this._triggerDeleted = {
          event: event!,
          events: isMultiple ? events : UNDEFINED,
          source: args.source,
        };
      }
    }

    if (changed) {
      this._hidePopover();
      this.refresh();
    }
  };

  /** @hidden */
  public _setEl = (el: any) => {
    this._el = el ? el._el || el : null;
    this._calendarView = el;
  };

  /** @hidden */
  public _setList = (el: any) => {
    this._list = el;
  };

  /** @hidden */
  public _setPopoverList = (list: any) => {
    this._popoverList = list && list._el;
  };

  // tslint:enable variable-name
  // ---

  protected _render(s: MbscEventcalendarOptions, state: MbscEventcalendarState) {
    const prevProps = this._prevS;
    const showDate = this._showDate;
    const timezonesChanged = s.displayTimezone !== prevProps.displayTimezone || s.dataTimezone !== prevProps.dataTimezone;

    let renderList = false;
    let selectedChanged = false;
    let selectedDateTime: number;
    let viewChanged = false;

    this._colorEventList = s.eventTemplate !== UNDEFINED || s.renderEvent !== UNDEFINED ? false : s.colorEventList!;

    // If we have display timezone set, default to exclusive end dates
    if (s.exclusiveEndDates === UNDEFINED) {
      s.exclusiveEndDates = !!s.displayTimezone;
    }

    if (!isEmpty(s.min)) {
      if (prevProps.min !== s.min) {
        this._minDate = +makeDate(s.min);
      }
    } else {
      this._minDate = -Infinity;
    }

    if (!isEmpty(s.max)) {
      if (prevProps.max !== s.max) {
        this._maxDate = +makeDate(s.max);
      }
    } else {
      this._maxDate = Infinity;
    }

    // Load selected date from prop or state
    if (s.selectedDate !== UNDEFINED) {
      selectedDateTime = +makeDate(s.selectedDate);
    } else {
      if (!this._defaultDate) {
        // Need to save the default date, otherwise if no default selected is specified, new Date will always create a later timestamp
        this._defaultDate = +(s.defaultSelectedDate !== UNDEFINED ? makeDate(s.defaultSelectedDate) : removeTimezone(createDate(s)));
      }
      selectedDateTime = state.selectedDate || this._selectedDateTime || this._defaultDate;
    }

    this.eventList = state.eventList || [];

    if (s.data !== prevProps.data) {
      this._events = s.immutableData ? [...(s.data || [])] : prepareEvents(s.data);
      this._refresh = true;
    }

    if (s.invalid !== prevProps.invalid || s.colors !== prevProps.colors || timezonesChanged) {
      this._refresh = true;
    }

    // Process the view option
    if (s.view !== prevProps.view || s.firstDay !== prevProps.firstDay) {
      const firstDay = s.firstDay!;
      const view = s.view || {};
      const agenda = view.agenda || {};
      const calendar = view.calendar || {};
      const schedule = view.schedule || {};
      const timeline = view.timeline || {};
      const eventListSize = +(agenda.size || 1);
      const eventListType = agenda.type || 'month';
      const scheduleSize = +(schedule.size || 1);
      const scheduleType = schedule.type || 'week';
      const scheduleStartDay = schedule.startDay !== UNDEFINED ? schedule.startDay : firstDay;
      const scheduleEndDay = schedule.endDay !== UNDEFINED ? schedule.endDay : (firstDay + 6) % 7;
      const scheduleStartTime = schedule.startTime;
      const scheduleEndTime = schedule.endTime;
      const scheduleMaxEventStack = schedule.maxEventStack || 'all';
      const scheduleMinEventWidth = schedule.minEventWidth;
      const scheduleTimeCellStep = schedule.timeCellStep || 60;
      const scheduleTimeLabelStep = schedule.timeLabelStep || 60;
      const scheduleTimezones = schedule.timezones;
      const showCalendar = !!view.calendar;
      const showEventCount = calendar.count;
      const showEventList = !!view.agenda;
      const showSchedule = !!view.schedule;
      const showScheduleDays =
        schedule.days !== UNDEFINED
          ? schedule.days
          : !showCalendar && showSchedule && !(scheduleType === 'day' && s.resources && s.resources.length > 0 && scheduleSize < 2);
      const showTimeline = !!view.timeline;
      const showTimelineWeekNumbers = timeline.weekNumbers;
      const hasSlots = showTimeline && !!s.slots && s.slots.length > 0;
      const currentTimeIndicator = showTimeline ? timeline.currentTimeIndicator : schedule.currentTimeIndicator;
      const timelineType = timeline.type || 'week';
      const resX = timeline.resolutionHorizontal || timeline.resolution;
      const resXDef = resX || 'hour';
      const timelineResolutionVertical = timeline.resolutionVertical;
      const timelineResolution = timelineResolutionVertical === 'day' && !/hour|day/.test(resXDef) ? 'hour' : resXDef;
      const timelineStartDay = timeline.startDay !== UNDEFINED && /hour|day|week/.test(timelineResolution) ? timeline.startDay : firstDay;
      const timelineEndDay =
        timeline.endDay !== UNDEFINED && /hour|day|week/.test(timelineResolution) ? timeline.endDay : (firstDay + 6) % 7;
      const timelineStartTime = timeline.startTime;
      const timelineEndTime = timeline.endTime;
      const timelineMaxEventStack = timeline.maxEventStack || 'all';
      const timelineListing = timeline.eventList || hasSlots;
      const timelineSize = +(timeline.size || 1);
      const timelineStep =
        (/month|year/.test(timelineType) && !resX && timelineResolutionVertical !== 'day') ||
        timelineResolution === 'day' ||
        timelineListing
          ? 1440
          : 60;
      const timelineTimeCellStep = hasSlots || timelineResolution === 'day' ? timelineStep : timeline.timeCellStep || timelineStep;
      const timelineTimeLabelStep = hasSlots || timelineResolution === 'day' ? timelineStep : timeline.timeLabelStep || timelineStep;
      const timelineVirtualScroll = timeline.virtualScroll === UNDEFINED ? true : timeline.virtualScroll;
      const calendarType = calendar.type || 'month';
      const showEventLabels =
        calendar.labels !== UNDEFINED
          ? !!calendar.labels
          : !showEventList &&
            !showSchedule &&
            !showTimeline &&
            !s.marked &&
            !(calendarType === 'year' || (calendarType === 'month' && calendar.size));

      this._calendarScroll = calendar.scroll;
      this._calendarSize = calendar.size || 1;
      this._calendarLabelList = calendar.labels;
      this._calendarType = calendarType;
      this._dragTimeStep = s.dragTimeStep !== UNDEFINED ? s.dragTimeStep : /hour|day/.test(timelineResolution) ? 15 : 1440;
      this._showEventPopover = calendar.popover;
      this._showOuterDays = calendar.outerDays;
      this._showWeekNumbers = calendar.weekNumbers;
      this._popoverClass = calendar.popoverClass || '';
      this._showScheduleAllDay = schedule.allDay !== UNDEFINED ? schedule.allDay : true;
      this._navView =
        timelineResolution === 'year' || calendarType === 'year'
          ? MULTI_YEAR_VIEW
          : timelineResolution === 'quarter' || timelineResolution === 'month' || (showCalendar && calendarType === 'month')
          ? YEAR_VIEW
          : MONTH_VIEW;

      if (
        eventListSize !== this._eventListSize ||
        eventListType !== this._eventListType ||
        showCalendar !== this._showCalendar ||
        showEventCount !== this._showEventCount ||
        showEventLabels !== this._showEventLabels ||
        showEventList !== this._showEventList ||
        scheduleSize !== this._scheduleSize ||
        scheduleType !== this._scheduleType ||
        showSchedule !== this._showSchedule ||
        showScheduleDays !== this._showScheduleDays ||
        scheduleStartDay !== this._scheduleStartDay ||
        scheduleEndDay !== this._scheduleEndDay ||
        scheduleStartTime !== this._scheduleStartTime ||
        scheduleEndTime !== this._scheduleEndTime ||
        scheduleTimeCellStep !== this._scheduleTimeCellStep ||
        scheduleTimeLabelStep !== this._scheduleTimeLabelStep ||
        showTimeline !== this._showTimeline ||
        timelineStartDay !== this._timelineStartDay ||
        timelineEndDay !== this._timelineEndDay ||
        timelineStartTime !== this._timelineStartTime ||
        timelineEndTime !== this._timelineEndTime ||
        timelineSize !== this._timelineSize ||
        timelineType !== this._timelineType ||
        timelineTimeCellStep !== this._timelineTimeCellStep ||
        timelineTimeLabelStep !== this._timelineTimeLabelStep ||
        timelineListing !== this._timelineListing ||
        timelineResolution !== this._timelineResolution ||
        timelineResolutionVertical !== this._timelineResolutionVertical
      ) {
        this._refresh = true;
        this._viewChanged = viewChanged = true;
      }

      this._currentTimeIndicator = currentTimeIndicator;
      this._eventListSize = eventListSize;
      this._eventListType = eventListType;
      this._scheduleType = scheduleType;
      this._showCalendar = showCalendar;
      this._showEventCount = showEventCount;
      this._showEventLabels = showEventLabels;
      this._showEventList = showEventList;
      this._showSchedule = showSchedule;
      this._showScheduleDays = showScheduleDays;
      this._scheduleStartDay = scheduleStartDay;
      this._scheduleEndDay = scheduleEndDay;
      this._scheduleStartTime = scheduleStartTime;
      this._scheduleEndTime = scheduleEndTime;
      this._scheduleSize = scheduleSize;
      this._scheduleTimeCellStep = scheduleTimeCellStep;
      this._scheduleTimeLabelStep = scheduleTimeLabelStep;
      this._scheduleTimezones = scheduleTimezones;
      this._scheduleMaxEventStack = scheduleMaxEventStack;
      this._scheduleMinEventWidth = scheduleMinEventWidth;
      this._showTimeline = showTimeline;
      this._showTimelineWeekNumbers = showTimelineWeekNumbers;
      this._timelineSize = timelineSize;
      this._timelineType = timelineType;
      this._timelineStartDay = timelineStartDay;
      this._timelineEndDay = timelineEndDay;
      this._timelineListing = timelineListing;
      this._timelineStartTime = timelineStartTime;
      this._timelineEndTime = timelineEndTime;
      this._timelineTimeCellStep = timelineTimeCellStep;
      this._timelineTimeLabelStep = timelineTimeLabelStep;
      this._timelineMaxEventStack = timelineMaxEventStack;
      this._timelineRowHeight = timeline.rowHeight;
      this._timelineResolution = timelineResolution;
      this._timelineResolutionVertical = timelineResolutionVertical;
      this._timelineVirtualScroll = timelineVirtualScroll;
      this._rangeType = showSchedule ? scheduleType : showTimeline ? timelineType : eventListType;
      this._rangeStartDay = showSchedule ? scheduleStartDay : showTimeline ? timelineStartDay : UNDEFINED;
      this._rangeEndDay = showSchedule ? scheduleEndDay : showTimeline ? timelineEndDay : UNDEFINED;
      this._firstWeekDay = showSchedule ? scheduleStartDay : showTimeline ? timelineStartDay : firstDay;
    }

    this._showDate =
      !this._showScheduleDays &&
      ((this._showSchedule && this._scheduleType === 'day') ||
        (this._showEventList && this._eventListType === 'day' && this._eventListSize < 2));

    // Check if page reload needed
    const lastPageLoad = this._pageLoad;
    if (this._refresh || s.locale !== prevProps.locale || s.theme !== prevProps.theme) {
      renderList = true;
      this._pageLoad++;
    }

    if (s.resources !== prevProps.resources) {
      this._resourcesMap = (s.resources || []).reduce((map: any, res) => {
        map[res.id] = res;
        return map;
      }, {});
    }

    if (s.selectMultipleEvents) {
      if (s.selectedEvents !== prevProps.selectedEvents) {
        this._selectedEventsMap = (s.selectedEvents || []).reduce((map: any, ev) => {
          if (ev.occurrenceId) {
            map[ev.occurrenceId] = ev;
          } else {
            map[ev.id!] = ev;
          }
          return map;
        }, {});
      }
    }

    if (this._selectedEventsMap === UNDEFINED) {
      this._selectedEventsMap = {};
    }

    if (s.refDate !== prevProps.refDate) {
      this._refDate = makeDate(s.refDate);
    }

    if (!this._refDate && !this._showCalendar && (this._showSchedule || this._showTimeline)) {
      this._refDate = getDateOnly(new Date());
    }

    if (selectedDateTime !== this._selectedDateTime) {
      this._viewDate = selectedDateTime;
    }

    if (s.cssClass !== prevProps.cssClass || s.className !== prevProps.className || s.class !== prevProps.class) {
      this._checkSize++;
      this._viewChanged = viewChanged = true;
    }

    if (viewChanged && this._viewDate && selectedDateTime !== this._viewDate) {
      selectedChanged = true;
      selectedDateTime = this._viewDate;
    }

    // Check if selected date & time changed
    if (selectedDateTime !== this._selectedDateTime || viewChanged) {
      let validated =
        this._showCalendar && (this._showSchedule || this._showTimeline || this._showEventList)
          ? +getClosestValidDate(new Date(selectedDateTime), s, this._minDate, this._maxDate, UNDEFINED, UNDEFINED, 1)
          : constrain(selectedDateTime, this._minDate, this._maxDate);

      // In day view (scheduler/timeline), if only certain week days are displayed,
      // we need to change the loaded day, if it's outside of the displayed week days.
      validated = this._getValidDay(validated);

      // Emit selected change event, if change happened.
      if (selectedDateTime !== validated || selectedChanged) {
        selectedDateTime = validated;
        setTimeout(() => {
          this._selectedChange(selectedDateTime);
        });
      }

      if (!this._skipScheduleScroll) {
        this._shouldScrollSchedule++;
      }

      this._selectedDateTime = selectedDateTime;
    }

    const selectedDate = getDateOnly(new Date(selectedDateTime));
    const selected = +selectedDate;

    // Re-format selected date if displayed
    if (
      selected !== this._selected ||
      showDate !== this._showDate ||
      s.locale !== prevProps.locale ||
      prevProps.dateFormatLong !== s.dateFormatLong
    ) {
      this._selectedDateHeader = this._showDate ? formatDate(s.dateFormatLong!, selectedDate, s) : '';
    }

    // Check if selected changed
    if (selected !== this._selected || s.dataTimezone !== prevProps.dataTimezone || s.displayTimezone !== prevProps.displayTimezone) {
      this._shouldAnimateScroll = this._shouldAnimateScroll !== UNDEFINED ? this._shouldAnimateScroll : this._selected !== UNDEFINED;
      this._selected = selected;
      this._selectedDates = {};
      this._selectedDates[+addTimezone(s, new Date(selected))] = true;
      // If the selected date changes, update the active date as well
      this._active = selected;
      renderList = true;
      selectedChanged = true;
    }

    if (
      renderList &&
      this._showCalendar &&
      (this._eventListType === 'day' || this._scheduleType === 'day' || this._timelineType === 'day')
    ) {
      this._setEventList(selectedDate, addDays(selectedDate, 1));
    }

    if (this._refresh && state.showPopover) {
      setTimeout(() => {
        this._hidePopover();
      });
    }

    this._refresh = false;
    this._cssClass =
      this._className +
      ' mbsc-eventcalendar' +
      (this._showEventList ? ' mbsc-eventcalendar-agenda' : '') +
      (this._showSchedule ? ' mbsc-eventcalendar-schedule' : '') +
      (this._showTimeline ? ' mbsc-eventcalendar-timeline' : '');

    this._navService.options(
      {
        activeDate: this._active,
        calendarType: this._calendarType,
        endDay: this._showSchedule ? this._scheduleEndDay : this._showTimeline ? this._timelineEndDay : this._rangeEndDay,
        eventRange: this._rangeType,
        eventRangeSize: this._showSchedule ? this._scheduleSize : this._showTimeline ? this._timelineSize : this._eventListSize,
        firstDay: s.firstDay,
        getDate: s.getDate,
        getDay: s.getDay,
        getMonth: s.getMonth,
        getYear: s.getYear,
        max: s.max,
        min: s.min,
        onPageChange: this._onPageChange,
        onPageLoading: this._onPageLoading,
        refDate: this._refDate,
        resolution: this._timelineResolution,
        showCalendar: this._showCalendar,
        showOuterDays: this._showOuterDays,
        size: this._calendarSize,
        startDay: this._rangeStartDay,
        weeks: this._calendarSize,
      },
      this._pageLoad !== lastPageLoad,
    );

    if (selectedChanged) {
      // This needs to be after the navService, because onPageChange is fired there
      this._shouldScroll = !this._isPageChange && !this._shouldSkipScroll;
    }
  }

  protected _mounted() {
    this._unsubscribe = subscribeExternalDrag(this._onExternalDrag);
    listen(this._el, KEY_DOWN, this._onKeyDown);
  }

  protected _updated() {
    // Scroll to selected date in the list
    if (this._shouldScroll && this.state.eventList && this.state.isListScrollable) {
      ngSetTimeout(this, () => {
        this._scrollToDay();
        this._shouldAnimateScroll = UNDEFINED;
      });
      this._shouldScroll = false;
    }

    if (this._shouldLoadDays) {
      // In case of custom event listing in jQuery and plain js we need to find
      // the day containers and store them, this is needed to scroll the event list
      // to the selected day, when a day is clicked
      this._shouldLoadDays = false;
      forEach(this._list!.querySelectorAll('[mbsc-timestamp]'), (listItem: any) => {
        this._listDays![listItem.getAttribute('mbsc-timestamp')!] = listItem;
      });
    }

    if (this._shouldEnhance) {
      this._shouldEnhance = this._shouldEnhance === 'popover' ? this._popoverList : this._list;
    }

    if (this._triggerCreated) {
      const created = this._triggerCreated;
      const target =
        created.source === 'calendar'
          ? this._calendarView._body.querySelector(`.mbsc-calendar-table-active .mbsc-calendar-text[data-id="${created.event!.id}"]`)
          : this._el.querySelector(`.mbsc-schedule-event[data-id="${created.event!.id}"]`);
      this._hook<MbscEventCreatedEvent>('onEventCreated', {
        ...this._triggerCreated,
        target: target as HTMLElement,
      });
      this._triggerCreated = null;
    }

    if (this._triggerUpdated) {
      const updated = this._triggerUpdated;
      const target =
        updated.source === 'calendar'
          ? this._calendarView._body.querySelector(`.mbsc-calendar-table-active .mbsc-calendar-text[data-id="${updated.event!.id}"]`)
          : this._el.querySelector(`.mbsc-schedule-event[data-id="${updated.event!.id}"]`);
      this._hook<MbscEventUpdatedEvent>('onEventUpdated', {
        ...this._triggerUpdated,
        target: target as HTMLElement,
      });
      this._triggerUpdated = null;
    }

    if (this._triggerDeleted) {
      this._hook<MbscEventDeletedEvent>('onEventDeleted', {
        ...this._triggerDeleted,
      });
      this._triggerDeleted = null;
    }

    if (this._viewChanged) {
      // setTimeout needed because the scroll event will fire later
      setTimeout(() => {
        this._viewChanged = false;
      }, 10);
    }

    if (this._shouldSkipScroll) {
      setTimeout(() => {
        this._shouldSkipScroll = false;
      });
    }

    if (this._navigateToEvent) {
      setTimeout(() => {
        this._navigateToEvent = UNDEFINED;
      });
    }

    this._skipScheduleScroll = false;
  }

  protected _destroy() {
    if (this._unsubscribe) {
      unsubscribeExternalDrag(this._unsubscribe);
    }
    unlisten(this._el, KEY_DOWN, this._onKeyDown);
  }

  // tslint:disable-next-line: variable-name
  private _onKeyDown = (ev: any) => {
    if (ev.keyCode === TAB) {
      this._resetSelection();
    }
  };

  private _resetSelection() {
    // reset selected events if there are any selected
    if (this.s.selectMultipleEvents && Object.keys(this._selectedEventsMap).length > 0) {
      this._selectedEventsMap = {};
      this._onSelectedEventsChange([]);
      this.forceUpdate();
    }
  }

  private _getAgendaEvents(firstDay: Date, lastDay: Date, eventMap?: { [key: string]: MbscCalendarEvent[] }): MbscEventList[] {
    const events: MbscEventList[] = [];
    const s = this.s;

    if (eventMap && this._showEventList) {
      for (const d = getDateOnly(firstDay); d < lastDay; d.setDate(d.getDate() + 1)) {
        const eventsForDay = eventMap[getDateStr(d)];
        if (eventsForDay && eventsForDay.length) {
          const sorted = sortEvents(eventsForDay, s.eventOrder);
          events.push({
            date: formatDate(s.dateFormatLong!, d, s),
            events: sorted.map((event: MbscCalendarEvent) => this._getEventData(event, d)),
            timestamp: +d,
          });
        }
      }
    }
    return events;
  }

  private _getEventData(event: MbscCalendarEvent, d: Date, res?: MbscResource, fullDates?: boolean): MbscCalendarEventData {
    const s = this.s;

    if (!event.color && event.resource && res === UNDEFINED) {
      res = (this._resourcesMap || {})[isArray(event.resource) ? event.resource[0] : event.resource];
    }

    return getEventData(s, event, d, this._colorEventList, res, true, true, false, false, fullDates);
  }

  /**
   * Returns the timestamp of the closest day which falls between the specified start and end weekdays.
   * @param timestamp The timestamp of the date to validate.
   * @param dir Navigation direction. If not specified, it will return the next valid day, otherwise the next or prev, based on direction.
   */
  private _getValidDay(timestamp: number, dir = 1): number {
    const startDay = this._rangeStartDay;
    const endDay = this._rangeEndDay;
    if (!this._showCalendar && this._rangeType === 'day' && startDay !== UNDEFINED && endDay !== UNDEFINED) {
      const date = new Date(timestamp);
      const day = date.getDay();
      let diff = 0;

      // Case 1: endDay < startDay, e.g. Friday -> Monday (5-1)
      // Case 2: endDay >= startDay, e.g. Tuesday -> Friday (2-5)
      if (endDay < startDay ? day > endDay && day < startDay : day > endDay || day < startDay) {
        // If navigating backwards, we go to end day, otherwise to start day
        diff = dir < 0 ? endDay - day : startDay - day;
      }

      if (diff) {
        diff += dir < 0 ? (diff > 0 ? -7 : 0) : diff < 0 ? 7 : 0;
        return +addDays(date, diff);
      }
    }
    return timestamp;
  }

  private _setEventList(firstDay: Date, lastDay: Date) {
    setTimeout(() => {
      this._eventListHTML = UNDEFINED;
      this._shouldScroll = true;
      this._listDays = null;
      this._scrollToDay(0);
      this.setState({
        eventList: this._getAgendaEvents(firstDay, lastDay, this._eventMap),
      });
    });
  }

  private _showPopover(
    key: string | number,
    date: number,
    list: MbscCalendarEventData[],
    anchor: HTMLDivElement,
    context?: string | HTMLElement,
    inst?: any,
  ) {
    // Check if popover is already opened for this mote button
    if (!this.state.showPopover || key !== this.state.popoverKey) {
      // Wait for the popover to hide, if already open
      setTimeout(() => {
        this._anchor = anchor;
        this.setState({
          popoverContext: context,
          popoverDate: date,
          popoverHidden: false,
          popoverHost: inst,
          popoverKey: key,
          popoverList: list,
          showPopover: true,
        });
      });
    }
  }

  private _hidePopover() {
    if (this.state.showPopover) {
      this.setState({
        popoverDrag: false,
        showPopover: false,
      });
    }
  }

  private _scrollToDay(pos?: number) {
    if (this._list) {
      let to = pos;
      let animate: boolean | undefined;

      if (pos === UNDEFINED && this._listDays) {
        const day = this._listDays[this._selected];
        const eventId = this._navigateToEvent && this._navigateToEvent.id;
        if (day) {
          to = day.offsetTop;
          if (eventId !== UNDEFINED) {
            const event = day.querySelector(`.mbsc-event[data-id="${eventId}"]`) as HTMLElement;
            const dayHeader = day.querySelector('.mbsc-event-day') as HTMLElement;
            if (event) {
              to = event.offsetTop - (dayHeader ? dayHeader.offsetHeight : 0) + 1;
            }
          }
        }
        if (to !== UNDEFINED) {
          animate = this._shouldAnimateScroll;
        }
      }

      if (to !== UNDEFINED) {
        this._isListScrolling++;
        smoothScroll(this._list, UNDEFINED, to, animate, false, () => {
          setTimeout(() => {
            this._isListScrolling--;
          }, 150);
        });
      }
    }
  }

  private _selectedChange(d: number, skipState?: boolean) {
    const date = new Date(d);
    if (this.s.selectedDate === UNDEFINED && !skipState) {
      this.setState({ selectedDate: +d });
    }
    this._emit('selectedDateChange', date); // needed for the two-way binding to work - the argument needs to be the value only
    this._hook('onSelectedDateChange', { date });
  }

  private _cellClick(name: string, args: any) {
    this._hook(name, {
      target: args.domEvent.currentTarget,
      ...args,
    });
  }

  private _dayClick(name: string, args: any) {
    const d = getDateStr(args.date);
    const events = sortEvents(this._eventMap[d], this.s.eventOrder);
    args.events = events;
    this._hook(name, args);
  }

  private _labelClick(name: string, args: any) {
    if (args.label) {
      this._hook(name, {
        date: args.date,
        domEvent: args.domEvent,
        event: args.label,
        source: 'calendar',
      });
    }
  }

  private _eventClick(name: string, args: any) {
    args.date = new Date(args.date);
    return this._hook(name, args);
  }

  /**
   * Handles multiple event selection on label/event click.
   */
  private _handleMultipleSelect(args: any) {
    const event = args.label || args.event;
    if (event && this.s.selectMultipleEvents) {
      const domEvent = args.domEvent;
      const selectedEvents = !domEvent.shiftKey && !domEvent.ctrlKey && !domEvent.metaKey ? {} : this._selectedEventsMap;
      const eventId = event.occurrenceId || event.id;

      if (selectedEvents[eventId]) {
        delete selectedEvents[eventId];
      } else {
        selectedEvents[eventId] = event;
      }

      this._selectedEventsMap = { ...selectedEvents };
      this._onSelectedEventsChange(toArray(selectedEvents));

      if (this.s.selectedEvents === UNDEFINED) {
        this.forceUpdate();
      }
    }
  }
}
