import clsx from "clsx";
import  { FC, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers/index";
import { DragAndDrop } from "./DragAndDrop";

type Props = {
  taskadd: string;
};

const ActivityTabs: FC<Props> = ({ taskadd }) => {
  let [tab, setTab] = useState("Subtask");
  let keylabel2 = (Math.random() + 1).toString(36).substring(7);
  return (
    <div className="card card-custom">
      <div className="card-header overflow-auto px-0 min-h-45px mb-4">
        <ul
          className="nav nav-stretch nav-line-tabs fw-bold border-black flex-nowrap bg-bggray-900 w-100 ps-4 pe-11 justify-content-between tabCss border-0 overflow-auto text-nowrap"
          role="tablist"
        >
          <li className="nav-item mb-0 me-4 me-sm-0">
            <a
              className={clsx(`px-0 m-0 nav-link text-color75 fw-bold cursor-pointer fs-8`, {
                active: tab === "Subtask",
              })}
              onClick={() => setTab("Subtask")}
              role="tab"
            >
              <KTSVG
                path="/media/icons/duotune/extra/sub-task.svg"
                className="text-color75 me-2"
                svgClassName="w-13px h-auto"
              />              
              <span>Subtasks</span>
            </a>
          </li>
          <li className="nav-item mb-0 me-4 me-sm-0">
            <a
              className={clsx(`px-0 m-0 nav-link text-color75 fw-bold cursor-pointer fs-8`, {
                active: tab === "Attachments",
              })}
              onClick={() => setTab("Attachments")}
              role="tab"
            >
              <KTSVG
                path="/media/icons/duotune/extra/attachment.svg"
                className="text-color75 me-2"
                svgClassName="w-13px h-auto"
              />              
              <span>Attachments</span>
            </a>
          </li>
          <li className="nav-item mb-0 me-4 me-sm-0">
            <a
              className={clsx(`px-0 m-0 nav-link text-color75 fw-bold cursor-pointer fs-8`, {
                active: tab === "CustomFields",
              })}
              onClick={() => setTab("CustomFields")}
              role="tab"
            >
              <KTSVG
                path="/media/icons/duotune/extra/text-italic.svg"
                className="text-color75 me-2"
                svgClassName="w-13px h-auto"
              />              
              <span>Custom Fields</span>
            </a>
          </li>
          <li className="nav-item mb-0">
            <a
              className={clsx(`px-0 m-0 nav-link text-color75 fw-bold cursor-pointer fs-8`, {
                active: tab === "Comments",
              })}
              onClick={() => setTab("Comments")}
              role="tab"
            >
              <KTSVG
                path="/media/icons/duotune/extra/messages.svg"
                className="text-color75 me-2"
                svgClassName="w-16px h-auto"
              />              
              <span>Comments</span>
            </a>
          </li>
        </ul>
      </div>
      {/* end::Header */}
      
      {/* Progressbar */}
      <div className="d-flex align-items-center px-2 mb-7">
        <span className="text-color75 fw-bold min-w-150px fs-8">Progress:</span>      
        <div className="progDiv d-flex align-items-center w-100">
          <span className="text-color75 fw-800 me-2 fs-9 lh-1">0/1</span>
          <div className="progWrap w-100 bg-gray-101 h-3px rounded-pill">
            <div
              className="progress bg-primary h-100"
              role="progressbar"
              style={{ width: `${100}%` }}
            ></div>          
          </div>      
        </div>            
      </div>
      {/* end::progressbar */}  


      <div className="chkwithBtns d-flex align-items-center px-2 mb-8">      
        <div className="form-check form-check-custom me-3 align-items-center">
          <input
            className="form-check-input rounded-0 w-12px h-12px border-color75 border-1"
            type="checkbox"
            value=""
            id={`flexCheckDefault ${keylabel2}`}
          />
          <label className="form-check-label fw-normal text-color75 fs-8 lh-1 ms-1" htmlFor={`flexCheckDefault ${keylabel2}`}>
            Invite your team members or your guests(clients) to your new project!
          </label>        
        </div>

        <div className="roundIcons d-flex align-items-center">
            <a href="" className="d-flex align-items-center justify-content-center w-30px h-30px rounded-circle bg-colorF1 me-2">
              <KTSVG
                path="/media/icons/duotune/extra/messages.svg"
                className="text-color75"
                svgClassName="w-16px h-auto"
              />  
            </a>
            <a href="" className="d-flex align-items-center justify-content-center w-30px h-30px rounded-circle bg-colorF1">
              <KTSVG
                path="/media/icons/duotune/extra/user-add.svg"
                className="text-color75"
                svgClassName="w-14px h-auto"
              />
            </a>        
        </div>    
      </div>    

      
      {/* begin::Form */}
      <form className="form">
        {/* begin::Body */}
        <div className="card-body p-0">
          <div className="tab-content">
            {/* Begin::subtask */}
            <div className={clsx("tab-pane", { active: tab === "Subtask" })}>
              <div className="card mb-7">
                <div className="card-body p-0 bg-colorF7 text-dark d-flex align-items-center px-3">

                    <KTSVG
                      path="/media/icons/duotune/extra/add.svg"
                      className="text-gray-900 me-2"
                      svgClassName="w-12px h-auto"
                    />
                    <input
                      type="text"
                      className="form-control text-gray-900 bg-transparent border-0 fs-9 fw-bold p-0 h-42px"
                      placeholder={taskadd}
                    />                  

                  {/* <a
                    href="#"
                    className="text-gray-900 text-hover-primary fs-6 fw-bold d-flex align-items-center py-5 px-2"
                  >
                    <KTSVG
                      path="/media/icons/duotune/extra/add.svg"
                      className="text-gray-900"
                      svgClassName="w-auto h-auto"
                    />                    
                    <span>{taskadd}</span>
                  </a> */}
                </div>
              </div>
              {/* End::subtask */}

              {/* Begin::attachments */}
              <div className="attachments mb-7">              
                <div className="d-flex align-items-center text-gray-900 fs-8 fw-bold ps-2">
                  <KTSVG
                    path="/media/icons/duotune/extra/attachment.svg"
                    className="text-color75 me-1"
                    svgClassName="w-12px h-auto"
                  />
                  <span className="lh-1">Attachments</span>

                  <div className="drpDown ms-2">                
                    <button className="w-17px h-17px bg-successt border-0 p-0 d-flex align-items-center justify-content-center rounded-1" type="button" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-start" data-kt-menu-flip="bottom">
                      <KTSVG
                          path="/media/icons/duotune/extra/add.svg"
                          className="text-white m-0"
                          svgClassName="w-11px h-auto"
                        />
                    </button>
                    <div className="menu menu-sub menu-sub-dropdown bg-white px-2 py-6 w-150px fs-8">
                      <div className="d-flex align-items-center text-black fw-semibold mb-4 text-hover-primary cursor-pointer">
                        <i className="las fs-3 text-primary me-2 la-cloud-upload-alt"></i> Your Computer
                      </div>
                      <div className="d-flex align-items-center text-black fw-semibold mb-4 text-hover-primary cursor-pointer">
                        <i className="lab fs-3 text-primary me-2 la-google-drive"></i> Google Drive
                      </div>
                      <div className="d-flex align-items-center text-black fw-semibold mb-4 text-hover-primary cursor-pointer">
                        <i className="lab fs-3 text-primary me-2 la-mixcloud"></i> One Drive
                      </div>
                      <div className="d-flex align-items-center text-black fw-semibold text-hover-primary cursor-pointer">
                        <i className="lab fs-3 text-primary me-2 la-dropbox"></i> Dropbox
                      </div>                                                            
                    </div>
                  </div>  

                </div>            
                {/* Drag And Drop */}
                <DragAndDrop />
              </div>
              {/* End::attachments */}

              {/* Begin::custom fields */}
              <div className="ps-2 mb-7">              
                <div className="d-flex align-items-center text-gray-900 fs-8 fw-bold mb-4">
                  <KTSVG
                    path="/media/icons/duotune/extra/text-italic.svg"
                    className="text-color75 me-1"
                    svgClassName="w-13px h-auto"
                  />
                  <span className="lh-1">Custom fields</span>
                </div>
                <div className="fs-9 text-color75 fw-normal">
                  Organize projects, track budgets, set user titles, and more with custom fields.
                </div>
              </div>  
              {/* End::custom fields */}
            </div>
            {/* End::subtask tab */}

            {/* Begin::attachments tab */}
            <div
              className={clsx("tab-pane", { active: tab === "Attachments" })}
            >
              <div className="row mb-10">
                <label className="col-lg-8 col-form-label text-lg-end">
                  Display Attachments:
                </label>
              </div>
            </div>
            {/* End::attachment tab */}

            {/* Begin::custom field tab*/}
            <div
              className={clsx("tab-pane", { active: tab === "CustomFields" })}
            >
              <div className="row mb-10">
                <label className="col-lg-8 col-form-label text-lg-end">
                  Display custom fields:
                </label>
              </div>
            </div>
            {/* End::custom field tab */}

            {/* Begin::comments tab */}
            <div className={clsx("tab-pane", { active: tab === "Comments" })}>
              <div className="row mb-10">
                <label className="col-lg-8 col-form-label text-lg-end">
                  Display comments:
                </label>
              </div>
            </div>
            {/* End::comments tab */}
          </div>
        </div>
        {/* end::Body */}
      </form>
      {/* end::Form */}
    </div>
  );
};

export { ActivityTabs };
