/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import { useMemo, useState } from "react";
import { Loading } from "../../../../components/loading/Loading";
import { useQueryResponse, useQueryResponseData } from "./core/QueryResponseProvider";
import { Log } from "./core/_model";
import './Log.scss';
import { useAuth } from "../../../auth/core/AuthCognito";
import { useQuery } from "react-query";
import { getTooltipData } from "../../../superadmin/hint-tour/core/_request";
import { Steps } from "intro.js-react";
import { getCurrentUserDetails, updateTooltipVisibilityByPage } from "../../common/_requests";
import React from "react";

function LogView() {
  let logs = useQueryResponseData();
  const { isLoading } = useQueryResponse();

  let groups = logs.reduce((groups, log) => {
    let date = log.created_at!.split('T')[0];
    if (!groups[date as keyof Log]) {
      groups[date as keyof Log] = [];
    }
    groups[date as keyof Log].push(log);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date,
      logdata: groups[date as keyof Log]
    };
  });

  const currDate = moment(new Date).format('DD-MM-Y');
  const yesterday = moment().subtract(1, 'days').format('DD-MM-Y');
  const changeDate = (date: any) => {
    switch (date) {
      case currDate:
        return 'Today'
      case yesterday:
        return 'Yesterday'
      default:
        return date;
    }
  }

  const { data: tooltipData } = useQuery(
    "tooltip-Data",
    () => {
      return getTooltipData();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );
  const { data: loggedUser } = useQuery(
    'logged-user',
    async () => {
      return await getCurrentUserDetails(_id)
    },
    {
      cacheTime: 0, keepPreviousData: false, refetchOnWindowFocus: false
    }
  )
  const steps = tooltipData?.filter(
    (v: any) => v.type === "tour" && v.page === "Logs-Login"
  );

  const { currentUser } = useAuth()
  const  _id  = currentUser?.data?.user?._id
  const [enabled, setEnabled] = useState<boolean>(true);
  const [initialStep] = useState<number>(0);
  
  const tooltipVisibility = {
    showTooltipVisibility: {
      LogsLogin: false,
    },
  };
  const onExit = async () => {
    setEnabled(false);
    await updateTooltipVisibilityByPage(_id, tooltipVisibility);
  };
  return (
    <>
      {loggedUser && loggedUser?.showTooltipVisibility?.LogsLogin !== false && groupArrays.length > 0 && (
        <Steps
          enabled={enabled}
          initialStep={initialStep}
          steps={steps?.length > 0 ? steps : []}
          onExit={onExit}
          options={{
            showStepNumbers: true,
            showButtons: true,
            hidePrev: true,
            scrollToElement: true,
            showBullets: true,
            showProgress: true,
            exitOnOverlayClick: false,
            disableInteraction: true,
          }}
        />
      )}
      <Loading
        isLoading={isLoading}
        isHideSpinner={true}
        className="splash-screen"
      />
      <div className="py-6 px-7 h-100 notifyHeight" >
        <div className="card mh-100 login-logs" id="kt_profile_details_view">
          <div className="card-header cursor-pointer">
            <div className="card-title m-0">
              <h3 className="text-dark fw-bold fs-18 m-0">Login logs</h3>
            </div>
          </div>
          <div className="card-body pt-4 overflow-auto mh-100">
            {!isLoading &&
              <div className="timeline timeline-6 mt-3 ml-5 overflow-auto" >
                <div className='accordion' id='kt_accordion_1'>
                  {groupArrays.map((log, index) => (

                    <div className='accordion-item mb-1 rounded-1'>
                      <h2 className='accordion-header' id={`kt_accordion_1_header_${index}`}>

                        <button
                          className='accordion-button fw-bold collapsed p-4 fs-16'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target={`#kt_accordion_1_body_${index}`}
                          aria-expanded='false'
                          aria-controls={`kt_accordion_1_body_${index}`}
                        >
                          {changeDate(moment(log.date).format("DD-MM-Y"))}

                        </button>
                      </h2>

                      <div
                        id={`kt_accordion_1_body_${index}`}
                        className={`accordion-collapse pb-6 collapse ${index == 0 ? 'show' : ''}`}
                        aria-labelledby={`kt_accordion_1_header_${index}`}
                        data-bs-parent='#kt_accordion_1'
                      >
                        {log.logdata.map((logd: any) => (
                          <div>
                            {JSON.parse(logd.response_data).code != '500' &&
                              <div className='accordion-body pb-0 fs-15'>
                                <span className="px-2 p-1 fs-15 min-w-49px badge badge-light-primary me-4 bg-prlight">{moment(logd.created_at).tz(localStorage.getItem('timezone')!).format(localStorage.getItem('time_format')!)}</span>
                                {logd.is_successfull == '1' ? (
                                  <>

                                    {logd.username} successfully logged in from IP Address {logd?.ip_address}&nbsp;
                                    <span className="px-2 p-1 fs-15 min-w-55px badge badge-light-success">{JSON.parse(logd.response_data).code} OK</span>
                                  </>
                                ) : (
                                  <>
                                    {logd?.username} attempt wrong password from IP Address {logd?.ip_address}&nbsp;
                                    <span className="px-2 p-1 fs-15 min-w-55px badge badge-light-danger">{JSON.parse(logd.response_data).code} ERR</span>
                                  </>
                                )}
                              </div>
                            }
                          </div>
                        ))}
                      </div>
                    </div>

                  ))}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
export default LogView;
