// @ts-nocheck
import { Column } from "react-table";
import { IntrojsInfoCell } from "./IntrojsInfoCell";
import { IntrojsCustomHeader } from "./IntrojsCustomHeader";
import { IntrojsActionCell } from "./IntrojsActionCell";
import { IntrojsDisableCell } from "./IntrojsDisableCell";
import { IntrojsTextCell } from "./IntrojsTextCell";
// import {EmailInfoCell} from './EmailInfoCell'
// import {EmailActionsCell} from './EmailActionsCell'
// import {EmailSelectionCell} from './EmailSelectionCell'
// import {EmailCustomHeader} from './EmailCustomHeader'
// import {EmailSelectionHeader} from './EmailSelectionHeader'
// import {Email} from '../../core/_models'

const introjsColumns: ReadonlyArray<Column<Email>> = [
  {
    Header: (props) => (
      <IntrojsCustomHeader
        tableProps={props}
        title="ElementID"
        className="min-w-125px"
      />
    ),
    id: "elementId",
    Cell: ({ ...props }) => (
      <IntrojsInfoCell introJs={props.data[props.row.index]} />
    ),
  },
  {
    Header: (props) => (
      <IntrojsCustomHeader
        tableProps={props}
        title="Type"
        className="min-w-125px"
      />
    ),
    accessor: "type",
  },
  {
    Header: (props) => (
      <IntrojsCustomHeader
        tableProps={props}
        title="Page"
        className="min-w-125px"
      />
    ),
    accessor: "page",
  },
  {
    Header: (props) => (
      <IntrojsCustomHeader
        tableProps={props}
        title="Tooltip Title"
        className="min-w-125px"
      />
    ),
    accessor: "title",
  },
  {
    Header: (props) => (
      <IntrojsCustomHeader
        tableProps={props}
        title="Tooltip Text"
        className="min-w-125px text-ellipsis"
      />
    ),
    id: 'tooltipText',
    Cell: ({...props}) => <IntrojsTextCell tooltip={props.data[props.row.index]} />,
  },
  // {
  //   Header: (props) => (
  //     <IntrojsCustomHeader
  //       tableProps={props}
  //       title="Tooltip Prioriety"
  //       className="min-w-125px"
  //     />
  //   ),
  //   accessor: "priority",
  // },
  {
    Header: (props) => (
      <IntrojsCustomHeader
        tableProps={props}
        title="Tooltip Class"
        className="min-w-125px"
      />
    ),
    accessor: "tooltipClass",
  },
  {
    Header: (props) => (
      <IntrojsCustomHeader
        tableProps={props}
        title="Highlight Class"
        className="min-w-125px"
      />
    ),
    accessor: "highlightClass",
  },
  {
    Header: (props) => (
      <IntrojsCustomHeader
        tableProps={props}
        title="Tooltip Position"
        className="min-w-125px"
      />
    ),
    accessor: "position",
  },
  {
    Header: (props) => (
      <IntrojsCustomHeader
        tableProps={props}
        title="Tooltip Scroll-to"
        className="min-w-125px"
      />
    ),
    accessor: "scrollTo",
  },
  {
    Header: (props) => (
      <IntrojsCustomHeader
        tableProps={props}
        title="disalbeScrolling"
        className="min-w-125px"
      />
    ),
    id: 'disableScrolling',
    Cell: ({...props}) => <IntrojsDisableCell tooltip={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <IntrojsCustomHeader
        tableProps={props}
        title="Hint Text"
        className="min-w-125px"
      />
    ),
    accessor: "hint",
  },
  {
    Header: (props) => (
      <IntrojsCustomHeader
        tableProps={props}
        title="Hint Position"
        className="min-w-125px"
      />
    ),
    accessor: "hintPosition",
  },
  {
    Header: (props) => (
      <IntrojsCustomHeader tableProps={props} title='Actions' className='text-start ps-5 min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <IntrojsActionCell id={props.data[props.row.index]._id} introjs={props.data[props.row.index]}  />,
  },

  //   {
  //     Header: (props) => (
  //       <EmailCustomHeader tableProps={props} title='Subject' className='min-w-125px' />
  //     ),
  //     accessor: 'subject',
  //   },
  //   {
  //     Header: (props) => (
  //       <EmailCustomHeader tableProps={props} title='Actions' className='text-start ps-5 min-w-100px' />
  //     ),
  //     id: 'actions',
  //     Cell: ({...props}) => <EmailActionsCell id={props.data[props.row.index]._id} email={props.data[props.row.index]}  />,
  //   },
];

export { introjsColumns };