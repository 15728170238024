import {
  Button,
  Eventcalendar,
  MbscEventcalendarView,
  Popup,
  formatDate,
  momentTimezone,
  setOptions,
  toast,
} from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import "../../../../_metronic/assets/sass/planning.scss";
import { KTSVG } from "../../../../_metronic/helpers";
import "./App.css";

import moment from "moment-timezone";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import {
  createPopupvalues,
  getPlanningData,
  getServicecallDetail,
  updatePopupvalues,
} from "../../../../_metronic/requests/PlanningRequest";
import ServicecallUpdate from "../../../components/modals/servicecallUpdate";
import { webSocketContext } from "../../../context/WebSocketContext";
import {
  setisCreated,
  setispageLoad,
  setplanningCache,
} from "../../../counterSlice";
import {
  checkBufferHours,
  resourcehasNoLicenseMoreMultiple,
  splitServicecallEvents,
  splitedEventCall,
} from "../../../helpers/servicecall";
import { useAppDispatch } from "../../../hook";
import { useAuth } from "../../auth/core/AuthCognito";
import { SERVICECALL_HOVER_COLOR, TASK_HOVER_COLOR, TICKET_HOVER_COLOR } from "../constant/Constant";
import FilterMain from "../filters/MainFilter/FilterMain";
import TaskCreate from "../project/TaskCreate";
import CustomWithNavButtons from "./components/CustomWithNavButtons";
import MyScheduleEvent from "./components/MyScheduleEvent";
import RenderMyResource from "./components/RenderMyResource"; 
import { loadFromLocalStorage } from "../../../helpers/latest_selection";
momentTimezone.moment = moment;
setOptions({
  theme: "ios",
  themeVariant: "light",
});
function getWeekStartAndEndDate(date) {
  const startDate = moment(date).startOf('isoWeek').format('YYYY-MM-DD');
  const endDate = moment(date).endOf('isoWeek').format('YYYY-MM-DD');
  return { startDate, endDate };
}

const PlanningView: FC<any> = ({
  viewSettingsData, 
  handleclick,
  activebtn,
  activetextcolor,
  availability,
  isFetchingPlanning,
  planningCapacityView,
  showtodo_onclick,
  showviewcomponent,
  todoclassName,
  viewclassName,
  toplanactivetextcolor,
  Viewbtncolor , 
  toplanActive,
  viewBtnbackground , 
  sortactivetextcolor,
  sortActive,
  toplanrefetch,
  sortData,
  sortTooltip,
  sortIcon,
  handleHeaderChange,
  resource_settings,
  general_settings,
  filterArray,
  filterIds,
  resource_data,
  ticket_status,
  prioritylist,
  issueslist,
  subIssueslist,
  todo_status_serviceCall,
  worktype,
  task_status,
  pagination,
  dateHeaderCal,
  responseMonthdata,
  responseYeardata,
  phase,
  offlineCount,
  refetchMonthdata,
  layoutAndFiltersData,
  refetchLayouts,
  selectActiveLayout,
  setIsPopupOpen,
  todopayload,
  isFetchingMonthdata,
  planningTask,
  setPlanningTask,
  refetchPlanning,
  openDropdown,
  toggleDropdown,
  monthCatchPayload,
  lockedResourceData,
  initial_sync_completed_or_not,
  locations,
  isLoadingView,
  currentDate,
  setCurrentDate,
  monthYearDataResponse
}) => {

 const { currentUser } = useAuth();
  const {socketMessage}:any = useContext(webSocketContext)
  const websocketCallback = useCallback((event:any) => {
    let eData = JSON.parse(event.data);
      if (eData.organizationId === currentUser?.data?.user?.organizationId) {
        let from = "organization";
        if (eData.userId === currentUser?.data?.user?._id) {
          from = "user";
        }
        if (
          eData.type === "update_sc_success" &&
          eData?.split_generate_id !== ""
        ) {
        } else if (eData.type === "update_sc_changes_failed") {
          updatePlanningItem(
            eData?.data,
            eData,
            "",
            eData?.dataTodelete,
            from,
            eData?.split_generate_id
          );
        } else if (eData.type === "delete_sc_changes_failed") {
          updatePlanningItem(
            eData?.data,
            eData,
            "delete_sc",
            eData?.dataTodelete,
            from
          );
        }
      }
  },[socketMessage])

  useEffect(() => {
    if (socketMessage) {
      websocketCallback(socketMessage)
    }
   },[socketMessage])

  let [splitEventData, setSplitEventsData] = useState<any>(null);
  const [isEventOpen, setEventOpen] = useState<any>(false);
  const [isEventLoading, setIsEventLoading] = useState<any>(false);
  const [anchor, setAnchor] = useState<any>(null);
  const [monthEvent, setMonthEvent] = useState<any>([]);
  const [defaultMonthYearHighlight, setDefaultMonthYearHighlight] =
    useState("New");
  const [istaskupdated, setistaskupdated] = useState<any>(true);
  const [monthViewData, setmonthViewData] = useState<any>([]);
  const [yearViewData, setyearViewData] = useState<any>([]);
  const [existServicecall, setexistServicecall] = useState<any>([]);

  const starttime: any = general_settings?.start_time;
  const endtime: any = general_settings?.end_time;
  let time_format: any = general_settings?.time_format;
  let timezone: any = general_settings?.timezone;
  let extendedMorningStart: any = general_settings?.extended_morning_start;
  let extendedMorningEnd: any = general_settings?.extended_morning_end;
  let extendedEveningStart: any = general_settings?.extended_evening_start;
  let extendedEveningEnd: any = general_settings?.extended_evening_end;

  extendedMorningStart = extendedMorningStart ?? starttime;
  extendedMorningEnd = extendedMorningEnd ?? starttime;
  extendedEveningStart = extendedEveningStart ?? endtime;
  extendedEveningEnd = extendedEveningEnd ?? endtime;
  
  if (timezone === "undefined" || timezone === "" || timezone === undefined) {
    timezone = "Europe/Amsterdam";
  }

  let defaultView: any = localStorage.getItem("timeline_view") ? localStorage.getItem("timeline_view") : general_settings?.planning_screen_view;
  let [view, setView] = useState(defaultView  ?? "week");
  const [calView, setCalView] = useState<MbscEventcalendarView>(null);
  const locationShow = general_settings?.isLocation ? true : false;
  useEffect(()=>{
    if(!isFetchingMonthdata){
      if (responseMonthdata?.length > 0) {
        setmonthViewData(responseMonthdata)
      }
      setyearViewData(responseYeardata)
    }
  },[isFetchingMonthdata])

  useEffect(() => {
    if (
      !filterArray?.isfetchlayoutandfilters
    ) {
      let savedPreferences = loadFromLocalStorage('planning_layout');
      let savedPreferencesplanning = savedPreferences?.planning_filter || filterArray?.layoutandfilters?.planning_filter;
      if(savedPreferencesplanning?.view_type){
        changeView(savedPreferencesplanning?.view_type);
        setCurrentDate(new Date());
      } else {
        setCurrentDate(new Date());
        changeView(defaultView ?? "week");
      }
    }
  }, [filterArray?.isfetchlayoutandfilters]);

  useEffect(() => {
    if(viewSettingsData?.view){
      changeView(view);
    }
  }, [extendedEveningEnd,extendedMorningStart,starttime,endtime]);

  useEffect(() => {
    if (viewSettingsData?.view && calView) {
      const updatedView = updateTimelineView(calView, viewSettingsData);
      setCalView(updatedView);
    }
  }, [viewSettingsData]);
  
  const updateTimelineView = (currentView, settings) => {
    let latestView = JSON.parse(JSON.stringify(currentView));
    
    latestView.timeline.startDay = getStartDay(settings);
    latestView.timeline.endDay = getEndDay(settings);
    
    if (latestView.timeline?.eventList) {
      latestView.timeline.eventList = getEventList(settings);
    }
    
    if (latestView.timeline?.timeCellStep) {
      latestView.timeline.timeCellStep = getColumnSize(settings);
    }
    
    if (latestView.timeline?.timeLabelStep) {
      latestView.timeline.timeLabelStep = getColumnSize(settings);
    }
    
    return latestView;
  };
  
  const getStartDay = (settings) => {
    return (settings?.start || settings?.start === 0) ? +settings.start : 1;
  };
  
  const getEndDay = (settings) => {
    return (settings?.end || settings?.end === 0) ? +settings.end : 5;
  };
  
  const getEventList = (settings) => {
    return settings?.showColumnSummaryEvent ?? null;
  };
  
  const getColumnSize = (settings) => {
    return settings?.columnSize?.hoursPerColumn ?? 60;
  };

  // calculate total duration
  var duration: any = moment.duration(
    moment(endtime, time_format).diff(moment(starttime, time_format))
  );
  // duration in hours
  var hours =
    parseInt(duration.asHours()) + "." + (parseInt(duration.asMinutes()) % 60);

  //popup
  const [isUpdateEvent, setisUpdateEvent] = useState<any>(false);

  const [tempEvent, settempEvent] = useState<any>(null);
  const [tempArgsEvent, settempArgsEvent] = useState<any>(null);
  const [istooltipOpen, settooltipOpen] = useState<any>(false);
  const [isCreateTaskOpen, setIsCreateTaskOpen] = useState<any>(false);

  const [eventDetail, seteventDetail] = useState<any>(null);
  const [createTask, setcreateTask] = useState<any>(null);
  const [showCreateError, setshowCreateError] = useState<any>(false);
  const [refDate, setRefDate] = useState<any>();
  const [rangeVal, setRangeVal] = useState<any>([]);
  const [buttonText, setButtonText] = useState<string>();
  const [isEventDetailPopup, setEventDetailPopup] = useState<any>(false);

  const dispatch = useAppDispatch();

  const setPlanningcapacityview = (day: string) => {
    setView(day);
    changeView(day);
    planningCapacityView(day);
    dispatch(setispageLoad(true));
    setCurrentDate(new Date());
  };

  /* Filter array for company id 0 for create task*/

  const changeView = (value: any) => {
    let calView: MbscEventcalendarView;
    switch (value) {
      case "day":
        calView = {
          timeline: {
            type: "day",
            timeCellStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
            timeLabelStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
            startTime: extendedMorningStart,
            endTime: extendedEveningEnd,
            eventList: viewSettingsData?.showColumnSummaryEvent ?? false,
            maxEventStack: 'all' //viewSettingsData?.numberOfEvents
          },
        };
        break;
      case "week":
      default:
        calView = {
          timeline: {
            type: "week",
            timeCellStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
            timeLabelStep: viewSettingsData?.columnSize?.hoursPerColumn ?? 60,
            startTime: extendedMorningStart,
            endTime: extendedEveningEnd,
            startDay:(viewSettingsData?.start || viewSettingsData?.start === 0) ? +viewSettingsData?.start : 1,
            endDay: (viewSettingsData?.end || viewSettingsData?.end === 0)  ? +viewSettingsData?.end : 5,
            eventList: viewSettingsData?.showColumnSummaryEvent ?? false,
            maxEventStack: 'all'
          },
        };
        break;
      case "month":
        calView = {
          timeline: {
            eventList: true,
            type: "month",
            startTime: extendedMorningStart,
            endTime: extendedEveningEnd,
            startDay: 1,
            endDay: 5,
          },
        };
        break;
      case "year":
        calView = {
          timeline: {
            type: "year",
            startDay: 4,
            endDay: 4,
            weekNumbers: true,
            eventList: true,
          },
        };
        break;
    }
    
    setView(value);
    monthCatchPayload.view_type = value
    setCalView(calView);
  };


  //popup

  const updateEvent = useCallback(
    async (
      event: any,
      split_event: any,
    ) => {
      let e = JSON.parse(JSON.stringify(event));
      let res: any = [];

      e.isProjectScreen = false;
      if (e.split_generate_id !== "" && e.split_generate_id !== undefined) {
        e.id = "";
      }
      let duration = moment.duration(
        moment(e.end).diff(moment(e.start))
      );
      e.planned = duration.asHours();
      e.duration = duration.asHours();
      e.status_name = todo_status_serviceCall?.filter(
        (status: any) =>
          parseInt(status.id) ===
          parseInt(e.servicecall_status)
      )?.[0]?.name
      if(e.duration > 0){
        if(e.id === ""){
          res = await createPopupvalues(e);
        }else{
          res = await updatePopupvalues(e);
        }
      }
      dispatch(setplanningCache(false));
      if (!res || res?.status !== 200) {
        return false;
      } else {
          let planTask:any = planningTask?.filter((item: any) => (item.id !== event.id)) || [];
          let newServicecalls:any = [];
            event.resource = event.servicecall_resources
            event.split_generate_id = ""
            newServicecalls.push(event)
          if (split_event?.length > 0) {
            let splitUpdateArray = await splitedEventCall(split_event, resource_settings?.data, 'planning');
            newServicecalls.push(...splitUpdateArray)
          }
          setPlanningTask([...planTask, ...newServicecalls]);
          //Due to capacity is not refresh 
          refetchPlanning();
          toplanrefetch()
          return true;
      }
    },
    [planningTask, availability]
  );

  const updatePlanningItem = useCallback(
    async (
      event: any,
      servicecallData: any,
      splitId: any,
      dataTodelete: any,
      fromwhere = "user",
      split_generate_id: any = ""
    ) => {
      
      let planItem: any;
      let planItem1: any;
      planItem1 = planningTask;
      if(view === 'day' || view === "week"){
        if (fromwhere === "user") {
          if (event) {
            if (splitId === "delete_sc") {
              planItem = planItem1.filter(
                (item: any) => (item.id !== event[0]?.id)
              );
            } else if (dataTodelete) {
              planItem = await planItem1?.filter(
                (item: any) => (item.id !== dataTodelete)
              );
            } else {
              if (servicecallData?.serviceCallID) {
                planItem = planItem1.filter(
                  (item: any) =>
                    !(
                      item.id === event[0]?.id ||
                      item.serviceCallId === servicecallData?.serviceCallID
                    )
                );
              } else {
                planItem = planItem1.filter(
                  (item: any) => (item.id !== event[0]?.id)
                );
              }
            }
            if (split_generate_id) {
              if (servicecallData?.type === "update_sc_changes_failed") {
                planItem = planItem.filter(
                  (item: any) => (item.split_generate_id !== split_generate_id)
                );
              } else {
                let createdServicecall = planItem.filter(
                  (item: any) => item.id === event[0]?.mongo_id
                );

                planItem = planItem.filter(
                  (item: any) => (item.id !== event[0]?.mongo_id)
                );
                if (createdServicecall?.length > 0) {
                  createdServicecall[0].autotask_id = event[0]?.autotask_id;
                  createdServicecall[0].serviceCallId = event[0]?.autotask_id;
                  createdServicecall[0].id = event[0]?.mongo_id;
                  createdServicecall[0].entity_id = event[0]?.autotask_id;
                  createdServicecall[0].split_generate_id = "";
                  event = createdServicecall;
                }
              }
            }
            if (splitId === "delete_sc" || !Array.isArray(event)) {
              setPlanningTask(planItem);
            } else {
              if(event[0]?.resource){
                setPlanningTask([...planItem, ...event]);
              }
            }
            dispatch(setisCreated(true));
            if (
              servicecallData?.type !== "delete_sc_success" &&
              servicecallData?.type !== "update_sc_success"
            ) {
              refetchMonthdata();
            } else {
              toplanrefetch();
              refetchMonthdata();
            }
          }
        } else {
          if (event) {
            let sortData: any = planItem1.filter(
              (item: any) =>
                item.id === event[0]?.id ||
                item.serviceCallId === servicecallData?.serviceCallID
            );
            if (sortData.length > 0) {
              planItem = planItem1.filter(
                (item: any) =>
                  !(
                    item.id === event[0]?.id ||
                    item.serviceCallId === servicecallData?.serviceCallID
                  )
              );
              if (split_generate_id) {
                let createdServicecall = planItem.filter(
                  (item: any) => item.id === event[0]?.mongo_id
                );

                planItem = planItem.filter(
                  (item: any) => (item.id !== event[0]?.mongo_id)
                );
                createdServicecall[0].autotask_id = event[0]?.autotask_id;
                createdServicecall[0].entity_id = event[0]?.autotask_id;
                createdServicecall[0].id = event[0]?.mongo_id;
                createdServicecall[0].serviceCallId = event[0]?.autotask_id;
                createdServicecall[0].split_generate_id = "";
                event = createdServicecall;
              }
              if (Array.isArray(event)) {
                setPlanningTask([...planItem, ...event]);
              } else {
                setPlanningTask(planItem);
              }
            }

            if (sortData.length > 0) {
              dispatch(setisCreated(true));
              if (servicecallData?.type === "update_sc_changes_failed") {
                setTimeout(() => {
                  toplanrefetch();
                }, 10000);
              } else {
                toplanrefetch();
              }
              setEventOpen(false);
              if (
                servicecallData?.type !== "delete_sc_success" &&
                servicecallData?.type !== "update_sc_success"
              ) {
                refetchMonthdata();
              }
            }
          }
        }
      }
    },
    [planningTask]
  );

  const splitEventsFn = useCallback(
    async (event: any, fromwhere: any) => {
      event.status_name = todo_status_serviceCall?.filter(
        (status: any) =>
          parseInt(status.id) ===
          parseInt(event.servicecall_status)
      )?.[0]?.name
      let data:any = await splitServicecallEvents(event,starttime,endtime,timezone)
      setSplitEventsData(data.splitData);
      if (fromwhere === "fromdrag") {
        seteventDetail(data.event);
        updateEvent(data.event, data.splitData, []);
      }
    },
    [updateEvent, endtime, starttime, planningTask]
  );

  const onClose = useCallback(() => {
    let planTask: any;
    planTask = planningTask?.filter(
      (item: any) =>
        !(item.id === eventDetail.id)
    ) || [];
    if (isUpdateEvent) {
      setPlanningTask([...planTask, tempEvent]);
    } else {
      setPlanningTask(planTask);
    }
    if (eventDetail.type) {
      let div = document.getElementById("avail_task_" + tempEvent.id);
      if (div) {
        div!.style.display = "";
      }
    }
    settooltipOpen(false);
  }, [availability, planningTask, tempEvent, eventDetail, isUpdateEvent]);

  const [hoveredEventId, setHoveredEventId] = useState(null);
  const [isHoverColor,setIsHoverColor] = useState<string>('');

  const onEventDragStart = useCallback((args: any) => {
    let eventType = args.event.type.toLowerCase();
  
    if (eventType === "task") {
      setIsHoverColor(TASK_HOVER_COLOR);
    }else if (eventType === "ticket") {
      setIsHoverColor(TICKET_HOVER_COLOR);
    }else if (eventType === "servicecall") {
      setIsHoverColor(SERVICECALL_HOVER_COLOR);
    }
    
    setHoveredEventId(args.event.id);
    settooltipOpen(false);
  }, []);

  // POPUP update
  // END:Popup update

  const loadPopupForm = useCallback(
    async (event: any) => {
      if (
        general_settings?.default_servicecall_status &&
        todo_status_serviceCall?.some(
          (e: any) =>
            parseInt(e.id) ===
            parseInt(general_settings?.default_servicecall_status)
        )
      ) {
        event.servicecall_status = general_settings?.default_servicecall_status;
      }
      event.assigntoresource = [];
      event.servicecall_resources = [];
      let defaultSetting: any = await Array.from(
        resource_settings?.data
      ).filter((ele: any) => parseInt(ele.id) === parseInt(event.resource));
      if (event?.related_tickets && event?.related_tickets?.length > 0) {
        let default_ticket_role:any = defaultSetting[0]?.ticket_roles?.filter((i:any) => i?.isDefault === true);
        event?.related_tickets?.filter((sticket: any) => {
          //status
          if (
            general_settings?.default_ticket_status &&
            ticket_status?.some(
              (e: any) =>
                parseInt(e.id) ===
                parseInt(general_settings?.default_ticket_status)
            )
          ) {
            sticket.status = general_settings?.default_ticket_status;
          }

          //if primary not set then primary first
          if (
            sticket.assignedResourceID === "" ||
            sticket.assignedResourceID === null
          ) {
            if (
              default_ticket_role?.[0]?.value &&
              default_ticket_role?.[0]?.value !== "" &&
              default_ticket_role?.[0]?.value !==
                undefined
            ) {
              sticket.assignedResourceID = event.resource;
              sticket.assignedResourceRoleID =
                default_ticket_role?.[0].value;
              sticket.departmentID =
                default_ticket_role?.[0]?.departmentID;
              event.servicecall_resources.push(parseInt(event.resource));
            } else {
              sticket.errorinDefaultrole = "in_primary";
            }
          } else {
            if (
              (sticket?.secondary_resources.filter(
                (secondaryres: any) =>
                  parseInt(secondaryres.resourceID) === parseInt(event.resource)
              )).length === 0
            ) {
              if (
                default_ticket_role?.[0]?.value &&
                default_ticket_role?.[0]?.value !== "" &&
                default_ticket_role?.[0]?.value !==
                  undefined
              ) {
                sticket?.secondary_resources.push({
                  resourceID: event.resource,
                  roleID: default_ticket_role?.[0]?.value,
                });
                event.servicecall_resources.push(parseInt(event.resource));
              } else {
                sticket.errorinDefaultrole = "in_secondary";
              }
            }
          }
          sticket.secondary_resources = sticket?.secondary_resources?.filter(
            (secondaryres: any) =>
              parseInt(secondaryres.resourceID) !==
              parseInt(sticket.assignedResourceID)
          );
          sticket?.secondary_resources?.filter((secondaryres: any) => {
            if (secondaryres.roleID) {
              secondaryres.value = parseInt(
                secondaryres.resourceID.toString() +
                  secondaryres.roleID.toString()
              );
            } else {
              secondaryres.value = parseInt(
                secondaryres.resourceID.toString() + secondaryres.roleID
              );
            }
            event.assigntoresource.push(parseInt(secondaryres.resourceID));
            event.servicecall_resources.push(parseInt(secondaryres.resourceID));
          });

          sticket.secondary_resources = sticket?.secondary_resources?.filter(
            (obj: any, index: any, self: any) =>
              self.findIndex((o: any) => o.resourceID === obj.resourceID) ===
              index
          );

          event.assigntoresource.push(parseInt(sticket.assignedResourceID));
          event.servicecall_resources.push(
            parseInt(sticket.assignedResourceID)
          );
          let isexist = sticket?.secondary_resources?.filter(
            (res: any) => res.resourceID === event.resource
          );
          if (isexist?.length > 0) {
            event.servicecall_resources.push(parseInt(event.resource));
          }
          if (
            general_settings?.default_ticket_worktype &&
            worktype?.some(
              (e: any) =>
                parseInt(e.value) ===
                parseInt(general_settings?.default_ticket_worktype)
            )
          ) {
            sticket.billingCodeID = general_settings?.default_ticket_worktype;
          }
          let status_name = ticket_status?.filter(
            (status: any) =>
              parseInt(status.id) === parseInt(sticket.status)
          );
          sticket.status_name = status_name?.[0]?.name;
        });
      }
      if (event?.related_tasks && event?.related_tasks?.length > 0) {
        let default_task_role:any = defaultSetting[0]?.task_roles?.filter((i:any) => i?.isDefault === true);
        event?.related_tasks?.filter((stask: any) => {
          
          if (
            general_settings?.default_task_status &&
            task_status?.some(
              (e: any) =>
                parseInt(e.id) ===
                parseInt(general_settings?.default_task_status)
            )
          ) {
            stask.status = general_settings?.default_task_status;
          }

          if (
            stask.assignedResourceID === "" ||
            stask.assignedResourceID === null
          ) {
            if (
              default_task_role?.[0]?.value &&
              default_task_role?.[0]?.value !== "" &&
              default_task_role?.[0]?.value !== undefined
            ) {
              stask.assignedResourceID = event.resource;
              stask.assignedResourceRoleID =
              default_task_role?.[0]?.value;
              stask.departmentID =
              default_task_role?.[0]?.departmentID;
              event.servicecall_resources.push(parseInt(event.resource));
            } else {
              stask.errorinDefaultrole = "in_primary";
            }
          } else {
            if (
              (stask?.secondary_resources.filter(
                (secondaryres: any) =>
                  parseInt(secondaryres.resourceID) === parseInt(event.resource)
              )).length === 0
            ) {
              if (
                default_task_role?.[0]?.value &&
                default_task_role?.[0]?.value !== "" &&
                default_task_role?.[0]?.value !==
                  undefined
              ) {
                stask?.secondary_resources.push({
                  resourceID: event.resource,
                  roleID: default_task_role?.[0]?.value,
                });
                event.servicecall_resources.push(parseInt(event.resource));
              } else {
                stask.errorinDefaultrole = "in_secondary";
              }
            }
          }
          stask.secondary_resources = stask?.secondary_resources?.filter(
            (secondaryres: any) =>
              parseInt(secondaryres.resourceID) !==
              parseInt(stask.assignedResourceID)
          );
          stask?.secondary_resources?.filter((secondaryres: any) => {
            if (secondaryres.roleID) {
              secondaryres.value = parseInt(
                secondaryres.resourceID.toString() +
                  secondaryres.roleID.toString()
              );
            } else {
              secondaryres.value = parseInt(
                secondaryres.resourceID.toString() + secondaryres.roleID
              );
            }
            event.assigntoresource.push(parseInt(secondaryres.resourceID));
            event.servicecall_resources.push(parseInt(secondaryres.resourceID));
          });
          event.assigntoresource.push(parseInt(stask.assignedResourceID));
          event.servicecall_resources.push(parseInt(stask.assignedResourceID));

          stask.secondary_resources = stask?.secondary_resources?.filter(
            (obj: any, index: any, self: any) =>
              self.findIndex((o: any) => o.resourceID === obj.resourceID) ===
              index
          );
          let isexist1 = stask?.secondary_resources?.filter(
            (res1: any) => res1.resourceID === event.resource
          );
          if (isexist1?.length > 0) {
            event.servicecall_resources.push(parseInt(event.resource));
          }
          if (
            general_settings?.default_task_worktype &&
            worktype?.some(
              (e: any) =>
                parseInt(e.value) ===
                parseInt(general_settings?.default_task_worktype)
            )
          ) {
            stask.billingCodeID = general_settings?.default_task_worktype;
          }
          let status_name = ticket_status?.filter(
            (status: any) =>
              parseInt(status.id) === parseInt(stask.status)
          );
          stask.status_name = status_name?.[0]?.name;
        });
        event.billing_code_id = event.related_tasks ? event.related_tasks[0]?.billingCodeID : ""
      }else{
        event.billing_code_id = event?.related_tickets ? event?.related_tickets[0]?.billingCodeID : ""
      }
      event.servicecall_resources = event.servicecall_resources?.filter(
        (value: any, index: any, array: any) => array.indexOf(value) === index
      );
      if (
        general_settings?.allow_overlapping === false &&
        hasOverlap(event, true)
      ) {
        toast({
          message: "Overlapping tasks is not allowed",
          display: "center",
        });
        onClose();
        return false;
      } else {
        settooltipOpen(true);
        seteventDetail(event);
      }
    },
    [resource_settings, general_settings, eventDetail, tempEvent, planningTask]
  );

  const hasOverlap = (args: any, inst: any) => {
    const ev = args;
    let events = [];
    var resultData = planningTask?.filter(function (a: any) {
      return (
        (moment(ev.start).isSameOrAfter(a.start) &&
          moment(ev.start).isSameOrBefore(a.end)) ||
        (moment(ev.end).isSameOrAfter(a.start) &&
          moment(ev.end).isSameOrBefore(a.end))
      );
    });
    if (!inst) {
      events = resultData?.filter(
        (e: any) =>
          e.id !== ev.id &&
          ev?.servicecall_resources.includes(parseInt(e.resource))
      );
    } else {
      events = resultData?.filter(
        (e: any) => e.id !== ev.id && e.resource === ev.resource
      );
    }
    return events?.length > 0;
  };
  
  const onEventCreate = useCallback(
    async (args: any, inst: any) => {
      toggleDropdown("")
      let hasNoLicense:any = await resourcehasNoLicenseMoreMultiple([args?.event?.resource],resource_data)
      if(hasNoLicense === true){
        if (isCreateTaskOpen) {
          onClose();
          settooltipOpen(false);
        } else {
          setisUpdateEvent(false);
          setEventDetailPopup(false);
          let currentEvent:any;
          currentEvent = JSON.parse(JSON.stringify(args.event))
          if(view === 'year'){
            let monthData = yearViewData?.filter((item:any) => (item.autotask_id !== args.event.autotask_id));
            setyearViewData(monthData)
          }else if(view === 'month'){
            let monthData = monthViewData?.filter((item:any) => (item.autotask_id !== args.event.autotask_id));
            setmonthViewData(monthData)
          }
          if(args.event?.type === 'task'){
            currentEvent = await getServicecallDetail({
              task_id: args?.event?.autotask_id,
              isProjectScreen: false,
            });
            currentEvent.id = (Math.random() + 1).toString(6).substring(6);
            currentEvent.serviceCallId = "";
            currentEvent.split_generate_id = (Math.random() + 1)
              .toString(6)
              .substring(6);
          } else if (
            args.event?.type === "serviceCall" ||
            args.event?.type === "servicecall"
          ) {
            let exist_servicecall:any = planningTask?.filter(
              (item: any) => (item.id === args.event.id)
            ) || [];
            setexistServicecall(exist_servicecall);
            currentEvent = await getServicecallDetail({
              id: args?.event?.id,
              isProjectScreen: false,
            });
            currentEvent.serviceCallId = currentEvent?.autotask_id ? currentEvent.autotask_id : "";
            setEventDetailPopup(true);
          } else {
            currentEvent = await getServicecallDetail({
              ticket_id: args?.event?.autotask_id,
              isProjectScreen: false,
            });
            currentEvent.id = (Math.random() + 1).toString(6).substring(6);
            currentEvent.serviceCallId = "";
            currentEvent.split_generate_id = (Math.random() + 1)
              .toString(6)
              .substring(6);
          }
          if (currentEvent?.entity_type) {
            currentEvent.type = currentEvent.entity_type;
          }
          
          currentEvent.start = args.event.start
          currentEvent.end = args.event.end
          currentEvent.resource = args.event.resource
          currentEvent.servcallTitle = currentEvent.title
          let event = JSON.parse(JSON.stringify(currentEvent));
          if(view === 'month' || view === 'year'){
            let year =  moment.utc(event.start.toLocaleString()).year()
            let start_with_timezone1 = moment(event.start).tz(timezone).format("YYYY-MM-DDTHH:mm:ss");
            let weeknumber = moment.utc(start_with_timezone1).week()
            let date = event.start
            if(view === 'year'){
              date = moment().utc().day("Monday").isoWeekYear(year).isoWeek(weeknumber).format("YYYY-MM-DD")
            }
            let start = moment.tz(moment(date).format("YYYY-MM-DDT") + starttime,timezone).utc().format("YYYY-MM-DDTHH:mm:ss");
            event.start = start + "Z";
            let end = moment(event.start).add(args.event.duration , "hours").utc().format("YYYY-MM-DDTHH:mm");
            event.end = end + "Z";
            event.weeknumber = weeknumber
          }
          event.type = 'servicecall';
          event.entity_type = 'servicecall';
          let valid: any = await checkBufferHours(event,starttime, endtime, timezone);
          if (!valid[0]) {
            let planTask: any = planningTask?.filter(
              (item: any) =>
                !(
                  item.id === args.event.id
                )
            ) || [];
            setPlanningTask(planTask);
            return false;
          }
          if (args.event.type) {
            let div = document.getElementById("avail_task_" + args.event.id);
            if (div && div?.style?.display) {
              div!.style.display = "none";
            }
          }
          seteventDetail(null);
          setSplitEventsData(null);
          settempArgsEvent(null);
          settempEvent(JSON.parse(JSON.stringify(args.event)));
          settempArgsEvent(args);
          event.from_where = "plan_bucket";
          loadPopupForm(event);
        }
      }else{
        toast({
          message: "One of the assigned resources does not have a valid license. Please update the resource license and try again.",
          display: "center",
        });
        if(view === 'month'){
          let monthData: any = monthViewData.filter(
            (item: any) =>
              !(
                item.id === args.event.id 
              )
          );
          setmonthViewData(monthData);
          return false;
        }else if(view === 'year'){
          let yearData: any = yearViewData.filter(
            (item: any) =>
              !(
                item.id === args.event.id
              )
          );
          setyearViewData(yearData);
          return false;
        }else{
          let planTask: any = planningTask?.filter(
            (item: any) =>
              !(
                item.id === args.event.id
              )
          ) || [];
          setPlanningTask(planTask);
          return false;
        }
      }
    },
    [
      loadPopupForm,
      general_settings?.allow_overlapping,
      availability,
      tempEvent,
      isUpdateEvent,
      eventDetail,
      planningTask,
      splitEventData,
      starttime,
      endtime,
      tempArgsEvent,
      isCreateTaskOpen,
      todo_status_serviceCall,
      view,
      monthViewData,
      yearViewData
    ]
  );

  const onEventUpdate = useCallback(
    async (args: any, inst: any) => {
      toggleDropdown("")
      let hasNoLicense:any = await resourcehasNoLicenseMoreMultiple([parseInt(args?.resource),parseInt(args?.oldResource)],resource_data)
      let planTask: any;
      if(hasNoLicense === true){
        if (isCreateTaskOpen) {
          onClose();
          settooltipOpen(false);
        } else {
          setEventDetailPopup(false);
          //checks if user is not going beyond the  timelimit  in planning view and if start date is not after end date here
          let valid: any = await checkBufferHours(
            args.event,
            starttime,
            endtime,
            timezone
          );
          if (!valid[0]) {
            let oldevent = args.oldEvent;
            let planTask: any = planningTask?.filter(
              (item: any) =>
                !(
                  item.id === args.event.id
                )
            ) || [];
            setPlanningTask([...planTask, oldevent]);
            return false;
          }

          let event = JSON.parse(JSON.stringify(args.event));
          if (args.event?.from_copy !== true) {
            let currentEvent = await getServicecallDetail({
              id: args.event?.id,
              isProjectScreen: false,
            });
            if (!currentEvent) {
              let oldevent = args.oldEvent;
              planTask = planningTask?.filter(
                (item: any) => !(item.id === args.event.id)
              ) || [];
              setPlanningTask([...planTask, oldevent]);
              return false;
            }
            
            let hasNoLicenseDetail:any =true;
            let resourceId:any = []
            if (currentEvent?.related_tickets && currentEvent?.related_tickets?.length > 0) {
              currentEvent?.related_tickets?.filter((sticket: any) => {
                if(sticket?.assignedResourceID){
                  resourceId.push(parseInt(sticket?.assignedResourceID))
                }
                sticket.secondary_resources?.filter((secondaryres: any) => {
                  resourceId.push(parseInt(secondaryres.resourceID));
                });
              })
            }
            if (currentEvent?.related_tasks && currentEvent?.related_tasks?.length > 0) {
              currentEvent?.related_tasks?.filter((stask: any) => {
                if(stask?.assignedResourceID){
                  resourceId.push(parseInt(stask?.assignedResourceID))
                }
                stask.secondary_resources?.filter((secondaryres: any) => {
                  resourceId.push(parseInt(secondaryres.resourceID));
                });
              })
            }
            hasNoLicenseDetail = await resourcehasNoLicenseMoreMultiple(resourceId,resource_data)
            if (hasNoLicenseDetail === false) {
              let oldevent = args.oldEvent;
              planTask = planningTask?.filter(
                (item: any) => !(item.id === args.event.id)
              ) || [];
              setPlanningTask([...planTask, oldevent]);
              toast({
                message: "One of the assigned resources does not have a valid license. Please update the resource license and try again.",
                display: "center",
              });
              return false;
            }
            event = JSON.parse(JSON.stringify(currentEvent));
            event.start = args.event.start;
            event.end = args.event.end;
            event.resource = args.event.resource;
            event.type = 'servicecall';
            event.servcallTitle = event.title;
            if (args.event?.from_copy === true) {
              event.serviceCallId = "";
              event.id = (Math.random() + 1).toString(6).substring(6);
              event.split_generate_id = (Math.random() + 1)
                .toString(6)
                .substring(6);
            } else {
              event.serviceCallId = currentEvent.autotask_id;
            }
          }
          event.removed_tasks = []
          event.removed_tickets = []
          seteventDetail(event);
          setSplitEventsData(null);
          setisUpdateEvent(false);
          settempEvent(null);
          settempArgsEvent(null);
          if (args?.resource !== args.oldResource) {
            settempEvent(args.oldEvent);
            settempArgsEvent(args);
            seteventDetail(event);
            setisUpdateEvent(true);
            let status_title = todo_status_serviceCall?.filter(
              (item1: any) =>
                parseInt(item1?.id) === parseInt(args.event.servicecall_status)
            );
            event.servicecall_status_name = status_title[0]?.label;
            event.resource = args?.resource;
            loadPopupForm(event);
          } else {
            if (
              general_settings?.allow_overlapping === false &&
              hasOverlap(args.event, true)
            ) {
              toast({
                message: "Overlapping tasks is not allowed",
                display: "center",
              });

              let planTask: any;
              let oldevent = args.oldEvent;

              planTask = planningTask?.filter(
                (item: any) =>
                  !(
                    item.id === args.event.id
                  )
              ) || [];

              setPlanningTask([...planTask, oldevent]);
              return false;
            } else {
              splitEventsFn(event, "fromdrag");
            }
          }
        }
      }else{
        let oldevent = args.oldEvent;
        planTask = planningTask?.filter(
          (item: any) => !(item.id === args.event.id)
        );
        setPlanningTask([...planTask, oldevent]);
        toast({
          message: "One of the assigned resources does not have a valid license. Please update the resource license and try again.",
          display: "center",
        });
        return false;
      }
    },
    [
      loadPopupForm,
      eventDetail,
      general_settings?.allow_overlapping,
      planningTask,
      availability,
      tempEvent,
      isUpdateEvent,
      splitEventData,
      starttime,
      endtime,
      tempArgsEvent,
      isCreateTaskOpen,
      splitEventsFn,
      view
    ]
  );

  const onEventUpdated = useCallback(
    async () => {
    },
    [
      loadPopupForm,
      eventDetail,
      general_settings?.allow_overlapping,
      planningTask,
      availability,
      tempEvent,
      isUpdateEvent,
      splitEventData,
      starttime,
      endtime,
      tempArgsEvent,
      isCreateTaskOpen,
      splitEventsFn,
    ]
  );

  const onLinkClick = useCallback((e: any, args: any) => {
    e.stopPropagation();
    window.open(args, "_blank", "noreferrer");
  }, []);

  //open tooltip
  const onEventClick = useCallback(
    async (e: any, args: any) => {
      toggleDropdown("")
        if (args?.type !== "appointment" && args?.editable !== false) {
          let event = args;
          if (args?.from_copy !== true) {
            let currentEvent = await getServicecallDetail({
              id: args?.id,
              isProjectScreen: false,
            });

            currentEvent.resource = args?.resource;
            event = currentEvent;
            if(view === 'year'){
              let start_with_timezone1 = moment(event.start).tz(timezone).format("YYYY-MM-DDTHH:mm:ss");
              let weeknumber =  moment.utc(start_with_timezone1).week()
              event.weeknumber = weeknumber;
            }
          }else{
            if((view === 'month' || view === 'year') && args?.from_copy !== true){
              let start_with_timezone1 = moment(event.start).tz(timezone).format("YYYY-MM-DDTHH:mm:ss");
              let weeknumber =  moment.utc(start_with_timezone1).week()
              let year =  moment.utc(event.start.toLocaleString()).year()
              let date = event.start
              if(view === 'year'){
                date = moment().utc().day("Monday").isoWeekYear(year).isoWeek(weeknumber).format("YYYY-MM-DD")
              }
              let start = moment.tz(moment(date).format("YYYY-MM-DDT") + starttime,timezone).utc().format("YYYY-MM-DDTHH:mm:ss");
              event.start = start + "Z";
              let end = moment(event.start).add(general_settings?.service_call_duration , "hours").utc().format("YYYY-MM-DDTHH:mm");
              event.end = end + "Z";
              event.weeknumber = weeknumber;
            }
          }
          event.removed_tasks = []
          event.removed_tickets = []
          event.isOvertimeShow = false;
          settempEvent(null);
          settempArgsEvent(null);
          setSplitEventsData(null);
          setEventDetailPopup(true);
          seteventDetail(event);
          if (!isCreateTaskOpen) {
            settooltipOpen(true);
          }
          if (args?.from_copy !== true) {
            settempEvent(JSON.parse(JSON.stringify(args)));
          }
        }
      // }
    },
    [
      eventDetail,
      planningTask,
      availability,
      tempEvent,
      starttime,
      endtime,
      splitEventData,
      settempArgsEvent,
      isCreateTaskOpen,
      todo_status_serviceCall,
      view
    ]
  );

  const createTaskTicket = useCallback(
    (e: any) => {
      if (isCreateTaskOpen === false) {
        let task: any = {};
        task.type = e.target.id;
        task.title = "";
        setcreateTask(task);
        setshowCreateError(false);
        setIsPopupOpen(true);
        setIsCreateTaskOpen(true);
      }
    },
    [createTask, general_settings, showCreateError, isCreateTaskOpen]
  );

  const renderCustomDay = useCallback((args: any) => {
    const date = args.date;
    let eventOccurrence = "none";

    var capacity = 0;

    var planned = 0;
    let weeknumber: any;
    let weekday: any;
    let year: any;
    let monthnumber: any;
    
    let ar = dateHeaderCal?.filter(
      (item: any) =>
        moment(item.start).format("YYYY-MM-DD") ===
        moment(args.date).format("YYYY-MM-DD")
    );
    if (ar.length > 0) {
      planned = ar?.[0].planned;
      capacity = ar?.[0].capacity;
    }
    weeknumber = moment.utc(date).week();
    year = moment.utc(date).year();
    const getmonth = moment.utc(date).month();
    monthnumber = getmonth + 1;
    weekday = moment().utc().year(year).week(weeknumber).day("Thursday");
    var monday = moment(date).clone().weekday(1);
    var friday = moment(date).clone().weekday(7);
    let total_capacity = capacity ? capacity : 0;
    let total_planned = planned ? planned : 0;
    let percentage = (total_planned * 100) / total_capacity;
    percentage = isNaN(percentage) ? 0 : percentage;
    let available_per = 100 - percentage;
    let overbooked_per = 0;
    let overbooked_per1 = 0;
    if (percentage > 100) {
      overbooked_per = 100;
      overbooked_per1 = percentage - 100;
    }
    let total_availability = total_capacity - total_planned;
    let total_overbooked = 0;
    let week_overbooked = 0;

    if (total_availability < 0) {
      week_overbooked = total_planned - total_capacity;
      total_overbooked = total_availability;
    } else {
      total_overbooked = 0;
    }
    if (args.events) {
      if (percentage === 0) {
        eventOccurrence = "none";
      } else if (percentage <= 25) {
        eventOccurrence = "25";
      } else if (percentage > 25 && percentage <= 50) {
        eventOccurrence = "50";
      } else if (percentage > 50 && percentage <= 75) {
        eventOccurrence = "75";
      } else {
        eventOccurrence = "more";
      }
    }
    return (
      <OverlayTrigger
        key="copy-to-clipboard"
        placement="auto"
        overlay={
          <Tooltip id="tooltip-copy-to-clipboard" className="position-absolute text-nowrap setPointerarrow">
           <div
              className="md-tooltip-info align-left"
              style={{ textAlign: "left" }}
            >
              <div className="md-tooltip-title text-white">
                Total capacity:{" "}
                <span className="md-tooltip-reason md-tooltip-text">
                  {Number((Math.round(total_capacity * 100) / 100).toFixed(2))}
                </span>
              </div>
              <div className="md-tooltip-title text-white">
                Planned capacity:{" "}
                <span className="md-tooltip-location md-tooltip-text">
                  {Number((Math.round(total_planned * 100) / 100).toFixed(2))}
                </span>
              </div>
              {total_overbooked === 0 && (
                <div className="md-tooltip-title text-white">
                  Left capacity:{" "}
                  <span className="md-tooltip-location md-tooltip-text">
                    {Number(
                      (Math.round(total_availability * 100) / 100).toFixed(2)
                    )}
                  </span>
                </div>
              )}
              {Math.abs(total_overbooked) > 0 && (
                <div className="md-tooltip-title text-white">
                  Total overbooked:{" "}
                  <span className="md-tooltip-location md-tooltip-text">
                    {Number(
                      (
                        Math.round(Math.abs(total_overbooked) * 100) / 100
                      ).toFixed(2)
                    )}
                  </span>
                </div>
              )}
            </div>
          </Tooltip>
        }
      >
        <div className="d-flex align-items-center" >
          {view === "day" || view === "week" || view === "month" ? (
            <div
              className={
                "progress-tooltip md-date-header md-date-header-events-" + eventOccurrence
              }              
            >
              <div className="md-date-header-day-name">
                {formatDate("DDD", date)}
              </div>
              &nbsp;
              <div className="md-date-header-day-nr">
                {formatDate("DD", date)}
              </div>
            </div>
          ) : (
            <div
              className={
                "progress-tooltip md-date-header md-date-header-events-" + eventOccurrence
              }              
            >
              <div className="md-date-header-day-name">
                {monday.format("DD-MM")} - {friday.format("DD-MM")}
              </div>
            </div>
          )}
          {(view && view === "week") || view === "day" ? (
            <div className="progress h-25px flex-fill rounded-4px me-1 ms-2">
              {overbooked_per === 0 ? (
                <>
                  <div
                    className="progress-bar text-dark fs-16 fw-bold h-100"
                    style={{ background: "#336FC9", width: `${percentage}%` }}
                  >
                    {percentage >= available_per &&
                      Number(
                        (Math.round(total_planned * 100) / 100).toFixed(2)
                      ) +
                        "/" +
                        Number(
                          (Math.round(total_capacity * 100) / 100).toFixed(2)
                        )}{" "}
                  </div>
                  {percentage !== 100 && (
                    <div
                      className="progress-bar h-100 text-center text-dark fs-16 fw-bold px-2"
                      style={{
                        background: "#72B4D9",
                        width: `${available_per}%`,
                      }}
                    >
                      {percentage < available_per &&
                        Number(
                          (Math.round(total_planned * 100) / 100).toFixed(2)
                        ) +
                          "/" +
                          Number(
                            (Math.round(total_capacity * 100) / 100).toFixed(2)
                          )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div
                    className="progress-bar text-dark fs-16 fw-bold h-100"
                    style={{
                      background: "#D97872",
                      width: `${overbooked_per}%`,
                    }}
                  >
                    {Number(
                      (Math.round(Math.abs(total_planned) * 100) / 100).toFixed(
                        2
                      )
                    )}
                    /
                    {Number(
                      (Math.round(total_capacity * 100) / 100).toFixed(2)
                    )}
                  </div>
                </>
              )}

            </div>
          ) : (
            ""
          )}
        </div>
      </OverlayTrigger>
    );
  },[monthYearDataResponse, dateHeaderCal, view]);

  let myInvalid: any = [];
  if (view === "week" || view === "day") {
    let bstart: any = moment
      .tz(filterIds?.start_date, "YYYY-MM-DD", true, timezone)
      .format("YYYY-MM-DD");
    let bend: any = moment
      .tz(filterIds?.end_date, "YYYY-MM-DD", true, timezone)
      .format("YYYY-MM-DD");
    let mstart: any =
      bstart +
      "T" +
      moment.tz(extendedMorningStart, "HH:mm", true, timezone).format("HH:mm");
    let mend: any =
      bstart +
      "T" +
      moment.tz(extendedMorningEnd, "HH:mm", true, timezone).format("HH:mm");
    let estart: any =
      bend +
      "T" +
      moment.tz(extendedEveningStart, "HH:mm", true, timezone).format("HH:mm");
    let eend: any =
      bend +
      "T" +
      moment.tz(extendedEveningEnd, "HH:mm", true, timezone).format("HH:mm");
    if (parseFloat(hours) <= 22) {
      myInvalid = [
        {
          start: moment
            .tz(mstart, "YYYY-MM-DDTHH:mm", true, timezone)
            .tz("UTC")
            .format("YYYY-MM-DDTHH:mm"),
          end: moment
            .tz(mend, "YYYY-MM-DDTHH:mm", true, timezone)
            .tz("UTC")
            .format("YYYY-MM-DDTHH:mm"),
          title: "",
          background:
            "#EFEFEF",
          recurring: {
            repeat: "weekly",
            weekDays: "MO,TU,WE,TH,FR,SA,SU",
          },
          cssClass: "md-lunch-break-class mbsc-flex",
        },
        {
          start: moment
            .tz(estart, "YYYY-MM-DDTHH:mm", true, timezone)
            .tz("UTC")
            .format("HH:mm"),
          end: moment
            .tz(eend, "YYYY-MM-DDTHH:mm", true, timezone)
            .tz("UTC")
            .format("HH:mm"),
          title: "",
          background: "#EFEFEF",  
          recurring: {
            repeat: "weekly",
            weekDays: "MO,TU,WE,TH,FR,SA,SU",
          },
          cssClass: "md-lunch-break-class mbsc-flex",
        },
      ];
    }
  }

  const startDate: any = useRef();
  const endDate: any = useRef();

  // returns the number of days between two dates
  const getNrDays = useCallback((start: any, end: any) => {
    return (
      Math.round(
        Math.abs((end.setHours(0) - start.setHours(0)) / (24 * 60 * 60 * 1000))
      ) + 1
    );
  }, []);

  // returns the formatted date
  const getFormattedRange = useCallback(
    (start: any, end: any) => {
      return (
        formatDate("MMM D, YYYY", new Date(start)) +
        (end && getNrDays(start, end) > 1
          ? " - " + formatDate("MMM D, YYYY", new Date(end))
          : "")
      );
    },
    [getNrDays]
  );

  const onPageLoading = useCallback(
    (args: any) => {
      const sDate = args.firstDay;
      const end = args.lastDay;

      const eDate = new Date(
        end.getFullYear(),
        end.getMonth(),
        end.getDate() - 1,
        0
      );
      startDate.current = sDate;
      endDate.current = eDate;
      setTimeout(() => {
        setButtonText(getFormattedRange(sDate, eDate));
        setRangeVal([sDate, eDate]);
      });
    },
    [getFormattedRange]
  );

  const EventList = useCallback(
    async (args: any, inst: any) => {
     if ((view === "month" || view === "year") && !isLoadingView) {
        setIsEventLoading(true)
        setEventOpen(true); 
        if (lockedResourceData?.indexOf(args.resource) !== -1) {
          setEventOpen(true); 
          setAnchor(args.domEvent.target);
          setMonthEvent([]);
          return false;
        }
        let startDate =  moment(args.date).format("YYYY-MM-DD")
        let endDate = startDate
        let type = "day"
        if (view === "year") {
          const weekDate = getWeekStartAndEndDate(args.date)
          startDate = weekDate.startDate
          endDate = weekDate.endDate
          type = "week"
        } 
        
        setAnchor(args.domEvent.target);
        setMonthEvent([]);
        let payload = {
          resources:[args.resource],
          start_date:startDate,
          end_date:endDate,
          view_type:type,
          servicecall_status_ids:filterIds?.servicecall_status_ids || []
        }
        const data = await getPlanningData(
          false,
          payload,
          initial_sync_completed_or_not
        );        
        setEventOpen(false); 
        setIsEventLoading(false)
        setTimeout(() => {
          setEventOpen(true);
          setAnchor(args.domEvent.target);
          setMonthEvent(data.formattedData);
        },1)
      }
    },
    [view, planningTask, monthEvent, isLoadingView]
  );

  const onEventClose = useCallback(() => {
    setEventOpen(false);
  }, [isEventOpen]);


  useEffect(() => {
    if(!isFetchingPlanning){
      setPlanningTask(availability);
    }
  }, [isFetchingPlanning]);

  useEffect(() => {
    let defualtHighlight = general_settings?.default_monthyearhours_highlight;
    if (
      defualtHighlight &&
      todo_status_serviceCall
    ) {
      let serviceCallStatusLabel = todo_status_serviceCall.find(
        (servicallStatus) => {
          return (
            parseInt(servicallStatus.id) ===
            parseInt(defualtHighlight)
          );
        }
      );
      if (serviceCallStatusLabel?.length !== 0) {
        setDefaultMonthYearHighlight(serviceCallStatusLabel?.name);
      }
    }
  }, [general_settings, todo_status_serviceCall]);

  const renderViewSettingsTooltip = (
    <Popover id="render-hours-tooltip">
      <div className="p-5 bg-white card">
        <div>Edit your view for a more compact or expanded view.
          <a href="https://knowledgebase.proxuma.io/article/planning-screen-view-settings" className="text-decoration-underline ms-2" target="_blank">
              Learn more
            </a> 
          </div>
        </div> 
    </Popover>
  )



  return (
    <>
      <div className={`planing_main overflow-hidden h-100 ${isEventOpen === true ||isEventLoading === true ? 'noScroll' : ''} ${todoclassName} ${viewclassName}`}>
        <NotificationContainer />
        <div className="filTop d-flex align-items-center justify-content-end bg-white px-5 border-bottom grayBorder">
          <div className="filterLeft d-flex align-items-center flex-wrap flex-1 row-gap-5">
            <FilterMain 
              filterArray={filterArray} 
              view={view} 
              openDropdown={openDropdown} 
              setOpenDropdown = {openDropdown}
              toggleDropdown={toggleDropdown} 
            />
          </div>
          <div
                className="cursor-pointer d-flex align-items-center me-1 fs-16 view-settings-button"
                id="kt_todo_plan_close"
                onClick={showviewcomponent}
              >
                <KTSVG
                  path="/media/icons/duotune/new-icons/settings.svg"
                  className="me-1"
                  stylecode={{ color: `${Viewbtncolor}` }}
                  svgClassName="w-auto h-auto"
                />
                <span>Settings</span>
              </div>          
              <div className="h-32px d-flex align-items-center">
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={renderViewSettingsTooltip}
                >
                  <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn me-5 ms-1">i</button>
                </OverlayTrigger>
              </div>       

          <div className="filterBtns">
            <div className="d-flex align-items-center">              
              <button
                style={{ backgroundColor: sortActive }}
                className="btn btn-primary d-inline-flex align-items-center p-0 px-5 h-32px rounded-4px me-5 fs-16"
                type="button"
                data-kt-menu-trigger="click"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="bottom"
              >
              <KTSVG
                path="/media/icons/duotune/new-icons/plus.svg"
                className="me-2"
                svgClassName="w-auto h-auto"
              />
              <small className="fs-100">New</small>
              </button>
              <div
                className="menu menu-sub menu-sub-dropdown bg-white px-3 py-5 w-125px fs-13"
                data-kt-menu="true"
              >
                <a
                  className="fs-16 d-flex align-items-center text-black fw-semibold text-hover-primary mb-3"
                  data-name="people_asc"
                  href="javascript:void(0)"
                  id="task"
                  onClick={createTaskTicket}
                >
                  Create Task
                </a>
                <a
                  className="fs-16 d-flex align-items-center text-black fw-semibold text-hover-primary"
                  data-name="people_desc"
                  href="javascript:void(0)"
                  id="ticket"
                  onClick={createTaskTicket}
                >
                  Create Ticket
                </a>
              </div> 
            </div>
          </div>
                 
          {/* } */}
        </div>
        <div
          className={`planningScreen pScreenmain m-0 ${
            view === "month"
              ? "monthView"
              : "" || view === "year"
              ? "yearView"
              : "" || view === "day"
              ? "dayView"
              : ""
          }`}
        >
          {/* Calender */}
          <div className="mbsc-grid mbsc-no-padding p-0">
            <div className="mbsc-row m-0" id="planningPage">
              <div className="mbsc-col-sm-12 dynamically-color-and-invalidate-calendar">
                <div
                  className="mbsc-timeline-grid-scroll mbsc-flex-col
                                    mbsc-flex-1-1 mbsc-ios mbsc-ltr"
                >
                  {resource_data?.length !== 0 || resource_data?.length > 0 ? (
                    <>
                      <Eventcalendar
                        showEventTooltip={false}
                        theme="ios"
                        themeVariant="light"
                        dataTimezone="utc"
                        defaultSelectedDate={currentDate}
                        selectedDate={currentDate}
                        displayTimezone={
                          view === "month" || view === "year"
                            ? "utc"
                            : timezone
                        }
                        timezonePlugin={momentTimezone}
                        view={calView}
                        data={
                          view === "month"
                            ? monthViewData
                            : view === "year"
                              ? yearViewData
                              : planningTask
                        }
                        resources={resource_data}
                        externalDrop={true}
                        dragToResize={
                          view === "day" || view === "week" ? true : false
                        }
                        dragToMove={
                          view === "day" || view === "week" ? true : false
                        }
                        onEventCreate={onEventCreate}
                        onEventUpdate={onEventUpdate}
                        onEventDragStart={onEventDragStart}
                        renderResource={(resource: any) => (
                          <RenderMyResource resource={resource} />
                        )}
                        renderScheduleEvent={(data: any) => (
                          <MyScheduleEvent
                            data={data}
                            view={view}
                            isHoverColor={isHoverColor}
                            availability={availability}
                            isFetchingPlanning={isFetchingPlanning}
                            isDragOnHover={hoveredEventId === data.id}
                            onEventClick={onEventClick}
                            general_settings={general_settings}
                            onLinkClick={onLinkClick}
                            defaultMonthYearHighlight={
                              defaultMonthYearHighlight
                            }
                            todo_status_serviceCall={todo_status_serviceCall}
                            resource_data = {resource_data}
                            timezone={timezone}
                            viewSettings={viewSettingsData}
                            locationShow ={locationShow}
                            locations ={locations}
                            prioritylist={prioritylist}
                            task_status={task_status}
                          />
                        )}
                        renderHeader={() => (
                          <CustomWithNavButtons
                            buttonText={buttonText}
                            startDate={startDate}
                            endDate={endDate}
                            setCurrentDate={setCurrentDate}
                            setRefDate={setRefDate}
                            getNrDays={getNrDays}
                            setView={setView}
                            setCalView={setCalView}
                            extendedMorningStart={extendedMorningStart}
                            extendedEveningEnd={extendedEveningEnd}
                            setRangeVal={setRangeVal}
                            currentDate={currentDate}
                            sortTooltip={sortTooltip}
                            sortActive={sortActive}
                            sortIcon={sortIcon}
                            sortactivetextcolor={sortactivetextcolor}
                            sortData={sortData}
                            setPlanningcapacityview={setPlanningcapacityview}
                            view={view}
                            handleclick={handleclick}
                            activetextcolor={activetextcolor}
                            toplanActive={toplanActive}
                            showtodo_onclick={showtodo_onclick}
                            toplanactivetextcolor={toplanactivetextcolor}
                            Viewbtncolor = {Viewbtncolor}
                            viewBtnbackground = {viewBtnbackground}
                            pagination={pagination}
                            offlineCount={offlineCount}
                            timezone={timezone}
                            time_format={time_format}
                            rangeVal={rangeVal}
                            activebtn={activebtn}
                            showviewcomponent={showviewcomponent}
                            viewSettingsData={viewSettingsData}
                            locationShow ={locationShow}
                            layoutAndFiltersData={layoutAndFiltersData}
                            selectActiveLayout={selectActiveLayout}
                            refetchLayouts={refetchLayouts}
                            filterIds={filterIds}
                            todopayload={todopayload}
                            toggleDropdown={toggleDropdown}
                            openDropdown ={openDropdown}
                            changeView={changeView}
                          />
                        )}
                        onPageLoading={onPageLoading}
                        cssClass="md-event-listing"
                        onEventClick={EventList} 
                        onEventUpdated={onEventUpdated}
                        colors={myInvalid}
                        onPageChange={handleHeaderChange}
                        refDate={refDate}
                        renderDay={renderCustomDay}
                        timeFormat={
                          time_format === "HH:mm" ? time_format : "hh:mm a"
                        }
                        todayText="To Today"
                      />
                    </>
                  ) : (
                    <>
                      <span
                        style={{
                          fontWeight: "500",
                          textAlign: "center",
                          marginTop: "22%",
                          left: "50px",
                          color: "red",
                          fontSize: "18px",
                        }}
                      >
                        There are no items to display.
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* End::Calendar */}
        </div>  
        {/* For display planning tooltip */}
        {istooltipOpen && (
          <ServicecallUpdate
            planningTask={planningTask}
            setPlanningTask={setPlanningTask}
            istooltipOpen={istooltipOpen}
            settooltipOpen={settooltipOpen}
            isEventDetailPopup={isEventDetailPopup}
            eventDetail={eventDetail}
            screenName="planning"
            general_settings={general_settings}
            timezone={timezone}
            seteventDetail={seteventDetail}
            splitEventsFn={splitEventsFn}
            splitEventData={splitEventData}
            tempEvent={tempEvent}
            settempEvent={settempEvent}
            onEventClick={onEventClick}
            tempArgsEvent={tempArgsEvent}
            settempArgsEvent={settempArgsEvent}
            onEventUpdate={onEventUpdate}
            setistaskupdated={setistaskupdated}
            istaskupdated={istaskupdated}
            onClose={onClose}
            issueslist={issueslist}
            subIssueslist={subIssueslist}
            worktype={worktype}
            prioritylist={prioritylist}
            time_format={time_format}
            todo_status_serviceCall={todo_status_serviceCall}
            resource_settings={resource_settings}
            task_status={task_status}
            ticket_status={ticket_status}
            refetchMonthdata={refetchMonthdata}
            toplanrefetch={toplanrefetch}
            view={view}
            monthEvent={monthEvent}
            setMonthEvent={setMonthEvent}
            setexistServicecall={setexistServicecall}
            existServicecall={existServicecall}
            refetchPlanning={refetchPlanning}
            filterArray={filterArray} 
            openDropdown={openDropdown} 
            setOpenDropdown = {openDropdown}
            toggleDropdown={toggleDropdown}
            locationShow = {locationShow}
            locations ={locations}
            filterIds={filterIds}
            getNrDays={getNrDays}
          />
        )}

        {/* for events in month/year view */}
        {isEventOpen && (
          <Popup
            display="anchored"
            isOpen={isEventOpen}
            anchor={anchor}
            touchUi={false}
            contentPadding={false}
            closeOnOverlayClick={true}
            width={450}            
            scrollLock={false}
            cssClass="md-tooltip"
            onClose={onEventClose}
          >
            {isEventLoading ? (
                <div className="text-center p-5 fs-16">
                  <span>Loading...</span>
                </div>
              ) : monthEvent?.length > 0 ? (
                <div className="mwviewTooltip">
                  <div className="md-tooltip-header d-none">
                    <span className="md-tooltip-name-age">All Events</span>
                    <span className="md-tooltip-time"></span>
                  </div>
                    {monthEvent?.map((ev: any, keytask) => {
                      ev.status = todo_status_serviceCall?.filter((status: any) => parseInt(status.id) === parseInt(ev?.servicecall_status))?.[0]?.name
                      ev.billing_code_label = worktype?.filter((w: any) => parseInt(w.value) === parseInt(ev?.billing_code_id))?.[0]?.label
                      return (
                        <>
                          <div
                            className={`md-tooltip-info ${ev?.editable === false ? 'offline' : ''}`}
                          >
                            <div className="md-tooltip-header d-flex justify-content-between px-4 position-relative py-2 align-items-center fs-16">
                              <div className="d-flex align-items-center text-ellipsis">
                                {(ev.status === "Complete" ||
                                  ev.status === "complete") && (
                                    <span
                                      className="text-success me-2 fa fa-check bg-white rounded-circle d-inline-flex align-items-center justify-content-center customCheck"
                                      style={{
                                        color: "green !important",
                                        fontWeight: "bolder",
                                        fontSize: "16px",
                                      }}
                                    ></span>
                                  )}
                                <span className="text-ellipsis fw-bold">
                                  {ev?.title}
                                </span>
                                <KTSVG
                                  path="/media/icons/duotune/new-icons/right-arrow-new.svg"
                                  className="m-0 ms-2 d-none"
                                  svgClassName="w-auto h-auto"
                                  stylecode={{ color: "inherit" }}
                                />
                              </div>
  
                              <div className="d-flex align-items-center fw-bold ps-2 text-nowrap">
                                {ev.status}
                              </div>
                            </div>
  
                            <div className="px-4 fs-14 d-flex align-items-center justify-content-between">
                              <span className="fw-bold pe-1">
                                {" "}
                                {ev.company_name}/{ev.project_name}
                              </span>
                              <span className="fw-bold text-end ps-1">
                                {" "}
                                {ev.billing_code_label}
                              </span>
                            </div>
                            
                            <div className={`d-flex justify-content-between px-4`}>
                              <div className="d-flex align-items-center fs-14">
                                <div className="d-flex align-items-center">
                                  <strong className="fw-bold">Start:&nbsp;</strong>
                                  <span className="text-primary fw-bold">{ev?.start ? moment(ev?.start).tz(timezone).format("DD-MM-YYYY "+time_format) : "-"}</span>
                                </div>        
                                <div className="d-flex align-items-center ms-4">
                                  <strong className="fw-bold">End:&nbsp;</strong>
                                  <span className="text-primary fw-bold">{ev?.end ? moment(ev?.end).tz(timezone).format("DD-MM-YYYY "+time_format) : "-"}</span>
                                </div>                                      
                              </div>
                              <Button
                                color="secondary"
                                className={`md-tooltip-view-button w-30px h-30px text-primary fw-bold fs-16 bg-white border border-primary}`}
                                data-color="primary"
                                data-variant="outline"
                                onClick={(e: any) => {onEventClick(ev?.rowResourceId, ev);}}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/new-icons/right-arrow-new.svg"
                                  className="text-primary mt-n1"
                                  svgClassName="w-6px h-auto"
                                />
                              </Button>
                            </div>
                          </div>
                      </>
                    );
                  })}
                </div>
              ) : (
                <div className="text-center p-5 fs-16">
                  <span>No events found</span>
                </div>
              )
            }
          </Popup>
        )}
        {/* end for events in month/year view */}

        {/** for create task/ticket */}
        <TaskCreate
          isOpen={isCreateTaskOpen}
          setIsCreateTaskOpen={setIsCreateTaskOpen}
          time_format={time_format}
          timezone={timezone}
          projectList={filterArray?.projects}
          general_settings={general_settings}
          resource_settings={resource_settings}
          issueslist={issueslist}
          subIssueslist={subIssueslist}
          worktype={worktype}
          phase={phase}
          filterArray={filterArray}
          prioritylist={prioritylist}
          setIsPopupOpen={setIsPopupOpen}
          createtype={createTask?.type}
          toplanrefetch={toplanrefetch}
          screenName="planning"
          locationShow = {locationShow}
          locations ={locations}
        />
        
      </div>
    </>
  );
};
export default PlanningView;