
import { BaseComponent, IBaseProps } from '../../base';
import { MbscPopupDisplay } from '../../components/popup/popup.types.public';
import { IWheelDataItem, MbscScrollerWheel } from '../../components/scroller/scroller.types';
import { cssPrefix } from '../../util/dom';
import { DOWN_ARROW, ENTER, UP_ARROW } from '../../util/keys';
import { isArray, round, UNDEFINED } from '../../util/misc';

// tslint:disable no-non-null-assertion
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

/** @hidden */
export interface IWheelIndexChangeArgs {
  click?: boolean;
  diff: number;
  index: number;
  wheel: MbscScrollerWheel;
  selected?: boolean;
}

export interface IActiveChangeArgs {
  index: number;
  wheel: MbscScrollerWheel;
}

/** @hidden */
export interface IWheelProps extends IBaseProps {
  activeIndex: number;
  disabled?: Map<any, boolean>;
  display?: MbscPopupDisplay;
  itemHeight: number;
  itemTemplate?: any;
  key: number;
  renderItem?: any;
  rows: number;
  scroll3d: boolean;
  selectedIndex: number;
  selectedValues?: any[];
  selectOnScroll?: boolean;
  wheel: MbscScrollerWheel;
  maxIndex: number;
  maxWheelWidth?: number | number[];
  minIndex: number;
  minWheelWidth?: number | number[];
  multiple: boolean;
  wheelWidth?: number | number[];
  onActiveChange(args: any): void;
  onIndexChange(args: any): void;
  onSet(): void;
}

/** @hidden */

export class WheelBase extends BaseComponent<IWheelProps, any> {
  // tslint:disable variable-name
  public _angle3d!: number;
  public _batchSize3d?: number;
  public _style: any;
  public _innerStyle: any;
  public _items?: IWheelDataItem[] | ((index: number) => any);
  public _itemNr?: number;
  public _wheelStyle: any;

  private _shouldFocus?: boolean;
  // tslint:enable variable-name

  // tslint:disable-next-line: variable-name
  public _onIndexChange = (args: any) => {
    args.wheel = this.s.wheel;
    this._hook('onIndexChange', args);
  };

  // tslint:disable-next-line: variable-name
  public _onItemClick = (args: any) => {
    this._hook('onIndexChange', { click: true, index: args.index, wheel: this.s.wheel, selected: args.selected });
  };

  // tslint:disable-next-line: variable-name
  public _onKeyDown = (ev: any) => {
    let dir = 0;
    if (ev.keyCode === UP_ARROW) {
      dir = -1;
    } else if (ev.keyCode === DOWN_ARROW) {
      dir = 1;
    }

    const s = this.s;
    const newIndex = s.activeIndex + dir;
    // Only trigger the hooks if there's a change in the index.
    // Compare using the ! operator, because min and max indexes might be undefined
    const change = !(newIndex < s.minIndex) && !(newIndex > s.maxIndex);

    if (dir) {
      // Prevent content scroll on arrow keys
      ev.preventDefault();
    }

    if (dir && change) {
      const hook = s.selectOnScroll ? 'onIndexChange' : 'onActiveChange';
      this._shouldFocus = true;
      this._hook(hook, { diff: dir, index: newIndex, wheel: s.wheel });
    } else if (ev.keyCode === ENTER && s.multiple) {
      this._hook('onSet', {});
    }
  };

  public _getText(data: any): string {
    return data !== UNDEFINED ? (data.display !== UNDEFINED ? data.display : data) : UNDEFINED;
  }

  public _getValue(data: any): any {
    return data ? (data.value !== UNDEFINED ? data.value : data.display !== UNDEFINED ? data.display : data) : data;
  }

  public _isActive(item: any, text: any, is3d: boolean): boolean {
    const s = this.s;
    const d3 = s.scroll3d && s.multiple ? is3d : !is3d;
    return s.activeIndex === item.key && text && d3;
  }

  public _isSelected(item: any): boolean {
    const s = this.s;
    const selectedValues = s.selectedValues;
    const value = this._getValue(item.data);

    return s.multiple
      ? !!(selectedValues && selectedValues.indexOf) && selectedValues.indexOf(value) >= 0
      : s.selectOnScroll
      ? item.key === s.selectedIndex
      : value !== UNDEFINED && value === selectedValues;
  }

  public _isDisabled(data: any): boolean {
    const disabledMap = this.s.disabled;
    const disabledProp = data && data.disabled;
    const value = this._getValue(data);
    return !!(disabledProp || (disabledMap && disabledMap.get(value)));
  }

  protected _render(s: IWheelProps) {
    const rows = s.rows;
    const itemHeight = s.itemHeight;
    const key = s.wheel._key!;
    const itemHeight3d = round((itemHeight - ((itemHeight * rows) / 2 + 3) * 0.03) / 2) * 2;

    this._items = s.wheel.getItem || s.wheel.data || [];

    this._batchSize3d = round(rows * 1.8);
    this._angle3d = 360 / (this._batchSize3d * 2);

    this._style = {
      height: round((rows * itemHeight * (s.scroll3d ? 1.1 : 1)) / 2) * 2 + 'px',
    };

    this._itemNr = s.wheel.spaceAround ? 1 : rows;
    this._innerStyle = {
      height: (s.scroll3d ? itemHeight3d : s.wheel.spaceAround ? itemHeight : itemHeight * rows) + 'px',
    };

    this._wheelStyle = s.wheelWidth
      ? {
          width: (isArray(s.wheelWidth) ? s.wheelWidth[key] : s.wheelWidth) + 'px',
        }
      : {
          maxWidth: (isArray(s.maxWheelWidth) ? s.maxWheelWidth![key] : s.maxWheelWidth) + 'px',
          minWidth: (isArray(s.minWheelWidth) ? s.minWheelWidth![key] : s.minWheelWidth) + 'px',
        };

    if (s.scroll3d) {
      this._innerStyle[cssPrefix + 'transform'] = 'translateY(-50%) translateZ(' + ((itemHeight * rows) / 2 + 3) + 'px';
    }
  }

  protected _updated() {
    if (this._shouldFocus) {
      const item = this._el.querySelector('[tabindex="0"]') as HTMLElement;
      if (item) {
        setTimeout(() => {
          item.focus();
        });
      }
      this._shouldFocus = false;
    }
  }
}
