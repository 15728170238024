import { alert } from "@mobiscroll/react";
import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { KTSVG } from "../../../../../_metronic/helpers";
import { savedViewSettings } from "../../../../../_metronic/requests/ViewSettingsRequest";
import { useAppDispatch } from "../../../../hook";
import { RootState } from "../../../../store";
import { setViewSetting } from "../../../../viewSettingsSlice";
import { Loading } from "../../../../components/loading/Loading";

interface IPlanBucketSettings {
  show: boolean;
  handleViewSetting: () => void;
  general_settings :any;
}

const PlanBucketSettings: FC<IPlanBucketSettings> = ({
  handleViewSetting,
  general_settings,
  show,
}) => {
  const currentURL = window.location.href;
  const regex = /\/apps\/([^/]+)\//;
  const match = currentURL.match(regex);
  const [isLoading, setIsLoading] = useState(false);
  const currentPath = match[1] === "planning";
  const [isData, setIsData] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState<any>({});
  const ViewsettingFunction = useSelector((state: RootState) => state?.viewSettings);
  const defaultSelectedPlanBucketFields = [
    "title",
    "status",
    "type",
    "primary_secondary_resource",
  ];

  const dispatch = useAppDispatch();
  const isLocation = general_settings?.isLocation ? general_settings?.isLocation : false;
    const filteredData = ViewsettingFunction.PlanBucketData.filter(item => {
      if (!isLocation) {
          return item.id !== 'location';
      } else {
          return true;
      }
  });

  useEffect(() => {
    if (ViewsettingFunction?.PlanBucketData) {
      const initialValues = filteredData.reduce(
        (acc, item) => {
          acc[item.id] = item.value;
          return acc;
        },
        {}
      );
      setInitialFormValues(initialValues);
    }
  }, [ViewsettingFunction?.PlanBucketData]);

  const handleDataSave = async (values: any, updatedValues: any) => {
    const data = {
      type: "plan_bucket",
      planBucketData: updatedValues,
    };
    let storedViewSettingsData = localStorage.getItem('viewSettingsData');
    const parsedData = JSON.parse(storedViewSettingsData);
    let dispatchViewSettingData:any = {
      PlannigViewData: parsedData?.PlannigViewData,
      ProjectViewData: parsedData?.ProjectViewData,
      PlanBucketData: parsedData?.PlanBucketData,
    };
    if (currentPath && (ViewsettingFunction?.PlannigViewData?.planbucket_from_filter === false || ViewsettingFunction?.PlannigViewData.hasOwnProperty('planbucket_from_filter')=== false)) {
      const res = await savedViewSettings(data);
      if (res?.data?.success) {
        dispatchViewSettingData.PlanBucketData = res?.data?.data?.PlanBucketData;
      }
    } else {
      dispatchViewSettingData.PlanBucketData = data?.planBucketData;
    }
    localStorage.setItem("viewSettingsData", JSON.stringify(dispatchViewSettingData));
    dispatch(setViewSetting(dispatchViewSettingData));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialFormValues,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setIsData(true);
      try {
        const updatedPlanBucketData = ViewsettingFunction.PlanBucketData.map((item) => ({
          ...item,
          value: values[item.id] !== undefined ? values[item.id] : item.value,
        }));

        await handleDataSave(values, updatedPlanBucketData);
      } catch (error: any) {
        setIsData(false);
        console.log(error);
      } finally {
        setIsData(false);
        alert({
          message: "Changes saved successfully!",
        });
        setSubmitting(false);
      }
    },
  });

  const handleFormReset = async () => {
    setIsLoading(true);
    try {
      const resetValues = ViewsettingFunction.PlanBucketData?.reduce(
        (acc, item) => {
          acc[item.id] = defaultSelectedPlanBucketFields.includes(item.id);
          return acc;
        },
        {}
      );
      formik.resetForm({ values: resetValues });
  
      // Update PlanBucketData to reflect reset values
      const updatedPlanBucketData =ViewsettingFunction.PlanBucketData?.map((item) => ({
        ...item,
        value: resetValues[item.id],
      }));
  
      await handleDataSave(resetValues, updatedPlanBucketData);
    } finally {
      setIsLoading(false);
      alert({
        message: "Reset successfully!",
      });
    }
  };
    const handleCheckboxChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    formik.setFieldValue(name, checked);
  };

  return (
    <div
      className={`pt-0 overflow-auto mh-100 position-absolute start-0 top-0 end-0 h-100 flex-1 bg-white ${
        show ? "" : "d-none"
      }`}
      style={{ zIndex: "9" }}
    >
      <div className="d-flex flex-column bg-white h-100" style={{ borderColor: "#DADCE0" }}>
        <div className="border-bottom d-flex align-items-center justify-content-between w-100 grayBorder min-h-65px paddLR24">
          <h3 className="text-dark fw-bold fs-20px m-0 d-inline-flex align-items-center">
            View Settings{" "}
          </h3>
          <div className="cursor-pointer" id="kt_todo_plan_close" onClick={handleViewSetting}>
            <KTSVG
              path="/media/icons/duotune/new-icons/cross-circle-dark.svg"
              className="w-24px h-24px d-inline-flex"
              svgClassName="w-100 h-100"
              stylecode={{ color: "#181833" }}
            />
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="form card-body border-top p-0 overflow-auto mh-100 d-flex flex-column"
        >
          <div className="overflow-auto py-5 paddLR24">
            <h3 className="text-dark fw-bold fs-18 m-0 mb-4">Row selection</h3>
            <div className="bg-gray-100 rounded-8px p-5 pb-1 form-check-outline-primary-all">
              <div className="row">
                {filteredData?.map((item, index) => (
                  <div key={item.id} className={`col col-md-5 ${index % 2 === 0 ? "col-md-5" : "col-md-5"}`}>
                    <div className="form-check form-check-custom form-check-solid h-24px fs-16 mb-4">
                      <input
                        className="form-check-input me-2"
                        name={item.id}
                        type="checkbox"
                        disabled={item.id === "title" || item.id === "type"}
                        checked={formik.values[item.id]}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor={item.id}>{item.label}</label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="align-items-center justify-content-center d-flex py-10 pt-5 paddLR24 mt-auto" style={{ columnGap: "16px" }}>
            <button
              type="reset"
              onClick={handleFormReset}
              className="btn bg-gray-100 border border-primary text-primary h-55px py-0 px-5 rounded-8px fs-20px fw-bold flex-1"
              data-kt-role-modal-action="close"
              disabled={isLoading}
            >
              {!isLoading && "Reset setting"}
              {isLoading && <Loading isLoading={isLoading} text={"Please wait..."} />}
            </button>
            <button
              type="submit"
              className="btn btn-primary h-55px py-0 px-5 rounded-8px fs-20px fw-bold flex-1"
              disabled={isData}
            >
              Save changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PlanBucketSettings;
