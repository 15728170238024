import React, { FC, useEffect } from "react";
import { useLocation } from "react-router";
import clsx from "clsx";
import { useLayout } from "../core";
import { DrawerComponent } from "../../assets/ts/components";
import { WithChildren } from "../../helpers";
import { useAuth } from "../../../app/modules/auth/core/AuthCognito";
import CompanyInfoPopup from "../../../../src/app/components/PopupMessage/CompanyInfoPopup";
import CreditCardMain from "./CreditCardMain";



const Content: FC<WithChildren> = ({ children }) => {
  const { classes } = useLayout();
  const location = useLocation();
  const { currentUser } = useAuth();
  
  
  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  return (
    <div
      id="kt_content_container"      
      className={clsx(classes.contentContainer.join(" "), "container-fluid position-relative px-0 overflow-auto")}
    >
      {children}  
      {(!(currentUser?.data?.user?.role_label === 'company_admin' && currentUser?.data?.user?.iscreditExist && !currentUser?.data?.user?.isclientIdExist) && !(currentUser?.data?.user?.role_label === 'company_admin' && !currentUser?.data?.user?.iscreditExist)) && 
        <CompanyInfoPopup />
      } 

      {(((currentUser?.data?.user?.role_label === 'company_admin' && currentUser?.data?.user?.iscreditExist && !currentUser?.data?.user?.isclientIdExist) || (currentUser?.data?.user?.role_label === 'company_admin' && !currentUser?.data?.user?.iscreditExist))) && 
        <CreditCardMain />
      } 
      
    </div>
  );
};

export { Content };
