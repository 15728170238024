import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Auth } from './auth';

type Props = {
    data: any;

};
export default function CognitoPage() {

    const [cognitoData, setCognitoData] = useState<any>('')


    useEffect(() => {
        const search = window.location.search
        const params = new URLSearchParams(search);
        const url = decodeURIComponent(params.get('continueurl')) || decodeURIComponent(localStorage.getItem('continueurl'))
        const sudomainUrl = url.split('.')
        let remove = sudomainUrl.shift()
        let subdomain = remove.replace(/http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g, '')
        const user = async () => {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}outlook/organization/${subdomain}`);
            setCognitoData(data);
        }
        if (params.has('continueurl')) {
            user();
        }
    }, [])

    return (
        cognitoData && (<Auth cognitoData={cognitoData} />)
    );

}
