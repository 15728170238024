import axios from "axios";
import { AuthModel, UserModel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}auth/me`;
export const GET_SUPERADMINUSER_BY_ACCESSTOKEN_URL = `${API_URL}superadmin/me`;
export const LOGIN_URL = `${API_URL}auth/signin`;
export const MFT_LOGIN_URL = `${API_URL}auth/signinmft`;
export const MFT_LOGIN_URL_UPDATE_STATUS = `${API_URL}auth/updateLoggedInStatus`;
export const SUPERADMIN_LOGIN_URL = `${API_URL}superadmin/signin`;
export const REGISTER_URL = `${API_URL}/register`;
export const COGNITO_REGISTER_URL = `${API_URL}auth/cognito-register`;
export const REQUEST_PASSWORD_URL = `${API_URL}auth/forgot-password`;
export const REQUEST_SUPERADMIN_PASSWORD_URL = `${API_URL}superadmin/forgot-password`;
export const RESET_PASSWORD_URL = `${API_URL}users/reset-password`;
export const SETUP_PASSWORD_URL = `${API_URL}users/setup-password`;
export const SET_LICENSE_KEY = `${API_URL}auth/set-license-email`;

// Server should return AuthModel
export async function login(username: any, password: any, type: any,subdomainURL:any) {
  let url = type === "superadmin" ? SUPERADMIN_LOGIN_URL : LOGIN_URL;
  
  return axios.post<AuthModel>(url, {
    username,
    password,
    type,
    subdomainURL
  });
}

export async function mftLogin(email:any,subdomain:any){
  let url = MFT_LOGIN_URL;
  
  return axios.post<AuthModel>(url,{
    email,
    subdomain
  });
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

export function cognitoUser(
  userDetails: any
) {
  return axios.post(COGNITO_REGISTER_URL, {
    username: userDetails.username,
    sub: userDetails.userId,
    email: userDetails.email,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(username: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    username,
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestSuperadminPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_SUPERADMIN_PASSWORD_URL, {
    email,
  });
}

export function resetPassword(data: any) {
  return axios.post(RESET_PASSWORD_URL, data);
}


export function setLicenseKey(database_id: string) {
  
  return axios.post(SET_LICENSE_KEY, {
    database_id,
  });
}

export function setupPassword(data: any) {
  return axios.post(SETUP_PASSWORD_URL, data);
}

export function getUserByToken(token: string) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {});
}

export function getCognitoUserByToken(token: any) {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);
}

export function getSuperadminUserByToken(token: string) {
  return axios.get<UserModel>(GET_SUPERADMINUSER_BY_ACCESSTOKEN_URL, {});
}

export async function anyWordIncludedInURL(wordsArray:any) {
  let url = window.location.href;
  const lowerCaseURL = url.toLowerCase();
  for (const word of wordsArray) {
      const lowerCaseWord = word.toLowerCase();
      if (lowerCaseURL.includes(lowerCaseWord)) {
          return true;
      }
  }
  return false;
};
