import { FC, useCallback, useEffect, useMemo, useState, useContext  } from "react";
import clsx from "clsx";
import {  useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive, KTSVG, WithChildren } from "../../../helpers";
import { useLayout } from "../../core";
import { useQuery } from "react-query";
import { getNotificationcount } from "../../../requests/NotificationRequest";
import { useAuth } from "../../../../app/modules/auth/core/AuthCognito";
import { confirm } from "@mobiscroll/react";
import { useAppDispatch, useAppSelector } from "../../../../app/hook";
import { setisSaved, setispageLoad } from "../../../../app/counterSlice";
import { webSocketContext } from "../../../../app/context/WebSocketContext";
type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {

  const {socketMessage}:any = useContext(webSocketContext)

  const { currentUser } = useAuth();
  const [countApiEnable, setcountApiEnable] = useState(true);
  const [lasttaskFromWebsocket, setlasttaskFromWebsocket] = useState("");
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;
  const navigate = useNavigate();
  const is_Changed = useAppSelector((state: any) => state.counter?.isSaved);
  const dispatch = useAppDispatch();
  const {
    data: notification } = useQuery(
    `notification-data`,
    async () => {
        return await getNotificationcount('');
    },
    {
        enabled : countApiEnable,
        cacheTime: 0,
        onError: (err) => {
            console.error(err);
        },
        onSuccess: (data:any) => {
          setcountApiEnable(false)
          if(data && pathname != "/apps/notifications/list"){
            localStorage.setItem('sucesscount',data?.data?.success);
            localStorage.setItem('failcount',data?.data?.failed);
          }
          else{
            localStorage.setItem('sucesscount','0');
            localStorage.setItem('failcount','0');
          } 
        }
    }
);

const handleWebSocketMessage = useCallback((event:any) => {
  const eData = JSON.parse(event.data);
  if (eData.organizationId === currentUser?.data?.user?.organizationId  && lasttaskFromWebsocket != eData?.title && (eData.userId === currentUser?.data?.user?._id || eData?.type === 'sync process') ) {
    setcountApiEnable(true)
  }
  setlasttaskFromWebsocket(eData?.title)
  setTimeout(() => {
    setlasttaskFromWebsocket('')
  }, 5000);
}, [socketMessage,lasttaskFromWebsocket]);

const SucessCount   = useMemo(()=>{
  return localStorage.getItem('sucesscount')

} , [localStorage.getItem('sucesscount')])


const FailCount = useMemo(()=>{
return localStorage.getItem('failcount')
} , [localStorage.getItem('failcount')])

 useEffect(() => {
  if (socketMessage) {
    handleWebSocketMessage(socketMessage)
  }
 },[socketMessage])
const handleClick = (event: any) => {
  if(to != '/apps/planning/resources'){
    dispatch(setispageLoad(true));
  }
  if(is_Changed == 'true' || is_Changed == true){
    event.preventDefault();
      confirm({
        title: 'Changes you made may not be saved. Are you sure you want to leave the page?',
        okText: 'Yes',
        cancelText: 'No',
        callback: function (result) {
            if(result){
              navigate(to);
              dispatch(setisSaved(false));
            }
        }
      });
  }
  else{
    navigate(to);
  }
}

  return (
    <div className="menu-item">
      <a
        className={clsx("menu-link without-sub text-hover-white bg-active-menuactive", { active: isActive })}
        onClick={handleClick}>
        {hasBullet && (
          <span className="menu-bullet">
            {to == '/apps/profile' ? '' :
              <span className="bullet bullet-dot"></span>
            }
          </span>
        )}
        {icon && aside.menuIcon === "svg" && (
          <span className="menu-icon">
            <KTSVG path={icon} className="" svgClassName="w-20px h-20px" />
          </span>
        )}
        {fontIcon && aside.menuIcon === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span className="menu-title">{title}

            <span className="d-none"></span>
            
          <span className={`badge rounded-pill badge-primary bg-yellow text-dark fs-16 px-3 top-0 ms-2 min-h-24px ${(title == "Notifications" && notification && SucessCount != '0') ? '' : 'd-none'}`}>
            {SucessCount}
          </span>
          <span className={`badge rounded-pill badge-danger bg-red text-dark fs-16 px-3 top-0 ms-2 min-h-24px ${(title == "Notifications" && notification && FailCount != '0') ? '' : 'd-none'}`}>
            {FailCount}
          </span>
            
        </span>

        {/* </Link> */}
      </a>
      {children}
    </div>
  );
};

export { AsideMenuItem };
