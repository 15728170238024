import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ImageRender = (params) => {
    const { value, node } = params;
    let rowData = node ? node.data : null;
    let type = rowData ? rowData.type : null;
    return (

        ((value && value.length > 0) ? (<div className="d-flex align-items-center h-100" >
            <OverlayTrigger
                key="copy-to-clipboard"
                placement="auto"
                //trigger="click"
                //defaultShow= {true}   //To set tooltip by default open
                overlay={
                    <Tooltip
                        id="tooltip-copy-to-clipboard"
                        className="position-absolute assiresTooltip"
                    >
                        <div
                            className="md-tooltip-info align-left"
                            style={{ textAlign: "left", padding: "4px" }}
                        >
                            <div className={`fw-bold md-rect-bg-task pb-4`}>
                                Assigned Resources
                            </div>
                            {value?.length > 0 &&
                                Array.from(value)?.map(
                                    (resource1: any, key: any) => {
                                        return (
                                            <>
                                                <div className="d-flex align-items-center md-tooltip-title m-0 mb-3">
                                                    <strong className="fw-bold d-inline-flex align-items-center pe-3">
                                                        <span
                                                            className="rounded-circle bg-graydarkBg w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 fw-bold lh-sm"
                                                            style={{
                                                                textTransform: "uppercase",
                                                            }}
                                                        >
                                                            {resource1?.firstName
                                                                ?.trim()
                                                                ?.substring(0, 1)}
                                                            {resource1?.lastName
                                                                ?.trim()
                                                                ?.substring(0, 1)}
                                                        </span>
                                                    </strong>{" "}
                                                    <span className="md-tooltip-reason md-tooltip-text">
                                                        {resource1?.firstName}{" "}
                                                        {resource1?.lastName}
                                                    </span>
                                                </div>
                                            </>
                                        );
                                    }
                                )}
                        </div>
                    </Tooltip>
                }
            >
                <div className="rounded-pill h-24px text-nowrap">
                    {value?.length > 0 &&
                        Array.from(value)?.map(
                            (resource: any, key: any) => {
                                return (
                                    <>
                                        {key < 2 && resource?.firstName && (
                                            <span
                                                style={{
                                                    marginLeft: `${key > 0 ? "-4px" : "0"}`,
                                                    textTransform: "uppercase",
                                                }}
                                                className="rounded-circle bg-graydarkBg w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 align-top" >

                                                {resource?.firstName
                                                    ?.trim()
                                                    ?.substring(0, 1)}
                                                {resource?.lastName
                                                    ?.trim()
                                                    ?.substring(0, 1)}
                                            </span>
                                            // </span>
                                        )}
                                    </>
                                );
                            }
                        )}
                    {value?.length > 2 && (
                        <span
                            className="rounded-circle bg-graydarkBg w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 fw-bold lh-sm align-top"
                            style={{
                                marginLeft: "-4px",
                            }}
                        >
                            +{value?.length - 2}
                        </span>
                    )}
                </div>
            </OverlayTrigger>
        </div > 
        ) : (type === "task") ? "-" : "") 
    );
}

export default ImageRender;
