import {useListView} from '../../core/ListViewProvider'
// import {EmailsListToolbar} from './EmailsListToolbar'
// import {EmailsListGrouping} from './EmailsListGrouping'
import { IntrojsGrouping } from './IntrojsGrouping'
import { IntrojsToolbar } from './IntrojsToolbar'

const IntrojsHeader = () => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6 px-11'>
      {/* <UsersListSearchComponent /> */}
      {/* begin::Card toolbar */}
      <div className='card-toolbar col-12 '>
        {/* begin::Group actions */}
        {selected.length > 0 ? <IntrojsGrouping /> : <IntrojsToolbar />}

        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {IntrojsHeader}