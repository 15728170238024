import CryptoJS from 'crypto-js';

export function generateEncryptionKey() {
  const keySize = 256; // Key size in bits (e.g., 128, 192, 256)
  const randomBytes = CryptoJS.lib.WordArray.random(keySize / 8);
  const encryptionKey = randomBytes.toString();
  return encryptionKey;
}

export function encryptData(data, key) {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  return encryptedData;
}

export function decryptData(encryptedData, key) {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, key);
  const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}