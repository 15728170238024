/** @jsxRuntime classic */
/** @jsx createElement */
import { CalendarViewBase } from './calendar-view';
import { ICalendarViewProps, ICalendarViewState } from './calendar-view.types';
import { Button } from '../../../react/components/button';
import { Popup } from '../../../react/components/popup';
import { createElement, enhance, Fragment, isVue, ON_ANIMATION_END, ON_KEY_DOWN } from '../../../react/renderer';
import { CalendarDay } from '../../../react/shared/calendar-day';
import { CalendarContext, CalendarNav, CalendarNext, CalendarPrev, CalendarToday } from '../../../react/shared/calendar-header';
import { MonthView } from '../../../react/shared/month-view';
import { Scrollview } from '../../../react/shared/scroll-view';
import { ARRAY3, ARRAY4, isString, noop, UNDEFINED } from '../../util/misc';
import { MONTH_VIEW, MULTI_YEAR_VIEW, PAGE_VIEW, YEAR_VIEW } from './calendar-view.util';
import { CalendarWeekDays } from './calendar-weekdays';

import '../../base.scss';
import './calendar-view.scss';

let update = 0;

export function template(s: ICalendarViewProps, state: ICalendarViewState, inst: CalendarViewBase, content: any): any {
  update++;
  const variableRow = inst._variableRow;
  const monthOrYearSelection = inst._view !== PAGE_VIEW;
  const animationEnd = { [ON_ANIMATION_END]: inst._onViewAnimationEnd };
  const keydown = { [ON_KEY_DOWN]: inst._onKeyDown };

  const renderMonthView = (timestamp: number, props: any) => {
    return (
      <MonthView
        {...props}
        activeDate={inst._active}
        amText={s.amText}
        calendarType={s.calendarType!}
        cellTextHeight={state.cellTextHeight}
        clickToCreate={s.clickToCreate}
        colors={inst._colors}
        dayNames={s.dayNames!}
        dayNamesShort={inst._dayNames}
        dataTimezone={s.dataTimezone}
        displayTimezone={s.displayTimezone}
        eventText={s.eventText!}
        events={s.eventMap}
        eventsText={s.eventsText!}
        exclusiveEndDates={s.exclusiveEndDates}
        firstDay={s.firstDay!}
        firstPageDay={timestamp}
        getDate={s.getDate!}
        getDay={s.getDay!}
        getMonth={s.getMonth!}
        getWeekNumber={s.getWeekNumber!}
        getYear={s.getYear!}
        hasMarks={!!inst._marked}
        hoverEnd={s.hoverEnd}
        hoverStart={s.hoverStart}
        isPicker={s.isPicker}
        invalid={inst._invalid}
        labels={inst._labelsLayout}
        labelHeight={state.labelHeight}
        marked={inst._marked}
        max={inst._maxDate as Date}
        min={inst._minDate as Date}
        monthNames={s.monthNames!}
        monthNamesShort={s.monthNamesShort!}
        onDayClick={inst._onDayClick}
        onDayDoubleClick={s.onDayDoubleClick}
        onDayRightClick={s.onDayRightClick}
        onDayHoverIn={inst._onDayHoverIn}
        onDayHoverOut={inst._onDayHoverOut}
        onLabelClick={inst._onLabelClick}
        onLabelDoubleClick={s.onLabelDoubleClick}
        onLabelRightClick={s.onLabelRightClick}
        onLabelHoverIn={s.onLabelHoverIn}
        onLabelHoverOut={s.onLabelHoverOut}
        onLabelDelete={s.onLabelDelete}
        pmText={s.pmText}
        rangeEnd={s.rangeEnd}
        rangeStart={s.rangeStart}
        resourcesMap={s.resourcesMap}
        rtl={s.rtl}
        selectedDates={s.selectedDates}
        selectedEventsMap={s.selectedEventsMap}
        showEventTooltip={s.showEventTooltip}
        showOuter={inst._showOuter}
        showWeekDays={!inst._showDaysTop}
        showWeekNumbers={s.showWeekNumbers}
        showSingleMark={!!s.marksMap}
        todayText={s.todayText!}
        theme={s.theme}
        timeFormat={s.timeFormat}
        timezonePlugin={s.timezonePlugin}
        valid={inst._valid}
        weeks={inst._weeks}
        weekText={s.weekText!}
        renderDay={s.renderDay}
        renderDayContent={s.renderDayContent}
        renderLabel={s.renderLabel}
        renderLabelContent={s.renderLabelContent}
        variableRow={inst._variableRow}
      />
    );
  };

  const renderMonth = (item: any, offset: number) => {
    const key = item.key;
    const isActive = key >= inst._pageIndex && key < inst._pageIndex + inst._pageNr && inst._view === PAGE_VIEW;
    const ownProps = {
      dragData: s.dragData,
      dragToCreate: s.dragToCreate,
      dragToMove: s.dragToMove,
      dragToResize: s.dragToResize,
      isActive,
      onLabelUpdateEnd: s.onLabelUpdateEnd,
      onLabelUpdateModeOff: s.onLabelUpdateModeOff,
      onLabelUpdateModeOn: s.onLabelUpdateModeOn,
      onLabelUpdateMove: s.onLabelUpdateMove,
      onLabelUpdateStart: s.onLabelUpdateStart,
    };
    return (
      <div
        className={'mbsc-calendar-slide' + (isActive ? ' mbsc-calendar-slide-active' : '') + inst._theme + inst._rtl}
        key={key}
        style={inst._getPageStyle(key, offset, inst._pageIndex, inst._pageNr)}
      >
        {renderMonthView(inst._getPageDay(key), ownProps)}
      </div>
    );
  };

  const renderNavMonth = (item: any, offset: number) => {
    const key = item.key;
    return (
      <div
        className={'mbsc-calendar-picker-slide mbsc-calendar-slide' + inst._theme + inst._rtl}
        key={key}
        style={inst._getPageStyle(key, offset, 1)}
      >
        <MonthView
          activeDate={inst._activeMonth}
          dataTimezone={s.dataTimezone}
          dayNames={s.dayNames!}
          dayNamesShort={s.dayNamesMin!}
          displayTimezone={s.displayTimezone}
          firstDay={s.firstDay!}
          firstPageDay={inst._getPageMonth(key)}
          getDate={s.getDate!}
          getDay={s.getDay!}
          getMonth={s.getMonth!}
          getYear={s.getYear!}
          isActive={key >= inst._monthIndex && key < inst._monthIndex + 1}
          max={inst._maxDate as Date}
          min={inst._minDate as Date}
          monthNames={s.monthNames!}
          monthNamesShort={s.monthNamesShort!}
          onDayClick={inst._onNavDayClick}
          rtl={s.rtl}
          selectedDates={s.selectedDates}
          showOuter={true}
          showWeekDays={!inst._showDaysTop}
          theme={s.theme}
          timezonePlugin={s.timezonePlugin}
          todayText={s.todayText!}
        />
      </div>
    );
  };

  const renderYears = (item: any, offset: number) => {
    const index = item.key;
    const first = inst._getPageYears(index);
    const selectedYear = s.getYear!(new Date(inst._active));
    const activeYear = s.getYear!(new Date(inst._activeMonth));
    return (
      <div
        aria-hidden={inst._yearsIndex === index ? UNDEFINED : 'true'}
        className={'mbsc-calendar-picker-slide mbsc-calendar-slide' + inst._theme + inst._rtl}
        key={index}
        style={inst._getPageStyle(index, offset, inst._yearsIndex)}
      >
        <div className="mbsc-calendar-table mbsc-flex-col">
          {ARRAY4.map((x: any, i: number) => (
            <div className="mbsc-calendar-row mbsc-flex mbsc-flex-1-0" key={i}>
              {ARRAY3.map((y: any, j: number) => {
                const year = first + i * 3 + j;
                const d = +s.getDate!(year, 0, 1);
                return (
                  <CalendarDay
                    active={year === activeYear}
                    date={d}
                    display={true}
                    selected={year === selectedYear}
                    disabled={year < inst._minYears || year > inst._maxYears}
                    rtl={s.rtl}
                    text={year + s.yearSuffix!}
                    theme={s.theme}
                    type="year"
                    onDayClick={inst._onYearClick}
                    key={year}
                  />
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderYear = (item: any, offset: number) => {
    const index = item.key;
    const year = inst._getPageYear(index);
    const active = new Date(inst._activeMonth);
    const activeYear = s.getYear!(active);
    const activeMonth = s.getMonth!(active);
    const selected = new Date(inst._active);
    const selectedYear = s.getYear!(selected);
    const selectedMonth = s.getMonth!(selected);
    return (
      <div
        aria-hidden={inst._yearIndex === index ? UNDEFINED : 'true'}
        className={'mbsc-calendar-picker-slide mbsc-calendar-slide' + inst._theme + inst._rtl}
        key={index}
        style={inst._getPageStyle(index, offset, inst._yearIndex)}
      >
        <div className="mbsc-calendar-table mbsc-flex-col">
          {ARRAY4.map((a: any, i: number) => (
            <div className="mbsc-calendar-row mbsc-flex mbsc-flex-1-0" key={i}>
              {ARRAY3.map((b: any, j: number) => {
                const d = s.getDate!(year, i * 3 + j, 1);
                const y = s.getYear!(d);
                const m = s.getMonth!(d);
                return (
                  <CalendarDay
                    active={y === activeYear && m === activeMonth}
                    date={+d}
                    display={true}
                    selected={y === selectedYear && m === selectedMonth}
                    disabled={d < inst._minYear || d >= inst._maxYear}
                    month={s.monthNames![m]}
                    rtl={s.rtl}
                    text={s.monthNamesShort![m]}
                    theme={s.theme}
                    type="month"
                    onDayClick={inst._onMonthClick}
                    key={+d}
                  />
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    let headerContent: any;
    let html: any;
    if (s.renderHeader) {
      headerContent = s.renderHeader();
      if (isString(headerContent)) {
        if (headerContent !== inst._headerHTML) {
          inst._headerHTML = headerContent;
          inst._shouldEnhanceHeader = true;
        }
        html = inst._safeHtml(headerContent);
      }
    } else {
      const isMultiPage = inst._pageNr > 1;
      headerContent = (
        <Fragment>
          <CalendarNav className="mbsc-flex mbsc-flex-1-1 mbsc-calendar-title-wrapper" />
          <CalendarPrev className={'mbsc-calendar-button-prev' + (isMultiPage ? ' mbsc-calendar-button-prev-multi' : '')} />
          {s.showToday && <CalendarToday className="mbsc-calendar-header-today" />}
          <CalendarNext className={'mbsc-calendar-button-next' + (isMultiPage ? ' mbsc-calendar-button-next-multi' : '')} />
        </Fragment>
      );
    }
    const header = (
      <div className={'mbsc-calendar-controls mbsc-flex' + inst._theme} dangerouslySetInnerHTML={isVue ? UNDEFINED : html}>
        {headerContent}
      </div>
    );

    if (isVue) {
      return header;
    }
    // We need to use the createElement for preact to work with context
    return createElement(CalendarContext.Provider, { children: header, value: { instance: inst } });
  };

  const calWeekDays =
    inst._showDaysTop && s.showCalendar ? (
      <CalendarWeekDays
        dayNamesShort={inst._dayNames}
        rtl={inst._rtl}
        theme={inst._theme}
        firstDay={s.firstDay!}
        hasScroll={state.hasScrollY}
        hidden={inst._view !== PAGE_VIEW && !inst._hasPicker}
        showWeekNumbers={s.showWeekNumbers}
      />
    ) : null;

  const pickerProps = {
    axis: inst._axis,
    batchSize: 1,
    changeOnEnd: true,
    className: 'mbsc-calendar-scroll-wrapper' + inst._theme,
    // Need to pass some random data to render month views inside the scrollview if something changed (other than scrollview props)
    data: update,
    easing: 'ease-out',
    itemSize: state.pickerSize,
    items: inst._months,
    mousewheel: inst._mousewheel,
    prevAnim: inst._prevAnim,
    rtl: s.rtl,
    snap: true,
    time: 200,
  };

  const monthYearPicker = (
    <div ref={inst._setPickerCont} className={inst._hasPicker ? 'mbsc-calendar-picker-wrapper' : ''}>
      {(state.view === MULTI_YEAR_VIEW || state.viewClosing === MULTI_YEAR_VIEW || s.selectView === MULTI_YEAR_VIEW) && (
        <div className={inst._getPickerClass(MULTI_YEAR_VIEW)} {...animationEnd}>
          <Scrollview
            key="years"
            itemRenderer={renderYears}
            maxIndex={inst._maxYearsIndex}
            minIndex={inst._minYearsIndex}
            onGestureEnd={inst._onGestureEnd}
            onIndexChange={inst._onYearsPageChange}
            selectedIndex={inst._yearsIndex}
            {...pickerProps}
          />
        </div>
      )}
      {(state.view === YEAR_VIEW || state.viewClosing === YEAR_VIEW || s.selectView === YEAR_VIEW) && (
        <div className={inst._getPickerClass(YEAR_VIEW)} {...animationEnd}>
          <Scrollview
            key="year"
            itemRenderer={renderYear}
            maxIndex={inst._maxYearIndex}
            minIndex={inst._minYearIndex}
            onGestureEnd={inst._onGestureEnd}
            onIndexChange={inst._onYearPageChange}
            selectedIndex={inst._yearIndex}
            {...pickerProps}
          />
        </div>
      )}
      {inst._hasPicker && (state.view === MONTH_VIEW || state.viewClosing === MONTH_VIEW) && (
        <div className={inst._getPickerClass(MONTH_VIEW)} {...animationEnd}>
          <Scrollview
            key="month"
            itemRenderer={renderNavMonth}
            maxIndex={inst._maxMonthIndex}
            minIndex={inst._minMonthIndex}
            onGestureEnd={inst._onGestureEnd}
            onIndexChange={inst._onMonthPageChange}
            selectedIndex={inst._monthIndex}
            {...pickerProps}
          />
        </div>
      )}
    </div>
  );
  return (
    <div className={inst._cssClass} ref={inst._setEl} style={inst._dim} onClick={noop}>
      <div
        className={
          'mbsc-calendar-wrapper mbsc-flex-col' +
          inst._theme +
          inst._hb +
          (s.hasContent || !s.showCalendar ? ' mbsc-calendar-wrapper-fixed mbsc-flex-none' : ' mbsc-flex-1-1')
        }
      >
        <div
          className={'mbsc-calendar-header' + inst._theme + inst._hb + (inst._showDaysTop ? ' mbsc-calendar-header-vertical' : '')}
          ref={inst._setHeader}
        >
          {s.showControls && renderHeader()}
          {calWeekDays}
        </div>
        <div className={'mbsc-calendar-body mbsc-flex-col mbsc-flex-1-1' + inst._theme} ref={inst._setBody} {...keydown}>
          {s.showCalendar && (
            <div
              className={'mbsc-calendar-body-inner mbsc-flex-col mbsc-flex-1-1' + (variableRow ? ' mbsc-calendar-body-inner-variable' : '')}
            >
              {inst._isGrid ? (
                <div
                  aria-hidden={monthOrYearSelection ? 'true' : UNDEFINED}
                  className={'mbsc-calendar-grid mbsc-flex-1-1 mbsc-flex-col' + inst._theme + inst._hb}
                >
                  {inst._monthsMulti.map((row: number[], i: number) => {
                    return (
                      <div key={i} className="mbsc-calendar-grid-row mbsc-flex mbsc-flex-1-1">
                        {row.map((item: number, j: number) => {
                          return (
                            <div key={j} className={'mbsc-calendar-grid-item mbsc-flex-col mbsc-flex-1-1' + inst._theme}>
                              <div className={'mbsc-calendar-month-title' + inst._theme}>{s.monthNames![new Date(item).getMonth()]}</div>
                              {renderMonthView(item, { isActive: true })}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              ) : variableRow ? (
                <div
                  aria-hidden={monthOrYearSelection ? 'true' : UNDEFINED}
                  className={'mbsc-calendar-slide mbsc-calendar-slide-active ' + inst._getPickerClass(PAGE_VIEW)}
                >
                  {renderMonthView(+s.navService!.firstDay, {
                    dragData: s.dragData,
                    dragToCreate: s.dragToCreate,
                    dragToMove: s.dragToMove,
                    dragToResize: s.dragToResize,
                    isActive: true,
                    onLabelUpdateEnd: s.onLabelUpdateEnd,
                    onLabelUpdateModeOff: s.onLabelUpdateModeOff,
                    onLabelUpdateModeOn: s.onLabelUpdateModeOn,
                    onLabelUpdateMove: s.onLabelUpdateMove,
                    onLabelUpdateStart: s.onLabelUpdateStart,
                  })}
                </div>
              ) : (
                s.selectView === PAGE_VIEW && (
                  <div
                    aria-hidden={monthOrYearSelection ? 'true' : UNDEFINED}
                    className={inst._getPickerClass(PAGE_VIEW)}
                    {...animationEnd}
                  >
                    <Scrollview
                      {...pickerProps}
                      itemNr={inst._pageNr}
                      itemSize={state.pageSize / inst._pageNr}
                      itemRenderer={renderMonth}
                      maxIndex={inst._maxIndex}
                      minIndex={inst._minIndex}
                      mouseSwipe={s.mouseSwipe}
                      onAnimationEnd={inst._onAnimationEnd}
                      onGestureStart={inst._onGestureStart}
                      onIndexChange={inst._onPageChange}
                      onStart={inst._onStart}
                      selectedIndex={inst._pageIndex}
                      swipe={s.swipe}
                    />
                  </div>
                )
              )}
              {!inst._hasPicker && monthYearPicker}
            </div>
          )}
        </div>
      </div>
      {content}
      {inst._hasPicker && (
        <Popup
          anchor={inst._pickerBtn}
          closeOnScroll={true}
          contentPadding={false}
          context={s.context}
          cssClass="mbsc-calendar-popup"
          display="anchored"
          isOpen={inst._view !== PAGE_VIEW}
          locale={s.locale}
          onClose={inst._onPickerClose}
          onOpen={inst._onPickerOpen}
          rtl={s.rtl}
          scrollLock={false}
          showOverlay={false}
          theme={s.theme}
          themeVariant={s.themeVariant}
        >
          <div {...keydown}>
            <div className={'mbsc-calendar-controls mbsc-flex' + inst._theme}>
              <div
                aria-live="polite"
                className={'mbsc-calendar-picker-button-wrapper mbsc-calendar-title-wrapper mbsc-flex mbsc-flex-1-1' + inst._theme}
              >
                <Button
                  className="mbsc-calendar-button"
                  onClick={inst._onPickerBtnClick}
                  theme={s.theme}
                  themeVariant={s.themeVariant}
                  type="button"
                  variant="flat"
                >
                  {inst._viewTitle}
                </Button>
              </div>
              <Button
                className="mbsc-calendar-button"
                ariaLabel={s.prevPageText}
                disabled={inst._isPrevDisabled(true)}
                iconSvg={inst._prevIcon}
                onClick={inst.prevPage}
                theme={s.theme}
                themeVariant={s.themeVariant}
                type="button"
                variant="flat"
              />
              <Button
                className="mbsc-calendar-button"
                ariaLabel={s.nextPageText}
                disabled={inst._isNextDisabled(true)}
                iconSvg={inst._nextIcon}
                onClick={inst.nextPage}
                theme={s.theme}
                themeVariant={s.themeVariant}
                type="button"
                variant="flat"
              />
            </div>
            {monthYearPicker}
          </div>
        </Popup>
      )}
    </div>
  );
}

export class CalendarView extends CalendarViewBase {
  protected _template(s: ICalendarViewProps, state: ICalendarViewState): any {
    return template(s, state, this, s.children);
  }

  protected _updated() {
    super._updated();
    if (this._shouldEnhanceHeader) {
      enhance(this._headerElement, { view: this });
      this._shouldEnhanceHeader = false;
    }
  }
}
