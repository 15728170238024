import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { requestSuperadminPassword } from "../core/_requests";
import { Loading } from "../../../components/loading/Loading";

const initialValues = {
  email: "",
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("This Email address is unknown.")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email address is required"),
});

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        requestSuperadminPassword(values.email)
          .then(({ data: { result } }) => {
            setHasErrors(false);
            setLoading(false);
            setSubmitting(false);
          })
          .catch(() => {
            setHasErrors(true);
            setLoading(false);
            setSubmitting(false);
            setStatus("Email address doesn’t exist.");
          });
      }, 1000);
    },
  });

  return (
    <div className="w-100 w-sm-350px">
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_password_reset_form"
      >
        <div className="mb-8">
          {/* begin::Title */}
          <h1 className="text-dark fw-bold mb-1 h2 fs-1 fs-ls-30 text-center">Password Forgotten?</h1>
          {/* end::Title  */}
        </div>

        {/* begin::Message */}
        {hasErrors === true && (
          <div className="alert alert-danger">
            <div className="alert-text font-weight-bold">
              Email address doesn’t exist.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className="alert alert-success">
            <div className="alert-text font-weight-bold">
              Instruction for password reset has been sent to your email address.
            </div>
          </div>
        )}
        {/* end::Message */}

        {/* begin::Form group */}
        <div className="fv-row mb-8">
          <label className="form-label fs-6 fs-ls-20"></label>
          <input
            placeholder="Email address"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control h-45px px-6 fs-6 rounded-1",
              { "is-invalid": formik.touched.email && formik.errors.email },
              {
                "is-valid": formik.touched.email && !formik.errors.email,
              }
            )}
            onKeyDown={()=>setHasErrors(undefined)}
            type="email"
            name="email"
            autoComplete="off"
          />
          {formik.touched.email && formik.errors.email && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block fs-ls-14">
                <span role="alert">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* End::Form group */}

        <div className=" pb-lg-0">
          {/* begin::Action */}
          <div className="d-flex justify-content-center">
            <button
              type="submit"
              id="kt_password_reset_submit"
              className="btn btn-primary h-40px fs-6 px-10 fs-ls-20 h-ls-60 col-lg-12"
              disabled={formik.isSubmitting}
            /* !formik.isValid */
            >
              {!loading && <span className="indicator-label">Send</span>}
              <Loading isLoading={loading} text={"Please wait..."}></Loading>
            </button>
          </div>

            <div className='d-flex justify-content-center mt-5'>
          <Link to="/superadmin/auth/login"

              id="kt_login_password_reset_form_cancel_button"
              className="text-decoration-underline fs-4"
              // disabled={formik.isSubmitting}
              >
                Back to login screen


          </Link>
          </div>
          {/* begin::Action */}
        </div>
      </form>
    </div>
  );
}
