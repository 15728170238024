import React from 'react';
import { ON_KEY_DOWN } from '../../../react/renderer';
import { Scrollview } from '../../../react/shared/scroll-view';
import { WheelItem } from '../../../react/shared/wheel-item';
import { UNDEFINED } from '../../util/misc';
import { IWheelProps, WheelBase } from './wheel';

export function template(s: IWheelProps, inst: WheelBase): any {
  const keydown = { [ON_KEY_DOWN]: inst._onKeyDown };

  const renderer = (item: any, offset: number, is3d: boolean) => {
    if (item !== UNDEFINED) {
      const text = inst._getText(item.data);
      return (
        <WheelItem
          active={inst._isActive(item, text, is3d)}
          angle3d={inst._angle3d}
          data={item.data}
          disabled={inst._isDisabled(item.data)}
          height={s.itemHeight}
          index={item.key}
          is3d={is3d}
          isGroup={item.data && item.data.isGroup}
          key={item.key}
          multiple={s.multiple}
          onClick={inst._onItemClick}
          offset={offset}
          checkmark={s.wheel.checkmark}
          renderItem={s.renderItem}
          rows={s.rows}
          rtl={s.rtl}
          scroll3d={s.scroll3d}
          selected={inst._isSelected(item)}
          text={text}
          theme={s.theme}
        />
      );
    }
    return null;
  };

  return (
    <div
      aria-multiselectable={s.multiple ? 'true' : UNDEFINED}
      className={
        'mbsc-scroller-wheel-wrapper mbsc-scroller-wheel-wrapper-' +
        s.wheel._key +
        ' ' +
        (s.wheel.cssClass || '') +
        (s.scroll3d ? ' mbsc-scroller-wheel-wrapper-3d' : '') +
        inst._theme +
        inst._rtl
      }
      ref={inst._setEl}
      role="listbox"
      style={inst._wheelStyle}
      {...keydown}
    >
      <Scrollview
        batchSize3d={inst._batchSize3d}
        className={'mbsc-scroller-wheel' + (s.scroll3d ? ' mbsc-scroller-wheel-3d' : '') + inst._theme}
        innerClass={
          'mbsc-scroller-wheel-cont mbsc-scroller-wheel-cont-' +
          s.display +
          (s.scroll3d ? ' mbsc-scroller-wheel-cont-3d' : '') +
          (s.multiple ? ' mbsc-scroller-wheel-multi' : '') +
          inst._theme
        }
        innerStyles={inst._innerStyle}
        items={inst._items}
        itemSize={s.itemHeight}
        itemRenderer={renderer}
        itemNr={inst._itemNr}
        margin={true}
        maxIndex={s.maxIndex}
        minIndex={s.minIndex}
        onIndexChange={inst._onIndexChange}
        offset={s.wheel._offset}
        rtl={s.rtl}
        scroll3d={s.scroll3d}
        scrollBar={!inst._touchUi}
        selectedIndex={s.selectedIndex}
        snap={true}
        spaceAround={s.wheel.spaceAround}
        styles={inst._style}
        visibleSize={s.rows}
      />
    </div>
  );
}

export class Wheel extends WheelBase {
  protected _template(s: IWheelProps): any {
    return template(s, this);
  }
}
