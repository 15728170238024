
import {
  addTimezone,
  createDate,
  dateValueEquals,
  formatDate,
  getDateOnly,
  makeDate,
  // parseDate,
} from '../../util/datetime';
import { MbscDateType } from '../../util/datetime.types.public';
import { MbscCalendarNavService } from '../../shared/calendar-nav/calendar-nav';
import { MbscCalendarDayData } from '../../shared/calendar-view/calendar-day';
import { CalendarViewBase } from '../../shared/calendar-view/calendar-view';
import {
  ICalendarProps,
  ICellClickEvent,
  ICellHoverEvent,
  ILabelClickEvent,
  IPageChangeEvent,
  IPageLoadedEvent,
  IPageLoadingEvent,
  ViewType,
} from '../../shared/calendar-view/calendar-view.types';
import { calendarViewDefaults } from '../../shared/calendar-view/calendar-view.util';
import { InstanceServiceBase } from '../../shared/instance-service';
import { PickerBase } from '../../shared/picker/picker';
import { IPickerProps, IPickerState } from '../../shared/picker/picker.types';
import { isArray, isString, UNDEFINED } from '../../util/misc';

// tslint:disable no-non-null-assertion
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

/**
 * Calendar options
 * @interface MbscCalendarOptions
 */
// tslint:disable-next-line interface-name
export interface MbscCalendarOptions<T = CalendarViewBase> extends IPickerProps, ICalendarProps {
  // #region Hidden options
  /** @hidden */
  active?: number;
  /** @hidden */
  selectMin?: number; // hidden
  /** @hidden */
  selectRange?: boolean; // hidden
  /** @hidden */
  selectView?: ViewType;
  /** @hidden */
  size?: number;
  // #endregion Hidden options

  // #region Options

  /**
   * Specifies the type of the calendar.
   *
   * In case of `'month'` set the number of displayed months using the [pages](#opt-pages) option (swipeable month view) or
   * the [calendarSize](#opt-calendarSize) option (grid month view).
   *
   * In case of `'week'` set the number of displayed weeks using the [calendarSize](#opt-calendarSize) option.
   * @defaultValue 'month'
   */
  calendarType?: 'year' | 'month' | 'week';
  /**
   * Controls the direction of the calendar navigation. You can navigate by scrolling, swiping or by clicking the arrow
   * buttons in the header.
   * Possible values:
   * - `'horizontal'` - Enables navigating the view horizontally.
   * - `'vertical'` - Enables navigating the view vertically.
   * When navigation is `'vertical'`, the outer days (days from previous and next months) are hidden. You can explicitly override
   * it with the [showOuterDays](#opt-showOuterDays) option.
   * @defaultValue 'horizontal'
   */
  calendarScroll?: 'horizontal' | 'vertical';
  /**
   * Number of calendar pages (month or week) to display.
   * If `'auto'`, the displayed number of pages will be decided based on the viewport size.
   * @defaultValue 1
   */
  pages?: number | 'auto';
  /**
   * Specifies the reference date of the component, which represents when to start to calculate the view you want to display.
   *
   * For example, if you want to display 2 months from the current month, you must specify the first day of the current month as
   * the reference date. Then you can use the [calendarSize](#opt-calendarSize) option to show 2 months.
   *
   * @defaultValue '1970/01/01'
   */
  refDate?: MbscDateType;
  /**
   * The maximum number of selected items in case of [multiple selection](#opt-selectMultiple).
   * @defaultValue undefined
   */
  selectMax?: number;
  /**
   * Show or hide days from previous and next months.
   *
   * :::info
   * Hiding outer days only works in case of month view, and not supported for week view.
   * :::
   *
   * :::info
   * Outer days are automatically hidden if [calendarScroll](#opt-calendarScroll) is set to `'vertical'`.
   * :::
   *
   * @defaultValue true
   */
  showOuterDays?: boolean;
  /**
   * Show week numbers on the calendar view. Enumeration starts with the first week of the year.
   * @defaultValue false
   */
  showWeekNumbers?: boolean;
  /**
   * @hidden
   * @deprecated
   * Number of weeks to display if [calendarType](#opt-calendarType) is set to `'week'`.
   *
   * Deprecated, use the [calendarSize](#opt-calendarSize) option instead.
   */
  weeks?: number;

  // #endregion Options

  // #region Localization

  /**
   * Specifies the amount of selected items according to the rules of particular language. The '{count}' substring will be replaced with
   * the number of selected items.
   * @defaultValue '{count} selected'
   * @group Localizations
   */
  selectedText?: string;
  /**
   * Specifies the plural form of the amount of selected items according to the rules of particular language. The '{count}' substring will
   * be replaced with the number of selected items.
   * @defaultValue '{count} selected'
   * @group Localizations
   */
  selectedPluralText?: string;

  // #endregion Localization

  // #region Templates

  /**
   * @hidden
   * Use this option to customize the header of the Datepicker. In the template you can use custom components as well as the built in header
   * controls of the calendar.
   *
   * Check out the customizing the header section for a more detailed description on built in components.
   * @defaultValue undefined
   * @group Templates
   */
  calendarHeaderTemplate?: any;
  /**
   * @hidden
   * You can use the dayTemplate option to customize the day cells of the Datepicker. It takes a function that should return the desired
   * markup. The Datepicker will take care of the positioning, but everything else (like background color, hover effect, etc.) is left
   * to you.
   *
   * If you are looking to customize only the content and don't want to bother with the styling of the event, you can use the
   * [dayContentTemplate](#templates-dayContentTemplate) option.
   *
   * The template will receive an object as data. This data can be used to show day specific things on the Datepicker. The object passed
   * to the template has the following properties:
   * - date: Date object - The specific date as a Date object.
   * - selected: Boolean - True if the date is selected. (In case of calendar view)
   * - events: Array - The list of events of the day.
   *
   * @defaultValue undefined
   * @group Templates
   */
  dayTemplate?: any;
  /**
   * @hidden
   * You can use dayContentTemplate option to customize the day cells of the Datepicker. You will get the styling taken care of by the
   * Datepicker, and you can focus on what you show besides the day number a.k.a. the content.
   *
   * If you are looking to fully customize the day (ex. add custom hover effect) you will need to use the
   * [dayTemplate](#templates-dayTemplate) option. In that case you will only get the positioning done by the Datepicker and everything
   * else is up to you.
   *
   * The template will receive an object as data. This data can be used to show day specific things on the Datepicker. The object
   * passed to the template has the following properties:
   * - date: Date object - The specific date as a Date object.
   * - selected: Boolean - True if the date is selected.
   * - events: Array - The list of events of the day.
   *
   * @defaultValue undefined
   * @group Templates
   */
  dayContentTemplate?: any;

  // #endregion Templates

  // #region Renderers

  /**
   * Customize the day cells of the Datepicker.
   * The Datepicker will take care of the positioning, but everything else (like background color, hover effect, etc.) is left
   * to you.
   *
   * If you are looking to customize only the day cell content and don't want to bother with the styling of the event, you can use the
   * [renderDayContent](#renderer-renderDayContent) option.
   *
   * The following day specific details are available:
   * - `date`: _Date_ - The specific date as a Date object.
   * - `selected`: _boolean_ - True if the date is selected. (In case of calendar view)
   * - `events`: _Array<MbscCalendarEvent>_ - The list of events of the day.
   * @group Renderers
   */
  renderDay?(args: MbscCalendarDayData): any;
  /**
   * Customize the day cells content of the Datepicker.
   * The Datepicker will take care of styling and you can focus on what you show beside the day number a.k.a the content.
   *
   * If you are looking to fully customize the day cell (ex. add custom hover effects) you will need to use the
   * [renderDay](#renderer-renderDay) option. In that case you will only get the positioning done by the Datepicker and everything else
   * is up to you.
   *
   * The following day specific details are available:
   * - `date`: _Date_ - The specific date as a Date object.
   * - `selected`: _boolean_ - True if the date is selected.
   * - `events`: _Array<MbscCalendarEvent>_ - The list of events of the day.
   * @group Renderers
   */
  renderDayContent?(args: MbscCalendarDayData): any;
  /**
   * Customize the header of the Datepicker.
   * You can use custom html as well as the built in header components of the calendar.
   *
   * Check out the customizing the header section for a more detailed description on built in components.
   * @group Renderers
   */
  renderCalendarHeader?(): any;

  // #endregion Renderers

  // #region Events

  /** @hidden */
  onActiveChange?(args: any, inst: T): void;
  onCellHoverIn?(args: ICellHoverEvent<T>, inst: T): void;
  onCellHoverOut?(args: ICellHoverEvent<T>, inst: T): void;
  onCellClick?(args: ICellClickEvent<T>, inst: T): void;
  onLabelClick?(args: ILabelClickEvent<T>, inst: T): void;
  onPageChange?(args: IPageChangeEvent<T>, inst: T): void;
  onPageLoaded?(args: IPageLoadedEvent<T>, inst: T): void;
  onPageLoading?(args: IPageLoadingEvent<T>, inst: T): void;

  // #endregion Events
}

/** @hidden */
// tslint:disable-next-line interface-name
export interface MbscCalendarState extends IPickerState {
  activeTab?: string;
  pages?: number;
}

type CalendarValue = null | Date | Date[];

interface ICalendarValueRep {
  [key: string]: Date;
}

export class CalendarBase extends PickerBase<MbscCalendarOptions, MbscCalendarState, CalendarValue, ICalendarValueRep> {
  public static defaults: MbscCalendarOptions = {
    ...calendarViewDefaults,
    calendarScroll: 'horizontal',
    calendarType: 'month',
    selectedText: '{count} selected',
    showControls: true,
    showOnClick: true,
    weeks: 1,
  };

  // tslint:disable variable-name

  public static _name = 'Calendar';

  // These are public because of the angular template only
  // ---
  /** @hidden */
  public _calendarView!: CalendarViewBase;
  /** @hidden */
  public _instanceService?: InstanceServiceBase;
  /** @hidden */
  public _navService: MbscCalendarNavService = new MbscCalendarNavService();
  /** @hidden */
  public _update = 0;

  // tslint:enable variable-name

  /** @hidden */
  // tslint:disable-next-line: variable-name
  public _onDayClick = (args: any) => {
    const s = this.s;
    const date: Date = addTimezone(s, args.date);
    const d = +date;

    if (args.disabled) {
      return;
    }

    // Update tempValueRep with the new selection
    if (s.selectMultiple) {
      const tempValueRep = this._tempValueRep;
      if (tempValueRep[d]) {
        delete tempValueRep[d];
      } else if (s.selectMax !== UNDEFINED ? Object.keys(tempValueRep).length < s.selectMax : true) {
        tempValueRep[d] = date;
      }
      // Need a new object reference to always re-render the calendarview
      this._tempValueRep = { ...tempValueRep };
    } else {
      if (!s.selectRange) {
        this._tempValueRep = {};
      }
      this._tempValueRep[d] = date;
    }
    this._navService.preventPageChange = s.selectRange!;
    this._hook('onCellClick', args);
    this._setOrUpdate();
  };

  /** @hidden */
  // tslint:disable-next-line: variable-name
  public _onTodayClick = () => {
    const date = new Date();
    const d = +getDateOnly(date);
    if (!this.s.selectRange && !this.s.selectMultiple) {
      this._tempValueRep = {};
      this._tempValueRep[d] = date;
      this._setOrUpdate();
    }
  };

  /** @hidden */
  // tslint:disable-next-line: variable-name
  public _onActiveChange = (args: any) => {
    this._navService.forcePageChange = args.pageChange;
    // Force update if active date is the same as previous active date
    this._update++;
    this._hook('onActiveChange', args);
  };

  /** @hidden */
  public _valueEquals(v1: any, v2: any) {
    return dateValueEquals(v1, v2, this.s);
  }

  public _shouldValidate(s: MbscCalendarOptions, prevS: MbscCalendarOptions) {
    return s.dataTimezone !== prevS.dataTimezone || s.displayTimezone !== prevS.displayTimezone;
  }

  // tslint:disable-next-line: variable-name
  public _setCal = (cal: any) => {
    this._calendarView = cal;
  };

  protected _render(s: MbscCalendarOptions, state: MbscCalendarState) {
    super._render(s, state);
    this._navService.options({
      activeDate: s.active,
      calendarType: s.calendarType,
      firstDay: s.firstDay,
      getDate: s.getDate,
      getDay: s.getDay,
      getMonth: s.getMonth,
      getYear: s.getYear,
      max: s.max,
      min: s.min,
      onPageChange: s.onPageChange,
      onPageLoading: s.onPageLoading,
      pages: s.pages,
      refDate: s.refDate,
      showCalendar: true,
      showOuterDays: s.showOuterDays,
      size: s.size,
      weeks: s.weeks,
    });
  }
  protected _copy(value: any): any {
    return { ...value };
  }

  protected _format(value: any): string {
    const s = this.s;
    const ret = [];

    for (const i in value) {
      if (value[i] !== UNDEFINED && value[i] !== null) {
        ret.push(formatDate(s.dateFormat!, new Date(+value[i]), s));
      }
    }

    return s.selectMultiple || s.selectRange ? ret.join(', ') : ret[0];
  }

  protected _parse(value: any): { [key: number]: Date } {
    const s = this.s;
    const isRange = s.selectRange;
    const ret: { [key: number]: Date } = {};
    let values = [];

    if (isString(value)) {
      values = value.split(',');
    } else if (isArray(value)) {
      values = value;
    } else if (value && !isArray(value)) {
      values = [value];
    }

    for (const val of values) {
      if (val !== null) {
        const date = makeDate(val, s, s.dateFormat!);
        const key = isRange ? +date : +getDateOnly(date); // we need the time part for the same day ranges ex. [06.30 00:00 - 06.30 23:59]
        ret[key] = date;
      }
    }

    return ret;
  }

  protected _get(value: ICalendarValueRep): CalendarValue {
    const s = this.s;
    const isRange = s.selectRange;
    if (this.s.selectMultiple || isRange) {
      const valueArray = [];
      for (const v of Object.keys(value)) {
        valueArray.push(createDate(s, +value[v]));
      }
      return valueArray;
    }

    const valueKeys = Object.keys(value || {});
    if (!valueKeys.length) {
      return null;
    }
    return createDate(s, value[valueKeys[0]]);
  }
}
