import { FC } from "react";
import { DemosDrawer } from "./demos-drawer/DemosDrawer";
import { HelpDrawer } from "./help-drawer/HelpDrawer";

const RightToolbar: FC = () => {
  return (
    <>
      <DemosDrawer />
      <HelpDrawer />
    </>
  );
};

export { RightToolbar };
