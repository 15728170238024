import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../_metronic/helpers";
import {
  Email,
  EmailQueryResponse,
  EmailCredSetting,
  GeneralSetting,
  MediaSetting,
  SmtpSetting,
  TestMail,
  InvoiceSetting,
} from "./_models";

const API_URL = process.env.REACT_APP_API_URL;
const EMAIL_URL = `${API_URL}superadmin/emails`;
const SETTING_URL = `${API_URL}superadmin/settings`;
const GENERAL_SETTING_URL = `${API_URL}superadmin/settings/general`;
const GENERAL_SETTING_URL2 = `${API_URL}settings/general_settings`;
const MAIL_URL = `${API_URL}superadmin/settings/send_test_email`;
const ADMIN_MEDIA_URL = `${API_URL}superadmin/settings/media`;
const SMTP_SETTING_URL = `${API_URL}superadmin/settings/smtp_setting`;
const RESOURCE_SETTING_URL = `${API_URL}settings/resource`

const getEmail = (query: string): Promise<EmailQueryResponse> => {
  return axios
    .get(`${EMAIL_URL}?${query}`)
    .then((d: AxiosResponse<EmailQueryResponse>) => d.data);
};

const getEmailById = (id: string | null): Promise<Email | undefined> => {
  return axios
    .get(`${EMAIL_URL}/${id}`)
    .then((response: AxiosResponse<Response<Email>>) => response.data)
    .then((response: Response<Email>) => response.data);
};

const createEmail = (email: Email): Promise<Email | undefined> => {
  return axios
    .post(EMAIL_URL, email)
    .then((response: AxiosResponse<Response<Email>>) => response.data)
    .then((response: Response<Email>) => response.data);
};

const updateEmail = (email: Email): Promise<Email | undefined> => {
  return axios
    .patch(`${EMAIL_URL}/${email._id}`, email)
    .then((response: AxiosResponse<Response<Email>>) => response.data)
    .then((response: Response<Email>) => response.data);
};

const deleteEmail = (emailId: ID): Promise<void> => {
  return axios.delete(`${EMAIL_URL}/${emailId}`).then(() => {});
};

const deleteSelectedEmail = (emailIds: Array<ID>): Promise<void> => {
  const requests = emailIds.map((id) => axios.delete(`${EMAIL_URL}/${id}`));
  return axios.all(requests).then(() => {});
};

const updateGeneralSetting = (
  generalSetting: GeneralSetting
): Promise<GeneralSetting | undefined> => {
  return axios
    .post(`${SETTING_URL}`, generalSetting)
    .then((response: AxiosResponse<Response<GeneralSetting>>) => response.data)
    .then((response: Response<GeneralSetting>) => response.data);
};

const createGeneralSetting = (
  generalSetting: GeneralSetting
): Promise<GeneralSetting | undefined> => {
  return axios
    .post(SETTING_URL, generalSetting)
    .then((response: AxiosResponse<Response<GeneralSetting>>) => response.data)
    .then((response: Response<GeneralSetting>) => response.data);
};

const getGeneralSetting = (query: string): Promise<any | undefined> => {
  return axios
    .get(`${GENERAL_SETTING_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data);
};

const getGeneralSetting2 = (query: string): Promise<any | undefined> => {
  return axios
    .get(`${GENERAL_SETTING_URL2}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data);
};

const getResourceSettings = (query: string): Promise<any | undefined> => {
  return axios
    .get(`${RESOURCE_SETTING_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data);
};
const getSmtpSetting = (query: string): Promise<any | undefined> => {
  return axios
    .get(`${SMTP_SETTING_URL}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data);
};


const updateAdminMedia = (adminMedia: MediaSetting): Promise<MediaSetting | undefined> => {
  return axios
    .post(`${ADMIN_MEDIA_URL}`, adminMedia)
    .then((response: AxiosResponse<Response<MediaSetting>>) => response.data)
    .then((response: Response<MediaSetting>) => response.data);
};

const createAdminMedia = (adminMedia: MediaSetting): Promise<MediaSetting | undefined> => {
  return axios
    // .patch(PROFILEUPDATE_URL_NODE, profileDetail, { headers: headers })
    .post(ADMIN_MEDIA_URL, adminMedia)
    .then((response: AxiosResponse<Response<MediaSetting>>) => response.data)
    .then((response: Response<MediaSetting>) => response.data);
};

const updateEmailCredSetting = (
  emailSetting: EmailCredSetting
): Promise<EmailCredSetting | undefined> => {
  return axios
    .post(`${SETTING_URL}`, emailSetting)
    .then((response: AxiosResponse<Response<EmailCredSetting>>) => response.data)
    .then((response: Response<EmailCredSetting>) => response.data);
};

const createEmailCredSetting = (
  emailSetting: EmailCredSetting
): Promise<EmailCredSetting | undefined> => {
  return axios
    .post(SETTING_URL, emailSetting)
    .then((response: AxiosResponse<Response<EmailCredSetting>>) => response.data)
    .then((response: Response<EmailCredSetting>) => response.data);
};

const updateSmtpSetting = (
  smtpSetting: SmtpSetting
): Promise<SmtpSetting | undefined> => {
  return axios
    .post(`${SETTING_URL}`, smtpSetting)
    .then((response: AxiosResponse<Response<SmtpSetting>>) => response.data)
    .then((response: Response<SmtpSetting>) => response.data);
};

const createSmtpSetting = (
  smtpSetting: SmtpSetting
): Promise<SmtpSetting | undefined> => {
  return axios
    .post(SETTING_URL, smtpSetting)
    .then((response: AxiosResponse<Response<SmtpSetting>>) => response.data)
    .then((response: Response<SmtpSetting>) => response.data);
};

const sendTestmail = (
  testMail: TestMail
): Promise<TestMail | undefined> => {
  return axios
    .post(`${MAIL_URL}`, testMail)
    .then((response: AxiosResponse<Response<TestMail>>) => response.data)
    .then((response: Response<TestMail>) => response.data);
};

const updateInvoiceSetting = (
  invoiceSetting: InvoiceSetting
): Promise<InvoiceSetting | undefined> => {
  return axios
    .post(`${SETTING_URL}`, invoiceSetting)
    .then((response: AxiosResponse<Response<InvoiceSetting>>) => response.data)
    .then((response: Response<InvoiceSetting>) => response.data);
};

const createInvoiceSetting = (
  invoiceSetting: InvoiceSetting
): Promise<InvoiceSetting | undefined> => {
  return axios
    .post(SETTING_URL, invoiceSetting)
    .then((response: AxiosResponse<Response<InvoiceSetting>>) => response.data)
    .then((response: Response<InvoiceSetting>) => response.data);
};



export {
  getEmail,
  deleteEmail,
  deleteSelectedEmail,
  getEmailById,
  createEmail,
  updateEmail,
  createGeneralSetting,
  updateGeneralSetting,
  getGeneralSetting,
  updateAdminMedia,
  createAdminMedia,
  updateEmailCredSetting,
  createEmailCredSetting,
  updateSmtpSetting,
  createSmtpSetting,
  sendTestmail,
  getSmtpSetting,
  updateInvoiceSetting,
  createInvoiceSetting,
  getGeneralSetting2
};
