import React, { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import { getGeneralSetting } from "../../../../../_metronic/requests/GeneralSettingRequest";
import {
  setcallApiforfilter,
  storeAppointmentFilter,
  storeCompanyIds,
  storeDepartmentIds,
  storeLocationIds,
  storePeopleIds,
  storeProjectIds,
  storeRoleIds,
  storeSkillIds,
  storeTypeids,
  storelineOfBusinessIds,
  storequeuesids,
  storeservicecall_statusids,
} from "../../../../../app/counterSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hook";
import { DropDown } from "./DropDown";
import { loadFromLocalStorage } from "../../../../helpers/latest_selection";
type Props = {
  filterArray?: any;
  view?: any;
  openDropdown?:any;
  setOpenDropdown?:any;
  toggleDropdown?:any;
};
const FilterMain: FC<Props> = ({ filterArray, view, openDropdown,setOpenDropdown,toggleDropdown  }) => {
  const count = useAppSelector((state) => state.counter);
  const dispatch = useAppDispatch();
  const [currentClick, setcurrentClick] = useState("");
  const [showproject, setshowproject] = useState("d-none");
  const [showrole, setshowrole] = useState("d-none");
  const [showpeople, setshowpeople] = useState("d-none");
  const [showdepartment, setshowdepartment] = useState("d-none");
  const [showlocation, setshowlocation] = useState("d-none");
  const [showskill, setshowskill] = useState("d-none");
  const [showqueues, setshowqueues] = useState("d-none");
  const [showtype, setshowtype] = useState("d-none");
  const [showservicecall_status, setshowservicecall_status] =
    useState("d-none");
  const [showcompany, setshowcompany] = useState("d-none");
  const [showlineOfBusiness, setshowlineOfBusiness] = useState("d-none");
  const [Projectid, setProjectid] = useState<any>([]);
  const [Roleid, setRoleid] = useState<any>([]);
  const [ProjectName, setProjectName] = useState<any>([]);
  const [RoleName, setRoleName] = useState<any>([]);
  const [Peopletid, setPeopletid] = useState<any>(Array);
  const [PeopletName, setPeopletName] = useState<any>([]);
  const [Departmentid, setDepartmentid] = useState<any>(Array);
  const [DepartmentName, setDepartmentName] = useState<any>([]);
  const [Companyid, setCompanyid] = useState<any>(Array);
  const [CompanyName, setCompanyName] = useState<any>([]);
  const [projectinputText, setprojectinputText] = useState("");
  const [roleinputText, setroleinputText] = useState("");
  const [peopleinputText, setpeopleinputText] = useState("");
  const [departmentinputText, setdepartmentinputText] = useState("");
  const [companyinputText, setcompanyinputText] = useState("");
  const [queuesinputText, setqueuesinputText] = useState("");
  const [servicecall_statusinputText, setservicecall_statusinputText] =
    useState("");
  const [typeinputText, settypeinputText] = useState("");
  const [locationinputText, setlocationinputText] = useState("");
  const [skillinputText, setskillinputText] = useState("");
  const [lineOfBusinessinputText, setlineOfBusinessinputText] = useState("");
  const [Locationid, setLocationid] = useState<any>(Array);
  const [Skillid, setSkillid] = useState<any>(Array);
  const [queuesid, setqueuesid] = useState<any>(Array);
  const [Servicecall_statusid, setServicecall_statusid] = useState<any>(Array);
  const [Typeid, setTypeid] = useState<any>(Array);
  const [lineOfBusinessid, setlineOfBusinessid] = useState<any>(Array);
  const [LocationName, setLocationName] = useState<any>([]);
  const [SkillName, setSkillName] = useState<any>([]);
  const [queuesName, setqueuesName] = useState<any>([]);
  const [Servicecall_statusName, setServicecall_statusName] = useState<any>([]);
  const [TypeName, setTypeName] = useState<any>([]);
  const [lineOfBusinessName, setlineOfBusinessName] = useState<any>([]);
  const {
    data: generalSetting,
  } = useQuery(
    `planning-resource-setting`,
    async () => {
      return await getGeneralSetting("");
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const clearAllFilter = (value: any) => {
    dispatch(setcallApiforfilter(value));
    let ar: any = [];
    setProjectid([]);
    setProjectName([]);
    dispatch(storeProjectIds(ar));
    setshowproject("d-none");
    setprojectinputText("");

    setRoleid([]);
    setRoleName([]);
    dispatch(storeRoleIds(ar));
    setshowrole("d-none");
    setroleinputText("");

    setshowpeople("d-none");
    setPeopletid(ar);
    setPeopletName(ar);
    dispatch(storePeopleIds(ar));
    setpeopleinputText("");

    setDepartmentid(ar);
    setDepartmentName(ar);
    dispatch(storeDepartmentIds(ar));
    setshowdepartment("d-none");
    setdepartmentinputText("");

    setCompanyid(ar);
    setCompanyName(ar);
    dispatch(storeCompanyIds(ar));
    setshowcompany("d-none");
    setcompanyinputText("");

    setLocationid(ar);
    setLocationName(ar);
    dispatch(storeLocationIds(ar));
    setshowlocation("d-none");
    setlocationinputText("");

    setSkillid(ar);
    setSkillName(ar);
    dispatch(storeSkillIds(ar));
    setshowskill("d-none");
    setskillinputText("");

    setshowqueues("d-none");
    setqueuesid(ar);
    setqueuesName(ar);
    dispatch(storequeuesids(ar));
    setqueuesinputText("");

    setshowtype("d-none");
    setTypeid(ar);
    setTypeName(ar);
    dispatch(storeTypeids(ar));
    settypeinputText("");

    setlineOfBusinessid(ar);
    setlineOfBusinessName(ar);
    dispatch(storelineOfBusinessIds(ar));
    setshowlineOfBusiness("d-none");
    setlineOfBusinessinputText("");

    if (value) {
      setServicecall_statusid(ar);
      setServicecall_statusName(ar);
      dispatch(storeservicecall_statusids(ar));
      setshowservicecall_status("d-none");
      setservicecall_statusinputText("");
      storeFilterValue("all")
    }
  };

  const hideSelection = (e: any) => {
    dispatch(setcallApiforfilter(true));
    let ar: any = [];
    if (e.target.value === "Project") {
      setProjectid([]);
      setProjectName([]);
      dispatch(storeProjectIds(ar));
      setshowproject("d-none");
      setprojectinputText("");
    } else if (e.target.value === "Role") {
      setRoleid([]);
      setRoleName([]);
      dispatch(storeRoleIds(ar));
      setshowrole("d-none");
      setroleinputText("");
    } else if (e.target.value === "People") {
      setshowpeople("d-none");
      setPeopletid(ar);
      setPeopletName(ar);
      dispatch(storePeopleIds(ar));
      setpeopleinputText("");
    } else if (e.target.value === "Department") {
      setDepartmentid(ar);
      setDepartmentName(ar);
      dispatch(storeDepartmentIds(ar));
      setshowdepartment("d-none");
      setdepartmentinputText("");
    } else if (e.target.value === "Company") {
      setCompanyid(ar);
      setCompanyName(ar);
      dispatch(storeCompanyIds(ar));
      setshowcompany("d-none");
      setcompanyinputText("");
    } else if (e.target.value === "Location") {
      setLocationid(ar);
      setLocationName(ar);
      dispatch(storeLocationIds(ar));
      setshowlocation("d-none");
      setlocationinputText("");
    } else if (e.target.value === "Queues") {
      setshowqueues("d-none");
      setqueuesid(ar);
      setqueuesName(ar);
      dispatch(storequeuesids(ar));
      setqueuesinputText("");
    } else if (e.target.value === "servicecall_status") {
      setshowservicecall_status("d-none");
      setServicecall_statusid(ar);
      setServicecall_statusName(ar);
      dispatch(storeservicecall_statusids(ar));
      setservicecall_statusinputText("");
    } else if (e.target.value === "Type") {
      setshowtype("d-none");
      setTypeid(ar);
      setTypeName(ar);
      dispatch(storeTypeids(ar));
      settypeinputText("");
    } else if (e.target.value === "Skills") {
      setskillinputText("");
      setshowskill("d-none");
      setSkillid(ar);
      setSkillName(ar);
      dispatch(storeSkillIds(ar));
      setskillinputText("");
    } else if (e.target.value === "LineOfBusiness") {
      setlineOfBusinessinputText("");
      setshowlineOfBusiness("d-none");
      setlineOfBusinessid(ar);
      setlineOfBusinessName(ar);
      dispatch(storelineOfBusinessIds(ar));
      setlineOfBusinessinputText("");
    } else {
      setProjectid([]);
      setProjectName([]);
      dispatch(storeProjectIds(ar));
      setshowproject("d-none");
      setprojectinputText("");

      setRoleid([]);
      setRoleName([]);
      dispatch(storeRoleIds(ar));
      setshowrole("d-none");
      setroleinputText("");

      setshowpeople("d-none");
      setPeopletid(ar);
      setPeopletName(ar);
      dispatch(storePeopleIds(ar));
      setpeopleinputText("");

      setDepartmentid(ar);
      setDepartmentName(ar);
      dispatch(storeDepartmentIds(ar));
      setshowdepartment("d-none");
      setdepartmentinputText("");

      setCompanyid(ar);
      setCompanyName(ar);
      dispatch(storeCompanyIds(ar));
      setshowcompany("d-none");
      setcompanyinputText("");

      setLocationid(ar);
      setLocationName(ar);
      dispatch(storeLocationIds(ar));
      setshowlocation("d-none");
      setlocationinputText("");

      setSkillid(ar);
      setSkillName(ar);
      dispatch(storeSkillIds(ar));
      setshowskill("d-none");
      setskillinputText("");

      setshowqueues("d-none");
      setqueuesid(ar);
      setqueuesName(ar);
      dispatch(storequeuesids(ar));
      setqueuesinputText("");

      setshowtype("d-none");
      setTypeid(ar);
      setTypeName(ar);
      dispatch(storeTypeids(ar));
      settypeinputText("");

      setServicecall_statusid(ar);
      setServicecall_statusName(ar);
      dispatch(storeservicecall_statusids(ar));
      setshowservicecall_status("d-none");
      setservicecall_statusinputText("");

      setshowlineOfBusiness("d-none");
      setlineOfBusinessid(ar);
      setlineOfBusinessName(ar);
      dispatch(storelineOfBusinessIds(ar));
      setlineOfBusinessinputText("");
    }
  };

  const ChangeFilter = (e: any) => {
    dispatch(setcallApiforfilter(true));
    if (e.target.getAttribute("data-name") === "project") {
      setshowproject("");
      setcurrentClick("Project");
      toggleDropdown("Project")
    } 
    if (e.target.getAttribute("data-name") === "role") {
      setshowrole("");
      setcurrentClick("Role");
      toggleDropdown("Role")
    }
    if (e.target.getAttribute("data-name") === "people") {
      setshowpeople("");
      setcurrentClick("People");
      toggleDropdown("People")
    }
    if (e.target.getAttribute("data-name") === "department") {
      setshowdepartment("");
      setcurrentClick("Department");
      toggleDropdown("Department")
    } else if (e.target.getAttribute("data-name") === "location") {
      setshowlocation("");
      setcurrentClick("Location");
      toggleDropdown("Location")
    }
    if (e.target.getAttribute("data-name") === "Skills") {
      setshowskill("");
      setcurrentClick("Skills");
      toggleDropdown("Skills")
    }
    if (e.target.getAttribute("data-name") === "queues") {
      setshowqueues("");
      setcurrentClick("Queues");
      toggleDropdown("Queues")
    }
    if (e.target.getAttribute("data-name") === "type") {
      setshowtype("");
      setcurrentClick("Type");
      toggleDropdown("Type")
    }
    if (e.target.getAttribute("data-name") === "servicecall_status") {
      setshowservicecall_status("");
      setcurrentClick("servicecall_status");
      toggleDropdown("servicecall_status")
    }
    if (e.target.getAttribute("data-name") === "LineOfBusiness") {
      setshowlineOfBusiness("");
      setcurrentClick("LineOfBusiness");
      toggleDropdown("LineOfBusiness")
    }
    if (e.target.getAttribute("data-name") === "company") { 
      setshowcompany("");
      setcurrentClick("Company");
      toggleDropdown("Company")
    }
  };

  const storeFilterValue = (value: any) => {
    const filterMap = {
      "Project": () => dispatch(storeProjectIds(Projectid)),
      "Role": () => dispatch(storeRoleIds(Roleid)),
      "People": () => dispatch(storePeopleIds(Peopletid)),
      "Company": () => dispatch(storeCompanyIds(Companyid)),
      "Department": () => dispatch(storeDepartmentIds(Departmentid)),
      "Location": () => dispatch(storeLocationIds(Locationid)),
      "Skills": () => dispatch(storeSkillIds(Skillid)),
      "Queues": () => dispatch(storequeuesids(queuesid)),
      "servicecall_status": () => dispatch(storeservicecall_statusids(Servicecall_statusid)),
      "Type": () => dispatch(storeTypeids(Typeid)),
      "LineOfBusiness": () => dispatch(storelineOfBusinessIds(lineOfBusinessid)),
    };
  
    // Dispatch actions based on specific value or "all"
    if (value === "all") {
      Object.values(filterMap).forEach(dispatchAction => dispatchAction());
    } else if (filterMap[value]) {
      filterMap[value]();
    }
  };
  

  const resetSelectedItems = (value: any) => {
      let resetArray = ["Project", "Role" , "People", "Company", "Department", "Location", "Skills", "Queues", "servicecall_status", "Type", "LineOfBusiness"];
      if(resetArray.includes("Project")){
        setProjectid(count.project_ids)
      }
      if(resetArray.includes("Role")){
        setRoleid(count.role_ids)
      }
      if(resetArray.includes("People")){
        setPeopletid(count.people_ids)
      }
      if(resetArray.includes("Company")){
        setCompanyid(count.company_ids)
      }
      if(resetArray.includes("Department")){
        setDepartmentid(count.department_ids)
      }
      if(resetArray.includes("Location")){
        setLocationid(count.location_ids)
      }

      if(resetArray.includes("Skills")){
        setSkillid(count.skill_ids)
      }

      if(resetArray.includes("Queues")){
        setqueuesid(count.queues_ids)
      }
      if(resetArray.includes("servicecall_status")){
        setServicecall_statusid(count.servicecall_status_ids)
      }
      if(resetArray.includes("Type")){
        setTypeid(count.type_ids)
      }
      if(resetArray.includes("LineOfBusiness")){
        setlineOfBusinessid(count.lineOfBusiness_ids)
      }
  };

  const handleChange = (e: any) => {
    dispatch(setcallApiforfilter(true));
    if (e.target.getAttribute("data-id") === "Project") {
      if (e.target.checked) {
        setProjectid((Projectid: any) => [
          ...Projectid,
          parseInt(e.target.value),
        ]);
      } else {
        setProjectid(
          Projectid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "Role") {
      if (e.target.checked) {
        setRoleid((Roleid: any) => [...Roleid, parseInt(e.target.value)]);
      } else {
        setRoleid(
          Roleid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "People") {
      if (e.target.checked) {
        setPeopletid((Peopletid: any) => [
          ...Peopletid,
          parseInt(e.target.value),
        ]);
      } else {
        setPeopletid(
          Peopletid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "Company") {
      if (e.target.checked) {
        setCompanyid((Companyid: any) => [
          ...Companyid,
          parseInt(e.target.value),
        ]);
      } else {
        setCompanyid(
          Companyid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "Department") {
      if (e.target.checked) {
        setDepartmentid((Departmentid: any) => [
          ...Departmentid,
          parseInt(e.target.value),
        ]);
      } else {
        setDepartmentid(
          Departmentid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "Location") {
      if (e.target.checked) {
        setLocationid((Locationid: any) => [
          ...Locationid,
          parseInt(e.target.value),
        ]);
      } else {
        setLocationid(
          Locationid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "Skills") {
      if (e.target.checked) {
        setSkillid((Skillid: any) => [...Skillid, parseInt(e.target.value)]);
      } else {
        setSkillid(
          Skillid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "Queues") {
      if (e.target.checked) {
        setqueuesid((queuesid: any) => [...queuesid, parseInt(e.target.value)]);
      } else {
        setqueuesid(
          queuesid.filter((item: any) => item !== parseInt(e.target.value))
        );
      }
    } else if (e.target.getAttribute("data-id") === "servicecall_status") {
      if (e.target.checked) {
        setServicecall_statusid((Servicecall_statusid: any) => [
          ...Servicecall_statusid,
          parseInt(e.target.value),
        ]);
      } else {
        setServicecall_statusid(
          Servicecall_statusid.filter(
            (item: any) => item !== parseInt(e.target.value)
          )
        );
      }
    } else if (e.target.getAttribute("data-id") === "Type") {
      
      if (e.target.checked) {
        setTypeid((Typeid: any) => [...Typeid, e.target.value]);
      } else {
        setTypeid(Typeid.filter((item: any) => item !== e.target.value));
        if(e.target.name === "Appointment"){
          dispatch(storeAppointmentFilter([]))
        }
      }
    } else if (e.target.getAttribute("data-id") === "LineOfBusiness") {
      if (e.target.checked) {
        setlineOfBusinessid((lineOfBusinessid: any) => [
          ...lineOfBusinessid,
          parseInt(e.target.value),
        ]);
      } else {
        setlineOfBusinessid(
          lineOfBusinessid.filter(
            (item: any) => item !== parseInt(e.target.value)
          )
        );
      }
    }
  };
  let inputHandler = (e: any) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    if (e.target.id === "People-search") {
      setpeopleinputText(lowerCase);
    } else if (e.target.id === "Company-search") {
      setcompanyinputText(lowerCase);
    } else if (e.target.id === "Department-search") {
      setdepartmentinputText(lowerCase);
    } else if (e.target.id === "Project-search") {
      setprojectinputText(lowerCase);
    } else if (e.target.id === "Role-search") {
      setroleinputText(lowerCase);
    } else if (e.target.id === "Location-search") {
      setlocationinputText(lowerCase);
    } else if (e.target.id === "Skills-search") {
      setskillinputText(lowerCase);
    } else if (e.target.id === "Queues-search") {
      setqueuesinputText(lowerCase);
    } else if (e.target.id === "servicecall_status-search") {
      setservicecall_statusinputText(lowerCase);
    } else if (e.target.id === "Type-search") {
      settypeinputText(lowerCase);
    } else if (e.target.id === "LineOfBusiness-search") {
      setlineOfBusinessinputText(lowerCase);
    }
  };

  const selectAll = (e: any) => {
    dispatch(setcallApiforfilter(true));
    if (e.target.id === "Project") {
      if (e.target.checked) {
        const selectallid = filterArray?.projects
          ?.filter(
            (twork: any) => twork.isActive === true && twork.isCompleted === false
          )
          ?.map((x: any) => parseInt(x.id));
        setProjectid(selectallid);
      } else {
        setProjectid([]);
      }
    } else if (e.target.id === "Role") {
      if (e.target.checked) {
        const selectallid = filterArray?.roles
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        setRoleid(selectallid);
      } else {
        setRoleid([]);
      }
    } else if (e.target.id === "People") {
      if (e.target.checked) {
        const selectallid = filterArray?.peoples
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        setPeopletid(selectallid);
      } else {
        setPeopletid([]);
      }
    } else if (e.target.id === "Company") {
      if (e.target.checked) {
        const selectallid = filterArray?.companies
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        setCompanyid(selectallid);
      } else {
        setCompanyid([]);
      }
    } else if (e.target.id === "Department") {
      if (e.target.checked) {
        const selectallid = filterArray?.departments?.items?.map((x: any) =>
          parseInt(x.id)
        );
        setDepartmentid(selectallid);
      } else {
        setDepartmentid([]);
      }
    } else if (e.target.id === "Location") {
      if (e.target.checked) {
        const selectallid = filterArray?.locations?.data
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        setLocationid(selectallid);
      } else {
        setLocationid([]);
      }
    } else if (e.target.id === "Skills") {
      if (e.target.checked) {
        const selectallid = filterArray?.skills?.data
          ?.filter((twork: any) => twork.isActive === true)
          .map((x: any) => parseInt(x.id));
        setSkillid(selectallid);
      } else {
        setSkillid([]);
      }
    } else if (e.target.id === "Queues") {
      if (e.target.checked) {
        const selectallid = filterArray?.queues?.data
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        setqueuesid(selectallid);
      } else {
        setqueuesid([]);
      }
    } else if (e.target.id === "servicecall_status") {
      if (e.target.checked) {
        const selectallid = filterArray?.todo_status_serviceCall
          ?.filter((twork: any) => twork.isActive === true)
          ?.map((x: any) => parseInt(x.id));
        setServicecall_statusid(selectallid);
      } else {
        setServicecall_statusid([]);
      }
    } else if (e.target.id === "Type") {
      if (e.target.checked) {
        const selectallid = filterArray?.status_type?.map((x: any) => x.id);
        setTypeid(selectallid);
      } else {
        setTypeid([]);
      }
    } else if (e.target.id === "LineOfBusiness") {
      if (e.target.checked) {
        const selectallid = filterArray?.lineOfBusiness?.data
          ?.filter((twork: any) => twork.isActive === true)
          .map((x: any) => parseInt(x.id));
        setlineOfBusinessid(selectallid);
      } else {
        setlineOfBusinessid([]);
      }
    }
  };
  useEffect(() => {
    if (count?.ispageLoad && !filterArray?.isfetchlayoutandfilters && !filterArray?.isPeopleFetch && !filterArray?.isDepartmentFetch && !filterArray?.isCompanyFetch && !filterArray?.isProjectFetch && !filterArray?.isSkillFetch && !filterArray?.isRoleFetch && !filterArray?.isQueuesFetch && !filterArray?.isfetchservicecallstatus && !filterArray?.islineOfBusinessFetch) {
      let savedPreferences = loadFromLocalStorage('planning_layout');
      let savedPreferencesplanning = savedPreferences?.planning_filter || filterArray?.layoutandfilters?.planning_filter;
      let data = JSON.parse(JSON.stringify(savedPreferencesplanning));
      const planningFilter = Object.keys(data || "") || []
      let callApiforfilter = false;
      if (planningFilter?.length > 0) {
        if (data?.companies?.length > 0) {
          let ids1 = filterArray?.companies
            ?.filter((twork: any) => twork.isActive === true)
            ?.map((item1: any) => parseInt(item1?.id));
          const filteredcompany = data?.companies?.filter((id: any) =>
            ids1?.includes(id)
          );
          if(filteredcompany?.length > 0){
            setCompanyid(filteredcompany);
            dispatch(storeCompanyIds(filteredcompany))
            setshowcompany("");
          }else{
            callApiforfilter = true;
          }
        }
        if (data?.resources?.length > 0) {
          let ids2 = filterArray?.peoples
            ?.filter((twork: any) => twork.isActive === true)
            ?.map((item1: any) => parseInt(item1?.id));
          const filteredresource = data?.resources?.filter((id: any) =>
            ids2?.includes(id)
          );
          if(filteredresource?.length > 0){
            setPeopletid(filteredresource);
            dispatch(storePeopleIds(filteredresource))
            setshowpeople("");
          }else{
            callApiforfilter = true;
          }
        }
        if (data?.departments?.length > 0) {
          let ids3 = filterArray?.departments?.items
          ?.map((item1: any) => parseInt(item1?.id)); // Convert to integers, if they aren't already  
          const filtereddepartments = data?.departments?.filter((id:any)=>{
              return ids3?.includes(id); 
          });
          if(filtereddepartments > 0){
            setDepartmentid(filtereddepartments);
            dispatch(storeDepartmentIds(filtereddepartments))
            setshowdepartment("");
          }else{
            callApiforfilter = true;
          }
        }
        if (data?.projects?.length > 0) {
      
          let ids4 = filterArray?.projects
            ?.filter((twork: any) => twork.isActive === true)
            ?.map((item1: any) => parseInt(item1?.id));
          const filteredprojects = data?.projects?.filter((id: any) =>
            ids4?.includes(id)
          );
          if(filteredprojects?.length >0){
            setProjectid(filteredprojects);
            dispatch(storeProjectIds(filteredprojects))
            setshowproject("");
            setcurrentClick("Project");
          }else{
            callApiforfilter = true;
          }
        }
        if (data?.roles?.length > 0) {
          let ids5 = filterArray?.roles
            ?.filter((twork: any) => twork.isActive === true)
            ?.map((item1: any) => parseInt(item1?.id));
          const filteredroles = data?.roles?.filter((id: any) =>
            ids5?.includes(id)
          );
          if(filteredroles?.length >0){
            setRoleid(filteredroles);
            dispatch(storeRoleIds(filteredroles))
            setshowrole("");
          }else{
            callApiforfilter = true;
          }
        }
        if (data?.queues_ids?.length > 0) {
          let ids6 = filterArray?.queues?.data
            ?.filter((twork: any) => twork.isActive === true)
            ?.map((item1: any) => parseInt(item1?.id));
          const filteredqueues_ids = data?.queues_ids?.filter((id: any) =>
            ids6?.includes(id)
          );
          if(filteredqueues_ids?.length >0){
            setqueuesid(filteredqueues_ids);
            dispatch(storequeuesids(filteredqueues_ids))
            setshowqueues("");
          }else{
            callApiforfilter = true;
          }
        }
        if(generalSetting?.show_appointments === false){
          data.type_ids = data?.type_ids?.filter((item: any) => item !== 'appointments')
        }
        if (data?.type_ids?.length > 0) {
          setTypeid(data?.type_ids);
          dispatch(storeTypeids(data?.type_ids))
          setshowtype("");
        }
        if (data?.servicecall_status_ids?.length > 0) {
          let ids7 = filterArray?.todo_status_serviceCall
            ?.filter((twork: any) => twork.isActive === true)
            ?.map((item1: any) => parseInt(item1?.id));
          const filteredservicecall_status_ids =
            data?.servicecall_status_ids?.filter((id: any) => ids7?.includes(id));
          setServicecall_statusid(filteredservicecall_status_ids);
          dispatch(storeservicecall_statusids(filteredservicecall_status_ids))
          setshowservicecall_status("");
        }
        if (data?.skills?.length > 0) {
          let ids8 = filterArray?.skills?.data
            ?.filter((twork: any) => twork.isActive === true)
            ?.map((item1: any) => parseInt(item1?.id));
          const filteredskills = data?.skills?.filter((id: any) =>
            ids8?.includes(id)
          );
          if(filteredskills?.length > 0){
          setSkillid(filteredskills);
          dispatch(storeSkillIds(filteredskills))
          setshowskill("");
          }else{
            callApiforfilter = true;
          }
        }
        if (data?.lineOfBusiness?.length > 0) {
          setlineOfBusinessid(data?.lineOfBusiness);
          dispatch(storelineOfBusinessIds(data?.lineOfBusiness))
          setshowlineOfBusiness("");
        }
        let values:any = {};
        values.groups = []
        if (data?.search_conditions?.length > 0) {
          values.groups = data?.search_conditions
          dispatch(storeAppointmentFilter(values));
        }else{
          dispatch(storeAppointmentFilter([]));
        }
        if(callApiforfilter){
          dispatch(setcallApiforfilter(true));
        }
      }
    }
  }, [
    filterArray?.isfetchlayoutandfilters,
    filterArray?.isPeopleFetch,
    filterArray?.isDepartmentFetch,
    filterArray?.isCompanyFetch,
    filterArray?.isProjectFetch,
    filterArray?.isSkillFetch,
    filterArray?.isRoleFetch,
    filterArray?.isQueuesFetch,
    filterArray?.isfetchservicecallstatus,
    filterArray?.islineOfBusinessFetch
  ]);

  useEffect(() => {
    if (!filterArray?.isfetchlayoutandfilters) {
      let savedPreferences = loadFromLocalStorage('planning_layout');
      let savedPreferencesplanning = savedPreferences?.planning_filter || filterArray?.layoutandfilters?.planning_filter;
      const planningFilter = Object.keys(savedPreferencesplanning || "") || []
      if (planningFilter?.length === 0 && !filterArray?.isfetchservicecallstatus) {
        clearAllFilter(false);
        dispatch(setcallApiforfilter(true));
        let sids = filterArray?.todo_status_serviceCall
        ?.filter((s: any) => parseInt(s.value) !== 2 && s.isActive === true)
        ?.map((x: any) => parseInt(x.id))
        setServicecall_statusid(sids);
        const svNameArray = filterArray?.todo_status_serviceCall
            .filter((item: { id: any; }) => sids.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setServicecall_statusName(svNameArray)
        setshowservicecall_status("");
        setcurrentClick("servicecall_status");
        dispatch(storeservicecall_statusids(
          filterArray?.todo_status_serviceCall
            ?.filter((s: any) => parseInt(s.value) !== 2 && s.isActive === true)
            ?.map((x: any) => parseInt(x.id))
        ));
      }
    }
  }, [
    filterArray?.isfetchservicecallstatus,
    filterArray?.isfetchlayoutandfilters,
  ]);

  useEffect(() => {
    if (filterArray?.isfetchlayoutandfilters) {
        clearAllFilter(false);
    }
  }, [filterArray?.isfetchlayoutandfilters]);
  
  useEffect(() => {
    resetSelectedItems(openDropdown)
  },[openDropdown])


  useEffect(() => {
    if (!filterArray?.isfetchlayoutandfilters && !filterArray?.isPeopleFetch && !filterArray?.isDepartmentFetch && !filterArray?.isCompanyFetch && !filterArray?.isProjectFetch && !filterArray?.isSkillFetch && !filterArray?.isRoleFetch && !filterArray?.isQueuesFetch && !filterArray?.isfetchservicecallstatus && !filterArray?.islineOfBusinessFetch) {

        const companyNameArray = filterArray.companies
            .filter((item: { id: any; }) => Companyid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setCompanyName(companyNameArray) 
        
        const resourceNameArray = filterArray.peoples
            .filter((item: { id: any; }) => Peopletid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setPeopletName(resourceNameArray) 

        const departMentNameArray = filterArray.departments.items
            .filter((item: { id: any; }) => Departmentid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setDepartmentName(departMentNameArray)
        
        const projectNameArray = filterArray.projects
            .filter((item: { id: any; }) => Projectid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setProjectName(projectNameArray)

        const roleNameArray = filterArray.roles
            .filter((item: { id: any; }) => Roleid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setRoleName(roleNameArray)

        const queueNameArray = filterArray.queues.data
            .filter((item: { id: any; }) => queuesid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setqueuesName(queueNameArray)

        const typeNameArray = filterArray.status_type
            .filter((item: { id: any; }) => Typeid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setTypeName(typeNameArray)

        const svNameArray = filterArray.todo_status_serviceCall
            .filter((item: { id: any; }) => Servicecall_statusid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setServicecall_statusName(svNameArray)
      
        const skillNameArray = filterArray.skills.data
            .filter((item: { id: any; }) => Skillid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setSkillName(skillNameArray)

        const lineNameArray = filterArray.lineOfBusiness.data
            .filter((item: { id: any; }) => lineOfBusinessid.includes(item.id))
            .map((item: { name: any; }) => item.name)
        setlineOfBusinessName(lineNameArray)
      }
  }, [
    Companyid, Peopletid, Departmentid, Projectid, Roleid, queuesid, Typeid, Servicecall_statusid, Skillid, lineOfBusinessid
  ]);

  const renderFilterTooltip = (
    <Popover id="renderAddUserTooltip">
      <div className="p-5 bg-white card">
        <div>
          Use the Planning Screen Filters to view planned items and appointments based on Company, Resources, Department, etc.
          <a href="https://knowledgebase.proxuma.io/article/planning-screen-filters" className="text-decoration-underline ms-2" target="_blank">
            Learn more
          </a>
        </div>
      </div>
    </Popover>
  )
  return (
    <>
      <div className="dropDown d-flex align-items-center"  id="filters">
        <button
          onClick={()=> toggleDropdown("main-planing-filter")}
          className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-pill px-4 py-0 h-32px fs-16 d-flex align-items-center"
          type="button"
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-start"
          data-kt-menu-flip="bottom"
        >
          <KTSVG
            path="/media/icons/duotune/new-icons/filter.svg"
            className="me-2"
            stylecode={{ color: "inherit" }}
            svgClassName="w-16px h-14px"
          />
          Filter
        </button>
        <div className="h-32px d-flex align-items-start">
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom-start"
            overlay={renderFilterTooltip}
          >
            <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-2" type="button">i</button>
          </OverlayTrigger>
        </div>
        {(showproject === "" ||
          showrole === "" ||
          showpeople === "" ||
          showcompany === "" ||
          showdepartment === "" ||
          showlocation === "" ||
          showskill === "" ||
          showqueues === "" ||
          showtype === "" ||
          showservicecall_status === "" ||
          showlineOfBusiness === "") && (
          <OverlayTrigger
            key="copy-to-clipboard"
            trigger={["hover", "focus"]}
            placement="bottom"
            overlay={
              <Tooltip
                id="tooltip-copy-to-clipboard"
                className="position-absulute"
              >
                <span className="pe-2">Clear all</span>
              </Tooltip>
            }
          >
            <span>
              <button
                value="All"
                onClick={hideSelection}
                className="p-0 bg-transparent border-0 w-auto h-auto ms-3 me-5"
              >
                <KTSVG
                  path="/media/icons/duotune/extra/close.svg"
                  className="text-primary pe-none"
                  svgClassName="w-10px h-10px"
                />
              </button>
            </span>
          </OverlayTrigger>
        )}
        {openDropdown === "main-planing-filter" &&
          <div
            className="menu menu-sub menu-sub-dropdown bg-white px-3 py-5 min-w-130px fs-14 show position-absolute top-100 start-0"
            data-kt-menu="true"
          >
            <a
              onClick={ChangeFilter}
              data-name="people"
              className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap"
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/resources.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Resources
            </a>
            <a
              onClick={ChangeFilter}
              data-name="department"
              className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap"
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/extra/mouse-square.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Department
            </a>
            <a
              data-name="company"
              onClick={ChangeFilter}
              className={`d-flex align-items-center mb-3 text-black fw-semibold text-hover-primary text-nowrap ${view === 'month' || view === "year" ? 'disabled-filter-month-year-view' : ''} `}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/company.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Company
            </a>
            <a
              className={`d-flex align-items-center mb-3 text-black fw-semibold text-hover-primary text-nowrap ${view === 'month' || view === "year" ? 'disabled-filter-month-year-view' : ''} `}
              data-name="project"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/project.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Project
            </a>
            <a
              className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap"
              data-name="role"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/role.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Role
            </a>
            <a
              className={`d-flex align-items-center mb-3 text-black fw-semibold text-hover-primary text-nowrap ${view === 'month' || view === "year" ? 'disabled-filter-month-year-view' : ''} `}
              data-name="queues"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/queues.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Queues
            </a>
            <a
              className={`d-flex align-items-center mb-3 text-black fw-semibold text-hover-primary text-nowrap ${view === 'month' || view === "year" ? 'disabled-filter-month-year-view' : ''} `}
              data-name="type"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/type.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Type
            </a>
            <a
              className={`d-flex align-items-center mb-3 text-black fw-semibold text-hover-primary text-nowrap ${view === 'month' || view === "year" ? 'disabled-filter-month-year-view' : ''} `}
              data-name="servicecall_status"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/new-icons/service-call-center.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Servicecall status
            </a>

            {generalSetting?.show_resource_skills === true && (
              <a
                className="d-flex align-items-center text-black fw-semibold mb-3 text-hover-primary text-nowrap"
                data-name="Skills"
                onClick={ChangeFilter}
                href="#"
              >
                <KTSVG
                  path="/media/icons/duotune/new-icons/skills.svg"
                  stylecode={{ color: "#164387" }}
                  className="w-20px me-1"
                  svgClassName="w-16px h-auto"
                />
                Skills
              </a>
            )}
            <a
              className={`d-flex align-items-center mb-3 text-black fw-semibold text-hover-primary text-nowrap ${view === 'month' || view === "year" ? 'disabled-filter-month-year-view' : ''} `}
              data-name="LineOfBusiness"
              onClick={ChangeFilter}
              href="#"
            >
              <KTSVG
                path="/media/icons/duotune/extra/note.svg"
                stylecode={{ color: "#164387" }}
                className="w-20px me-1"
                svgClassName="w-16px h-auto"
              />
              Line of business
            </a>
          </div>
        }
      </div>
      {showpeople === "" &&
        <DropDown
          imgpath="/media/icons/duotune/extra/profile-user-2.svg"
          inputHandler={inputHandler}
          inputText={peopleinputText}
          currentClick={currentClick}
          checked_ids={Peopletid}
          title="Resources"
          id="People"
          data={filterArray?.peoples?.filter(
            (twork: any) => twork.isActive === true
          )}
          isLoading={filterArray?.isPeopleFetch}
          showdropdown={showpeople}
          hideSelection={hideSelection}
          storeFilterValue={storeFilterValue}
          handleChange={handleChange}
          selectedName={PeopletName}
          selectAll={selectAll}
          tooltipTitle="Filter resources on name."
          isOpen={openDropdown === 'People'}
          toggleDropdown={toggleDropdown}
        ></DropDown>
      }
      {showdepartment === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/mouse-square.svg"
        inputHandler={inputHandler}
        inputText={departmentinputText}
        currentClick={currentClick}
        checked_ids={Departmentid}
        title="Department"
        id="Department"
        data={filterArray?.departments?.items}
        isLoading={filterArray?.isDepartmentFetch}
        showdropdown={showdepartment}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={DepartmentName}
        selectAll={selectAll}
        tooltipTitle="Filter resources on their default department."
        isOpen={openDropdown === 'Department'}
        toggleDropdown={toggleDropdown}
      ></DropDown>
      }
      {showcompany === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/profile-user-1.svg"
        inputHandler={inputHandler}
        inputText={companyinputText}
        currentClick={currentClick}
        checked_ids={Companyid}
        title="Company"
        id="Company"
        data={filterArray?.companies?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.isCompanyFetch}
        showdropdown={showcompany}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={CompanyName}
        selectAll={selectAll}
        view={view}
        tooltipTitle="Filter resources on their company."
        isOpen={openDropdown === 'Company'}
        toggleDropdown={toggleDropdown}
      ></DropDown>
      }
      {showproject === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={projectinputText}
        currentClick={currentClick}
        checked_ids={Projectid}
        title="Project"
        id="Project"
        data={filterArray?.projects?.filter(
          (twork: any) => twork.isActive === true && twork.isCompleted === false
        )}
        isLoading={filterArray?.isProjectFetching}
        showdropdown={showproject}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={ProjectName}
        selectAll={selectAll}
        view={view}
        tooltipTitle="Filter resources on their project."
        isOpen={openDropdown === 'Project'}
        toggleDropdown={toggleDropdown}
      ></DropDown>
      }
      {showrole === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={roleinputText}
        currentClick={currentClick}
        checked_ids={Roleid}
        title="Role"
        id="Role"
        data={filterArray?.roles?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.isRoleFetch}
        showdropdown={showrole}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={RoleName}
        selectAll={selectAll}
        tooltipTitle="Filter resources on their roles."
        isOpen={openDropdown === 'Role'}
        toggleDropdown={toggleDropdown}
      ></DropDown>
      }
      {showqueues === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={queuesinputText}
        currentClick={currentClick}
        checked_ids={queuesid}
        title="Queues"
        id="Queues"
        data={filterArray?.queues?.data?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.isQueuesFetch}
        showdropdown={showqueues}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={queuesName}
        selectAll={selectAll}
        view={view}
        tooltipTitle="Filter resources on their queues."
        isOpen={openDropdown === 'Queues'}
        toggleDropdown={toggleDropdown}
      ></DropDown>
      }
      {showtype === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={typeinputText}
        currentClick={currentClick}
        checked_ids={Typeid}
        title="Type"
        id="Type"
        data={filterArray?.status_type}
        isLoading={false}
        showdropdown={showtype}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={TypeName}
        selectAll={selectAll}
        view={view}
        tooltipTitle="Filter data on their type."
        isOpen={openDropdown === 'Type'}
        toggleDropdown={toggleDropdown}
      ></DropDown>
      }
      {showservicecall_status === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={servicecall_statusinputText}
        currentClick={currentClick}
        checked_ids={Servicecall_statusid}
        title="Servicecall status"
        id="servicecall_status"
        data={filterArray?.todo_status_serviceCall?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.isfetchservicecallstatus}
        showdropdown={showservicecall_status}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={Servicecall_statusName}
        selectAll={selectAll}
        view={view}
        tooltipTitle="Filter data on their status."
        isOpen={openDropdown === 'servicecall_status'}
        toggleDropdown={toggleDropdown}
      ></DropDown>
      }
      {showskill === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={skillinputText}
        currentClick={currentClick}
        checked_ids={Skillid}
        title="Skills"
        id="Skills"
        data={filterArray?.skills?.data?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.isSkillFetch}
        showdropdown={showskill}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={SkillName}
        selectAll={selectAll}
        tooltipTitle="Filter data on their skills."
        isOpen={openDropdown === 'Skills'}
        toggleDropdown={toggleDropdown}
      ></DropDown>
      }
      {showlineOfBusiness === "" &&
      <DropDown
        imgpath="/media/icons/duotune/extra/note.svg"
        inputHandler={inputHandler}
        inputText={lineOfBusinessinputText}
        currentClick={currentClick}
        checked_ids={lineOfBusinessid}
        title="Line of business"
        id="LineOfBusiness"
        data={filterArray?.lineOfBusiness?.data?.filter(
          (twork: any) => twork.isActive === true
        )}
        isLoading={filterArray?.islineOfBusinessFetch}
        showdropdown={showlineOfBusiness}
        hideSelection={hideSelection}
        storeFilterValue={storeFilterValue}
        handleChange={handleChange}
        selectedName={lineOfBusinessName}
        selectAll={selectAll}
        view={view}
        tooltipTitle="Filter data on their line of business."
        isOpen={openDropdown === 'LineOfBusiness'}
        toggleDropdown={toggleDropdown}
      ></DropDown>
      }
    </>
  );
};

export default FilterMain;
