export const ANIMATION_START = 'animationstart';
export const BLUR = 'blur';
export const CHANGE = 'change';
export const CLICK = 'click';
export const CONTEXTMENU = 'contextmenu';
export const DOUBLE_CLICK = 'dblclick';
export const FOCUS = 'focus';
export const FOCUS_IN = 'focusin';
export const INPUT = 'input';
export const KEY_DOWN = 'keydown';
export const MOUSE_DOWN = 'mousedown';
export const MOUSE_MOVE = 'mousemove';
export const MOUSE_UP = 'mouseup';
export const MOUSE_OVER = 'mousedown';
export const MOUSE_ENTER = 'mouseenter';
export const MOUSE_LEAVE = 'mouseleave';
export const MOUSE_WHEEL = 'mousewheel';
export const RESIZE = 'resize';
export const SCROLL = 'scroll';
export const TOUCH_START = 'touchstart';
export const TOUCH_MOVE = 'touchmove';
export const TOUCH_END = 'touchend';
export const TOUCH_CANCEL = 'touchcancel';
export const WHEEL = 'wheel';
