import { doc, getWindow, listen, matches, unlisten } from '../../util/dom';
import { BLUR, CHANGE, CLICK, FOCUS, KEY_DOWN, MOUSE_DOWN } from '../../util/events';
import { ENTER, SPACE } from '../../util/keys';
import { isString, noop } from '../../util/misc';
import { PickerBase } from './picker';

// tslint:disable no-non-null-assertion

function getIonInput(el: any, cb: (inp: HTMLInputElement) => void, nr = 0) {
  // Give up after multiple tries, and return the ion-input element
  if (nr > 10) {
    delete el.__mbscTimer;
    cb(el);
  } else {
    clearTimeout(el.__mbscTimer);
    el.__mbscTimer = setTimeout(() => {
      // IonInput might not be fully read yet, so if getInputElement is not yet there, try once again
      if (el.getInputElement) {
        el.getInputElement().then((inp: HTMLInputElement) => {
          // In Safari the input element is sometimes not set on the first call, so try once again
          if (inp) {
            delete el.__mbscTimer;
            cb(inp);
          } else {
            getIonInput(el, cb, nr + 1);
          }
        });
      } else {
        getIonInput(el, cb, nr + 1);
      }
    }, 10);
  }
}

function isIonInput(el: any) {
  return el.getInputElement || (el.tagName && el.tagName.toLowerCase() === 'ion-input');
}

export function getNativeElement(input: any, callback: (input: HTMLInputElement) => void) {
  if (input) {
    if (isIonInput(input)) {
      getIonInput(input, callback);
    } else if (input.vInput) {
      // if it's Mobiscroll Input (Angular)
      callback(input.vInput.nativeElement);
    } else if (input._el) {
      // if it's Mobiscroll Input (React)
      callback(input._el);
    } else if (input.instance && input.instance._el) {
      // if it's Mobiscroll Input (Vue)
      callback(input.instance._el);
    } else if (input.nodeType === 1) {
      // The element must be a HTMLElement
      callback(input);
    } else if (isString(input)) {
      // It's a query string
      const inputElement = doc!.querySelector(input);
      if (inputElement) {
        callback(inputElement as HTMLInputElement);
      }
    }
  }
}

export function initPickerElement(
  el: HTMLElement,
  inst: PickerBase,
  handleChange?: (ev: any) => void,
  handleClick?: (ev: any) => void,
): () => void {
  // The element must be a HTMLElement, but might be something else,
  // if a custom component was passed through the component option
  if (!el || el.nodeType !== 1) {
    return noop;
  }

  const setReadOnly = () => {
    if ((inst.s.showOnClick || inst.s.showOnFocus) && isInput && !inst._allowTyping) {
      // Set input to readonly
      input.readOnly = true;
    }
  };

  const onClick = (ev: any) => {
    const s = inst.s;
    // Needed if the label of the input was clicked
    setReadOnly();
    if (handleClick) {
      handleClick(ev);
    }
    if (s.showOnClick && !s.disabled && (!inst._popup!._isVisible || el !== inst._popup!._prevFocus)) {
      setTimeout(() => {
        inst._focusElm = el;
        inst._anchor = s.anchor || el;
        inst.open();
      });
    }
  };

  const onMouseDown = (ev: any) => {
    if (inst.s.showOnClick) {
      if (inst.s.showOnFocus) {
        // Delay showing on click instead of focus, otherwise the document click will not close any previous popup
        inst._preventShow = true;
      }
      if (!inst._allowTyping) {
        // Prevent input focus
        ev.preventDefault();
      }
    }
  };

  const onKeyDown = (ev: any) => {
    if (inst.s.showOnClick) {
      if (inst._isOpen) {
        // Prevent closing the picker on input enter key
        if (ev.keyCode === ENTER && inst._allowTyping) {
          ev.stopPropagation();
        }
      } else {
        if (ev.keyCode === SPACE) {
          ev.preventDefault();
        }
        // Open the picker on space or enter
        if (ev.keyCode === ENTER || ev.keyCode === SPACE) {
          onClick(ev);
        }
      }
    }
  };

  const onFocus = (ev: any) => {
    setReadOnly();
    if (inst.s.showOnFocus) {
      if (inst._preventShow) {
        inst._preventShow = false;
      } else {
        onClick(ev);
      }
    }
  };

  const onBlur = () => {
    if (isInput) {
      // Reset original readonly state
      input.readOnly = readOnly;
    }
  };

  const onChange = (ev: any) => {
    if (handleChange) {
      handleChange(ev);
    }
  };

  const onWinFocus = () => {
    if (win!.document.activeElement === el) {
      setReadOnly();
      inst._preventShow = true;
    }
  };

  const win = getWindow(el);
  const isInput = matches(el, 'input,select');
  const input = el as HTMLInputElement;
  let readOnly: boolean;

  if (isInput) {
    input.autocomplete = 'off';
    // Save original readonly state
    readOnly = input.readOnly;
  }

  listen(el, CLICK, onClick);
  listen(el, MOUSE_DOWN, onMouseDown);
  listen(el, KEY_DOWN, onKeyDown);
  listen(el, FOCUS, onFocus);
  listen(el, BLUR, onBlur);
  listen(el, CHANGE, onChange);
  listen(win, FOCUS, onWinFocus);

  return () => {
    if (isInput) {
      // Reset original readonly state
      input.readOnly = readOnly;
    }
    unlisten(el, CLICK, onClick);
    unlisten(el, MOUSE_DOWN, onMouseDown);
    unlisten(el, KEY_DOWN, onKeyDown);
    unlisten(el, FOCUS, onFocus);
    unlisten(el, BLUR, onBlur);
    unlisten(el, CHANGE, onChange);
    unlisten(win, FOCUS, onWinFocus);
  };
}
