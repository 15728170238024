// ColumnSizeComponent.js
import React, { FC, useEffect, useState } from 'react';

interface IColumnSizeComponent {
    view: any;
    currentPath: any;
    formik: any;

}

const ColumnSizeComponent:FC<IColumnSizeComponent> = ({view  , currentPath , formik}) => {
    const newPath = currentPath ?  formik?.values?.PlannigViewData?.[view]: formik.values?.ProjectViewData?.[view]
    const viewPage = currentPath ? 'PlannigViewData' : 'ProjectViewData' ; 
    const [selectedOption, setSelectedOption] = useState(newPath?.columnSize?.isColumnWidth ? 'columnWidth' : 'hoursPerColumn');
    useEffect(() => {
        setSelectedOption(newPath?.columnSize?.isColumnWidth ? 'columnWidth' : 'hoursPerColumn');
    }, [newPath?.columnSize?.isColumnWidth]);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        formik.setFieldValue(`${viewPage}.${view}.columnSize.isColumnWidth`, option === 'columnWidth');
    };
    return (
        <div className="d-flex flex-column mb-10">
            <h3 className="text-dark fw-bold fs-18 m-0 mb-4">Column size</h3>
            <div className="bg-gray-100 rounded-8px p-5 form-check-outline-primary-all">

                <div className="form-check form-check-outline-primary form-check-custom form-check-solid h-30px mb-5 fs-16 d-none">
                    <input
                        className="form-check-input me-2"
                        name="columnSizeOption"
                        type="radio"
                        id="columnWidth"
                        checked={selectedOption === 'columnWidth'}
                        onChange={() => handleOptionChange('columnWidth')}
                    />
                    <label htmlFor="columnWidth">Column width</label>

                  {newPath?.columnSize?.isColumnWidth ? <select
                        className="form-select fs-16 fw-normal h-32px p-0 px-4 ps-3 bg-white border-graybor w-80px text-gray-900 ms-auto"
                        value={newPath?.columnSize?.columnWidth}
                        onChange={(e) => {
                            formik.setFieldValue(`${viewPage}.${view}.columnSize.columnWidth`, e.target.value);
                          }}
                    >
                        <option value="50">50%</option>
                        <option value="100">100%</option>
                    </select>  : ''}  
                </div>
                {view!=="month" && view!=="year"  && (
 <div className="form-check form-check-outline-primary form-check-custom form-check-solid h-30px fs-16">
 <input
     className="form-check-input me-2"
     name="columnSizeOption"
     type="radio"
     id="hoursColumn"
     checked={selectedOption === 'hoursPerColumn'}
     onChange={() => handleOptionChange('hoursPerColumn')}
 />
 <label htmlFor="hoursPerColumn">Hours per column</label>
 {
   selectedOption === 'hoursPerColumn' 
   ?   
     <select
         id="hoursPerColumn"
         className="form-select fs-16 fw-normal h-32px p-0 px-4 ps-3 bg-white border-graybor w-80px text-gray-900 ms-auto"
         disabled={newPath?.columnSize?.isColumnWidth}
         value={newPath?.columnSize?.hoursPerColumn}
         onChange={(e) => {
             formik.setFieldValue(`${viewPage}.${view}.columnSize.hoursPerColumn`, e.target.value);
         }}
     >
         <option value="60">1</option>
         <option value="120">2</option>
         <option value="180">3</option>
         <option value="240">4</option>
     </select>
  :
     ''
 }
</div>
                )}
               

            </div>
        </div>
    );
};

export default ColumnSizeComponent;
