import React from 'react';
import { Fragment, isVue, ON_CONTEXT_MENU } from '../../../../react/renderer';
import { isString, UNDEFINED } from '../../../util/misc';
import { MbscScheduleEventOptions, ScheduleEventBase } from './schedule-event';

export function template(s: MbscScheduleEventOptions, inst: ScheduleEventBase): any {
  const event = s.event;
  const isAllDay = inst._isAllDay;
  const isTimeline = s.isTimeline;
  const rtl = inst._rtl;
  const theme = inst._theme;
  const editable = s.resize && event.original!.editable !== false;
  const rightClick = { [ON_CONTEXT_MENU]: inst._onRightClick };

  const renderBufferBefore = () => {
    let content: any;
    let html: any;
    if (s.renderBufferBefore) {
      content = s.renderBufferBefore(event);
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }

    return (
      <div
        className={
          `mbsc-${inst._host}-event-buffer mbsc-${inst._host}-event-buffer-before` +
          (content ? '' : ' mbsc-schedule-event-buffer-background') +
          theme +
          rtl
        }
        style={inst._bufferStyleStart}
        dangerouslySetInnerHTML={isVue ? UNDEFINED : html}
      >
        {content}
      </div>
    );
  };

  const renderBufferAfter = () => {
    let content: any;
    let html: any;

    if (s.renderBufferAfter) {
      content = s.renderBufferAfter(event);
      if (isString(content)) {
        html = inst._safeHtml(content);
        inst._shouldEnhance = true;
      }
    }

    return (
      <div
        className={
          `mbsc-${inst._host}-event-buffer mbsc-${inst._host}-event-buffer-after` +
          (content ? '' : ' mbsc-schedule-event-buffer-background') +
          theme +
          rtl
        }
        style={inst._bufferStyleEnd}
        dangerouslySetInnerHTML={isVue ? UNDEFINED : html}
      >
        {content}
      </div>
    );
  };

  return (
    <div
      tabIndex={0}
      className={inst._cssClass}
      data-id={event.id}
      style={inst._style}
      ref={inst._setEl}
      title={event.tooltip}
      onClick={inst._onClick}
      {...rightClick}
    >
      {inst._isStart && event.bufferBefore && renderBufferBefore()}
      {inst._isEnd && event.bufferAfter && renderBufferAfter()}
      {inst._isStart && editable && (
        <div
          className={
            'mbsc-schedule-event-resize mbsc-schedule-event-resize-start' +
            (isTimeline ? ' mbsc-timeline-event-resize' : '') +
            (s.isDrag ? ' mbsc-schedule-event-resize-start-touch' : '') +
            rtl
          }
        />
      )}

      {inst._isEnd && editable && (
        <div
          className={
            'mbsc-schedule-event-resize mbsc-schedule-event-resize-end' +
            (isTimeline ? ' mbsc-timeline-event-resize' : '') +
            (s.isDrag ? ' mbsc-schedule-event-resize-end-touch' : '') +
            rtl
          }
        />
      )}

      {s.render && !inst._isMore ? (
        // Full custom template (_content is vDOM markup, _html is string)
        inst._html ? (
          <div style={{ height: '100%' }} dangerouslySetInnerHTML={isVue ? UNDEFINED : inst._html} />
        ) : (
          inst._content
        )
      ) : (
        // Default template
        <Fragment>
          {!isAllDay && !isTimeline && !inst._isMore && <div className={'mbsc-schedule-event-bar' + theme + rtl} />}
          <div
            className={
              'mbsc-schedule-event-background' +
              (isTimeline ? ' mbsc-timeline-event-background' : '') +
              (isAllDay ? ' mbsc-schedule-event-all-day-background' : '') +
              theme
            }
            style={{ background: event.style.background }}
          />
          <div
            aria-hidden="true"
            className={
              'mbsc-schedule-event-inner' + theme + (isAllDay ? ' mbsc-schedule-event-all-day-inner' : '') + (event.cssClass || '')
            }
            style={{ color: event.style.color }}
          >
            <div
              className={'mbsc-schedule-event-title' + (isAllDay ? ' mbsc-schedule-event-all-day-title' : '') + theme}
              dangerouslySetInnerHTML={isVue ? UNDEFINED : inst._html}
            >
              {isVue ? inst._content || inst._html : inst._content}
            </div>
            {!isAllDay && !inst._isMore && <div className={'mbsc-schedule-event-range' + theme}>{inst._rangeText}</div>}
          </div>
          {event.ariaLabel && <div className="mbsc-hidden-content">{event.ariaLabel}</div>}
        </Fragment>
      )}
      {/* TRIAL */}
    </div>
  );
}

export class ScheduleEvent extends ScheduleEventBase {
  protected _template(s: MbscScheduleEventOptions): any {
    return template(s, this);
  }
}
