
import { BaseComponent, IBaseProps } from '../../base';

// tslint:disable directive-class-suffix
// tslint:disable directive-selector

// tslint:disable-next-line interface-name
export interface MbscListHeaderOptions extends IBaseProps {
  theme?: string;
}

/** @hidden */

export class ListHeaderBase extends BaseComponent<MbscListHeaderOptions, any> {
  // tslint:disable variable-name
  public _cssClass?: string;

  protected _render(s: MbscListHeaderOptions) {
    this._cssClass = this._className + ' mbsc-list-header' + this._theme + this._hb;
  }
}
