import React, { FC, useEffect } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

const Error404: FC = () => {

  const disableSplashScreen = () => {
    const splashScreen = document.getElementById("splash-screen");
    if (splashScreen) {
      splashScreen.style.setProperty("display", "none");
    }
  };

  useEffect(() => {
    disableSplashScreen()
  }, [])


  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-center flex-column-fluid p-10">
        {/* begin::Illustration */}
        <img
          src={toAbsoluteUrl("/media/illustrations/sketchy-1/18.png")}
          alt=""
          className="mw-100 mb-10 h-lg-450px"
        />
        {/* end::Illustration */}
        {/* begin::Message */}
        <h1 className="fw-bold mb-10" style={{ color: "#A3A3C7" }}>
          Seems there is nothing here
        </h1>
      </div>
    </div>
  );
};

export { Error404 };
