import React from 'react';
import { ON_KEY_DOWN } from '../../../react/renderer';
import { UNDEFINED } from '../../util/misc';
import { MbscDatetimeOptions } from '../datetime/datetime';
import { TimegridBase } from './timegrid';

import './timegrid.scss';

export function template(s: MbscDatetimeOptions, inst: TimegridBase): any {
  const keydown = { [ON_KEY_DOWN]: inst._onKeyDown };
  return (
    <div className={inst._cssClass} ref={inst._setCont}>
      {inst._timeSlots.map((arr, rowIndex) => {
        return (
          <div className="mbsc-timegrid-row" key={rowIndex}>
            {arr.map((v, cellIndex) => {
              const disabled = inst._isDisabled(v.value);
              return (
                <div className={'mbsc-timegrid-cell' + (disabled ? ' mbsc-disabled' : '')} key={cellIndex}>
                  {/* TRIALMULTI(rowIndex*3+cellIndex) */}
                  <div
                    className={
                      'mbsc-timegrid-item' +
                      (inst._value === v.value ? ' mbsc-selected' : '') +
                      (disabled ? ' mbsc-disabled' : '') +
                      inst._theme
                    }
                    // tslint:disable-next-line: jsx-no-lambda
                    onClick={() => inst._setTime(v)}
                    tabIndex={disabled ? UNDEFINED : 0}
                    data-timeslot={v.value}
                    {...keydown}
                  >
                    {v.formattedValue}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export class Timegrid extends TimegridBase {
  protected _template(s: MbscDatetimeOptions): any {
    return template(s, this);
  }
}
