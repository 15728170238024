import { FC } from "react";
import { Dashboard } from "../../modules/apps/dashboard-management/core/_model";
import { CardTiels } from "./CardTiels";

type Props = {
  data: Dashboard;
  isLoading: boolean;
};

const StatisticsListWrapper: FC<Props> = ({ data, isLoading }) => {

  const companyData = [{ title: "Active company", count: data ? data?.active_company : 0 }];
  const companyCountData = { isShowCount: true, count: data ? data?.all_company_count : 0 };
  const projectData = [{ title: "Active project", count: data ? data?.active_project : 0 }, { title: "To Do", count: data ? data?.to_do_projects : 0 }];
  const projectCountData = { isShowCount: true, count: data ? data?.all_project_count : 0 };
  const resourceData = [{ title: "Available today", count: data ? data?.today_resources : 0 }, { title: "Available this week", count: data ? data?.week_resources : 0 }, { title: "Available this month", count: data ? data?.month_resources : 0 }];

  return (
    <>
      {/* begin::Row */}
      <div className="row h-100">
        <CardTiels title="All companies" data={companyData} countData={companyCountData} isLoading={isLoading} icon={'/media/icons/duotune/extra/briefcase.svg'}></CardTiels>
        <CardTiels title="Projects" data={projectData} countData={projectCountData} isLoading={isLoading} icon={'/media/icons/duotune/extra/archive-book.svg'}></CardTiels>
        <CardTiels title="Resources" data={resourceData} isLoading={isLoading} icon={'/media/icons/duotune/extra/profile-user.svg'} cardClassName="col-xl-12  mb-xxl-0"></CardTiels>
      </div>
      {/* end::Row */}
    </>
  )
};

export { StatisticsListWrapper };