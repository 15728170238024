
import { BaseComponent, IBaseProps } from '../../../base';
import { MbscCalendarEventData } from '../../../shared/calendar-view/calendar-view.types';
import { addDays, addTimezone, getDayMilliseconds, getEndDate, isInWeek, isSameDay } from '../../../util/datetime';
import { MbscTimezonePlugin } from '../../../util/datetime.types.public';
import { getDocument, listen, unlisten } from '../../../util/dom';
import { MOUSE_DOWN, TOUCH_START } from '../../../util/events';
import { gestureListener } from '../../../util/gesture';
import { BACKSPACE, DELETE, ENTER, SPACE } from '../../../util/keys';
import { isString, UNDEFINED } from '../../../util/misc';
import { Observable } from '../../../util/observable';
import { ICalendarEventDragArgs } from '../shared/schedule-timeline-base.types';
import { DEF_ID } from '../shared/schedule-timeline-base.util';

const stateObservables: { [key: string]: Observable<any> } = {};

// tslint:disable no-non-null-assertion
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

// tslint:disable-next-line interface-name
export interface MbscScheduleEventOptions extends IBaseProps {
  contentTemplate?: any;
  displayTimezone?: string;
  drag?: boolean;
  endDay: number;
  event: MbscCalendarEventData;
  eventHeight?: number;
  exclusiveEndDates?: boolean;
  gridEndTime: number;
  gridStartTime: number;
  hasResY?: boolean;
  hidden?: boolean;
  inactive?: boolean;
  isDrag?: boolean;
  isListing?: boolean;
  isTimeline?: boolean;
  lastDay: number;
  resize?: boolean;
  resource?: number | string;
  selected?: boolean;
  singleDay?: boolean;
  slot?: number | string;
  startDay: number;
  template?: any;
  timezonePlugin?: MbscTimezonePlugin;
  onClick?(arg: any): void;
  onDoubleClick?(arg: any): void;
  onHoverIn?(arg: any): void;
  onHoverOut?(arg: any): void;
  onRightClick?(arg: any): void;
  onDelete?(arg: any): void;
  onDragEnd?(arg: any): void;
  onDragModeOff?(args: any): void;
  onDragModeOn?(args: any): void;
  onDragMove?(arg: any): void;
  onDragStart?(arg: any): void;
  renderBufferAfter?(event: MbscCalendarEventData): any;
  renderBufferBefore?(event: MbscCalendarEventData): any;
  renderContent?(event: MbscCalendarEventData): any;
  render?(event: MbscCalendarEventData): any;
}

// tslint:disable-next-line interface-name
export interface MbscScheduleEventState {
  hasHover?: boolean;
  hasFocus?: boolean;
}

/** @hidden */

export class ScheduleEventBase extends BaseComponent<MbscScheduleEventOptions, MbscScheduleEventState> {
  // tslint:disable variable-name
  public _bufferStyleStart?: { height?: string; width?: string };
  public _bufferStyleEnd?: { height?: string; width?: string };
  public _content?: any;
  public _cssClass?: string;
  public _html?: any;
  public _host?: string;
  public _isStart?: boolean;
  public _isEnd?: boolean;
  public _rangeText?: string;
  public _style?: any;
  public _isAllDay?: boolean;
  public _isMore?: boolean;

  private _doc?: Document;
  private _isDrag?: boolean;
  private _text?: string;
  private _unlisten?: () => void;
  private _unsubscribe?: number;
  // tslint:enable variable-name

  // tslint:disable-next-line: variable-name
  public _onClick = (ev: any) => {
    this._triggerClick('onClick', ev);
    const s = this.s;
    const observable = stateObservables[s.event.uid!];
    if (observable && s.selected) {
      observable.next({ hasFocus: false });
    }
  };

  // tslint:disable-next-line: variable-name
  public _onRightClick = (ev: any) => {
    this._triggerClick('onRightClick', ev);
  };

  // tslint:disable-next-line: variable-name
  protected _onDocTouch = (ev: any) => {
    unlisten(this._doc, TOUCH_START, this._onDocTouch);
    unlisten(this._doc, MOUSE_DOWN, this._onDocTouch);
    this._isDrag = false;
    this._hook('onDragModeOff', {
      domEvent: ev,
      event: this.s.event.original,
    });
  };

  protected _render(s: MbscScheduleEventOptions, state: MbscScheduleEventState) {
    const event = s.event;
    const day = new Date(event.date);
    const pos = event.position;
    const startDate = event.startDate;
    const endDate = getEndDate(s, event.allDay, startDate, event.endDate);
    const isTimeline = s.isTimeline;
    const isTimelineListing = s.isListing;
    // tslint:disable-next-line: no-string-literal
    const isMore = event.original!['more']; // TODO
    const isAllDay = (isMore && isTimeline) || isTimelineListing || event.allDay;
    const isMultiDay = !isSameDay(startDate, endDate);
    const isMultiDayStart = isMultiDay && isSameDay(startDate, day);
    const isMultiDayEnd = isMultiDay && isSameDay(endDate, day);
    const allDayStyle = isAllDay && (!isTimeline || isTimelineListing || isMore);
    const host = isTimeline ? 'timeline' : 'schedule';
    const gridStartTime = s.gridStartTime;
    const gridEndTime = s.gridEndTime;
    const startTime = getDayMilliseconds(startDate);
    const endTime = getDayMilliseconds(endDate);
    const hasSlots = isTimeline && s.slot !== DEF_ID;
    const isEndInWeek = isInWeek(endDate.getDay(), s.startDay, s.endDay);
    let lastDay = s.singleDay ? addDays(day, 1) : new Date(s.lastDay);

    if (!event.allDay) {
      lastDay = addTimezone(s, lastDay);
    }

    this._isStart = hasSlots || !isMultiDay || isMultiDayStart;
    this._isEnd = hasSlots || !isMultiDay || (isAllDay || (isTimeline && !s.hasResY) ? endDate < lastDay && isEndInWeek : isMultiDayEnd);

    if (!hasSlots && !isAllDay && (gridStartTime > startTime || gridEndTime < startTime)) {
      this._isStart = false;
    }

    if (!hasSlots && !isAllDay && (gridEndTime < endTime || gridStartTime > endTime)) {
      this._isEnd = false;
    }

    this._isMore = isMore;
    this._isDrag = this._isDrag || s.isDrag;
    this._content = UNDEFINED;
    this._rangeText = event.start + ' - ' + event.end;
    this._isAllDay = allDayStyle;
    this._host = host;

    if (event.allDay || ((!isTimeline || s.hasResY) && isMultiDay && !isMultiDayStart && !isMultiDayEnd)) {
      this._rangeText = event.allDayText || '\u00A0';
    }

    if (event.bufferBefore) {
      this._bufferStyleStart = isTimeline ? { width: event.bufferBefore } : { height: event.bufferBefore };
    }

    if (event.bufferAfter) {
      this._bufferStyleEnd = isTimeline ? { width: event.bufferAfter } : { height: event.bufferAfter };
    }

    this._cssClass =
      'mbsc-schedule-event' +
      this._theme +
      this._rtl +
      (s.render || s.template ? ' mbsc-schedule-event-custom' : '') +
      (isTimeline ? ' mbsc-timeline-event' : '') +
      (isTimelineListing || isMore ? ' mbsc-timeline-event-listing' : '') +
      (this._isStart ? ` mbsc-${host}-event-start` : '') +
      (this._isEnd ? ` mbsc-${host}-event-end` : '') +
      (allDayStyle ? ' mbsc-schedule-event-all-day' : '') +
      (hasSlots ? ' mbsc-timeline-event-slot' : '') +
      ((state.hasFocus && !s.inactive && !s.selected) || s.selected ? ' mbsc-schedule-event-active' : '') +
      (state.hasHover && !s.inactive && !this._isDrag ? ' mbsc-schedule-event-hover' : '') +
      (s.isDrag ? ' mbsc-schedule-event-dragging' + (isTimeline ? ' mbsc-timeline-event-dragging' : '') : '') +
      (s.hidden ? ' mbsc-schedule-event-hidden' : '') +
      (s.inactive ? ' mbsc-schedule-event-inactive' : '') +
      (event.original!.editable === false ? ' mbsc-readonly-event' : '') +
      (event.original!.cssClass ? ' ' + event.original!.cssClass : '');

    this._style = {
      ...pos,
      color: event.color,
      top: s.eventHeight && pos.top !== UNDEFINED ? pos.top * s.eventHeight + 'px' : pos.top,
    };

    const renderer = s.render || s.renderContent;
    let text: string | undefined;

    if (renderer && !isMore) {
      const content = renderer(event);
      if (isString(content)) {
        text = content;
      } else {
        this._content = content;
      }
    } else if (!s.contentTemplate || isMore) {
      text = event.html;
    }

    if (text !== this._text) {
      this._text = text;
      this._html = text ? this._safeHtml(text) : UNDEFINED;
      this._shouldEnhance = text && !!renderer;
    }
  }

  protected _mounted() {
    const id = this.s.event.uid!;
    const el = this._el;
    let resizeDir: 'start' | 'end' | undefined;
    let observable = stateObservables[id];
    let startDomEvent: any;
    let touchTimer: any;

    if (!observable) {
      observable = new Observable<any>();
      stateObservables[id] = observable;
    }

    this._unsubscribe = observable.subscribe(this._updateState);
    this._doc = getDocument(el);
    this._unlisten = gestureListener(el, {
      keepFocus: true,
      onBlur: () => {
        observable.next({ hasFocus: false });
      },
      onDoubleClick: (ev) => {
        // Prevent event creation on label double click
        ev.domEvent.stopPropagation();
        this._triggerClick('onDoubleClick', ev.domEvent);
      },
      onEnd: (ev) => {
        if (this._isDrag) {
          const s = this.s;
          const args: ICalendarEventDragArgs = { ...ev };
          // Will prevent mousedown event on doc
          args.domEvent.preventDefault();
          args.eventData = s.event;
          args.resource = s.resource;
          args.slot = s.slot;

          if (s.resize && resizeDir) {
            args.resize = true;
            args.direction = resizeDir;
          } else if (s.drag) {
            args.drag = true;
          }

          this._hook('onDragEnd', args);

          // Turn off update, unless we're in touch update mode
          if (!s.isDrag) {
            this._isDrag = false;
          }

          if (el && args.moved) {
            el.blur();
          }
        }
        clearTimeout(touchTimer);
        resizeDir = UNDEFINED;
      },
      onFocus: () => {
        observable.next({ hasFocus: true });
      },
      onHoverIn: (ev: any) => {
        observable.next({ hasHover: true });
        this._triggerClick('onHoverIn', ev);
      },
      onHoverOut: (ev: any) => {
        observable.next({ hasHover: false });
        this._triggerClick('onHoverOut', ev);
      },
      onKeyDown: (ev: any) => {
        const event = this.s.event.original!;
        switch (ev.keyCode) {
          case ENTER:
          case SPACE:
            el.click();
            ev.preventDefault();
            break;
          case BACKSPACE:
          case DELETE:
            if (event.editable !== false) {
              this._hook('onDelete', {
                domEvent: ev,
                event,
                resource: this.s.resource,
                slot: this.s.slot,
                source: this._host,
              });
            }
            break;
        }
      },
      onMove: (ev) => {
        const s = this.s;
        const args: ICalendarEventDragArgs = { ...ev };

        args.eventData = s.event;
        args.resource = s.resource;
        args.slot = s.slot;

        if (resizeDir) {
          args.resize = true;
          args.direction = resizeDir;
        } else if (s.drag) {
          args.drag = true;
        } else {
          return;
        }

        if (s.event.original!.editable === false) {
          return;
        }

        if (this._isDrag || !args.isTouch) {
          // Prevents page scroll on touch and text selection with mouse
          args.domEvent.preventDefault();
        }

        if (this._isDrag) {
          this._hook('onDragMove', args);
        } else if (Math.abs(args.deltaX) > 7 || Math.abs(args.deltaY) > 7) {
          clearTimeout(touchTimer);
          if (!args.isTouch) {
            args.domEvent = startDomEvent;
            this._isDrag = true;
            this._hook('onDragStart', args);
          }
        }
      },
      onStart: (ev) => {
        startDomEvent = ev.domEvent;
        const s = this.s;
        const args: ICalendarEventDragArgs = { ...ev };
        const target: HTMLElement = startDomEvent.target;

        args.eventData = s.event;
        args.resource = s.resource;
        args.slot = s.slot;

        if (s.resize && target.classList.contains('mbsc-schedule-event-resize')) {
          resizeDir = target.classList.contains('mbsc-schedule-event-resize-start') ? 'start' : 'end';
          args.resize = true;
          args.direction = resizeDir;
        } else if (s.drag) {
          args.drag = true;
        } else {
          return;
        }

        if (s.event.original!.editable === false) {
          return;
        }

        if (this._isDrag) {
          startDomEvent.stopPropagation();
          this._hook('onDragStart', args);
        } else if (args.isTouch) {
          touchTimer = setTimeout(() => {
            this._hook('onDragModeOn', args);
            this._hook('onDragStart', args);
            this._isDrag = true;
          }, 350);
        }
      },
    });

    if (this._isDrag) {
      listen(this._doc, TOUCH_START, this._onDocTouch);
      listen(this._doc, MOUSE_DOWN, this._onDocTouch);
    }
  }

  protected _destroy() {
    if (this._el) {
      this._el.blur();
    }
    if (this._unsubscribe) {
      const id = this.s.event.uid!;
      const observable = stateObservables[id];
      if (observable) {
        observable.unsubscribe(this._unsubscribe);
        if (!observable.nr) {
          delete stateObservables[id];
        }
      }
    }
    if (this._unlisten) {
      this._unlisten();
    }
    unlisten(this._doc, TOUCH_START, this._onDocTouch);
    unlisten(this._doc, MOUSE_DOWN, this._onDocTouch);
  }

  // tslint:disable-next-line: variable-name
  private _updateState = (args: any) => {
    this.setState(args);
  };

  private _triggerClick(name: string, domEvent: any) {
    const s = this.s;
    this._hook(name, {
      date: s.event.date,
      domEvent,
      event: s.event.original,
      resource: s.resource,
      slot: s.slot,
      source: this._host,
    });
  }
}
