import { Response } from "../../../../../_metronic/helpers";

export type Tooltip = {
    _id?: any;
    element: string
    type: string
    page: string
    intro?: string
    title?: string
    // priority?: string
    tooltipClass?: string
    highlightClass?: string
    position?: string
    scrollTo?: string
    // scrollToElement?: boolean
    disableScrolling?: boolean
    hint?: string
    hintPosition?: string
}
export type IntroJsQueryResponse = Response<Array<Tooltip>>

export const initialTooltip: Tooltip = {
    _id: '',
    element: '',
    type: '',
    page: '',
    intro: '',
    title: '',
    // priority: '',
    tooltipClass: '',
    highlightClass: '',
    position: '',
    scrollTo: '',
    hint: '',
    hintPosition: '',
}
