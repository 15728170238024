import { FC } from "react";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { IntrojsSearchComponent } from "./IntrojsSearchComponent";
import { useListView } from "../../core/ListViewProvider";



const IntrojsToolbar: FC = () => {
  const { setItemIdForUpdate } = useListView();
  const openUserModel = () => {
    setItemIdForUpdate(null);
  };
  return (
    <>
      <h2 className="mt-3 mb-6">Introjs Elements</h2>
      <div
        className="d-flex col-12 justify-content-between flex-column flex-lg-row"
        data-kt-user-table-toolbar="base"
      >
        <IntrojsSearchComponent />
        <button
          type="button"
          className="btn btn-primary mt-6 mt-lg-0 w-150px w-lg-auto"
          onClick={openUserModel}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr075.svg"
            className="svg-icon-2"
          />
          Add Tour/Hint(s)
        </button>
      </div>
    </>
  );
};

export { IntrojsToolbar };
