import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";
import { confirmOrganisation } from "../../../../_metronic/requests/RegistrationRequest";
import { useQuery } from "react-query";
import { Loading } from "../../../components/loading/Loading";

const ConfirmRegister: FC = () => {
    const search = useLocation().search;
    let token = new URLSearchParams(search).get("token");

    const initialValues = {
        token: token,
      };

    const {
        isFetching,
        data: confirmdata,
      } = useQuery(
        'confirm-register',
        async () => {
          return await confirmOrganisation(initialValues);
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
      );

    return (

        <>
        {/* Added logo and sidebar */}
        <div className="d-flex flex-column flex-root login" id="kt_app_root">
      <div className="d-flex flex-column flex-lg-row flex-column-fluid">
        <div
          className="d-flex align-items-end justify-content-center w-40 bgi-size-cover bgi-position-center p-10 p-sm-15 loginLeft position-relative"
          style={{
            backgroundImage: `url(${toAbsoluteUrl("/media/misc/login-bg.jpg")})`,
          }}
        >
          {/* <div className="logoName text-white fs-1">Proxuma</div> */}
          <div className="position-absolute top-50 start-50 translate-middle">        
            <img
                alt='Proxuma'
                className='h-auto logo mw-100 mh-100'
                src={toAbsoluteUrl('/media/logos/logo.png')}
            />
          </div>
        </div>

        <div className="d-flex flex-column flex-grow-1 justify-content-md-center flex-lg-row-fluid w-60 p-10 pt-sm-15 pb-sm-15 loginForm">
          <div className="d-flex flex-center flex-column flex-lg-row-fluid">

          
            {/* Confirm Registration Form */}
            {!isFetching &&
                <div className="mb-8">
                        <h1 className='text-dark fw-bold h2 fs-1 text-center'>
                          {confirmdata ?  (
                          <> You are registered successfully. Company is awaiting approval.
                         <br /> 
                         You will be notified by e-mail when your request has been processed.
                         </>
                        ) : (
                        "Session Expired!"
                        )}
                        
                        </h1>
                        

            {/* END :: Confirm Registration Form */}
            </div>
            }
            <Loading isLoading={isFetching}
            isHideSpinner={true}></Loading>
            </div>
            </div>
        </div>
        </div>
        {/* END :: Added logo and sidebar */}
    </>
);
}
export default ConfirmRegister;
