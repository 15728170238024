import { createRoot } from "react-dom/client";
// Axios
import axios from "axios";
import { Chart, registerables } from "chart.js";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
// Apps
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";

/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/plugins.scss";
import "./_metronic/assets/sass/style.react.scss";
import { AppRoutes } from "./app/routing/AppRoutes";
import { setupAxios } from "./app/modules/auth";
import { PermifyProvider } from "@permify/react-role";
import { RecoilRoot } from "recoil";
import { Provider } from "react-redux";
import { store } from "./app/store";
import '@aws-amplify/ui-react/styles.css';
import { SubdomainProvider } from "./app/context/SubdomainContext";
import { URLCheckProvider } from './app/context/URLCheckContext';
import CognitoPage from "./app/components/cognito/CognitoPage";
import { React } from "react";
import { AuthProvider } from "./app/modules/auth/core/AuthCognito";
let subdomain = window.location.hostname.split(".")[0];
let authSubDomain = process.env.REACT_APP_AUTHENTICATION_SUBDOMAIN;
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios);
Chart.register(...registerables);

const queryClient = new QueryClient();
const container = document.getElementById("root");
if (container) {
  let data;

  createRoot(container).render(
    (subdomain === process.env.REACT_APP_AUTHENTICATION_SUBDOMAIN) ?
      (<QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <CognitoPage></CognitoPage>
        </Provider>
      </QueryClientProvider>
      )
      : (
        <SubdomainProvider>
            <URLCheckProvider>
              <QueryClientProvider client={queryClient}>
                <MetronicI18nProvider>
                  <AuthProvider>
                    <PermifyProvider />
                    <RecoilRoot>
                      <Provider store={store}>
                        <AppRoutes />
                      </Provider>
                    </RecoilRoot>
                  </AuthProvider>
                </MetronicI18nProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
          </URLCheckProvider>
        </SubdomainProvider>
      )
  );
}
