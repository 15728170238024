
import { MbscCalendarDayData } from '../../../shared/calendar-view/calendar-day';
import { createDate, isMBSCDate, ONE_MIN } from '../../../util/datetime';
import { jsPrefix } from '../../../util/dom';
import { floor, isString, UNDEFINED } from '../../../util/misc';
import { MbscSchedulerTimezone } from '../eventcalendar.types';
import { STBase } from '../shared/schedule-timeline-base';
import { ISTOptions, ISTState } from '../shared/schedule-timeline-base.types';

// tslint:disable no-non-null-assertion
// tslint:disable no-inferrable-types
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

// TODO:
// Type drag event args

export interface ISchedulerOptions extends ISTOptions {
  groupBy?: 'date' | 'resource';
  timezones?: Array<MbscSchedulerTimezone | string> | undefined;
  type: 'month' | 'week' | 'day';
  renderDayContent?(args: MbscCalendarDayData): any;
  onWeekDayClick(arg: any): void;
}

export interface ISchedulerState extends ISTState {
  showShadow?: boolean;
}

/** @hidden */

export class SchedulerBase extends STBase<ISchedulerOptions, ISchedulerState> {
  // tslint:disable variable-name
  public _largeDayNames!: boolean;
  public _timeWidth?: { width: string };
  public _timezones?: MbscSchedulerTimezone[];

  protected _allDayCont?: HTMLElement | null;
  protected _timeCont?: HTMLElement | null;

  // tslint:disable-next-line: variable-name
  public _onScroll = () => {
    const grid = this._scrollCont;
    if (grid) {
      const scrollTop = grid.scrollTop;
      const scrollLeft = 'translateX(' + -grid.scrollLeft + 'px)';
      const timeCont = this._timeCont;
      const allDay = this._allDayCont;
      const header = this._headerCont;
      const transform = (jsPrefix ? jsPrefix + 'T' : 't') + 'ransform';

      if (allDay) {
        (allDay.style as any)[transform] = scrollLeft;
      }

      if (timeCont) {
        timeCont.style.marginTop = -scrollTop + 'px';
      }

      if (header) {
        (header.style as any)[transform] = scrollLeft;
      }

      if (scrollTop === 0) {
        this.setState({ showShadow: false });
      } else if (!this.state.showShadow) {
        this.setState({ showShadow: true });
      }

      this._onMouseMove();
    }
  };

  // tslint:disable-next-line: variable-name
  public _setCont = (el: any) => {
    this._scrollCont = el;
  };

  // tslint:disable-next-line: variable-name
  public _setTimeCont = (el: any) => {
    this._timeCont = el;
  };

  // tslint:disable-next-line: variable-name
  public _setAllDayCont = (el: any) => {
    this._allDayCont = el;
  };

  // tslint:disable-next-line: variable-name
  public _setGridCont = (el: any) => {
    this._gridCont = el;
  };

  // tslint:disable-next-line: variable-name
  public _setHeaderCont = (el: any) => {
    this._headerCont = el;
  };

  // tslint:disable-next-line: variable-name
  public _setCursorTimeCont = (el: any) => {
    this._cursorTimeCont = el;
  };

  protected _render(s: ISchedulerOptions, state: ISchedulerState) {
    super._render(s, state);

    const prevS = this._prevS;
    const timezones = s.timezones;
    const stepCell = this._stepCell / ONE_MIN;
    const startMinutes = floor(this._startTime / ONE_MIN) % stepCell;
    const endMinutes = (floor(this._endTime / ONE_MIN) % stepCell) + 1;

    if (timezones !== prevS.timezones) {
      this._timeWidth = timezones ? { width: timezones.length * 4.25 + 'em' } : UNDEFINED;
      this._timezones = UNDEFINED;
      if (timezones) {
        const tz = [];
        for (const t of timezones) {
          let tzProps: MbscSchedulerTimezone;
          if (isString(t)) {
            const d = createDate(s, 1970, 0, 1);
            if (isMBSCDate(d)) {
              d.setTimezone(t);
            }
            const offset = (d.getTimezoneOffset() / 60) * -1;
            tzProps = {
              label: 'UTC' + (offset > 0 ? '+' : '') + offset,
              timezone: t,
            };
          } else {
            tzProps = t;
          }
          tz.push(tzProps);
        }
        this._timezones = tz;
      }
    }

    this._largeDayNames = state.dayNameWidth! > 99;

    this._startCellStyle =
      startMinutes % stepCell !== 0
        ? {
            height: (state.cellHeight || 50) * (((stepCell - startMinutes) % stepCell) / stepCell) + 'px',
          }
        : UNDEFINED;

    this._endCellStyle =
      endMinutes % stepCell !== 0
        ? {
            height: ((state.cellHeight || 50) * (endMinutes % stepCell)) / stepCell + 'px',
          }
        : UNDEFINED;
  }
}
