
import { MbscResource } from '../../../shared/calendar-view/calendar-view.types';
import { ONE_MIN } from '../../../util/datetime';
import { hasSticky } from '../../../util/dom';
import { constrain, floor, round, UNDEFINED } from '../../../util/misc';
import { MbscSlotData } from '../eventcalendar.types';
import { STBase } from '../shared/schedule-timeline-base';
import { IDayData, ISTOptions, ISTState } from '../shared/schedule-timeline-base.types';

// tslint:disable no-non-null-assertion
// tslint:disable no-inferrable-types
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

export interface ITimelineOptions extends ISTOptions {
  dayTemplate?: any;
  type: 'week' | 'day' | 'month' | 'year';
  renderSlot?(args: MbscSlotData): any;
}

export interface ITimelineState extends ISTState {
  dayIndex: number;
}

export interface IConnectionData {
  color?: string;
  cssClass?: string;
  fill?: string;
  id: string;
  pathD: string;
  startDate: Date;
  endDate: Date;
}

/** @hidden */

export class TimelineBase extends STBase<ITimelineOptions, ITimelineState> {
  // tslint:disable variable-name
  public _isTimeline = true;
  public _connections?: IConnectionData[];
  public _stickyDay!: IDayData;

  protected _stickyHeader!: HTMLElement | null;
  protected _stickyDate!: HTMLElement | null;
  protected _stickyMonth!: HTMLElement | null;
  protected _stickyWeek!: HTMLElement | null;

  private _scrollDebounce: any;

  // tslint:enable variable-name

  // tslint:disable-next-line: variable-name
  public _onScroll = () => {
    const s = this.s;
    const isRtl = s.rtl;
    const state = this.state;
    const gridWidth = this._gridWidth;
    const scrollCont = this._scrollCont!;
    const scrollTop = scrollCont.scrollTop;
    const scrollLeft = scrollCont.scrollLeft;
    const resCont = this._resCont;
    const sidebarCont = this._sidebarCont;
    const footer = this._footerCont;
    const header = this._headerCont;
    const stickyHeader = this._stickyHeader;
    const stickyFooter = this._stickyFooter;
    const days = this._days;
    const daysNr = this._daysNr;
    const colsNr = this._cols.length;
    const rtl = isRtl ? -1 : 1;
    const margin = isRtl ? 'marginRight' : 'marginLeft';
    const batchIndexX = round((scrollLeft * rtl * (colsNr / this._daysBatchNr)) / gridWidth);
    const dayWidth = gridWidth / daysNr;
    const dayIndex = dayWidth ? constrain(floor((scrollLeft * rtl) / dayWidth), 0, daysNr - 1) : 0;

    // Vertical virtual page index
    const virtualPagesY = this._virtualPagesY || [];
    let batchIndexY = 0;
    let i = 0;
    while (i < virtualPagesY.length && virtualPagesY[i].top - state.scrollContHeight! / 2 <= scrollTop) {
      batchIndexY = i;
      i++;
    }

    // RTL issue https://bugs.chromium.org/p/chromium/issues/detail?id=1140374
    if (!hasSticky || isRtl) {
      if (resCont) {
        resCont.scrollTop = scrollTop;
      }
      if (sidebarCont) {
        sidebarCont.scrollTop = scrollTop;
      }
    }

    if (stickyHeader && hasSticky) {
      // Update the sticky header position to handle scroll bounce on touch devices
      const headerStyle = stickyHeader.style;
      headerStyle.marginTop = scrollTop < 0 ? -scrollTop + 'px' : '';
      headerStyle[margin] = scrollLeft * rtl < 0 ? -scrollLeft * rtl + 'px' : '';
    }

    if (stickyFooter && hasSticky) {
      // Update the sticky footer position to handle scroll bounce on touch devices
      const footerStyle = stickyFooter.style;
      footerStyle.marginTop = scrollTop < 0 ? -scrollTop + 'px' : '';
      footerStyle[margin] = scrollLeft * rtl < 0 ? -scrollLeft * rtl + 'px' : '';
    }

    if (!gridWidth) {
      return;
    }

    if ((header || footer) && this._isDailyResolution) {
      const updateStickyLabel = (label: HTMLElement | null, key: string) => {
        if (label && dayWidth) {
          const labelWidth = label.offsetWidth;
          const labelStyle = label.style;
          const nextDayIndex = constrain(floor((scrollLeft * rtl + labelWidth) / dayWidth), 0, daysNr - 1);

          if ((days[dayIndex] as any)[key + 'Index'] !== (days[nextDayIndex] as any)[key + 'Index']) {
            labelStyle[margin] = -(scrollLeft * rtl + labelWidth - (days[nextDayIndex] as any)[key + 'Index'] * dayWidth + 1) + 'px';
          } else {
            labelStyle[margin] = '';
          }
        }
      };

      updateStickyLabel(this._stickyDate, 'date');
      updateStickyLabel(this._stickyMonth, 'month');
      updateStickyLabel(this._stickyWeek, 'week');

      if (!hasSticky) {
        if (footer) {
          footer.scrollLeft = scrollLeft;
        }
        if (header) {
          header.scrollLeft = scrollLeft;
        }
      }
    }

    if (
      batchIndexX !== state.batchIndexX ||
      batchIndexY !== state.batchIndexY ||
      ((this._stickyDate || this._stickyMonth || this._stickyWeek) && dayIndex !== state.dayIndex)
    ) {
      this.setState({ batchIndexX, batchIndexY, dayIndex });
    }

    clearTimeout(this._scrollDebounce);
    this._scrollDebounce = setTimeout(() => {
      if (!this._isScrolling && !this._viewChanged && !this._hasResY) {
        // const time = ((scrollLeft * rtl + this.state.gridContWidth! / 2) * this._time * this._daysNr) / gridWidth;
        const day = days[dayIndex].date;
        const ms = (this._time * (scrollLeft * rtl - dayIndex * dayWidth)) / dayWidth;
        this._hook('onActiveChange', { date: new Date(+day + this._startTime + ms), scroll: true });
      }
    }, 100);

    this._onMouseMove();
  };

  // tslint:disable-next-line: variable-name
  public _onParentClick(domEvent: any, resource: MbscResource) {
    resource.collapsed = !resource.collapsed;
    this._hook(resource.collapsed ? 'onResourceCollapse' : 'onResourceExpand', { domEvent, resource: resource.id });
    this._visibleResources = this._flattenResources(this._resourcesCopy, [], 0);
    this._shouldCheckSize = true;
    this._isParentClick = true;
    this.forceUpdate();
  }

  // tslint:disable-next-line: variable-name
  public _setStickyHeader = (el: any) => {
    this._stickyHeader = el;
  };

  // tslint:disable-next-line: variable-name
  public _setStickyFooter = (el: any) => {
    this._stickyFooter = el;
  };

  // tslint:disable-next-line: variable-name
  public _setStickyDay = (el: any) => {
    this._stickyDate = el;
  };

  // tslint:disable-next-line: variable-name
  public _setStickyMonth = (el: any) => {
    this._stickyMonth = el;
  };

  // tslint:disable-next-line: variable-name
  public _setStickyWeek = (el: any) => {
    this._stickyWeek = el;
  };

  // tslint:disable-next-line: variable-name
  public _setCont = (el: any) => {
    this._scrollCont = el;
  };

  // tslint:disable-next-line: variable-name
  public _setResCont = (el: any) => {
    this._resCont = el;
  };

  // tslint:disable-next-line: variable-name
  public _setSidebarCont = (el: any) => {
    this._sidebarCont = el;
  };

  // tslint:disable-next-line: variable-name
  public _setGridCont = (el: any) => {
    this._gridCont = el;
  };

  // tslint:disable-next-line: variable-name
  public _setHeaderCont = (el: any) => {
    this._headerCont = el;
  };

  // tslint:disable-next-line: variable-name
  public _setFooterCont = (el: any) => {
    this._footerCont = el;
  };

  // tslint:disable-next-line: variable-name
  public _setCursorTimeCont = (el: any) => {
    this._cursorTimeCont = el;
  };

  protected _render(s: ITimelineOptions, state: ITimelineState) {
    super._render(s, state);

    clearTimeout(this._scrollDebounce);

    const prevS = this._prevS;
    const eventMap = this._eventMap;
    const resourceTops = this._resourceTops;
    const stepCell = this._stepCell / ONE_MIN;
    const startMinutes = floor(this._startTime / ONE_MIN) % stepCell;
    const endMinutes = (floor(this._endTime / ONE_MIN) % stepCell) + 1;

    this._stickyDay = this._days[state.dayIndex || 0] || this._days[0];

    this._startCellStyle =
      startMinutes % stepCell !== 0
        ? {
            width: (state.cellWidth || 64) * (((stepCell - startMinutes) % stepCell) / stepCell) + 'px',
          }
        : UNDEFINED;

    this._endCellStyle =
      endMinutes % stepCell !== 0
        ? {
            width: ((state.cellWidth || 64) * (endMinutes % stepCell)) / stepCell + 'px',
          }
        : UNDEFINED;

    if (s.connections !== prevS.connections || s.eventMap !== prevS.eventMap || s.theme !== prevS.theme || s.rtl !== prevS.rtl) {
      this._calcConnections = true;
    }

    if (this._hasSlots) {
      this._connections = UNDEFINED;
    }

    if (this._calcConnections && !this._hasSlots && !this._shouldCheckSize && resourceTops) {
      const connections: IConnectionData[] = [];
      const eventHeight = this._eventHeight;
      const gridWidth = this._gridWidth;
      const gridHeight = state.hasScrollY ? this._gridHeight : state.scrollContHeight! - state.headerHeight!;
      const constLineH = 1500 / gridWidth; // 15 px converted to percent - horizontal
      const isRtl = s.rtl === true;
      const rtl = isRtl ? -1 : 1;
      const arrowH = (750 / gridWidth) * rtl;
      const arrowV = (400 / gridHeight) * rtl;
      const eventHeightInPercent = (100 * eventHeight) / gridHeight;

      for (const connection of s.connections || []) {
        const fromEvent = eventMap[connection.from];
        const toEvent = eventMap[connection.to];
        const arrow = connection.arrow;
        const color = connection.color;
        const cssClass = connection.cssClass || '';
        const id = connection.from + '__' + connection.to;
        const type = connection.type || 'fs';

        if (fromEvent && toEvent) {
          const fromPos = fromEvent.position;
          const toPos = toEvent.position;
          const hasFromPos = fromPos.width !== UNDEFINED;
          const hasToPos = toPos.width !== UNDEFINED;
          const fromResource = fromEvent.resource as string;
          const toResource = toEvent.resource as string;

          // one of the to/from positions should be calculated (present on the view)
          if ((hasFromPos || hasToPos) && resourceTops[fromResource] >= 0 && resourceTops[toResource] >= 0) {
            const isFirstEnd = type === 'fs' || type === 'ff';
            const isSecondStart = type === 'fs' || type === 'ss';
            const from = isFirstEnd ? fromEvent.endDate : fromEvent.startDate;
            const to = isSecondStart ? toEvent.startDate : toEvent.endDate;
            const isToBefore = to < from;
            const startDate = isToBefore ? to : from;
            const endDate = isToBefore ? from : to;
            const fromTop = fromPos.top || 0;
            const toTop = toPos.top || 0;
            const positionProp = isRtl ? 'right' : 'left';
            const fromLeft = hasFromPos ? +fromPos[positionProp].replace('%', '') : isToBefore ? 100 : 0;
            const toLeft = hasToPos ? +toPos[positionProp].replace('%', '') : isToBefore ? 0 : 100;
            const fromWidth = hasFromPos ? +fromPos.width.replace('%', '') : 0;
            const toWidth = hasFromPos ? +toPos.width.replace('%', '') : 0;
            const resourceTopsDiff = resourceTops[toResource] - resourceTops[fromResource];
            const avoidLinethrough =
              !resourceTopsDiff &&
              ((type === 'fs' && isToBefore) || (type === 'sf' && !isToBefore) || type === 'ff' || type === 'ss') &&
              toTop === fromTop;
            const lineWidth =
              isFirstEnd && isSecondStart // fs
                ? toLeft - fromLeft - fromWidth - 2 * constLineH
                : isFirstEnd && !isSecondStart // ff
                ? toLeft - fromLeft + (toWidth - fromWidth)
                : !isFirstEnd && isSecondStart // ss
                ? toLeft - fromLeft
                : toLeft - fromLeft + toWidth + 2 * constLineH; // sf
            const toUpperResource = resourceTopsDiff < 0 || (!resourceTopsDiff && toTop < fromTop) ? -1 : 1;
            const lineHeight =
              (100 *
                (resourceTopsDiff -
                  fromTop * eventHeight + // - fromEvent top position
                  toTop * eventHeight + // + toEvent top position
                  (avoidLinethrough ? eventHeight : 0))) /
              gridHeight;
            const reversedHLine =
              (type === 'fs' && lineWidth < 0) || ((type === 'ff' || type === 'ss') && avoidLinethrough) || type === 'sf';
            const drawHorizontalFirst =
              (type === 'ss' && isToBefore && !reversedHLine) ||
              (type === 'ff' && !isToBefore && !reversedHLine) ||
              (type === 'sf' && lineWidth < 0);

            let posX = (isRtl ? 100 - fromLeft : fromLeft) + (isFirstEnd ? fromWidth * rtl : 0);
            let posY = (100 * (resourceTops[fromResource] + fromTop * eventHeight + 3 + eventHeight / 2)) / gridHeight;

            if (hasFromPos && (arrow === 'from' || arrow === 'bidirectional')) {
              const computedArrowH = isFirstEnd ? arrowH : arrowH * -1;
              connections.push({
                color,
                cssClass: 'mbsc-connection-arrow ' + cssClass,
                endDate,
                fill: color,
                id: id + '__start',
                pathD: `M ${posX}, ${posY} L ${posX + computedArrowH} ${posY - arrowV} L ${posX + computedArrowH} ${posY + arrowV} Z`,
                startDate,
              });
            }

            // set the starting position
            let pathD = `M ${posX}, ${posY}`;

            // adding the starting line
            posX += isFirstEnd ? constLineH * rtl : -constLineH * rtl;

            if (drawHorizontalFirst) {
              posX += lineWidth * rtl;
            }
            // adding second line if there is one
            if (lineHeight) {
              pathD += ` H ${posX}`;
              if (!drawHorizontalFirst) {
                posY += lineHeight - (reversedHLine ? eventHeightInPercent / 2 : 0) * toUpperResource;
                pathD += ` V ${posY}`;
              }
            }

            // adding the horizontal line that connects the two events.
            if (!drawHorizontalFirst) {
              posX += lineWidth * rtl;
            }

            if (lineHeight) {
              pathD += ` H ${posX}`;
              if (drawHorizontalFirst) {
                posY += lineHeight - (reversedHLine ? eventHeightInPercent / 2 : 0) * toUpperResource;
                pathD += ` V ${posY}`;
              }
            }

            // adding the second vertical section if needed
            if (lineHeight && reversedHLine) {
              posY += (eventHeightInPercent / 2) * toUpperResource * (avoidLinethrough ? -1 : 1);
              pathD += ` V ${posY}`;
            }

            // adding the ending line
            posX += isSecondStart ? constLineH * rtl : -constLineH * rtl;
            pathD += ` H ${posX}`;

            connections.push({ color, cssClass, id, pathD, startDate, endDate });

            if (hasToPos && (arrow === 'to' || arrow === 'bidirectional' || arrow === true)) {
              const computedArrowH = isSecondStart ? arrowH * -1 : arrowH;
              connections.push({
                color,
                cssClass: 'mbsc-connection-arrow ' + cssClass,
                endDate,
                fill: color,
                id: id + '__end',
                pathD: `M ${posX}, ${posY} L ${posX + computedArrowH} ${posY - arrowV} L ${posX + computedArrowH} ${posY + arrowV} Z`,
                startDate,
              });
            }
          }
        }
      }
      this._connections = connections;
      this._calcConnections = false;
    }
  }
}
