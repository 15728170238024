/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";

import { Tooltip } from "../../core/_types";

type Props = {
  introJs: Tooltip;
};

const IntrojsInfoCell: FC<Props> = ({ introJs }) => {

  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-column">{introJs?.element}</div>
    </div>
  );
};

export { IntrojsInfoCell };
