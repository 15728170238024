/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  initialQueryState,
  KTSVG,
  useDebounce,
} from "../../../_metronic/helpers";
import { useQueryRequest } from "../core/QueryRequestProvider";

const ListSearchComponent = () => {
  const { updateState } = useQueryRequest();
  const [showActive, setShowActive] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 150);
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        updateState({ search: debouncedSearchTerm, ...initialQueryState });
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  );

  useEffect(() => {
    updateState({
      filter: {
        show_active: showActive ? 1 : 0
      },
      ...initialQueryState
    })

  }, [showActive]);

  return (
    <div className="card-title m-0">
      <div className="form-check form-switch form-switch-lg form-check-custom form-check-solid me-5">
        <input
          className="form-check-input"
          type="checkbox"
          id="show-active"
          checked={showActive}
          onChange={() => setShowActive(!showActive)}
        />
        <label className="form-check-label h5 mt-1" for="show-active">
           Show Active User
        </label>
      </div>

      {/* begin::Search */}
      <div
        className="w-250px searchBox d-flex align-items-center rounded-4px"
        style={{ background: "#EEF0F4" }}
      >
        <KTSVG
          path="/media/icons/duotune/extra/search.svg"
          className="w-40px h-32px d-inline-flex align-items-center justify-content-center"
          svgClassName="w-16px h-auto"
        />
        <input
          type="text"
          data-kt-user-table-filter="search"
          className="form-control form-control-flush p-0 fw-normal fs-16 h-32px text-black ps-0 pe-2 placeholderGray"
          placeholder="Search"
          value={searchTerm}
          onChange={(e: { target: { value: any } }) =>
            setSearchTerm(e.target.value)
          }
        />
      </div>
      {/* end::Search */}
    </div>
  );
};

export { ListSearchComponent };
