/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";

export function SettingSideBar() {
	return (
		<>
			
			<ul className="nav nav-pills  px-3" id="myTab" role="tablist">
				<li className="nav-item">
					<NavLink className="nav-link"  to="general">General</NavLink>
				</li>
				<li className="nav-item">
					<NavLink className="nav-link"  to="admin-media">Media</NavLink>
				</li>
				<li className="nav-item">
					<NavLink className="nav-link" to="security">Security</NavLink>
				</li>
				<li className="nav-item">
					<NavLink className="nav-link" to="email-setting">SMTP-gegevens</NavLink>
				</li>
				<li className="nav-item">
					<NavLink className="nav-link" to="invoice">Invoice</NavLink>
				</li>
			</ul>

		</>
	);
}
