
import { BaseComponent, IBaseProps } from '../../base';
import { cssPrefix } from '../../util/dom';
import { gestureListener } from '../../util/gesture';
import { ENTER, SPACE } from '../../util/keys';
import { UNDEFINED } from '../../util/misc';

// tslint:disable directive-class-suffix
// tslint:disable directive-selector

/** @hidden */
export interface IWheelItemProps extends IBaseProps {
  active: boolean;
  angle3d: number;
  data: any;
  disabled: boolean;
  height: number;
  index: number;
  is3d: boolean;
  isGroup?: boolean;
  multiple: boolean;
  offset: number;
  renderItem?: any;
  rows: number;
  scroll3d: boolean;
  selected: boolean;
  text: string;
  // update: any;
  checkmark?: boolean;
  onClick?(args: any): void;
}

export interface IWheelItemState {
  hasHover: boolean;
  hasFocus: boolean;
  isActive: boolean;
}

/** @hidden */

export class WheelItemBase extends BaseComponent<IWheelItemProps, IWheelItemState> {
  // tslint:disable variable-name
  public _cssClass?: string;
  public _style?: { [key: string]: string };
  public _checkmarkClass?: string;
  public _transform: any;

  private _unlisten: any;
  // tslint:enable variable-name

  // tslint:disable-next-line: variable-name
  public _onClick = () => {
    const s = this.s;
    if (s.text !== UNDEFINED && !s.isGroup) {
      this._hook('onClick', { index: s.index, selected: s.selected, disabled: s.disabled });
    }
  };

  protected _mounted() {
    this._unlisten = gestureListener(this._el, {
      click: true,
      keepFocus: false,
      onBlur: () => {
        this.setState({ hasFocus: false });
      },
      onFocus: () => {
        this.setState({ hasFocus: true });
      },
      onHoverIn: () => {
        if (this.s.text !== UNDEFINED) {
          this.setState({ hasHover: true });
        }
      },
      onHoverOut: () => {
        if (this.s.text !== UNDEFINED) {
          this.setState({ hasHover: false });
        }
      },
      onKeyDown: (ev: any) => {
        if (ev.keyCode === SPACE || (!this.s.multiple && ev.keyCode === ENTER)) {
          this._onClick();
        }
      },
      onPress: () => {
        if (this.s.text !== UNDEFINED) {
          this.setState({ isActive: true });
        }
      },
      onRelease: () => {
        if (this.s.text !== UNDEFINED) {
          this.setState({ isActive: false });
        }
      },
    });
  }

  protected _destroy() {
    this._unlisten();
  }

  protected _render(s: IWheelItemProps, state: IWheelItemState) {
    const height = s.height;

    this._cssClass =
      'mbsc-scroller-wheel-' +
      (s.isGroup ? 'header' : 'item') +
      this._theme +
      this._rtl +
      (s.checkmark && !s.isGroup ? ' mbsc-wheel-item-checkmark' : '') +
      (s.is3d ? ' mbsc-scroller-wheel-item-3d' : '') +
      (s.scroll3d && !s.is3d ? ' mbsc-scroller-wheel-item-2d' : '') +
      (s.selected && !s.is3d ? ' mbsc-selected' : '') +
      (s.selected && s.is3d ? ' mbsc-selected-3d' : '') +
      (s.disabled ? ' mbsc-disabled' : '') +
      (s.multiple && !s.isGroup ? ' mbsc-wheel-item-multi' : '') +
      (state.hasHover ? ' mbsc-hover' : '') +
      (state.hasFocus ? ' mbsc-focus' : '') +
      (state.isActive ? ' mbsc-active' : '');

    this._style = {
      height: height + 'px',
      lineHeight: height + 'px',
    };

    this._checkmarkClass = this._theme + this._rtl + ' mbsc-wheel-checkmark' + (s.selected ? ' mbsc-selected' : '');

    if (s.is3d) {
      this._transform = 'rotateX(' + (((s.offset - s.index) * s.angle3d) % 360) + 'deg) translateZ(' + (height * s.rows) / 2 + 'px)';
      this._style[cssPrefix + 'transform'] = this._transform;
    }
  }
}
