import { useIntl } from "react-intl";
import { AsideMenuItem } from "./AsideMenuItem";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";

export function AsideMenuSuperadmin() {
  const intl = useIntl();

  return (
    <>
      <AsideMenuItem
        to="/superadmin/dashboard"
        icon="/media/icons/duotune/extra/chart.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />
      <AsideMenuItem
        to="/superadmin/organisation-management/organisations"
        icon="/media/icons/duotune/art/organisations.svg"
        title="Organisations"
        fontIcon="bi-layers"
      />
      <AsideMenuItem
        to="/superadmin/role-management/roles"
        icon="/media/icons/duotune/art/roles.svg"
        title="Roles"
        fontIcon="bi-layers"
      />
      <AsideMenuItem
        to="/superadmin/user-management/users"
        icon="/media/icons/duotune/art/users.svg"
        title="Users"
        fontIcon="bi-layers"
      />
      <AsideMenuItem
        to="/superadmin/mail-template/email"
        icon="/media/icons/duotune/art/email.svg"
        title="Email Templates"
        fontIcon="bi-layers"
      />
      <AsideMenuItem
        to="/superadmin/general-settings/"
        icon="/media/icons/duotune/art/setting.svg"
        title="General Settings"
        fontIcon="bi-layers"
      />
      <AsideMenuItemWithSub
        to="/superadmin/invoice"
        title="Invoices"
        fontIcon="bi-layers"
        icon="/media/icons/duotune/art/setting.svg"
      >
        <AsideMenuItem
          to="/superadmin/invoices-management/list"
          title="Invoice Overview"
          hasBullet={true}
        />
        <AsideMenuItem
          to="/superadmin/invoice-settings/"
          title="Invoice Settings"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to="/superadmin/introjs/introjs-setting"
        title="Intro-js"
        fontIcon="bi-layers"
        icon="/media/icons/duotune/art/intro.svg"
      />
    </>
  );
}
