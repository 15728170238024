
import { BaseComponent, IBaseProps } from '../../base';
import { cssPrefix, getDocument, getOffset, getPosition, jsPrefix, listen, raf, rafc, unlisten } from '../../util/dom';
import { CLICK, MOUSE_DOWN, MOUSE_MOVE, MOUSE_UP, MOUSE_WHEEL, SCROLL, TOUCH_MOVE, WHEEL } from '../../util/events';
import { gestureListener } from '../../util/gesture';
import { constrain, debounce, isArray, round, UNDEFINED } from '../../util/misc';
import { getCoord } from '../../util/tap';

// tslint:disable no-non-null-assertion
// tslint:disable no-inferrable-types
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

/** @hidden */
export interface IScrollviewBaseProps extends IBaseProps {
  axis?: 'X' | 'Y';
  batchSize?: number;
  batchSize3d?: number;
  spaceAround?: boolean;
  changeOnEnd?: boolean;
  data?: any;
  easing?: string;
  innerClass?: string;
  innerStyles?: any;
  items?: any;
  itemRenderer?: any;
  itemSize?: number;
  itemNr?: number;
  margin?: boolean;
  mouseSwipe?: boolean;
  mousewheel?: boolean;
  maxIndex?: number;
  minIndex?: number;
  offset?: number;
  prevAnim?: boolean;
  prevDef?: boolean;
  scroll3d?: boolean;
  scrollBar?: boolean;
  scrollLock?: boolean;
  selectedIndex?: number;
  snap?: boolean;
  stopProp?: boolean;
  styles?: any;
  swipe?: boolean;
  thresholdX?: number;
  thresholdY?: number;
  time?: number;
  /** The nr of rows that are visible */
  visibleSize?: number;
  onAnimationEnd?(args: any): void;
  onGestureEnd?(args: any): void;
  onGestureStart?(args: any): void;
  onIndexChange?(args: any): void;
  onStart?(args: any): void;
}

/** @hidden */
export interface IScrollviewBaseState {
  index?: number;
}

// TODO: snap points

function getItem(items: any, i: number, min: number, max: number): any {
  let item: any;
  if (i < min || i > max) {
    return;
  }
  if (isArray(items)) {
    const len = items.length;
    const index = i % len;
    item = items[index >= 0 ? index : index + len];
  } else {
    item = items(i);
  }
  return item;
}

/** @hidden */

export class ScrollviewBase extends BaseComponent<IScrollviewBaseProps, any> {
  public static defaults: IScrollviewBaseProps = {
    axis: 'Y',
    batchSize: 40,
    easing: 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',
    mouseSwipe: true,
    mousewheel: true,
    prevDef: true,
    selectedIndex: 0,
    spaceAround: true,
    stopProp: true,
    swipe: true,
    thresholdX: 10,
    thresholdY: 5,
  };

  public visibleItems!: any[];
  public visible3dItems!: any[];

  // tslint:disable variable-name
  public _cssClass!: string;
  public _innerEl!: HTMLElement;
  public _offset!: number;
  public _scrollEl!: HTMLElement;
  public _scrollEl3d!: HTMLElement;
  public _scrollbarContEl!: HTMLElement;
  public _scrollbarEl!: HTMLElement;
  public _barContSize!: number;
  public _barSize!: number;
  public _started?: boolean;

  private _isInfinite?: boolean;
  private _trackStartX!: number;
  private _trackStartY!: number;

  private _currIndex!: number;
  private _currPos = 0;
  private _currX!: number;
  private _currY!: number;
  private _delta = 0;
  private _doc: any;
  private _endPos = 0;
  private _hasScrolled?: boolean;
  private _isAnimating?: number;
  private _isScrolling?: boolean;
  private _isVertical?: boolean;
  private _lastRaf = 0;
  private _max!: number;
  private _maxSnapScroll = 0;
  private _margin = 0;
  private _min!: number;
  private _prevIndex?: number;
  private _raf: any;
  private _round!: number;
  private _rtlNr!: number;
  private _scrollSnap!: number;
  private _startPos!: number;
  private _timestamp!: number;
  private _threshold!: number;
  private _velocityX!: number;
  private _velocityY!: number;
  private _unlisten: any;

  private _scrollEnd = debounce(() => {
    rafc(this._raf);
    this._raf = false;
    this._onEnd();
    this._hasScrolled = false;
  }, 200);
  // tslint:enable variable-name

  // tslint:disable-next-line: variable-name
  public _setInnerEl = (el: any) => {
    this._innerEl = el;
  };

  // tslint:disable-next-line: variable-name
  public _setScrollEl = (el: any) => {
    this._scrollEl = el;
  };

  // tslint:disable-next-line: variable-name
  public _setScrollEl3d = (el: any) => {
    this._scrollEl3d = el;
  };

  // tslint:disable-next-line: variable-name
  public _setScrollbarEl = (el: any) => {
    this._scrollbarEl = el;
  };

  // tslint:disable-next-line: variable-name
  public _setScrollbarContEl = (el: any) => {
    this._scrollbarContEl = el;
  };

  protected _render(s: IScrollviewBaseProps, state: IScrollviewBaseState) {
    const prevS = this._prevS;
    const batchSize = s.batchSize!;
    const batchSize3d = s.batchSize3d!;
    const itemNr = s.itemNr! || 1;
    const itemSize = s.itemSize!;
    // Index of the selected item
    const selectedIndex = s.selectedIndex!;
    // Index of the previously selected item;
    const prevIndex = prevS.selectedIndex!;
    // Index of the actual middle item during animation
    const currIndex = state.index === UNDEFINED ? selectedIndex : state.index;
    const visibleItems = [];
    const visible3dItems = [];
    const diff = selectedIndex - prevIndex;
    const diff2 = currIndex - this._currIndex;
    const minIndex = s.minIndex!;
    const maxIndex = s.maxIndex!;
    const items = s.items;
    const offset = s.offset;

    this._currIndex = currIndex;
    this._isVertical = s.axis === 'Y';
    this._threshold = this._isVertical ? s.thresholdY! : s.thresholdX!;
    this._rtlNr = !this._isVertical && s.rtl ? -1 : 1;
    this._round = s.snap ? itemSize : 1;

    let scrollSnap = this._round;
    while (scrollSnap > 44) {
      scrollSnap /= 2;
    }
    this._scrollSnap = round(44 / scrollSnap) * scrollSnap;

    if (items) {
      for (let i = currIndex - batchSize; i < currIndex + itemNr + batchSize; i++) {
        visibleItems.push({ key: i, data: getItem(items, i, minIndex, maxIndex) });
      }
      if (s.scroll3d) {
        for (let i = currIndex - batchSize3d; i < currIndex + itemNr + batchSize3d; i++) {
          visible3dItems.push({ key: i, data: getItem(items, i, minIndex, maxIndex) });
        }
      }
      this.visibleItems = visibleItems;
      this.visible3dItems = visible3dItems;
      this._maxSnapScroll = batchSize;
      this._isInfinite = typeof items === 'function';
    }

    if (this._offset === UNDEFINED) {
      this._offset = selectedIndex;
    }

    const nextPos = -(selectedIndex - this._offset) * itemSize * this._rtlNr;
    if (Math.abs(diff) > batchSize && nextPos !== this._endPos) {
      const off = diff + batchSize * (diff > 0 ? -1 : 1);
      this._offset += off;
      this._margin -= off;
    }

    if (offset && offset !== prevS.offset) {
      this._offset += offset;
      this._margin -= offset;
    }

    if (diff2) {
      this._margin += diff2;
    }

    if (minIndex !== UNDEFINED) {
      this._max = -(minIndex - this._offset) * itemSize * this._rtlNr;
    } else {
      this._max = Infinity;
    }

    if (maxIndex !== UNDEFINED) {
      this._min = -(maxIndex - this._offset - (s.spaceAround ? 0 : itemNr - 1)) * itemSize * this._rtlNr;
    } else {
      this._min = -Infinity;
    }

    if (this._rtlNr === -1) {
      const temp = this._min;
      this._min = this._max;
      this._max = temp;
    }

    if (this._min > this._max) {
      this._min = this._max;
    }

    const visibleSize = s.visibleSize!;
    const barContSize = visibleSize * itemSize;

    this._barContSize = barContSize;
    this._barSize = Math.max(20, (barContSize * barContSize) / (this._max - this._min + barContSize));
    this._cssClass = this._className + ' mbsc-ltr';
    // TODO: get rid of this:
    // (!s.scrollBar || this._barSize === this._barContSize ? ' mbsc-scroller-bar-none' : '');
  }

  protected _mounted() {
    // TODO: calculate scroll sizes, if not infinite
    // const s = this.s;
    // this.size = this.isVertical ? this.cont.clientHeight : this.cont.clientWidth;
    // this.max = 0;
    // this.min = Math.min(this.max, Math.min(0, this.size - (this.isVertical ? this.el.offsetHeight : this.el.offsetWidth)));
    // this.max = Infinity;
    // this.min = -Infinity;
    const el = this._el;
    const scrollbar = this._scrollbarContEl;
    this._doc = getDocument(el);
    listen(this.s.scroll3d ? this._innerEl : el, SCROLL, this._onScroll);
    listen(el, CLICK, this._onClick, true);
    listen(el, MOUSE_WHEEL, this._onMouseWheel, { passive: false });
    listen(el, WHEEL, this._onMouseWheel, { passive: false });
    listen(scrollbar, MOUSE_DOWN, this._onTrackStart);
    listen(scrollbar, CLICK, this._onTrackClick);

    this._unlisten = gestureListener(el, {
      onEnd: this._onEnd,
      onHoverIn: () => {
        scrollbar.classList.add('mbsc-scroller-bar-hover');
      },
      onHoverOut: () => {
        scrollbar.classList.remove('mbsc-scroller-bar-hover');
      },
      onMove: this._onMove,
      onStart: this._onStart,
      prevDef: true,
    });
  }

  protected _updated() {
    const s = this.s;
    const batchSize = s.batchSize!;
    const itemSize = s.itemSize!;
    // const selectedIndex = s.selectedIndex! < s.minIndex! ? s.minIndex! : s.selectedIndex!;
    const selectedIndex = s.selectedIndex!;
    const prevIndex = this._prevIndex;
    const shouldAnimate = !s.prevAnim && ((prevIndex !== UNDEFINED && prevIndex !== selectedIndex) || this._isAnimating);
    const newPos = -(selectedIndex - this._offset) * itemSize * this._rtlNr;

    if (s.margin) {
      this._scrollEl.style.marginTop = this._isVertical ? (this._margin - batchSize) * itemSize + 'px' : '';
    }

    // Scroll to the new position, but only if the view is not being moved currently
    // The _scroll function will call _infinite, so if the index is changed from outside
    // compared to the index stored in the state, this will ensure to update the index in the state,
    // to regenerate the visible items
    if (!this._started) {
      this._scroll(newPos, shouldAnimate ? this._isAnimating || s.time || 1000 : 0);
    }

    this._prevIndex = selectedIndex;
  }

  protected _destroy() {
    unlisten(this.s.scroll3d ? this._innerEl : this._el, SCROLL, this._onScroll);
    unlisten(this._el, CLICK, this._onClick, true);
    unlisten(this._el, MOUSE_WHEEL, this._onMouseWheel, { passive: false });
    unlisten(this._el, WHEEL, this._onMouseWheel, { passive: false });
    unlisten(this._scrollbarContEl, MOUSE_DOWN, this._onTrackStart);
    unlisten(this._scrollbarContEl, CLICK, this._onTrackClick);
    rafc(this._raf);
    this._raf = false;
    // Need to reset scroll because Preact recycles the DOM element
    this._scroll(0);
    this._unlisten();
  }

  // tslint:disable-next-line: variable-name
  protected _onStart = (args: any) => {
    const s = this.s;

    this._hook('onStart', {});

    // Don't allow new gesture if new items are only generated on animation end OR
    // mouse swipe is not enabled OR
    // swipe is completely disabled
    if ((s.changeOnEnd && this._isScrolling) || (!s.mouseSwipe && !args.isTouch) || !s.swipe) {
      return;
    }

    // Better performance if there are tap events on document
    // if (s.stopProp) {
    //   ev.stopPropagation();
    // }

    // TODO: check this, will prevent click on touch device
    // if (s.prevDef) {
    //   // Prevent touch highlight and focus
    //   ev.preventDefault();
    // }

    this._started = true;
    this._hasScrolled = this._isScrolling;
    this._currX = args.startX;
    this._currY = args.startY;
    this._delta = 0;
    this._velocityX = 0;
    this._velocityY = 0;
    this._startPos = getPosition(this._scrollEl, this._isVertical);
    this._timestamp = +new Date();

    if (this._isScrolling) {
      // Stop running movement
      rafc(this._raf);
      this._raf = false;
      this._scroll(this._startPos);
    }
  };

  // tslint:disable-next-line: variable-name
  protected _onMove = (args: any) => {
    const ev = args.domEvent;
    const s = this.s;

    if (this._isVertical || s.scrollLock) {
      // Always prevent native scroll, if vertical
      if (ev.cancelable) {
        ev.preventDefault();
      }
    } else {
      if (this._hasScrolled) {
        // Prevent native scroll
        if (ev.cancelable) {
          ev.preventDefault();
        }
      } else if (ev.type === TOUCH_MOVE && (Math.abs(args.deltaY) > 7 || !s.swipe)) {
        // It's a native scroll, stop listening
        this._started = false;
      }
    }

    if (!this._started) {
      return;
    }

    this._delta = this._isVertical ? args.deltaY : args.deltaX;

    if (this._hasScrolled || Math.abs(this._delta) > this._threshold) {
      if (!this._hasScrolled) {
        this._hook('onGestureStart', {});
      }

      this._hasScrolled = true;
      this._isScrolling = true;

      if (!this._raf) {
        this._raf = raf(() => this._move(args));
      }
    }
  };

  // tslint:disable-next-line: variable-name
  protected _onEnd = () => {
    this._started = false;
    if (this._hasScrolled) {
      const s = this.s;
      const v = (this._isVertical ? this._velocityY : this._velocityX) * 17;
      const maxSnapScroll = this._maxSnapScroll;
      let delta = this._delta;
      let time = 0;

      // Calculate stopping distance
      // TODO: speedUnit
      delta += v * v * 0.5 * (v < 0 ? -1 : 1);

      // Allow only max snap
      if (maxSnapScroll) {
        delta = constrain(delta, -this._round * maxSnapScroll, this._round * maxSnapScroll);
      }

      // Round and limit between min/max
      const pos = constrain(round((this._startPos + delta) / this._round) * this._round, this._min, this._max);
      const index = round((-pos * this._rtlNr) / s.itemSize!) + this._offset;
      const direction = delta > 0 ? (this._isVertical ? 270 : 360) : this._isVertical ? 90 : 180;
      const diff = index - s.selectedIndex!;

      // Calculate animation time
      // TODO: timeUnit
      time = s.time || Math.max(1000, Math.abs(pos - this._currPos) * 3);

      this._hook('onGestureEnd', { direction, index });

      // needed for the infinite scrollbar to be cleared at each end
      this._delta = 0;
      // Set new position
      this._scroll(pos, time);

      if (diff && !s.changeOnEnd) {
        this._hook('onIndexChange', { index, diff });
        // In case if the onIndexChange handler leaves the index at the previous position,
        // we need a force update to move the wheel back to the correct position
        if (s.selectedIndex === this._prevIndex && s.selectedIndex !== index) {
          this.forceUpdate();
        }
      }
    }
  };

  // tslint:disable-next-line: variable-name
  protected _onClick = (ev: any) => {
    if (this._hasScrolled) {
      this._hasScrolled = false;
      ev.stopPropagation();
      ev.preventDefault();
    }
  };

  // tslint:disable-next-line: variable-name
  protected _onScroll = (ev: any) => {
    ev.target.scrollTop = 0;
    ev.target.scrollLeft = 0;
  };

  // tslint:disable-next-line: variable-name
  protected _onMouseWheel = (ev: any) => {
    let delta = this._isVertical ? (ev.deltaY === UNDEFINED ? ev.wheelDelta || ev.detail : ev.deltaY) : ev.deltaX;

    if (delta && this.s.mousewheel) {
      ev.preventDefault();

      this._hook('onStart', {});

      if (!this._started) {
        this._delta = 0;
        this._velocityX = 0;
        this._velocityY = 0;
        this._startPos = this._currPos;
        this._hook('onGestureStart', {});
      }

      if (ev.deltaMode && ev.deltaMode === 1) {
        delta *= 15;
      }

      delta = constrain(-delta, -this._scrollSnap, this._scrollSnap);

      this._delta += delta;

      if (this._maxSnapScroll && Math.abs(this._delta) > this._round * this._maxSnapScroll) {
        delta = 0;
      }

      if (this._startPos + this._delta < this._min) {
        this._startPos = this._min;
        this._delta = 0;
        delta = 0;
      }

      if (this._startPos + this._delta > this._max) {
        this._startPos = this._max;
        this._delta = 0;
        delta = 0;
      }

      if (!this._raf) {
        this._raf = raf(() => this._move());
      }

      if (!delta && this._started) {
        return;
      }

      this._hasScrolled = true;
      this._isScrolling = true;
      this._started = true;
      this._scrollEnd();
    }
  };

  // tslint:disable-next-line: variable-name
  protected _onTrackStart = (ev: any) => {
    ev.stopPropagation();
    const args = {
      domEvent: ev,
      startX: getCoord(ev, 'X', true),
      startY: getCoord(ev, 'Y', true),
    };
    this._onStart(args);

    this._trackStartX = args.startX;
    this._trackStartY = args.startY;

    if (ev.target === this._scrollbarEl) {
      listen(this._doc, MOUSE_UP, this._onTrackEnd);
      listen(this._doc, MOUSE_MOVE, this._onTrackMove);
    } else {
      const top = getOffset(this._scrollbarContEl).top;
      const percent = (args.startY - top) / this._barContSize;
      this._startPos = this._currPos = this._max + (this._min - this._max) * percent;
      this._hasScrolled = true;
      this._onEnd();
    }
  };

  // tslint:disable-next-line: variable-name
  protected _onTrackMove = (ev: any) => {
    const barContSize = this._barContSize;
    const endX = getCoord(ev, 'X', true);
    const endY = getCoord(ev, 'Y', true);
    const trackDelta = this._isVertical ? endY - this._trackStartY : endX - this._trackStartX;
    const percent = trackDelta / barContSize;

    if (this._isInfinite) {
      this._delta = -(this._maxSnapScroll * this._round * 2 + barContSize) * percent;
    } else {
      this._delta = (this._min - this._max - barContSize) * percent;
    }

    if (this._hasScrolled || Math.abs(this._delta) > this._threshold) {
      if (!this._hasScrolled) {
        this._hook('onGestureStart', {});
      }

      this._hasScrolled = true;
      this._isScrolling = true;

      if (!this._raf) {
        this._raf = raf(() => this._move({ endX, endY }, !this._isInfinite));
      }
    }
  };

  // tslint:disable-next-line: variable-name
  protected _onTrackEnd = () => {
    this._delta = 0;
    this._startPos = this._currPos;
    this._onEnd();
    unlisten(this._doc, MOUSE_UP, this._onTrackEnd);
    unlisten(this._doc, MOUSE_MOVE, this._onTrackMove);
  };

  // tslint:disable-next-line: variable-name
  protected _onTrackClick = (ev: any) => {
    ev.stopPropagation();
  };

  /**
   * Maintains the current position during animation
   */
  private _anim(dir: -1 | 1) {
    return (this._raf = raf(() => {
      const s = this.s;
      const now = +new Date();
      // Component was destroyed
      if (!this._raf) {
        return;
      }
      if ((this._currPos - this._endPos) * -dir < 4) {
        this._currPos = this._endPos;
        this._raf = false;
        this._isAnimating = 0;
        this._isScrolling = false;
        this._infinite(this._currPos);
        this._hook('onAnimationEnd', {});
        this._scrollbarContEl.classList.remove('mbsc-scroller-bar-started');
        return;
      }
      if (now - this._lastRaf > 100) {
        this._lastRaf = now;
        this._currPos = getPosition(this._scrollEl, this._isVertical);
        if (!s.changeOnEnd) {
          this._infinite(this._currPos);
        }
      }
      this._raf = this._anim(dir);
    }));
  }

  private _infinite(pos: number) {
    const s = this.s;
    if (s.itemSize) {
      const index = round((-pos * this._rtlNr) / s.itemSize) + this._offset;
      const diff = index - this._currIndex;

      if (diff) {
        if (s.changeOnEnd) {
          this._hook('onIndexChange', { index, diff });
        } else {
          this.setState({ index });
        }
      }
    }
  }

  private _scroll(pos: number, time?: number) {
    const s = this.s;
    const itemSize = s.itemSize!;
    const isVertical = this._isVertical;
    const style: any = this._scrollEl.style;
    const prefix = jsPrefix ? jsPrefix + 'T' : 't';
    const timing = time ? cssPrefix + 'transform ' + round(time) + 'ms ' + s.easing : '';

    style[prefix + 'ransform'] = 'translate3d(' + (isVertical ? '0,' + pos + 'px,' : pos + 'px,0,') + '0)';
    style[prefix + 'ransition'] = timing;

    this._endPos = pos;

    if (s.scroll3d) {
      const style3d: any = this._scrollEl3d.style;
      const angle = 360 / (s.batchSize3d! * 2);
      style3d[prefix + 'ransform'] = 'translateY(-50%) rotateX(' + (-pos / itemSize) * angle + 'deg)';
      style3d[prefix + 'ransition'] = timing;
    }

    if (this._scrollbarEl) {
      const sbStyle: any = this._scrollbarEl.style;
      const percent = this._isInfinite
        ? (this._maxSnapScroll * this._round - this._delta) / (this._maxSnapScroll * this._round * 2)
        : (pos - this._max) / (this._min - this._max);
      const barPos = constrain((this._barContSize - this._barSize) * percent, 0, this._barContSize - this._barSize);
      sbStyle[prefix + 'ransform'] = 'translate3d(' + (isVertical ? '0,' + barPos + 'px,' : barPos + 'px,0,') + '0)';
      sbStyle[prefix + 'ransition'] = timing;
    }

    if (time) {
      rafc(this._raf);
      // Maintain position during animation
      this._isAnimating = time;
      this._scrollbarContEl.classList.add('mbsc-scroller-bar-started');
      this._raf = this._anim(pos > this._currPos ? 1 : -1);
    } else {
      this._currPos = pos;
      // Infinite
      if (!s.changeOnEnd) {
        this._infinite(pos);
      }
    }
  }

  private _move(args?: any, preventMaxSnap?: boolean) {
    const prevX = this._currX;
    const prevY = this._currY;
    const prevT = this._timestamp;
    const maxSnapScroll = this._maxSnapScroll;

    if (args) {
      this._currX = args.endX;
      this._currY = args.endY;
      this._timestamp = +new Date();

      const timeDelta = this._timestamp - prevT;

      if (timeDelta > 0 && timeDelta < 100) {
        const velocityX = (this._currX - prevX) / timeDelta;
        const velocityY = (this._currY - prevY) / timeDelta;
        this._velocityX = velocityX * 0.7 + this._velocityX * 0.3;
        this._velocityY = velocityY * 0.7 + this._velocityY * 0.3;
      }
    }

    if (maxSnapScroll && !preventMaxSnap) {
      this._delta = constrain(this._delta, -this._round * maxSnapScroll, this._round * maxSnapScroll);
    }

    this._scroll(constrain(this._startPos + this._delta, this._min - this.s.itemSize!, this._max + this.s.itemSize!));
    this._raf = false;
  }
}
