import { FC } from "react";

type Props = {
    isLoading: boolean
    isHideSpinner?: boolean
    text?: string
    className?: string
}

const Loading: FC<Props> = ({ isLoading, text,className, isHideSpinner }) => {

    return (
        <>
            {isLoading && (
                <span className={`${className?className:''}`} style={{zIndex:999999}}>
                    {text}
                    {isHideSpinner ?
                    <span className="spinner-border" 
                    style={{width: "3rem", height: "3rem"}}>
                    </span>
                    :
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                }
                </span>
            )}
        </>
    );

};

export { Loading };