import axios, { AxiosResponse } from "axios";
import { Response } from "../../../../../_metronic/helpers";

const API_URL = process.env.REACT_APP_API_URL;
const INVOICE_URL = `${API_URL}superadmin/invoice_settings`;
const USER_URL = `${API_URL}superadmin/roles`

const getInvoiceSetting = (id: string | null): Promise<any | undefined> => {
  return axios
    .get(`${INVOICE_URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data);
};

const createInvoiceSetting = (data: any): Promise<any | undefined> => {
  return axios
    .post(INVOICE_URL, data)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data);
};

const getRoles = (query: string): Promise<any> => {
  return axios.get(`${USER_URL}?${query}`).then((d: AxiosResponse<any>) => d.data)
}

export {
  getInvoiceSetting,
  createInvoiceSetting,
  getRoles
};
