import clsx from "clsx";
import { FC } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers/index";
import { HeaderUserMenu } from "../../../../_metronic/partials";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px",
  createButtonMarginClass = "ms-1",
  createTextMarginClass = "ms-3";

type Props = {
  createbuttontext: string;
  username: string;
};

const ActivityTaskToolbar: FC<Props> = ({ createbuttontext, username }) => {
  return (
    <>
      <div className="d-flex align-items-stretch flex-shrink-0">
        <div
          className={clsx("d-flex align-items-center", createButtonMarginClass)}
        >
          <button type="button" className="btn btn-primary">
            <KTSVG
              path="/media/icons/duotune/general/gen035.svg"
              className="svg-icon-2"
            />
            {createbuttontext}
          </button>
        </div>
        <span className="h-50px border-black-200 border-start ms-3 mx-2 mt-4"></span>

        {/* begin::User */}
        <div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
        >
          <div
            className={clsx(
              "cursor-pointer symbol",
              toolbarUserAvatarHeightClass
            )}
          >
            <img
              src={toAbsoluteUrl("/media/avatars/300-1.jpg")}
              alt="metronic"
            />
          </div>
          <div className={clsx("d-flex flex-column", createTextMarginClass)}>
            <a
              href="#"
              className="text-gray-800 text-hover-primary fs-6 fw-bold"
            >
              {username}
            </a>
          </div>
          <div
            className={clsx(
              "d-flex align-items-center",
              toolbarButtonMarginClass
            )}
            id="kt_header_user_menu_toggle"
          >
            <div
              className={clsx(
                "cursor-pointer symbol",
                toolbarUserAvatarHeightClass
              )}
              data-kt-menu-trigger="click"
              data-kt-menu-attach="parent"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="bottom"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr072.svg"
                className="svg-icon-1"
              />
            </div>
            <HeaderUserMenu />
            {/* end::Toggle */}
          </div>
        </div>
        {/* end::User */}
      </div>
      <div className={clsx("card-toolbar")}>
        <div
          className="btn btn-sm btn-icon btn-active-light-primary"
          id="kt_activity_close"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen052.svg"
            className="svg-icon-1"
          />
        </div>
        <div
          className="btn btn-sm btn-icon btn-active-light-primary"
          id="kt_activity_close"
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr040.svg"
            className="svg-icon-1"
          />
        </div>
        <div
          className="btn btn-sm btn-icon btn-active-light-primary"
          id="kt_activity_close"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen007.svg"
            className="svg-icon-1"
          />
        </div>
        <div
          className="btn btn-sm btn-icon btn-active-light-primary"
          id="kt_activity_close"
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon-1"
          />
        </div>
      </div>
    </>
  );
};

export { ActivityTaskToolbar };
