import { UNDEFINED } from '../core/util/misc';

export { createContext, createElement, Fragment, PureComponent } from 'react';
export { render, unmountComponentAtNode } from 'react-dom';

export const enhance: any = UNDEFINED;
export const isPreact = false;
export const isVue = false;
export const ON_ANIMATION_END = 'onAnimationEnd';
export const ON_CONTEXT_MENU = 'onContextMenu';
export const ON_DOUBLE_CLICK = 'onDoubleClick';
export const ON_KEY_DOWN = 'onKeyDown';
export const ON_MOUSE_DOWN = 'onMouseDown';
export const ON_MOUSE_LEAVE = 'onMouseLeave';
export const ON_MOUSE_MOVE = 'onMouseMove';
