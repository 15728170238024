import { addDays, createDate, getDateStr, getDayEnd, getDayStart, getEndDate, IDatetimeProps, isSameDay, isSameMonth } from './datetime';
import { getEventMap } from './recurrence';

// tslint:disable no-non-null-assertion

/**
 * Checks if a date is invalid or not.
 * @param s Options object for the exclusiveEndDates and timezone options used
 * @param d The date to check.
 * @param invalids Object map containing the invalids.
 * @param valids Object map containing the valids.
 * @param min Timestamp of the min date.
 * @param max Timestamp of the max date.
 */
export function isInvalid(s: any, d: Date, invalids: any, valids: any, min?: number, max?: number) {
  const key = getDateStr(d); // +getDateOnly(d);

  if ((min && +d < min) || (max && +d > max)) {
    return true;
  }

  if (valids && valids[key]) {
    return false;
  }

  const invalidsForDay = invalids && invalids[key];

  if (invalidsForDay) {
    for (const invalid of invalidsForDay) {
      const { start, end, allDay } = invalid;
      if (start && end && !allDay) {
        const endDate = getEndDate(s, allDay, start, end);
        const dayStart = getDayStart(s, d);
        const dayEnd = getDayEnd(s, endDate);
        if (
          !isSameDay(start, end) &&
          (+start === +dayStart || +endDate === +dayEnd || (!isSameDay(d, start) && !isSameDay(d, end) && d > start && d < end))
          // d <= end???
        ) {
          return invalid;
        }
      } else {
        return invalid;
      }
    }
  }

  return false;
}

/**
 * Returns the closest valid date. Actually gets the closest valid only if the next or the previous valid is in
 * the other month. Otherwise it gets the next valid (when not given direction), regardless if the previous valid is closer.
 * @param d Initial date.
 * @param s Date & time options.
 * @param min Timestamp of the min date.
 * @param max Timestamp of the max date.
 * @param invalids Object map containing the invalids.
 * @param valids Object map containing the valids.
 * @param dir Direction to find the next valid date. If 1, it will search forwards, if -1, backwards,
 * otherwise will search both directions and return the closest one.
 */
export function getClosestValidDate(
  d: Date,
  s: IDatetimeProps,
  min: number,
  max: number,
  invalids?: any,
  valids?: any,
  dir?: number,
  // viewStart?: number,
  // viewEnd?: number,
) {
  let next: Date;
  let prev: Date;
  let nextInvalid = true;
  let prevInvalid = true;
  let up = 0;
  let down = 0;

  if (+d < min) {
    d = createDate(s, min);
  }

  if (+d > max) {
    d = createDate(s, max);
  }

  const year = s.getYear!(d);
  const month = s.getMonth!(d);
  const start = s.getDate!(year, month - 1, 1);
  const end = s.getDate!(year, month + 2, 1);
  const from = +start > min ? +start : min;
  const until = +end < max ? +end : max;

  // If invalids are not passed we create the invalids map for +/- 1 month
  if (!invalids) {
    // Map the valids and invalids for prev and next months
    valids = getEventMap(s.valid!, start, end, s, true);
    invalids = getEventMap(s.invalid!, start, end, s, true);
  }

  if (!isInvalid(s, d, invalids, valids, min, max)) {
    return d;
  }

  next = d;
  prev = d;

  // Find next valid value
  while (nextInvalid && +next < until && up < 100) {
    next = addDays(next, 1);
    nextInvalid = isInvalid(s, next, invalids, valids, min, max);
    up++;
  }

  // Find previous valid value
  while (prevInvalid && +prev > from && down < 100) {
    prev = addDays(prev, -1);
    prevInvalid = isInvalid(s, prev, invalids, valids, min, max);
    down++;
  }

  // If no valid value found, return the invalid value
  if (nextInvalid && prevInvalid) {
    return d;
  }

  if (dir === 1 && !nextInvalid) {
    return next;
  }

  if (dir === -1 && !prevInvalid) {
    return prev;
  }

  if (isSameMonth(d, next, s) && !nextInvalid) {
    return next;
  }

  if (isSameMonth(d, prev, s) && !prevInvalid) {
    return prev;
  }

  return prevInvalid || (down >= up && !nextInvalid) ? next : prev;
}
