import React, { useEffect, useState,FC } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { attachCardtocustomer, getSubscriptionList } from "../../requests/GeneralSettingRequest";
import { SegmentedGroup, SegmentedItem } from '@mobiscroll/react';
import { useQuery } from 'react-query';
import { useAuth } from '../../../app/modules/auth/core/AuthCognito';

const PAYMENTMETHOD:any = process.env.REACT_APP_PAYMENTMETHOD || "card";

const CreditCardForm: FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setsuccessMessage] = useState('');
  const [price, setPrice] = useState(null);
  const [btnDisable, setbtnDisable] = useState(false);
  const { currentUser } = useAuth();
  const organizationData:any = localStorage.getItem('organizationID');
  let organization_id = organizationData;

  const {
    isFetching: isfetchsubscriptionlist,
    data: subscriptionlist,
  } = useQuery(
    'subscriptionlist',
    async () => {
      return await getSubscriptionList({organization_id});
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const handleSubmit = async (event: React.FormEvent) => {
    setbtnDisable(true)
    setErrorMessage('')
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
      return;
    }
    
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: PAYMENTMETHOD,
      card: cardNumberElement,
    });

    if (error) {
      setErrorMessage(error.message || 'Failed to create payment method.');
      setbtnDisable(false)
    } else if (paymentMethod) {
      const { id } = paymentMethod;
      try {
        const res = await attachCardtocustomer(id,price,currentUser?.data?.user?.organizationId);
        if (!res || res?.data?.status !== true) {
          setErrorMessage(res?.data?.message);
          setbtnDisable(false)
          return false;
        } else {
          setsuccessMessage('Payment method added successfully.');
          setTimeout(() => {
            window.location.reload()
          }, 2000);
        }
      } catch (error:any) {
        setbtnDisable(false)
      }
    }
  };
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const setSubscriptionview = (view?: any) => {
    setPrice(view)
  };
  useEffect(()=>{
    const defaultSelected = subscriptionlist?.find((e:any) => e.selectedOne === e.id)?.id;
    if(subscriptionlist?.length > 0){
      setPrice(subscriptionlist[0].id)
    }
    if (defaultSelected) {
      setPrice(defaultSelected);
    }
    
  },[subscriptionlist])

  return (<>
  <div className="card-row mb-4">
          <div className='bg-white border border-primary rounded-pill mb-4 switchBtns'>
            <SegmentedGroup
                value={price}
                // onChange={changeView}
                className="m-0 bg-transparent p-0 fs-16 rounded-pill"
            >
              {!isfetchsubscriptionlist && subscriptionlist?.map((e: any,key:any) => {
                  return <><SegmentedItem
                  value={e.id}
                  key={key}
                  className=''
                  onChange={() => setSubscriptionview(e.id)}
              >
                  {e.name}
              </SegmentedItem></>
              }) 
              }
              </SegmentedGroup>
          </div>
        </div>
    {/* <div className="mb-4">
      <span className="fs-16">Please add your creditcard details below:</span>
    </div> */}
    <form className="form-container w-100" onSubmit={handleSubmit}>
      {/* <div className='mb-6'>
        <span className="fs-16"><label className='mb-1'>Update payment method</label></span>
      </div> */}
      {errorMessage &&
        <div className="mb-4 alert alert-danger p-2 px-3">
          <div className="alert-text font-weight-bold fs-14">{errorMessage}</div>
        </div>
      }
      {successMessage &&
        <div className="mb-4 alert alert-success p-2 px-3">
          <div className="alert-text font-weight-bold fs-14">{successMessage}</div>
        </div>
        }
        
        <div className="card-row mb-4">
          <label className='mb-1'>Card Number:</label>
          <div className="split-card-container">
            <div className="card-element-container">
              <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>
        </div>
        <div className="card-row mb-4">
          <label className='mb-1'>Expiration Date:</label>
          <div className="split-card-container">
            <div className="card-element-container">
              <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>
        </div>
        <div className="card-row mb-4">
          <label className='mb-1'>CVC:</label>
          <div className="split-card-container">
            <div className="card-element-container">
              <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
            </div>
          </div>
        </div>
        <button className='btn btn-primary text-left h-40px p-0 px-5 me-3 w-100 fs-16 mb-4' type="submit" disabled={(price == null || price ==='' || btnDisable) ? true : false}>Update</button>
    </form>
    </>
  );
};

export default CreditCardForm;
