import React from "react";
import clsx from "clsx";
import { useLocation } from "react-router";
import { checkIsActive, KTSVG, WithChildren } from "../../../helpers";
import { useLayout } from "../../core";

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  imgsrc?: string;
  className?: string;
};

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  imgsrc,
  className
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;

  return (
    <div
      className={clsx("menu-item", { "here show": isActive }, "menu-accordion", className)}
      data-kt-menu-trigger="click"
    >
      <span className="menu-link text-hover-white">
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && aside.menuIcon === "svg" && (
          <span className="menu-icon">
            <KTSVG path={icon} className="" svgClassName="w-20px h-auto" />
          </span>
        )}

        {imgsrc && (
        <span className="lh-1 min-w-32px">
        <img 
          src={imgsrc} 
          alt="metronic" className="rounded-circle w-24px h-24px"/>
          </span>
        )}

        {fontIcon && aside.menuIcon === "font" && (
          <i className={clsx("bi fs-3", fontIcon)}></i>
        )}
        <span className="menu-title d-block text-ellipsis">{title}</span>
        <span className="menu-arrow"></span>
      </span>
      <div
        className={clsx("menu-sub menu-sub-accordion", {
          "menu-active-bg": isActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export { AsideMenuItemWithSub };
