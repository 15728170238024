
import { BaseComponent } from '../../../base';
import { createDate, formatDate, getDayMilliseconds, getGridDayDiff, isInWeek, isMBSCDate, ONE_MIN } from '../../../util/datetime';
import { MbscTimezonePlugin } from '../../../util/datetime.types.public';
import { floor, UNDEFINED } from '../../../util/misc';
import { MbscSchedulerTimezone } from '../eventcalendar.types';

// tslint:disable no-non-null-assertion
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

// tslint:disable-next-line interface-name
export interface MbscTimeIndicatorOptions {
  // cssClass?: string;
  amText?: string;
  displayedTime: number;
  displayedDays: number;
  endDay: number;
  hasResY?: boolean;
  showDayIndicator?: boolean;
  orientation: 'x' | 'y';
  pmText?: string;
  rtl?: boolean;
  theme?: string;
  firstDay: Date;
  startDay: number;
  startTime: number;
  timeFormat?: string;
  displayTimezone?: string;
  timezonePlugin?: MbscTimezonePlugin;
  timezones?: MbscSchedulerTimezone[];
}

/** @hidden */

export class TimeIndicatorBase extends BaseComponent<MbscTimeIndicatorOptions, any> {
  // tslint:disable variable-name
  public _cssClass?: string;
  public _dayPos: any;
  public _pos: any; // TODO type
  public _time!: string;
  public _times!: string[];

  private _timer: any;
  // tslint:enable variable-name

  protected _mounted() {
    clearInterval(this._timer);
    this._timer = setInterval(() => {
      if (this._zone) {
        this._zone.runOutsideAngular(() => {
          this.forceUpdate();
        });
      } else {
        this.forceUpdate();
      }
    }, 10000);
  }

  protected _destroy() {
    clearInterval(this._timer);
  }

  protected _render(s: MbscTimeIndicatorOptions) {
    const now = createDate(s);
    const rtl = s.rtl;
    const displayedDays = s.displayedDays;
    const displayedTime = s.displayedTime;
    const startTime = s.startTime;
    const time = floor(getDayMilliseconds(now) / ONE_MIN) * ONE_MIN;
    const timezones: any = s.timezones;
    const formatOpt = { amText: s.amText, pmText: s.pmText };

    if (timezones && isMBSCDate(now)) {
      this._times = [];
      for (const t of timezones) {
        const tzNow = now.clone();
        tzNow.setTimezone(t.timezone);
        this._times.push(formatDate(s.timeFormat!, tzNow, formatOpt));
      }
    } else {
      this._time = formatDate(s.timeFormat!, now, formatOpt);
    }

    this._cssClass =
      'mbsc-schedule-time-indicator mbsc-schedule-time-indicator-' +
      s.orientation +
      this._theme +
      this._rtl +
      ' ' +
      // (s.cssClass || '') +
      (time < startTime || time > startTime + displayedTime || !isInWeek(now.getDay(), s.startDay, s.endDay) ? ' mbsc-hidden' : '');

    const dayIndex = s.hasResY ? 0 : getGridDayDiff(s.firstDay, now, s.startDay, s.endDay);

    if (s.orientation === 'x') {
      const horiz = (dayIndex * 100) / displayedDays + '%';
      const multiPos = timezones && 4.25 * timezones.length + 'em';
      this._pos = {
        left: timezones && !rtl ? multiPos : UNDEFINED,
        right: timezones && rtl ? multiPos : UNDEFINED,
        top: ((time - startTime) * 100) / displayedTime + '%',
      };
      this._dayPos = {
        left: rtl ? '' : horiz,
        right: rtl ? horiz : '',
        width: 100 / displayedDays + '%',
      };
    } else {
      const pos = ((dayIndex * displayedTime + time - startTime) * 100) / (displayedDays * displayedTime) + '%';
      this._pos = {
        left: rtl ? '' : pos,
        right: rtl ? pos : '',
      };
    }
  }
}
