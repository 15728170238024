import { ListViewProvider, useListView } from '../core/ListViewProvider'
import { QueryRequestProvider } from '../core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponsePagination } from '../core/QueryResponseProvider'
import { KTCard } from '../../../../../_metronic/helpers'
import { useQueryResponse, useQueryResponseData } from '../core/QueryResponseProvider'
import { useMemo } from 'react'
import { IntrojsEditModal } from '../IntrojsEditModal'
import { IntrojsHeader } from '../components/headers/IntrojsHeader'
import { introjsColumns } from './column/_column'
import { IntrojsTable } from './IntrojsTable'

const IntrojsList = () => {
  const { isLoading } = useQueryResponse();
    let introjs = useQueryResponseData();
    const data = useMemo(() => introjs, [introjs]);
    const columns = useMemo(() => introjsColumns, []);
    const pagination = useQueryResponsePagination();
 const {itemIdForUpdate} = useListView()
  const openEditModal = (state:boolean)=>{


  }
  return (
    <>
      <KTCard>
        <IntrojsHeader/>
        <IntrojsTable columns={columns} data={data} isLoading={isLoading} pagination={pagination}/>
      </KTCard>
      {itemIdForUpdate !== undefined && <IntrojsEditModal openUserModel={openEditModal}/>}
    </>
  )
}

const IntrojsListWrapper = () => {
  const { isLoading } = useQueryResponse()
  const data = useQueryResponseData()
  return (
    <>
      <QueryRequestProvider>
        <QueryResponseProvider>
          <ListViewProvider isLoading={isLoading} data={data as any}>
            <IntrojsList />
          </ListViewProvider>
        </QueryResponseProvider>
      </QueryRequestProvider>
    </>
  )
}

export { IntrojsListWrapper }
