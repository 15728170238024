
export type SSOData = {
    enableCognitoIntegration: boolean,
    enableOutLookIntegration: boolean,
    identityProviderType: string,
    clientId: string,
    clientSecret: string,
    tenentId: string,
}

export const initalSSOData:SSOData = {
    enableCognitoIntegration: false,
    enableOutLookIntegration: false,
    identityProviderType: '',
    clientId: '',
    clientSecret: '',
    tenentId: '',

}