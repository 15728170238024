import { ID, Response } from "../../../../../_metronic/helpers";

export type GeneralSetting = {
  [x: string]: unknown;
  id?: ID;
  daily_availability?: string;
  planning_screen_view?: string;
  // task_screen_view?: string,
  service_call_duration?: string,
  task_duration?: string,
  ticket_duration?: string,
  task_color?: string,
  ticket_color?: string,
  service_call_color?: string,
  planning_first_day_of_week?: string,
  // task_first_day_of_week?: string,
  allow_overlapping?: boolean,
  show_task_type?: boolean,
  time_format?: any
  default_todofilter?: any,
  timezone?: any,
  show_resource_skills?: boolean;
  show_appointments?: boolean;
  show_appointments_project?: boolean;
  show_notes?: boolean;
  show_internal_notes?: boolean;
  default_monthyearhours?: any
  outlook_sync_type?:string,
  plan_bucket_settings?:string[]
};

export type GeneralSettingQueryResponse = Response<Array<GeneralSetting>>;

export const initialGeneralSetting: GeneralSetting = {
  daily_availability: "",
  planning_screen_view: "",
  // task_screen_view: "",
  service_call_duration: "",
  task_duration: "",
  ticket_duration: "",
  task_color: "",
  ticket_color: "",
  service_call_color: "",
  planning_first_day_of_week: "",
  // task_first_day_of_week: "",
  timezone:"",
  outlook_sync_type:'both' , 
  isLocation: true
};

