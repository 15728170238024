import { round, UNDEFINED } from './misc';
import { isBrowser, isSafari, os } from './platform';

// tslint:disable no-non-null-assertion

/**
 * Generic DOM functions.
 */

export const doc = isBrowser ? document : UNDEFINED;
export const win = isBrowser ? window : UNDEFINED;

const prefixes = ['Webkit', 'Moz'];
const elem = doc && doc.createElement('div').style;
const canvas = doc && doc.createElement('canvas');
const ctx: any = canvas && canvas.getContext && canvas.getContext('2d', { willReadFrequently: true });
const css = win && (win as any).CSS;
const cssSupports = css && css.supports;
const textColors: any = {};

export const raf: any =
  (win && win.requestAnimationFrame) ||
  ((func: any) => {
    return setTimeout(func, 20);
  });

export const rafc: any =
  (win && win.cancelAnimationFrame) ||
  ((id: any) => {
    clearTimeout(id);
  });

export const hasAnimation = elem && elem.animationName !== UNDEFINED;

// UIWebView on iOS still has the ghost click,
// WkWebView does not have a ghost click, but it's hard to tell if it's UIWebView or WkWebView
// In addition in iOS 12.2 if we enable tap handling, it brakes the form inputs
// (keyboard appears, but the cursor is not in the input).
const isWebView = os === 'ios' && !isSafari;
const isWkWebView = isWebView && win && (win as any).webkit && (win as any).webkit.messageHandlers;
export const hasGhostClick = (elem && elem.touchAction === UNDEFINED) || (isWebView && !isWkWebView);

export const jsPrefix = getPrefix();
export const cssPrefix = jsPrefix ? '-' + jsPrefix.toLowerCase() + '-' : '';
export const has3d = cssSupports && cssSupports('(transform-style: preserve-3d)');
export const hasSticky = cssSupports && (cssSupports('position', 'sticky') || cssSupports('position', '-webkit-sticky'));

/** @hidden */
function getPrefix() {
  if (!elem || elem.transform !== UNDEFINED) {
    return '';
  }
  for (const prefix of prefixes) {
    if ((elem as any)[prefix + 'Transform'] !== UNDEFINED) {
      return prefix;
    }
  }
  return '';
}

/**
 * @hidden
 * @param el
 * @param event
 * @param handler
 */
export function listen(el: EventTarget | null | undefined, event: string, handler: EventListener, opt?: any) {
  if (el) {
    el.addEventListener(event, handler, opt);
  }
}

/**
 * @hidden
 * @param el
 * @param event
 * @param handler
 */
export function unlisten(el: EventTarget | null | undefined, event: string, handler: EventListener, opt?: any) {
  if (el) {
    el.removeEventListener(event, handler, opt);
  }
}

/**
 * @hidden
 * @param el
 */
export function getDocument(el: HTMLElement): Document | undefined {
  if (!isBrowser) {
    return UNDEFINED;
  }
  return el && el.ownerDocument ? el.ownerDocument : doc;
}

export function getDimension(el: HTMLElement, property: string): number {
  return parseFloat((getComputedStyle(el) as any)[property] || '0');
}

export function getScrollLeft(el: any) {
  return el.scrollLeft !== UNDEFINED ? el.scrollLeft : el.pageXOffset;
}

export function getScrollTop(el: any) {
  return el.scrollTop !== UNDEFINED ? el.scrollTop : el.pageYOffset;
}

export function setScrollLeft(el: HTMLElement | Window, val: number) {
  if (el.scrollTo) {
    el.scrollTo(val, (el as Window).scrollY);
  } else {
    (el as HTMLElement).scrollLeft = val;
  }
}

export function setScrollTop(el: HTMLElement | Window, val: number) {
  if (el.scrollTo) {
    el.scrollTo((el as Window).scrollX, val);
  } else {
    (el as HTMLElement).scrollTop = val;
  }
}

/**
 * @hidden
 * @param el
 */
export function getWindow(el: HTMLElement): Window | undefined {
  if (!isBrowser) {
    return UNDEFINED;
  }
  return el && el.ownerDocument && el.ownerDocument.defaultView ? el.ownerDocument.defaultView : win;
}

/**
 * @hidden
 * @param el
 * @param vertical
 */
export function getPosition(el: HTMLElement, vertical?: boolean) {
  const style: any = getComputedStyle(el);
  const transform = jsPrefix ? style[jsPrefix + 'Transform'] : style.transform;
  const matrix = transform.split(')')[0].split(', ');
  const px = vertical ? matrix[13] || matrix[5] : matrix[12] || matrix[4];

  return +px || 0;
}

/**
 * Calculates the text color to be used with a given color (black or white)
 * @hidden
 * @param color
 */
export function getTextColor(color?: string): string {
  if (!ctx || !color) {
    return '#000';
  }

  // Cache calculated text colors, because it is slow
  if (textColors[color]) {
    return textColors[color];
  }

  // Use canvas element, since it does not require DOM append
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, 1, 1);

  const img = ctx.getImageData(0, 0, 1, 1);
  const rgb = img ? img.data : [0, 0, 0];
  const delta = +rgb[0] * 0.299 + +rgb[1] * 0.587 + +rgb[2] * 0.114;
  const textColor = delta < 130 ? '#fff' : '#000';

  textColors[color] = textColor;

  return textColor;
}

/** @hidden */
function scrollStep(elm: HTMLElement, startTime: number, fromX: number, fromY: number, toX?: number, toY?: number, callback?: () => void) {
  const elapsed = Math.min(1, (+new Date() - startTime) / 468);
  const eased = 0.5 * (1 - Math.cos(Math.PI * elapsed));
  let currentX: number | undefined;
  let currentY: number | undefined;

  if (toX !== UNDEFINED) {
    currentX = round(fromX + (toX - fromX) * eased);
    elm.scrollLeft = currentX;
  }
  if (toY !== UNDEFINED) {
    currentY = round(fromY + (toY - fromY) * eased);
    elm.scrollTop = currentY;
  }

  if (currentX !== toX || currentY !== toY) {
    raf(() => {
      scrollStep(elm, startTime, fromX, fromY, toX, toY, callback);
    });
  } else if (callback) {
    callback();
  }
}

/**
 * Scrolls a container to the given position
 * @hidden
 * @param elm Element to scroll
 * @param toX Position to scroll horizontally to
 * @param toY Position to scroll vertically to
 * @param animate If true, scroll will be animated
 * @param rtl Rtl
 * @param callback Callback when scroll position is reached
 */
export function smoothScroll(elm: HTMLElement, toX?: number, toY?: number, animate?: boolean, rtl?: boolean, callback?: () => void) {
  if (toX !== UNDEFINED) {
    toX = Math.max(0, round(toX));
  }
  if (toY !== UNDEFINED) {
    toY = Math.max(0, round(toY));
  }

  if (rtl && toX !== UNDEFINED) {
    toX = -toX;
  }

  if (animate) {
    scrollStep(elm, +new Date(), elm.scrollLeft, elm.scrollTop, toX, toY, callback);
  } else {
    if (toX !== UNDEFINED) {
      elm.scrollLeft = toX;
    }
    if (toY !== UNDEFINED) {
      elm.scrollTop = toY;
    }
    if (callback) {
      callback();
    }
  }
}

/**
 * Convert html text to plain text
 * @hidden
 * @param htmlString
 */
export function htmlToText(htmlString?: string): string {
  if (doc && htmlString) {
    const tempElm = doc.createElement('div');
    tempElm.innerHTML = htmlString;
    return tempElm.textContent!.trim();
  }
  return htmlString || '';
}

/**
 * Gets the offset of a HTML element relative to the window
 * @param el The HTML element
 */
export function getOffset(el: HTMLElement): { left: number; top: number } {
  const bRect = el.getBoundingClientRect();
  const ret = {
    left: bRect.left,
    top: bRect.top,
  };
  const window = getWindow(el);
  if (window !== UNDEFINED) {
    ret.top += getScrollTop(window);
    ret.left += getScrollLeft(window);
  }
  return ret;
}

/**
 * Checks if an HTML element matches the given selector
 * @param elm
 * @param selector
 */
export function matches(elm: HTMLElement, selector: string) {
  // IE11 only supports msMatchesSelector
  const matchesSelector = elm && (elm.matches || (elm as any).msMatchesSelector);
  return matchesSelector && matchesSelector.call(elm, selector);
}

/**
 * Returns the closest parent element matching the selector
 * @param elm The starting element
 * @param selector The selector string
 * @param context Only look within the context element
 */
export function closest(elm: HTMLElement, selector: string, context?: HTMLElement): HTMLElement | null {
  while (elm && !matches(elm, selector)) {
    if (elm === context || elm.nodeType === elm.DOCUMENT_NODE) {
      return null;
    }
    elm = elm.parentNode as HTMLElement;
  }
  return elm;
}

/**
 * Triggers an event on a HTML element
 * NOTE: React messes with the event listeners, so triggering an event with
 * this method will not be picked up with a react way listener (ex. `<input onChange={handler} />`),
 * instead will require to be listened manually
 * @param elm The target HTML element, the event will triggered on
 * @param name The name of the event
 * @param data Additional event data
 */
export function trigger(elm: HTMLElement, name: string, data?: any) {
  let evt: any;
  try {
    evt = new CustomEvent(name, {
      bubbles: true,
      cancelable: true,
      detail: data,
    });
  } catch (e) {
    evt = document.createEvent('Event');
    evt.initEvent(name, true, true);
    evt.detail = data;
  }
  elm.dispatchEvent(evt);
}

export function forEach(items: any, func: (item: any, index: number) => void) {
  for (let i = 0; i < items.length; i++) {
    func(items[i], i);
  }
}
