// ViewSettings.js
import React, { FC } from 'react';
import { SegmentedGroup, SegmentedItem } from "@mobiscroll/react";

interface IViewComponent {
  view: string;
  handleViewChange: any;
  currentPath: boolean;
}
const ViewComponent: FC = ({handleViewChange, currentPath, view }: IViewComponent)=> {
 
  return (
    <div className="viewSettings">
      {/* Other settings components */}
      <SegmentedGroup
        value={view}
        drag={false}
        className="m-0 bg-transparent p-0 fs-16 rounded-pill"
      >
        <SegmentedItem
          value="day"
          className=""
          onChange={() => handleViewChange("day")}

        >
          Day
        </SegmentedItem>
        <SegmentedItem
          value="week"
          className=""
          onChange={() => handleViewChange("week")}
        >
          Week
        </SegmentedItem>

        <SegmentedItem
          value="month"
          className={`${currentPath? 'disabled-filter-month-year-view' : ''}`}
          onChange={() => handleViewChange("month")}
        >
          Month
        </SegmentedItem>
                
        <SegmentedItem
          value="year"
          className={`${currentPath  ? 'disabled-filter-month-year-view' : ''}`}
          onChange={() => handleViewChange("year")}
        >
          Year
        </SegmentedItem>
      </SegmentedGroup>
      {/* Other settings components */}
    </div>
  );
};

export default ViewComponent;
