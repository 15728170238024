
import { BaseComponent } from '../../base';
import { addDays, createDate, dateTimeLocale, formatDate, getDateOnly, getDateStr, makeDate } from '../../util/datetime';
import { getScrollTop, smoothScroll } from '../../util/dom';
import { SPACE } from '../../util/keys';
import { find, findIndex, isEmpty, UNDEFINED } from '../../util/misc';
import { isBrowser } from '../../util/platform';
import { getEventMap } from '../../util/recurrence';
import { MbscDatetimeOptions, MbscDatetimeState } from '../datetime/datetime';

// tslint:disable no-non-null-assertion
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

export interface ITimeSlot {
  value: number;
  formattedValue: string;
}

/**
 * Returns the closest number (timestamp) to a value from an array of numbers
 */
function getClosestNumber(arr: number[], value: number): number | null {
  if (value == null || !arr.length) {
    // intentional == checks for undefined as well
    return null;
  }
  // go until find a greater number
  let i = 0;
  while (i < arr.length && value >= arr[i]) {
    i++;
  }

  if (i === arr.length) {
    // no greater number was found
    return arr[i - 1]; // the last one is the closest
  } else if (i === 0) {
    // the first one was greater
    return arr[0];
  } else {
    const prev = arr[i - 1];
    const next = arr[i];
    return value - prev < next - value ? prev : next; // return the one that is closer to the given number
  }
}


export class TimegridBase extends BaseComponent<MbscDatetimeOptions, MbscDatetimeState> {
  /** @hidden */
  public static defaults: MbscDatetimeOptions = {
    ...dateTimeLocale,
    stepHour: 0,
    stepMinute: 30,
  };

  // tslint:disable variable-name

  public static _name = 'Timegrid';

  /** @hidden */
  public _cssClass?: string;
  public _valids?: { [key: string]: any[] };
  public _invalids?: { [key: string]: any[] };
  public _timeSlots!: ITimeSlot[][];
  public _value!: number;
  public _gridContEl!: HTMLElement;

  private _min?: Date;
  private _max?: Date;
  private _validTimes!: ITimeSlot[];
  private _selectedDate?: number;
  private _valueChanged?: boolean;
  private _lastValue?: number;
  private _validationHandle?: any;
  private _isOpen?: boolean;

  public _setTime = (timeSlot: ITimeSlot) => {
    this._hook('onChange', { value: createDate(this.s, timeSlot.value) });
  };

  public _isDisabled = (d: number) => {
    if (d) {
      const key = getDateStr(createDate(this.s, d));
      const invalidsForDay = this._invalids && this._invalids[key];
      const validsForDay = this._valids && this._valids[key];
      const exclusiveEndDates = this.s.exclusiveEndDates;

      if (validsForDay) {
        for (const valid of validsForDay) {
          const lessThanEnd = valid.end && (exclusiveEndDates ? d < +valid.end : d <= +valid.end);
          if ((valid.start && d >= +valid.start && lessThanEnd) || valid.allDay) {
            return false;
          }
        }
        return true;
      }
      if (invalidsForDay) {
        for (const invalid of invalidsForDay) {
          const lessThanEnd = invalid.end && (exclusiveEndDates ? d < +invalid.end : d <= +invalid.end);
          if ((invalid.start && d >= +invalid.start && lessThanEnd) || invalid.allDay) {
            return true;
          }
        }
        return false;
      }
    }
    return false;
  };

  public _onKeyDown = (ev: any) => {
    switch (ev.keyCode) {
      case SPACE:
        ev.target.click();
        ev.preventDefault();
        break;
    }
  };

  public _setCont = (el: any) => {
    this._gridContEl = el && el.parentElement!;
  };

  protected _render(s: MbscDatetimeOptions, state: MbscDatetimeState) {
    const prevS = this._prevS;

    this._cssClass = 'mbsc-timegrid-container mbsc-font' + this._theme + this._rtl;

    const minChanged = s.min !== prevS.min;
    const maxChanged = s.max !== prevS.max;
    const timeFormat = s.timeFormat!;
    const valueChanged = (prevS.value && !s.value) || (s.value && +s.value !== this._value);

    if (minChanged) {
      this._min = isEmpty(s.min) ? UNDEFINED : makeDate(s.min, s, timeFormat);
    }

    if (maxChanged) {
      this._max = isEmpty(s.max) ? UNDEFINED : makeDate(s.max, s, timeFormat);
    }

    // constrain the default date or the selected date that comes from outside to the min and max
    const selected = s.value || createDate(s);
    // const selectedConstrained = createDate(s, constrain(+selected, +this._min, +this._max));
    // calculate the current day start and end points
    const dayStart = getDateOnly(selected);
    const dayEnd = addDays(dayStart, 1);
    // optimize the invalid map to only reload when the current day changes
    // because invalids are loaded for a single day only
    // TODO: maybe we could optimize for a month as well
    const currentDateChanged = this._selectedDate !== +dayStart;
    const invChanged = s.invalid !== prevS.invalid;
    const validChanged = s.valid !== prevS.valid;

    if (invChanged || currentDateChanged) {
      this._invalids = getEventMap(s.invalid!, dayStart, dayEnd, s, true);
    }

    if (validChanged || currentDateChanged) {
      this._valids = getEventMap(s.valid!, dayStart, dayEnd, s, true);
    }

    if (valueChanged) {
      this._value = s.value && +s.value; // set or clear the selected time
    }

    const timeSlotsChange = currentDateChanged || invChanged || minChanged || maxChanged || timeFormat !== prevS.timeFormat;

    if (timeSlotsChange) {
      this._selectedDate = +dayStart; // save the current day for next render

      // define start and end points of the time slots - the day start and day end points constrained with min and max
      // const start = +constrainDate(dayStart, this._min);
      // const end = +constrainDate(dayEnd, UNDEFINED, this._max);
      const start = Math.max(+dayStart, +(this._min || -Infinity));
      const end = Math.min(+dayEnd, +(this._max || Infinity) + 1);

      // calculate the time step
      const timeInterval = s.stepHour! * 3600000 + s.stepMinute! * 60000;

      this._timeSlots = [];
      this._validTimes = [];

      let arr: ITimeSlot[] = [];
      let i = 0;
      for (let d = +dayStart; d < +dayEnd; d += timeInterval) {
        if (end >= start ? d >= start && d < end : d >= start || d < end) {
          const timeslot: ITimeSlot = { formattedValue: formatDate(timeFormat, createDate(s, d), s), value: d };
          arr.push(timeslot);
          if (i === 2) {
            this._timeSlots.push(arr);
            arr = [];
            i = -1;
          }
          if (!this._isDisabled(d)) {
            this._validTimes.push(timeslot);
          }
          i++;
        }
      }

      if (arr.length) {
        this._timeSlots.push(arr);
      }
    }

    // validate the selected time passed down from datepicker

    if (
      this._isDisabled(this._value) ||
      ((valueChanged || timeSlotsChange) && findIndex(this._validTimes, (ts) => ts.value === this._value) === -1)
    ) {
      const validated = getClosestNumber(
        this._validTimes.map((slot) => slot.value),
        this._value,
      );
      if (validated) {
        clearTimeout(this._validationHandle);
        this._validationHandle = setTimeout(() => {
          const validTimeslot = find(this._validTimes, (slot) => slot.value === validated);
          this._setTime(validTimeslot!);
        });
      }
    } else if (timeSlotsChange) {
      // if the value and the valid times also change in the next cycle, before the setTimeout above could have run,
      // then the validated value will not be found in the _validTimes array, so the validTimeSlot will error out in
      // _setTime. We can safely clear the timeout since, the value we wanted to set is not available anymore.
      clearTimeout(this._validationHandle);
    }

    this._valueChanged = this._valueChanged || valueChanged;
  }

  protected _updated() {
    const isOpen = this.s.isOpen;
    if (isBrowser && this._value !== UNDEFINED && (this._valueChanged || (this._isOpen !== isOpen && isOpen))) {
      const animate = this._lastValue !== UNDEFINED;
      const grid = this._gridContEl;
      const timeslot = grid.querySelector<HTMLElement>('[data-timeslot="' + this._value + '"]');
      if (timeslot) {
        setTimeout(() => {
          const itemRect = timeslot.getBoundingClientRect();
          const itemTop = itemRect.top;
          const itemHeight = itemRect.height;
          const gridRect = grid.getBoundingClientRect();
          const gridTop = gridRect.top;
          const gridHeight = gridRect.height;
          const currPos = getScrollTop(grid);
          if (itemTop + itemHeight > gridTop + gridHeight || itemTop < gridTop) {
            const scrollPos = itemTop - gridTop + currPos - 5;
            smoothScroll(grid, UNDEFINED, scrollPos, animate);
          }
        });
      }
      this._valueChanged = false;
      this._lastValue = this._value;
    }
    this._isOpen = isOpen;
  }
}
