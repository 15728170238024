import clsx from "clsx";
import { FC } from "react";
import { KTSVG } from "../../../../_metronic/helpers/index";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const createTextMarginClass = "ms-2";
type Props = {
  lefttab: string;
  useroverlay: string;
  duedate: string;
  righttab: string;
  prioritybutton: string;
  addbutton: string;
  dependancy: string;
  setbutton: string;
};

const ActivityTags: FC<Props> = ({
  lefttab,
  useroverlay,
  duedate,
  righttab,
  prioritybutton,
  addbutton,
  dependancy,
  setbutton,
}) => {
  return (
    <div className="timeline-item my-3">      
      <div className="overflow-auto">
        <div className="timeline-content">
          <div className="d-flex">
            {/* Begin:: Assignee */}
            <div className="card-body w-50 p-0 ps-2 pb-2">
              <div className="d-flex align-items-center mb-2">
                <div>
                  <KTSVG
                    path="/media/icons/duotune/extra/user.svg"
                    className="text-gray-900"
                    svgClassName="w-14px h-auto"
                  />
                </div>
                <div
                  className={clsx("", createTextMarginClass)}
                >
                  <a
                    href="#"
                    className="text-gray-900 text-hover-primary fs-8 fw-bold"
                  >
                    {lefttab}
                  </a>
                </div>
              </div>
              <div className="d-flex mb-3">
                {/* begin::Symbol */}
                <OverlayTrigger
                  key={`1`}
                  placement="top"
                  overlay={<Tooltip id="tooltip-user-name">User</Tooltip>}
                >
                  <div className="symbol symbol-25px symbol-circle me-1">
                    <span className="symbol-label bg-primary text-inverse-primary fw-bolder lh-1 fs-9">
                      {useroverlay}
                    </span>
                  </div>
                </OverlayTrigger>
                {/* end::Symbol */}
                {/* begin::Text */}
                <div className="d-flex">
                  <a href="#" data-bs-toggle="tooltip" className="w-25px h-25px rounded-circle bg-gray-900 d-flex align-items-center justify-content-center">
                    <KTSVG
                      path="/media/icons/duotune/extra/user-add.svg"
                      className="text-white"
                      svgClassName="w-14px h-auto"
                    />                    
                  </a>
                </div>
              </div>
              <div className="d-flex mb-2">
                <div className="d-flex align-items-center justify-content-center">
                  <KTSVG
                      path="/media/icons/duotune/extra/calendar.svg"
                      className=""
                      svgClassName="w-17px h-17px"
                      stylecode={{color: "#757575"}}
                  />  
                  <div className="fs-9 fw-bold ms-1 lh-1">Dates</div>                
                </div>
              </div>
              <div className="d-flex">
                <div className="fs-8 fw-bold ms-1 text-primary">
                  Due: {duedate}
                </div>
              </div>
            </div>
            {/* End::assignee */}

            {/* Seperator */}
            <div className="w-1px mx-8 bg-gray-101"></div>
            {/* end::seperator */}

            {/* Tags */}
            <div className="card-body w-50 p-0 pb-2">
              <div className="d-flex mb-3">
                <div className="d-flex">
                  <KTSVG
                    path="/media/icons/duotune/extra/tags.svg"
                    className="text-gray-900"
                    svgClassName="w-18px h-auto align-top"
                  />
                </div>
                <div
                  className={clsx("d-flex flex-column", createTextMarginClass)}
                >
                  <a
                    href="#"
                    className="text-gray-900 text-hover-primary fs-8 fw-bold"
                  >
                    {righttab}
                  </a>
                </div>
              </div>
              <div className="d-flex align-items-center mb-4">
                {/* begin::Symbol */}
                <a
                  href="#"
                  className="btn btn-sm btn-highpriority text-uppercase fw-bolder fs-9 p-0 h-16px px-2 d-flex justify-content-center align-items-center me-3"
                >
                  {prioritybutton}
                </a>
                {/* end::Symbol */}
                {/* begin::Text */}
                <div className="d-flex">
                  <div className="fs-8 fw-bold text-successt">
                    {addbutton}
                  </div>
                </div>
              </div>
              <div className="d-flex mb-2">
                <div className="fs-8 fw-bold">{dependancy}</div>
              </div>
              <div className="d-flex">
                <div className="fs-8 fw-bold text-successt">
                  {setbutton}
                </div>
              </div>
            </div>
            {/* End:: tags */}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ActivityTags };
