import React, { useState } from 'react';
import { Datepicker } from '@mobiscroll/react';
import { KTSVG } from '../../_metronic/helpers';


// Define a type for the props
interface CustomDatePickerProps {
  id?: any;
  value?: any;
  max?: any;
  min?: any;
  onChange?: any;
  timeFormat?: any;
  dateFormat?: any;
  controls?: any;
  touchUi?: boolean;
  showOverlay?: boolean;
  display?: any;
  buttons?: any
  defaultValue?: any;
  disabled?: boolean;
  invalid?: any
  valid?: any
  weekDays?: any;
  theme?: any;
  select?: any;
  calendarType?: any;
  showOuterDays?: boolean;
  maxRange?: any;
  defaultSelection?: boolean;
  showTodayButton?: boolean;
  showClearButton?: boolean;
  labels?: any;
  showLabels?: boolean;
  steps?: any;
  timeWheels?: string;
  className?: string;
  rangeHighlight?: any;
  showRangeLabels?: any;
  iconPath?: any;
  placeholder?: any;
  onClose?:any;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  id,
  value,
  max,
  min,
  onChange,
  timeFormat,
  dateFormat,
  controls,
  touchUi,
  showOverlay,
  display,
  buttons,
  defaultValue,
  disabled,
  invalid,
  valid,
  weekDays,
  theme,
  select,
  calendarType,
  showOuterDays,
  maxRange,
  defaultSelection,
  showTodayButton,
  showClearButton,
  labels,
  showLabels,
  steps,
  timeWheels,
  className,
  rangeHighlight,
  showRangeLabels,
  iconPath,
  placeholder,
  onClose
}) => {

  const [openPicker, setOpenPicker] = useState<boolean>(false);
  
  const show = (e : Event) => {
    e.preventDefault();
    setOpenPicker(true);
  };
  const onCloseDatePicker = () => {
    setOpenPicker(false);
    onClose && onClose()
  };

  const props = { placeholder: placeholder ?? 'dd-mm-yyyy', onBlur: onCloseDatePicker};
  return (
    <div className={`position-relative ${className}`}>
      <Datepicker
        id={id}
        value={value}
        max={max}
        min={min}
        controls={controls}
        touchUi={touchUi}
        isOpen={openPicker}
        showOverlay={showOverlay}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        display={display}
        defaultValue={defaultValue}
        buttons={buttons}
        disabled={disabled}
        invalid={invalid}
        valid={valid}
        weekDays={weekDays}
        theme={theme}
        select={select}
        calendarType={calendarType}
        showOuterDays={showOuterDays}
        maxRange={maxRange}
        defaultSelection={defaultSelection}
        showTodayButton={showTodayButton}
        showClearButton={showClearButton}
        labels={labels}
        showLabels={showLabels}
        steps={steps}
        timeWheels={timeWheels}
        onClose={onCloseDatePicker}
        onChange={(e) => onChange(e)}
        showOnClick={false}
        showOnFocus={false}
        inputProps={props}
        rangeHighlight={rangeHighlight}
        showRangeLabels={showRangeLabels}
      />
      <button className={`position-absolute top-0 bottom-0 end-0 bg-transparent border-0 w-35px ${disabled ? "disablecursor" : ""}`} disabled={disabled} onClick={(e)=>show(e)}>
        <KTSVG
          path={ iconPath ?? "/media/icons/duotune/new-icons/event-calendar.svg"}
          className="m-0 position-absolute end-0 top-0 bottom-0 pe-none w-21px d-inline-flex align-items-center mh-32px"
          svgClassName="w-13px h-auto"
        />
      </button>
    </div>
  );
};

export default CustomDatePicker;