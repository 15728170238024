import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";

const DragAndDrop = ({}) => {
  const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
    useDropzone({});
  let [newFiles, setMyFiles] = useState<File[]>([]);

  const removeImage = (file: any) => {
    newFiles = [...acceptedFiles];
    acceptedFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const blankImg = toAbsoluteUrl("/media/svg/avatars/blank.svg");

  return (
    <div>
      {acceptedFiles.length == 0 ? (
        <div {...getRootProps()} className="">
          <div className="mt-3 bg-bggray-900 border border-gray-101 border-dashed h-45px d-flex align-items-center justify-content-center">
            <input className="input-zone" {...getInputProps()} />
            <div className="fw-bold fs-8 text-color75 d-flex align-items-center justify-content-center">
              {isDragActive ? (
                <p className="dropzone-content text-color75 fw-bold m-0">
                  Release to drop the files here
                </p>
              ) : (
                <p className="dropzone-content text-color75 fw-bold m-0 d-flex align-items-center">
                  <KTSVG
                    path="/media/icons/duotune/extra/attachment.svg"
                    className="text-color75 me-1"
                    svgClassName="w-12px h-auto"
                  />
                  Drag & drop or&nbsp;<u>Choose files</u>
                </p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-2 border border-gray-400 border-dashed rounded w-130px py-3 px-5">
          <aside>
            <ul className="p-0 d-flex flex-wrap">
              {acceptedFiles.map((file, index) => (
                <div className="fv-row">
                  {/* <label className="col-lg-4"></label> */}
                  <div
                    className="image-input image-input-outline mt-2 mb-4 me-8"
                    data-kt-image-input="true"
                    style={{ backgroundImage: `url('${blankImg}')` }}
                  >
                    <img
                      width={"80px"}
                      height={"80px"}
                      src={URL.createObjectURL(file)}
                      alt="Thumb"
                    />

                    <label
                      className="btn btn-icon btn-circle btn-active-color-primary w-20px h-20px bg-body"
                      data-kt-image-input-action="change"
                      title="Remove Image"
                    >
                      <span
                        className="btn btn-icon btn-circle btn-active-color-primary w-20px h-20px bg-body shadow"
                        data-bs-toggle="tooltip"
                      >
                        <i className="bi bi-x fs-3 text-danger" onClick={removeImage}></i>
                      </span>
                    </label>
                  </div>
                </div>
              ))}
            </ul>
          </aside>
        </div>
      )}
    </div>
  );
};
export { DragAndDrop };
