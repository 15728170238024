const QUERIES = {
  USERS_LIST: "users-list",
  RESOURCES_SETTING_LIST: "resource-setting-list",
  ORGANISATIONS_LIST: "organisations-list",
  APILOG_LIST: "apilogs-list",
  EMAIL_LIST: "email-list",
  ROLES_LIST: "email-list",
  INVOICE_LIST: "invoice-list",
  COUNTRY : "country-list"
};

export { QUERIES };