// DaysComponent.js
import React, { useEffect, useState } from 'react';

const DaysComponent = ({formik ,view, currentPath}: any) => {
  const newPath = currentPath ?  formik.values?.PlannigViewData?.[view]: formik.values?.ProjectViewData?.[view]
  const viewPage = currentPath ? 'PlannigViewData' : 'ProjectViewData' ;
  const [selectedStartDay, setSelectedStartDay] = useState(newPath?.start ?? null); 
  const [selectedEndDay, setSelectedEndDay] = useState(newPath?.end ?? null);
 
  const daysOptions = [
    { value: 0, label: 'Sunday' },
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' }
  ];
  
  useEffect(() => {
    if (newPath) {
      setSelectedStartDay(newPath?.start);
      setSelectedEndDay(newPath?.end);
    }
  }, [newPath,  view]); 
  
  const handleStartDayChange = (e) => {
    const newValue = Number(e.target.value);
    setSelectedStartDay(newValue);
    formik.setFieldValue(`${viewPage}.${view}.start`, newValue);
  };

  const handleEndDayChange = (e) => {
    const newValue = Number(e.target.value);
    setSelectedEndDay(newValue);
    formik.setFieldValue(`${viewPage}.${view}.end`, newValue);
  };

  return (
    <div className="d-flex flex-column mb-10">
      <h3 className="text-dark fw-bold fs-18 m-0 mb-4">Days</h3>
      <div className="bg-gray-100 rounded-8px p-5">

        <div className="d-flex align-items-center justify-content-between">
          <label className="fw-normal fs-16">Display days from</label>
          <div className="d-flex align-items-center">
            <select
              className="form-select fs-16 fw-normal h-32px p-0 px-4 ps-3 bg-white border-graybor w-120px text-gray-900 ms-auto"
              value={selectedStartDay}
              disabled={view === "day" ? true : false}
              onChange={handleStartDayChange}
            >
              {daysOptions.map((day) => (
                <option 
                  key={day.value} 
                  value={day.value}
                  disabled={(selectedEndDay !== null && day.value == selectedEndDay)}
                >
                  {day.label}
                </option>
              ))}
            </select>
            <span className="fs-16 fw-normal text-dark px-5">to</span>
            <select
              className="form-select fs-16 fw-normal h-32px p-0 px-4 ps-3 bg-white border-graybor w-120px text-gray-900 ms-auto"
              value={selectedEndDay}
              disabled={view === "day" ? true : false}
              onChange={handleEndDayChange}
            >
              {daysOptions.map((day) => (
                <option 
                  key={day?.value} 
                  value={day?.value}
                  disabled={selectedStartDay !== null && day?.value == selectedStartDay}
                >
                  {day.label}
                </option>
              ))}
            </select>
          </div>
        </div>

      </div>
    </div>
  );
};

export default DaysComponent; 