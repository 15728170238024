/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth/core/AuthCognito";
import { toAbsoluteUrl } from "../../../helpers";

const HeaderUserMenu: FC = () => {
  const { currentUser } = useAuth();
  const blankImg = toAbsoluteUrl("/media/svg/avatars/blank.svg");
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-8 w-250px bg-white"
      data-kt-menu="true"
    >
      <div className="menu-item px-3 pb-4">
        <div className="menu-content d-flex align-items-center p-0">
          <div className="symbol symbol-35px me-2">
  
            <img alt="Logo" src=
            {
                currentUser?.data?.user?.avatar ?
              
              (`${currentUser?.data?.user?.avatar}`)
              :
              blankImg} 
              className="rounded-circle" />
            </div>

          <div className="d-flex flex-column">
            <div className="d-flex align-items-center fw-bold fs-12 fs-7">
              {
                currentUser?.data.type === 'superadmin' ?
                  currentUser?.data.username :
                  currentUser?.data?.user?.username
              }
            </div>
            <a href="#" className="text-muted text-hover-primary fw-bold fs-12 fs-7">
              {
                currentUser?.data.type === 'superadmin' ?
                  currentUser?.data.email :
                  currentUser?.data?.user?.email
              }
            </a>
          </div>
        </div>
      </div>

      <div className="separator"></div>

      <div className="menu-item p-0">
        <Link to="/apps/profile" className="menu-link px-3 py-3 fs-7 rounded-0">
          My Profile
        </Link>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
