import { FC, useState } from "react";
import { useTable, ColumnInstance, Row } from "react-table";
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn";
import { CustomRow } from "./columns/CustomRow";
import { User } from "../../modules/apps/user-management/core/_model";
import { ListLoading } from "../loading/ListLoading";
import { KTCardBody, PaginationState } from "../../../_metronic/helpers";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { useLocation } from "react-router-dom";
import { Pagination } from 'react-bootstrap'
import { CustomRowClickable } from "./columns/CustomRowClickable";

type Props = {
  isLoading: Boolean;
  data: any;
  columns: any;
  pagination: PaginationState;
  elementId?: string;
};

const SuperTable: FC<Props> = ({ isLoading, data, columns, pagination, elementId }) => {
  const location = useLocation()
  let path = location.pathname;

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  const mappedLabel = (label: string): string => {
    if (label === "&laquo; Previous") {
      return "Previous";
    }
    if (label === "Next &raquo;") {
      return "Next";
    }
    return label;
  };
  const { updateState } = useQueryRequest();
  const [pageitems,setPageitems] = useState<any>(20);

  pagination.items_per_page = path == '/apps/settings/resources' ? pageitems : pagination.items_per_page;


  const linkCount = Math.ceil(parseInt(pagination.total) / pagination.items_per_page);
  let links = []
  let start = pagination.page - 3 > 0 ? pagination.page - 3 : 0
  let end = pagination.page + 2 < linkCount ? pagination.page + 2 : linkCount
  for (let i = start; i < end; i++) {
    links[i] = {
      active: false,
      label: '' + (i + 1) + '',
      page: i + 1,
      url: '/?page=' + (i + 1),
    }
  }
  links = links.filter(function (el: any) {
    return el != null;
  });

  const updatePage = (page: number | null) => {
    if ((!page || isLoading || pagination.page === page) || (page > linkCount)) {
      return;
    }
    pagination.page = page;
    updateState({ page, items_per_page: pagination.items_per_page || 20 });
  };

  const OnItemchange = (event: any) => {
    setPageitems(parseInt(event.target.value));
    updateState({ page: 1,items_per_page: event.target.value || 20 });
  }

  return (
    <KTCardBody className="py-4 mh-100 overflow-auto pt-0 d-flex flex-column">
      <div className="table-responsive flex-fill" id={elementId}>
        <table
          id="kt_table_users"
          className="table align-middle fs-16 gy-4 table-striped dataTable no-footer"
          {...getTableProps()}
        >
          <thead style={{
            position: "sticky",
            top: 0,
            background: "white",
            zIndex: 1
          }}>
            {path == '/apps/settings/resources' &&
              <><tr className="text-start text-dark fw-bolder text-uppercase gs-0 fs-16"
              >
                <th>Active</th>
                <th>Name</th>
                <th colSpan={16}>Daily Availability (hours)</th>
              </tr><tr className="text-start text-dark fw-bolder text-uppercase gs-0 fs-16"
              >
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>Role Task</th>
                  <th>Role Ticket</th>
                  <th colSpan={2} className="text-center ps-0 pe-18 pb-2">MON</th>
                  <th colSpan={2} className="text-center ps-0 pe-18 pb-2">TUE</th>
                  <th colSpan={2} className="text-center ps-0 pe-18 pb-2">WED</th>
                  <th colSpan={2} className="text-center ps-0 pe-18 pb-2">THU</th>
                  <th colSpan={2} className="text-center ps-0 pe-18 pb-2">FRI</th>
                  <th colSpan={2} className="text-center ps-0 pe-18 pb-2">SAT</th>
                  <th colSpan={2} className="text-center ps-0 pe-18 pb-2">SUN</th>

                </tr></>
            }
            <tr className="text-start text-dark fw-bolder text-uppercase gs-0 fs-16"
            >
              {/* <th></th>
              <th></th> */}
              {headers.map((column: ColumnInstance<User>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className="text-gray-900 fw-400 fs-15 haveSkill" {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<User>, i) => {
                prepareRow(row);
                if (path === '/apps/logs/apis') {
                  return <CustomRowClickable row={row} key={`row-${i}-${row.id}`} />
                }
                else {
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                }

              })
            ) : (
              <tr>
                <td colSpan={52}>
                  <div className="d-flex text-center w-100 align-content-center justify-content-center">
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
          <div className="d-flex align-items-center justify-content-between pt-4">

                {pagination.items_per_page && path == '/apps/settings/resources' ?     

                    <div className="d-flex align-items-center">
                        <label className='col-form-label fw-bold fs-14 pe-2'>Items per page: </label>
                        <select className='form-select form-select-solid bg-white form-select-small h-32px py-0 px-3 fw-normal fs-16 text-dark w-auto min-w-70px' name='refreshtime' 
                            value={pageitems}
                            onChange={(e: any) => OnItemchange(e)}>
                              <option value={10} >10</option>
                              <option value={20} >20</option>
                              <option value={30} >30</option>
                              <option value={50} >50</option>
                              <option value={70} >70</option>
                              <option value={100}>100</option>
                            </select>                
                      </div>
                    :
                    ''
                }
                    <div id="kt_table_users_paginate" className="ms-auto">
                      {links.length > 0 &&
                        <>
                        <Pagination>
                          <Pagination.First onClick={() => updatePage(1)} />
                          <Pagination.Prev onClick={() => updatePage(pagination.page - 1)} />
                          {links
                            ?.map((link) => (
                              <Pagination.Item
                                active={link.page === pagination.page}
                                onClick={() => updatePage(link.page)}
                              >
                                {mappedLabel(link.label)}
                              </Pagination.Item>
                            ))}
                          <Pagination.Next onClick={() => updatePage(pagination.page + 1)} />
                          <Pagination.Last onClick={() => updatePage(pagination.last_page)} />
                        </Pagination></>
                      }
                    </div>
          </div> 
      {isLoading && <ListLoading />}
    </KTCardBody >
  );
};

export { SuperTable };
