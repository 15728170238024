import React, { FC } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { confirm, alert } from "@mobiscroll/react";
import { setLicenseKey } from "../../modules/auth/core/_requests";
import { components } from "react-select";

const handleLockLicense = async (res:string) => {
  try {
    await setLicenseKey(res);
    alert({
      message: "License Request Sent!"
    });
  } catch (error) {
    alert({
      message: "Error Sending License Request', 'An error occurred while sending the license request."
    });
  }
};

export const formatOptionLabel = (option: any) => (
  <div className="d-flex align-items-center">
    {option?.has_license == false && option.isActive && (
      <span
      onClick={(e: any) => {
        if (option?.has_license==false) {
          confirm({
              title:
              "You don't have a license. Do you want to send a license request?",
              okText: "Yes",
              cancelText: "No",
              callback: function (event) {
              if (event) {
                  handleLockLicense(option?.database_id);
              }
              },
          });
        }
    }}
      >
      <KTSVG
        path='/media/icons/duotune/new-icons/lock-icon.svg'
        className="text-dark h-24px me-2 d-flex align-items-center justify-content-center cursor-pointer"
        svgClassName="w-14px h-auto"
      />
      </span>
    )}
    {option?.label}
  </div>
);

type Props = {
  option?: any;
  props?:any
};
const SelectCustomLockOption: FC<Props> = ({
  option,
  props
}) => {
  return (
    <>
    <components.Option {...props}>
      <div className="d-flex align-items-center">
        {(option?.has_license == false && option.isActive) && (
        <><span
          onClick={(e: any) => {
            if (option?.has_license==false) {
            confirm({
                title:
                "You don't have a license. Do you want to send a license request?",
                okText: "Yes",
                cancelText: "No",
                callback: function (event) {
                if (event) {
                    handleLockLicense(option?.database_id);
                }
                },
            });
            }
        }}
        >
        <KTSVG 
            path='/media/icons/duotune/new-icons/lock-icon.svg' 
            className="text-dark h-24px me-2 d-flex align-items-center justify-content-center cursor-pointer" 
            svgClassName="w-14px h-auto" />                                            
        
          </span></>                                      
          )}  
        <button
            className={`bg-transparent border-0 p-0 text-ellipsis ${
              option?.has_license==false || !option.isActive ? 'with-lock-icon isoffline' : ''
            }`}
            title={option?.has_license==false ? 'Lock License' : ''}
    >
    <span>{option.label}</span>
    
    </button>
    </div>
    </components.Option>
    </>
  );
};

export default SelectCustomLockOption;
