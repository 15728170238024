import React, { FC, useCallback, useState } from 'react'
import { Popup } from "@mobiscroll/react";
import {useFormik } from "formik";
import { KTSVG } from '../../../../_metronic/helpers';
import { setcallApiforfilter, storeAppointmentFilter } from '../../../counterSlice';
import { useAppDispatch, useAppSelector } from '../../../hook';
import { Loading } from '../../loading/Loading';
import * as Yup from "yup";
import { OverlayTrigger, Popover } from 'react-bootstrap';

type Props = {
    conditioncount: any
    setconditioncount: any
    isFilterPopupOpen: any
    setisFilterPopupOpen: any
}

interface SearchCondition {
    field: string;
    condition: string;
    value: string;
  }
  
interface Group {
    conditions: SearchCondition[];
}

interface FormValues {
    groups: Group[];
  }

const AppointmentFilters: FC<Props> = ({conditioncount, setconditioncount, isFilterPopupOpen, setisFilterPopupOpen}) => {
    const count = useAppSelector((state) => state.counter)
    const dispatch = useAppDispatch();
    const [isBtnSubmitted, setIsSubmitted] = useState<any>(false);
    const [loading, setLoading] = useState(false);
    let appoinmentfiltedata:any = { groups: [{ conditions: [{ field: '', condition: '', value: '' }] }] }
    let appointmentDetailData = appoinmentfiltedata;
        appointmentDetailData.groups = count?.appointment_filter?.groups?.length > 0 ? count?.appointment_filter?.groups : appoinmentfiltedata?.groups;

    const onCreateCloseLayout = useCallback(
        async (e: any) => {
            setisFilterPopupOpen(false);
        },
        []
    );

    const validations = Yup.object().shape({
  groups: Yup.array().of(
    Yup.object().shape({
      conditions: Yup.array().of(
          Yup.object().shape({
          field: Yup.string().required('Type is required'),
          condition: Yup.string().required('Condition is required'),
          value: Yup.string().max(255, "Maximum 255 characters").required('Search value is required').trim(),
        })
      ),
    })
  ),
})

    const formik = useFormik<FormValues>({
        enableReinitialize: true,
        initialValues: appointmentDetailData,
        validationSchema: validations,
        validateOnChange: true,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            setLoading(true);
            try {
                dispatch(setcallApiforfilter(true));
                dispatch(storeAppointmentFilter(values));
            } catch (ex) {
                setLoading(false);
            } finally {
                setSubmitting(true);
                setLoading(false);
                cancel(true);
            }
        },
    });

    const cancel = (withRefresh?: boolean) => {
        if (withRefresh) {
            setconditioncount(0)
            formik.resetForm();
            setisFilterPopupOpen(false)
        }
    };
    const removeCondition = (groupIndex: number, index: number) => {
        const updatedItems = formik.values.groups[groupIndex].conditions.filter((_, i) => i !== index);
        setconditioncount(conditioncount - 1)
        if (updatedItems?.length == 0) {
            const updatedItemsGroup = formik.values.groups.filter((_, i) => i !== groupIndex);
            formik.setFieldValue(`groups`, updatedItemsGroup);
        } else {
            formik.setFieldValue(`groups.${groupIndex}.conditions`, updatedItems);
        }
    };

    const handleAddGroup = () => {
        setIsSubmitted(false)
        formik.setTouched({});
        formik.setErrors({});
        let conditionsCount: any = 0;
        formik.values.groups.map((group: any, groupIndex: number) => {
            conditionsCount = conditionsCount + group?.conditions?.length
        })
        if (conditionsCount < 10) {
            setconditioncount(conditionsCount + 1)
            const newItems: Group[] = [...formik.values.groups, { conditions: [{ field: '', condition: '', value: '' }] }];
            formik.setFieldValue('groups', newItems);
        }
    };

    const handleAddCondition = (groupIndex: number) => {
        setIsSubmitted(false)
        formik.setTouched({});
        formik.setErrors({});
        let conditionsCount: any = 0;
        formik.values.groups.map((group: any, groupIndex: number) => {
            conditionsCount = conditionsCount + group?.conditions?.length
        })
        if (conditionsCount < 10) {
            setconditioncount(conditionsCount + 1)
            const newItems: SearchCondition[] = [...formik.values.groups[groupIndex].conditions, { field: '', condition: '', value: '' }];
            formik.setFieldValue(`groups.${groupIndex}.conditions`, newItems);
        }
    };


    const responsivePopup = {
        medium: {
            display: "center",
            width: 500,
            fullScreen: false,
            touchUi: false,
            showOverlay: false,
        },
    };

    const renderAppointmentFilter = (
        <Popover id="renderServiceCallTooltip">
          <div className="p-5 bg-white card">
            <div>
            Select which Outlook appointments you want to see on your Planning Screen.
              <a className="text-decoration-underline ms-2"
                href="https://knowledgebase.proxuma.io/article/appointment-filters"
                target="_blank"
              >
                Learn more.
              </a>
            </div>
          </div>
        </Popover>
      )
    return (
        <>
        <Popup
            display="anchored"
            fullScreen={true}
            contentPadding={false}
            closeOnOverlayClick={false}
            //buttons={popupCreateButtonLayout}
            isOpen={isFilterPopupOpen}
            scrollLock={false}
            onClose={onCreateCloseLayout}
            // onClose={onClose}
            responsive={responsivePopup}
            cssClass="md-tooltip md-tooltip-detail plannPopup commonMidpopup assignPopup createPopup"
        >
            <div className="accordion" id="kt_accordion_1">
                <div className="fs-16 fontIner text-dark">

                    <div className="min-h-65px"></div>
                    <div className="md-tooltip-header d-flex justify-content-between px-7 position-absolute top-0 start-0 end-0 py-3 align-items-center min-h-65px" style={{ backgroundColor: '#dfdeff', zIndex: '1' }}>
                        <div className="position-absolute start-0 top-0 w-100 h-100 opacity-60" style={{ backgroundColor: '#dfdeff', zIndex: '-1' }}></div>
                        <div className="d-inline-flex align-items-center fw-bold text-primary fs-18 lh-1 align-left mw-100 pe-4">
                            <div className="h-32px d-flex align-items-center">
                            <OverlayTrigger
                                trigger="click"
                                rootClose
                                placement="top"
                                overlay={renderAppointmentFilter}
                            >
                                <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-0 me-2">i</button>
                            </OverlayTrigger>
                            </div>
                            Appointment Filter
                        </div>
                    </div>

                    <form onSubmit={formik.handleSubmit} noValidate className='form d-flex flex-column overflow-hidden' style={{ maxHeight: '535px' }}>

                        <div className="paddLR24 md-tooltip-info position-relative accordion-collapse collapse show pb-0 h-100 overflow-auto border-bottom-0" id={`kt_accordion_1_body_task`} aria-labelledby={`kt_accordion_1_header_task`} data-bs-parent="#kt_accordion_1">
                            <div>
                                {formik.values.groups.map((group: any, groupIndex: number) => (
                                    <>
                                        <div className="border border-graydarkBg p-5 pb-0 mb-5 rounded-4px">
                                            {group.conditions.map((condition: any, conditionIndex: any) => (
                                                <div className="d-flex flex-wrap column-gap-15 mb-5">
                                                    <div className="flex-1">
                                                        <select onChange={formik.handleChange} name={`groups.${groupIndex}.conditions.${conditionIndex}.field`} value={condition.field} id="" className="form-select fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor">
                                                            <option value="">Select type</option>
                                                            <option value="subject">Title</option>
                                                            <option value="description">Description</option>
                                                        </select>
                                                        {isBtnSubmitted && formik.errors?.groups?.[groupIndex]?.conditions?.[conditionIndex]?.field && (
                                                            <div className="fv-plugins-message-container"><div className="fv-help-block fs-14">{formik.errors?.groups?.[groupIndex]?.conditions?.[conditionIndex]?.field}</div></div>
                                                        )}
                                                    </div>
                                                    <div className="flex-1">
                                                        <select onChange={formik.handleChange} name={`groups.${groupIndex}.conditions.${conditionIndex}.condition`} value={condition.condition} id="" className="form-select fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor">
                                                            <option value="">Select Conditions</option>
                                                            <option value="is_equal_to">Is equal to</option>
                                                            <option value="is_not_equal_to">Is not equal to</option>
                                                            <option value="contains">Contains</option>
                                                            <option value="does_not_contain">Does not contain</option>
                                                        </select>
                                                        {isBtnSubmitted && formik.errors?.groups?.[groupIndex]?.conditions?.[conditionIndex]?.condition && (
                                                            <div className="fv-plugins-message-container"><div className="fv-help-block fs-14">{formik.errors?.groups?.[groupIndex]?.conditions?.[conditionIndex]?.condition}</div></div>
                                                        )}
                                                    </div>
                                                    <div className="flex-1">
                                                        <input type="text" onChange={formik.handleChange} name={`groups.${groupIndex}.conditions.${conditionIndex}.value`} value={condition.value} className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor" />
                                                        {isBtnSubmitted && formik.errors?.groups?.[groupIndex]?.conditions?.[conditionIndex]?.value && (
                                                            <div className="fv-plugins-message-container"><div className="fv-help-block fs-14">{formik.errors?.groups?.[groupIndex]?.conditions?.[conditionIndex]?.value}</div></div>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {(group.conditions?.length > 1 || groupIndex > 0) &&
                                                            <button
                                                                className="btn d-inline-flex align-items-center p-0 justify-content-center w-30px h-40px rounded-4px fs-16"
                                                                type="button"
                                                                onClick={() => removeCondition(groupIndex, conditionIndex)}
                                                            >
                                                                <KTSVG
                                                                    path="/media/icons/duotune/general/gen027.svg"
                                                                    className="m-0 text-danger"
                                                                    svgClassName="w-auto h-auto"
                                                                />
                                                            </button>
                                                        }
                                                    </div>

                                                </div>
                                            ))}
                                            <div className="col-12 text-center">
                                                <button
                                                    className={`btn btn-primary d-inline-flex align-items-center p-0 justify-content-center w-25px h-25px rounded-4px me-5 fs-16 mb-5 ${conditioncount >= 10 && 'isofflinebutton'}`}
                                                    type="button"
                                                    title={conditioncount >= 10 ? "Can not add more than 10 conditions" : ''}
                                                    onClick={() => handleAddCondition(groupIndex)}
                                                >
                                                    <KTSVG
                                                        path="/media/icons/duotune/new-icons/plus.svg"
                                                        className="m-0"
                                                        svgClassName="w-15px h-15px"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ))}

                                <div className="col-12 text-center">
                                    <button
                                        className={`btn btn-primary d-inline-flex align-items-center p-0 justify-content-center w-40px h-40px rounded-4px me-5 fs-16 ${conditioncount >= 10 && 'isofflinebutton'}`}
                                        type="button"
                                        title={conditioncount >= 10 ? "Can not add more than 10 conditions" : ''}
                                        data-kt-menu-trigger="click"
                                        data-kt-menu-attach="parent"
                                        data-kt-menu-placement="bottom-start"
                                        data-kt-menu-flip="bottom"
                                        onClick={() => { handleAddGroup() }}
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/new-icons/plus.svg"
                                            className="m-0"
                                            svgClassName="w-20px h-20px"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>


                        <div className="d-flex py-4 justify-content-end border-top border-graydarkBg mt-6 position-sticky bg-white bottom-0" style={{ margin: '0', paddingLeft: '24px', paddingRight: '24px', zIndex: '1' }}>
                            <button className="btn btn-outline btn-outline-primary fw-normal border-primary rounded-4px px-4 py-0 h-32px fs-16 d-inline-flex align-items-center me-3" type="button" onClick={() => cancel(true)}>
                                Cancel
                            </button>
                            <button
                                type="submit"
                                onClick={() => setIsSubmitted(true)}
                                className="btn btn-primary fw-normal border-primary rounded-4px px-4 py-0 h-32px fs-16 d-inline-flex align-items-center"
                                disabled={loading}
                            >
                                {!loading && "Search"}
                                <Loading isLoading={loading} text={"Please wait..."}></Loading>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Popup>
        </>
    )
}

export default AppointmentFilters