
import { MbscPopupDisplay } from '../popup/popup.types.public';
import { PickerBase } from '../../shared/picker/picker';
import { IActiveChangeArgs, IWheelIndexChangeArgs } from '../../shared/wheel/wheel';
import { cssPrefix, has3d } from '../../util/dom';
import { constrain, floor, isArray, UNDEFINED } from '../../util/misc';
import { MbscScrollerOptions, MbscScrollerState, MbscScrollerWheel } from './scroller.types';

// tslint:disable no-non-null-assertion
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

/**
 * Returns the closest valid value on a wheel.
 * @hidden
 * @param wheel The wheel object.
 * @param val The current value.
 * @param direction Direction of the wheel movement.
 * @param disabled Disabled values on the wheel.
 */
export function getValid(wheel: MbscScrollerWheel, val: any, disabled?: Map<any, boolean>, direction?: number) {
  const min = wheel.min === UNDEFINED ? -Infinity : wheel.min;
  const max = wheel.max === UNDEFINED ? Infinity : wheel.max;
  const index = getIndex(wheel, val);
  let value = getValue(wheel, index);
  let value1 = value;
  let value2 = value;
  let dist1 = 0;
  let dist2 = 0;

  if (disabled && disabled.get(value)) {
    while (index - dist1 >= min && disabled.get(value1) && dist1 < 100) {
      dist1++;
      value1 = getValue(wheel, index - dist1);
    }

    while (index + dist2 < max && disabled.get(value2) && dist2 < 100) {
      dist2++;
      value2 = getValue(wheel, index + dist2);
    }

    // If no valid value found, return the invalid value
    if (disabled.get(value1) && disabled.get(value2)) {
      return value;
    }

    if (((dist2 < dist1 && dist2 && direction !== -1) || !dist1 || index - dist1 < 0 || direction === 1) && !disabled.get(value2)) {
      value = value2;
    } else {
      value = value1;
    }
  }

  return value;
}

/** @hidden */
function getItemValue(item: any): any {
  return item !== UNDEFINED ? (item.value !== UNDEFINED ? item.value : item.display !== UNDEFINED ? item.display : item) : item;
}

/** @hidden */
function getItem(wheel: MbscScrollerWheel, index: number): any {
  if (wheel.getItem) {
    return wheel.getItem(index);
  }
  const data = wheel.data || [];
  const len = data.length;
  const i = index % len;
  return wheel._circular ? data[i >= 0 ? i : i + len] : data[constrain(index, 0, len - 1)];
}

/**
 * Returns the index of a value on a wheel.
 * In case of multiple selection returns the index of the first selected item.
 * @hidden
 * @param wheel
 * @param value
 * @returns Returns the index of the value or the first value in case of multiple values.
 */
function getIndex(wheel: MbscScrollerWheel, value: any) {
  const val = wheel.multiple ? (value && value.length && value[0]) || UNDEFINED : value;
  return (wheel.getIndex ? +wheel.getIndex(value) : wheel._map!.get(val)) || 0;
}

/** @hidden */
// This function returns the first index when circular data is provided on wheels.
// Note: when there are circular wheels, the index can go past the data length or below 0.
// In these cases we take the remainder as the index from the division by data length.
function getFirstIndex(wheel: MbscScrollerWheel, index: number): number {
  if (wheel.getItem && wheel.getIndex) {
    return wheel.getIndex(getItemValue(wheel.getItem(index)));
  }
  const data = wheel.data || [];
  const len = data.length;
  const i = index % len;
  return !len ? 0 : i >= 0 ? i : i + len;
}

/** @hidden */
function getValue(wheel: any, index: number) {
  return getItemValue(getItem(wheel, index));
}

/** @hidden */

export class ScrollerBase extends PickerBase<MbscScrollerOptions, MbscScrollerState, any, any[]> {
  /** @hidden */
  public static defaults: MbscScrollerOptions = {
    itemHeight: 40,
    rows: 5,
    selectOnScroll: true,
    showOnClick: true,
    // showOnFocus: true,
  };

  // tslint:disable variable-name

  protected static _name = 'Scroller';

  /** @hidden */
  public _circular?: boolean | boolean[];
  /** @hidden */
  public _disabled?: Array<Map<any, boolean>>;
  /** @hidden */
  public _displayStyle?: MbscPopupDisplay;
  /** @hidden */
  public _indexes: number[] = [];
  /** @hidden */
  public _activeIndexes: number[] = [];
  /** @hidden */
  public _lineStyle?: any;
  /** @hidden */
  public _overlayStyle?: any;
  /** @hidden */
  public _rows!: number;
  /** @hidden */
  public _scroll3d!: boolean;
  /** @hidden */
  public _wheels: MbscScrollerWheel[][] = [];

  private _batches: number[] = [];
  /**
   * Stores the last index that was set when selecting a value
   * Check out the _setIndexes method for more explanations.
   */
  private _lastIndexes: number[] = [];
  private _shouldSetIndex?: boolean;
  private _indexFromValue?: boolean;
  private _wheelMap!: MbscScrollerWheel[];

  public _onSet = () => {
    this._setOrUpdate();
  };

  /**
   * Triggered when the active item is changed via keyboard navigation.
   * When the selectOnScroll is true the onWheelIndexChange is triggered instead,
   * because selection also happens.
   */
  public _onActiveChange = ({ wheel, index }: IActiveChangeArgs) => {
    const wheelIndex = wheel._key!;
    this._activeIndexes[wheelIndex] = index; // set the active index

    // we need to update the current index if the active item is outside of the visible items
    // so the wheel is scrolled down/up to be visible
    const indexes = this._indexes;
    let currentIndex = indexes[wheelIndex];
    if (this._scroll3d) {
      currentIndex = index;
    } else if (index - currentIndex >= this._rows) {
      currentIndex++;
    } else if (index < currentIndex) {
      currentIndex--;
    }
    indexes[wheelIndex] = currentIndex; // update the index
    this.forceUpdate();
  };

  public _onWheelIndexChange = (args: IWheelIndexChangeArgs) => {
    const s = this.s;
    const wheel: MbscScrollerWheel = args.wheel;
    const key = wheel._key!;
    const isMultiple = wheel.multiple;
    const newValue = getValue(wheel, args.index);
    const disabled = this._disabled && this._disabled[key] && this._disabled[key].get(newValue);
    const lengths: number[] = [];
    const selectOnScroll = s.selectOnScroll;
    const updateIndex = selectOnScroll || !args.click;

    if (updateIndex) {
      this._lastIndexes[key] = this._indexes[key] = args.index;
      // update batches too
      this._indexes.forEach((val: any, i: number) => {
        const w = this._wheelMap[i];
        const len = w.data ? w.data.length : 0;
        this._batches[i] = len ? floor(val / len) : 0;
        lengths[i] = len;
      });
    }

    this._activeIndexes[key] = args.index;

    const beforeTempValue = this._get(this._tempValueRep);
    const itemTap = !!args.click && !disabled;
    const scrollOrTapSelect = selectOnScroll || itemTap;

    // Update the Temp. Value Representation

    if (isMultiple) {
      if (itemTap) {
        const selectionArr = [...(this._tempValueRep[key] || [])];
        if (args.selected === false) {
          // add
          selectionArr.push(newValue);
        } else if (args.selected === true) {
          selectionArr.splice(selectionArr.indexOf(newValue), 1);
        }
        this._tempValueRep[key] = selectionArr;
      }
    } else if (scrollOrTapSelect) {
      this._tempValueRep[key] = newValue;
    }

    if (s.onWheelMove && args.index !== UNDEFINED) {
      const wheelRep = s.onWheelMove({
        dataItem: getItem(wheel, args.index),
        selection: scrollOrTapSelect,
        wheelIndex: key,
      });
      if (wheelRep) {
        wheelRep.forEach((v, i) => {
          if (v !== UNDEFINED) {
            this._tempValueRep[i] = v;
          }
          if (!scrollOrTapSelect) {
            // there will be no validation in this case down the line, so we need to move the wheel to the new index
            const w = this._wheelMap[i];
            const newIndex = getIndex(w, v);
            this._constrainIndex(newIndex, w);
          }
        });
      }
    }

    // Run validation on the tempValue

    if (scrollOrTapSelect) {
      this._validate(key, args.diff > 0 ? 1 : -1);
    }

    // Update wheel offset with the new validated value
    // _offset is used to compensate for wheel length changes. For example changing the month from March to February
    // will change the days wheel length from 31 to 28-29, which equals 2-3 index difference for each circular batches
    // that are rendered. The index difference is added as the offset, and later used to compensate for this by adding
    // margins to the wheel (in the ScrollView).
    if (selectOnScroll) {
      this._tempValueRep.forEach((val: any, i: number) => {
        const w = this._wheelMap[i];
        const len = w.data ? w.data.length : 0;
        const oldIndex = this._indexes[i];
        const newIndex = getIndex(w, val) + this._batches[i] * len;
        this._activeIndexes[i] = this._lastIndexes[i] = this._indexes[i] = newIndex;
        w._offset = len !== lengths[i] ? newIndex - oldIndex : 0;
      });
    }

    // Update underlying components or set the new value

    const currentTempValue = this._get(this._tempValueRep);
    const tempValueChanged = !this._valueEquals(beforeTempValue, currentTempValue);

    if (tempValueChanged || (args.click && this._live && !this._valueEquals(this.value, currentTempValue))) {
      // If the temp value changed, or in live mode we clicked the selected item
      this._setOrUpdate(!tempValueChanged);
    } else {
      // In the case of tap select (multi select, and single select desktop mode) when we spin the wheel,
      // or the temp value did not change, we need to propagate down the new index.
      this.forceUpdate();
    }

    if (this._live && itemTap && wheel.closeOnTap) {
      this.close();
    }
  };

  public _initWheels() {
    let key = 0;
    const wheels = this.s.wheels || [];
    this._wheelMap = [];
    wheels.forEach((wheelGroup: MbscScrollerWheel[]) => {
      wheelGroup.forEach((wheel: MbscScrollerWheel) => {
        this._initWheel(wheel, key);
        this._wheelMap[key] = wheel;
        key++;
      });
    });
    this._wheels = wheels;
  }

  public _shouldValidate(s: MbscScrollerOptions, prevS: MbscScrollerOptions): boolean {
    // TODO: wheel check is moved to the datetime currently, but it should be checked here
    // We removed it, because select filtering changes the wheel, but re-validation of the value should not happen in this case.
    // A possible solution would be that the select only changes the wheel data only, but in this case we need to force the
    // re-render of the scroller somehow
    // const superValidate = s.shouldValidate ? s.shouldValidate(s, prevS) : false;
    // return superValidate || s.wheels !== prevS.wheels;
    return s.shouldValidate ? s.shouldValidate(s, prevS) : false;
  }

  public _valueEquals(v1: any, v2: any): boolean {
    if (this.s.valueEquality) {
      return this.s.valueEquality(v1, v2);
    }
    return v1 === v2;
  }

  // tslint:enable variable-name

  protected _render(s: MbscScrollerOptions, state: MbscScrollerState) {
    const props = this.props || {};
    const resp = this._respProps || {};
    const prevS = this._prevS;
    const circular = this._touchUi ? s.circular : false;
    const rows = this._touchUi ? s.rows! : resp.rows || props.rows || 7;

    this._displayStyle = s.displayStyle || s.display!;
    this._scroll3d = s.scroll3d && this._touchUi && has3d;

    if (s.itemHeight !== prevS.itemHeight || rows !== this._rows) {
      this._rows = rows;
      this._lineStyle = {
        height: s.itemHeight! + 'px',
      };

      if (this._scroll3d) {
        const translateZ = 'translateZ(' + ((s.itemHeight! * rows) / 2 + 3) + 'px';
        this._overlayStyle = {};
        this._overlayStyle[cssPrefix + 'transform'] = translateZ;
        this._lineStyle[cssPrefix + 'transform'] = 'translateY(-50%) ' + translateZ;
      }
    }

    if (s.wheels !== prevS.wheels || circular !== this._circular) {
      this._batches = [];
      this._shouldSetIndex = true;
      this._circular = circular;
      this._initWheels();
    }

    super._render(s, state);

    if (this._shouldSetIndex) {
      this._setIndexes();
      this._shouldSetIndex = this._indexFromValue = false;
    }

    if (s.wheels !== prevS.wheels && prevS.wheels !== UNDEFINED) {
      // Trigger wheel index change if wheels changed dynamically,
      // this will validate the values on each wheel
      // TODO: we need a better solution here, maybe this should be triggered from the wheel/scrollview somehow
      setTimeout(() => {
        for (const wheel of this._wheelMap) {
          this._onWheelIndexChange({
            diff: 0,
            index: this._indexes[wheel._key!],
            wheel,
          });
        }
      });
    }
  }

  protected _writeValue(elm: HTMLInputElement, text: string, value: any): boolean {
    if (this.s.writeValue) {
      return this.s.writeValue(elm, text, value);
    }
    return super._writeValue(elm, text, value);
  }

  // tslint:disable variable-name

  protected _copy(value: any[]) {
    return value.slice(0);
  }

  protected _format(value: any[]): string {
    if (this.s.formatValue) {
      return this.s.formatValue(value);
    }
    return value.join(' ');
  }

  protected _get(value: any[]) {
    if (this.s.getValue) {
      return this.s.getValue(value);
    }
    return value;
  }

  protected _parse(valueStr: string): any[] {
    if (this.s.parseValue) {
      return this.s.parseValue(valueStr);
    }

    const ret: any[] = [];
    let values: any[] = [];
    let i = 0;

    if (valueStr !== null && valueStr !== UNDEFINED) {
      values = (valueStr + '').split(' ');
    }

    this._wheels.forEach((wheelGroup: MbscScrollerWheel[]) => {
      wheelGroup.forEach((wheel: MbscScrollerWheel) => {
        const data = wheel.data || [];
        const len = data.length;
        // Default to first wheel value if not found
        let value = getItemValue(data[0]);
        let j = 0;
        // Don't do strict comparison, because the parsed value is always string,
        // but the wheel value can be number as well
        /* eslint-disable eqeqeq */
        // tslint:disable-next-line: triple-equals
        while (value != values[i] && j < len) {
          value = getItemValue(data[j]);
          j++;
        }
        /* eslint-enable eqeqeq */
        ret.push(value);
        i++;
      });
    });

    return ret;
  }

  /**
   * Does the validation
   * @param index Index of the wheel
   * @param direction Direction the wheel was moved
   */
  protected _validate(index?: number, direction?: number) {
    if (this.s.validate) {
      const ret = this.s.validate.call(this._el, {
        direction,
        index,
        values: this._tempValueRep.slice(0),
        wheels: this._wheelMap,
      });

      this._disabled = ret.disabled;

      if (ret.init) {
        this._initWheels();
      }

      if (ret.indexes) {
        ret.indexes.forEach((value: any, i: number) => {
          if (value !== UNDEFINED) {
            const w = this._wheelMap[i];
            const newIndex = getIndex(w, value);
            this._constrainIndex(newIndex, w);
          }
        });
      }

      if (ret.valid) {
        this._tempValueRep = ret.valid.slice(0);
      } else {
        this._wheelMap.forEach((wheel, i) => {
          this._tempValueRep[i] = getValid(wheel, this._tempValueRep[i], this._disabled && this._disabled[i], direction);
        });
      }
    }
  }

  protected _onOpen() {
    this._batches = [];
    this._shouldSetIndex = true;
    this._indexFromValue = true;
  }

  protected _onParse() {
    this._shouldSetIndex = true;
  }

  // tslint:enable variable-name

  private _initWheel(wheel: MbscScrollerWheel, key: number) {
    const circular = this._circular;

    wheel._key = key;
    wheel._map = new Map<any, number>();
    wheel._circular =
      circular === UNDEFINED
        ? wheel.circular === UNDEFINED
          ? wheel.data && wheel.data.length > this._rows
          : wheel.circular
        : isArray(circular)
        ? circular[key]
        : circular;

    if (wheel.data) {
      wheel.min = wheel._circular ? UNDEFINED : 0;
      wheel.max = wheel._circular ? UNDEFINED : wheel.data.length - 1;
      // Map keys to index
      wheel.data.forEach((item, i: number) => {
        wheel._map!.set(getItemValue(item), i);
      });
    }
  }

  /** Indexes must be set in two occasions:
   * 1. When the picker is opened
   * 2. When the wheels are changed (ex. Select filtering)
   *
   * The new index can come from the value (when opening the scroller), or from the currently scrolled to item
   */
  private _setIndexes() {
    const currentIndexes = this._indexes || [];
    this._indexes = [];
    this._activeIndexes = [];
    this._tempValueRep.forEach((val: any, i: number) => {
      const w = this._wheelMap[i];
      const len = w.data ? w.data.length : 0;
      const newIndex = getIndex(w, val);
      if (this.s.selectOnScroll) {
        this._activeIndexes[i] = this._indexes[i] = newIndex + (this._batches[i] || 0) * len;
      } else {
        let currentIndex = newIndex; // comes from the selected value
        if (!this._indexFromValue) {
          // comes from the currently "scrolled to item"
          currentIndex = this._prevS.wheels !== this.s.wheels ? 0 : currentIndexes[i];
          if (currentIndex !== UNDEFINED) {
            currentIndex = getFirstIndex(w, currentIndex) + (this._batches[i] || 0) * len;
          }
        }
        // the current index is the index of the topmost visible item on the scroller, but the selected item can be under that.
        // So if the currentIndex comes from the selected value, the currentIndex can turn up greater than the topmost item's
        // index. So we need to constrain the currentIndex in this case, otherwise at the end of the list, there will be an
        // empty space
        this._constrainIndex(currentIndex, w);
      }
    });
  }

  /**
   * The newIndex is the index of the topmost visible item on the scroller, but the selected item can be under that.
   * So if the newIndex comes from the selected value, the newIndex can turn up greater than the topmost item's
   * index. So we need to constrain the newIndex in this case, otherwise at the end of the list, there will be an
   * empty space
   * @param newIndex
   * @param wheel
   */
  private _constrainIndex(newIndex: number | undefined, wheel: MbscScrollerWheel) {
    const i = wheel._key!;
    if (newIndex !== UNDEFINED && wheel.data) {
      // constrain so we don't get an empty space at the end of the list
      if (!wheel.spaceAround) {
        // the index needs to be constrained only in case of desktop styling
        newIndex = constrain(newIndex, 0, Math.max(wheel.data.length - this._rows, 0));
      }
      this._activeIndexes[i] = this._indexes[i] = newIndex;
    } else {
      this._activeIndexes[i] = this._indexes[i] = this._lastIndexes[i] || 0; // we use the last available index or default to zero
    }
  }
}
