import axios, { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_THEME_API_URL;

const API_URL_NODE = process.env.REACT_APP_API_URL;
const APILOG_URL_NODE = `${API_URL_NODE}general_logs`;
const ENDPOINTS_URL_NODE = `${API_URL_NODE}general_logs/endpoints`;

const getAllLog = (query: string): Promise<any> => {
  return axios
    .get(`${APILOG_URL_NODE}?${query}`)
    .then((d: AxiosResponse<any>) => d.data);
};

const getEndpointsList = (query: string): Promise<any> => {
  return axios
    .get(`${ENDPOINTS_URL_NODE}?${query}`)
    .then((d: AxiosResponse<any>) => d.data);
};

export {
  getAllLog,
  getEndpointsList
};