import "./../../../../_metronic/assets/sass/custom.scss";
import { useQuery } from "react-query";
import { getTotalsSuperAdmin } from "../../../../_metronic/requests/DashboardRequest";
import { getSuperadminUserByToken } from "../../auth/core/_requests";
import { Cards } from "./Cards";
import React, { useEffect } from "react";

const DashboardPage = () => {
  const {
    data: response,
  } = useQuery(
    'TotalsSuperAdmin',
    () => {
      return getTotalsSuperAdmin('');
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );
  const usersData = [{ count: response?.data?.usersCount }];
  const usersCountData = { isShowCount: true, count: response?.data?.usersCount };
  const organizationData = [{ count: response?.data?.organizationCount }];
  const organizationCountData = { isShowCount: true, count: response?.data?.organizationCount };
  
  const {
    data:superadminuser,}
    = useQuery('superadminUser', () => {return getSuperadminUserByToken('')})
    useEffect(() => {
    },[superadminuser])
    let userName = superadminuser?.data?.data?.username

  return (
    <>
        <div className="mt-2   px-4 py-6 overflow-auto mh-100 px-6">
         <h1 className="px-4 mt-3"> Welcome, {userName} </h1>
        </div>
      <div className="row m-0 px-7 py-6 overflow-auto mh-100 dashMain">
        <div className='col-xxl-3'>
          <Cards title="Active Organisations" data={organizationData} countData={organizationCountData} isLoading={false} icon={'/media/icons/duotune/extra/briefcase.svg'} redirect={"/superadmin/organisation-management/organisations"}></Cards>
        </div>
        <div className='col-xxl-3'>
          <Cards title="Total Users" data={usersData} countData={usersCountData} isLoading={false} icon={'/media/icons/duotune/extra/archive-book.svg'} redirect={"/superadmin/user-management/users"}></Cards>
        </div>
      </div>
    </>
  );
}

export default DashboardPage;