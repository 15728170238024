
import { BaseComponent, IBaseProps } from '../../base';
import { isInvalid } from '../../util/date-validation';
import { addTimezone, getDateStr } from '../../util/datetime';
import { MbscDateType, MbscTimezonePlugin } from '../../util/datetime.types.public';
import { ARRAY7 } from '../../util/misc';
import { MbscCalendarDayData } from './calendar-day';
import {
  ICalendarLabelData,
  ILabelDragData,
  MbscCalendarEvent,
  MbscCalendarEventData,
  MbscCalendarMarked,
  MbscResource,
} from './calendar-view.types';

// tslint:disable no-non-null-assertion
// tslint:disable directive-class-suffix
// tslint:disable directive-selector

/** @hidden */
export interface IMonthViewProps extends IBaseProps {
  activeDate: number;
  amText?: string;
  calendarType?: 'year' | 'month' | 'week';
  cellTextHeight?: number;
  clickToCreate?: boolean | 'double' | 'single';
  colors?: { [key: string]: any[] };
  dragData?: ILabelDragData;
  dragToCreate?: boolean;
  dragToMove?: boolean;
  dragToResize?: boolean;
  dayNames: string[];
  // dayNamesMin: string[];
  dayNamesShort: string[];
  dataTimezone?: string;
  displayTimezone?: string;
  events?: { [key: string]: MbscCalendarEvent[] };
  eventText?: string;
  eventsText?: string;
  exclusiveEndDates?: boolean;
  firstDay: number;
  firstPageDay: number;
  hasMarks?: boolean;
  hoverEnd?: number;
  hoverStart?: number;
  isActive: boolean;
  isPicker?: boolean;
  invalid?: { [key: string]: any[] };
  labels?: { [key: string]: ICalendarLabelData };
  labelHeight?: number;
  marked?: { [key: string]: MbscCalendarMarked[] };
  max?: MbscDateType;
  min?: MbscDateType;
  monthNames: string[];
  monthNamesShort: string[];
  pmText?: string;
  rangeEnd?: number;
  rangeStart?: number;
  resourcesMap?: { [key: number]: MbscResource };
  selectedDates?: { [key: number]: boolean | Date };
  selectedEventsMap?: { [key: number]: MbscCalendarEvent };
  showEventTooltip?: boolean;
  showOuter?: boolean;
  showSingleMark?: boolean;
  showWeekDays?: boolean;
  showWeekNumbers?: boolean;
  todayText: string;
  timeFormat?: string;
  timezonePlugin?: MbscTimezonePlugin;
  valid?: { [key: string]: any[] };
  variableRow?: boolean;
  weeks?: number;
  weekText?: string;
  getDate(y: number, m: number, d: number): Date;
  getDay(d: Date): number;
  getMonth(d: Date): number;
  getWeekNumber?(d: Date): number;
  getYear(d: Date): number;
  onDayClick?(args: any, inst: any): void;
  onDayDoubleClick?(args: any, inst: any): void;
  onDayRightClick?(args: any, inst: any): void;
  onLabelClick?(args: any, inst: any): void;
  onLabelDoubleClick?(args: any, inst: any): void;
  onLabelRightClick?(args: any, inst: any): void;
  onLabelHoverIn?(args: any, inst: any): void;
  onLabelHoverOut?(args: any, inst: any): void;
  onLabelDelete?(args: any): void;
  onLabelUpdateStart?(args: any): void;
  onLabelUpdateMove?(args: any): void;
  onLabelUpdateEnd?(args: any): void;
  onLabelUpdateModeOn?(args: any): void;
  onLabelUpdateModeOff?(args: any): void;
  onDayHoverIn?(args: any, inst: any): void;
  onDayHoverOut?(args: any, inst: any): void;
  // Customization options
  renderDay?(event: MbscCalendarDayData): any;
  renderDayContent?(event: MbscCalendarDayData): any;
  renderLabel?(event: MbscCalendarEventData): any;
  renderLabelContent?(event: MbscCalendarEventData): any;
}

/** @hidden */

export class MonthViewBase extends BaseComponent<IMonthViewProps, any> {
  // These are public because of the angular template only
  // ---
  // tslint:disable variable-name
  public _days!: number[];
  public _rows!: any[];
  public _rowHeights!: number[];

  public _isActive(d: number) {
    return this.s.isActive && d === this.s.activeDate;
  }

  public _isInvalid(d: number) {
    const s = this.s;
    const localDate = new Date(d);
    const timezoneDate = addTimezone(s, localDate);
    return isInvalid(s, timezoneDate, s.invalid, s.valid, +s.min!, +s.max!);
  }

  public _isSelected(d: number) {
    const localDate = new Date(d);
    const timezoneDate = addTimezone(this.s, localDate);
    return !!this.s.selectedDates![+timezoneDate];
  }

  public _getWeekNr(s: IMonthViewProps, date: number): string {
    const d = new Date(date);
    return '' + s.getWeekNumber!(s.getDate(d.getFullYear(), d.getMonth(), d.getDate() + ((7 - s.firstDay + 1) % 7)));
  }
  // tslint:enable variable-name

  protected _render(s: IMonthViewProps) {
    // TODO: optimize what to calculate on render
    const weeks = s.weeks || 6;
    const firstWeekDay = s.firstDay;
    const firstDay = new Date(s.firstPageDay!);
    const year = s.getYear(firstDay);
    const month = s.getMonth(firstDay);
    const day = s.getDay(firstDay);
    const weekDay = s.getDate(year, month, day).getDay();
    const offset = firstWeekDay - weekDay > 0 ? 7 : 0;

    let row: any[] = [];
    let maxLabels = 0;

    this._rowHeights = [];
    this._rows = [];
    this._days = ARRAY7;

    for (let i = 0; i < 7 * weeks; i++) {
      const curr = s.getDate(year, month, i + firstWeekDay - offset - weekDay + day);
      const key = getDateStr(curr);
      const displayMonth = s.getMonth(curr);
      const outer = displayMonth !== month && s.calendarType !== 'week';
      const marked = s.marked && s.marked[key];
      const marks = marked ? (s.showSingleMark ? [{}] : marked) : null;
      const labels = s.labels && s.labels[key];
      const labelCount = labels ? labels.data.length : 0;
      const isWeekStart = i % 7 === 0;

      if (s.variableRow) {
        // Don't render rows containing fully outer days
        if (isWeekStart && outer && i) {
          break;
        }

        if (labelCount > maxLabels) {
          maxLabels = labelCount;
        }

        // Row end
        if (i % 7 === 6) {
          this._rowHeights.push(maxLabels * (s.labelHeight || 20) + (s.cellTextHeight || 0) + 3);
          maxLabels = 0;
        }
      }

      if (isWeekStart) {
        row = [];
        this._rows.push(row);
      }

      row.push({
        colors: s.colors && s.colors[key],
        date: +curr,
        day: s.dayNames[curr.getDay()],
        display: outer ? s.showOuter : true,
        events: s.events && s.events[key],
        labels,
        marks,
        month: s.monthNames[displayMonth],
        monthShort: s.monthNamesShort[displayMonth],
        outer,
        text: s.getDay(curr),
        year: s.getYear(curr),
      });
    }
  }
}
