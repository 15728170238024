
export type OutlookData = {
    enableOutLookIntegration: boolean,
    clientId: string,
    clientSecret: string,
    tenentId: string,
    defaultOutlookEmail: string,
    type_task: string,
    title_task: string,
    description_task: string,
    type_ticket: string,
    title_ticket: string,
    description_ticket: string,
    type_serviceCall: string,
    title_serviceCall: string,
    description_serviceCall: string,
}

export const initalOutlookData:OutlookData = {
    enableOutLookIntegration: false,
    clientId: '',
    clientSecret: '',
    tenentId: '',
    defaultOutlookEmail: '',
    type_task:'',
    title_task:'',
    description_task:'',
    type_ticket:'',
    title_ticket:'',
    description_ticket:'',
    type_serviceCall:'',
    title_serviceCall:'',
    description_serviceCall:'',
}