import {useQueryClient, useMutation} from 'react-query'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
// import {deleteSelectedEmail} from '../../core/_requests'
import { KTSVG } from '../../../../../../_metronic/helpers'

const IntrojsGrouping = () => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()

  const deleteSelectedItems = useMutation(() => deleteSelectedEmail(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.EMAIL_LIST}-${query}`])
      clearSelected()
    },
  })

  return (
    <div className='d-flex w-lg-100 justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button
        type='button'
        className='btn btn-danger'
        data-bs-toggle="modal"
        data-bs-target="#kt_modal_1"
        // onClick={async () => await deleteSelectedItems.mutateAsync()}
      >
        Delete Selected
      </button>
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Delete</h5>
        <div
          className="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon svg-icon-2x"
          />
        </div>
      </div>
      <div className="modal-body text-start">
        <p>Are you sure you want to delete this {'tour/hint(s)'} </p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          data-bs-dismiss="modal"
        >
          No
        </button>
        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={async () => await deleteSelectedItems.mutateAsync()}>
          Yes
        </button>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export {IntrojsGrouping}