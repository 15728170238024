import { ID, Response } from "../../../../_metronic/helpers";

export type ProfileDetail = {
  [x: string]: unknown;
  id?: ID;
  // name?: string;
  username?: string;
  email?: string;
  avatar?: string;
  is_active?: boolean,
  phone_no?: string,
  mobile_no?: string,
  note?: string,
  showTooltipVisibility?: {
    Dashboard: boolean,
    Planning: boolean,
    Users: boolean,
    Projects: boolean,
    Notifications: boolean,
    LogsLogin: boolean,
    LogsAPIs: boolean,
    SettingsGeneral: boolean,
    SettingsEmails: boolean,
    SettingsSSO: boolean,
    AISettings: boolean,
    MyOrganisation: boolean,
    BillingAndSubscription_Invoice: boolean,
    BillingAndSubscription_Payment: boolean,
    BillingAndSubscription_Subscription: boolean,
    Profile: boolean,
  }
};

export type ProfileDetailQueryResponse = Response<Array<ProfileDetail>>;

export const initialProfileDetail: ProfileDetail = {
  // name: "",
  username: "",
  email: "",
  is_active: false,
  phone_no: "",
  mobile_no: "",
  note: "",
  showTooltipVisibility: {
    Dashboard: true,
    Planning: true,
    Users: true,
    Projects: true,
    Notifications: true,
    LogsLogin: true,
    LogsAPIs: true,
    SettingsGeneral: true,
    SettingsEmails: true,
    SettingsSSO: true,
    AISettings: true,
    MyOrganisation: true,
    BillingAndSubscription_Invoice: true,
    BillingAndSubscription_Payment: true,
    BillingAndSubscription_Subscription: true,
    Profile: true,
  }
};
