export interface IaiSetting {
    PlannigDataArray : {
        planWithinShift: {
            Setting: {value: boolean};
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        bufferHours: {
            Setting: {value: number};
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        planningOutside: {
            Setting: {value: boolean};
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        ticketPlanningBefore: {
            Setting: {value: boolean};
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        ticketPlanningAfter: {
            Setting: {value: boolean};
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        taskPlanningBefore: {
            Setting: {value: boolean};
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        taskPlanningAfter: {
            Setting: {value: boolean};
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        planOnSetDays: {
            Setting: {value: Array<string>};
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        minTimeServiceCall: {
            Setting: {value: number};
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        maxTimeServiceCall: {
            Setting: {value: number};
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        timeBetweenServiceCall: {
            Setting: {value: number};
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        allowOverlapForPlannig: {
            Setting: {value: boolean};
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        allowReplanningFuture: {
            Setting: {value: boolean};
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        onlyPlanItemsResource: {
            Setting: {value: boolean};
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        planningPriority: {
            list: Array<string>;
            Visible: boolean;
            Editable: boolean;
        };
    },
    ProjectDataArray: {
        phaseAndProjectStartEnd: {
            SpankLevel: number,
            Visible: boolean,
            Editable: boolean,
        };
        neverPlanBefore: {
            SpankLevel: number,
            Visible: boolean,
            Editable: boolean,
        };
        neverPlanTaskBefore: {
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        neverPlanTaskBeforePhase: {
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        taskForAssignedResource: {
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        planTaskWithLabel: {
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        planServiceCall: {
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        allowOverlapForProject: {
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        minServiceCall: {
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        maxServiceCall: {
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        allowReplanningFutureSC: {
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        autoAdjustTasks: {
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        serviceCallTitle: {
            text: string;
            specialcharacter: string;
            list: Array<string>;
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        multiPlanServiceCall: {
            list: Array<string>;
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
        plannigPriorityForProject : {
            list: Array<string>;
            SpankLevel: number;
            Visible: boolean;
            Editable: boolean;
        };
    }
}

export const initalSettingData:IaiSetting = {
    PlannigDataArray : {
        planWithinShift: {
            Setting: {value: false},
            SpankLevel: 1,
            Visible: false,
            Editable: false,
        },
        bufferHours: {
            Setting: {value: 2},
            SpankLevel: 1,
            Visible: false,
            Editable: false,
        },
        planningOutside: {
            Setting: {value: false},
            SpankLevel: 1,
            Visible: false,
            Editable: false,
        },
        ticketPlanningBefore: {
            Setting: {value: false},
            SpankLevel: 1,
            Visible: false,
            Editable: false,
        },
        ticketPlanningAfter: {
            Setting: {value: false},
            SpankLevel: 1,
            Visible: false,
            Editable: false,
        },
        taskPlanningBefore: {
            Setting: {value: false},
            SpankLevel: 1,
            Visible: false,
            Editable: false,
        },
        taskPlanningAfter: {
            Setting: {value: false},
            SpankLevel: 1,
            Visible: false,
            Editable: false,
        },
        planOnSetDays: {
            Setting: {value: []},
            SpankLevel: 1,
            Visible: false,
            Editable: false,
        },
        minTimeServiceCall: {
            Setting: {value: 1},
            SpankLevel: 1,
            Visible: false,
            Editable: false,
        },
        maxTimeServiceCall: {
            Setting: {value: 1},
            SpankLevel: 1,
            Visible: false,
            Editable: false,
        },
        timeBetweenServiceCall: {
            Setting: {value: 1},
            SpankLevel: 1,
            Visible: false,
            Editable: false,
        },
        allowOverlapForPlannig: {
            Setting: {value: false},
            SpankLevel: 1,
            Visible: false,
            Editable: false,
        },
        allowReplanningFuture: {
            Setting: {value: true},
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        onlyPlanItemsResource: {
            Setting: {value: false},
            SpankLevel: 1,
            Visible: false,
            Editable: false,
        },
        planningPriority: {
            list: [],
            Visible: false,
            Editable: false,
        },
    },
    ProjectDataArray: {
        phaseAndProjectStartEnd: {
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        neverPlanBefore: {
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        neverPlanTaskBefore: {
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        neverPlanTaskBeforePhase: {
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        taskForAssignedResource: {
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        planTaskWithLabel: {
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        planServiceCall: {
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        allowOverlapForProject: {
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        minServiceCall: {
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        maxServiceCall: {
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        allowReplanningFutureSC: {
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        autoAdjustTasks: {
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        serviceCallTitle: {
            text: '',
            specialcharacter: '',
            list: [],
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        multiPlanServiceCall: {
            list: [],
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
        plannigPriorityForProject : {
            list: [],
            SpankLevel: 1,
            Visible: true,
            Editable: true,
        },
    }
}
