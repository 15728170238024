import React from "react";
import { KTSVG } from "../../../../../_metronic/helpers";
const CreateButton: React.FC<any> = ({
  setisCreatePhaseOpen,
  isCreateProjectOpen,
  setIsPopupOpen,
  setisCreateProjectOpen,
  isCreatePhaseOpen,
  setIsCreateTaskOpen,
}) => {
  const handleCreateButton = (e: any) => {
    if (isCreatePhaseOpen === false && isCreateProjectOpen === false) {
      if (e.target.id == "phase") {
        setisCreatePhaseOpen(true);
        setIsPopupOpen(true);
      } else if (e.target.id === "project") {
        setisCreateProjectOpen(true);
      } else if (e.target.id == "task") {
        setIsCreateTaskOpen(true);
        setIsPopupOpen(true);
      }
    }
  };
  
  return (
    <div className="filterBtns">
      <button
        className="btn btn-primary d-inline-flex align-items-center p-0 px-5 h-32px rounded-4px me-1 fs-16"
        type="button"
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
        id="projects-create"
      >
        <KTSVG
          path="/media/icons/duotune/new-icons/plus.svg"
          className="me-2"
          svgClassName="w-auto h-auto"
        />
        <small className="fs-100">New</small>
      </button>
      <div
        className="menu menu-sub menu-sub-dropdown bg-white px-3 py-5 fs-13"
        data-kt-menu="true"
      >
        <a className="fs-16 d-flex align-items-center text-black fw-semibold text-hover-primary mb-3"
          data-name="project"
          href="javascript:void(0)"
          id="project"
          onClick={handleCreateButton}
        >
          Create Project / Phase
        </a>
        <a
          className="fs-16 d-flex align-items-center text-black fw-semibold text-hover-primary"
          data-name="task"
          href="javascript:void(0)"
          onClick={handleCreateButton}
          id="task"
        >
          Create Task
        </a>
      </div>
    </div>
  );
};

export default React.memo(CreateButton);
