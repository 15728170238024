import React, {FC} from 'react'
import { ApiLog } from '../../../core/_model';


type Props = {
  user: ApiLog,
}

const ApiLogSuccessCell: FC<Props> = ({user}) => {
  
 return (
  <>
  <div className='d-flex align-items-center'>
<div className='d-flex flex-column'>
    {user?.resoponse_status === 200 ?
    (<span className="px-3 p-2 fs-14 min-w-55px badge badge-light-success">{user?.resoponse_status} success</span>)
    :
    (<span className="px-3 p-2 fs-14 min-w-55px badge badge-light-danger">{user?.resoponse_status} error</span>)
    }  
    </div>

  </div>
        </>
)}

export {ApiLogSuccessCell}
