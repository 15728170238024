import axios, { AxiosError, AxiosResponse } from "axios";
import { Tooltip } from "./_types";
import { ID, Response } from "../../../../../_metronic/helpers";

const URL = process.env.REACT_APP_API_URL;
const TOOLTIPURL = `${URL}introjs/save-introjs-data`;

const saveTooltipData = async (data: any) => {
  return await axios
    .post(TOOLTIPURL, data)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((err: AxiosError) => console.log(err));
};

const getTooltipData = async () => {
  return await axios
    .get(`${URL}introjs/get-introjs-data`)
    .then((response: AxiosResponse<any>) => response.data)
    .then((response: Response<Tooltip>) => response.data)
    .catch((err: AxiosError) => console.log(err));
};
const getTooltip = async (query: any) => {
  return await axios
    .get(`${URL}introjs/get-introjs-data?${query}`)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((err: AxiosError) => console.log(err));
};

const deleteIntrojsData = async (id: ID) => {
  return await axios
    .delete(`${URL}introjs/delete-introjs-data/${id}`)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((err: AxiosError) => console.log(err));
};
const getIntroById = async (id: any) => {
  return await axios
    .get(`${URL}introjs/get-intro/${id}`)
    .then((response: AxiosResponse<Response<Tooltip>>) => response.data)
    .then((response: Response<Tooltip>) => response.data)
    .catch((err: AxiosError) => console.log(err));
};

const updateTooltipDataById = async (tooltip: Tooltip, id: any) => {
  return await axios
    .post(`${URL}introjs/update-intro/${id}`, tooltip)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error: AxiosError) => console.log(error));
};

const updateTooltipVisibility = async (data: any) => {
  return await axios
    .post(`${URL}introjs/update-tooltip-visibility`, data)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error: AxiosError) => console.log(error));
};
const getTooltipVisibility = async () => {
  return await axios
    .get(`${URL}introjs/get-tooltip-visibility`)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error: AxiosError) => console.log(error));
};
export {
  saveTooltipData,
  getTooltipData,
  getTooltip,
  deleteIntrojsData,
  getIntroById,
  updateTooltipDataById,
  updateTooltipVisibility,
  getTooltipVisibility
};
