let os: string;
let vers: any;
let version: string[] = [];
let touchUi = false;

const isBrowser = typeof window !== 'undefined';
const isDarkQuery = isBrowser && window.matchMedia && window.matchMedia('(prefers-color-scheme:dark)');
const userAgent = isBrowser ? navigator.userAgent : '';
const platform = isBrowser ? navigator.platform : '';
const maxTouchPoints = isBrowser ? navigator.maxTouchPoints : 0;
const device: any = userAgent && userAgent.match(/Android|iPhone|iPad|iPod|Windows Phone|Windows|MSIE/i);
const isSafari = userAgent && /Safari/.test(userAgent);

if (/Android/i.test(device)) {
  os = 'android';
  vers = userAgent.match(/Android\s+([\d.]+)/i);
  touchUi = true;
  if (vers) {
    version = vers[0].replace('Android ', '').split('.');
  }
} else if (/iPhone|iPad|iPod/i.test(device) || /iPhone|iPad|iPod/i.test(platform) || (platform === 'MacIntel' && maxTouchPoints > 1)) {
  // On iPad with iOS 13 desktop site request is automatically enabled in Safari,
  // so 'iPad' is no longer present in the user agent string.
  // In this case we check `navigator.platform` and `navigator.maxTouchPoints`.
  // maxTouchPoints is needed to exclude desktop Mac OS X.
  os = 'ios';
  vers = userAgent.match(/OS\s+([\d_]+)/i);
  touchUi = true;
  if (vers) {
    version = vers[0].replace(/_/g, '.').replace('OS ', '').split('.');
  }
} else if (/Windows Phone/i.test(device)) {
  os = 'wp';
  touchUi = true;
} else if (/Windows|MSIE/i.test(device)) {
  os = 'windows';
}

const majorVersion = +version[0];
const minorVersion = +version[1];

export { os, majorVersion, minorVersion, isBrowser, isDarkQuery, isSafari, touchUi, userAgent };
