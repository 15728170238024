import { Confirm } from "@mobiscroll/react";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { KTSVG } from "../../../../_metronic/helpers";
import {
  deleteLayoutAndFilterList,
  editLayoutandFilterName,
  saveLayoutandfilters,
} from "../../../../_metronic/requests/PlanningRequest";
import { responsivePopup } from "../../../helpers/popup";
import { Popup } from "../../../mobiscroll/core/components/popup/popup.common";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { ViewsettingFunction } from "../ViewSettings/_model";
import { savedViewSettings } from "../../../../_metronic/requests/ViewSettingsRequest";
import { removeFromLocalStorage } from "../../../helpers/latest_selection";

const layoutAndFiltersDeleteData = async (
  layoutId: any,
  layoutType: string,
) => {
  let response = await deleteLayoutAndFilterList(layoutId, layoutType);
  if ([203, 204].includes(response.status)) {
    let layoutkey:any;
    if(layoutType === "planning"){
      layoutkey = "planning_layout"
    } else if (layoutType === "project") {
      removeFromLocalStorage("expanded_company")
      layoutkey = "project_layout"
    } else if (layoutType === "planbucket") {
      layoutkey = "planbucket_layout"
    }
    removeFromLocalStorage(layoutkey)
    return true;
  } else{
    return false;
  }
};
async function saveLayoutData(layout: any, payload: any, layoutType: any, refetchLayouts:any, activeID:any="") {
  let result: any = null;
  if (layout.id && activeID === "") {
    result = await editLayoutandFilterName(layout.name, layoutType, layout.id);
    refetchLayouts()
  } else {
    let data: any = {};
    if (layoutType === "project") {
      data["project_filter"] = payload?.projectPayload;
    } else if (layoutType === "planning") {
      data["planning_filter"] = payload?.planningPayload;
    } else if (layoutType === "planbucket") {
      data["task_list_filter"] = payload?.taskListPayload;
    }
    result = await saveLayoutandfilters(data, layout.name, layoutType, activeID);
    if (result.status === 200 && activeID === "") {
      if (layoutType !== "planbucket") {
        window.location.reload();
      }
      console.log("call the windows reload");
    }
  }
}

const INITIAL_LAYOUT = {
  id: "",
  name: "",
};

const LayoutButtonPopup: FC<any> = ({
  layoutAndFiltersData,
  selectActiveLayout,
  layoutType,
  payload,
  refetchLayouts,
  toggleDropdown,
  openDropdown,
  refetchlayoutandfilters
}) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isSavePress, setSavePress] = useState(false);
  const [isSaveCurrentPress, setSaveCurrentPress] = useState(false);
  const [layoutDelete, setlayoutToBeDeleted] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState<any>(false);
  const [layout, setLayout] = useState<any>({ ...INITIAL_LAYOUT });
  const viewSettingsData = useSelector((state: any) => state?.viewSettings);
  const viewSettingInitial = ViewsettingFunction();
  const onClosePopup = () => {
    setSavePress(false)
    setIsPopupOpen(false);
    setlayoutToBeDeleted("");
    setLayout({ ...INITIAL_LAYOUT });
    setIsEditOpen(false);
  };
  const wrapperRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target) && openDropdown==='saved-layout'+layoutType) {
        toggleDropdown("")
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdown])

  const openConfirm = useCallback(
    (layoutId: any) => {
      setIsDeleteOpen(true);
      setlayoutToBeDeleted(layoutId);
    },
    [setlayoutToBeDeleted]
  );

  const closeConfirm = useCallback(
    async (result: any) => {
      setIsDeleteOpen(false);
      if (result) {
        let currentLayout:any = layoutAndFiltersData?.filter((layout:any)=> layout?._id === layoutDelete)?.[0]
        let success = await layoutAndFiltersDeleteData(layoutDelete, layoutType);
          if(success && currentLayout?.isActive){
            const data = {
              values: viewSettingInitial ,
              planBucketData:viewSettingInitial?.PlanBucketData,
              type: layoutType,
              reset: true
            };
            const res = await savedViewSettings(data);
            if(res?.data?.success){
              localStorage.setItem("setviewSetting",layoutType);
              localStorage.setItem('viewSettingIsShow', "0")
              if(layoutType === "planbucket"){
                refetchlayoutandfilters()
              }else{
                window.location.reload();
              }
            }
          }
          refetchLayouts()
          toggleDropdown("")
      }else{
        setlayoutToBeDeleted("");
      }
    },
    [layoutDelete, layoutAndFiltersDeleteData]
  );

  const handleButtonClick = async () => {
    setSavePress(true)
    if (layoutType === "project") {
      payload["projectPayload"]["ProjectViewData"] = viewSettingsData?.ProjectViewData;
    } else if (layoutType === "planning") {
      payload["planningPayload"]["PlannigViewData"] = viewSettingsData?.PlannigViewData;
    } else if (layoutType === "planbucket") {
      payload["taskListPayload"]["PlanBucketData"] = viewSettingsData?.PlanBucketData;
    }
    localStorage.setItem("setviewSetting",layoutType);
    localStorage.setItem("viewSettingIsShow",'0');
    await saveLayoutData(layout, payload, layoutType, refetchLayouts);
    if (layoutType === "planbucket") {
      refetchlayoutandfilters()
      refetchLayouts()
    }
    toggleDropdown("")
    setSavePress(false)
    onClosePopup();
  };

  const saveCurrentLayout = async () => {
    setSaveCurrentPress(true)
    let activeLayout:any = layoutAndFiltersData?.filter((layout:any)=> layout?.isActive === true)[0];
    if (layoutType === "project") {
      payload["projectPayload"]["ProjectViewData"] = viewSettingsData?.ProjectViewData;
    } else if (layoutType === "planning") {
      payload["planningPayload"]["PlannigViewData"] = viewSettingsData?.PlannigViewData;
    } else if (layoutType === "planbucket") {
      payload["taskListPayload"]["PlanBucketData"] = viewSettingsData?.PlanBucketData;
    }
    localStorage.setItem("setviewSetting",layoutType);
    localStorage.setItem("viewSettingIsShow",'0');
    await saveLayoutData({id:activeLayout?.id, name:activeLayout?.layout_name}, payload, layoutType,refetchLayouts,activeLayout?._id);
    toggleDropdown("")
    setSaveCurrentPress(false)
    onClosePopup();
  };

  const popupButtons = useMemo<any>(() => {
    return [
      "cancel",
      {
        handler: handleButtonClick,
        cssClass: "mbsc-popup-button-primary",
        keyCode: "enter",
        disabled: (isSavePress || layout?.name?.trim() === ""),
        text: isEditOpen ? "Update view name" : "Save new view",
      },
    ];
  }, [
    setIsEditOpen,
    handleButtonClick,
    layout,
    isSavePress
  ]);
  const openEdit = (id: string, name: string) => {
    setSavePress(false)
    setLayout({ id, name });
    setIsPopupOpen(true);
  };

  const createLayOuts = () => {
    setSavePress(false)
    setIsPopupOpen(true);
  };

  const URL = useLocation()
  const renderSaveLayoutTooltipForPlanning = (
    <Popover id="renderAddUserTooltip">
      <div className="p-5 bg-white card">
        <div>
        Save your selection of Planning Screen Filters, Plan Bucket Filters, and View Settings for future use.
          <a className="text-decoration-underline ms-2"
            href="https://knowledgebase.proxuma.io/article/save-layout-and-filters"
            target="_blank"
          >
            Learn more.
          </a>
        </div>
      </div>
    </Popover>
  )
  const renderSaveLayoutTooltipForProject = (
    <Popover id="renderAddUserTooltip">
      <div className="p-5 bg-white card">
        <div>
        Save your selection of Project Screen Filters, View Format, and View Settings for future use.
          <a className="text-decoration-underline ms-2"
            href="https://knowledgebase.proxuma.io/article/save-layout-and-filters"
            target="_blank"
          >
            Learn more.
          </a>
        </div>
      </div>
    </Popover>
  )
  return (
    <>
      <div className="LayoutBtns d-flex position-relative" ref={wrapperRef}>
        <button
          className="btn text-primary bg-transparent d-inline-flex align-items-center p-0 h-32px rounded-4px me-1 fs-18 fw-bold"
          type="button"
          onClick={() => toggleDropdown('saved-layout'+layoutType)}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
          id="saved-layouts"
        >
          <small className="fs-100 text-ellipsis mw-160px">{layoutAndFiltersData?.filter((layout:any)=> layout?.isActive === true)?.[0]?.layout_name || "Select View"}</small>
        </button>
        {
          URL.pathname.includes('/planning') && 
          (
            <div className="h-32px d-flex align-items-center">
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="bottom-end"
                overlay={renderSaveLayoutTooltipForPlanning}
              >
                <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-1 me-5" type="button">i</button>
              </OverlayTrigger>
            </div>
          )
        }
        {
          URL.pathname.includes('/project') && 
          (
            <div className="h-32px d-flex align-items-center">
              <OverlayTrigger
                trigger="click"
                rootClose
                placement="bottom-end"
                overlay={renderSaveLayoutTooltipForProject}
              >
                <button className="btn p-0 text-center rounded-3px border lh-1 border-primary text-primary fs-6 w-10px color-white infoBtn ms-1 me-5" type="button">i</button>
              </OverlayTrigger>
            </div>
          )
        }
        {openDropdown ==='saved-layout'+layoutType &&
        <div
          className="menu menu-sub menu-sub-dropdown bg-white px-0 pt-4 pb-2 w-200px fs-14 show position-absolute end-0"
          data-kt-menu="true"
          style={{ margin: '44px 0 0 -40px', animation: 'none'}}
        >
          
          <div className="px-4">          
            <button
              className="btn btn-primary btn-active-primary w-100 p-0 h-24px fs-12px rounded-4px mb-4"
              id="createlayout"
              onClick={createLayOuts}
            >
              Save New
            </button>            

            <div className="d-flex align-items-center justify-content-between fs-12px fw-normal text-dark lh-lg  mb-8px">
              {layoutAndFiltersData?.length > 0 && "Layouts"}
              {layoutAndFiltersData?.filter((layout:any)=> layout?.isActive === true)?.length > 0 && 
                  <>             
                  <button
                  className="fw-bold p-0 border-0 bg-transparent text-primary"
                  id="savelayout"
                  onClick={saveCurrentLayout}
                >
                  {isSaveCurrentPress ? "Saving" : "Save as current"}
                </button></>          
              }
            </div>
          </div>

          <div className="mh-200px overflow-auto px-4">            
            {layoutAndFiltersData
              ? layoutAndFiltersData.map((layout: any) => {
                  return (
                    <>
                    {layout._id !== layoutDelete &&
                      <div
                        key={(Math.random() + 1).toString(6).substring(6)}
                        className={`d-flex justify-content-between align-items-center px-8px py-2 fs-12px fw-normal border-bottom border-gary-200 text-dark ${
                          layout.isActive ? "bg-yellow-light" : ""
                        }`}
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => selectActiveLayout(layout._id, layoutType)}
                          className={`${
                            layout.isActive
                              ? "lh-12 pe-2 text-ellipsis mw-120px"
                              : "lh-12 pe-2 text-ellipsis mw-120px"
                          }`}
                        >
                          {layout.layout_name}
                        </span>
                        <div className="min-w-30px">
                        <span
                            className="me-5px cursor-pointer"                          
                            onClick={() => openConfirm(layout._id)}
                          >
                            <KTSVG
                              path="/media/icons/duotune/new-icons/trash-icon-new.svg"
                              svgClassName="w-12px h-12px"
                              className="text-primary"
                            />
                          </span>

                          <span                          
                            className="cursor-pointer"
                            onClick={() =>
                              openEdit(layout._id, layout.layout_name)
                            }
                          >
                            <KTSVG
                              path="/media/icons/duotune/new-icons/pencil-icon-new.svg"                            
                              svgClassName="w-12px h-12px"
                              className="text-primary"
                            />
                          </span>
                        </div>
                      </div>
                    }
                    </>
                  );
                })
              : ""}
          </div>
        </div>
        }
      </div>
      {/* popup for creating and edit layouts */}
      {isPopupOpen && (
        <Popup
          display="bottom"
          fullScreen={true}
          contentPadding={false}
          closeOnOverlayClick={false}
          buttons={popupButtons}
          isOpen={isPopupOpen}
          scrollLock={false}
          onClose={onClosePopup}
          // onClose={onClose}
          responsive={responsivePopup}
          cssClass="md-tooltip md-tooltip-detail plannPopup commonMidpopup assignPopup createPopup layoutPopup"
        >
          <div className="accordion" id="kt_accordion_1">
            <div className="fs-16 fontIner text-dark">
              <div className="min-h-55px"></div>
              <div
                className="md-tooltip-header d-flex justify-content-between px-7 position-absolute top-0 start-0 end-0 py-5 align-items-center "
                style={{
                  backgroundColor: `${"#DAE4F9"}`,
                  zIndex: "1",
                }}
                id={`kt_accordion_1_header_task`}
              >
                <div
                  className="position-absolute start-0 top-0 w-100 h-100 opacity-100"
                  style={{
                    backgroundColor: `${"#DAE4F9"}`,
                    zIndex: "-1",
                  }}
                ></div>
                <div className="d-flex align-items-center text-ellipsis">
                  <p className="d-inline-flex align-items-center fw-bold text-primary fs-18 lh-1 align-left mw-100 pe-4 mb-0">
                    <span className="text-ellipsis lh-sm text-dark">
                      {layout?.id ? "Edit view" : "Save new view"}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className="paddLR24 md-tooltip-info position-relative accordion-collapse collapse show"
                id={`kt_accordion_1_body_task`}
                aria-labelledby={`kt_accordion_1_header_task`}
                data-bs-parent="#kt_accordion_1"
              >
                <div className="row row-cols-2 row-24">
                  <div className="col col-12  d-flex flex-column">
                    <strong className="fw-bold pb-1 required required-padd-none">
                      View name:
                    </strong>
                    <input
                      type="text"
                      className="form-control form-control-solid h-32px px-8px rounded-4px py-0 bg-white border-graybor fw-normal fs-16 text-dark"
                      id="layoutName"
                      name="layoutName"
                      placeholder="Enter view name"
                      value={layout?.name}
                      onChange={(e) =>
                        {setSavePress(false)
                        setLayout({ ...layout, name: e.target.value })}
                      }
                      // onChange={(e) =>
                      //   handleLayoutNameChange(e, setLayoutName, setIsValid)
                      // }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      )}

      <Confirm
        title="Delete this view?"
        message="Are you sure you wish to delete this view."
        okText="Yes"
        cancelText="No"
        isOpen={isDeleteOpen}
        onClose={closeConfirm}
      />
    </>
  );
};

export default React.memo(LayoutButtonPopup);
