import React, { FC } from "react";
import moment from "moment-timezone";

const RenderMonth: FC<any> = ({ args, view }) => {
  let month = moment(args?.date).format("MMMM");
  return (
    <div className="d-flex align-items-center">
      {(view === "year") && (
        <div className={"md-date-header md-date-header-events-"}>
          <div className="md-date-header-day-name">
            {month}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(RenderMonth);
