import axios, { AxiosResponse } from "axios";

const API_URL_NODE = process.env.REACT_APP_API_URL;
const RESOURCE_URL_NODE = `${API_URL_NODE}`;

export const createAISettings = async (data: any): Promise<any | undefined> => {
    const response = await axios
        .post(`${RESOURCE_URL_NODE}aisettings/create-ai-settings
            `, data).then((response: AxiosResponse<any>) => {
            return response;
        }).catch((error) => {
            return error.response;
        });
    return response;
};

export const getAISettings = (): Promise<any | undefined> => {
    return axios
      .get(`${RESOURCE_URL_NODE}aisettings/get-ai-settings`)
  }