import React, { FC, Fragment, useEffect, useState } from 'react';

interface IRowSelectionComponent {
  view: any;
  currentPath: any;
  formik: any;
}

const RowSelectionComponent: FC<IRowSelectionComponent> = ({ formik , view , currentPath }) => {
  const newPath = currentPath ?  formik.values?.PlannigViewData?.[view]: formik.values?.ProjectViewData?.[view]
 const viewPage = currentPath ? 'PlannigViewData' : 'ProjectViewData' ; 
  const [rows, setRows] = useState([
    { id: 'row1', label: 'Row1', checked: newPath?.rowSelection?.row1, inputs: [{ placeholder: 'Name/Title', value: '' }, { placeholder: 'Status', value: '' }] },
    { id: 'row2', label: 'Row2', checked: newPath?.rowSelection?.row2, inputs: [{ placeholder: 'Start Date/Time', value: '' }, { placeholder: 'End Date/Time', value: '' }] },
    { id: 'row3', label: 'Row3', checked: newPath?.rowSelection?.row3, inputs: [{ placeholder: 'Varies', value: '' }, { placeholder: 'Varies', value: '' }] },
  ]);


  useEffect(() => {
    setRows([
      { id: 'row1', label: 'Row1', checked: newPath?.rowSelection?.row1, inputs: [{ placeholder: 'Name/Title', value: '' }, { placeholder: 'Status', value: '' }] },
      { id: 'row2', label: 'Row2', checked: newPath?.rowSelection?.row2, inputs: [{ placeholder: 'Start Date/Time', value: '' }, { placeholder: 'End Date/Time', value: '' }] },
      { id: 'row3', label: 'Row3', checked: newPath?.rowSelection?.row3, inputs: [{ placeholder: 'Varies', value: '' }, { placeholder: 'Varies', value: '' }] },
    ]);
  }, [formik?.values, currentPath, view]);


  const handleChange = (e) => {
    const { id, checked } = e.target;
    formik.setFieldValue(`${viewPage}.${view}.rowSelection.${id}`, checked);

    // Check if all rows are unchecked, then check row1 by default
    const updatedRows = rows.map(row =>
      row.id === id ? { ...row, checked } : row
    );
    setRows(updatedRows);

    const allUnchecked = updatedRows.every(row => !row.checked);

    if (allUnchecked) {
      formik.setFieldValue(`${viewPage}.${view}.rowSelection.row1`, true);
      setRows((prevRows) =>
        prevRows.map(row =>
          row.id === 'row1' ? { ...row, checked: true } : row
        )
      );
    }
  };

  

  return (
    <div className="d-flex flex-column mb-10">
      <h3 className="text-dark fw-bold fs-18 m-0 mb-4">Row selection</h3>
      <div className="bg-gray-100 rounded-8px p-5 form-check-outline-primary-all">
        {rows.map(row => (
          <div key={row.id} className="d-flex align-items-center mb-4">
            <div className="form-check form-check-custom form-check-solid h-30px fs-16 me-7">
              <>
                <input
                  className="form-check-input me-2"
                  name="default_monthyearhours"
                  type="checkbox"
                  id={row.id}
                  checked={row.checked}
                  onChange={handleChange}
                  // onChange={(e) =>formik.setFieldValue(`${viewPage}?.${view}.rowSelection.${row.id}`, e.target.checked)}
                />
                <label htmlFor={row.id}>{row.label}</label>
              </>
            </div>
            {row.inputs.map((input, index) => (
              <Fragment key={index}>
                <input
                  type="text"
                  className="form-control fs-16 fw-normal h-32px p-0 px-4 bg-white w-140px border-white text-center text-gray-900 isdisable"
                  placeholder={input.placeholder}
                  value={input.value}
                />
                {index < row.inputs.length - 1 && <span className="fs-16 fw-normal text-dark px-5">and</span>}
              </Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RowSelectionComponent;
