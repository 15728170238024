import React, { FC } from 'react';

interface IColumnSelectionComponent {
    view: any;
    currentPath: any;
    formik: any;
}
const ColumnSelectionComponent:FC<IColumnSelectionComponent> = ({view, formik, currentPath }) => {
    const newPath = currentPath ?  formik?.values?.PlannigViewData?.[view]: formik.values?.ProjectViewData?.[view]
    const viewPage = currentPath ? 'PlannigViewData' : 'ProjectViewData' ; 
    return (
        <div className="d-flex flex-column mb-10">
            <h3 className="text-dark fw-bold fs-18 m-0 mb-4">Column selection</h3>
            <div className="bg-gray-100 rounded-8px p-5 form-check-outline-primary-all">
                <div className="row">
                    <div className="col col-4 mb-3">
                        <div className="form-check form-check-custom form-check-solid h-30px fs-16">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                id="assigned"
                                checked={newPath?.columnSelection?.assigned}
                                onChange={(e) => {
                                    formik.setFieldValue(`${viewPage}.${view}.columnSelection.assigned`, e.target.checked);
                                  }}
                            />
                            <label htmlFor="assigned">Assigned</label>
                        </div>
                    </div>
                    <div className="col col-8 mb-3">
                        <div className="form-check form-check-custom form-check-solid h-30px fs-16">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                id="hours"
                                checked={newPath?.columnSelection?.hours}
                                onChange={(e) => {
                                    formik.setFieldValue(`${viewPage}.${view}.columnSelection.hours`, e.target.checked);
                                  }}
                            />
                            <label htmlFor="hours">Hours</label>
                        </div>
                    </div>
                    <div className="col col-4">
                        <div className="form-check form-check-custom form-check-solid h-30px fs-16">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                id="date"
                                checked={newPath?.columnSelection?.date}
                                onChange={(e) => {
                                    formik.setFieldValue(`${viewPage}.${view}.columnSelection.date`, e.target.checked);
                                  }}
                            />
                            <label htmlFor="date">Date</label>
                        </div>
                    </div>
                    <div className="col col-8">
                        <div className="form-check form-check-custom form-check-solid h-30px fs-16">
                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                id="status"
                                checked={newPath?.columnSelection?.status}
                                onChange={(e) => {
                                    formik.setFieldValue(`${viewPage}.${view}.columnSelection.status`, e.target.checked);
                                  }}
                            />
                            <label htmlFor="status">Status</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ColumnSelectionComponent;
