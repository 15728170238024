import moment from "moment";
import {
  getCompany,
  getDepartments,
  getIssues,
  getPriorities,
  getProjects,
  getSubIssues,
  getlineOfBusiness,
} from "../../../../../_metronic/requests/FilterRequest";
import {
  getContracts,
  getLayoutandFiltersList,
  getOpportunities,
  getPhase,
  getProjectStatus,
  getProjectType,
  getResources,
  getResourcesWorkType,
  getToPlanServiceCallStatus,
  getToPlanTaskStatus,
} from "../../../../../_metronic/requests/PlanningRequest";
import { getResourceSetting } from "../../../../../_metronic/requests/ResourceSettingRequest";

export const commonVariables = () => {
  const currentDate = moment();
  const weekStart = currentDate.clone().startOf("week").day("Monday");
  const weekEnd = currentDate.clone().endOf("week").day("Friday");
  const starting_date = weekStart.format("YYYY-MM-DD");
  const ending_date = weekEnd.format("YYYY-MM-DD");
  const projectOptionDefault = {
    start_date: starting_date,
    end_date: ending_date,
    view_type: "",
    project_end_date_option: "",
    project_start_date_option: "",
    project_end_date_month_value: 0,
    project_start_date_month_value: 0,

  };

  return {
    currentDate,
    weekStart,
    weekEnd,
    starting_date,
    ending_date,
    projectOptionDefault,
    project_view: true,
    payloadDefault: {
      project_leads: [],
      projects: [],
      companies: [],
      task_status: [],
      start_date: starting_date,
      end_date: ending_date,
      project_start_date: '',
      project_end_date: '',
      view_type: "week",
      project_view: true,
      project_status: [],
      lineOfBusiness: [],
      project_type: []
    },
  };
};
export const projectInit = async () => {
  const data = {
    resource_settings: await getResourceSetting(""),
    worktype: await getResourcesWorkType(""),
    lineOfBusiness: await getlineOfBusiness(),
    peoples: await getResources(""),
    companies: await getCompany(),
    projects: await getProjects(),
    project_status: await getProjectStatus(),
    todo_status_serviceCall: await getToPlanServiceCallStatus(),
    task_status: await getToPlanTaskStatus(),
    priorities: await getPriorities(),
    issues: await getIssues(),
    subIssues: await getSubIssues(),
    layoutAndFiltersData: await getLayoutandFiltersList("project"),
    phaseList: await getPhase(""),
    projectTypeList: await getProjectType(""),
    opportunitiesList: await getOpportunities(""),
    contractList: await getContracts(""),
    departments: await getDepartments(),
  };
  return data;
};

export const setProjectView = (event: any) => {
  let startDate = moment(event.firstDay);
  let endDate = moment(event.lastDay).subtract(1, "days");
  let diff = endDate.diff(startDate, "days");
  let startDateStr = startDate.format("YYYY-MM-DD");
  let endDateStr = endDate.format("YYYY-MM-DD");
  let viewType = "day";
  if (diff > 0 && diff <= 7) {
    viewType = "week";
  } else if (diff > 7 && diff < 32) {
    viewType = "month";
  } else if (diff >= 32) {
    viewType = "year";
  } else {
    viewType = "day";
  }

  return {
    start_date: startDateStr,
    end_date: endDateStr,
    view_type: viewType,
  };
};

export const handleProjectSocketResponse = (event: any) => {
  let eData = JSON.parse(event.data);
  if (eData.type === "create_phase_success") {
  }
};

export const getNrDaysFunc = (start: any, end: any) => {
  const startDate = moment(start);
  const endDate = moment(end);
  // Calculate the number of days between the two dates
  return endDate.diff(startDate, "days");
};

const getStartEndDate = (currentYear: any) => {
  return {
    start: new Date(currentYear, 0, 1), //`${currentYear}-01-01`, //moment.utc().year(currentYear).startOf("year").format("YYYY-MM-DD"),
    end: new Date(currentYear, 11, 31), //`${currentYear}-12-31`,
  };
};

export const myInvalidsFunc = (
  projectData: any,
  allProjectIds: any,
  allPhaseIds: any,
  currentYear: any
) => {
  console.log("currentYear", currentYear);
  const { start, end } = getStartEndDate(currentYear);
  let data = [
    {
      resource: projectData?.company,
      allDay: true,
      start,
      end,
      title: "",
      cssClass: "md-rect-bg-company-timeline",
    },
    {
      resource: allProjectIds,
      allDay: true,
      start,
      end,
      title: "",
      cssClass: "md-rect-bg-project-timeline",
    },
    {
      resource: allPhaseIds,
      allDay: true,
      start,
      end,
      title: "",
      cssClass: "md-rect-bg-phase-timeline",
    },
  ];
  return data;
};

export const getFullYear = (input: any) => {
  if (input) {
    let year = new Date(input)?.getFullYear();
    return !isNaN(year) ? year : new Date().getFullYear();
  } else {
    return new Date().getFullYear();
  }
};
