    import React, {  useState,FC, useEffect } from 'react';
    import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
    import { attachCardtocustomer, getCutomerCard, updateCardHolderName} from '../../../../_metronic/requests/GeneralSettingRequest';

    import { useQuery } from 'react-query';
    import { useAuth } from '../../auth';
    const PAYMENTMETHOD:any = process.env.REACT_APP_PAYMENTMETHOD || "card";

    const CardDetails: FC = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setsuccessMessage] = useState('');
    const [btnDisable, setbtnDisable] = useState(false);
    const [cardHolderName, setCardHolderName] = useState('');
    const [isemptycardNumber, setisemptycardNumber] = useState(true);
    const [isemptyexpiry, setisemptyexpiry] = useState(true);
    const [isemptycvv, setisemptycvv] = useState(true);
    const [placeholderColor, setplaceholderColor] = useState('#32325d');

    const { currentUser } = useAuth();
    const [CARD_ELEMENT_OPTIONS_NUMBER, setCARD_ELEMENT_OPTIONS_NUMBER] = useState({
        style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
            color: placeholderColor,
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
        },
        placeholder: ''
    });

    const [CARD_ELEMENT_OPTIONS_DATE, setCARD_ELEMENT_OPTIONS_DATE] = useState({
        style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
            color: placeholderColor,
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
        },
        placeholder: ''
    });

    const [CARD_ELEMENT_OPTIONS_CVV, setCARD_ELEMENT_OPTIONS_CVV] = useState({
        style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
            color: placeholderColor,
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
        },
        placeholder: ''
    });

    const {
        data: existingCard,
      } = useQuery(
        'get-customer-card',
        () => {
            return getCutomerCard();
        },
        { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false, onSuccess(data:any) {
            setCardHolderName(data?.data?.name)
            setCARD_ELEMENT_OPTIONS_NUMBER({...CARD_ELEMENT_OPTIONS_NUMBER,placeholder : data?.data ? '**** **** **** '+data?.data?.last4 : ''})
            setCARD_ELEMENT_OPTIONS_DATE({...CARD_ELEMENT_OPTIONS_DATE,placeholder : data?.data ? (String(data?.data?.exp_month).padStart(2, '0')+ ' / ' + String(data?.data?.exp_year).slice(-2)) : ''})
            setCARD_ELEMENT_OPTIONS_CVV({...CARD_ELEMENT_OPTIONS_CVV,placeholder : data?.data ? '***' : ''})
        }, }
      );

    const handleSubmit = async (event: React.FormEvent) => {
        setbtnDisable(true)
        setErrorMessage('')
        event.preventDefault();
        var specialChars = /[^a-zA-Z ]/g;
        if (cardHolderName.match(specialChars)) {
            setErrorMessage("Please enter valid cardholder name.!")
            setbtnDisable(false)
            return;
        }
        if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        return;
        }

        const cardNumberElement:any = elements.getElement(CardNumberElement);
        const cardExpiryElement:any = elements.getElement(CardExpiryElement);
        const cardCvcElement:any = elements.getElement(CardCvcElement);
        // console.log(cardNumberElement?._parent?.includes('StripeElement--empty'))
        if (isemptycardNumber && isemptyexpiry && isemptycvv) {
            try {
                const res = await updateCardHolderName(existingCard?.data?.payment_method,cardHolderName);
                if (!res || (res?.status !== 200) ) {
                setErrorMessage(res?.data?.message)
                setbtnDisable(false)
                return false;
                } else {
                setsuccessMessage('Cardholder name updated successfully.')
                // console.log(res)
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
                }
            } catch (error:any) {
                setbtnDisable(false)
            }
            return;
        }
        if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
            return;
        }
        
        const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: PAYMENTMETHOD,
        card: cardNumberElement,
        billing_details: {
            name: cardHolderName.trim(),
        },
        });
        if (error) {
        setErrorMessage(error.message || 'Failed to create payment method.');
        setbtnDisable(false)
        } else if (paymentMethod) {
        const { id } = paymentMethod;
        try {
            const res = await attachCardtocustomer(id,'',currentUser?.data?.user?.organizationId);
            if (!res || res?.data?.status !== true) {
            setErrorMessage(res?.data?.message)
            setbtnDisable(false)
            return false;
            } else {
            setsuccessMessage('Payment method updated successfully.')
            // console.log(res)
            setTimeout(() => {
                window.location.reload()
            }, 2000);
            }
        } catch (error:any) {
            setbtnDisable(false)
        }
        }
    };

    const onFocusData = async (type:any) => {
        if(type === 'number'){
            setCARD_ELEMENT_OPTIONS_NUMBER({...CARD_ELEMENT_OPTIONS_NUMBER,placeholder : ''})
        }
        if(type === 'date'){
            setCARD_ELEMENT_OPTIONS_DATE({...CARD_ELEMENT_OPTIONS_NUMBER,placeholder : ''})
        }
        if(type === 'cvv'){
            setCARD_ELEMENT_OPTIONS_CVV({...CARD_ELEMENT_OPTIONS_NUMBER,placeholder : ''})
        }
    }
    useEffect(()=>{
        if(!isemptycardNumber || !isemptyexpiry || !isemptycvv){
            setCARD_ELEMENT_OPTIONS_NUMBER({...CARD_ELEMENT_OPTIONS_NUMBER,placeholder : ''})
            setCARD_ELEMENT_OPTIONS_DATE({...CARD_ELEMENT_OPTIONS_NUMBER,placeholder : ''})
            setCARD_ELEMENT_OPTIONS_CVV({...CARD_ELEMENT_OPTIONS_NUMBER,placeholder : ''})
        }
    },[isemptycardNumber, isemptyexpiry, isemptycvv])
    const onBlurData = async () => {
        if(isemptycardNumber && isemptyexpiry && isemptycvv){
            setCARD_ELEMENT_OPTIONS_NUMBER({...CARD_ELEMENT_OPTIONS_NUMBER,placeholder : existingCard?.data ? '**** **** **** '+existingCard?.data?.last4 : 'Please enter card number.'})
            setCARD_ELEMENT_OPTIONS_DATE({...CARD_ELEMENT_OPTIONS_NUMBER,placeholder : existingCard?.data ? (String(existingCard?.data?.exp_month).padStart(2, '0')+ ' / ' + String(existingCard?.data?.exp_year).slice(-2)) : 'Please enter card expiry date'})
            setCARD_ELEMENT_OPTIONS_CVV({...CARD_ELEMENT_OPTIONS_NUMBER,placeholder : existingCard?.data ? '***' : 'Please enter card cvv.'})
        }
    }
    return (<>
    
        <form className="form-container w-50" onSubmit={handleSubmit}>
        {/* <div className='mb-6'>
            <span className="fs-16"><label className='col-form-label fw-bold fs-16 text-dark pt-0 mb-0'>Update payment method</label></span>
        </div> */}
        {errorMessage &&
            <div className="mb-4 alert alert-danger p-2 px-3">
            <div className="alert-text font-weight-bold fs-14">{errorMessage}</div>
            </div>
        }
        {successMessage &&
            <div className="mb-4 alert alert-success p-2 px-3">
            <div className="alert-text font-weight-bold fs-14">{successMessage}</div>
            </div>
            }
            <div className="mb-6">
                <label className='col-form-label fw-bold fs-16 text-dark pt-0 mb-0'>Cardholder Name:</label>
                <input
                type="text"
                value={cardHolderName}
                onChange={(e) => {
                    setCardHolderName(e.target.value)
                }}
                className="form-control fs-16 fw-normal h-40px p-0 px-4 bg-transparent border-graybor"
                placeholder="Enter cardholder name"
                style={{background : "white "}}
                />
            </div>

            <div className="mb-6">
            <label className='col-form-label fw-bold fs-16 text-dark pt-0 mb-0'>Card Number:</label>
            <div className="split-card-container">
                <div className="card-element-container border-graybor elementNew">
                <CardNumberElement 
                    onFocus={()=>onFocusData('number')} 
                    onBlur={()=>onBlurData()} 
                    onChange={(e:any) => setisemptyexpiry(e.empty)} 
                    options={CARD_ELEMENT_OPTIONS_NUMBER} />
                </div>
            </div>
            </div>
            <div className="mb-6">
            <label className='col-form-label fw-bold fs-16 text-dark pt-0 mb-0'>Expiration Date:</label>
            <div className="split-card-container">
                <div className="card-element-container border-graybor elementNew">
                <CardExpiryElement 
                onChange={(e:any) => setisemptyexpiry(e.empty)} 
                options={CARD_ELEMENT_OPTIONS_DATE}
                onFocus={()=>onFocusData('date')} 
                onBlur={()=>onBlurData()}  />
                </div>
            </div>
            </div>
            <div className="mb-6">
            <label className='col-form-label fw-bold fs-16 text-dark pt-0 mb-0'>CVC:</label>
            <div className="split-card-container">
                <div className="card-element-container border-graybor elementNew">
                <CardCvcElement 
                onFocus={()=>onFocusData('cvv')} 
                onBlur={()=>onBlurData()} 
                onChange={(e:any) => setisemptycvv(e.empty)} options={CARD_ELEMENT_OPTIONS_CVV} />
                </div>
            </div>
            </div>
            
            <button className='btn btn-primary text-left h-40px p-0 px-5 me-3 fs-16 mb-4' type="submit" disabled={(btnDisable) ? true : false}>Update</button>
        </form>
        </>
    );
    };

    export default CardDetails;
