import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';

interface NewCounterState {
  allPeoples_data: string;
  allDepartments_data: string;
  allCompany_data: string;
  allProject_data: string;
  allRole_data: string;
  allQueues_data: string;
  allType_data: string;
  allServicecallStatus_data: string;
  allSkills_data: string;
  allLineofBusiness_data: string;
}

const initialNewCounterState: NewCounterState = {
  allPeoples_data: '',
  allDepartments_data: '',
  allCompany_data: '',
  allProject_data: '',
  allRole_data: '',
  allQueues_data: '',
  allType_data: '',
  allServicecallStatus_data: '',
  allSkills_data: '',
  allLineofBusiness_data: '',
};

export const newCounterSlice = createSlice({
  name: 'newCounter',
  initialState: initialNewCounterState,
  reducers: {
    storeAllResourcesFilter: (state, action: PayloadAction<string>) => {
      state.allPeoples_data = action.payload;
    },
    storeAllDepartmentsFilter: (state, action: PayloadAction<string>) => {
      state.allDepartments_data = action.payload;
    },
    storeAllCompanyFilter: (state, action: PayloadAction<string>) => {
      state.allCompany_data = action.payload;
    },
    storeAllProjectFilter: (state, action: PayloadAction<string>) => {
      state.allProject_data = action.payload;
    },
    storeAllRoleFilter: (state, action: PayloadAction<string>) => {
      state.allRole_data = action.payload;
    },
    storeAllQueuesFilter: (state, action: PayloadAction<string>) => {
      state.allQueues_data = action.payload;
    },
    storeAllTypeFilter: (state, action: PayloadAction<string>) => {
      state.allType_data = action.payload;
    },
    storeAllServiceCallFilter: (state, action: PayloadAction<string>) => {
      state.allServicecallStatus_data = action.payload;
    },
    storeAllSkillsFilter: (state, action: PayloadAction<string>) => {
      state.allSkills_data = action.payload;
    },
    storeAllLineOfBuisnessFilter: (state, action: PayloadAction<string>) => {
      state.allLineofBusiness_data = action.payload;
    },
  },
});

export const {
  storeAllResourcesFilter,
  storeAllDepartmentsFilter,
  storeAllCompanyFilter,
  storeAllProjectFilter,
  storeAllRoleFilter,
  storeAllQueuesFilter,
  storeAllTypeFilter,
  storeAllServiceCallFilter,
  storeAllSkillsFilter,
  storeAllLineOfBuisnessFilter,
} = newCounterSlice.actions;

export const selectNewCounter = (state: RootState) => state.counter;

export default newCounterSlice.reducer;
