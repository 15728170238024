import { doc } from './dom';
import { CHANGE, CLICK, MOUSE_DOWN, MOUSE_ENTER, MOUSE_OVER, MOUSE_UP } from './events';
import { isBrowser, majorVersion, os } from './platform';

// tslint:disable no-non-null-assertion

let tapped = 0;
let allowQuick: boolean;

/**
 * Returns the X or Y coordinate from a touch or mouse event.
 * @hidden
 * @param ev
 * @param axis
 * @param page
 * @returns
 */
export function getCoord(ev: any, axis: 'X' | 'Y', page?: boolean): number {
  const prop = (page ? 'page' : 'client') + axis;

  // Multi touch support
  if (ev.targetTouches && ev.targetTouches[0]) {
    return ev.targetTouches[0][prop];
  }

  if (ev.changedTouches && ev.changedTouches[0]) {
    return ev.changedTouches[0][prop];
  }

  return ev[prop];
}

/** @hidden */
export function preventClick() {
  // Prevent ghost click
  tapped++;
  setTimeout(() => {
    tapped--;
  }, 500);
}

/** @hidden */
export function triggerClick(ev: any, control: any) {
  // Prevent duplicate triggers on the same element
  // e.g. a form checkbox inside a listview item
  if (control.mbscClick) {
    return;
  }

  const touch = (ev.originalEvent || ev).changedTouches[0];
  const evt = document.createEvent('MouseEvents') as any;

  evt.initMouseEvent(
    'click',
    true,
    true,
    window,
    1,
    touch.screenX,
    touch.screenY,
    touch.clientX,
    touch.clientY,
    false,
    false,
    false,
    false,
    0,
    null,
  );

  evt.isMbscTap = true;
  // Prevent ionic to bust our click
  // This works for Ionic 1 - 3, not sure about 4
  evt.isIonicTap = true;

  // This will allow a click fired together with this click
  // We need this, because clicking on a label will trigger a click
  // on the associated input as well, which should not be busted
  allowQuick = true;

  control.mbscChange = true;
  control.mbscClick = true;
  control.dispatchEvent(evt);

  allowQuick = false;

  // Prevent ghost click
  preventClick();

  setTimeout(() => {
    delete control.mbscClick;
  });
}

/**
 * Prevent standard behaviour on click
 * @hidden
 * @param ev
 */
function bustClick(ev: any) {
  // Textarea needs the mousedown event
  if (tapped && !allowQuick && !ev.isMbscTap && !(ev.target.nodeName === 'TEXTAREA' && ev.type === MOUSE_DOWN)) {
    ev.stopPropagation();
    ev.preventDefault();
  }
}

if (isBrowser) {
  [MOUSE_OVER, MOUSE_ENTER, MOUSE_DOWN, MOUSE_UP, CLICK].forEach((ev: any) => {
    doc!.addEventListener(ev, bustClick, true);
  });

  if (os === 'android' && majorVersion < 5) {
    doc!.addEventListener(
      CHANGE,
      (ev: any) => {
        const target = ev.target;
        if (tapped && target.type === 'checkbox' && !target.mbscChange) {
          ev.stopPropagation();
          ev.preventDefault();
        }
        delete target.mbscChange;
      },
      true,
    );
  }
}
