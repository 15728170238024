import { FC } from "react";
import { Tooltip } from "../../core/_types";
type Props = {
  tooltip: Tooltip;
};
const IntrojsTextCell: FC<Props> = ({ tooltip }) => {
  return (
    console.log(tooltip),
    (
      <>
        <div className="text-ellipsis mw-100px">{tooltip.intro}</div>
      </>
    )
  );
};

export { IntrojsTextCell };
