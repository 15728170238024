import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { registerOrganisation } from "../../../../_metronic/requests/RegistrationRequest";
import { Loading } from "../../../components/loading/Loading";
import { RegistrationDetail } from "./_model";

const Subscription: FC = () => {
    const navigate = useNavigate();
    const [successfulRegistration, setSuccessfulRegistration] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    useEffect(()=>{
      if (!sessionStorage.getItem('register')) {
        navigate('/register');    
      }
    },[]);
  
    const formik = useFormik<RegistrationDetail>({
      initialValues: {
        subscription_type: '',
        isTrail: false,
      },
      onSubmit: async (values) => {
        try {
          // Get the initial values from the formik initialValues
          setIsLoading(true);
          const organizationData = JSON.parse(sessionStorage.getItem('register'));
          if (values.isTrail) {
            const currentDate = new Date().toUTCString();
            organizationData.subscription_trail_ends = currentDate;
            organizationData.isOrganizationOnTrial = true;
          } else {
            organizationData.subscription_trail_ends = null;
            organizationData.isOrganizationOnTrial = false;
          }
    
          organizationData.subscription_type = values.subscription_type;
          organizationData.coupon_code = values.coupon_code;
          // Assuming your alert function is asynchronous
          let result = await registerOrganisation(organizationData); // Your API call or other async task
          setSuccessfulRegistration(true);
          if(result){
            setIsLoading(false);
            sessionStorage.removeItem('register');
          }
          
        } catch (ex) {
          console.log(ex);
          setIsLoading(false);
          
        }finally {

        }
      },
    });  



    const handleStartTrialClick = (subscription_type,coupon_code) => {
      formik.setValues({
        subscription_type,
        isTrail: true,
        
      });
      formik.submitForm();
    };

    const handleButtonClick = (subscription_type,coupon_code) => {
      formik.setValues({
        subscription_type,
        isTrail: false,
      
      });
      formik.submitForm();
    };
    




    

  return (
    <>
      {/* Added logo and sidebar */}
      <div className="d-flex flex-column flex-root login" id="kt_app_root">
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div
            className="d-flex align-items-end justify-content-center w-40 bgi-size-cover bgi-position-center p-10 p-sm-15 loginLeft position-relative"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/misc/login-bg.jpg")})`,
            }}
          >
            <div className="position-absolute top-50 start-50 translate-middle">
              <img
                alt="Proxuma"
                className="h-auto logo w-250px"
                src={toAbsoluteUrl("/media/logos/horizontal-dark-bg.svg")}
              />
            </div>
          </div>

          <div className="d-flex w-60 p-0 loginForm bg-body height100 overflow-hidden subscriptionDiv">
          
            <div className="d-flex p-10 w-100 h-100 overflow-auto">
              <div className="w-100 w-sm-800px mt-auto mb-auto mx-auto">


              {
        isLoading ? 
          <Loading
            isLoading={isLoading}
            isHideSpinner={true}
            className="splash-screen"
          />
        : (

              !successfulRegistration?
                <div>
                  <div className="mb-8">
                    <h1 className="text-dark fw-bold mb-0 h1 fs-1 fs-ls-30 text-center">
                      Choose Your Plan
                    </h1>
                  </div>
                  {/* Subscription Options as Cards with Margins */}
                  <div className="d-flex w-100 justify-content-between">
                   <form   onSubmit={formik.handleSubmit} className="d-flex w-100 justify-content-between">
                    {/* Monthly Subscription */}
                    <div className="card mb-4" style={{ width: "45rem", marginRight: "10px" }}>
                      <div className="card-body d-flex flex-column align-items-center">
                        <h1 className="card-title text-center mb-3">Month to Month</h1>                        
                        <div className="mt-auto">
                          <button
                            className="btn btn-primary d-inline-flex align-items-center p-0 px-5 h-32px rounded-4px fs-16"
                            type="button"
                            onClick={() => handleButtonClick('64c8b1af8c4e44656ebcb464','')}
                          >
                            Choose Plan
                          </button>
                          <p className="mt-2 text-center">
                          <strong>  <a href="#" onClick={() => handleStartTrialClick('64c8b1af8c4e44656ebcb464','')}>Start a Free 14 day trial</a></strong></p>
                        </div>
                      </div>
                    </div>
                    {/* Yearly Subscription */}
                    <div className="card mb-4" style={{ width: "45rem", marginRight: "10px" }}>
                      <div className="card-body d-flex flex-column align-items-center">
                        <h1 className="card-title text-center mb-3">12 Month Commitment <br /> (Save 10%)</h1>                                                
                        <div className="mt-auto">  
                          <button
                            className="btn btn-primary d-inline-flex align-items-center p-0 px-5 h-32px rounded-4px fs-16"
                            type="button"
                            onClick={() => handleButtonClick('64cb93a4a97f554479e52071','')}
                          >
                            Choose Plan
                          </button>
                          <p className="mt-2 text-center">
                           <strong> <a href="#" onClick={() => handleStartTrialClick('64cb93a4a97f554479e52071','')}>Start a Free 14 day trial</a></strong></p>
                        </div>  
                      </div>
                    </div>
                    {/* Three-Year Subscription */}
                    <div className="card mb-4" style={{ width: "45rem" }}>
                      <div className="card-body d-flex flex-column align-items-center">
                        <h1 className="card-title text-center mb-3">36 Month Commitment <br /> (Save 20%)</h1>                        
                        
                        <div className="mt-auto">
                          <button
                            className="btn btn-primary d-inline-flex align-items-center p-0 px-5 h-32px rounded-4px fs-16"
                            type="button"
                            onClick={() => handleButtonClick('64d373b392cc17269008b763','')}
                          >
                            Choose Plan
                          </button>
                          <p className="mt-2 text-center">
                           <strong> <a href="#" onClick={() => handleStartTrialClick('64d373b392cc17269008b763+trail','')} >Start a Free 14 day trial</a></strong>
                          </p>
                        </div>  
                      </div>
                    </div>
                    </form>
                  </div>
                  {/* plan details */}
                  <div className="d-flex w-100 justify-content-between">
                   <div className="d-flex w-100 justify-content-between">
                    {/* Monthly Subscription */}
                    <div className="card mb-4" style={{ width: "45rem", marginRight: "10px" }}>
                      <div className="card-body d-flex flex-column ">
                        <h1 className="card-title text-center mb-3">Engineer</h1>
                        <h5 className="mb-4">Gain control over your time allocation in a simple, intuitive timeline.</h5>

                        <ul className="fs-16">                         
                          <li><strong className="fs-18">&euro; 20/mo</strong></li>
                          <li>View your planning screen.</li>
                        
                        </ul>


                        <div className="mt-auto">
                          
                        </div>
                      </div>
                    </div>
                    {/* Yearly Subscription */}
                    <div className="card mb-4" style={{ width: "45rem", marginRight: "10px" }}>
                      <div className="card-body d-flex flex-column ">
                        <h1 className="card-title text-center">Planner</h1>
                        <h5 className="mb-4">Get a comprehensive overview of the whole organisation and plan accordingly.</h5>


                        <ul className="fs-16">                        
                          <li><strong className="fs-18">&euro; 40/mo.</strong></li>
                          <li>All the benefits of 'Engineer', and</li>
                          <li>View the planning screen of the entire organisation.</li>
                          <li>Create service calls for all resources.</li>
                          <li>Drag & Drop tasks, Service calls and tickets.</li>
                          <li>Notifications.</li>
                        </ul>  
                        <div className="mt-auto">  
                         
                        </div>  
                      </div>
                    </div>
                    {/* Three-Year Subscription */}
                    <div className="card mb-4" style={{ width: "45rem" }}>
                      <div className="card-body d-flex flex-column ">
                        <h1 className="card-title text-center">Project Manager</h1>
                        <h5 className="mb-4">Dig into Projects to make sure you meet deadlines, hire staffs and sell new Projects exactly when you have the capacity to do so.  </h5>

                        <ul className="fs-16">                        
                          <li><strong className="fs-18">&euro; 50/mo.</strong></li>
                          <li>All the benefits of 'Planner', and</li>
                          <li>Access to Project Management.</li>
                          <li>Real-time progress tracking of Projects.</li>
                          <li>View all Project Dependencies and Resources , plan accordingly.</li>
                        </ul>  
                        <div className="mt-auto">
                         
                        </div>  
                      </div>
                    </div>
                    </div>
                  </div>
                  {/* END :: Subscription Options */}
                </div>
                : <div>
                   <div className="mb-8">
                        <h1 className='text-dark fw-bold h2 fs-1 text-center'>
                          <> Thank you for registering with Proxuma. Please check your mail and confirm your registration.
                            
                              After confirmation we will review your application for Proxuma within 24 hours. 
                              When approved you will receive a confirmation and you can continue the onboarding process. </>
                        </h1>
                    </div>
                  </div> 
                
              )}
              </div>
            </div>
           
            
          </div>
        </div>
      </div>
      {/* END :: Added logo and sidebar */}
    </>
  );
};

export default Subscription;

