
import { BaseComponent, IBaseProps } from '../../base';
import { gestureListener } from '../../util/gesture';
import { BACKSPACE, DELETE, ENTER, SPACE } from '../../util/keys';
import { ICalendarEventDragArgs } from '../eventcalendar/shared/schedule-timeline-base.types';

// tslint:disable directive-class-suffix
// tslint:disable directive-selector

// tslint:disable-next-line interface-name
export interface MbscListItemOptions extends IBaseProps {
  actionable?: boolean;
  drag?: boolean;
  event?: any;
  eventData?: any;
  ripple?: boolean;
  selected?: boolean;
  // Events
  onClick?(args: any): void;
  onContextMenu?(args: any): void;
  onDelete?(arg: any): void;
  onDoubleClick?(args: any): void;
  onHoverIn?(args: any): void;
  onHoverOut?(args: any): void;
  onDragStart?(args: any): void;
  onDragMove?(args: any): void;
  onDragEnd?(args: any): void;
  onDragModeOn?(args: any): void;
  onDragModeOff?(args: any): void;
}

/** @hidden */
// tslint:disable-next-line interface-name
export interface MbscListItemState {
  hasFocus?: boolean;
  hasHover?: boolean;
  isActive?: boolean;
}

/** @hidden */

export class ListItemBase extends BaseComponent<MbscListItemOptions, MbscListItemState> {
  public static defaults: MbscListItemOptions = {
    actionable: true,
    ripple: false,
  };

  // tslint:disable variable-name
  protected static _name = 'ListItem';

  public _cssClass?: string;

  private _unlisten?: () => void;

  public _onClick = (ev: any) => {
    this._hook('onClick', { domEvent: ev });
    if (this.s.selected) {
      this.setState({ hasFocus: false });
    }
  };

  // tslint:enable variable-name

  protected _mounted() {
    let isDrag: boolean;
    let touchTimer: any;
    this._unlisten = gestureListener(this._el, {
      click: true,
      keepFocus: true,
      onBlur: () => {
        this.setState({ hasFocus: false });
      },
      onEnd: (ev) => {
        if (isDrag) {
          const s = this.s;
          const args: ICalendarEventDragArgs = { ...ev };
          const eventData = s.eventData;
          // Will prevent mousedown event on doc
          args.domEvent.preventDefault();
          args.drag = true;
          args.event = s.event;
          args.eventData = eventData;
          args.resource = eventData && eventData.currentResource && eventData.currentResource.id;
          this._hook('onDragEnd', args);
          isDrag = false;
        }
        clearTimeout(touchTimer);
      },
      onFocus: () => {
        this.setState({ hasFocus: true });
      },
      onHoverIn: (ev: any) => {
        if (this.s.actionable) {
          this.setState({ hasHover: true });
        }
        this._hook('onHoverIn', { domEvent: ev });
      },
      onHoverOut: (ev: any) => {
        this.setState({ hasHover: false });
        this._hook('onHoverOut', { domEvent: ev });
      },
      onKeyDown: (ev: any) => {
        const event = this.s.event;
        switch (ev.keyCode) {
          case ENTER:
          case SPACE:
            this._el.click();
            ev.preventDefault();
            break;
          case BACKSPACE:
          case DELETE:
            if (event && event.editable !== false) {
              this._hook('onDelete', {
                domEvent: ev,
                event,
                source: 'agenda',
              });
            }
            break;
        }
      },
      onMove: (ev) => {
        const s = this.s;
        const args: ICalendarEventDragArgs = { ...ev };
        const eventData = s.eventData;

        args.drag = true;
        args.event = s.event;
        args.eventData = eventData;
        args.external = true;
        args.resource = eventData && eventData.currentResource && eventData.currentResource.id;

        if (isDrag || !args.isTouch) {
          // Prevents page scroll on touch and text selection with mouse
          args.domEvent.preventDefault();
        }

        if (isDrag) {
          this._hook('onDragMove', args);
        } else if (Math.abs(args.deltaX) > 7 || Math.abs(args.deltaY) > 7) {
          clearTimeout(touchTimer);
          if (!args.isTouch && s.drag && s.event.editable !== false) {
            isDrag = true;
            this._hook('onDragStart', args);
          }
        }
      },
      onPress: () => {
        if (this.s.actionable) {
          this.setState({ isActive: true });
        }
      },
      onRelease: () => {
        this.setState({ isActive: false });
      },
      onStart: (ev) => {
        const s = this.s;
        if (ev.isTouch && s.drag && s.event.editable !== false && !isDrag) {
          touchTimer = setTimeout(() => {
            const args: ICalendarEventDragArgs = { ...ev };
            const eventData = s.eventData;
            args.drag = true;
            args.event = s.event;
            args.eventData = eventData;
            args.resource = eventData && eventData.currentResource && eventData.currentResource.id;
            this._hook('onDragModeOn', args);
            this._hook('onDragStart', args);
            isDrag = true;
          }, 350);
        }
        return { ripple: s.actionable && s.ripple };
      },
    });
  }

  protected _render(s: MbscListItemOptions, state: MbscListItemState) {
    this._cssClass =
      this._className +
      ' mbsc-list-item' +
      this._theme +
      this._hb +
      this._rtl +
      (s.actionable ? ' mbsc-list-item-actionable' : '') +
      (state.hasFocus ? ' mbsc-focus' : '') +
      (state.hasHover ? ' mbsc-hover' : '') +
      (state.isActive ? ' mbsc-active' : '') +
      (s.selected ? ' mbsc-selected' : '');
  }

  protected _destroy() {
    if (this._unlisten) {
      this._unlisten();
    }
  }
}
