import axios, { AxiosResponse } from "axios";
import { ProfileDetail } from "../../app/modules/apps/profile/_model";
import { Response } from "../helpers";

const API_URL_NODE = process.env.REACT_APP_API_URL;
const PROFILEDETAIL_URL_NODE = `${API_URL_NODE}users/get_current_user`;
const PROFILEUPDATE_URL_NODE = `${API_URL_NODE}/users/update-user`;

const getProfileDetail = (query: string): Promise<any | undefined> => {
  return axios
    .get(`${API_URL_NODE}superadmin/get_current_user`) 
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data);
};


const getProfileDetailUser = (query: string): Promise<any | undefined> => {
  return axios
  .get(`${PROFILEDETAIL_URL_NODE}`) 
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data);
};



const updateProfileDetail = (profileDetail: ProfileDetail): Promise<ProfileDetail | undefined> => {
  return axios
    .patch(`${API_URL_NODE}superadmin/update_user`, profileDetail)

    .then((response: AxiosResponse<Response<ProfileDetail>>) => response.data)
    .then((response: Response<ProfileDetail>) => response.data);
};

const updateProfileDetailUser = (profileDetail: ProfileDetail): Promise<ProfileDetail | undefined> => {
  return axios
    .patch(`${API_URL_NODE}users`, profileDetail)

    .then((response: AxiosResponse<Response<ProfileDetail>>) => response.data)
    .then((response: Response<ProfileDetail>) => response.data);
};



const createProfileDetail = (profileDetail: ProfileDetail): Promise<ProfileDetail | undefined> => {
  return axios
    .patch(PROFILEUPDATE_URL_NODE, profileDetail)
    .then((response: AxiosResponse<Response<ProfileDetail>>) => response.data)
    .then((response: Response<ProfileDetail>) => response.data);
};

export {
  getProfileDetail,
  createProfileDetail,
  updateProfileDetail,
  getProfileDetailUser,
  updateProfileDetailUser
};
