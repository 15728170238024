import { FC } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../AssetHelpers";
type Props = {
  className?: string;
  path: string;
  svgClassName?: string;
  stylecode?: any;
};

const KTSVG: FC<Props> = ({
  className = "",
  path,
  svgClassName = "mh-50px",
  stylecode,
}) => {
  return (
    <span className={`svg-icon ${className}`} style={stylecode}>
      <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
    </span>
  );
};

export { KTSVG };
