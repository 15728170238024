import { Observable } from '../util/observable';

export class InstanceServiceBase {
  public onInstanceReady = new Observable<any>();
  public onComponentChange = new Observable<any>();
  protected inst: any;
  public set instance(inst: any) {
    this.inst = inst;
    this.onInstanceReady.next(inst);
  }
  public get instance(): any {
    return this.inst;
  }
}
