import { MbscCalendarEvent, MbscCalendarEventData, MbscResource } from '../../../shared/calendar-view/calendar-view.types';
import {
  addDays,
  checkDateRangeOverlap,
  createDate,
  getDateOnly,
  getDateStr,
  getDayMilliseconds,
  getEndDate,
  IDatetimeProps,
  isInWeek,
  isSameDay,
  REF_DATE,
} from '../../../util/datetime';
import { isArray, round, UNDEFINED } from '../../../util/misc';
import { MbscSlot } from '../eventcalendar.types';
import { MbscEventcalendarOptions } from '../eventcalendar.types.public';
import { IDailyData, IDayData, IGroupData } from './schedule-timeline-base.types';

// tslint:disable no-non-null-assertion

export const DEF_ID = 'mbsc-def';

/** @hidden */
export function checkCollision(
  data: { [key: string]: { [key: string]: IDailyData } },
  start: Date,
  end: Date,
  allDay: boolean | undefined,
  isTimeline: boolean | undefined,
  invalidateEvent: 'start-end' | 'strict' | undefined,
  s: MbscEventcalendarOptions,
): MbscCalendarEvent | boolean {
  const showBuffer = s.showEventBuffer !== false;
  const onlyStartEnd = invalidateEvent === 'start-end';
  const until = s.exclusiveEndDates ? end : getDateOnly(addDays(end, 1));
  for (const r of Object.keys(data)) {
    const resourceData = data[r];
    for (const d = getDateOnly(start); d < until; d.setDate(d.getDate() + 1)) {
      const dayData = resourceData[getDateStr(d)];
      if (dayData) {
        if ((allDay || isTimeline) && dayData.allDay[0] && (!onlyStartEnd || isSameDay(d, start) || isSameDay(d, end))) {
          return dayData.allDay[0].original!;
        }
        if (!allDay) {
          for (const item of dayData.data) {
            const original = item.original!;
            const startDate = showBuffer && item.bufferStart ? item.bufferStart : item.startDate;
            const endDate = showBuffer && item.bufferEnd ? item.bufferEnd : item.endDate;
            if (onlyStartEnd) {
              if (checkDateRangeOverlap(startDate, endDate, start, start, true)) {
                return original;
              }
              if (checkDateRangeOverlap(startDate, endDate, end, end)) {
                return original;
              }
            } else if (checkDateRangeOverlap(startDate, endDate, start, end)) {
              return original;
            }
          }
        }
      }
    }
  }
  return false;
}

export function getEventLayoutStart(
  event: MbscCalendarEventData,
  s: IDatetimeProps,
  isListing: boolean | undefined,
  isTimeline: boolean | undefined,
  isDailyResolution: boolean | undefined,
  firstDay: Date,
  cols: IDayData[],
  colIndexMap: { [key: string]: number },
  showBuffer?: boolean,
) {
  const eventAllDay = event.allDay || isListing;
  const startDate = showBuffer && event.bufferStart ? event.bufferStart : event.startDate;
  if (isTimeline && isListing && !isDailyResolution) {
    const startCol = colIndexMap[getDateStr(startDate)];
    return startDate < firstDay ? firstDay : cols[startCol + (isInWeek(startDate.getDay(), s.startDay!, s.endDay!) ? 0 : 1)].date;
  }
  return eventAllDay ? createDate(s, startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) : startDate;
}

export function getEventLayoutEnd(
  event: MbscCalendarEventData,
  s: IDatetimeProps,
  isListing: boolean | undefined,
  isTimeline: boolean | undefined,
  isDailyResolution: boolean | undefined,
  lastDay: Date,
  cols: IDayData[],
  colIndexMap: { [key: string]: number },
  showBuffer?: boolean,
) {
  const eventAllDay = event.allDay || isListing;
  const endDate = showBuffer && event.bufferEnd ? event.bufferEnd : event.endDate;
  if (isTimeline && isListing && !isDailyResolution) {
    const endCol = colIndexMap[getDateStr(getEndDate(s, event.allDay, event.startDate, endDate))];
    const endD = endDate >= lastDay || endCol >= cols.length - 1 ? lastDay : cols[endCol + 1].date;
    return getEndDate(s, false, event.startDate, endD);
  }
  const eEnd = eventAllDay ? getEndDate(s, event.allDay, event.startDate, endDate) : endDate;
  return eventAllDay ? createDate(s, eEnd.getFullYear(), eEnd.getMonth(), eEnd.getDate(), 23, 59, 59, 999) : eEnd;
}

/** @hidden */
export function calcLayout(
  s: MbscEventcalendarOptions,
  groups: IGroupData[],
  event: MbscCalendarEventData,
  next: { [key: string]: number },
  maxEventStack: number | 'all',
  isListing?: boolean,
  isTimeline?: boolean,
  isDailyResolution?: boolean,
  firstDay?: Date,
  firstDayTz?: Date,
  lastDay?: Date,
  lastDayTz?: Date,
  cols?: any[],
  colIndexMap?: { [key: string]: number },
) {
  // Layout algorithm
  // Overlapping events are organized in groups, groups are organized in levels (columns)
  const showBuffer = s.showEventBuffer !== false;
  const first = event.allDay ? firstDay! : firstDayTz!;
  const last = event.allDay ? lastDay! : lastDayTz!;
  const eventStart = getEventLayoutStart(event, s, isListing, isTimeline, isDailyResolution, first, cols!, colIndexMap!, showBuffer);
  const eventEnd = getEventLayoutEnd(event, s, isListing, isTimeline, isDailyResolution, last, cols!, colIndexMap!, showBuffer);
  let pushed = false;
  for (const group of groups) {
    let i = 0;
    let groupOverlap = false;
    let groupLevel: MbscCalendarEventData[] | undefined;
    for (const level of group.stacks) {
      let overlap = false;
      for (const item of level) {
        // The collision check works on timestamps, so the right timestamp for allDay events will be the start of the start day
        // and end of the end day (1ms is already removed in case of exclusive end dates).
        // In case of timezones, the allDay event dates are always in the display timezone (meaning
        // they don't use timezones at all) and the times are not taken into account.
        const firstD = item.allDay ? firstDay! : firstDayTz!;
        const lastD = item.allDay ? lastDay! : lastDayTz!;
        const itemStart = getEventLayoutStart(item, s, isListing, isTimeline, isDailyResolution, firstD, cols!, colIndexMap!, showBuffer);
        const itemEnd = getEventLayoutEnd(item, s, isListing, isTimeline, isDailyResolution, lastD, cols!, colIndexMap!, showBuffer);
        if (checkDateRangeOverlap(itemStart, itemEnd, eventStart, eventEnd, true)) {
          overlap = true;
          groupOverlap = true;
          if (groupLevel) {
            next[event.uid!] = next[event.uid!] || i;
          } else {
            next[item.uid!] = i + 1;
          }
        }
      }
      // There is place on this level, if the event belongs to this group, will be added here
      if (!overlap && !groupLevel) {
        groupLevel = level;
      }
      i++;
    }
    // If event belongs to this group
    if (groupOverlap) {
      if (groupLevel) {
        // Add to existing level
        groupLevel.push(event);
      } else {
        // Add to new level
        if (maxEventStack === 'all' || group.stacks.length < +maxEventStack) {
          group.stacks.push([event]);
        } else {
          // TODO: should not modify this here
          event.position = UNDEFINED;
          group.more.push(event);
        }
      }
      pushed = true;
    }
  }
  // Create a new group
  if (!pushed) {
    next[event.uid!] = 0;
    groups.push({ stacks: [[event]], more: [] });
  }
}

/** @hidden */
export function roundStep(v: number) {
  // Don't allow negative values
  v = Math.abs(round(v));
  if (v > 60) {
    return round(v / 60) * 60;
  }
  if (60 % v === 0) {
    return v;
  }
  return [6, 10, 12, 15, 20, 30].reduce((a, b) => {
    return Math.abs(b - v) < Math.abs(a - v) ? b : a;
  });
}

/**
 * Calculates the displayed time for a range on the scheduler.
 * @hidden
 * @param startDate - Start of the displayed range.
 * @param endDate - End of the displayed range.
 * @param startTime - Start time of the scheduler.
 * @param endTime - End time of the scheduler.
 * @returns - The displayed time as milliseconds.
 */
export function calcSchedulerTime(startDate: Date, endDate: Date, startTime: number, endTime: number): number {
  let start = getDayMilliseconds(startDate);
  let end = getDayMilliseconds(endDate);

  if (startTime > start) {
    start = startTime;
  }
  if (endTime < end) {
    end = endTime;
  }

  return end - start;
}

/**
 * Calculates the displayed time for a range on the timeline.
 * It takes into account hidden weekdays, and displayed start and end times.
 * @hidden
 * @param startDate - Start of the displayed range.
 * @param endDate - End of the displayed range.
 * @param viewStart - First day of the timeline view.
 * @param viewEnd - Last day of the timeline view.
 * @param startTime - Start time of the scheduler.
 * @param endTime - End time of the scheduler.
 * @param startDay - Start weekday of the displayed weeks.
 * @param endDay - End weekday of the displayed weeks.
 * @param fullDay - True when the range should fill full days.
 * @returns The displayed time as milliseconds.
 */
export function calcTimelineTime(
  startDate: Date,
  endDate: Date,
  viewStart: Date,
  viewEnd: Date,
  startTime: number,
  endTime: number,
  startDay: number,
  endDay: number,
  fullDay?: boolean,
): number {
  const displayedTime = endTime - startTime + 1;
  let startD = startDate;
  let endD = endDate;
  let until = addDays(getDateOnly(endD), 1);

  if (startD < viewStart) {
    startD = viewStart;
  }

  if (endD > viewEnd) {
    endD = until = viewEnd;
  }

  let start = getDayMilliseconds(startD);
  let end = getDayMilliseconds(endD);

  // limit the start/end time of the events
  if (startTime > start) {
    start = startTime;
  }

  if (endTime < end) {
    end = endTime;
  }

  // in case of multi-day events limit the start/end hours if moved to a position without a cursor date change
  if (!isSameDay(startD, endD)) {
    if (start > endTime) {
      start = endTime;
    }
    if (end < startTime) {
      end = startTime;
    }
  }

  let time = 0;

  if (isSameDay(startD, endD)) {
    time = fullDay ? displayedTime : end - start;
  } else {
    for (const d = getDateOnly(startD); d < until; d.setDate(d.getDate() + 1)) {
      if (isInWeek(d.getDay(), startDay, endDay)) {
        if (!fullDay && isSameDay(d, startD)) {
          time += displayedTime - start + startTime;
        } else if (!fullDay && isSameDay(d, endD)) {
          time += end - startTime;
        } else {
          time += displayedTime;
        }
      }
    }
  }

  return time;
}

/** @hidden */
export function getEventStart(
  startDate: Date,
  startTime: number,
  displayedTime: number,
  viewStart?: Date,
  startDay?: number,
  endDay?: number,
): number {
  if (viewStart && viewStart > startDate) {
    startDate = viewStart;
  }

  let start = getDayMilliseconds(startDate);
  if (startTime > start || (startDay !== UNDEFINED && endDay !== UNDEFINED && !isInWeek(startDate.getDay(), startDay, endDay))) {
    start = startTime;
  }

  return ((start - startTime) * 100) / displayedTime;
}

/** @hidden */
export function getResourceMap(
  eventsMap: { [key: string]: MbscCalendarEvent[] },
  resources: MbscResource[],
  slots: MbscSlot[],
  hasResources: boolean,
  hasSlots: boolean,
): { [key: string]: { [key: string]: { [key: string]: MbscCalendarEvent[] } } } {
  eventsMap = eventsMap || {};
  const eventKeys = Object.keys(eventsMap);
  const resourceMap: { [key: string]: { [key: string]: { [key: string]: MbscCalendarEvent[] } } } = {};
  const resourceIds = resources.map((resource) => resource.id);
  const slotIds = slots.map((s) => s.id);

  resourceIds.forEach((rid) => {
    resourceMap[rid] = {};
    slotIds.forEach((sid) => {
      resourceMap[rid][sid] = {};
    });
  });

  for (const timestamp of eventKeys) {
    const events = eventsMap[timestamp];
    for (const event of events) {
      const eventResource = event.resource;
      const eventSlot = event.slot;
      // If resources are not passed at all (null or undefined), we'll show all events.
      // If the event has not resource specified, show it for all resources.
      const res = eventResource === UNDEFINED || !hasResources ? resourceIds : isArray(eventResource) ? eventResource : [eventResource];
      const slot = eventSlot === UNDEFINED || !hasSlots ? slotIds : [eventSlot];
      res.forEach((rid) => {
        const map = resourceMap[rid];
        if (map) {
          slot.forEach((sid) => {
            const slotMap = map[sid];
            if (slotMap) {
              if (!slotMap[timestamp]) {
                slotMap[timestamp] = [];
              }
              slotMap[timestamp].push(event);
            }
          });
        }
      });
    }
  }
  return resourceMap;
}

/** @hidden */
export function getCellDate(timestamp: number, ms: number): Date {
  const d = new Date(timestamp);
  const time = new Date(+REF_DATE + ms); // Date with no DST
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), time.getHours(), time.getMinutes());
}
