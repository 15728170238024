import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { KTSVG } from "../../../../../_metronic/helpers";
import { AUTOTASK_ENTITY } from "../../../../constants/global";
import {HoverOverTooltip} from "./HoverOverTooltip";
import { handleEntered, handleMouseEnter } from "../../../../helpers/servicecall";
const MyScheduleEvent: FC<any> = ({
  data,
  onEventClick,
  view,
  general_settings,
  defaultMonthYearHighlight,
  todo_status_serviceCall,
  isDragOnHover,
  isHoverColor,
  timezone,
  viewSettings,
  locationShow,
  locations,
  prioritylist,
  task_status
}) => {
  const ev = data.original;
  const ticketDiv1 = "#DFDEFF";
  const taskDiv1 = "#FFE2E2";
  const servicecallDiv1 = "#e7f6e7";
  const appointmentDiv1 = "#fffaa0";
  const color = data.color;
  const bgcolor = ev.bgcolor;
  const colordiv1 =
    (ev?.parentType || ev?.type) !== "appointment"
      ? (ev?.total_ticket === 1 && ev?.total_task === 0
        ? ticketDiv1
        : ev?.total_task === 1 && ev?.total_ticket === 0
          ? taskDiv1
          : servicecallDiv1)
      : appointmentDiv1;

      let isNotAppointment = (ev?.parentType || ev?.type) !== "appointment";
      let main_title;
      if (isNotAppointment) {
        if (ev?.total_ticket === 1 && ev?.total_task === 0) {
            main_title = ev?.related_tickets?.[0]?.title;
        } else if (ev?.total_task === 1 && ev?.total_ticket === 0) {
            main_title = ev?.related_tasks?.[0]?.title;
        } else {
            main_title = ev?.title;
        }
      } else {
        main_title = ev?.title;
      }

  let Status = todo_status_serviceCall?.filter((status: any) => parseInt(status.id) === parseInt(ev?.servicecall_status))?.[0]?.name;

  let hasNoLicense: any = data?.currentResource?.has_license === false && data?.currentResource?.resource_only === false ? true : false;
  // Determine the base title based on event type
  let titlePrefix;
  if (general_settings?.show_task_type) {
      if (ev?.type === "servicecall" || ev?.type === "serviceCall") {
          titlePrefix = AUTOTASK_ENTITY.ServiceCall.title + ": ";
      } else {
          titlePrefix = (ev?.type?.charAt(0).toUpperCase() + ev?.type?.slice(1) + ": ");
      }
  } else {
      titlePrefix = '';
  }

  // Combine the title prefix with the main title
  let title = titlePrefix + (main_title ? main_title : '');


  const [locationName, setLocationName] = useState(null);

  useEffect(() => {
    function findLocationName(ev, locations) {
      if (!Array.isArray(locations) || locations.length === 0) {
        return null;
      }
      if (typeof ev.companyLocationID === 'undefined' || ev.companyLocationID === null) {
        return null;
      }

      for (const location of locations) {
        if (typeof location.autotask_id !== 'undefined' && location.autotask_id !== null) {
          if (ev.companyLocationID.toString() === location.autotask_id.toString()) {
            return location.name;
          }
        }
      }
      return null;
    }
    if (ev && locations?.data.length > 0 && locations?.data) {
      const name = findLocationName(ev, locations?.data);
      if (name) {
        setLocationName(name);
      } else {
        setLocationName(null);
      }
    } else {
      setLocationName(null);
    }
  }, [ev, locations]);


  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });  
  useEffect(() => {
    // Function to handle mouse movement
    const handleMouseMove = (event) => {
      setMousePos({ x: event.clientX, y: event.clientY });
    };
    // Attach event listener to capture mouse position
    window.addEventListener('mousemove', handleMouseMove);
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);


  const [rectValue, setRect] = useState<{ x: number; y: number; z: number }>({ x: 0, y: 0, z: 0 });

  const hoursWorked = ev?.hoursWorked || Number(0); //time_entries
  const estimatedHours = ev?.estimatedHours || Number(0); //planned OrganizationID
  const plannedHours = ev?.plannedHours || Number(0);
  ev.hoursLeft = estimatedHours - (hoursWorked + plannedHours);
      
  let actual_worked = (hoursWorked / estimatedHours) * 100;
  let planned_hours = (plannedHours / estimatedHours) * 100;
  let hour_left = (ev.hoursLeft / estimatedHours) * 100;
  
      let Priority = null;
      
      if (ev?.related_tasks?.length === 1) {
        const taskPriority = parseInt(ev.related_tasks[0]?.priority); 
        Priority = prioritylist?.find((twork: any) =>{
       return parseInt(twork.value) === Number(taskPriority)        
        })
        Priority = Priority ? Priority?.label : null;
      }

      if (ev?.related_tickets?.length === 1) {
        const ticketPriority = parseInt(ev.related_tickets[0]?.priority); 
        Priority = prioritylist?.find((twork: any) =>{
       return parseInt(twork.value) === Number(ticketPriority)        
        })
        Priority = Priority ? Priority?.label : null;
      }

        return (
        <>

        <div
          className={`${ev?.type === "servicecall" ? "hash-serviceCall" : ""} ${viewSettings?.rowSelection?.row1 ? "showRow1" : "hideRow1"
            } ${viewSettings?.rowSelection?.row2 ? "showRow2" : "hideRow2"} ${viewSettings?.rowSelection?.row3 ? "showRow3" : "hideRow3"
            }`}
        >
        {view === "week" || view === "day" ? (
          <OverlayTrigger
          key="copy-to-clipboard"
          placement="bottom"
          trigger={['hover', 'focus']}
          onEntered={(e:any) => handleEntered(e,rectValue,mousePos)}
          flip
          // defaultShow= {true}
          overlay={
            <Tooltip
              id="tooltip-copy-to-clipboard"
              className="position-fixed newTooltip pronewTooltip"
            >
             <><div className="text-start">
              <HoverOverTooltip
                ev = {ev}
                data ={data}
                title = {title}
                Status ={Status}
                timezone = {timezone}
                actual_worked = {actual_worked}
                planned_hours ={planned_hours}
                hour_left = {hour_left}
                Priority = {Priority}
                task_status = {task_status}
                screen_name = "planning"
                locationName={locationName}
                locationShow={locationShow}
              />
              </div>
              </> 
            </Tooltip>
          }
        >
          <div
            onMouseEnter={(e) => handleMouseEnter(e, false,setRect)}           
            onClick={(e) => onEventClick(e, ev)}
            className={`md-timeline-template-event mbsc-ios mbsc-ltr mbsc-timeline-event mbsc-timeline-event-start mbsc-timeline-event-end p-0 w-100 rounded-4px bg-white position-relative ${hasNoLicense && ev?.type !== "appointment"
              ? "isdisable"
              : ""
              }`}
            data-id="4"
          >
              <div
                className={`position-absolute start-0 w-100 top-0 h-32px headerColor ${ev?.type === "servicecall" && ""
                  }`}
                style={{ background: colordiv1, zIndex: "-1" }}
              ></div>
              <div className="mbsc-schedule-event-inner m-0 p-0 fs-16 text-dark">
                {/* Row one start */}
                <div
                  className={`h-32px overflow-hidden px-3 d-flex justify-content-between rowOne`}
                >

                  <span
                    className={`text-ellipsis d-inline-block align-middle h-32px mw-100 fw-bold lh-32px text-dark 
                      ${!ev?.editable && ev?.type !== "appointment"
                        ? "isdisable"
                        : ""}
                      ${ev?.type === "appointment" ? "cursor-default" : "cursor-pointer" }
                      `}
                  >
                    {title} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>

                  {/* for status checking  */}
                  {(Status) && (
                    <div
                      className={`d-inline-flex align-items-center h-32px mw-100  ${ev?.type === "appointment" && "d-none"
                        }`}
                    >


                      {!(Status === "Complete" || Status === "complete") && (
                        <small
                          className="d-inline-block align-middle min-w-6px min-h-6px rounded-circle me-4px"
                          style={{
                            backgroundColor: `${Status === "New"
                                ? "#5195FC"
                                : Status === "To Reschedule"
                                  ? "#5195FC"
                                  : "#5195FC"
                              }`,
                          }}
                        ></small>
                      )}
                      {(Status === "Complete" ||
                        Status === "complete") && (
                          <KTSVG
                            path="/media/icons/duotune/new-icons/check-icon.svg"
                            className="me-4px"
                            svgClassName="w-auto h-auto"
                          />
                        )}

                      <span className="d-inline-block align-middle fw-normal lh-sm text-ellipsis">
                        <small className="fs-100 text-ellipsis">{Status}</small>
                      </span>
                    </div>
                  )}
                </div>
                {/* Row one end */}

                {/* Row two start */}
                <div className="h-32px overflow-hidden d-flex align-items-center px-3 rowTwo">
                  <div className="text-ellipsis">
                    <KTSVG
                      path="/media/icons/duotune/new-icons/clock.svg"
                      className="text-dark me-4px"
                      svgClassName="w-16px h-auto mt-n2px"
                    />
                    <span className="me-4px">
                      {moment(ev?.start).tz(timezone).format("DD-MM-'YY")}
                    </span>
                    <strong className="me-4px">{data.start}</strong>
                    <span className="me-4px">-</span>
                    <span className="me-4px">
                      {moment(ev?.end).tz(timezone).format("DD-MM-'YY")}
                    </span>
                    <strong className="me-4px">{data.end}</strong>
                  </div>
                </div>
                {/* Row two end */}

                {/* Row three start */}
                <div className="d-flex flex-column justify-content-between h-32px rowThree"
                >
                  <div className="h-32px overflow-hidden d-flex flex-wrap align-items-center px-3">

                  {(ev?.companyLocationID && locationShow && locationName !== null)
                    && (
                      <span className="d-inline-flex align-items-center h-32px me-5 mw-100">
                        {!!ev.serviceCallLocationVisible ? (
                          ev.teamsLink !== "" ? (
                            <>
                              <i className="d-inline-flex me-4px">
                                <KTSVG
                                path="/media/icons/duotune/new-icons/ms-teams.svg"
                                  className="text-dark"
                                  svgClassName="w-16px h-auto"
                                />
                              </i>
                              <a className="text-ellipsis text-dark" title="Click to open Teams link" href={ev.teamsLink} target="_blank">
                                <small className="fs-100 text-ellipsis">
                                  Teams link
                                </small>
                              </a>
                            </>
                          ) : (
                            <>
                              <i className="d-inline-flex me-4px">
                                <KTSVG
                                  path="/media/icons/duotune/new-icons/ms-teams.svg"
                                  className="text-dark"
                                  svgClassName="w-16px h-auto"
                                />
                              </i>
                              <a className="text-ellipsis text-dark" title="Teams link is not available." target="_blank">
                                <small className="fs-100 text-ellipsis">
                                  Teams link
                                </small>
                              </a>
                            </>
                          )
                      ) : (
                          <>
                            <i className="d-inline-flex me-4px">
                              <KTSVG
                                path="/media/icons/duotune/new-icons/location.svg"
                                className="text-dark"
                                svgClassName="w-16px h-auto"
                              />
                            </i>
                            <small className="fs-100 text-ellipsis">{locationName}</small>
                          </>
                        )}
                      </span>)}
                        
                    {/* company starts here*/}
                    {ev?.company_name && (
                      <span className="d-inline-flex align-items-center h-32px me-5 mw-100">
                        <i className="d-inline-flex me-4px">
                        <KTSVG path="/media/icons/duotune/new-icons/building.svg" className="text-dark" svgClassName="w-18px h-auto" />
                        </i>
                        <small className="fs-100 text-ellipsis">{ev?.company_name}</small>
                      </span>
                    )}
                    {/* company ends here */}

                  {(ev?.type === "appointment" && locationShow )
                    && (
                      <span className="d-inline-flex align-items-center h-32px me-5 mw-100">
                        <i className="d-inline-flex me-4px">
                          <KTSVG
                            path="/media/icons/duotune/new-icons/ms-teams.svg"
                            className="text-dark"
                            svgClassName="w-16px h-auto"
                          />
                        </i>
                        <small className="fs-100 text-ellipsis"><a className="text-ellipsis text-dark" href={ev?.url} target="_blank">Teams link</a></small>
                      </span>)
                    }

                    {/* demo */}
                    {((ev?.total_task === 1 && ev?.total_ticket === 0) || (ev?.total_task === 0 && ev?.total_ticket === 1)) && (
                      <div className="d-inline-flex align-items-center h-32px mw-100 me-5">
                        <span className="text-ellipsis">
                          {ev?.title}
                        </span>
                      </div>)}
                    {(!((ev?.total_task === 1 && ev?.total_ticket === 0) || (ev?.total_task === 0 && ev?.total_ticket === 1)) && ev?.type !== "appointment") &&
                      (<div className="d-inline-flex align-items-center h-32px mw-100 me-5">
                        <span className="text-ellipsis">
                          {ev?.total_task} Tasks and {ev?.total_ticket} Tickets
                        </span>
                      </div>)
                    }

                    {ev?.related_resources?.length > 0 &&
                      <div className="ms-auto">
                          <div className="rounded-pill text-nowrap">
                            {ev?.related_resources?.length > 0 &&
                              Array.from(ev?.related_resources)?.map(
                                (resource: any, key: any) => {
                                  return (
                                    <>
                                      {key < 2 && resource?.firstName && (
                                        <span
                                          style={{
                                            marginLeft: `${key > 0 ? "-4px" : "0"}`,
                                            textTransform: "uppercase",
                                          }}
                                          className="rounded-circle bg-graydarkBg w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 align-top" >
                                          {resource?.firstName
                                            ?.trim()
                                            ?.substring(0, 1)}
                                          {resource?.lastName
                                            ?.trim()
                                            ?.substring(0, 1)}
                                        </span>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            {ev?.related_resources?.length > 2 && (
                              <span
                                className="rounded-circle bg-graydarkBg w-24px h-24px d-inline-flex align-items-center justify-content-center text-dark fs-14 fw-bold lh-sm align-top"
                                style={{
                                  marginLeft: "-4px",
                                }}
                              >
                                +{ev?.related_resources?.length - 2}
                              </span>
                            )}
                          </div>
                      </div>
                    }
                  </div>

                </div>
                {/* Row three end */}
              </div>
              {/* Progress div start - This progressbar will only show when first row is visible & row 2-3 will be hidden */}
              {((ev?.total_task === 1 && ev?.total_ticket === 0) ||
                (ev?.total_task === 0 && ev?.total_ticket === 1)) && (
                <OverlayTrigger
                  key="copy-to-clipboard"
                  placement="bottom"
                  //trigger="click"
                  overlay={<span></span>}
                >
                  <div
                    className="progress h-3px rounded-2px extraProgress"
                    // onMouseEnter={(e) => handleMouseEnter(e, false)}
                  >
                  {ev?.hoursLeft >= 0 && (
                    <><div
                      className="progress-bar text-dark fs-16 fw-bold h-100"
                      style={{
                        background: "#164387",
                        width: `${actual_worked}%`,
                      }}
                    ></div>
                    <div
                      className="progress-bar h-100 text-center text-dark fs-16 fw-bold"
                      style={{
                        background: "#7EA8E7",
                        width: `${planned_hours}%`,
                      }}
                    ></div>
                    <div
                      className="progress-bar text-dark fs-16 fw-bold h-100"
                      style={{
                        background: "#CEECCE",
                        width: `${hour_left}%`,
                      }}
                    ></div></>
                  )}
                    {ev?.hoursLeft < 0 && (
                      <div
                        className="progress-bar text-dark fs-16 fw-bold h-100"
                        style={{ background: "#f59393", width: `100%` }}
                      ></div>
                    )}
                  </div>
                </OverlayTrigger>
              )}
              {/* Progress div end - This progressbar will only show when first row is visible & row 2-3 will be hidden */}
          </div>
        </OverlayTrigger>
        ) : (
          <OverlayTrigger
            key="copy-to-clipboard"
            placement="top"
            // trigger="click"
            // defaultShow={true}
            overlay={
              <Tooltip id="tooltip-copy-to-clipboard" style={{ zIndex: 10000 }}>
                <div
                  className="md-tooltip-info align-left"
                  style={{ textAlign: "left" }}
                >
                  <div className="md-tooltip-title">
                    {defaultMonthYearHighlight} :{" "}
                    <span className="md-tooltip-reason md-tooltip-text">
                      {Number((Math.round(ev?.highlightedHours * 100) / 100).toFixed(2))}
                    </span>
                  </div>
                  <div className="md-tooltip-title">
                    Other :{" "}
                    <span className="md-tooltip-location md-tooltip-text">
                      {Number((Math.round(ev?.selectedStatusHours * 100) / 100).toFixed(2))}
                    </span>
                  </div>
                  <div className="md-tooltip-title">
                    Capacity :{" "}
                    <span className="md-tooltip-location md-tooltip-text">
                      {Number((Math.round(ev?.free * 100) / 100).toFixed(2))}
                    </span>
                  </div>
                </div>
              </Tooltip>
            }
          >
            <div
              className="md-timeline-template-event mbsc-ios mbsc-ltr mbsc-timeline-event mbsc-timeline-event-start mbsc-timeline-event-end overflow-hidden p-0 w-100"
              data-id="4"
            >
              <div className="mbsc-schedule-event-inner position-static custom-event-class w-100 p-0 m-0 rounded-4px">
                <div className="d-flex flex-column overflow-hidden">
                  <div className={`eventBody position-relative p-4 ${isDragOnHover ? 'onDragHover' : ''}`}>
                    <div
                      className="position-absolute start-0 top-0 opacity-100 w-100 h-100"
                      style={{ background: color }}
                    >
                      <span style={{ background: isHoverColor, width: '8px' }} className="d-block h-100 onHoverSpan"></span>
                    </div>
                    <div
                      className="position-relative d-flex flex-column fw-normal lh-sm"
                      style={{ zIndex: "1" }}
                    >
                      <span className="mbsc-schedule-event-title fs-16 pt-2 fw-bold">
                        <small className="fs-100 min-w-1px rounded-pill paddLR8 min-h-24px d-inline-flex align-items-center position-relative overflow-hidden">
                          <div
                            className="position-absolute start-0 top-0 opacity-100 w-100 h-100"
                            style={{ background: bgcolor, zIndex: "-1" }}
                          ></div>
                          {Number.isNaN(Number((Math.round(ev?.highlightedHours * 100) / 100).toFixed(2)))
                            ? ''
                            : Number((Math.round(ev?.highlightedHours * 100) / 100).toFixed(2))}
                        </small>
                        {Number.isNaN(Number((Math.round(ev?.selectedStatusHours * 100) / 100).toFixed(2)))
                          ? ''
                          : `/${Number((Math.round(ev?.selectedStatusHours * 100) / 100).toFixed(2))}/`}
                        {Number.isNaN(Number((Math.round(ev?.free * 100) / 100).toFixed(2)))
                          ? ''
                          : Number((Math.round(ev?.free * 100) / 100).toFixed(2))}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OverlayTrigger>
        )
        }
      </div>
      {/* for demo purpose end  */}

    </>
  );
};

export default MyScheduleEvent;