import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CounterState {
    initialSecretKey: boolean;
    organizationId:any;
    initialSyncState:boolean;
}

const initialState: CounterState = {
    initialSecretKey: false,
    organizationId:null,
    initialSyncState:null
};





export const initialSyncToastSlice = createSlice({
    name: 'initialSyncToast',
    initialState,
    reducers: {
        setInitialSecretKey: (state, action: PayloadAction<boolean>) => {
            state.initialSecretKey = action.payload;
        },
        setOrganizationId: (state, action: PayloadAction<any>) => {
            state.organizationId = action.payload;
        },
        setInitialSyncState: (state, action: PayloadAction<any>) => {
            state.initialSyncState = action.payload;
        },
    },
});

export const { setInitialSecretKey,setOrganizationId,setInitialSyncState } = initialSyncToastSlice.actions;

export default initialSyncToastSlice.reducer;