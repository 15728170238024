/* eslint-disable no-sequences */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getSuperadminUserByToken, resetPassword } from "../core/_requests";
import { Loading } from "../../../components/loading/Loading";
import { useQuery } from "react-query";
import { checkPasswordToken } from "../../../../_metronic/requests/RegistrationRequest";
export function ResetPassword() {
  
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const search = useLocation().search;
  let token = new URLSearchParams(search).get("token");

  const checkValues = {
    token: token,
  };

  const {
    isFetching,
    data: confirmdata,
  } = useQuery(
    'confirm-register',
    async () => {
      return await checkPasswordToken(checkValues);
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const initialValues = {
    password: "",
    cpassword: "",
    token: token,
  };
  const [meter, setMeter] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passworderror, ] = React.useState<any>();

  const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
  const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
  const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
  const eightCharsOrMore = /.{8,}/g; // eight characters or more

  const passwordTracker = {
    uppercase: password.match(atLeastOneUppercase),
    lowercase: password.match(atLeastOneLowercase),
    number: password.match(atLeastOneNumeric),
    specialChar: password.match(atLeastOneSpecialChar),
    eightCharsOrGreater: password.match(eightCharsOrMore),
  };

  const passwordStrength = Object.values(passwordTracker).filter((value) => value
  ).length;

  useEffect(() => { }, [passwordStrength, passworderror])
  const {
    data: superadminuser, }
    = useQuery('superadminUser', () => { return getSuperadminUserByToken('') })


  useEffect(() => {
  }, [superadminuser])
  const passwordSchema = Yup.object().shape({
    password: Yup.string().required("New password is required"),
    cpassword: Yup.string()
      .required("Please enter password field")
      .when("password", {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "New Password and Confirm Password does not match"
        )
      }),
  });

  const formdata = new FormData();
  const formik = useFormik({
    initialValues,
    validationSchema: passwordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      formdata.append('password', JSON.stringify(values.password))
      setLoading(true);
      setHasErrors(undefined);

      setTimeout(() => {
        resetPassword(values)
          .then(({ data: { result } }) => {
            navigate("/auth/login");
            setHasErrors(false);
            setLoading(false);
          })
          .catch((e) => {
            setHasErrors(true);
            setSubmitting(false);
            setErrorMessage(e.response?.data?.message || 'Sorry, looks like there are some errors detected, please try again.')
            setStatus("Can't update password");
            setLoading(false);
          });
      }, 1000);
    },
  });

  const handleChange = (entry: any) => {
    setPassword(entry.target.value)
    passcode = entry.target.value.replace(/ /g, '');
  }


  return (

    <div className="w-100 w-sm-350px">
      <div className="d-flex flex-center flex-column flex-lg-row-fluid">
        <Loading isLoading={isFetching}
            isHideSpinner={true}></Loading>
      </div>
      {!isFetching && 
            <div>
          {!confirmdata ?
            <div className="mb-8">
              <h1 className='text-dark fw-bold h2 fs-1'>{"Reset password link has expired." }</h1>
            </div>
                  :
      <form
        className="form w-100"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_password_reset_form"
      >
        <div className="mb-8">
          {/* begin::Title */}
          <h1 className="text-dark fw-bold mb-1 h2 fs-1 fs-ls-30">Reset Password</h1>
          {/* begin::Title */}
        </div>

        {/* begin::Message */}
        {hasErrors === true && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">
              {errorMessage}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className="mb-10 bg-light-info p-8 rounded">
            <div className="text-info">Password changed successfully</div>
          </div>
        )}
        {/* end::Message */}

        {/* begin::Form group */}
        <div className="fv-row mb-8">
          <label className="form-label fs-6 fs-ls-20">New Password</label>
          <input
            onFocus={() => setMeter(true)}
            onChange={(e) => {
              handleChange(e)
              formik.handleChange(e)
            }}
            name="password"
             value={formik.values.password}
            placeholder=""
            type="password"
            autoComplete="off"
            className={clsx(
              "form-control h-45px px-6 fs-6 rounded-1",
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block fs-ls-14">
                <span role="alert">{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        <div className="fv-plugins-message-container">
          <div className="fv-help-block fs-ls-14">
            <span role="alert">{passworderror}</span>
          </div>
        </div>
        {/* end::Form group */}
        <div className="mb-4">
          {meter && (
            <div>
              <div className="password-strength-meter"></div>
              <div>
                {passwordStrength < 5 && 'Must contain '}
                {!passwordTracker.uppercase && 'uppercase, '}
                {!passwordTracker.lowercase && 'lowercase, '}
                {!passwordTracker.specialChar && 'special character, '}
                {!passwordTracker.number && 'number, '}
                {!passwordTracker.eightCharsOrGreater &&
                  'eight characters or more'}
              </div>
              {passwordStrength < 5 && <div>For example:Afs#52&d</div>}
            </div>
          )}
          <style>
            {`

          .password-strength-meter {
            height: 0.4rem;
            background-color: lightgrey;
            border-radius: 3px;
            margin: .5rem 0
          }

          .password-strength-meter::before {
            content: "";
            background-color: ${['red', 'red', 'orange', 'orange', 'green'][
              passwordStrength - 1
              ] || ''
              };
            height: 100%;
            width: ${(passwordStrength / 5) * 100}%;
            display: block;
            border-radius: 3px;
            transition: width 0.2s;
          }
        `}
          </style>
        </div>
        {/* begin::Form group */}
        <div className="fv-row mb-8 mt-8">
          <label className="form-label fs-6 fs-ls-20">Confirm Password</label>
          <input
            type="password"
            placeholder=""
            disabled={passwordStrength < 5}
            autoComplete="off"
            {...formik.getFieldProps("cpassword")}
            className={password ? clsx(
              "form-control h-45px px-6 fs-6 rounded-1",
              {
                "is-invalid":
                  formik.touched.cpassword && formik.errors.cpassword,
              },
              {
                "is-valid":
                  formik.touched.cpassword && !formik.errors.cpassword,
              }
            ) : "form-control h-45px px-6 fs-6 rounded-1"}
          />
          {password ? formik.touched.cpassword && formik.errors.cpassword && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block fs-ls-14">
                <span role="alert">{formik.errors.cpassword}</span>
              </div>
            </div>
          ) : ''}
        </div>
        {/* end::Form group */}

        <div className="d-flex flex-wrap justify-content-start pb-lg-0">
          {/* begin::Action */}
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-primary h-45px fs-6 px-10 fs-ls-20 h-ls-60 me-3"
            disabled={passwordStrength < 5}
          >
            {!loading && <span className="indicator-label">Submit</span>}
            <Loading isLoading={loading} text={"Please wait..."}></Loading>
          </button>
          &nbsp;
          <Link to="/superadmin/auth/login">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-dark h-45px fs-6 px-10 fs-ls-20 h-ls-60"
            >
              Cancel
            </button>
          </Link>
          {/* end::Action */}
        </div>
      </form>
}
</div>
}
    </div>
  );
}
