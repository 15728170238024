import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'

// Define a type for the slice state
interface CounterState {
  project_ids: any
  people_ids: any
  department_ids: any
  company_ids: any
  location_ids: any
  skill_ids: any
  role_ids: any
  workgroup_ids: any
  project_capacity_ids: any;
  people_capacity_ids: any;
  department_capacity_ids: any;
  company_capacity_ids: any;
  location_capacity_ids: any;
  workgroup_capacity_ids: any;
  role_capacity_ids: any
  refreshtime?: any;
  pageChange?: any;
  todoCount?: any;
  planningCache?: any;
  type_ids?: any;
  servicecall_status_ids?: any
  is_filter?: any
  isyearLoader?: any
  servicecall_status_capacity_ids?: any
  type_capacity_ids?: any;
  project_leads_capacity_ids?: any
  queues_ids?: any
  queues_capacity_ids?: any
  priorities_capacity_ids?: any
  ticketCategory_capacity_ids?: any
  ticketType_capacity_ids?: any
  taskCategory_capacity_ids?: any
  slas_capacity_ids?: any,
  contract_category_ids?: any
  skill_capacity_ids?: any
  clear_all_filter?: any
  clear_all_planning_filter?: any
  syncType?: any;
  isSyncInProgress?: any;
  isSaved?: any;
  isCreated?: any;
  ispageLoad?: any;
  callApiforfilter?: any;

  callApifortodofilter?: any;
  lineOfBusiness_ids: any;
  lineOfBusiness_capacity_ids: any;
  projecttype_ids:any
  resources_ids:any

  //project screen
  project_lead: any
  projects: any
  project_company: any
  task_status: any
  project_status: any
  appointment_filter: any
  project_start_date_option: string,
  project_start_date_month_value: number,
  project_end_date_option: string,
  project_end_date_month_value: number,
  project_view: boolean,
  callApiforprojectfilter:any

}

// Define the initial state using that type
const initialState: CounterState = {
  project_ids: [],
  people_ids: [],
  department_ids: [],
  company_ids: [],
  location_ids: [],
  skill_ids: [],
  role_ids: [],
  workgroup_ids: [],
  type_ids: [],
  project_capacity_ids: [],
  people_capacity_ids: [],
  department_capacity_ids: [],
  company_capacity_ids: [],
  location_capacity_ids: [],
  workgroup_capacity_ids: [],
  role_capacity_ids: [],
  refreshtime: 60,
  pageChange: false,
  todoCount: 0,
  planningCache: true,
  servicecall_status_ids: [],
  is_filter: false,
  isyearLoader: false,
  servicecall_status_capacity_ids: [],
  type_capacity_ids: [],
  project_leads_capacity_ids: [],
  queues_ids: [],
  queues_capacity_ids: [],
  priorities_capacity_ids: [],
  ticketCategory_capacity_ids: [],
  ticketType_capacity_ids: [],
  taskCategory_capacity_ids: [],
  slas_capacity_ids: [],
  contract_category_ids: [],
  skill_capacity_ids: [],
  clear_all_filter: false,
  clear_all_planning_filter: false,
  isSyncInProgress: false,
  isSaved: false,
  isCreated: false,
  ispageLoad: true,
  callApiforfilter: false,
  callApifortodofilter: false,
  lineOfBusiness_ids: [],
  lineOfBusiness_capacity_ids: [],
  projecttype_ids: [],
  resources_ids:[],

  //project screen
  project_lead: [],
  projects: [],
  project_company: [],
  task_status: [],
  project_status: [],
  appointment_filter: [],
  project_start_date_option: "",
  project_start_date_month_value: 0,
  project_end_date_option: "",
  project_end_date_month_value: 0,
  project_view: false,
  callApiforprojectfilter: undefined
}
export const counterSlice = createSlice({

  name: 'counter',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    // increment: (state) => {
    //   state.value += 1
    // },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    // Use the PayloadAction type to declare the contents of `action.payload`
    storeProjectIds: (state, action: PayloadAction<string>) => {
      state.project_ids = action.payload
    },
    storePeopleIds: (state, action: PayloadAction<string>) => {
      state.people_ids = action.payload
    },
    storeDepartmentIds: (state, action: PayloadAction<string>) => {
      state.department_ids = action.payload
    },
    storeCompanyIds: (state, action: PayloadAction<string>) => {
      state.company_ids = action.payload
    },
    storeLocationIds: (state, action: PayloadAction<string>) => {
      state.location_ids = action.payload
    },
    storeSkillIds: (state, action: PayloadAction<string>) => {
      state.skill_ids = action.payload
    },
    storeRoleIds: (state, action: PayloadAction<string>) => {
      state.role_ids = action.payload
    },
    storeWorkgroupids: (state, action: PayloadAction<string>) => {
      state.workgroup_ids = action.payload
    },
    storeTypeids: (state, action: PayloadAction<string>) => {
      state.type_ids = action.payload
    },
    storeservicecall_statusids: (state, action: PayloadAction<string>) => {
      state.servicecall_status_ids = action.payload
    },
    storequeuesids: (state, action: PayloadAction<string>) => {
      state.queues_ids = action.payload
    },
    storelineOfBusinessIds: (state, action: PayloadAction<string>) => {
      state.lineOfBusiness_ids = action.payload
    },

    //Capacity Filter
    storeCapacityProjectIds: (state, action: PayloadAction<string>) => {
      state.project_capacity_ids = action.payload
    },
    storeCapacityPeopleIds: (state, action: PayloadAction<string>) => {
      state.people_capacity_ids = action.payload
    },
    storeCapacityDepartmentIds: (state, action: PayloadAction<string>) => {
      state.department_capacity_ids = action.payload
    },
    storeCapacityCompanyIds: (state, action: PayloadAction<string>) => {
      state.company_capacity_ids = action.payload
    },
    storeCapacityLocationIds: (state, action: PayloadAction<string>) => {
      state.location_capacity_ids = action.payload
    },
    storeCapacityWorkgroupIds: (state, action: PayloadAction<string>) => {
      state.workgroup_capacity_ids = action.payload
    },
    storeCapacityRoleIds: (state, action: PayloadAction<string>) => {
      state.role_capacity_ids = action.payload
    },
    storeCapacityTypeids: (state, action: PayloadAction<string>) => {
      state.type_capacity_ids = action.payload
    },
    storeCapacityservicecall_statusids: (state, action: PayloadAction<string>) => {
      state.servicecall_status_capacity_ids = action.payload
    },
    storeCapacityprojectLeadsids: (state, action: PayloadAction<string>) => {
      state.project_leads_capacity_ids = action.payload
    },
    storeCapacityqueuesids: (state, action: PayloadAction<string>) => {
      state.queues_capacity_ids = action.payload
    },
    storeCapacityprioritiesids: (state, action: PayloadAction<string>) => {
      state.priorities_capacity_ids = action.payload
    },
    storeCapacityticketCategoryids: (state, action: PayloadAction<string>) => {
      state.ticketCategory_capacity_ids = action.payload
    },
    storeCapacityticketTypeids: (state, action: PayloadAction<string>) => {
      state.ticketType_capacity_ids = action.payload
    },
    storeCapacitytaskCategoryids: (state, action: PayloadAction<string>) => {
      state.taskCategory_capacity_ids = action.payload
    },
    storeCapacityslasids: (state, action: PayloadAction<string>) => {
      state.slas_capacity_ids = action.payload
    },
    storeCapacitycontractcategoryids: (state, action: PayloadAction<string>) => {
      state.contract_category_ids = action.payload
    },
    storeCapacityskillids: (state, action: PayloadAction<string>) => {
      state.skill_capacity_ids = action.payload
    },
    storeClearAllFilter: (state, action: PayloadAction<boolean>) => {
      state.clear_all_filter = action.payload
    },
    storeClearAllPlanningFilter: (state, action: PayloadAction<boolean>) => {
      state.clear_all_planning_filter = action.payload
    },

    storeRefreshTime: (state, action: PayloadAction<string>) => {
      state.refreshtime = action.payload
    },
    setpageChange: (state, action: PayloadAction<boolean>) => {
      state.pageChange = action.payload
    },
    settodoCount: (state, action: PayloadAction<boolean>) => {
      state.todoCount = action.payload
    },
    setplanningCache: (state, action: PayloadAction<boolean>) => {
      state.planningCache = action.payload
    },
    setisFilter: (state, action: PayloadAction<boolean>) => {
      state.is_filter = action.payload
    },
    setisyearLoader: (state, action: PayloadAction<boolean>) => {
      state.isyearLoader = action.payload
    },
    setsyncType: (state, action: PayloadAction<string>) => {
      state.syncType = action.payload
    },
    setisSyncInProgress: (state, action: PayloadAction<boolean>) => {
      state.isSyncInProgress = action.payload
    },
    setisSaved: (state, action: PayloadAction<boolean>) => {
      state.isSaved = action.payload
    },
    setisCreated: (state, action: PayloadAction<boolean>) => {
      state.isCreated = action.payload
    },
    setispageLoad: (state, action: PayloadAction<boolean>) => {
      state.ispageLoad = action.payload
    },
    setcallApiforfilter: (state, action: PayloadAction<boolean>) => {
      state.callApiforfilter = action.payload
    },
    setcallApiforProjectfilter: (state, action: PayloadAction<boolean>) => {
      state.callApiforprojectfilter = action.payload
    },
    setcallApifortodofilter: (state, action: PayloadAction<boolean>) => {
      state.callApifortodofilter = action.payload
    },
    storeCapacitylineOfBusinessIds: (state, action: PayloadAction<string>) => {
      state.lineOfBusiness_capacity_ids = action.payload
    },
    storeProjectTypeids: (state, action: PayloadAction<string>) => {
      state.projecttype_ids = action.payload
    },
    storeResourcesids: (state, action: PayloadAction<string>) => {
      state.resources_ids = action.payload
    },

    //project screen 
    storeProjects: (state, action: PayloadAction<string>) => {
      state.projects = action.payload
    },
    storeProjectStatus: (state, action: PayloadAction<string>) => {
      state.project_status = action.payload
    },
    storeProjectLead: (state, action: PayloadAction<string>) => {
      state.project_lead = action.payload
    },
    storeProjectCompany: (state, action: PayloadAction<string>) => {
      state.project_company = action.payload
    },
    storeTaskStatus: (state, action: PayloadAction<string>) => {
      state.task_status = action.payload
    },
    storeAppointmentFilter: (state, action: PayloadAction<any>) => {
      state.appointment_filter = action.payload
    },

    storeProjectStartDateOption: (state, action: PayloadAction<string>) => {
      state.project_start_date_option = action.payload
    },
    storeProjectStartDateMonthValue: (state, action: PayloadAction<number>) => {
      state.project_start_date_month_value = action.payload
    },
    storeProjectEndDateOption: (state, action: PayloadAction<string>) => {
      state.project_end_date_option = action.payload
    },
    storeProjectEndDateMonthValue: (state, action: PayloadAction<number>) => {
      state.project_end_date_month_value = action.payload
    },
    storeProjectView: (state, action: PayloadAction<boolean>) => {
      state.project_view = action.payload
    },
  },
})

export const { storeProjectIds, storePeopleIds, storeDepartmentIds, storeCompanyIds,

  storeCapacityProjectIds, storeCapacityPeopleIds, storeCapacityskillids, storeCapacityDepartmentIds, storeCapacityCompanyIds, storeRefreshTime, storeLocationIds, storeWorkgroupids, storeCapacityLocationIds, storeCapacityWorkgroupIds, setpageChange, settodoCount, setplanningCache, storeSkillIds, storeRoleIds, storeTypeids, storeservicecall_statusids, setisFilter, setisyearLoader, storeCapacityRoleIds, storeCapacityTypeids, storeCapacityservicecall_statusids, storeCapacityprojectLeadsids, storeCapacityqueuesids, storequeuesids, storeCapacityprioritiesids, storeCapacityticketCategoryids, storeCapacityticketTypeids, storeCapacitytaskCategoryids, storeCapacityslasids, storeCapacitycontractcategoryids, storeClearAllFilter, setsyncType, setisSyncInProgress, storeClearAllPlanningFilter, setisSaved, setisCreated, setispageLoad, setcallApiforfilter, setcallApifortodofilter, storelineOfBusinessIds, storeCapacitylineOfBusinessIds, storeProjects, storeProjectStatus, storeProjectLead, storeProjectCompany, storeTaskStatus, storeAppointmentFilter, storeProjectEndDateOption, storeProjectEndDateMonthValue, storeProjectStartDateOption, storeProjectStartDateMonthValue, storeProjectView, setcallApiforProjectfilter,storeProjectTypeids, storeResourcesids } = counterSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.counter

export default counterSlice.reducer