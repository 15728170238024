
import { FC } from "react";
import { Link } from "react-router-dom";
import { Dashboard } from "../../modules/apps/dashboard-management/core/_model";
import { Loading } from "../loading/Loading";
type Props = {
    data: Dashboard;
    isLoading: boolean;
};

const TileListWrapper: FC<Props> = ({ data, isLoading }) => (

    <div className="card h-100">
        {/* begin::Header */}
        <div className='card-header border-0 p-6 pb-0 min-h-1px'>
            <div className='card-title align-items-start flex-column m-0'>
                <span className='fw-bold text-hover-primary text-dark m-0 fw-bold fs-18'>Top allocated resources</span>
            </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body position-relative p-5 pt-6 ps-6 pb-0">

            <div className="bg-gray-300 d-flex align-items-center justify-content-between py-3 px-4 rounded">
                <span className="text-gray-900 opacity-50 fs-16">Resource name</span>
                {/* <span className="text-gray-900 opacity-50 fs-6 fs-ls-14">%Availability</span> */}
            </div>
            {!isLoading &&
                <div className="scroll h-600px">
                    {data?.resources!.map(({ firstName, percentage }, i) => {
                        return (
                            <div key={i} className='d-flex align-items-center justify-content-between px-4 py-3'>
                                <Link id="RouterNavLink" className='fs-16 text-gray-900 text-hover-primary fw-bold' to="#">{firstName}</Link>
                                {/* <span className='fs-6 fw-bold fs-ls-16'>{percentage}%</span> */}
                            </div>
                        )
                    })}
                </div>
            }
            <Loading isLoading={isLoading}></Loading>
        </div>
    </div>
);



export { TileListWrapper };


