import { addDays, getFirstDayOfWeek, makeDate } from '../../util/datetime';
import { constrain, isEmpty, UNDEFINED } from '../../util/misc';
import { ICalendarViewProps } from '../calendar-view/calendar-view.types';
import { getFirstPageDay, getPageIndex, getPageNr } from '../calendar-view/calendar-view.util';

// tslint:disable no-non-null-assertion

export class MbscCalendarNavService {
  public pageIndex?: number;
  public firstDay!: Date;
  public lastDay!: Date;
  public firstPageDay?: Date;
  public lastPageDay?: Date;
  public viewStart!: Date;
  public viewEnd!: Date;
  public maxDate!: Date | number;
  public minDate!: Date | number;

  public forcePageChange?: boolean;
  public pageSize = 0;
  public preventPageChange?: boolean;

  // tslint:disable-next-line: variable-name
  private _prevS: ICalendarViewProps = {};
  // tslint:disable-next-line: variable-name
  private _s: ICalendarViewProps = {};

  public options(news: ICalendarViewProps, forcePageLoading?: boolean) {
    const s = (this._s = { ...this._s, ...news });
    const prevS = this._prevS;
    const getDate = s.getDate!;
    const getYear = s.getYear!;
    const getMonth = s.getMonth!;
    const showCalendar = s.showCalendar;
    const calendarType = s.calendarType;
    const startDay = s.startDay!;
    const endDay = s.endDay!;
    const firstWeekDay = s.firstDay!;
    const isWeekView = calendarType === 'week';
    const weeks = showCalendar ? (isWeekView ? s.weeks! : 6) : 0;
    const minDate = s.min !== prevS.min || !this.minDate ? (!isEmpty(s.min) ? makeDate(s.min) : -Infinity) : this.minDate;
    const maxDate = s.max !== prevS.max || !this.maxDate ? (!isEmpty(s.max) ? makeDate(s.max) : Infinity) : this.maxDate;
    const initialActive = s.activeDate || +new Date();
    const activeDate = constrain(initialActive, +minDate, +maxDate);
    const forcePageChange = this.forcePageChange || activeDate !== initialActive;
    const d = new Date(activeDate);
    const activeChanged = activeDate !== prevS.activeDate;
    const viewChanged =
      s.calendarType !== prevS.calendarType ||
      s.eventRange !== prevS.eventRange ||
      s.firstDay !== prevS.firstDay ||
      s.eventRangeSize !== prevS.eventRangeSize ||
      s.refDate !== prevS.refDate ||
      showCalendar !== prevS.showCalendar ||
      s.size !== prevS.size ||
      s.weeks !== prevS.weeks;

    const pageIndex =
      forcePageChange ||
      this.pageIndex === UNDEFINED ||
      viewChanged ||
      (!this.preventPageChange && activeChanged && (activeDate < +this.firstDay || activeDate >= +this.lastDay))
        ? getPageIndex(d, s)
        : this.pageIndex;

    const size = calendarType === 'year' ? 12 : s.size || 1;
    const isGrid = size > 1 && !isWeekView;
    const pageNr = isGrid ? 1 : getPageNr(s.pages, this.pageSize);
    const isVertical = s.calendarScroll === 'vertical' && s.pages !== 'auto' && (s.pages === UNDEFINED || s.pages === 1);
    const showOuter = s.showOuterDays !== UNDEFINED ? s.showOuterDays : !isVertical && pageNr < 2 && (isWeekView || !size || size < 2);
    const pageBuffer = isGrid ? 0 : 1;

    let firstDay = getFirstPageDay(pageIndex!, s);
    let lastDay = getFirstPageDay(pageIndex! + pageNr, s);
    // In case of scheduler and timeline, if startDay & endDay is specified, calculate first and last days based on that
    if (!showCalendar && s.eventRange === 'week' && startDay !== UNDEFINED && endDay !== UNDEFINED) {
      firstDay = addDays(firstDay, startDay - firstWeekDay + (startDay < firstWeekDay ? 7 : 0));
      lastDay = addDays(firstDay, 7 * s.eventRangeSize! + endDay - startDay + 1 - (endDay < startDay ? 0 : 7));
    }

    const firstPageDay = showCalendar && showOuter ? getFirstDayOfWeek(firstDay, s) : firstDay;
    const lastPage = isGrid ? getDate(getYear(lastDay), getMonth(lastDay) - 1, 1) : getFirstPageDay(pageIndex! + pageNr - 1, s);
    const lastPageDay = showCalendar && showOuter ? addDays(getFirstDayOfWeek(lastPage, s), weeks * 7) : lastDay;
    const start = showCalendar ? getFirstDayOfWeek(getFirstPageDay(pageIndex! - pageBuffer, s), s) : firstDay;
    const last = showCalendar ? getFirstDayOfWeek(getFirstPageDay(pageIndex! + pageNr + pageBuffer - 1, s), s) : lastDay;
    const end = showCalendar ? addDays(isGrid ? getFirstDayOfWeek(lastPage, s) : last, weeks * 7) : lastDay;
    const initialRun = this.pageIndex === UNDEFINED;

    let viewStart = start;
    let viewEnd = end;
    if (!showCalendar && s.resolution === 'week' && (s.eventRange === 'year' || s.eventRange === 'month')) {
      const length = endDay - startDay + 1 + (endDay < startDay ? 7 : 0);
      if (firstDay.getDay() !== startDay) {
        const weekStart = getFirstDayOfWeek(firstDay, s, startDay);
        const weekEnd = addDays(weekStart, length);
        viewStart = weekEnd <= firstDay ? addDays(weekStart, 7) : weekStart;
      }
      if (lastDay.getDay() !== startDay) {
        const weekStart = getFirstDayOfWeek(lastDay, s, startDay);
        const weekEnd = addDays(weekStart, length);
        viewEnd = weekStart > lastDay ? addDays(weekEnd, -7) : weekEnd;
      }
    }

    let pageChange = false;

    if (pageIndex !== UNDEFINED) {
      pageChange = +viewStart !== +this.viewStart || +viewEnd !== +this.viewEnd;
      this.pageIndex = pageIndex;
    }

    this.firstDay = firstDay;
    this.lastDay = lastDay;
    this.firstPageDay = firstPageDay;
    this.lastPageDay = lastPageDay;
    this.viewStart = viewStart;
    this.viewEnd = viewEnd;
    this.forcePageChange = false;
    this.preventPageChange = false;
    this.minDate = minDate;
    this.maxDate = maxDate;
    this._prevS = s;

    if (pageIndex !== UNDEFINED && (pageChange || forcePageLoading)) {
      if (pageChange && !initialRun) {
        this.pageChange();
      }
      this.pageLoading(pageChange);
    }
  }

  private pageChange() {
    if (this._s.onPageChange) {
      this._s.onPageChange({
        firstDay: this.firstPageDay,
        lastDay: this.lastPageDay,
        month: this._s.calendarType === 'month' ? this.firstDay : UNDEFINED,
        type: 'onPageChange',
        viewEnd: this.viewEnd,
        viewStart: this.viewStart,
      } as any);
    }
  }

  private pageLoading(viewChanged: boolean) {
    if (this._s.onPageLoading) {
      this._s.onPageLoading({
        firstDay: this.firstPageDay,
        lastDay: this.lastPageDay,
        month: this._s.calendarType === 'month' ? this.firstDay : UNDEFINED,
        type: 'onPageLoading',
        viewChanged,
        viewEnd: this.viewEnd,
        viewStart: this.viewStart,
      } as any);
    }
  }
}
