import { FC } from "react";
import { Tooltip } from "../../core/_types";

type Props = {
  tooltip?: Tooltip;
};

const IntrojsDisableCell: FC<Props> = ({ tooltip }) => {
  return (
    <div className={"badge bg-primary"}>
      {tooltip.disableScrolling ? "Yes" : "No"}
    </div>
  );
};

export { IntrojsDisableCell };
