
import { BaseComponent, IBaseProps } from '../../base';

// tslint:disable directive-class-suffix
// tslint:disable directive-selector

// tslint:disable interface-name
export interface MbscListOptions extends IBaseProps {
  theme?: string;
}
// tslint:enable interface-name

/** @hidden */

export class ListBase extends BaseComponent<MbscListOptions, any> {
  // tslint:disable variable-name
  public _cssClass?: string;
  // tslint:enable variable-name

  protected _render(s: MbscListOptions) {
    this._cssClass = this._className + this._rtl + ' mbsc-font mbsc-list' + this._theme;
  }
}
