import { StatisticsListWrapper } from "../../../components/statistics/StatisticsListWrapper";
import { TileListWrapper } from "../../../components/tiles/TileListWrapper";
import "./../../../../_metronic/assets/sass/custom.scss";
import { useQuery } from "react-query";
import { getTotals } from "../../../../_metronic/requests/DashboardRequest";
import {  useState } from "react";
import { getTooltipData } from "../../superadmin/hint-tour/core/_request";
import { Hints, Steps } from "intro.js-react";
import { updateTooltipVisibilityByPage } from "./core/_request";
import { useAuth } from "../../auth/core/AuthCognito";

const DashboardPage = () => {
  const { currentUser } = useAuth();
  const [enabled, setEnabled] = useState<boolean>(true);
  const [initialStep] = useState<number>(0);
  const { _id, showTooltipVisibility } = currentUser.data?.user;
  const tooltipVisibility = {
    showTooltipVisibility: {
      Dashboard: false,
    },
  };
  const onExit = async () => {
    setEnabled(false);
    await updateTooltipVisibilityByPage(_id, tooltipVisibility);
  };
  const {
    isFetching,
    data: response,
  } = useQuery(
    "",
    () => {
      return getTotals();
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  const { data: introData } = useQuery("intro-data", () => {
    return getTooltipData();
  });

  const steps = introData?.filter(
    (v: any) => v.type === "tour" && v.page === "Dashboard"
    // (v) => v.type === "tour"
  );
  const hints = introData?.filter(
    (v: any) => v.type === "hint" && v.page === "Dashboard"
    // (v) => v.type === "hint"
  );

  const getSubdomain = (): string => {
    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const hostname = urlObject.hostname;
    const parts = hostname.split(".");
    if (parts[0] == "www" || parts[0] == "" || parts[0] == "localhost") {
      localStorage.clear();
      localStorage.removeItem("kt-auth-react-v");
      localStorage.setItem("subdomainExists", "false");
    }
    // Check if the URL is localhost or has only one part

    // Assuming the first part is the subdomain
    return parts[0];
  };

  getSubdomain();

  return (
    <>
    {showTooltipVisibility?.Dashboard !== false && response && (
        <>
          <Steps
            enabled={enabled}
            initialStep={initialStep}
            steps={steps?.length > 0 ? steps : []}
            onExit={onExit}
            options={{
              showStepNumbers: true,
              showButtons: true,
              hidePrev: true,
              scrollToElement: true,
              showBullets: true,
              showProgress: true,
              exitOnOverlayClick: false,

            }}
          />
          <Hints enabled={enabled} hints={hints?.length > 0 ? hints : []} />
        </>
      )}
      <div className="row m-0 px-4 py-6 overflow-auto mh-100 dashMain">
        <div className="col-xxl-6 dashLeft">
          <StatisticsListWrapper
            data={response}
            isLoading={isFetching}
          ></StatisticsListWrapper>
        </div>
        <div className="col-xxl-6 dashRight">
          <TileListWrapper
            data={response}
            isLoading={isFetching}
          ></TileListWrapper>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
