export const TASK_COLOR = "#FFE2E2";
export const TICKET_COLOR = "#DFDEFF";
export const SERVICECALL_COLOR = "#CEECCE";
export const TASK_HOVER_COLOR = "#d5b0b0";
export const TICKET_HOVER_COLOR = "#b6b5db";
export const SERVICECALL_HOVER_COLOR = "#abcdab";
export const MONTHYEARCOLOUR = {
    over : "#FFE2E2",
    over_bg : "#e9bfbf",
    left : "#DFDEFF",
    left_bg: "#b8b5e7",
    noplanned: "#CEECCE",
    noplanned_bg: "#9ed19e"
};

