import React, { FC } from "react";
import moment from "moment-timezone";

const RenderWeekDay: FC<any> = ({ args, view }) => {
  let start = moment(args?.startDate);
  let end = moment(args?.endDate);
  return (
    <div className="d-flex align-items-center">
      {(view === "month") && (
        <div className={"md-date-header md-date-header-events-"}>
          <div className="md-date-header-day-name">
            {start?.format("DD-MM")} - {end?.format("DD-MM")}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(RenderWeekDay);
