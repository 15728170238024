import { ID, Response } from "../../../../_metronic/helpers";

export type RegistrationDetail = {
  [x: string]: unknown;
  id?: ID;
  first_name?: string;
  last_name?: string;
  email?: string;
  invoice_email?: string;
  zipcode?: string;
  street?: string;
  city?: string;
  terms_condition?: boolean,
  datacenter?: string,
  company_name?: string,
  country?: string,
  // client_id?: string;
  // secret?: string;
  // integration_code?: string;
  subdomain?: string;
  // is_active?: boolean,

};

export type RegistrationDetailQueryResponse = Response<Array<RegistrationDetail>>;

export const initialRegistrationDetail: RegistrationDetail = {
 
  first_name: "",
  last_name: "",
  email: "",
  invoice_email: "",
  zipcode: "",
  street: "",
  city: "",
  datacenter: "frankfurt",
  terms_condition: false,
  company_name: '',
  country: 'NL',
  // client_id: "",
  // secret: "",
  // integration_code: "",
  subdomain: "",
  // is_active: false,
};
