import { FC } from "react";
import { useAuth } from "../../modules/auth/core/AuthCognito";
import { useQueryRequest } from "../core/QueryRequestProvider";
import { ActivityTabs } from "./ActivityTabcontent/ActivityTabs";
import { ActivityTags } from "./ActivityTabcontent/ActivityTags";
import { ActivityTaskHeader } from "./ActivityTabcontent/ActivityTaskStatus";
import { ActivityTaskToolbar } from "./ActivityTabcontent/ActivityTaskToolbar";

const ActivityTaskDrawer: FC = () => {

  const { state } = useQueryRequest();
  const { currentUser } = useAuth();
  

  return (
    <div
      id="kt_activity"
      className="bg-body"
      data-kt-drawer="true"
      data-kt-drawer-name="activity"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'300px', 'lg': '580px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_activity_toggle"
      data-kt-drawer-close="#kt_activity_close"
    >
      <div className="card shadow-none rounded-0">
        <div className="card-header" id="kt_activity_header">
          {/* Toolbar */}
          <ActivityTaskToolbar
            createbuttontext="Create Project"
            username={currentUser?.data.data?.name}
          />
          {/* End:Toolbar */}
        </div>
        <div className="card-body position-relative" id="kt_activity_body">
          <div
            id="kt_activity_scroll"
            className="position-relative scroll-y me-n5 pe-5"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-wrappers="#kt_activity_body"
            data-kt-scroll-dependencies="#kt_activity_header, #kt_activity_footer"
            data-kt-scroll-offset="5px"
          >
            <ActivityTaskHeader
              descriptionnote="Go ahead and create your project from here." 
              taskdata={undefined}            
              />
            <div className="separator"></div>
            <ActivityTags
              lefttab="Assignees"
              useroverlay="PS"
              duedate="Sep 21, 2022 at 11:30 AM"
              righttab="Tags"
              prioritybutton="High Priority"
              addbutton="Add"
              dependancy="Dependancy"
              setbutton="Set"
            />
            <div className="separator"></div>
            <ActivityTabs 
                taskadd="Add a new Subtask...." 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ActivityTaskDrawer };
