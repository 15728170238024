// SubdomainContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface SubdomainContextProps {
  subdomainExists: boolean;
  setSubdomainExists: React.Dispatch<React.SetStateAction<boolean>>;
}

const SubdomainContext = createContext<SubdomainContextProps | undefined>(undefined);

export const SubdomainProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [subdomainExists, setSubdomainExists] = useState(false);

  const value = {
    subdomainExists,
    setSubdomainExists,
  };

  return (
    <SubdomainContext.Provider value={value}>
      {children}
    </SubdomainContext.Provider>
  );
};

export const useSubdomain = (): SubdomainContextProps => {
  const context = useContext(SubdomainContext);
  if (!context) {
    throw new Error('useSubdomain must be used within a SubdomainProvider');
  }
  return context;
};