export const permissionArray = [
  {
    name: "Dashboard",
    value: false,
    url: "/apps/dashboard-management/dashboard"
  },
  {
    name: "Planning",
    value: false,
    url: "/apps/planning/resources"
  },
  {
    name: "Users",
    value: false,
    url: "/apps/user-management/users"
  },
  {
    name: "Projects",
    value: false,
    url:"/apps/projects/list"
  },
  {
    name: "Notification",
    value: false,
    url:"/apps/notifications/list"
  },
  {
    name: "Logs",
    value: false,
    subkey: [
      {
        name: "Login",
        value: false,
        url:"/apps/logs/login"
      },
      {
        name: "Apis",
        value: false,
        url:"/apps/logs/apis"
      },
    ],
  },
  {
    name: "Setting",
    value: false,
    subkey: [
      {
        name: "General",
        value: false,
        url:"/apps/settings/general"
      },
      // {
      //   name: "Resources",
      //   value: false,
      // },
      {
        name: "Emails",
        value: false,
        url:"/apps/settings/outlooksync"
      },
      {
        name: "SSO",
        value: false,
        url:"/apps/settings/sso",
      }
    ],
  },
  {
    name: "AI settings",
    value: false,
    url:"/apps/ai-settings"
  },
  {
    name: "My organisation",
    value: false,
    url:"/apps/organisation"
  },
  {
    name: "Billing & Subscription",
    value: false,
    subkey: [
      {
        name: "Invoice",
        value: false,
        url:"/apps/billing-subscription/invoices/list"
      },
      {
        name: "Subscription",
        value: false,
        url:"/apps/billing-subscription/payment"
      },
      {
        name: "Payment",
        value: false,
        url:"/apps/billing-subscription/subscription"
      },
    ],
  },
];

