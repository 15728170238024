import { loadStripe } from '@stripe/stripe-js';
import React from 'react'
import { Elements } from '@stripe/react-stripe-js';
import CardDetails from './CardDetails';

type Props = {}

const STRIPEKEY = (process.env.REACT_APP_STRIPE_MODE == 'live' ? process.env.REACT_APP_STRIPELIVEKEY : process.env.REACT_APP_STRIPETESTKEY) || "";
const stripePromise = loadStripe(STRIPEKEY);
function PaymentDetails({}: Props) {

  return (

    
  <div
      id="kt_content_container"
      className={
        "container container-fluid position-relative px-0 overflow-auto"
      }
    >

      <div className='card'>
        <div className="card-header">
          <div className="card-title">
            <h3 className="text-dark fw-bold fs-18 m-0">Payment</h3>
          </div>
        </div>
        <div className='card-body'>          
          {stripePromise &&                     
            <Elements stripe={stripePromise}>
              <CardDetails/>
            </Elements>                    
          }
        </div> 
      </div>      
  </div>        
  )
}

export default PaymentDetails