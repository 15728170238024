/** @jsxRuntime classic */
/** @jsx createElement */
import { Button } from '../../../react/components/button';
import { Icon } from '../../../react/components/icon';
import { createContext, createElement, Fragment, PureComponent } from '../../../react/renderer';
import { Observable } from '../../util/observable';
import { CalendarViewBase } from './calendar-view';
import { ICalendarViewHost } from './calendar-view.types';
import { MbscCalendarHeaderOptions } from './calendar-view.types.public';
import { PAGE_VIEW } from './calendar-view.util';

export const CalendarContext = createContext<{ children?: any; instance?: CalendarViewBase }>({});

/** @hidden */
export interface IInstanceSubscriberProps {
  /**
   * Host is only set if header controls are placed outside of the calendar,
   * and the host instance (eventcalendar or calendar) is passed to the header control using the `calendar` option.
   */
  host?: ICalendarViewHost;
  component: any;
  /**
   * View is only set in case of jQuery/JS, when enhancing the header controls from the calenar view,
   * and we pass the calendar view instance, which is ready, instead of the host instance, which does not have
   * the calendar view instance yet in the initial render.
   */
  view?: CalendarViewBase;
  [key: string]: any;
}

export class InstanceSubscriber extends PureComponent<IInstanceSubscriberProps, any> {
  // tslint:disable: variable-name
  private _handler!: number;
  private _changes?: Observable<any>;
  // tslint:enable: variable-name

  public componentWillUnmount() {
    if (this._changes) {
      this._changes.unsubscribe(this._handler);
    }
  }

  public render() {
    const { host, component, view, ...other } = this.props;
    const calView = view || (host && host._calendarView);
    if (calView && !this._changes) {
      this._changes = calView.s.instanceService!.onComponentChange;
      this._handler = this._changes.subscribe(() => {
        this.forceUpdate();
      });
    }

    return createElement(CalendarContext.Consumer, null, (({ instance }: { instance?: CalendarViewBase }) => {
      const inst = instance || view || (host && host._calendarView);
      return inst && createElement(component, { inst, ...other });
    }) as any);
  }
}

interface IInstanceProp {
  inst: CalendarViewBase;
  className?: string;
}

const CalendarPrevButton = ({ inst, className }: IInstanceProp) => {
  return (
    <Button
      ariaLabel={inst.s.prevPageText}
      className={'mbsc-calendar-button ' + (className || '')}
      disabled={inst._isPrevDisabled()}
      iconSvg={inst._prevIcon}
      onClick={inst.prevPage}
      theme={inst.s.theme}
      themeVariant={inst.s.themeVariant}
      type="button"
      variant="flat"
    />
  );
};

const CalendarNextButton = ({ inst, className }: IInstanceProp) => {
  return (
    <Button
      ariaLabel={inst.s.nextPageText}
      disabled={inst._isNextDisabled()}
      className={'mbsc-calendar-button ' + (className || '')}
      iconSvg={inst._nextIcon}
      onClick={inst.nextPage}
      theme={inst.s.theme}
      themeVariant={inst.s.themeVariant}
      type="button"
      variant="flat"
    />
  );
};

const CalendarTodayButton = ({ inst, className }: IInstanceProp) => {
  return (
    <Button
      className={'mbsc-calendar-button mbsc-calendar-button-today ' + (className || '')}
      onClick={inst._onTodayClick}
      theme={inst.s.theme}
      themeVariant={inst.s.themeVariant}
      type="button"
      variant="flat"
    >
      {inst.s.todayText}
    </Button>
  );
};

const CalendarTitleButton = ({ inst, className }: IInstanceProp) => {
  const s = inst.s;
  const theme = inst._theme;
  const view = inst._view;
  return (
    <div aria-live="polite" className={(className || '') + theme}>
      {inst._title.map((val: any, index: number) => {
        return (
          (inst._pageNr === 1 || index === 0 || inst._hasPicker || view === PAGE_VIEW) && (
            <Button
              className={'mbsc-calendar-button' + (inst._pageNr > 1 ? ' mbsc-flex-1-1' : '')}
              data-index={index}
              onClick={inst._onPickerBtnClick}
              key={index}
              theme={s.theme}
              themeVariant={s.themeVariant}
              type="button"
              variant="flat"
            >
              {(inst._hasPicker || view === PAGE_VIEW) &&
                (val.title ? (
                  <span className={'mbsc-calendar-title' + theme}>{val.title}</span>
                ) : (
                  <Fragment>
                    {inst._yearFirst && <span className={'mbsc-calendar-title mbsc-calendar-year' + theme}>{val.yearTitle}</span>}
                    <span className={'mbsc-calendar-title mbsc-calendar-month' + theme}>{val.monthTitle}</span>
                    {!inst._yearFirst && <span className={'mbsc-calendar-title mbsc-calendar-year' + theme}>{val.yearTitle}</span>}
                  </Fragment>
                ))}
              {!inst._hasPicker && view !== PAGE_VIEW && <span className={'mbsc-calendar-title' + theme}>{inst._viewTitle}</span>}
              {s.downIcon && inst._pageNr === 1 ? <Icon svg={view === PAGE_VIEW ? s.downIcon : s.upIcon} theme={s.theme} /> : null}
            </Button>
          )
        );
      })}
    </div>
  );
};

export const CalendarPrev = ({ calendar, view, ...others }: MbscCalendarHeaderOptions) => {
  return <InstanceSubscriber component={CalendarPrevButton} host={calendar} view={view} {...others} />;
};
CalendarPrev._name = 'CalendarPrev';

export const CalendarNext = ({ calendar, view, ...others }: MbscCalendarHeaderOptions) => {
  return <InstanceSubscriber component={CalendarNextButton} host={calendar} view={view} {...others} />;
};
CalendarNext._name = 'CalendarNext';

export const CalendarToday = ({ calendar, view, ...others }: MbscCalendarHeaderOptions) => {
  return <InstanceSubscriber component={CalendarTodayButton} host={calendar} view={view} {...others} />;
};
CalendarToday._name = 'CalendarToday';

export const CalendarNav = ({ calendar, view, ...others }: MbscCalendarHeaderOptions) => {
  return <InstanceSubscriber component={CalendarTitleButton} host={calendar} view={view} {...others} />;
};
CalendarNav._name = 'CalendarNav';
