import {FC} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import { Pagination } from 'react-bootstrap'
import { useQueryRequest } from '../core/QueryRequestProvider'
import { KTCardBody, PaginationState } from '../../../../../_metronic/helpers'
import { Tooltip } from '../core/_types'
import { CustomRow } from './column/customRow'
import { CustomHeaderColumn } from './column/CustomHeaderColumn'
import { IntrojsLoading } from '../components/loading/IntrojsLoading'

type Props = {
  isLoading: Boolean;
  data: any;
  columns: any;
  pagination: PaginationState;
};
const IntrojsTable:FC<Props> = ({columns,data,isLoading, pagination}) => {

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  const mappedLabel = (label: string): string => {
    if (label === "&laquo; Previous") {
      return "Previous";
    }
    if (label === "Next &raquo;") {
      return "Next";
    }
    return label;
  };

  const linkCount = Math.ceil(parseInt(pagination.total) / pagination.items_per_page);
  let links = []
  let start = pagination.page - 3 > 0 ? pagination.page - 3 : 0
  let end = pagination.page + 2 < linkCount ? pagination.page + 2 : linkCount
  if (end > 1) {
    for (let i = start; i < end; i++) {
      links[i] = {
        active: false,
        label: '' + (i + 1) + '',
        page: i + 1,
        url: '/?page=' + (i + 1),
      }
    }
  }
  links = links.filter(function (el: any) {
    return el != null;
  });
  const { updateState } = useQueryRequest();

  const updatePage = (page: number | null) => {
    if ((!page || isLoading || pagination.page === page) || (page > linkCount)) {
      return;
    }
    pagination.page = page;
    updateState({ page, items_per_page: pagination.items_per_page || 5 });
  };

  const updatePageItem = (e: any) => {
    pagination.page = 1;
    updateState({ page: 1, items_per_page: e.target.value || 20 });
  };

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_emails'
          className='table align-middle table-row-dashed fs-16 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-dark fw-bolder fs-16 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Tooltip>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Tooltip>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* <EmailsListPagination /> */}
      <div className="d-flex flex-column flex-lg-row col-12 justify-content-between pb-20">
      {+pagination.total > 0 ? (
          <div className="d-flex align-items-center position-relative">
            <label
              className="col-form-label fw-bold fs-6 w-100px fs-ls-14"
              htmlFor="itemPerPage"
            >
              Items per page :
            </label>
            <select
              className="form-select form-select-solid h-40px w-80px p-0 px-4 fs-6 h-ls-48 fs-ls-14"
              id="itemPerPage"
              onChange={updatePageItem}
              value={+pagination.items_per_page}
            >
              {[10, 20, 30, 50, 100].map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </div>
        ) : <div className="d-flex align-items-center position-relative"></div>}
          {links.length > 0 &&
              <Pagination>
                <Pagination.First onClick={() => updatePage(1)} />
                <Pagination.Prev onClick={() => updatePage(pagination.page - 1)} />
                {links
                  ?.map((link) => (
                    <Pagination.Item
                      active={link.page === pagination.page}
                      onClick={() => updatePage(link.page)}
                    >
                      {mappedLabel(link.label)}
                    </Pagination.Item>
                  ))}
                <Pagination.Next onClick={() => updatePage(pagination.page + 1)} />
                <Pagination.Last onClick={() => updatePage(pagination.last_page - 1)} />
              </Pagination>
            }
      </div>
      {isLoading && <IntrojsLoading />}
    </KTCardBody>
  )
}

export {IntrojsTable}
