import { useAuth } from "../../../modules/auth/core/AuthCognito";
export interface IViewSetting {
  PlanBucketData: any,
  ProjectViewData: {
    view_settings_days : boolean , 
    currentview : string , 
    day: {
      view: string,
      showServicecall: boolean,
      showRelations: boolean,
      showColumnSummaryEvent: boolean,
      showAllEvents: boolean,
      numberOfEvents: number,
      rowSelection: {
        row1: boolean,
        row2: boolean,
        row3: boolean,
      },
      columnSelection: {
        assigned: boolean,
        date: boolean,
        hours: boolean,
        status: boolean,
      },
      columnSize: {
        isColumnWidth: boolean,
        columnWidth: number , 
        hoursPerColumn: number,
      },
      start: number,
      end: number,
    },
    week: {
      view: string,
      showServicecall: boolean,
      showRelations: boolean,
      showColumnSummaryEvent: boolean,
      showAllEvents: boolean,
      numberOfEvents: number,
      // Initialize row selection states
      rowSelection: {
        row1: boolean,
        row2: boolean,
        row3: boolean,
      },
      columnSelection: {
        assigned: boolean,
        date: boolean,
        hours: boolean,
        status: boolean,
      },
      columnSize: {
        isColumnWidth: boolean,
        columnWidth: number , 
        hoursPerColumn: number,
      },
      start: number,
      end: number,
    },
    month: {
      view: string,
      showServicecall: boolean,
      showRelations: boolean,
      showColumnSummaryEvent: boolean,
      showAllEvents: boolean,
      numberOfEvents: number,
      rowSelection: {
        row1: boolean,
        row2: boolean,
        row3: boolean,
      },
      columnSelection: {
        assigned: boolean,
        date: boolean,
        hours: boolean,
        status: boolean,
      },
      columnSize: {
        isColumnWidth: boolean,
        columnWidth: number , 
        hoursPerColumn: number,
      },
      start: number,
      end: number,
    },
    year: {
        view: string,
        showServicecall: boolean,
        showRelations: boolean,
        showColumnSummaryEvent: boolean,
        showAllEvents: boolean,
        numberOfEvents: number,
        rowSelection: {
          row1: boolean,
          row2: boolean,
          row3: boolean,
        },
        columnSelection: {
          assigned: boolean,
          date: boolean,
          hours: boolean,
          status: boolean,
        },
        columnSize: {
          isColumnWidth: boolean,
          columnWidth: number , 
          hoursPerColumn: number,
        },
        start: number,
        end: number,
    },
  } , 
  PlannigViewData : {
    view_settings_days : boolean , 
    currentview : string ,
    day: {
      view: string,
      showAllEvents: boolean,
      numberOfEvents: number,
      rowSelection: {
        row1: boolean,
        row2: boolean,
        row3: boolean,
      },
      columnSize: {
        isColumnWidth: boolean,
        columnWidth: number, 
        hoursPerColumn: number,
      },
      start: number,
      end: number,
    },
    week: {
      view: string,
      showAllEvents: boolean,
      numberOfEvents: number,
      rowSelection: {
        row1: boolean,
        row2: boolean,
        row3: boolean,
      },
      columnSize: {
        isColumnWidth: boolean,
        columnWidth: number, 
        hoursPerColumn: number,
      },
      start: number,
      end:number, 
    }
  }
}

// viewSettingsData.js
export const ViewsettingFunction = ():IViewSetting => {
  const { currentUser } = useAuth();
  const { planning_first_day_of_week } = currentUser?.data?.setting || {};
  const startDayIndex = parseInt(planning_first_day_of_week, 10);
  let endDayIndex;
  if(startDayIndex == 0){
    endDayIndex = 6;
  }else{
    endDayIndex = (+startDayIndex - 1);
  }
  return {
    ProjectViewData: {
      view_settings_days : true, 
      currentview : 'week', 
      day: {
        view: 'day',
        showServicecall: true,
        showRelations: true,
        showColumnSummaryEvent: false,
        showAllEvents: true,
        numberOfEvents: 1,
        rowSelection: {
          row1: true,
          row2: false,
          row3: true,
        },
        columnSelection: {
          assigned: true,
          date: true,
          hours: true,
          status: false,
        },
        columnSize: {
          isColumnWidth: false,
          columnWidth: 100,
          hoursPerColumn: 60,
        },
        start: startDayIndex,
        end: endDayIndex,
      },
      week: {
        view: 'week',
        showServicecall: true,
        showRelations: true,
        showColumnSummaryEvent: false,
        showAllEvents: true,
        numberOfEvents: 1,
        // Initialize row selection states
        rowSelection: {
          row1: true,
          row2: false,
          row3: false,
        },
        columnSelection: {
          assigned: true,
          date: true,
          hours: true,
          status: false,
        },
        columnSize: {
          isColumnWidth: false,
          columnWidth: 100,
          hoursPerColumn: 60,
        },
        start: startDayIndex,
        end: endDayIndex,
      },
      month: {
        view: 'month',
        showServicecall: false,
        showRelations: true,
        showColumnSummaryEvent: true,
        showAllEvents: true,
        numberOfEvents: 1,
        rowSelection: {
          row1: true,
          row2: false,
          row3: false,
        },
        columnSelection: {
          assigned: true,
          date: false,
          hours: false,
          status: false,
        },
        columnSize: {
          isColumnWidth: false,
          columnWidth: 100,
          hoursPerColumn: 60,
        },
        start: startDayIndex,
        end: endDayIndex,
      },
      year: {
        view: 'year',
        showServicecall: false,
        showRelations: true,
        showColumnSummaryEvent: true,
        showAllEvents: true,
        numberOfEvents: 1,
        rowSelection: {
          row1: true,
          row2: false,
          row3: false,
        },
        columnSelection: {
          assigned: true,
          date: false,
          hours: false,
          status: false,
        },
        columnSize: {
          isColumnWidth: false,
          columnWidth: 100,
          hoursPerColumn: 60,
        },
        start: startDayIndex,
        end: endDayIndex,
      },
    } , 
    PlannigViewData : {
      view_settings_days : true, 
      currentview : 'week',
      day: {
        view: 'day',
        showAllEvents: true,
        numberOfEvents: 1,
        rowSelection: {
          row1: true,
          row2: false,
          row3: true,
        },
        columnSize: {
          isColumnWidth: false,
          columnWidth: 100,
          hoursPerColumn: 60,
        },
        start: startDayIndex,
        end: endDayIndex,
      },
      week: {
        view: 'week',
        showAllEvents: false,
        numberOfEvents: 3,
        rowSelection: {
          row1: true,
          row2: false,
          row3: false,
        },
        columnSize: {
          isColumnWidth: false,
          columnWidth: 100,
          hoursPerColumn: 60,
        },
        start: startDayIndex,
        end: endDayIndex,
      }
    },
    PlanBucketData: [
      { id: "title", label: "Title", value: true },
      { id: "status", label: "Status", value: true },
      { id: "type", label: "Type", value: true },
      { id: "description", label: "Description", value: false },
      { id: "company_project", label: "Company/Project", value: false },
      { id: "start_end_date_estimated_hours", label: "Start / End date / Estimated hours", value: false },
      { id: "related_task_ticket", label: "Related Task / Ticket", value: false },
      { id: "primary_secondary_resource", label: "Primary / Secondary resource", value: true },
      { id: "related_servicecall", label: "Related Servicecall", value: false },
      { id: "location", label: "Location", value: false },
      { id: "queues", label: "Queues", value: false },
      { id: "priority", label: "Priority", value: false },
      { id: "contact_information", label: "Contact information", value: false },
    ],
  }
  
}




