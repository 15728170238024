import React, { FC } from "react";
import moment from "moment-timezone";
const RenderCustomDay: FC<any> = ({ args, view, formatDate }) => {
  const date = args.date;
  let eventOccurrence = "none";
  let monday = moment(date).clone().weekday(0);
  let friday = moment(date).clone().weekday(6);
  return (
    <div className="d-flex align-items-center">
      {view == "day" || view == "week" || view == "month" ? (
        <div className={"md-date-header md-date-header-events-"}>
          <div className="md-date-header-day-name">
            {formatDate("DDD", date)}
          </div>
          &nbsp;
          <div className="md-date-header-day-nr">{formatDate("DD", date)}</div>
        </div>
      ) : (
        <div
          className={"md-date-header md-date-header-events-" + eventOccurrence}
        >
          <div className="md-date-header-day-name">
            {monday.format("DD-MM")} - {friday.format("DD-MM")}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(RenderCustomDay);
