import axios, { AxiosResponse } from "axios";
import { RegistrationDetail } from "../../app/modules/apps/registration/_model";
import { Response } from "../helpers";

const API_URL_NODE = process.env.REACT_APP_API_URL;
const REGISTER_URL_NODE = `${API_URL_NODE}superadmin/organisations/register`;
const CONFIRM_REGISTER_URL_NODE = `${API_URL_NODE}superadmin/organisations/confirm`;
const APPROVE_REGISTER_URL_NODE = `${API_URL_NODE}superadmin/organisations/approve_registration`;
const UNIQUE_EMAIL_URL_NODE = `${API_URL_NODE}superadmin/organisations`;
const CHECK_PASSWORD_URL = `${API_URL_NODE}users/check-token`;

const registerOrganisation = (registerDetail: RegistrationDetail): Promise<RegistrationDetail | undefined> => {
  return axios
    .post(REGISTER_URL_NODE, registerDetail)
    .then((response: AxiosResponse<Response<RegistrationDetail>>) => response.data)
    .then((response: Response<RegistrationDetail>) => response.data);
};

const confirmOrganisation = (registerDetail: RegistrationDetail): Promise<RegistrationDetail | undefined> => {
  return axios
    .post(CONFIRM_REGISTER_URL_NODE, registerDetail)
    .then((response: AxiosResponse<Response<RegistrationDetail>>) => response.data)
    .then((response: Response<RegistrationDetail>) => response.data);
};

const approveOrganisation = (registerDetail: RegistrationDetail): Promise<RegistrationDetail | undefined> => {
  return axios
    .post(APPROVE_REGISTER_URL_NODE, registerDetail)
    .then((response: AxiosResponse<Response<RegistrationDetail>>) => response.data)
    .then((response: Response<RegistrationDetail>) => response.data);
};

const uniqueEmail = (registerDetail: RegistrationDetail): Promise<RegistrationDetail | undefined> => {
  return axios
    .post(`${UNIQUE_EMAIL_URL_NODE}/check-email`, registerDetail)
    .then((response: AxiosResponse<Response<RegistrationDetail>>) => response.data)
    .then((response: Response<RegistrationDetail>) => response.data);
};

const uniqueSubdomain = (registerDetail: RegistrationDetail): Promise<RegistrationDetail | undefined> => {
  return axios
    .post(`${UNIQUE_EMAIL_URL_NODE}/check-unique-domain`, registerDetail)
    .then((response: AxiosResponse<Response<RegistrationDetail>>) => response.data)
    .then((response: Response<RegistrationDetail>) => response.data);
};


const checkPasswordToken = (registerDetail: RegistrationDetail): Promise<RegistrationDetail | undefined> => {
  return axios
    .post(CHECK_PASSWORD_URL, registerDetail)
    .then((response: AxiosResponse<Response<RegistrationDetail>>) => response.data)
    .then((response: Response<RegistrationDetail>) => response.data);
};

export {
    registerOrganisation,
    confirmOrganisation,
    approveOrganisation,
    uniqueEmail,
    uniqueSubdomain,
    checkPasswordToken
};
